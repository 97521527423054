import React from 'common/react-vendor';
import {UseTransformation} from '../pipeline';
import styles from './index.module.css';

const {useMemo} = React;

export interface PaginationState {
	page: number;
	pageSize: number;
}

export function setPage(
	{pageSize}: PaginationState,
	page: number
): PaginationState {
	return {page, pageSize};
}

export function setPageSize(
	{page}: PaginationState,
	pageSize: number
): PaginationState {
	return {page, pageSize};
}

type PaginationProps = {
	pagination: PaginationState;
	onChange: (s: PaginationState) => void;
	pageSizes?: number[];
} & PartialCounts;

export function Pagination({
	pagination,
	onChange,
	pageSizes = [9, 10, 25, 50],
	...rest
}: PaginationProps): JSX.Element {
	const {page, pageSize} = pagination;
	const {pageCount, rowCount} = getCounts(rest, pageSize);

	return (
		// TODO: this is a temporary placeholder until we have the component library
		<div className={styles.wrapper}>
			<label className={styles.pageSize}>
				<select
					className={styles.input}
					value={pageSize}
					onChange={(e) => {
						const pageSize = +e.target.value;
						const page = 0;
						if (!Number.isNaN(pageSize))
							onChange({...pagination, pageSize, page});
					}}>
					{!pageSizes.includes(pageSize) && (
						// If the current page size isn't one of the options, add it to
						// the list to keep the UI consistent
						<option value={pageSize}>{pageSize}</option>
					)}
					{pageSizes.map((s) => (
						<option key={s} value={s}>
							{s}
						</option>
					))}
				</select>{' '}
				Items of {rowCount.toLocaleString()}
			</label>
			<label className={styles.page}>
				Page{' '}
				<input
					className={`${styles.input} pagination_pageNumber_input`}
					type='number'
					value={page + 1}
					onChange={(e) => {
						if (e.target.value === '')
							return onChange({...pagination, page: 0});
						const page = +e.target.value - 1;
						if (Number.isNaN(page)) return;
						if (page < 0 || page >= pageCount) return;
						onChange({...pagination, page});
					}}
				/>{' '}
				of {pageCount.toLocaleString()}
			</label>
			<button
				type='button'
				className={styles.button}
				disabled={page <= 0}
				onClick={() => onChange({...pagination, page: page - 1})}>
				<i className='fa fa-angle-left' />
			</button>
			<button
				type='button'
				className={styles.button}
				disabled={page + 1 >= pageCount}
				onClick={() => onChange({...pagination, page: page + 1})}>
				<i className='fa fa-angle-right' />
			</button>
		</div>
	);
}

type Counts = {
	rowCount: number;
	pageCount: number;
};

type PartialCounts = Partial<Counts> &
	(Pick<Counts, 'rowCount'> | Pick<Counts, 'pageCount'>);

function getCounts(counts: PartialCounts, pageSize: number): Counts {
	if (typeof counts.rowCount === 'number')
		return {
			rowCount: counts.rowCount,
			pageCount: Math.ceil(counts.rowCount / pageSize),
		};
	if (typeof counts.pageCount === 'number')
		return {
			pageCount: counts.pageCount,
			rowCount: counts.pageCount * pageSize,
		};
	throw new Error(
		'The <Pagination /> component needs either a row count or a page count'
	);
}

export function paginate<Item>(): UseTransformation<
	PaginationState,
	Item[],
	Item[]
> {
	return ({page, pageSize}, items) => {
		return useMemo(
			() => items.slice(page * pageSize, page * pageSize + pageSize),
			[items, page, pageSize]
		);
	};
}
