import {isEqual} from 'lodash';
import React, {Component, PropTypes} from 'common/react-vendor';
import {redux} from 'store';
import pikaday from 'pikaday';
import './le-date-range.scss';

export default class LeDateRange extends Component {
	constructor(props) {
		super(props);

		this.redux = redux({
			path: props.namespace || '@filter.daterange',
			state: props.config,
			component: this,
			appCache: props.noAppCache ? null : ['low', 'high'],
		});

		this.high_calendar = React.createRef();
		this.low_calendar = React.createRef();
	}

	/**
	 * FIXME
	 * This is an initial fix for a deeper problem with redux and the item list component
	 * where the props and state are not in sync.
	 * A better fix should be done later when more time is allowed
	 */
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps?.config?.items?.length !== undefined &&
			!isEqual(nextProps?.config?.items, this.redux.get()?.items)
		) {
			this.redux.set({items: nextProps.config.items});
		}
	}

	closeCalendars() {
		if (this.high_calendar.current.childNodes.length > 0) {
			this.high_calendar.current.innerHTML = '';
		}

		if (this.low_calendar.current.childNodes.length > 0) {
			this.low_calendar.current.innerHTML = '';
		}
	}

	openCalendar(ref) {
		const state = this.redux.get(),
			element = this[ref].current;

		if (element.childNodes.length > 0) {
			return;
		}

		const picker = new pikaday({
			format: 'MM/DD/YYYY',
			theme: 'filter-date-range-pikaday',
			minDate: new Date(this.date(state.min)),
			maxDate: new Date(this.date(state.max)),
			onSelect: (date) => {
				this.setCalendar(date, ref);
			},
		});

		picker.setDate(
			new Date(
				ref == 'low_calendar' ? state.low || state.min : state.high || state.max
			)
		);
		const closebtn = document.createElement('div');
		closebtn.className = 'fa fa-times calendar-close';
		closebtn.addEventListener('click', (e) => {
			this.closeCalendars(e);
		});
		element.appendChild(picker.el);
		element.appendChild(closebtn);
	}

	setCalendar(date, ref) {
		const state = this.redux.get();
		let timestamp = date.getTime();
		this.closeCalendars();

		if (ref == 'low_calendar') {
			state['low'] = timestamp;
		} else {
			timestamp = new Date(timestamp);
			timestamp = Date.parse(
				`${timestamp.getFullYear()}/${
					timestamp.getMonth() + 1
				}/${timestamp.getDate()}  23:59:59`
			);
			state['high'] = timestamp;
		}

		this.redux.set(state);
	}

	date = (ts) => {
		const date = new Date(ts);
		return (
			date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
		);
	};

	render() {
		const state = this.redux.get();

		return (
			<div className='filter-date-range'>
				{state.label ? (
					<span className='date-range-label'>{state.label}</span>
				) : (
					''
				)}
				<ul className='date-range-container noselect-children'>
					<li className='date-range-input'>
						<div
							onClick={() => {
								this.openCalendar('low_calendar');
							}}>
							<div className='date-range-calendar' ref={this.low_calendar} />
							{this.date(state.low || state.min)}
							<i className='fa fa-calendar pull-right' />
						</div>
					</li>
					<li className='date-range-separator'>to</li>
					<li className='date-range-input'>
						<div
							onClick={() => {
								this.openCalendar('high_calendar');
							}}>
							<div
								className='date-range-calendar'
								ref={this.high_calendar}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
								}}
							/>
							{this.date(state.high || state.max)}
							<i className='fa fa-calendar pull-right' />
						</div>
					</li>
				</ul>
			</div>
		);
	}
}

LeDateRange.propTypes = {
	config: PropTypes.object.isRequired,
};
