import {DNBTypography} from 'common/dnb-uux-vendor';
import React from 'common/react-vendor';
import {
	CommonMultipleSelectGroup,
	ICommonMultipleSelectGroup,
} from '../../AddConnection/Component/Common/CommonMultipleSelectGroup';
import {ConnectionSearch} from '../Search/ConnectionSearch';
import Styles from './SourceListCustomizeColumn.module.scss';

const SourceListCustomizeColumn = ({
	optionList,
	onOptionChanged,
	isDraggable,
	onDragStart,
	onDragEnd,
	onDragOver,
}: ICommonMultipleSelectGroup): React.ReactElement => {
	const [search, setSearch] = React.useState('');
	return (
		<div className={Styles.SourceListCustomizeColumn}>
			<DNBTypography variant='subtitle2' sx={{mb: 3}}>
				Customize Columns
			</DNBTypography>
			<ConnectionSearch
				search={search}
				placeHolder='Filter by Name'
				onSearchChanged={setSearch}
			/>
			<CommonMultipleSelectGroup
				optionList={optionList}
				onOptionChanged={onOptionChanged}
				search={search}
				isDraggable={isDraggable}
				onDragStart={onDragStart}
				onDragEnd={onDragEnd}
				onDragOver={onDragOver}
			/>
		</div>
	);
};

export {SourceListCustomizeColumn};
