import React from 'common/react-vendor';
import {DNBTypography} from 'common/dnb-uux-vendor';
import {AlertBox} from 'common/widgets/alert/AlertBox';
import styles from './PersonalizationComponent.module.scss';

interface ILimitWarningBanner {
	tipsBannerMessage: string;
}

const LimitWarningBanner = ({
	tipsBannerMessage,
}: ILimitWarningBanner): React.ReactElement => {
	return (
		<div className={styles.personalizationBanner}>
			<AlertBox
				message={
					<DNBTypography variant='compact-body'>
						{tipsBannerMessage}
					</DNBTypography>
				}
				variant='error'
				hasCloseIcon={false}
			/>
		</div>
	);
};

interface ISuccessBanner {
	onClose: () => void;
	tipsBannerMessage: string;
}

const SuccessBanner = ({
	onClose,
	tipsBannerMessage,
}: ISuccessBanner): React.ReactElement => {
	return (
		<div className={styles.successBanner}>
			<AlertBox
				message={
					<DNBTypography variant='compact-body'>
						{tipsBannerMessage}
					</DNBTypography>
				}
				variant='success'
				handleClose={onClose}
			/>
		</div>
	);
};

interface IWorkflowStatusBanner {
	tipsBannerMessage: string;
	variant?: 'success' | 'info' | 'warning' | 'error';
}

const WorkflowStatusBanner = ({
	tipsBannerMessage,
	variant,
}: IWorkflowStatusBanner): React.ReactElement => {
	return (
		<div className={styles.successBanner}>
			<AlertBox
				message={
					<DNBTypography variant='compact-body'>
						{tipsBannerMessage}
					</DNBTypography>
				}
				variant={variant}
			/>
		</div>
	);
};

export {LimitWarningBanner, SuccessBanner, WorkflowStatusBanner};
