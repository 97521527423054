import React from 'common/react-vendor';
import {
	DNBIconButton,
	EditIcon,
	DeleteOutlineOutlinedIcon,
} from 'common/dnb-uux-vendor';
import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {CDP_SCHEMA_FIELD} from '../consts';
import type {IAttributeData} from '../schemaTypes';

export default function ColumnActions({
	data,
	onClickEditAction,
	onClickDeleteAction,
}: {
	data: IAttributeData;
	onClickEditAction: () => void;
	onClickDeleteAction: () => void;
}): React.ReactElement {
	const showDeleteButton = RBAC.hasAccess(
		RBACInterface.EIF_CDP_SCHEMA,
		RBACActions.CREATE
	);
	const showEditButton = RBAC.hasAccess(
		RBACInterface.EIF_CDP_SCHEMA,
		RBACActions.EDIT
	);
	return (
		<div>
			{showEditButton && (
				<DNBIconButton
					title={`edit ${data.screenName || ''}`}
					onClick={onClickEditAction}>
					<EditIcon />
				</DNBIconButton>
			)}
			{showDeleteButton && data.property === CDP_SCHEMA_FIELD && (
				<DNBIconButton
					title={`delete ${data.screenName || ''}`}
					onClick={onClickDeleteAction}>
					<DeleteOutlineOutlinedIcon />
				</DNBIconButton>
			)}
		</div>
	);
}
