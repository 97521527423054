import React from 'common/react-vendor';

interface ISpinner {
	width?: number;
	height?: number;
}

export const Spinner = ({width = 100, height = 100}: ISpinner): JSX.Element => (
	<img
		src='/assets/images/spinners/spinner-lattice-dots.gif'
		style={{display: 'block', margin: 'auto', height, width}}
		alt='Loading'
	/>
);
