import {ImportStatus} from './ImportType';

enum FieldType {
	// User Action.
	UserAction = 'UserAction',
	// Common Connection fields.
	SystemId = 'SystemId',
	SystemName = 'SystemName',
	SystemType = 'SystemType',
	Enabled = 'Enabled',
	Beta = 'Beta',
	Locked = 'Locked',
	Description = 'Description',
	// Authentication.
	AuthenticationName = 'AuthenticationName',
	AuthenticationUpdatedAt = 'AuthenticationUpdatedAt',
	IsSandbox = 'IsSandbox',
	ClientId = 'ClientId',
	ClientSecret = 'ClientSecret',
	EndPoint = 'EndPoint',
	UnitId = 'UnitId',
	// Import Connection fields.
	Priority = 'Priority',
	LastImportStatus = 'LastImportStatus',
	LastImportTime = 'LastImportTime',
	SyncStartDate = 'SyncStartDate',
	Schedule = 'Schedule',
	CronExpression = 'CronExpression',
	CreatedBy = 'CreatedBy',
	LastUpdatedDate = 'LastUpdatedDate',
	// Activation Connection Fields.
	OrgId = 'OrgId',
	ContactId = 'ContactId',
	AccountId = 'AccountId',
	AccountName = 'AccountName',
	LeadId = 'LeadId',
	AttributeSetName = 'AttributeSetName',
	exportFieldMappings = 'exportFieldMappings',
	emailMapping = 'emailMapping',
	Created = 'Created',
	LastUpdated = 'LastUpdated',
	LastUpdatedBy = 'LastUpdatedBy',
	Status = 'Status',
	ExpirationDate = 'ExpirationDate',
	ExternalAuthentication = 'ExternalAuthentication',
	BrokerAuthentication = 'BrokerAuthentication',
	ExternalSystemType = 'ExternalSystemType',
	TrayEnabled = 'TrayEnabled',
	Category = 'Category',
	GDSPId = 'GDSPId',
	GDSPName = 'GDSPName',
	// Source Fields.
	Source = 'Source',
	SourceId = 'SourceId',
	TypeOfConnection = 'TypeOfConnection',
	CDPObject = 'CDPObject',
	Entity = 'Entity',
	UniqueID = 'UniqueID',
	UniqueIDDisplayName = 'UniqueIDDisplayName',
	S3Location = 'S3Location',
	IsEntityRelationConfirmed = 'IsEntityRelationConfirmed',
	IsEmptySource = 'IsEmptySource',
	IsNoDataSource = 'IsNoDataSource',
	TeamId = 'TeamId',
}

const CommonFieldTypeList = [
	FieldType.SystemName,
	FieldType.SystemType,
	FieldType.Enabled,
	FieldType.Beta,
	FieldType.Locked,
	FieldType.Description,
];

const ImportFieldTypeList = [
	FieldType.SystemName,
	FieldType.Priority,
	FieldType.SystemType,
	FieldType.Schedule,
	FieldType.CreatedBy,
	FieldType.TypeOfConnection,
];

const ActivationFieldTypeList = [
	FieldType.SystemName,
	FieldType.SystemType,
	FieldType.CreatedBy,
	FieldType.Created,
	FieldType.TypeOfConnection,
];

const SourceFieldList = [
	FieldType.Source,
	FieldType.SystemName,
	FieldType.TypeOfConnection,
	FieldType.LastImportTime,
	FieldType.LastImportStatus,
	FieldType.CDPObject,
	FieldType.Entity,
	FieldType.CreatedBy,
	FieldType.LastUpdated,
	FieldType.LastUpdatedBy,
	FieldType.UniqueID,
	FieldType.S3Location,
];

type IFieldTypeConfig = {
	[key in FieldType]: string;
};

const FieldTypeConfig: IFieldTypeConfig = {
	// User action.
	[FieldType.UserAction]: 'UserAction',
	// Common fields.
	[FieldType.SystemId]: 'System ID',
	[FieldType.SystemName]: 'Connection',
	[FieldType.SystemType]: 'Connector',
	[FieldType.Enabled]: 'Enabled',
	[FieldType.Beta]: 'Beta',
	[FieldType.Locked]: 'Locked',
	[FieldType.Description]: 'Description',
	// Authentication fields.
	[FieldType.AuthenticationName]: 'Authentication Name',
	[FieldType.AuthenticationUpdatedAt]: 'Authentication Updated At',
	[FieldType.IsSandbox]: 'isSandbox',
	[FieldType.ClientId]: 'clientId',
	[FieldType.ClientSecret]: 'clientSecret',
	[FieldType.EndPoint]: 'endPoint',
	[FieldType.UnitId]: 'Business Unit Id',
	// Import fields.
	[FieldType.Priority]: 'Priority',
	[FieldType.LastImportStatus]: 'Last Import Status',
	[FieldType.LastImportTime]: 'Last Import Date',
	[FieldType.SyncStartDate]: 'Sync Start Date',
	[FieldType.Schedule]: 'Schedule',
	[FieldType.CronExpression]: 'CronExpression',
	[FieldType.CreatedBy]: 'Created By',
	[FieldType.LastUpdatedDate]: 'Last Updated Date',
	// Activation Fields.
	[FieldType.OrgId]: 'Org ID',
	[FieldType.ContactId]: 'ContactId',
	[FieldType.AccountId]: 'AccountId',
	[FieldType.AccountName]: 'AccountName',
	[FieldType.LeadId]: 'LeadId',
	[FieldType.TeamId]: 'TeamId',
	[FieldType.AttributeSetName]: 'AttributeSetName',
	[FieldType.exportFieldMappings]: 'exportFieldMappings',
	[FieldType.emailMapping]: 'emailMapping',
	[FieldType.Created]: 'Created Date',
	[FieldType.LastUpdated]: 'Last Updated Date',
	[FieldType.LastUpdatedBy]: 'Last Updated By',
	[FieldType.Status]: 'Status',
	[FieldType.ExpirationDate]: 'Authentication Expired On',
	[FieldType.ExternalAuthentication]: 'External Authentication',
	[FieldType.BrokerAuthentication]: 'Broker Authentication',
	[FieldType.ExternalSystemType]: 'ExternalSystemType',
	[FieldType.TrayEnabled]: 'TrayEnabled',
	[FieldType.Category]: 'Connection Type',
	[FieldType.GDSPId]: 'DSP Id',
	[FieldType.GDSPName]: 'DSP Name',
	// Source Fields.
	[FieldType.SourceId]: 'SourceId',
	[FieldType.Source]: 'Source',
	[FieldType.TypeOfConnection]: 'Type Of Connection',
	[FieldType.CDPObject]: 'CDP Object',
	[FieldType.Entity]: 'Connection Object',
	[FieldType.UniqueID]: 'Unique ID',
	[FieldType.UniqueIDDisplayName]: 'Unique ID',
	[FieldType.S3Location]: 'AWS S3 Location',
	IsEntityRelationConfirmed: 'Is Entity Relation Confirmed',
	IsEmptySource: 'Is Source Empty',
	IsNoDataSource: 'No data file in AWS S3',
};

const DefaultPriority = 2147483647;
const DefaultLastImportStatus = ImportStatus.None;
const DefaultDescription = '';
const DefaultBeta = false;
const DefaultLocked = false;
const SystemNameDisplayName = 'Name';

export {
	FieldType,
	FieldTypeConfig,
	CommonFieldTypeList,
	ImportFieldTypeList,
	ActivationFieldTypeList,
	SourceFieldList,
	DefaultPriority,
	DefaultLastImportStatus,
	DefaultDescription,
	DefaultBeta,
	DefaultLocked,
	SystemNameDisplayName,
};
