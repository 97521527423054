import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {PredefinedRoles} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {store} from 'store';
import {clearDataCloudStore} from 'common/stores/datacloud';
import template from './edit-list.component.html';
import {Banner} from '../../../../../app/services/BannerService';
import AttrConfigService from '../../AttrConfigService';
import AttrConfigStore from '../../AttrConfigStore';

angular.module('common.attributes.edit.list', []).component('attrEditList', {
	template,
	bindings: {
		filters: '<',
	},
	controller: function ($state) {
		const vm = this;

		vm.store = AttrConfigStore;

		this.$onInit = function () {
			vm.accesslevel = RBAC.getUserAccessLevel().name;
			vm.section = vm.store.getSection($state.current.name);
			vm.category = vm.store.get('category');
			vm.data = vm.store.get('data');
			vm.elementSuffixes = {
				description: '_Description',
				displayName: '_DisplayName',
			};

			vm.store.setData('original', JSON.parse(JSON.stringify(vm.data.config)));

			if (vm.isUser()) {
				vm.showImmutable();
			}
		};

		this.showImmutable = function () {
			Banner.info({
				title: 'Immutable Data Notification',
				message:
					'Your access level has placed certain restrictions on modifications in this section.',
			});
		};

		this.isUser = function () {
			return RBAC.userHasAccessLevel([
				PredefinedRoles.INTERNAL_USER,
				PredefinedRoles.EXTERNAL_USER,
			]);
		};

		this.getResults = function () {
			return this.data.config.Attributes;
		};

		this.getPageSize = function () {
			return this.filters.pagesize;
		};

		this.isStartsDisabled = function (item) {
			if (vm.isUser()) {
				return true;
			}

			return false;
		};

		vm.modifiedAttributes = [];
		vm.original = null;

		this.setOriginal = function (item) {
			if (item.DisplayName === '') {
				item.DisplayName = item.DefaultName;
			}
			vm.original = vm.store
				.getData('original')
				.Attributes.filter(function (attr) {
					return attr.Attribute === item.Attribute;
				});
			vm.original = vm.original.length > 0 ? vm.original[0] : item;
		};
		const debounce = (fn, time) => {
			let timeout;

			return function (...args) {
				const functionCall = () => fn.apply(this, args);

				clearTimeout(timeout);
				timeout = setTimeout(functionCall, time);
			};
		};
		this.saveAttrChanges = debounce(function () {
			AttrConfigService.putConfig(
				'name',
				vm.category,
				{},
				{Attributes: vm.modifiedAttributes}
			).then(function (result) {
				const attributes = result.data.Attributes;
				attributes.forEach((currentAttributeResult) => {
					const storeElement = vm
						.getResults()
						.find((i) => i.Attribute === currentAttributeResult.Attribute);
					Object.values(vm.elementSuffixes).forEach((suffix) => {
						const formElement =
							vm.attr_edit_form[`${currentAttributeResult.Attribute}${suffix}`];
						if (formElement.$dirty) {
							if ('errorMessage' in currentAttributeResult) {
								storeElement.ErrorMessage = currentAttributeResult.errorMessage;
								formElement.$setValidity('saved', false);
							} else {
								storeElement.ErrorMessage = '';
								formElement.$setValidity('saved', true);
							}
						}
					});
				});

				vm.modifiedAttributes.forEach(function (attribute) {
					if (attribute.DisplayName === '') {
						attribute.DisplayName = attribute.DefaultName;
					}
					vm.original = vm.store
						.getData('original')
						.Attributes.filter(function (attr) {
							return attr.Attribute === attribute.Attribute;
						});
					vm.original = vm.original.length > 0 ? vm.original[0] : attribute;
				});

				store.dispatch(clearDataCloudStore());
			});
		}, 1500);
		this.onChange = function (item, name) {
			this.setOriginal(item);

			// set item to pending
			vm.attr_edit_form[name].$setValidity('saved');
			if (
				item.DisplayName !== vm.original.DisplayName ||
				item.Description !== vm.original.Description
			) {
				if (!vm.modifiedAttributes.includes(item)) {
					vm.modifiedAttributes.push(item);
				}
			} else {
				const removeItemIndex = vm.modifiedAttributes.indexOf(item);
				vm.modifiedAttributes.splice(removeItemIndex);
			}
			this.saveAttrChanges();
		};
	},
});
