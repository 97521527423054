import React from 'common/react-vendor';
import {DNBTooltip, InfoOutlinedIcon} from 'common/dnb-uux-vendor';
import styles from './DisabledCategoryTooltip.module.scss';

interface IDisabledCategoryTooltip {
	categoryName: string;
}

const CategoryTooltipMap: Record<string, string> = {
	Performance:
		'Campaign performance metrics are available for setup, contact support for help.',
};

const ModalTooltipOverride = {
	zIndex: '1000 !important', // override it since modal's z-index is 999
};

const DisabledCategoryTooltip = ({
	categoryName,
}: IDisabledCategoryTooltip): React.ReactElement => {
	const toolTip = CategoryTooltipMap[categoryName];
	return (
		<DNBTooltip
			arrow
			placement='top'
			sx={ModalTooltipOverride}
			content={<div className={styles.tooltip}>{toolTip}</div>}>
			<InfoOutlinedIcon fontSize='inherit' />
		</DNBTooltip>
	);
};

const DisabledColumnTooltip = (): React.ReactElement => {
	return (
		<DNBTooltip
			arrow
			placement='top'
			sx={ModalTooltipOverride}
			content={
				<div className={styles.tooltip}>
					This metric is from a data source not set up yet, contact support for
					help.
				</div>
			}>
			<InfoOutlinedIcon fontSize='inherit' />
		</DNBTooltip>
	);
};

export {DisabledCategoryTooltip, DisabledColumnTooltip};
