import NgState from 'atlas/ng-state';
import {
	LatticeSchema,
	Field,
	Entity,
	FieldObj,
	LatticeSchemaApiResponse,
	OriginalMapping,
	Fields,
} from './ImportUtils.types';

let latticeSchema: LatticeSchema = {};

const setFields = (fieldObj: Field, field: Field): Field => {
	const newFieldObj = {...fieldObj};
	Object.keys(field).forEach(function (key) {
		newFieldObj[key] = field[key]!;
	});
	return newFieldObj;
};

const setFieldsInEntity = (entityName: string, fields: Field[]): void => {
	let entity = latticeSchema.entityName;
	if (!entity) {
		entity = {map: {}, list: []};
		latticeSchema[entityName] = entity;
	}
	fields.forEach((field) => {
		const fieldName = field.name!;
		let fieldObj = {};
		entity!.map[fieldName] = fieldObj;
		fieldObj = setFields(fieldObj, field);
		entity!.list.push(fieldObj);
	});
};

const isFieldPartOfSchema = (entity: string, fieldName: string): boolean => {
	const entityObj = latticeSchema[entity];
	const {map} = entityObj as Entity;
	if (map[fieldName]) {
		return true;
	}
	return false;
};

const isFieldMapped = (
	entity: string,
	fieldName: string,
	fieldsMapped: Field[]
): boolean => {
	let mapped = false;

	if (fieldsMapped) {
		const entityObj = latticeSchema[entity];
		const {map} = entityObj as Entity;
		fieldsMapped.forEach(function (element) {
			if (
				element.userField === fieldName &&
				element.mappedField != null &&
				map[element.mappedField]
			) {
				mapped = true;
				return mapped;
			}
		});
	}

	return mapped;
};

const cleanSchema = (): void => {
	latticeSchema = {};
};

const removeOtherIds = (fieldsMapped: Field[]): void => {
	if (fieldsMapped) {
		fieldsMapped.forEach((fieldMapped, index) => {
			if (
				fieldMapped.cdlExternalSystemType &&
				fieldMapped.cdlExternalSystemType === true
			) {
				fieldsMapped.splice(index, 1);
			}
		});
	}
};

const addOtherIdsEmpty = (savedObj: Field[]): Field[] => {
	const ret: Field[] = [];
	if (savedObj) {
		// const {redux} = $state.get('home.import').data;
		const {redux} = NgState.getAngularState().get('home.import').data;
		const initialMapping = redux.store.fieldMappings.map;
		savedObj.forEach((object) => {
			if (object.cdlExternalSystemType && object.cdlExternalSystemType !== '') {
				const {userField} = object;
				const obj: Field = {
					userField,
					mappedField: null,
					fieldType: initialMapping[userField].fieldType,
				};
				ret.push(obj);
			}
		});
	}
	return ret;
};

const mapUnmapUniqueId = (
	fieldsMapping: Field[],
	uniqueId: string,
	fieldName: string,
	unmap: boolean,
	mapToLatticeId: boolean,
	IdType: unknown,
	systemName?: string | null
): Field[] => {
	let newFieldsMapping = [...fieldsMapping];
	if (uniqueId && fieldName) {
		newFieldsMapping = fieldsMapping.map((fieldParam) => {
			const field = {...fieldParam};
			if (field.userField === fieldName) {
				if (unmap) {
					if (field.mappedField === uniqueId) {
						field.mappedToLatticeField = false;
						field.mapToLatticeId = false;
						field.idType = null;
						field.systemName = systemName;
						delete field.mappedField;
					}
				} else if (!field.fieldMapped) {
					field.mappedToLatticeField = false;
					field.mapToLatticeId = mapToLatticeId || false;
					field.mappedField = uniqueId;
					field.idType = IdType;
					field.systemName = systemName;
				}
			}
			return field;
		});
	}
	return newFieldsMapping;
};

const updateUniqueIdsMapping = (
	fieldsMapping: Field[],
	savedObj: Field[]
): Field[] => {
	let newFieldsMapping = [...fieldsMapping];
	savedObj.forEach((saved) => {
		if (
			(saved!.originalUserField && saved!.append !== true) ||
			(!saved!.originalUserField && saved!.append !== true)
		) {
			newFieldsMapping = mapUnmapUniqueId(
				fieldsMapping,
				saved!.mappedField!,
				saved!.originalUserField!,
				true,
				false,
				null,
				null
			);
			newFieldsMapping = mapUnmapUniqueId(
				fieldsMapping,
				saved!.mappedField!,
				saved!.userField!,
				false,
				saved!.mapToLatticeId!,
				saved!.idType,
				saved!.systemName
			);
		}
	});
	return newFieldsMapping;
};

const cleanDuplicates = (arr: Field[]): Field[] => {
	const filteredArr = arr.reduce((acc, current) => {
		const x = acc.find((item) => {
			return (
				item.userField === current.userField &&
				item.mappedField === current.mappedField &&
				item.cdlExternalSystemType === current.cdlExternalSystemType
			);
		});
		if (!x) {
			return acc.concat([current]);
		}
		return acc;
	}, [] as Field[]);

	return filteredArr;
};

const excludeOtherIds = (savedObj: Field[]): Field[] => {
	const retList: Field[] = [];
	const indexToRemove: number[] = [];
	savedObj.forEach((item, index) => {
		if (item.cdlExternalSystemType && item.cdlExternalSystemType !== '') {
			indexToRemove.unshift(index);
		}
	});
	indexToRemove.forEach((index) => {
		const tmp: Field = savedObj.splice(index, 1)[0]!;
		retList.push(tmp);
	});
	return retList;
};

const removeMatchIds = (fieldsMapped: Field[]): Field[] => {
	const matched: Field[] = [];
	if (fieldsMapped) {
		fieldsMapped.forEach((fieldMapped, index) => {
			if (fieldMapped.isMatchField && fieldMapped.isMatchField === true) {
				matched.push({...fieldMapped});
				fieldsMapped.splice(index, 1);
			}
		});
	}
	return matched;
};

const updateFieldDate = (
	fieldMappedParam: Field,
	newTypeObj: Field,
	entity: string
): Field => {
	const fieldMapped = {...fieldMappedParam};
	if (newTypeObj.type) {
		if (newTypeObj.type !== 'DATE') {
			delete fieldMapped.dateFormatString;
			delete fieldMapped.timeFormatString;
			delete fieldMapped.timezone;
			fieldMapped.fieldType = newTypeObj.type;
		} else {
			fieldMapped.dateFormatString = newTypeObj.dateFormatString;
			fieldMapped.timeFormatString = newTypeObj.timeFormatString;
			fieldMapped.timezone = newTypeObj.timezone;
			fieldMapped.fieldType = newTypeObj.type;
		}
	} else {
		const newMapped = newTypeObj.mappedField;
		if (!newMapped) {
			return fieldMapped;
		}
		fieldMapped.dateFormatString = newTypeObj.dateFormatString;
		fieldMapped.timeFormatString = newTypeObj.timeFormatString;
		fieldMapped.timezone = newTypeObj.timezone;
		const toLattice = fieldMapped.mappedToLatticeField;
		if (toLattice === true) {
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			const field = ImportUtils.getFieldFromLaticeSchema(entity, newMapped);
			fieldMapped.fieldType = field?.fieldType;
		} else {
			// const {redux} = $state.get('home.import').data;
			const {redux} = NgState.getAngularState().get('home.import').data;
			const field = redux.store.fieldMappings.map[newMapped];
			if (field) {
				fieldMapped.fieldType = field.fieldType;
			}
		}
	}
	return fieldMapped;
};

const setMapping = (
	entity: string,
	savedObj: Field,
	fieldsMappedParam: Field[]
): Field[] => {
	const fieldsMapped = {...fieldsMappedParam};
	if (!savedObj) {
		return fieldsMapped;
	}
	const keysMapped = Object.keys(fieldsMapped);
	for (let i = 0; i < keysMapped.length; i++) {
		if (
			savedObj.mappedField === fieldsMapped[i]!.mappedField &&
			savedObj.userField !== fieldsMapped[i]!.userField
		) {
			fieldsMapped[i]!.mappedField = null;
			fieldsMapped[i]!.mappedToLatticeField = false;
			fieldsMapped[i]!.isMatchField = false;
		}
		if (savedObj.userField === fieldsMapped[i]!.userField) {
			fieldsMapped[i]!.mappedField = savedObj.mappedField;
			fieldsMapped[i]!.mappedToLatticeField = isFieldPartOfSchema(
				entity,
				savedObj.mappedField!
			);
			if (savedObj.cdlExternalSystemType) {
				fieldsMapped[i]!.cdlExternalSystemType = savedObj.cdlExternalSystemType;
			}
			if (savedObj.IdType || savedObj.SystemName) {
				fieldsMapped[i]!.idType = savedObj.IdType;
				fieldsMapped[i]!.systemName = savedObj.SystemName;
			}
			fieldsMapped[i]!.isMatchField = savedObj.isMatchField;
			fieldsMapped[i] = updateFieldDate(fieldsMapped[i]!, savedObj, entity);
		}
	}
	return fieldsMapped;
};

const updateUserFieldType = (
	fieldsMapped: Field[],
	userFieldName: string,
	newTypeObj: Field,
	entity: string
): void => {
	for (let i = 0; i < fieldsMapped.length; i++) {
		let fieldMapped = fieldsMapped[i];
		if (fieldMapped!.userField === userFieldName) {
			fieldMapped!.fieldType = newTypeObj.type;
			fieldMapped = updateFieldDate(fieldMapped!, newTypeObj, entity);
			return;
		}
	}
};

const ImportUtils = {
	getFieldFromLaticeSchema: (
		entity: string,
		name: string
	): Field | undefined => {
		const retEntity = latticeSchema[entity];
		const field = retEntity?.map[name];
		return field;
	},
	updateDocumentMapping: (
		entity: string,
		savedObj: Field[],
		fieldsMappingParam: Field[]
	): Field[] => {
		let fieldsMapping = [...fieldsMappingParam];
		if (savedObj && fieldsMapping) {
			fieldsMapping = updateUniqueIdsMapping(fieldsMapping, savedObj);
			const matched = removeMatchIds(fieldsMapping);

			removeOtherIds(fieldsMapping);

			const toUpdate = excludeOtherIds(savedObj);

			savedObj.forEach(function (field) {
				setMapping(entity, field, fieldsMapping);
			});

			const otherIdsEmpty = addOtherIdsEmpty(toUpdate);
			// const {redux} = $state.get('home.import').data;
			const {redux} = NgState.getAngularState().get('home.import').data;

			toUpdate.forEach((item, index) => {
				const field = redux.store.fieldMappings.map[item.userField];
				otherIdsEmpty[index]!.userField = item.userField;
				otherIdsEmpty[index]!.mappedField = item.mappedField;
				otherIdsEmpty[index]!.fieldType = field ? field.fieldType : 'TEXT';
				otherIdsEmpty[index]!.cdlExternalSystemType =
					item.cdlExternalSystemType;
				otherIdsEmpty[index]!.mappedToLatticeField = false;
			});

			/**
			 * Adds idType and systemName if added during field mapping
			 * IdType can be overridden below by templateData.ImportSystem.name
			 */
			fieldsMapping = fieldsMapping.map((field) => {
				const newField = {...field};
				const saved = savedObj.find(function (item) {
					return item.mappedField === field.mappedField;
				});
				if (saved) {
					newField.idType = saved.idType;
					newField.systemName = saved.systemName;
				}
				return newField;
			});

			// Check if two mappedFields have the same userField (user mapped field)
			// If it exists, override the field document mapping with the explicitly
			// selected mapping from the user mapped field.
			savedObj.forEach(function (field) {
				const found = fieldsMapping.find(function (mappedField) {
					return mappedField.userField === field.userField;
				});
				if (found) {
					found.idType = field.idType;
					found.mapToLatticeId = field.mapToLatticeId;
					found.mappedToLatticeField = Boolean(field.mappedToLatticeField);
					found.systemName = field.systemName;
				}
			});

			savedObj.forEach(function (field) {
				const found = fieldsMapping.find(function (mappedField) {
					return (
						mappedField.mappedField === field.mappedField &&
						mappedField.userField !== field.userField
					);
				});
				if (found) {
					found.mappedField = null;
					found.mappedToLatticeField = false;
				}
			});

			let ret = fieldsMapping;
			ret = ret.concat(matched);
			ret = ret.concat(otherIdsEmpty);

			const filtered = cleanDuplicates(ret);
			return filtered;
		}
		return fieldsMapping;
	},
	remapTypes: (
		fieldsMapped: Field[],
		newTypesObj: FieldObj,
		entity: string
	): void => {
		const userFields = Object.keys(newTypesObj);
		userFields.forEach(function (userFieldName) {
			updateUserFieldType(
				fieldsMapped,
				userFieldName,
				newTypesObj[userFieldName]!,
				entity
			);
		});
	},
	setLatticeSchema: (apiResult: LatticeSchemaApiResponse): void => {
		/**
		 * It sets the entities and all the fields associated with it
		 */
		cleanSchema();
		const entities = Object.keys(apiResult);
		entities.forEach(function (entity) {
			setFieldsInEntity(entity, apiResult[entity]!);
		});
	},
	getOriginalMapping: (
		entity: string,
		fieldMappingsApi: Fields
	): OriginalMapping => {
		const originalMapping: OriginalMapping = {
			fieldMappings: {
				entity,
				list: [],
				map: {},
			},
		};

		fieldMappingsApi.forEach((field) => {
			const {userField} = field;

			originalMapping.fieldMappings.list.push(field);
			originalMapping.fieldMappings.map[userField!] = field;
		});

		return originalMapping;
	},
	isFieldInSchema: (
		entity: string,
		fieldName: string,
		fieldsMapped: Field[]
	): boolean => {
		let inschema = false;
		const entityObj = latticeSchema[entity];
		if (entityObj && entityObj.map[fieldName]) {
			inschema = true;
		} else {
			inschema = isFieldMapped(entity, fieldName, fieldsMapped);
		}
		return inschema;
	},
	updateFormatSavedObj: (savedObj: FieldObj, field: Field): FieldObj => {
		const keysSaved = Object.keys(savedObj);
		keysSaved.forEach(function (keySaved) {
			const saved = savedObj[keySaved]!;
			if (saved.mappedField === field.name) {
				saved.dateFormatString = field.dateFormatString;
				saved.timeFormatString = field.timeFormatString;
				saved.timezone = field.timezone;
			}
		});
		return savedObj;
	},
};

export default ImportUtils;
