import RBAC, {
	AccessGroups,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {store} from 'store';
import {
	getDataCloudProperty,
	getDataCloudMetadataProperty,
	setMetadataPropertyValue,
} from 'common/stores/datacloud';
import {ROUTE_PAGE, getAdminRoute} from 'common/app/utilities/AdminPageUtility';
import template from './filters.component.html';
import {getDownloadCsvToken} from '../../datacloud.queries';
import {
	isEIFInboundEnabled,
	isEntityMatchEnabled,
} from 'common/stores/tenantConfig';
import {sanitizeRuleBuckets} from '../../segment/segment.helpers';
import {getRatingsEngineProperty} from '../../../../../atlas/app/stores/ratingsEngine';

export default function () {
	return {
		restrict: 'EA',
		scope: {
			vm: '=',
		},
		template,
		controller: function FiltersController(
			$scope,
			$state,
			$timeout,
			$interval
		) {
			'ngInject';

			const {vm} = $scope;

			angular.extend(vm, {
				orders: {
					attributeLookupMode: ['-Value', 'DisplayName'],
					attribute: ['-HighlightHighlighted', 'DisplayName'],
					subcategory: 'toString()',
					category: 'toString()',
				},
				download_button: {
					class: 'blue-button select-label',
					icon: 'fa fa-download',
					iconclass: 'white-button select-more',
					iconrotate: false,
					tooltip: 'Download Enrichments',
				},
				header: {
					sort_modeliteration: {
						label: 'Display Name',
						icon: 'numeric',
						property: 'DisplayName',
						order: '',
						items: [
							{
								label: 'Predictive Power',
								icon: 'numeric',
								property: 'PredictivePower',
								tooltip:
									'Attributes with more Predictive Power are better at differentiating your higher and lower converting Accounts. Predictive Power is based on the mutual information between an Attribute and your Model Event.',
							},
							{label: 'Display Name', icon: 'alpha', property: 'DisplayName'},
							{
								label: 'Feature Importance',
								icon: 'numeric',
								property: 'ImportanceOrdering',
							},
						],
					},
				},
				sortPrefix: '+',
				view: 'list',
				queryText: '',
				saved: false,
				hasDeleteAccessRights:
					['segment.analysis'].indexOf(vm.section) != -1
						? RBAC.userHasAccessLevel(AccessGroups.ADMINS)
						: false,
				disableDeleteData: !RBAC.hasAccess(
					RBACInterface.DELETE_DATA,
					RBACActions.VIEW
				),
				entityMatchEnabled: isEntityMatchEnabled(),
				deleteSref: getAdminRoute(ROUTE_PAGE.DELETE_NEW_DATA),
				importCalendarSref: getAdminRoute(ROUTE_PAGE.CONFIGURE_CALENDAR),
			});

			// remove highlighting
			if (!vm.showHighlighting()) {
				vm.orders.attribute = vm.orders.attribute.filter(function (item) {
					return (
						item != '-HighlightHighlighted' && item != 'HighlightHighlighted'
					);
				});
			}

			const clearFilters = function () {
				for (const i in vm.metadata.toggle) {
					for (const j in vm.metadata.toggle[i]) {
						vm.metadata.toggle[i][j] = '';
					}
				}
			};

			vm.startDownload = function (mode) {
				getDownloadCsvToken(mode).then(function (token) {
					window.open('/pls/filedownloads/' + token, '_blank');
				});
			};

			vm.init_filters = function () {
				vm.download_button.items = [
					{
						mode: 'all',
						label: vm.label.button_download,
						icon: 'fa fa-file-o',
					},
					{
						mode: 'selected',
						label: vm.label.button_download_selected,
						icon: 'fa fa-file-o',
					},
				];

				clearFilters();

				$scope.$watchGroup(
					[
						'vm.metadata.toggle.hide.highlighted',
						'vm.metadata.toggle.hide.selected',
						'vm.metadata.toggle.hide.premium',
						'vm.metadata.toggle.hide.enabled',
						'vm.metadata.toggle.show.nulls',
						'vm.metadata.toggle.show.selected',
						'vm.metadata.toggle.show.premium',
						'vm.metadata.toggle.show.internal',
						'vm.metadata.toggle.show.enabled',
						'vm.metadata.toggle.show.highlighted',
						'vm.metadata.toggle.show.selected_ratingsengine_attributes',
					],
					function () {
						vm.filterEmptySubcategories();
						vm.TileTableItems = {};
					}
				);

				$scope.$watchGroup(
					['vm.premiumSelectedTotal', 'vm.generalSelectedTotal'],
					function () {
						store.dispatch(
							setMetadataPropertyValue(
								'generalSelectedTotal',
								vm.generalSelectedTotal
							)
						);

						store.dispatch(
							setMetadataPropertyValue(
								'premiumSelectedTotal',
								vm.premiumSelectedTotal
							)
						);
					}
				);

				$scope.$watch('vm.queryText', function (newvalue) {
					vm.queryInProgress = true;

					if (vm.queryTimeout) {
						$timeout.cancel(vm.queryTimeout);
					}

					// debounce timeout to speed things up
					vm.queryTimeout = $timeout(function () {
						if (!vm.category && newvalue) {
							vm.setCategory(vm.categories[0]);
							vm.updateStateParams();
						}

						vm.query = vm.queryText;

						if (vm.section != 'browse') {
							vm.updateStateParams();
						}

						// maybe this will fix the issues where they dont drill down??
						$timeout(function () {
							const categories = Object.keys(vm.categoryCounts).filter(
								function (value) {
									return vm.categoryCounts[value] > 0;
								}
							);

							if (vm.section == 're.model_iteration') {
								vm.setCategory(categories[0]);
								vm.filterEmptySubcategories();
							}

							if (
								vm.category &&
								(categories.indexOf(vm.category) < 0 || categories.length == 1)
							) {
								vm.setCategory(categories[0]);
								vm.filterEmptySubcategories();
							}

							vm.queryInProgress = false;
						}, 100);

						vm.filterEmptySubcategories();
						vm.TileTableItems = {};
					}, 666);
				});

				store.dispatch(
					setMetadataPropertyValue(
						'premiumSelectLimit',
						(vm.EnrichmentPremiumSelectMaximum &&
							vm.EnrichmentPremiumSelectMaximum['HGData_Pivoted_Source']) ||
							10
					)
				);

				store.dispatch(
					setMetadataPropertyValue(
						'generalSelectLimit',
						vm.EnrichmentSelectMaximum || 100
					)
				);

				vm.premiumSelectLimit =
					getDataCloudMetadataProperty('premiumSelectLimit');
				vm.generalSelectLimit =
					getDataCloudMetadataProperty('generalSelectLimit');

				const find_dropdown_buttons = $interval(dropdown_buttons, 300);

				let find_dropdown_buttons_count = 0;

				function dropdown_buttons() {
					const buttons = angular.element('.dropdown-container > h2');

					find_dropdown_buttons_count++;

					if (buttons.length > 0 || find_dropdown_buttons_count > 5) {
						$interval.cancel(find_dropdown_buttons);
					}

					buttons.click(function (e) {
						const button = angular.element(this),
							toggle_on = !button.hasClass('active'),
							parent = button.closest('.dropdown-container');

						parent.removeClass('active');
						buttons.removeClass('selected');
						buttons.parents().find('.dropdown-container').removeClass('active');
						buttons.siblings('ul.dropdown').removeClass('open');

						if (toggle_on) {
							parent.addClass('active');
							button.addClass('active');
							button.siblings('ul.dropdown').addClass('open');
						}

						e.stopPropagation();
					});
				}

				angular.element(document).click(function (event) {
					const target = angular.element(event.target),
						el = angular.element(
							'.dropdown-container ul.dropdown, button ul.button-dropdown, .button ul.button-dropdown'
						),
						has_parent = target.parents().is('.dropdown-container'),
						parent = el.parents().find('.dropdown-container'),
						is_visible = el.is(':visible');

					if (!has_parent) {
						el.removeClass('open');
						parent.removeClass('active');
						el.siblings('.button.active').removeClass('active');
					}

					if (is_visible && !has_parent) {
						$scope.$digest(); //ben -- hrmmm, works for now
					}
				});

				if (vm.show_internal_filter) {
					/*
					 * this is the default for the internal filter
					 * this also effectivly hides internal attributes when the filter is hidden
					 */
					vm.metadata.toggle.show.internal = true;
				} else {
					vm.metadata.toggle.show.internal = false;
				}

				if (vm.section === 'insights') {
					/* hide disabled for sales team from iframe */
					vm.metadata.toggle.show.enabled = true;
				} else {
					vm.metadata.toggle.show.enabled = '';
				}
			};

			vm.hideMessage = function () {
				vm.saved = false;
			};

			vm.isFilterSelected = function () {
				return (
					(vm.section !== 'insights' && vm.metadata.toggle.show.enabled) ||
					vm.metadata.toggle.show.selected ||
					vm.metadata.toggle.hide.selected ||
					vm.metadata.toggle.show.premium ||
					vm.metadata.toggle.hide.premium ||
					vm.metadata.toggle.hide.enabled ||
					vm.metadata.toggle.show.highlighted ||
					vm.metadata.toggle.hide.highlighted ||
					vm.metadata.toggle.show.nulls ||
					vm.metadata.toggle.show.internal
				);
			};

			function handleFilterOrder(order, sortPrefixParam) {
				let sortPrefix = sortPrefixParam || vm.sortPrefix.replace('+', '');

				if (typeof order === 'object') {
					const sortArr = order,
						retArr = [];

					if (vm.section == 're.model_iteration') {
						sortPrefix = vm.header.sort_modeliteration.order.replace('+', '');

						const importance = sortPrefix + 'ImportanceOrdering';
						const nullImportance = '!ImportanceOrdering';
						const predictive = sortPrefix + 'PredictivePower';
						const nullPredictive = '!PredictivePower';
						const name = sortPrefix + 'DisplayName';

						if (
							vm.header.sort_modeliteration.property == 'ImportanceOrdering'
						) {
							retArr.push(nullImportance, importance, '');
						}
						if (vm.header.sort_modeliteration.property == 'PredictivePower') {
							retArr.push(nullPredictive, predictive, '');
						}
						if (vm.header.sort_modeliteration.property == 'DisplayName') {
							retArr.push(name);
						}
					} else {
						sortArr.forEach(function (item, index) {
							retArr[index] = (item == 'DisplayName' ? sortPrefix : '') + item;
						});
					}

					return retArr;
				}

				return sortPrefix + order;
			}

			vm.sortOrder = function sortOrder() {
				if (!vm.category) {
					return handleFilterOrder(vm.orders.category);
				}
				if (
					vm.subcategories[vm.category] &&
					vm.subcategories[vm.category].length &&
					!vm.subcategory
				) {
					return handleFilterOrder(vm.orders.subcategory);
				}
				if (
					(vm.lookupMode && vm.category === 'D&B Technology Profile') ||
					(vm.lookupMode && vm.category === 'HG Technology Profile') ||
					vm.category === 'Website Profile'
				) {
					return handleFilterOrder(vm.orders.attributeLookupMode);
				}
				return handleFilterOrder(vm.orders.attribute);
			};

			vm.enrichmentsFilter = () => {
				const filter = {};

				if (
					vm.metadata.toggle.show.selected &&
					!vm.metadata.toggle.hide.selected
				) {
					filter.IsSelected = true;
				}

				if (
					!vm.metadata.toggle.show.selected &&
					vm.metadata.toggle.hide.selected
				) {
					filter.IsSelected = false;
				}

				if (
					vm.metadata.toggle.show.premium &&
					!vm.metadata.toggle.hide.premium
				) {
					filter.IsPremium = true;
				}

				if (
					!vm.metadata.toggle.show.premium &&
					vm.metadata.toggle.hide.premium
				) {
					filter.IsPremium = false;
				}

				if (!vm.metadata.toggle.show.internal) {
					filter.IsInternal = false;
				}

				if (vm.subcategory) {
					filter.Subcategory = vm.subcategory;
				}

				if (vm.section === 'team' || vm.section === 'insights') {
					filter.HighlightHidden =
						(!vm.metadata.toggle.hide.enabled ? '' : true) ||
						(!vm.metadata.toggle.show.enabled ? '' : false);
					filter.HighlightHighlighted =
						(!vm.metadata.toggle.show.highlighted ? '' : true) ||
						(!vm.metadata.toggle.hide.highlighted ? '' : false);
				}

				if (vm.lookupMode && vm.isYesNoCategory(vm.category)) {
					filter.AttributeValue = !vm.metadata.toggle.show.nulls ? '!No' : '';
				}

				if (vm.section === 'wizard.ratingsengine_segment') {
					filter.IsRatingsEngineAttribute = vm.metadata.toggle.show
						.selected_ratingsengine_attributes
						? true
						: '';
				}

				const iterationFilter = getDataCloudProperty('ratingIterationFilter');
				if (iterationFilter === 'used') {
					filter.ImportanceOrdering = '!!';
				}

				if (iterationFilter === 'warnings') {
					filter.HasWarnings = '!!';
				}

				if (iterationFilter === 'disabled') {
					filter.ApprovedUsage = 'None';
				}

				return filter;
			};

			vm.subcategoryFilter = function (subcategory) {
				if (!vm.enrichments_completed) {
					return true;
				}
				const category = vm.category,
					count = vm.subcategoryCount(category, subcategory);

				return count ? true : false;
			};

			vm.goBackToModelRules = function () {
				sanitizeRuleBuckets(getRatingsEngineProperty('rule')?.rule, true);
				$state.go('home.ratingsengine.dashboard.segment.attributes.rules');
			};

			vm.showAttributeAdmin = function () {
				if (
					vm.section == 'insight' ||
					vm.section == 'wizard.ratingsengine_segment'
				) {
					return false;
				}

				return ['segment.analysis'].indexOf(vm.section) != -1 && !vm.inWizard;
			};
			vm.disableAttributeAdmin = !RBAC.hasAccess(
				RBACInterface.ATTR_ADMIN,
				RBACActions.VIEW
			);

			vm.showFileImport = function () {
				return (
					['segment.analysis'].indexOf(vm.section) != -1 &&
					!vm.inWizard &&
					RBAC.userHasAccessLevel(AccessGroups.ADMINS)
				);
			};
			vm.disableFileImport = !RBAC.hasAccess(
				RBACInterface.IMPORT_DATA,
				RBACActions.VIEW
			);

			vm.isMultiTemplates = () => isEntityMatchEnabled();

			vm.getImportState = () => {
				if (isEIFInboundEnabled()) {
					return getAdminRoute(ROUTE_PAGE.CONNECTORS);
				}

				if (isEntityMatchEnabled()) {
					return getAdminRoute(ROUTE_PAGE.IMPORT_DATA);
				}

				return 'home.importtemplates';
			};

			vm.disableConfigureAttributes = !RBAC.hasAccess(
				RBACInterface.CONFIGURE_ATTR,
				RBACActions.VIEW
			);

			vm.disableConfigureCalendar = !RBAC.hasAccess(
				RBACInterface.CONFIGURE_CALENDAR,
				RBACActions.VIEW
			);

			vm.handleSortOrderChange = function (newOrder) {
				vm.header.sort_modeliteration.order = newOrder;
				$scope.$apply();
			};

			vm.handleSortByChange = function (newProperty) {
				vm.header.sort_modeliteration.property = newProperty;
				$scope.$apply();
			};

			vm.init_filters();
		},
	};
}
