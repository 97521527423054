import React from 'common/react-vendor';
import {
	DNBDataTable,
	DNBTextField,
	DNBTypography,
	GridColDef,
	GridRowSelectionModel,
	InputAdornment,
	SearchOutlinedIcon,
} from 'common/dnb-uux-vendor';
import {Spinner} from 'common/widgets/spinner';
import {
	accountLimitMessage,
	defaultBaseTotal,
	selectedLimitNumber,
	SegmentColumnUIConfig,
	selectLimitMessage,
	CommonHeaderMessageConfig,
} from '../configs/PersonalizationConst';
import {
	CommonHeaderMessage,
	CommonHeaderSize,
	HeaderType,
} from '../configs/PersonalizationTypes';
import {LimitWarningBanner} from './PersonalizationBanner';
import styles from './PersonalizationComponent.module.scss';
import {usePersonalization} from '../hook/usePersonalization';
import CommonHeader from './CommonHeader';

const PersonalizationSegment = (): React.ReactElement => {
	const {
		context: personalizationContext,
		setContext: personalizationUpdateContext,
	} = usePersonalization();
	const {workflowDataList, isLoading, total, selectionModel} =
		personalizationContext;
	const {setSelectionModel} = personalizationUpdateContext;

	const {useState, useMemo, useDeferredValue} = React;
	const [search, setSearch] = useState('');
	const commonColumns = SegmentColumnUIConfig as GridColDef[];
	const deferSearchValue = useDeferredValue(search);
	const showSelectLimitBanner = selectionModel.length > selectedLimitNumber;
	const showAccountLimitBanner = total > defaultBaseTotal;

	const commonRows = useMemo(() => {
		return workflowDataList
			.filter((item) => {
				return item?.listSegment?.systemType !== 'SSVI';
			})
			.map(
				({
					name,
					display_name,
					accounts,
					contacts,
					team,
					teamId,
					status,
					type,
					dataType,
					lastUpdated,
				}) => {
					const {TeamName} = team;
					return {
						id: name,
						name,
						display_name,
						accounts,
						contacts,
						teamId,
						TeamName,
						status,
						type,
						dataType,
						lastUpdated,
					};
				}
			)
			.filter((item) => {
				const value = item.display_name;
				return value.toLowerCase().includes(deferSearchValue.toLowerCase());
			});
	}, [workflowDataList, deferSearchValue]);

	const onSelectionModelChange = (
		selectionModel: GridRowSelectionModel
	): void => {
		setSelectionModel(selectionModel);
	};

	return (
		<div className={styles.personalizationSegment}>
			<CommonHeader
				headerType={HeaderType.Steps}
				size={CommonHeaderSize.CompactBody}
				title={CommonHeaderMessageConfig[CommonHeaderMessage.Segment].title}
				description={
					CommonHeaderMessageConfig[CommonHeaderMessage.Segment].description
				}
			/>
			<div className={styles.segmentTitle}>
				<div className={styles.segmentTitleLeft}>
					<div className={styles.segmentTitleRightNumber}>
						<DNBTypography
							variant='h5'
							sx={{
								color: (theme) =>
									selectionModel.length > selectedLimitNumber
										? theme.colors.ColorScaleHigh
										: theme.colors.ColorPrimaryBlueInteractive,
								mr: 1,
							}}>
							{selectionModel.length}
						</DNBTypography>
						<DNBTypography variant='compact-body' sx={{mr: 1}}>
							/10
						</DNBTypography>
						<DNBTypography variant='compact-body' sx={{mr: 4}}>
							Total Segments
						</DNBTypography>
						<DNBTypography
							variant='h5'
							sx={{
								color: (theme) =>
									total > defaultBaseTotal
										? theme.colors.ColorScaleHigh
										: theme.colors.ColorPrimaryBlueInteractive,
							}}>
							{Intl.NumberFormat('en-US').format(total)}
						</DNBTypography>
						<DNBTypography variant='compact-body' sx={{mr: 1}}>
							/{Intl.NumberFormat('en-US').format(defaultBaseTotal)}
						</DNBTypography>
						<DNBTypography variant='compact-body'>
							Total Accounts (DUNS)
						</DNBTypography>
					</div>
				</div>
				<div className={styles.segmentTitleRight}>
					<div className={styles.segmentTitleSearch}>
						<DNBTextField
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchOutlinedIcon fontSize='small' />
									</InputAdornment>
								),
							}}
							sx={{width: '240px', ml: 2}}
							value={search}
							size='compact'
							placeholder='What are you looking for?'
							onChange={(e) => setSearch(e.target.value)}
						/>
					</div>
				</div>
			</div>
			{showSelectLimitBanner && (
				<LimitWarningBanner tipsBannerMessage={selectLimitMessage} />
			)}
			{showAccountLimitBanner && (
				<LimitWarningBanner tipsBannerMessage={accountLimitMessage} />
			)}
			{isLoading ? (
				<Spinner />
			) : (
				<DNBDataTable
					checkboxSelection
					pagination
					zebraStripes={false}
					hideFooterSelectedRowCount
					keepNonExistentRowsSelected
					columns={commonColumns}
					rows={commonRows}
					disableSorting={true}
					disableColumnReorder={true}
					rowSelectionModel={selectionModel}
					onRowSelectionModelChange={(selectionModel) => {
						onSelectionModelChange(selectionModel);
					}}
					isRowSelectable={({row}) =>
						!(
							row.status === 'Initialized' ||
							row.accounts === 0 ||
							row.type !== 'Query'
						)
					}
				/>
			)}
		</div>
	);
};

export default PersonalizationSegment;
