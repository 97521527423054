import LocalStorageUtility from 'common/widgets/utilities/local-storage.utility';
import {Logout} from 'common/app/services/LoginService';
import NgState from '../../ng-state';
import {setHistory} from './login.service';
import {Banner} from 'common/app/services/BannerService';

angular
	.module('login', [])
	.run(function () {
		Banner.reset();
	})
	.config(function ($stateProvider) {
		$stateProvider
			.state('login', {
				url: '/',
				params: {
					disableUserInfo: true,
					disableLogoArea: false,
				},
				onEnter: ($state) => {
					setHistory();
					NgState.setAngularState($state);
				},
				resolve: {
					logindocument() {
						return LocalStorageUtility.getLoginDocument() || {};
					},
					clientsession() {
						return LocalStorageUtility.getClientSession() || {};
					},
					params($stateParams) {
						return $stateParams;
					},
				},
				views: {
					notice: 'noticeMessage',
					main: 'loginFrame',
				},
			})
			.state('login.form', {
				url: 'form',
				params: {
					obj: {},
				},
				resolve: {
					logindocument() {
						return LocalStorageUtility.getLoginDocument() || {};
					},
				},
				views: {
					banner: 'bannerMessage',
					FrameContent: 'loginForm',
				},
			})
			.state('login.tenants', {
				url: 'tenants',
				params: {
					obj: {},
					disableUserInfo: false,
				},
				views: {
					banner: 'bannerMessage',
					FrameContent: 'loginTenants',
				},
			})
			.state('login.forgot', {
				url: 'forgot',
				views: {
					banner: 'bannerMessage',
					FrameContent: 'loginForgotPassword',
				},
			})
			.state('login.error', {
				url: 'error',
				params: {
					disableUserInfo: true,
					disableLogoArea: false,
				},
				views: {
					banner: 'bannerMessage',
					FrameContent: 'loginSamlError',
				},
			})
			.state('login.saml', {
				url: 'saml/:tenantId',
				params: {
					disableUserInfo: false,
					disableLogoArea: true,
				},
				views: {
					banner: 'bannerMessage',
					FrameContent: 'loginSaml',
				},
			})
			.state('login.saml_logout', {
				url: 'saml/:tenantId/logout',
				params: {
					disableUserInfo: true,
					disableLogoArea: false,
				},
				views: {
					banner: 'bannerMessage',
					FrameContent: 'loginSamlLogout',
				},
			})
			.state('login.saml_error', {
				url: 'saml/:tenantId/error',
				params: {
					disableUserInfo: true,
					disableLogoArea: false,
				},
				views: {
					banner: 'bannerMessage',
					FrameContent: 'loginSamlError',
				},
			})
			.state('login.logout', {
				url: 'logout',
				resolve: {
					logindocument() {
						return Logout();
					},
				},
			})
			.state('login.passwordless', {
				url: 'passwordless',
				views: {
					banner: 'bannerMessage',
					FrameContent: {component: 'passwordless'},
				},
			})
			.state('login.idaasResetPasswordMessage', {
				url: 'idaas-reset-password-message',
				views: {
					banner: 'bannerMessage',
					FrameContent: 'idaasResetPasswordMessage',
				},
			})
			.state('login.idaasForgotPasswordMessage', {
				url: 'idaas-forgot-password-message',
				views: {
					banner: 'bannerMessage',
					FrameContent: 'idaasForgotPasswordMessage',
				},
			})
			.state('login.idaasLoginWithDnbCredentialsMessage', {
				url: 'idaas-login-with-dnb-creds',
				views: {
					banner: 'bannerMessage',
					FrameContent: 'idaasLoginWithDnbCredentialsMessage',
				},
			});
	});
