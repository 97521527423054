import CryptoJS from 'crypto-js';
import {isEmpty} from 'lodash';

enum EncryptionStorageKeys {
	TOKEN = 'GriotTokenDocument',
	ENCRYPTION_STORAGE_PHRASE = 'GriotStorageKeyDocument',
}

const getPassphrase = (): string => {
	const getStorageItem = (key: string): string | null =>
		window.localStorage.getItem(key);

	let passphrase =
		getStorageItem(EncryptionStorageKeys.TOKEN) ||
		getStorageItem(EncryptionStorageKeys.ENCRYPTION_STORAGE_PHRASE) ||
		'';

	if (isEmpty(passphrase)) {
		const newEncryptionKey = Math.random().toString(16).substr(2, 16);

		passphrase = newEncryptionKey;
		window.localStorage.setItem(
			EncryptionStorageKeys.ENCRYPTION_STORAGE_PHRASE,
			newEncryptionKey
		);
	}

	return passphrase;
};

const encryptJSONData = <T>(data: T | string): string => {
	try {
		const stringifyData = JSON.stringify(data);
		const passphrase = getPassphrase();

		const encryptedData = CryptoJS.Rabbit.encrypt(stringifyData, passphrase);

		return encryptedData.toString();
	} catch (error) {
		if (typeof data === 'string') {
			return data;
		}
	}
	return '';
};

const decryptJSONData = <T>(encryptedData: string | T): T | null => {
	if (typeof encryptedData !== 'string') {
		return encryptedData;
	}

	try {
		const passphrase = getPassphrase();

		const decryptedData = CryptoJS.Rabbit.decrypt(encryptedData, passphrase);

		const stringData = decryptedData.toString(CryptoJS.enc.Utf8) || null;
		if (stringData) {
			return JSON.parse(stringData);
		}
		return null;
	} catch (error) {
		console.warn(error);

		if (typeof encryptedData === 'string') {
			return null;
		}
		return JSON.parse(encryptedData);
	}
};

export {encryptJSONData, decryptJSONData};
