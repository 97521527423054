import {v4 as uuidv4} from 'uuid';

/**
 * Class for the creation of the Observer
 */
export default class Observer {
	constructor(next, error) {
		this.name = uuidv4();

		if (next) {
			this.next = next;
		} else {
			this.next = (response) => {
				console.log('Observable with next method not implemented', response);
			};
		}

		if (error) {
			this.error = error;
		} else {
			this.error = (error) => {};
		}
	}

	getName() {
		return this.name;
	}
}
