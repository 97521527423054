/* granted rights types */
export enum Service {
	Account_360 = 'Account_360',
	Data_Stewardship = 'Data_Stewardship',
	DCP_Projects = 'DCP_Projects',
	Campaigns = 'PLS_Campaigns',
	CDL_Data = 'PLS_CDL_Data',
	Configurations = 'PLS_Configurations',
	CrmCredential = 'PLS_CrmCredential',
	Data = 'PLS_Data',
	Jobs = 'PLS_Jobs',
	MarketoCredentials = 'PLS_MarketoCredentials',
	MarketoCredentials_Simplified = 'PLS_MarketoCredentials_Simplified',
	Models = 'PLS_Models',
	Oauth2Token = 'PLS_Oauth2Token',
	Oauth2Token_External = 'PLS_Oauth2Token_External',
	Plays = 'PLS_Plays',
	Quotas = 'PLS_Quotas',
	RatingEngines = 'PLS_RatingEngines',
	Refine_Clone = 'PLS_Refine_Clone',
	Remodel = 'PLS_Remodel',
	Reporting = 'PLS_Reporting',
	Reports = 'PLS_Reports',
	Sample_Leads = 'PLS_Sample_Leads',
	Spec = 'PLS_Spec',
	SSO_Config = 'PLS_SSO_Config',
	TargetMarkets = 'PLS_TargetMarkets',
	Teams = 'PLS_Teams',
	Users = 'PLS_Users',
}
export enum Right {
	Create = 'MayCreate',
	Edit = 'MayEdit',
	Execute = 'MayExecute',
	View = 'MayView',
}

/* RBAC Types */
export enum RBACInterface {
	MY_DATA = 'MY_DATA',
	ATTRIBUTES_TAB = 'ATTRIBUTES_TAB',
	SEGMENTS = 'SEGMENTS',
	MODELS = 'MODELS',
	CAMPAIGNS = 'CAMPAIGNS',
	CONNECTORS = 'CONNECTORS',
	REPORTING = 'REPORTING',
	IMPORT_DATA = 'IMPORT_DATA',
	DELETE_DATA = 'DELETE_DATA',
	JOBS = 'JOBS',
	MANAGE_USERS = 'MANAGE_USERS',
	MANAGE_TEAMS = 'MANAGE_TEAMS',
	SSO_SETTINGS = 'SSO_SETTINGS',
	ATTR_ADMIN = 'ATTR_ADMIN',
	CONFIGURE_ATTR = 'CONFIGURE_ATTR',
	CONFIGURE_CALENDAR = 'CONFIGURE_CALENDAR',
	REVUP_MODULE_NAV = 'REVUP_MODULE_NAV',
	CUSTOM_ACCOUNT_SRC = 'CUSTOM_ACCOUNT_SRC',
	EIF_INBOUND_CONNECTIONS = 'EIF_INBOUND_CONNECTIONS',
	EIF_INBOUND_SOURCES = 'EIF_INBOUND_SOURCES',
	EIF_CDP_SCHEMA = 'EIF_CDP_SCHEMA',
	CUSTOM_CONTACT_SRC = 'CUSTOM_CONTACT_SRC',
	SETTINGS_DROPDOWN = 'SETTINGS_DROPDOWN',
	MARKETPLACE_SEGMENTS = 'MARKETPLACE_SEGMENTS',
	ADMIN_MENU = 'ADMIN_MENU',
	PERSONALIZATION = 'PERSONALIZATION',
	BUYER_JOURNEY_ADMIN = 'BUYER_JOURNEY_ADMIN',
}

export enum RBACActions {
	VIEW = 'VIEW',
	DRILL = 'DRILL',
	EDIT = 'EDIT',
	CREATE = 'CREATE',
}

export enum RBACCampaignTags {
	Adobe_Audience_Mgr = 'Adobe_Audience_Mgr',
	AppNexus = 'AppNexus',
	AWS_S3 = 'AWS_S3',
	Eloqua = 'Eloqua',
	DataVision = 'DataVision',
	Facebook = 'Facebook',
	Google_Display_N_Video_360 = 'Google_Display_N_Video_360',
	GoogleAds = 'GoogleAds',
	LinkedIn = 'LinkedIn',
	Marketo = 'Marketo',
	MediaMath = 'MediaMath',
	Outreach = 'Outreach',
	Pardot = 'Pardot',
	Salesforce = 'Salesforce',
	TradeDesk = 'TradeDesk',
	Vendemore = 'Vendemore',
	Verizon_Media = 'Verizon_Media',
	SalesforceMarketingCloud = 'SalesforceMarketingCloud',
}

export enum RBACModelTags {
	FirstPurchase = 'FirstPurchase',
	RuleBased = 'RuleBased',
	RepeatPurchase = 'RepeatPurchase',
	CustomEvent = 'CustomEvent',
}

export enum RBACJobTags {
	PA = 'PA',
	Model = 'Model',
	OrphanExport = 'OrphanExport',
	SegmentExport = 'SegmentExport',
}

export enum PredefinedRoles {
	SALES = 'SALES',
	EXTERNAL_USER = 'EXTERNAL_USER',
	EXTERNAL_ADMIN = 'EXTERNAL_ADMIN',
	INTERNAL_USER = 'INTERNAL_USER',
	INTERNAL_ADMIN = 'INTERNAL_ADMIN',
	SUPER_ADMIN = 'SUPER_ADMIN',
}
