import {IRelationShipAttribute} from 'atlas/connectors/EIF/RelationShipSetting/Components/RelationShipGroupList';
import {ObjectType} from '../../ConnectionType';
import {DocumentType} from '../../DocumentType';
import {FieldType} from '../../FieldType';
import {ImportStatus} from '../../ImportType';
import {SystemType} from '../../SystemType';
import {ICommonResponse} from '../FE/CommonStatusResponse';
import {
	IImportSourceResponse,
	IImportSourceV2Response,
} from '../FE/ImportSourceResponse';
import {ICdpMappingConfig} from './GetSourcePerConnection';
import {IInboundSourceInfo} from './InboundSource';
import {
	toGetRelationShipListFEResponse,
	toGetSourceMatchConfigBEResponse,
	toGetSourceMatchConfigFEResponse,
	toUpdateRelationShipListRequestBody,
} from './RelationShip';

const getSourceByIdUrl = (id: string): string => {
	return `/pls/inboundsource/summary/${id}`;
};

enum SourceStatus {
	Pause = 'Pause',
	Active = 'Active',
}

type ISourceStatusConfig = {
	[key in SourceStatus]: boolean;
};

const SourceStatusConfig: ISourceStatusConfig = {
	[SourceStatus.Pause]: false,
	[SourceStatus.Active]: true,
};

interface IGetSourcePerConnectionItem extends ObjectType {
	cdpMappingConfig: ICdpMappingConfig;
	channelName: string;
	connectionName: string;
	connectionDisplayName: string;
	connectionType: SystemType;
	created: number;
	createdBy: string;
	dataFeedTaskUniqueId: string;
	displayName: string;
	documentType: DocumentType;
	importStatus: ImportStatus;
	lastImportDate: number;
	priority: number;
	s3Path: string;
	schemaType: DocumentType;
	selectedObjects: string[];
	sourceStatus: SourceStatus;
	updated: number;
	updatedBy: string;
	entityRelationConfirmed: boolean;
	sourceCdpAttributeDisplayName: string;
	sourceCdpAttributeName: string;
}

interface IGetSourceByIdResponse extends ObjectType {
	Errors?: string[];
	Success: boolean;
	Result: IGetSourcePerConnectionItem;
}

const toImportSourceById = ({
	lastImportDate,
	createdBy,
	dataFeedTaskUniqueId,
	displayName,
	documentType,
	updated,
	updatedBy,
	connectionName,
	connectionDisplayName,
	connectionType,
	cdpMappingConfig,
	s3Path,
	sourceStatus,
	schemaType,
	importStatus,
	entityRelationConfirmed,
}: IGetSourcePerConnectionItem): IImportSourceResponse => {
	return {
		[FieldType.SourceId]: dataFeedTaskUniqueId,
		[FieldType.Enabled]: SourceStatusConfig[sourceStatus],
		[FieldType.Source]: displayName,
		[FieldType.SystemId]: connectionName,
		[FieldType.SystemName]: connectionDisplayName,
		[FieldType.SystemType]: connectionType,
		[FieldType.LastImportTime]: lastImportDate,
		[FieldType.LastImportStatus]: importStatus || ImportStatus.None,
		[FieldType.CDPObject]: schemaType,
		[FieldType.Entity]: documentType,
		[FieldType.CreatedBy]: createdBy || '',
		[FieldType.LastUpdated]: updated,
		[FieldType.LastUpdatedBy]: updatedBy || '',
		[FieldType.UniqueID]: cdpMappingConfig.uniqueId || '',
		[FieldType.UniqueIDDisplayName]: cdpMappingConfig.uniqueId
			? cdpMappingConfig.cdpAttributeMappings.find(
					({cdpAttributeName}) => cdpAttributeName === cdpMappingConfig.uniqueId
			  )?.displayName || ''
			: '',
		[FieldType.S3Location]: s3Path,
		[FieldType.IsEntityRelationConfirmed]: entityRelationConfirmed,
		[FieldType.IsEmptySource]: !cdpMappingConfig?.cdpAttributeMappings?.length,
		[FieldType.IsNoDataSource]:
			connectionType === SystemType.AWS_S3 &&
			!lastImportDate &&
			sourceStatus !== SourceStatus.Pause,
	};
};

const toImportSourceResponseById = (
	response: IGetSourceByIdResponse
): IImportSourceResponse => {
	const {Result} = response;
	return toImportSourceById(Result);
};

const toImportSourceResponseByIdV2 = ({
	Result: {
		entityRelationRequest,
		inboundSourceRequest,
		sourceEntityRelation: {sourceRelations},
		sourceMatchConfig = {},
		sourceUniqueId,
	},
}: ICommonResponse<IInboundSourceInfo>): IImportSourceV2Response => {
	const {account_match_columns, contact_match_columns} = sourceMatchConfig;
	return {
		entityRelationRequest,
		inboundSourceRequest,
		sourceEntityRelation: toGetRelationShipListFEResponse({
			Result: {sourceRelations},
		}),
		sourceMatchConfig: toGetSourceMatchConfigFEResponse({
			Result: {account_match_columns, contact_match_columns},
		}),
		sourceUniqueId,
	} as IImportSourceV2Response;
};

const toUpdateSourceInfoRequest = ({
	entityRelationRequest,
	inboundSourceRequest,
	sourceEntityRelation,
	sourceMatchConfig,
	sourceUniqueId,
}: IImportSourceV2Response): IInboundSourceInfo => {
	return {
		entityRelationRequest,
		inboundSourceRequest,
		sourceEntityRelation: sourceEntityRelation
			? toUpdateRelationShipListRequestBody(
					sourceEntityRelation as IRelationShipAttribute[]
			  )
			: undefined,
		sourceMatchConfig: sourceMatchConfig
			? toGetSourceMatchConfigBEResponse(sourceMatchConfig || [])
			: undefined,
		sourceUniqueId,
	} as IInboundSourceInfo;
};

export type {IGetSourceByIdResponse, IGetSourcePerConnectionItem};
export {
	getSourceByIdUrl,
	toImportSourceById,
	toImportSourceResponseById,
	toImportSourceResponseByIdV2,
	toUpdateSourceInfoRequest,
	SourceStatus,
};
