import React from 'common/react-vendor';
import cx from 'classnames';
import styles from './index.module.css';

const {useRef, useLayoutEffect} = React;

export interface CheckboxProps {
	checked: boolean;
	onChange?: (nextVal: boolean) => void;
	disabled?: boolean;
	indeterminate?: boolean;
	label?: React.ReactNode;
	id?: string;
	className?: string;
}

export function Checkbox({
	checked,
	onChange,
	disabled = false,
	indeterminate = false,
	label,
	id,
	className,
}: CheckboxProps): JSX.Element {
	// Used for syncing indeterminate since react doesn't support it
	const ref = useRef<HTMLInputElement | null>(null);
	useLayoutEffect(() => {
		if (ref.current) ref.current.indeterminate = indeterminate;
	}, [indeterminate]);

	return (
		<>
			<input
				id={id}
				type='checkbox'
				checked={checked}
				onChange={(e) => {
					try {
						onChange?.(e.target.checked);
					} finally {
						// See https://html.spec.whatwg.org/multipage/input.html#the-input-element:attr-input-type-31
						// When the user interacts with the checkbox, indeterminate will be
						// set to false by the browser. To implement a controlled indeterminate
						// state, we need to replicate what react-dom does for the value prop
						// This is only done after running `onChange`, so that the handler can
						// access the `node.indeterminate` property as set by the browser
						e.target.indeterminate = indeterminate;
					}
				}}
				disabled={disabled}
				ref={ref}
				className={
					className ??
					cx(
						styles.checkbox,
						(indeterminate && styles.indet) || (checked && styles.checked)
					)
				}
			/>

			{label ? (
				<label
					htmlFor={id}
					onClick={(e) => {
						e.preventDefault();
					}}>
					{label}
				</label>
			) : null}
		</>
	);
}
