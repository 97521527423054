import React from 'common/react-vendor';
import {DNBTypography} from 'common/dnb-uux-vendor';
import styles from './PersonalizationComponent.module.scss';

export default function PersonalizationProcessing(): React.ReactElement {
	return (
		<div className={styles.personalizationProcessing}>
			<DNBTypography
				variant='h4'
				sx={{color: (theme) => theme.colors.ColorPrimaryBlue, mb: 3}}>
				Please check back later.
			</DNBTypography>
			<DNBTypography variant='subtitle2' sx={{mb: 18}}>
				We are currently processing your selected attributes and it may take up
				to 20 minutes.
			</DNBTypography>
			<img src='/assets/images/personalization/checkBack.svg' alt='' />
		</div>
	);
}
