import React from 'common/react-vendor';
import {ActionButton, IActionButton} from '../Component/ActionColumnItem';
import styles from '../ConnectionTableItem.module.scss';

const IconTipHeaderComponent = ({
	id,
	label,
}: IActionButton): React.ReactElement => {
	return (
		<div className={styles.NameTableHeaderComponent}>
			<span className={styles.Text} title={label}>
				{label}
			</span>
			<ActionButton id={id} />
		</div>
	);
};

export {IconTipHeaderComponent};
