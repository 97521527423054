import React from 'common/react-vendor';
import {
	DNBDateRangePicker,
	DNBTooltip,
	DNBTextField,
} from 'common/dnb-uux-vendor';

import {shortenValue} from 'widgets/filters/range-slider/le-numeric-shortened-input';
import {filter, UseTransformation} from '../pipeline';
import './index.scss';
import '../../filters/range-slider/le-range-slider.scss';
import '../../filters/date-range/le-date-range.scss';

const {useMemo, useCallback} = React;
type DefinedNumericRangeState = {
	low: number;
	high: number;
};
export type NumericRangeState = DefinedNumericRangeState | null;

interface NumericRangeProps {
	value: NumericRangeState;
	min: number;
	max: number;
	onChange: (newState: NumericRangeState) => void;
}

interface INumericShortenedInputUUX {
	value: number;
	name?: string;
	className?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const NumericShortenedInputUUX = ({
	value,
	name,
	className,
	onChange,
	onBlur,
}: INumericShortenedInputUUX): JSX.Element => {
	const [focused, setFocused] = React.useState(false);
	const shortValue = shortenValue(value);

	const handleOnBlur: React.FocusEventHandler<
		HTMLInputElement | HTMLTextAreaElement
	> = (event) => {
		setFocused(false);
		if (typeof onBlur === 'function') {
			onBlur(event);
		}
	};
	const handleOnFocus = (): void => setFocused(true);

	return (
		<DNBTextField
			size='small'
			type={focused ? 'number' : `string`}
			name={name}
			value={focused ? value : shortValue}
			className={className}
			onFocus={handleOnFocus}
			onChange={onChange}
			onBlur={handleOnBlur}
		/>
	);
};

export function NumericRange({
	value,
	min,
	max,
	onChange,
}: NumericRangeProps): JSX.Element {
	// If value is null, the entire range is selected (i.e. low & high handles are at the extremes)
	const state = useMemo(
		() => value || {low: min, high: max},
		[value, max, min]
	);
	const {low, high} = state;

	return (
		<div className='numeric-range-container'>
			<span className='numeric-range-input value-low'>
				<NumericShortenedInputUUX
					value={low}
					onChange={(e) => {
						const low = +e.target.value;
						if (low >= min) onChange({...state, low});
					}}
				/>
			</span>
			<span className='numeric-range-slider'>to</span>
			<span className='numeric-range-input value-high'>
				<NumericShortenedInputUUX
					value={high}
					onChange={(e) => {
						const high = +e.target.value;
						if (high <= max) onChange({...state, high});
					}}
				/>
			</span>
		</div>
	);
}

export interface IDateRangeProps {
	value: NumericRangeState;
	onChange: (newVal: NumericRangeState) => void;
	min: number;
	max: number;
	tooltip?: string;
	size?: 'small' | 'default';
	DataRangeVariant?: 'single' | 'double';
}

export const formatMinTime = (time: number): number =>
	new Date(time).setHours(0, 0, 0, 0);
export const formatMaxTime = (time: number): number =>
	new Date(time).setHours(23, 59, 59, 59);

const DateRangeComp = ({
	value,
	tooltip,
	onChange,
	min,
	max,
	size = 'default',
	DataRangeVariant = 'double',
}: IDateRangeProps): JSX.Element => {
	const {min: finalMin, max: finalMax} = React.useMemo(
		() => ({
			max: max > 0 && (!min || max >= min) ? formatMaxTime(max) : undefined,
			min: min > 0 && (!max || max >= min) ? formatMinTime(min) : undefined,
		}),
		[min, max]
	);
	const {low: curLow, high: curHigh} = value ?? {
		low: finalMin,
		high: finalMax,
	};

	const [low, setLow] = React.useState<number | undefined>(curLow);
	const [high, setHigh] = React.useState<number | undefined>(curHigh);
	const [doesOpen, setDoesOpen] = React.useState(false);
	const [renderKey, setRenderKey] = React.useState(1);

	const resetCalender = useCallback(
		(newLow: number | undefined, newHigh: number | undefined): void => {
			setLow(newLow);
			setHigh(newHigh);

			setRenderKey((value) => value + 1);
		},
		[]
	);

	React.useEffect(() => {
		resetCalender(curLow, curHigh);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [curLow, curHigh]);

	const isValidDate = useCallback(
		(time: number | undefined): boolean =>
			!!time &&
			(finalMin || -Infinity) <= time &&
			time <= (finalMax || Infinity),
		[finalMin, finalMax]
	);

	const handleChange = useCallback(
		(date: Date | null, type: 'low' | 'high') => {
			if (type === 'low')
				setLow(date ? formatMinTime(date.getTime()) : undefined);
			if (type === 'high')
				setHigh(date ? formatMaxTime(date.getTime()) : undefined);
		},
		[]
	);

	const handleRender = useCallback(
		(high: number | undefined, low: number | undefined) => {
			if (curHigh === high && curLow === low) return;
			const newLow =
				isValidDate(low) && low! <= high! ? low : finalMin || curLow;
			const newHigh = isValidDate(high) ? high : finalMax || curHigh;
			onChange({
				low: newLow!,
				high: newHigh!,
			});
			resetCalender(newLow, newHigh);
		},
		[curHigh, curLow, isValidDate, finalMin, finalMax, onChange, resetCalender]
	);

	React.useEffect(() => {
		if (!doesOpen) {
			handleRender(high, low);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [high, low, doesOpen]);

	const getLimitDate = (time?: number): Date | undefined =>
		time
			? new Date(new Intl.DateTimeFormat().format(new Date(time)))
			: undefined;

	return (
		<>
			<DNBTooltip arrow content={tooltip || ''} placement='top'>
				<span className={`numericRange-date-wrap ${size}`}>
					<DNBDateRangePicker
						label=''
						size='compact'
						variant={DataRangeVariant}
						startValue={getLimitDate(low) || null}
						endValue={getLimitDate(high) || null}
						showToday
						onClose={() => setDoesOpen(false)}
						onOpen={() => setDoesOpen(true)}
						onStartChange={(value: Date | null) => handleChange(value, 'low')}
						onEndChange={(value: Date | null) => handleChange(value, 'high')}
						key={`dateRangePicker_${renderKey}`}
						maxDate={getLimitDate(finalMax)}
						minDate={getLimitDate(finalMin)}
					/>
				</span>
			</DNBTooltip>
		</>
	);
};

export const DateRange = React.memo(
	DateRangeComp,
	(prevProps, nextProps) =>
		JSON.stringify(prevProps?.value) === JSON.stringify(nextProps?.value) &&
		prevProps.min === nextProps.min &&
		prevProps.max === nextProps.max &&
		prevProps.onChange === nextProps.onChange
);

export const getDayInclusiveTimeRange = (
	timeRange?: NumericRangeState
): DefinedNumericRangeState => {
	let high = new Date();
	let low = new Date(high.getTime());
	low.setMonth(high.getMonth() - 1);
	// return timeRange if passed, otherwise return past month timeRange
	if (timeRange && timeRange.low && timeRange.high) {
		low = new Date(timeRange.low);
		high = new Date(timeRange.high);
	}
	low.setHours(0, 0, 0, 0);
	high.setHours(23, 59, 59, 59);
	return {
		low: low.getTime(),
		high: high.getTime(),
	};
};

export function numericRange<Item>(
	getValue: (i: Item) => number,
	exclusive = false
): UseTransformation<NumericRangeState, Item[], Item[]> {
	return filter((state) => (i) => {
		if (!state) return true;
		const {low, high} = state;
		const cur = getValue(i);
		if (!exclusive) return low <= cur && cur <= high;
		return low < cur && cur < high;
	});
}
