/**
 * TODO: Delete this angular file once all components that uses transactionEditDirective gets refactor.
 * TODO: DELETE this file once there are no more instances of <transaction-edit-directive />.
 */

import {react2angular} from 'common/react-vendor';
import {TransactionEditItemWithAngularProps} from './TransactionEditItem/TransactionEditItem';

angular
	.module('common.datacloud.query.builder.tree.edit.transaction.edit', [])
	.component(
		'transactionEditDirective',
		react2angular(TransactionEditItemWithAngularProps, [
			'bucketrestriction',
			'type',
			'purchased',
			'booleanchanged',
			'onbucketrestrictionchange',
			'onnumericalrangevalidation',
			'setbooleanchanged',
		])
	);
