import {QueryType} from './datacloud.enums';

const LatticeInsightQueryMap = new Map<QueryType, string>([
	[QueryType.Attributes, '/pls/latticeinsights/insights'],
	[QueryType.StatsTopN, '/pls/latticeinsights/stats/topn'],
	[QueryType.StatsCubes, '/pls/latticeinsights/stats/cubes'],
]);

const DataCollectionQueryMap = new Map<QueryType, string>([
	[QueryType.Attributes, '/pls/datacollection/attributes'],
	[QueryType.StatsTopN, '/pls/datacollection/statistics/topn?topbkt=true'],
	[QueryType.StatsCubes, '/pls/datacollection/statistics/cubes'],
]);

const StickyHeight = 54;
const QueryButtonGroupHeigh = 60;

export {
	LatticeInsightQueryMap,
	DataCollectionQueryMap,
	StickyHeight,
	QueryButtonGroupHeigh,
};
