import {theme} from '@dnb-uux-design-system/react';
import {createTheme} from '@mui/material/styles';

declare module '@mui/material/styles' {
	interface Theme {
		revUpColors: {
			greyLight: string;
			greyMedium: string;
			lightGray: string;
		};
	}

	interface ThemeOptions {
		revUpColors: {
			greyLight: string;
			greyMedium: string;
			lightGray: string;
		};
	}
}

export const revUpTheme = createTheme(theme, {
	revUpColors: {
		greyLight: '#D0D1D0',
		greyMedium: '#939393',
		lightGray: '#D8D8D8',
	},
});
