import {BucketCmp} from '../../../../query.enums';

const timeFromCmps = [BucketCmp.BETWEEN_DATE, BucketCmp.AFTER];
const timeToCmps = [
	BucketCmp.BEFORE,
	BucketCmp.BETWEEN_DATE,
	BucketCmp.PRIOR_ONLY,
];

const hideTimeFrameCmps = [
	BucketCmp.EVER,
	BucketCmp.BETWEEN_DATE,
	BucketCmp.BEFORE,
	BucketCmp.AFTER,
];

const hideNumericalPeriodFromCmps = [
	BucketCmp.EVER,
	BucketCmp.IN_CURRENT_PERIOD,
	BucketCmp.BETWEEN_DATE,
	BucketCmp.BEFORE,
	BucketCmp.AFTER,
	BucketCmp.WITHIN,
	BucketCmp.PRIOR_ONLY,
];
const numericalPeriodToCmps = [
	BucketCmp.BETWEEN,
	BucketCmp.WITHIN,
	BucketCmp.PRIOR_ONLY,
];

const timeFrameCmps = [
	BucketCmp.BETWEEN_DATE,
	BucketCmp.BEFORE,
	BucketCmp.AFTER,
];

const unitFromCmps = [
	BucketCmp.EQUAL,
	BucketCmp.GREATER_OR_EQUAL,
	BucketCmp.GREATER_THAN,
	BucketCmp.GT_AND_LT,
	BucketCmp.GT_AND_LTE,
	BucketCmp.GTE_AND_LT,
	BucketCmp.GTE_AND_LTE,
	BucketCmp.NOT_EQUAL,
];
const unitToCmps = [
	BucketCmp.GT_AND_LT,
	BucketCmp.GT_AND_LTE,
	BucketCmp.GTE_AND_LT,
	BucketCmp.GTE_AND_LTE,
	BucketCmp.LESS_OR_EQUAL,
	BucketCmp.LESS_THAN,
];

const amtFromCmps = [
	BucketCmp.GREATER_OR_EQUAL,
	BucketCmp.GREATER_THAN,
	BucketCmp.GT_AND_LT,
	BucketCmp.GT_AND_LTE,
	BucketCmp.GTE_AND_LT,
	BucketCmp.GTE_AND_LTE,
];
const amtToCmps = [
	BucketCmp.GT_AND_LT,
	BucketCmp.GT_AND_LTE,
	BucketCmp.GTE_AND_LTE,
	BucketCmp.GTE_AND_LT,
	BucketCmp.LESS_OR_EQUAL,
	BucketCmp.LESS_THAN,
];

export {
	timeFromCmps,
	timeToCmps,
	hideTimeFrameCmps,
	hideNumericalPeriodFromCmps,
	numericalPeriodToCmps,
	timeFrameCmps,
	unitFromCmps,
	unitToCmps,
	amtFromCmps,
	amtToCmps,
};
