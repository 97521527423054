import React, {react2angular} from 'common/react-vendor';
import {getString} from 'common/app/utilities/ResourceUtility';

export const Logout = (): JSX.Element => {
	const dateString = `2010 - ${new Date().getFullYear()}`;

	return (
		<section>
			<div className='logout-message'>
				<p>You have been successfully logged out.</p>
				<p className='footer login-footer'>
					&copy;
					{getString('LOGIN_COPYRIGHT', [dateString])}
					<a href='https://www.dnb.com/utility-pages/privacy-policy.html'>
						{getString('HEADER_PRIVACY_POLICY')}
					</a>
				</p>
			</div>
		</section>
	);
};

angular
	.module('login.saml.logout', [])
	.component('loginSamlLogout', react2angular(Logout));
