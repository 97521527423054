import isEmpty from 'lodash/isEmpty';
import {store} from 'store';
import type {Attribute} from 'common/components/datacloud/query/advanced/tree/types/Attribute.types';
import {AttributeEntity} from 'common/components/datacloud/query/advanced/tree/types/Attribute.types';
import {
	getAttributes,
	getAttributesTimeSeries,
	getEntityMapping,
	getRatingModels,
	getStatsCubes,
	postRatingModels,
} from 'common/components/datacloud/datacloud.queries';
import type {
	GetAttributesOpts,
	GetStatsCubesOpts,
} from 'common/components/datacloud/datacloud.queries';
import type {
	EntityMappingResponse,
	RatingModel,
	StatsCubesResponse,
} from 'common/components/datacloud/datacloud.types';
import type {DataCloudState, DataCloudMetadata, EnrichmentsMap} from './types';
import {setDataCloudPropertyValue, setMetadataPropertyValue} from './actions';

const getDataCloudStore = (): DataCloudState => store.getState().dataCloud;

const getDataCloudProperty = <DataCloudProperty extends unknown>(
	key: keyof DataCloudState
): DataCloudProperty => {
	const dataCloudState: DataCloudState = getDataCloudStore();

	return dataCloudState[key] as DataCloudProperty;
};

const getDataCloudMetadataProperty = <MetadataProperty extends unknown>(
	key: keyof DataCloudMetadata
): MetadataProperty => {
	const dataCloudMetadata = getDataCloudProperty<DataCloudMetadata>('metadata');

	return dataCloudMetadata[key] as MetadataProperty;
};

const setEnrichments = (
	enrichments: Attribute[],
	concatEnrichments?: boolean
): void => {
	const enrichmentsState =
		getDataCloudProperty<Attribute[]>('enrichments') || [];

	const newEnrichments = concatEnrichments
		? [...enrichmentsState, ...enrichments]
		: enrichments;

	store.dispatch(
		setDataCloudPropertyValue<Attribute[]>('enrichments', newEnrichments)
	);
};

const setEnrichmentsTotal = (total: number): void => {
	store.dispatch(setMetadataPropertyValue('enrichmentsTotal', total));
};

interface GetEnrichmentsParams {
	opts?: GetAttributesOpts;
	concatEnrichments?: boolean;
	nocache?: boolean;
}
const getEnrichments = async (
	params?: GetEnrichmentsParams
): Promise<Attribute[]> => {
	const {opts, concatEnrichments, nocache} = params || {};

	const enrichmentsState = getDataCloudProperty<Attribute[]>('enrichments');

	if (!enrichmentsState || nocache) {
		const response = await getAttributes(opts);

		setEnrichments(response, concatEnrichments || false);
		setEnrichmentsTotal(response.length);

		return response;
	}

	setEnrichmentsTotal(enrichmentsState.length);

	return enrichmentsState;
};

const getCube = async (opts?: GetStatsCubesOpts): Promise<StatsCubesResponse> =>
	getStatsCubes(opts).then((statsCubes) => {
		store.dispatch(
			setDataCloudPropertyValue<StatsCubesResponse>('cube', statsCubes)
		);

		return statsCubes;
	});

const getEnrichmentsMap = (
	key?: string
): EnrichmentsMap | number | undefined => {
	const enrichmentsMapState =
		getDataCloudProperty<EnrichmentsMap>('enrichmentsMap');

	return key ? enrichmentsMapState[key] : enrichmentsMapState;
};

const getEntityList = async (
	nocache = false
): Promise<EntityMappingResponse> => {
	const entityListState =
		getDataCloudProperty<EntityMappingResponse>('entityList');

	if (!entityListState || nocache) {
		return getEntityMapping().then((response) => {
			store.dispatch(setDataCloudPropertyValue('entityList', response));

			return response;
		});
	}

	return entityListState;
};

const getRatingsEngineAttributes = async (
	ratingsEngineId: string,
	ratingModelId?: string
): Promise<[RatingModel]> => {
	const data = await getRatingModels(ratingsEngineId, ratingModelId);
	const ratingModels = data || [];

	const ratingsEngineAttributes =
		ratingModels[0]?.rule?.selectedAttributes || [];

	store.dispatch(
		setDataCloudPropertyValue<string[]>(
			'ratingsEngineAttributes',
			ratingsEngineAttributes
		)
	);

	return data;
};

const selectRatingsEngineAttributes = async (
	ratingsEngineId: string,
	ratingModelId?: string,
	attributes?: string[]
): Promise<string[]> => {
	const ratingsEngineAttributesState = getDataCloudProperty<string[]>(
		'ratingsEngineAttributes'
	);
	const updatedAttributes = [...ratingsEngineAttributesState];

	if (attributes) {
		attributes.forEach((attribute) => {
			const attributeIndex = updatedAttributes.indexOf(attribute);

			if (attributeIndex !== -1) {
				updatedAttributes.splice(attributeIndex, 1);
			} else {
				updatedAttributes.push(attribute);
			}
		});
	}

	return postRatingModels(
		ratingsEngineId,
		ratingModelId,
		updatedAttributes
	).then(function (response) {
		store.dispatch(
			setDataCloudPropertyValue('ratingsEngineAttributes', response)
		);

		return response;
	});
};

type AttributeEntries = [AttributeEntity, Attribute[]][];

const getEventEnrichments = async (
	concatEnrichments?: boolean,
	nocache = false
): Promise<Attribute[]> => {
	const eventEnrichmentsState =
		getDataCloudProperty<Attribute[]>('eventEnrichments');

	if (!eventEnrichmentsState || nocache) {
		const attributesTimeSeries = await getAttributesTimeSeries();

		const attributeEntries = Object.entries(
			attributesTimeSeries
		) as AttributeEntries;

		const eventEnrichments = attributeEntries.reduce(
			(accumulator, [category, attributes]) => {
				if (!isEmpty(attributes)) {
					const attributesWithCategory = attributes.map((attribute) => ({
						...attribute,
						Category: category,
						Entity: category,
					}));

					return [...accumulator, ...attributesWithCategory];
				}

				return [...accumulator];
			},
			[] as Attribute[]
		);

		const newEventEnrichments = concatEnrichments
			? [...eventEnrichmentsState, ...eventEnrichments]
			: eventEnrichments;

		store.dispatch(
			setDataCloudPropertyValue('eventEnrichments', newEventEnrichments)
		);

		store.dispatch(
			setMetadataPropertyValue('enrichmentsTotal', eventEnrichments.length)
		);

		return eventEnrichments;
	}

	store.dispatch(
		setMetadataPropertyValue(
			'eventEnrichmentsTotal',
			eventEnrichmentsState.length
		)
	);

	return eventEnrichmentsState;
};

const getNumericalOperations = (): Record<string, string> =>
	getDataCloudProperty<Record<string, string>>('numerical_operations');

export {
	getDataCloudStore,
	getDataCloudProperty,
	getDataCloudMetadataProperty,
	getCube,
	getEnrichments,
	setEnrichments,
	getEnrichmentsMap,
	getEntityList,
	getRatingsEngineAttributes,
	selectRatingsEngineAttributes,
	getEventEnrichments,
	getNumericalOperations,
};
