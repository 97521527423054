import React from 'react';
import {styled} from '@mui/material';
import MUIBox from '@mui/material/Box';
import {
	DNBDrawer,
	DNBLink,
	DNBDivider,
	DNBTypography,
	FilterAltOutlinedIcon,
} from 'common/dnb-uux-vendor';
import {
	FilterCheckboxAccordion,
	FilterCustomAccordion,
} from './filter-accordion';
import type {
	FilterAccordionCheckboxItem,
	FilterAccordionCustomItem,
} from './filter-accordion';

const {useState, useMemo, useEffect, useRef} = React;

interface StyledDNBDrawerProps {
	xPosition?: string;
}

const StyledDNBDrawer = styled(DNBDrawer, {
	shouldForwardProp: (prop) => prop !== 'xPosition',
})<StyledDNBDrawerProps>`
	.MuiDrawer-paper {
		overflow: auto;
		min-width: 326px;
		max-height: calc(100vh - ${({xPosition}) => xPosition}px);
		padding: 0;
	}

	/*
	TODO: It seems that the UUX Figma spec has the extra padding at the top
	when the variant is permanent. This might get reviewed in the UUX repo
	spike of the Drawer component.
	Maybe this won't be necessary after the spike is completed.
	*/
	> div > div:first-of-type {
		display: none;
	}
`;

const StyledMUIBox = styled(MUIBox)`
	display: flex;
	align-items: center;
`;

const StyledHeaderMUIBox = styled(StyledMUIBox)`
	justify-content: space-between;
	margin: ${({theme}) => `${theme.spacing(4)} ${theme.spacing(2)}`};
`;

const StyledTitleMUIBox = styled(StyledMUIBox)`
	gap: ${({theme}) => theme.spacing(2)};
`;

type FilterAccordionItem =
	| FilterAccordionCustomItem
	| FilterAccordionCheckboxItem;

interface FilterDrawerProps {
	isOpen: boolean;
	filters: FilterAccordionItem[];
	hasActiveFilters?: boolean;
	onSelectAllChange?(selectAll: boolean): void;
	onToggle?(open: boolean): void;
	elementId?: string;
	showSelectAll?: boolean;
	showClearAll?: boolean;
	onClearAllChange?: () => void;
}

const FilterDrawer = ({
	isOpen,
	filters,
	hasActiveFilters,
	onSelectAllChange,
	onToggle,
	elementId = 'mainContent',
	showSelectAll = true,
	showClearAll = false,
	onClearAllChange,
}: FilterDrawerProps): React.ReactElement | null => {
	const filterDrawer = useRef<HTMLDivElement | null>(null);

	const filterNames = useMemo(() => filters.map(({name}) => name), [filters]);

	const [expandedFilters, setExpandedFilters] = useState<string[]>(filterNames);

	const [drawerTopPosition, setDrawerTopPosition] = useState(
		filterDrawer.current?.getBoundingClientRect().top.toFixed(0)
	);

	const isAllExpanded = expandedFilters.length === filters.length;

	useEffect(() => {
		setExpandedFilters(filterNames);

		/**
		 * We only want to run this effect when the drawer
		 * isOpen state changes to make sure that all
		 * items are expanded by default.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	/**
	 * This help us to resize the drawer in case
	 * an element above disappears or moves.
	 */
	useEffect(() => {
		const resizeObserver = new ResizeObserver(() => {
			const currentDrawerTopPosition = filterDrawer.current
				?.getBoundingClientRect()
				.top.toFixed(0);

			if (drawerTopPosition !== currentDrawerTopPosition) {
				setDrawerTopPosition(currentDrawerTopPosition);
			}
		});

		resizeObserver.observe(document.getElementById(elementId)!);

		return () => {
			resizeObserver.unobserve(document.getElementById(elementId)!);
		};
	}, [drawerTopPosition, elementId]);

	if (!isOpen) {
		return null;
	}

	const toggleExpanded = (): void => {
		if (isAllExpanded) {
			setExpandedFilters([]);
		} else {
			setExpandedFilters(filterNames);
		}
	};

	const handleAccordionExpandedChange = (
		expanded: boolean,
		accordionName: string
	): void => {
		const newExpandedFilters = [...expandedFilters];

		if (expanded) {
			newExpandedFilters.push(accordionName);
		} else {
			newExpandedFilters.splice(newExpandedFilters.indexOf(accordionName), 1);
		}

		setExpandedFilters(newExpandedFilters);
	};

	return (
		<div ref={filterDrawer}>
			<StyledDNBDrawer
				relative
				variant='permanent'
				open
				onToggle={() => onToggle?.(!isOpen)}
				xPosition={drawerTopPosition}>
				<StyledHeaderMUIBox>
					<StyledTitleMUIBox>
						<FilterAltOutlinedIcon
							sx={{
								width: '20px',
								height: '20px',
							}} /* TODO: Use SizingIconCompact once the tokens are created in UUX */
						/>
						<DNBTypography variant='compact-body'>Filters</DNBTypography>
					</StyledTitleMUIBox>

					<StyledMUIBox>
						{showClearAll && (
							<DNBLink
								underline='none'
								size='compact'
								fontWeight={(theme) => theme.typography.FontWeightAvenirRoman}
								onClick={onClearAllChange}>
								Clear All
							</DNBLink>
						)}
						{showSelectAll && (
							<DNBLink
								underline='none'
								size='compact'
								fontWeight={(theme) => theme.typography.FontWeightAvenirRoman}
								onClick={() => onSelectAllChange?.(!hasActiveFilters)}>
								{hasActiveFilters ? 'Deselect All' : 'Select All'}
							</DNBLink>
						)}
						<DNBDivider
							variant='medium'
							orientation='vertical'
							sx={{height: ({spacing}) => spacing(4), ml: 3, mr: 3}}
						/>
						<DNBLink
							underline='none'
							size='compact'
							fontWeight={(theme) => theme.typography.FontWeightAvenirRoman}
							onClick={toggleExpanded}>
							{isAllExpanded ? 'Collapse All' : 'Expand All'}
						</DNBLink>
					</StyledMUIBox>
				</StyledHeaderMUIBox>

				{filters.map(({name, ...accordionProps}) => {
					return 'options' in accordionProps ? (
						<FilterCheckboxAccordion
							key={name}
							name={name}
							{...accordionProps}
							expanded={expandedFilters.includes(name)}
							onExpandedChange={(expanded) =>
								handleAccordionExpandedChange(expanded, name)
							}
						/>
					) : (
						<FilterCustomAccordion
							key={name}
							name={name}
							{...accordionProps}
							expanded={expandedFilters.includes(name)}
							onExpandedChange={(expanded) =>
								handleAccordionExpandedChange(expanded, name)
							}
						/>
					);
				})}
			</StyledDNBDrawer>
		</div>
	);
};

export {FilterDrawer};
