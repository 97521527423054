import {store} from 'store';
import React, {react2angular} from 'common/react-vendor';
import {Provider} from 'common/redux-vendor';
import {IQueryBlock} from './queryBlockTypes';
import {QueryBlock} from './queryBlock';

const QueryBlockWithRedux = (prop: IQueryBlock): JSX.Element => {
	return (
		<Provider store={store}>
			<QueryBlock {...prop} />
		</Provider>
	);
};

angular
	.module('common.datacloud.query.builder.block', [])
	.component(
		'queryBlock',
		react2angular(QueryBlockWithRedux, ['scope', 'type'], [])
	);
/**
 * <query-block />.
 */
