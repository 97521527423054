import template from './pagination.component.html';
import {react2angular} from '../../react-vendor';
import {LePaginationWithAngularProps} from './LePagination';

angular
	.module('lp.navigation.pagination', [])
	/**
	 * TODO: We need to keep this angular directive because 'max' and 'current'
	 * properties need to be initialized for angular pagination works properly,
	 * once angular components that use this <pd-pagination-controls /> component
	 * are refactored, remove this component and use uux pagination component
	 */
	.directive('pdPaginationControls', function () {
		return {
			restrict: 'EA',
			template,
			scope: {
				max: '=',
				current: '=',
			},
			controller: [
				'$scope',
				function ($scope) {
					$scope.Math = window.Math;
					$scope.onCallback = function (page) {
						$scope.current = page.current;
					};
				},
			],
		};
	})
	.component(
		'reactPagination',
		react2angular(LePaginationWithAngularProps, [
			'total',
			'start',
			'perpage',
			'callback',
			'classname',
		])
	);
