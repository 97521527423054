import React from 'common/react-vendor';

const MyAccountIcon = (): React.ReactElement => (
	<svg width="63" height="66" viewBox="0 0 63 66" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M7.49001 1H28.99V7.65002H35.48V22.0599H40.94V16.26H55.95V21.38H61.76V64.39H1V7.04004H7.44L7.49001 1Z" stroke="#4196B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M1 64.39V7.05005H7.44L7.49001 1" stroke="#4196B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M35.5303 21.1099V63.7499" stroke="#4196B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M22.8095 54.46H13.7695V63.91H22.8095V54.46Z" stroke="#4196B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M51.6903 58.04H45.6104V64.1899H51.6903V58.04Z" stroke="#4196B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M44.4103 30.4902V30.5902H44.3203V30.4902H44.4103ZM46.4103 28.4902H42.3203V32.5902H46.4103V28.4902Z" fill="#4196B4"/>
		<path d="M52.0705 30.4902V30.5902H51.9805V30.4902H52.0705ZM54.0705 28.4902H49.9805V32.5902H54.0705V28.4902Z" fill="#4196B4"/>
		<path d="M44.4103 38.8198V38.9099H44.3203V38.8198H44.4103ZM46.4103 36.8198H42.3203V40.9099H46.4103V36.8198Z" fill="#4196B4"/>
		<path d="M52.0705 38.8198V38.9099H51.9805V38.8198H52.0705ZM54.0705 36.8198H49.9805V40.9099H54.0705V36.8198Z" fill="#4196B4"/>
		<path d="M44.4103 47.4697V47.5597H44.3203V47.4697H44.4103ZM46.4103 45.4697H42.3203V49.5597H46.4103V45.4697Z" fill="#4196B4"/>
		<path d="M52.0705 47.4697V47.5597H51.9805V47.4697H52.0705ZM54.0705 45.4697H49.9805V49.5597H54.0705V45.4697Z" fill="#4196B4"/>
		<path d="M11.1603 17.6201V17.7101H11.0703V17.6201H11.1603ZM13.1603 15.6201H9.07031V19.7101H13.1603V15.6201Z" fill="#4196B4"/>
		<path d="M18.0099 17.6201V17.7101H17.8799V17.6201H17.9699M19.9699 15.6201H15.8799V19.7101H19.9699V15.6201Z" fill="#4196B4"/>
		<path d="M25.1701 17.6201V17.7101H25.0801V17.6201H25.1701ZM27.1701 15.6201H23.0801V19.7101H27.1701V15.6201Z" fill="#4196B4"/>
		<path d="M11.1603 25.96V26.05H11.0703V25.96H11.1603ZM13.1603 23.96H9.07031V28.05H13.1603V23.96Z" fill="#4196B4"/>
		<path d="M18.0099 25.96V26.05H17.8799V25.96H17.9699M19.9699 23.96H15.8799V28.05H19.9699V23.96Z" fill="#4196B4"/>
		<path d="M25.1701 25.96V26.05H25.0801V25.96H25.1701ZM27.1701 23.96H23.0801V28.05H27.1701V23.96Z" fill="#4196B4"/>
		<path d="M11.1603 34.6201V34.7201H11.0703V34.6201H11.1603ZM13.1603 32.6201H9.07031V36.7201H13.1603V32.6201Z" fill="#4196B4"/>
		<path d="M18.0099 34.6201V34.7201H17.8799V34.6201H17.9699M19.9699 32.6201H15.8799V36.7201H19.9699V32.6201Z" fill="#4196B4"/>
		<path d="M25.1701 34.6201V34.7201H25.0801V34.6201H25.1701ZM27.1701 32.6201H23.0801V36.7201H27.1701V32.6201Z" fill="#4196B4"/>
		<path d="M11.1603 43.0498V43.1398H11.0703V43.0498H11.1603ZM13.1603 41.0498H9.07031V45.1398H13.1603V41.0498Z" fill="#4196B4"/>
		<path d="M18.0099 43.0498V43.1398H17.8799V43.0498H17.9699M19.9699 41.0498H15.8799V45.1398H19.9699V41.0498Z" fill="#4196B4"/>
		<path d="M25.1701 43.0498V43.1398H25.0801V43.0498H25.1701ZM27.1701 41.0498H23.0801V45.1398H27.1701V41.0498Z" fill="#4196B4"/>
	</svg>
);

export default MyAccountIcon;
