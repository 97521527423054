import {AxiosError} from 'axios';
import {useEffect, useState} from 'react';

export interface UseApiConfig<Response = unknown> {
	enabled?: boolean;
	onSuccess?(response: Response): void;
	onError?(error: AxiosError): void;
	onFinally?(): void;
}

interface UseApiReturn<Response> {
	isFetching: boolean;
	error?: AxiosError;
	data?: Response;
}

/**
 * We should start to use this hook
 * for requests inside React components.
 * We are trying to create a hook similar
 * to the useQuery from React Query,
 * this way once we migrate to that library
 * the changes would be small.
 *
 * In case we need to make a request in a
 * non-functional component or non hook
 * we should use the axiosInstance.
 * projects/common/app/utilities/axiosUtility/axiosInstance.ts:47
 */
const useApi = <Response>(
	queryKey: string | string[],
	queryFn: () => Promise<Response>,
	config?: UseApiConfig<Response>
): UseApiReturn<Response> => {
	const {enabled = true, onSuccess, onError, onFinally} = config || {};

	const [response, setResponse] = useState<Response>();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [error, setError] = useState<AxiosError>();

	useEffect(() => {
		if (enabled) {
			setIsFetching(true);
			setError(undefined);
			setResponse(undefined);

			// eslint-disable-next-line promise/catch-or-return
			queryFn()
				.then((res) => {
					if (typeof onSuccess === 'function') {
						onSuccess(res);
					}

					setResponse(res);
				})
				.catch((err) => {
					if (typeof onError === 'function') {
						onError(err);
					}

					setError(err);
				})
				.finally(() => {
					if (typeof onFinally === 'function') {
						onFinally();
					}

					setIsFetching(false);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enabled, queryKey]);

	return {isFetching, error, data: response};
};

export {useApi};
export type {UseApiReturn};
