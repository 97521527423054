import React from 'common/react-vendor';
import {
	designTokens,
	DNBMenu,
	MoreVertOutlinedIcon,
} from 'common/dnb-uux-vendor';
import classNames from 'classnames';
import {
	SystemType,
	isConnectionUneditable,
} from 'atlas/connectors/EIF/Data/SystemType';
import {SourceContextMenuConfig} from 'atlas/connectors/EIF/Data/ContextMenuConst';
import {IImportSourceResponse} from 'atlas/connectors/EIF/Data/API/FE/ImportSourceResponse';
import {
	DocumentTypeGroupConfig,
	isIntentDocumentType,
	SupportedDocumentGroupList,
} from 'atlas/connectors/EIF/Data/DocumentConst';
import {GenericFetcherContext} from 'atlas/connectors/EIF/Configuration/Context/GenericFetcherContext';
import {IRBACMap} from 'atlas/connectors/EIF/RBAC/EIFRBAC';
import {GenericFetcherContextType} from 'atlas/connectors/EIF/Configuration/Context/GenericFetcherContextConst';
import {DocumentType} from 'atlas/connectors/EIF/Data/DocumentType';
import styles from './ActionColumnItem.module.scss';

interface IActionButton {
	id: string;
	icon?: React.ReactElement;
	label?: string;
	showTooltip?: boolean;
	size?: number;
	data?: string;
	onHandelClick?: (event: React.MouseEvent<HTMLElement>, id?: string) => void;
}

const isContextMenuHide = (
	entity: DocumentType,
	systemType: SystemType
): boolean => {
	const documentTypeGroup = DocumentTypeGroupConfig[entity];
	const isGroupHide = !SupportedDocumentGroupList.includes(documentTypeGroup);
	const isSystemTypeHide = isConnectionUneditable(systemType);
	const isEntityHide =
		entity === DocumentType.WebVisit || isIntentDocumentType(entity);
	return isGroupHide || isSystemTypeHide || isEntityHide;
};

const ActionButton = ({
	id,
	data,
	icon = (
		<MoreVertOutlinedIcon
			fontSize='small'
			sx={{
				color: designTokens.TokenColors.ColorGraySecondary,
			}}
		/>
	),
	label,
	showTooltip,
	size,
	onHandelClick,
}: IActionButton): React.ReactElement => {
	return (
		<button
			type='button'
			data-tip={data || true}
			data-for={id}
			style={size ? {width: size, height: size} : undefined}
			className={classNames({
				[styles.ActionButton!]: true,
				[styles.ActionButtonPressed!]: showTooltip,
			})}
			onClick={(e: React.MouseEvent<HTMLElement>) => onHandelClick?.(e, data)}>
			{label}
			{icon}
		</button>
	);
};

interface IActionColumnItem {
	sourceInfo: IImportSourceResponse;
}

const ActionColumnItem = ({
	sourceInfo,
}: IActionColumnItem): React.ReactElement => {
	const {SourceId: id, Entity, SystemType} = sourceInfo;
	const {data: RBACMap} = React.useContext(
		GenericFetcherContext<IRBACMap>(GenericFetcherContextType.IRBACMap)
	);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [ModalDialog, setModalDialog] = React.useState<null | React.ReactNode>(
		null
	);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (): void => {
		setAnchorEl(null);
	};
	const closeDeleteDialog = (): void => {
		setModalDialog(null);
	};
	const menuItems = SourceContextMenuConfig.filter(({filterHook}) =>
		filterHook ? filterHook({data: sourceInfo, RBACMap}) : true
	);

	return (
		<span className={classNames('le-cell-item')} title='Action'>
			<div className='edit bypass-tile-click'>
				{!isContextMenuHide(Entity, SystemType) && (
					<>
						<ActionButton id={id} onHandelClick={handleClick} />
						<DNBMenu
							open={open}
							anchorEl={anchorEl}
							menuItems={menuItems.map(({text, icon, onClick, Modal}) => ({
								icon,
								text,
								onClick: () => {
									handleClose();
									onClick?.(sourceInfo);
									Modal &&
										setModalDialog(
											<Modal
												data={sourceInfo}
												isDeleteDialogShow
												closeDeleteDialog={closeDeleteDialog}
											/>
										);
								},
							}))}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						/>
					</>
				)}
				{ModalDialog}
			</div>
		</span>
	);
};

export {ActionColumnItem, ActionButton};
export type {IActionColumnItem, IActionButton};
