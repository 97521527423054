import {Grid, DNBButton, DNBButtonProps} from 'common/dnb-uux-vendor';
import React from 'common/react-vendor';

type ISegmentButtonGroup = {
	buttonList: DNBButtonProps[];
};

const SegmentButtonGroup = ({
	buttonList,
}: ISegmentButtonGroup): React.ReactElement => {
	return (
		<Grid container item justifyContent='flex-end' gap={2}>
			{buttonList.map(({title, ...others}) => (
				<DNBButton key={title} title={title} {...others}>
					{title}
				</DNBButton>
			))}
		</Grid>
	);
};

export {SegmentButtonGroup};
