import React from 'common/react-vendor';
import {DNBToggle} from 'common/dnb-uux-vendor';

const toggle = ({
	checked,
	label,
	dataTest,
	onChange,
}: {
	checked: boolean;
	label: string;
	dataTest: string;
	onChange?: (checked: boolean) => void;
}): React.ReactElement => {
	return (
		<DNBToggle
			ariaLabel={label}
			checked={checked}
			data-test={dataTest}
			onChange={(event) => onChange?.(event.target.checked)}
		/>
	);
};

export {toggle};
