import {
	getEnrichments,
	getEventEnrichments,
} from 'common/stores/datacloud';
import template from './input.component.html';
import {getMetadataForCategory} from '../../../datacloud.service.vanilla';

angular
	.module('common.datacloud.query.builder.input', [])
	.directive('queryInputDirective', function () {
		return {
			restrict: 'AE',
			scope: {
				root: '=',
				tree: '=',
				parent: '=',
			},
			template,
			controllerAs: 'vm',
			controller($scope, $filter) {
				const vm = this;

				angular.extend(vm, {
					root: $scope.root,
					tree: $scope.tree,
					parent: $scope.parent,
					items: $scope.root.items,
					enrichments: [],
					eventEnrichments: [],
					enrichmentsMap: $scope.root.enrichmentsMap,
					eventEnrichmentsMap: $scope.root.eventEnrichmentsMap,
				});

				vm.init = () => {
					if (vm.root.showTimeSeries) {
						getEventEnrichments().then(function (eventEnrichments) {
							vm.eventEnrichments = eventEnrichments;
							if (vm.tree.bucketRestriction) {
								const {entityType, attr} = vm.tree.bucketRestriction;
								if (!entityType) {
									return;
								}
								const [, colId] = attr.split('.');
								const newAttr = `${entityType}.${colId}`;
								if (!Object.keys(vm.eventEnrichmentsMap).includes(newAttr)) {
									return;
								}
								vm.item = {
									...vm.eventEnrichments[vm.eventEnrichmentsMap[newAttr]]
								};
							}
						});
					}

					getEnrichments().then((enrichments) => {
						vm.enrichments = enrichments;
						if (vm.tree.bucketRestriction) {
							if (
								vm.root.showTimeSeries &&
								!Object.keys(vm.enrichmentsMap).includes(
									vm.tree.bucketRestriction.attr
								)
							) {
								return;
							}
							vm.item =
								vm.enrichments[
									vm.enrichmentsMap[vm.tree.bucketRestriction.attr]
								];
							vm.categoryMetadata = getMetadataForCategory(
								vm.root.categoryMetadata,
								vm.item?.Category
							);
						}
						if (
							vm.root.showSegmentationV2 &&
							vm.tree.segmentMemberRestriction
						) {
							const [segment] = $filter('filter')(
								vm.root.segmentsList,
								{name: vm.tree.segmentMemberRestriction.segmentName},
								true
							);
							vm.root.segmentsList;
							vm.item = {
								Category: `${segment.dataType} ${
									segment.type === 'List' ? '' : 'Segments'
								}`,
								DisplayName: segment.display_name,
							};
						}
					});
				};

				vm.init();
			},
		};
	});
