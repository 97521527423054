import React from 'common/react-vendor';
import {GridRowSelectionModel} from 'common/dnb-uux-vendor';
import {TimestampUtility} from 'common/app/utilities/PersonalizationUtility/TimestampUtility';
import {
	IAttributesConfig,
	PersonalizationPageType,
	IAttributeGroupConfig,
	ISegmentListConfig,
	IGetWorkflowStatusData,
} from '../configs/PersonalizationTypes';
import {
	getAllAttributeGroupData,
	getAttributeListData,
	getBoardingStatusData,
	getPersonalizationSegmentsData,
	getSegmentsListData,
	getWorkflowStatusData,
} from '../services/apiCalls';

type IDataUpdate<T> = React.Dispatch<React.SetStateAction<T>>;

const {createContext} = React;
const DefaultPageType = PersonalizationPageType.Steps;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const DefaultDataUpdate = (): void => {};

interface IPersonalizationContext {
	isBoarding: boolean;
	isStartModalShow: boolean;
	pageType: PersonalizationPageType;
	currentStep: number;
	isLoading: boolean;
	groupList: Array<IAttributeGroupConfig>;
	attributesList: Array<IAttributesConfig>;
	selectionModel: GridRowSelectionModel;
	total: number;
	groupOption: string;
	isAttributeListLoading: boolean;
	workflowData: IGetWorkflowStatusData;
	workflowDataList: Array<ISegmentListConfig>;
}
const DefaultPersonalizationContext: IPersonalizationContext = {
	isBoarding: false,
	isStartModalShow: false,
	pageType: DefaultPageType,
	currentStep: 0,
	isLoading: false,
	groupList: [],
	attributesList: [],
	selectionModel: [],
	total: 0,
	groupOption: '',
	isAttributeListLoading: false,
	workflowData: {},
	workflowDataList: [],
};

interface IPersonalizationContextUpdate {
	setIsBoarding: IDataUpdate<boolean>;
	setIsStartModalShow: IDataUpdate<boolean>;
	setPageType: IDataUpdate<PersonalizationPageType>;
	setCurrentStep: IDataUpdate<number>;
	setAttributesList: IDataUpdate<Array<IAttributesConfig>>;
	setSelectionModel: IDataUpdate<GridRowSelectionModel>;
	setGroupOption: IDataUpdate<string>;
	setIsAttributeListLoading: IDataUpdate<boolean>;
}
const DefaultPersonalizationContextUpdate: IPersonalizationContextUpdate = {
	setIsBoarding: DefaultDataUpdate,
	setIsStartModalShow: DefaultDataUpdate,
	setPageType: DefaultDataUpdate,
	setCurrentStep: DefaultDataUpdate,
	setAttributesList: DefaultDataUpdate,
	setSelectionModel: DefaultDataUpdate,
	setGroupOption: DefaultDataUpdate,
	setIsAttributeListLoading: DefaultDataUpdate,
};

const PersonalizationContext = createContext(DefaultPersonalizationContext);
const PersonalizationContextUpdate = createContext(
	DefaultPersonalizationContextUpdate
);

interface IPersonalizationContextProvider {
	children: React.ReactNode;
}
function PersonalizationContextProvider({
	children,
}: IPersonalizationContextProvider): React.ReactElement {
	const {useState, useEffect, useMemo} = React;
	const [isBoarding, setIsBoarding] = useState(false);
	const [isStartModalShow, setIsStartModalShow] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [pageType, setPageType] = useState(DefaultPageType);
	const [isLoading, setIsLoading] = useState(false);
	const [dataList, setDataList] = useState<Array<ISegmentListConfig>>([]);
	const [groupOption, setGroupOption] = useState('');
	const [groupList, setGroupList] = useState<Array<IAttributeGroupConfig>>([]);
	const [attributesList, setAttributesList] = useState<
		Array<IAttributesConfig>
	>([]);
	const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
		[]
	);
	const [isAttributeListLoading, setIsAttributeListLoading] = useState(false);
	const [workflowData, setWorkflowData] = useState<IGetWorkflowStatusData>({});
	const [segmentNamesData, setSegmentNamesData] =
		useState<GridRowSelectionModel>([]);
	const total = useMemo(
		() =>
			dataList
				.filter((rowItem) => selectionModel.includes(rowItem.name))
				.reduce((total, item) => {
					return total + item.accounts;
				}, 0),
		[dataList, selectionModel]
	);

	const workflowDataList = useMemo(
		() =>
			dataList.map((rowItem) => ({
				...rowItem,
				lastUpdated: segmentNamesData.includes(rowItem.name)
					? TimestampUtility(workflowData?.endTimestamp)
					: null,
			})),
		[dataList, segmentNamesData, workflowData]
	);

	useEffect(() => {
		const getSegmentsListDataAsync = async (): Promise<void> => {
			const segmentsListDataResponse = await getSegmentsListData();
			const {data} = segmentsListDataResponse;
			setDataList(data);
		};

		const getPersonalizationSegmentsDataAsync = async (): Promise<void> => {
			const personalizationSegmentsDataResponse =
				await getPersonalizationSegmentsData();
			const {segmentNames, attributeGroup} =
				personalizationSegmentsDataResponse.data;
			setSelectionModel(segmentNames);
			setSegmentNamesData(segmentNames);
			if (attributeGroup) {
				setGroupOption(attributeGroup.name);
			}
		};

		const getWorkflowStatusDataAsync = async (): Promise<void> => {
			const workflowStatusDataResponse = await getWorkflowStatusData();
			const {data} = workflowStatusDataResponse;
			if (data) {
				setWorkflowData(data);
			}
		};

		const getBoardingStatusDataAsync = async (): Promise<void> => {
			const boardingStatusDataResponse = await getBoardingStatusData();
			const {data} = boardingStatusDataResponse;
			setIsBoarding(data);
			if (data) {
				setIsStartModalShow(false);
				await getPersonalizationSegmentsDataAsync();
				await getWorkflowStatusDataAsync();
			} else {
				setIsStartModalShow(true);
			}
		};

		const getAllAttributeGroupDataAsync = async (): Promise<void> => {
			const allAttributeGroupDataResponse = await getAllAttributeGroupData();
			const {data} = allAttributeGroupDataResponse;
			setGroupList(data);
		};

		const getInitData = async (): Promise<void> => {
			setIsLoading(true);
			await Promise.all([
				getSegmentsListDataAsync(),
				getBoardingStatusDataAsync(),
				getAllAttributeGroupDataAsync(),
			]);
			setIsLoading(false);
		};
		getInitData();
	}, []);

	useEffect(() => {
		const getAttributeGroupData = (value: string): void => {
			setIsAttributeListLoading(true);
			getAttributeListData(value)
				.then((res) => {
					if (res.status === 200) {
						setIsAttributeListLoading(false);
						const {data} = res;
						setAttributesList(data);
					}
				})
				.catch(() => setIsAttributeListLoading(false));
		};
		if (groupOption !== '') {
			getAttributeGroupData(groupOption);
		} else {
			setAttributesList([]);
		}
	}, [groupOption]);

	return (
		<PersonalizationContext.Provider
			value={{
				isBoarding,
				isStartModalShow,
				pageType,
				currentStep,
				isLoading,
				groupList,
				attributesList,
				selectionModel,
				total,
				groupOption,
				isAttributeListLoading,
				workflowData,
				workflowDataList,
			}}>
			<PersonalizationContextUpdate.Provider
				value={{
					setIsBoarding,
					setIsStartModalShow,
					setCurrentStep,
					setPageType,
					setAttributesList,
					setSelectionModel,
					setGroupOption,
					setIsAttributeListLoading,
				}}>
				{children}
			</PersonalizationContextUpdate.Provider>
		</PersonalizationContext.Provider>
	);
}

export {
	PersonalizationContext,
	PersonalizationContextProvider,
	PersonalizationContextUpdate,
};
export type {IPersonalizationContext, IPersonalizationContextUpdate};
