import React from 'common/react-vendor';
import type {BucketRestriction} from '../../../../query.types';
import {TreeType, BucketCmp, TransactionKey} from '../../../../query.enums';
import {getBucketCmp, resetBktValues} from '../../tree.helpers';
import {isNumericalRangeValid} from '../../edit/NumericalRange/numericalRange.validations';
import {getChoicesList} from '../transactionStore.helpers';
import {amtConfig} from '../transactionStore.constants';
import {amtFromCmps, amtToCmps} from './transactionEditItem.constants';
import {SubTypeSelection} from './SubTypeSelection';
import {
	updatePurchaseHistoryCmp,
	removeBucketKey,
	getSubTypeConfig,
	getNumericalRangeValues,
} from './transactionEditItem.helpers';
import {SubType} from '../../tree.types';
import {NumericalRangeStatusesKeys} from './TransactionEditItem';

const {useState} = React;

interface TransactionAmountSpentProps {
	type?: TreeType;
	bucketRestriction?: BucketRestriction;
	purchased: string;
	onBucketRestrictionChange(newBucketRestriction?: BucketRestriction): void;
	onNumericalRangeValidation(
		key: NumericalRangeStatusesKeys,
		isValid: boolean
	): void;
}

const TransactionAmountSpent = ({
	type,
	bucketRestriction,
	purchased,
	onBucketRestrictionChange,
	onNumericalRangeValidation,
}: TransactionAmountSpentProps): React.ReactElement => {
	const [amtRangeConfig, setAmtRangeConfig] = useState(
		getSubTypeConfig({
			bucketRestriction,
			type,
			subType: TransactionKey.Amt,
			config: amtConfig,
		})
	);

	const amtCmp = getBucketCmp({
		bucketRestriction,
		type,
		subType: TransactionKey.Amt,
	});

	const amountSpentCmp = amtCmp === '' ? BucketCmp.ANY_CAPITALIZE : amtCmp;

	const showFromAmt = amtFromCmps.includes(amountSpentCmp);
	const showToAmt = amtToCmps.includes(amountSpentCmp);

	const getNewBucketRestriction = (
		value: BucketCmp
	): BucketRestriction | undefined => {
		const bucketParserParams = {
			bucketRestriction,
			type,
			value,
			subType: TransactionKey.Amt as SubType,
		};

		return removeBucketKey({
			...bucketParserParams,
			bucketRestriction: resetBktValues({
				...bucketParserParams,
				bucketRestriction: updatePurchaseHistoryCmp(bucketParserParams),
			}),
		});
	};

	const handleAmountSpentCmpChange = (value: BucketCmp): void => {
		onBucketRestrictionChange(getNewBucketRestriction(value));

		onNumericalRangeValidation(
			'isAmountSpentValid',
			value === BucketCmp.ANY_CAPITALIZE
		);

		setAmtRangeConfig((previousState) => ({
			...previousState,
			from: {
				...previousState.from,
				value: undefined,
			},
			to: {
				...previousState.to,
				value: undefined,
			},
		}));
	};

	const handleRangeChange = (): void => {
		const newNumericalConfig = getSubTypeConfig({
			bucketRestriction,
			type,
			subType: TransactionKey.Amt,
			config: amtConfig,
		});

		onNumericalRangeValidation(
			'isAmountSpentValid',
			isNumericalRangeValid({
				vals: getNumericalRangeValues({
					bucketRestriction,
					type,
					subType: TransactionKey.Amt,
				}),
				rangeConfig: newNumericalConfig,
				showFrom: showFromAmt,
				showTo: showToAmt,
			})
		);

		setAmtRangeConfig(newNumericalConfig);
	};

	return (
		<SubTypeSelection
			type={type}
			bucketRestriction={bucketRestriction}
			purchased={purchased}
			title='amount spent'
			cmpList={getChoicesList()}
			initialCmp={amountSpentCmp}
			onCmpChange={handleAmountSpentCmpChange}
			onRangeChange={handleRangeChange}
			showFrom={showFromAmt}
			showTo={showToAmt}
			rangeConfig={amtRangeConfig}
			fromLabel='$'
			toLabel='$'
		/>
	);
};

export {TransactionAmountSpent};
