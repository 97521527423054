import React from 'react';
import {
	DNBButton,
	designTokens,
	FilterAltOutlinedIcon,
	CheckOutlinedIcon,
} from 'common/dnb-uux-vendor';

interface FilterButtonProps {
	id: string;
	hasActiveFilters: boolean;
	doesFilterOpen: boolean;
	onClick(): void;
	disabled?: boolean;
}

const FilterButton = ({
	id,
	hasActiveFilters,
	doesFilterOpen,
	onClick,
	disabled,
}: FilterButtonProps): React.ReactElement => {
	return (
		<DNBButton
			data-test-id={`${id}-filter-button`}
			size='compact'
			variant='secondary'
			disabled={disabled}
			onClick={onClick}
			style={{
				backgroundColor: doesFilterOpen
					? designTokens.TokenColors.ColorPrimaryBlueInteractiveAlpha1
					: undefined,
				borderWidth: doesFilterOpen ? '2px' : undefined,
			}}
			startIcon={<FilterAltOutlinedIcon />}
			endIcon={hasActiveFilters && <CheckOutlinedIcon />}>
			Filters
		</DNBButton>
	);
};

export {FilterButton};
