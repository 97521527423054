import {createPersistedState} from 'app/utilities/persistance';

// The column widths are shared across attribute groups
export interface ColumnWidths {
	[colId: string]: number;
}

// inference is better here
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const createColumnWidthsProvider = (key: string) =>
	createPersistedState<ColumnWidths>(key, {});
