angular
	.module('common.datacloud.lookup', [
		'mainApp.appCommon.directives.ngEnterDirective',
	])
	.controller('LookupController', function ($state, $stateParams) {
		const vm = this;

		angular.extend(vm, {
			iframeMode: $stateParams.iframe,
		});

		vm.cancel = function () {
			$state.go('home.datacloud.datacloud');
		};

		vm.next = function () {
			if (vm.iframeMode) {
				$state.go('home.insights.iframe');
			} else {
				$state.go('home.datacloud.lookup.tabs');
			}
		};

		vm.parse = function () {
			try {
				const json = JSON.parse(vm.json);
				vm.request.record = json.request.record;
				vm.Authentication = json.Authentication;
			} catch (e) {
				console.log('JSON Parse Error:', e);
				vm.request.record = {};
			}
		};

		vm.validate = function () {
			if (
				vm.request.record.Website ||
				vm.request.record.CompanyName ||
				vm.request.record.DUNS
			) {
				vm.requiredMissingField = {};

				if (vm.iframeMode && !vm.Authentication) {
					vm.requiredMissingField.Authentication = true;
				}

				return true;
			}

			vm.requiredMissingField = {
				Website: true,
				CompanyName: true,
				DUNS: true,
			};

			if (vm.iframeMode && !vm.Authentication) {
				vm.requiredMissingField.Authentication = true;
			}

			return false;
		};
	});
