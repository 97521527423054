/* eslint-disable no-param-reassign */
import {createSlice} from 'common/node_modules/@reduxjs/toolkit';
import {schemaNamespace} from '../consts';
import type {
	IAttribute,
	IAttributeData,
	ISchemaResponseData,
	IState,
} from '../schemaTypes';

function generateIndexKeysForFieldDefinitionsRecordsMap(fieldDefinitionsRecordsMap: {
	[field: string]: Array<IAttribute>;
}): Array<IAttributeData> {
	return (
		Object.entries(fieldDefinitionsRecordsMap)
			.map(([property, attributes]) =>
				attributes?.map((attribute, index) => ({
					...attribute,
					// `property` used for identify if the attribute is editable
					// `propertyIndex` used for find which attribute is editing.
					property,
					propertyIndex: index,
				}))
			)
			.flat()
			// `dataIndex` used for delete action
			.map((item, dataIndex) => ({...item, dataIndex}))
	);
}

export const schemaSlice = createSlice({
	name: schemaNamespace,
	initialState: {
		loading: false,
		deleting: false,
		schemaData: null,
		dataSource: [],
		drawerOpened: false,
		filterConfigs: {},
	} as IState,
	reducers: {
		fetchDataBegin(state) {
			state.loading = true;
		},
		fetchDataEnd(state, {payload}: {payload: ISchemaResponseData | null}) {
			state.loading = false;
			if (payload) {
				state.schemaData = payload;

				state.dataSource = generateIndexKeysForFieldDefinitionsRecordsMap(
					payload.fieldDefinitionsRecordsMap
				);
			}
		},
		deleteDataBegin(state) {
			state.deleting = true;
		},
		deleteDataEnd(state, {payload}: {payload: IAttributeData[]}) {
			state.deleting = false;
			for (const {property, propertyIndex, dataIndex} of payload) {
				state.schemaData!.fieldDefinitionsRecordsMap[property]?.splice(
					propertyIndex,
					1
				);
				state.dataSource.splice(dataIndex, 1);
			}

			// Need to regenerate `dataIndex` & `propertyIndex` after delete

			state.dataSource = generateIndexKeysForFieldDefinitionsRecordsMap(
				state.schemaData!.fieldDefinitionsRecordsMap
			);
		},
		toggleDrawer(state) {
			state.drawerOpened = !state.drawerOpened;
		},
		setDrawerVisibility(state, {payload}) {
			state.drawerOpened = payload;
		},
	},
});

export const {
	fetchDataBegin,
	fetchDataEnd,
	deleteDataBegin,
	deleteDataEnd,
	toggleDrawer,
	setDrawerVisibility,
} = schemaSlice.actions;

export default schemaSlice.reducer;
/* eslint-enable no-param-reassign */
