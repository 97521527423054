import {SourceMappingResponse} from 'atlas/connectors/EIF/AddSource/api/apiCalls';

const getCurrentMap = (id: string): string =>
	`/pls/inboundsource/currentmap?sourceUniqueId=${id}`;

const toFieldsByCurrentMap = (response: SourceMappingResponse): string[] => {
	const {Result} = response;
	return Object.values(Result.fieldDefinitionsRecordsMap)
		.flat()
		.map(({fieldName}) => fieldName);
};

export {getCurrentMap, toFieldsByCurrentMap};
