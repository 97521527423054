/**
 * TODO: Delete this angular file once all components that uses queryActivityEditDirective gets refactor.
 * TODO: DELETE this file once there are no more instances of <query-activity-edit-directive />.
 */

import {react2angular} from 'common/react-vendor';
import {TreeActivityEditWithAngularProps} from './TreeActivityEdit/TreeActivityEdit';

angular
	.module('common.datacloud.query.builder.tree.edit.activity', [])
	.component(
		'queryActivityEditDirective',
		react2angular(TreeActivityEditWithAngularProps, ['vm', 'onScopeChange'])
	);
