import {getString} from '../utilities/ResourceUtility';
import {Banner} from './BannerService';
import {axiosInstance} from '../utilities/axiosUtility/axiosInstance';

const CHECK_SYSTEM_STATUS_TIMEOUT = 2000;

export default {
	checkSystemStatus: (): Promise<void> => {
		return new Promise<void>((resolve, reject) => {
			axiosInstance
				.get(`/pls/health/systemstatus`, {
					timeout: CHECK_SYSTEM_STATUS_TIMEOUT,
				})
				.then((response) => {
					if (response.data.status === 'OK') {
						resolve();
					} else {
						Banner.error({
							message:
								response.data.message || getString('UNEXPECTED_SERVICE_ERROR'),
						});
						reject();
					}
				})
				.catch((e) => {
					if (e.code === 'ECONNABORTED') {
						// timed out (cancelled)
						resolve();
					} else {
						Banner.error({message: getString('UNEXPECTED_SERVICE_ERROR')});
						reject();
					}
				});
		});
	},
};
