import {IEntityType} from 'common/components/datacloud/journeystage/journey.helpers';
import {isBuyerJourneyEnabled} from 'common/stores/tenantConfig';
import NgState from '../ng-state';

enum JourneyStageNgState {
	Library = 'home.journeystagelibrary',
	Configuration = 'home.journeystageconfiguration',
	Query = 'home.journeystagequery',
}

enum SegmentNgState {
	List = 'home.segments_new',
	Dashboard = 'home.segment.dashboard',
	Subsegments = 'home.segment.subsegments',
	QueryBuilder = 'home.segment.explorer.builder',
	Substage = 'home.segment.stage.substage',
	SubstageAccounts = 'home.segment.stage.substage.accounts',
	SubstageContacts = 'home.segment.stage.substage.contacts',
	JourneyAccounts = 'home.segment.journeyaccounts',
	JourneyContacts = 'home.segment.journeycontacts',
	Accounts = 'home.segment.accounts',
	Contacts = 'home.segment.contacts',
	Contact = 'home.segment.journeyaccounts.contacts',
}

/**
 * Get route by entity
 * @param entity Refers to @IEntityType
 * @return Route to accounts / contacts or journey accounts / contacts according to isBuyerJourneyEnabled FF.
 */
const getEntityRoute = (entity: IEntityType): string => {
	return `home.segment.${isBuyerJourneyEnabled() ? 'journey' : ''}${entity}`;
};

/**
 * Navigate helper.
 * @param route The route name better to use reusable const or enum defined.
 * @param data The route configuration data.
 * @param forceReload True on force reloading.
 * @remark Auto reload if navigate the same route as previous.
 */
const navigate = (
	route: string,
	data?: Record<string, unknown>,
	forceReload?: boolean
): void => {
	const {name} = NgState.getAngularState().current;
	const reload = forceReload || name === route;
	NgState.getAngularState().go(route, data, {reload});
};

export {JourneyStageNgState, SegmentNgState, getEntityRoute, navigate};
