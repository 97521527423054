interface State {
	name: string;
	goParams?: unknown;
}

interface StateParams {
	category?: string;
	segment?: string;
}

declare global {
	interface Window {
		StateHistory: {
			from: State[];
			fromParams: StateParams[];
			to: State[];
			toParams: StateParams[];
		};

		ShowSpinner: (LoadingString?: string, selector?: string) => void; // https://github.com/dnb-main/lat-leui/blob/develop/projects/atlas/app/common.utils.js#L19
		HideSpinner: (selector?: string) => void; // https://github.com/dnb-main/lat-leui/blob/develop/projects/atlas/app/common.utils.js#L14
	}
}

window.StateHistory = window.StateHistory || {
	from: [],
	fromParams: [],
	to: [],
	toParams: [],
};

const history = window.StateHistory;

const lastTo = (): State | undefined => history.to[history.to.length - 1];

const lastToParams = (): StateParams | undefined =>
	history.toParams[history.toParams.length - 1];

const lastFrom = (): State | undefined => history.from[history.from.length - 1];

const lastFromParams = (): StateParams | undefined =>
	history.fromParams[history.fromParams.length - 1];

const isTo = (name: string): boolean => lastTo()?.name === name;

const isFrom = (name: string): boolean => lastFrom()?.name === name;

const setTo = (state: State, params: StateParams): void => {
	history.to.push({...state, goParams: params});
	history.toParams.push(params);
};

const setFrom = (state: State, params: StateParams): void => {
	history.from.push({...state, goParams: params});
	history.fromParams.push(params);
};

const getFrom = (): State[] => history.from;

const fromPop = (): void => {
	history.from.pop();
	history.fromParams.pop();
};

const StateHistory = {
	lastTo,
	lastToParams,
	lastFrom,
	lastFromParams,
	isTo,
	isFrom,
	setTo,
	setFrom,
	getFrom,
	fromPop,
};

export default StateHistory;
