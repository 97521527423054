import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import React from 'common/react-vendor';
import type {
	BucketRestriction,
	InputRangeConfig,
	Val,
	InputChangeData,
} from '../../../../query.types';
import {schema, isFieldValid} from './numericalRange.validations';
import {getErrorMsg} from '../../percent/percentStore.helpers';
import './numericalRange.scss';

const {useState, useEffect, useMemo} = React;

interface NumericalRangeProps {
	config?: InputRangeConfig;
	showFrom?: boolean;
	fromLabel?: string;
	fromDisabled?: boolean;
	showTo?: boolean;
	toLabel?: string;
	toDisabled?: boolean;
	showMessage?: boolean;
	onChange(input: InputChangeData): void;
	bucketRestriction?: BucketRestriction;
}

const NumericalRange = ({
	config,
	showFrom,
	fromLabel,
	fromDisabled = false,
	showTo,
	toLabel,
	toDisabled = false,
	showMessage = true,
	onChange,
	bucketRestriction,
}: NumericalRangeProps): React.ReactElement => {
	const fromInputConfig = config?.from || {};
	const toInputConfig = config?.to || {};

	const isRangeConfig = showFrom && showTo;

	const errorMessage = getErrorMsg(showFrom, showTo);

	const [fromValue, setFromValue] = useState<Val | undefined>(
		fromInputConfig?.value
	);
	const [toValue, setToValue] = useState<Val | undefined>(toInputConfig?.value);

	const rangeSchema = useMemo(() => schema(config), [config]);

	const rangeValue = {
		showFrom,
		from: fromValue as number,
		showTo,
		to: toValue,
	};

	const isFromValid = isFieldValid({
		key: 'from',
		rangeSchema,
		rangeValue,
	});

	const isToValid = isFieldValid({
		key: 'to',
		rangeSchema,
		rangeValue,
	});

	useEffect(() => {
		if (
			(fromInputConfig.value === undefined || !showFrom) &&
			fromValue !== undefined
		) {
			setFromValue(undefined);
		} else if (
			fromInputConfig.initial !== undefined &&
			fromInputConfig.value !== fromValue
		) {
			setFromValue(fromInputConfig.value);
		}

		if (
			(toInputConfig.value === undefined || !showTo) &&
			toValue !== undefined
		) {
			setToValue(undefined);
		} else if (
			toInputConfig.initial !== undefined &&
			toInputConfig.value !== toValue
		) {
			setToValue(toInputConfig.value);
		}

		/**
		 * We only want to run this effect
		 * when the user changes the type
		 * of range (cmp) to clear the inputs.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bucketRestriction?.bkt?.Cmp, config]);

	return (
		<div className='numerical-container range-container'>
			{!isEmpty(fromLabel) && showFrom && <span> {fromLabel} </span>}

			{showFrom && (
				<input
					type='number'
					disabled={fromDisabled}
					name={fromInputConfig?.name}
					pattern={fromInputConfig?.pattern || ''}
					min={fromInputConfig?.min || ''}
					max={toValue || fromInputConfig?.max || ''}
					step={fromInputConfig?.step || 0.1}
					value={fromValue === undefined ? '' : fromValue}
					onChange={({target: {value}}) => {
						const newValue = value ? parseFloat(value) : undefined;

						onChange({
							index: 0,
							value: newValue,
							type: fromInputConfig?.type || '',
						});
						setFromValue(newValue);
					}}
					className={classnames({
						'invalid-field': !isFromValid,
						'field-disabled': fromDisabled,
					})}
					required={!fromDisabled}
				/>
			)}

			{isRangeConfig && <span> - </span>}

			{!isEmpty(toLabel) && (isRangeConfig || showTo) && <span>{toLabel}</span>}

			{showTo && (
				<input
					type='number'
					disabled={toDisabled}
					name={toInputConfig?.name}
					pattern={toInputConfig?.pattern || ''}
					min={fromValue || toInputConfig?.min || ''}
					max={toInputConfig?.max}
					step={toInputConfig?.step || 0.1}
					value={toValue === undefined ? '' : toValue}
					onChange={({target: {value}}) => {
						const newValue = value ? parseFloat(value) : undefined;

						onChange({
							index: 1,
							value: newValue,
							type: toInputConfig?.type || '',
						});
						setToValue(newValue);
					}}
					className={classnames({
						'invalid-field': !isToValid,
						'field-disabled': fromDisabled,
					})}
					required={!toDisabled}
				/>
			)}

			{showMessage && (!isFromValid || !isToValid) && (
				<span className='error-message'>{errorMessage}</span>
			)}
		</div>
	);
};

/**
 * TODO: Angular directive components can't use camelCase attributes...
 * Since we are migrating everything to React I feel that ideally
 * we should keep using camelCases for props, that is why I am creating this temporary
 * wrapper to pass the angular attributes to the React component...
 *
 * DONT use this component!
 * Use NumericalRange if you are refactoring an angular component to React.
 * This component purpose is only to be use in the temporary
 * numerical-range.component react2angular
 *
 * TODO: DELETE ME Once all instances of <numerical-range-directive />
 * are removed...
 */

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
const NumericalRangeWithAngularProps = ({
	config,
	showfrom,
	fromlabel,
	fromdisabled,
	showto,
	tolabel,
	todisabled,
	showmessage,
	changed,
	bucketrestriction,
}: Record<string, any>): React.ReactElement => (
	<NumericalRange
		config={config}
		showFrom={showfrom}
		fromLabel={fromlabel}
		fromDisabled={fromdisabled}
		showTo={showto}
		toLabel={tolabel}
		toDisabled={todisabled}
		showMessage={showmessage}
		onChange={changed}
		bucketRestriction={bucketrestriction}
	/>
);

export {NumericalRange, NumericalRangeWithAngularProps};
