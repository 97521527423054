import {store} from 'store';
import {setFeedbackModalPropertyValue} from 'common/stores/datacloud';
import template from './attributefeedbackbutton.component.html';

export default function () {
	return {
		restrict: 'EA',
		template,
		scope: {
			buttonType: '=?',
			attribute: '=',
			attributeKey: '=?',
			value: '=',
			iconImage: '=?',
			iconFont: '=?',
			categoryClass: '=?',
			label: '=?',
			showLookupStore: '=?',
			type: '=?',
		},
		controller: function ($scope) {
			'ngInject';

			$scope.buttonType = $scope.buttonType || 'infodot';

			$scope.menuClick = function ($event) {
				$event.stopPropagation();
				$scope.showMenu = !$scope.showMenu;
			};

			$scope.closeMenu = function () {
				$scope.showMenu = false;
			};

			$scope.leopen = function ($event) {
				$event.stopPropagation();
				$scope.closeMenu($event);

				store.dispatch(setFeedbackModalPropertyValue('show', true));
				store.dispatch(
					setFeedbackModalPropertyValue('context', {
						attribute: $scope.attribute,
						attributeKey: $scope.attributeKey,
						value: $scope.value,
						icon: {
							image: $scope.iconImage || '',
							font: $scope.iconFont || '',
						},
						categoryClass: $scope.categoryClass,
						label: $scope.label || '',
						showLookupStore: $scope.showLookupStore,
						type: $scope.type,
					})
				);
			};
		},
	};
}
