enum CronSettingType {
	Daily = 'Daily',
	Weekly = 'Weekly',
	Monthly = 'Monthly',
}

enum WeeklyType {
	Sunday = 'Sunday',
	Monday = 'Monday',
	Tuesday = 'Tuesday',
	Wednesday = 'Wednesday',
	Thursday = 'Thursday',
	Friday = 'Friday',
	Saturday = 'Saturday',
}

enum WeeklyShortType {
	SUN = 'SUN',
	MON = 'MON',
	TUE = 'TUE',
	WED = 'WED',
	THU = 'THU',
	FRI = 'FRI',
	SAT = 'SAT',
}

const CronSettingTypeConfig = {
	[CronSettingType.Daily]: CronSettingType.Daily,
	[CronSettingType.Weekly]: CronSettingType.Weekly,
	[CronSettingType.Monthly]: CronSettingType.Monthly,
};

const WeeklyTypeConfig = {
	[WeeklyType.Sunday]: {
		index: 1,
		short: 'SUN',
	},
	[WeeklyType.Monday]: {
		index: 2,
		short: 'MON',
	},
	[WeeklyType.Tuesday]: {
		index: 3,
		short: 'TUE',
	},
	[WeeklyType.Wednesday]: {
		index: 4,
		short: 'WED',
	},
	[WeeklyType.Thursday]: {
		index: 5,
		short: 'THU',
	},
	[WeeklyType.Friday]: {
		index: 6,
		short: 'FRI',
	},
	[WeeklyType.Saturday]: {
		index: 7,
		short: 'SAT',
	},
};

const WeeklyShortTypeConfig = {
	[WeeklyShortType.MON]: WeeklyType.Monday,
	[WeeklyShortType.TUE]: WeeklyType.Tuesday,
	[WeeklyShortType.WED]: WeeklyType.Wednesday,
	[WeeklyShortType.THU]: WeeklyType.Thursday,
	[WeeklyShortType.FRI]: WeeklyType.Friday,
	[WeeklyShortType.SAT]: WeeklyType.Saturday,
	[WeeklyShortType.SUN]: WeeklyType.Sunday,
};

enum MonthlyType {
	January = 'January',
	February = 'February',
	March = 'March',
	April = 'April',
	May = 'May',
	June = 'June',
	July = 'July',
	August = 'August',
	September = 'September',
	October = 'October',
	November = 'November',
	December = 'December',
}

const MonthlyTypeConfig = {
	[MonthlyType.January]: {
		index: 1,
		short: 'JAN',
	},
	[MonthlyType.February]: {
		index: 2,
		short: 'FEB',
	},
	[MonthlyType.March]: {
		index: 3,
		short: 'MAR',
	},
	[MonthlyType.April]: {
		index: 4,
		short: 'APR',
	},
	[MonthlyType.May]: {
		index: 5,
		short: 'MAY',
	},
	[MonthlyType.June]: {
		index: 6,
		short: 'JUN',
	},
	[MonthlyType.July]: {
		index: 7,
		short: 'JUL',
	},
	[MonthlyType.August]: {
		index: 8,
		short: 'AUG',
	},
	[MonthlyType.September]: {
		index: 9,
		short: 'SEP',
	},
	[MonthlyType.October]: {
		index: 10,
		short: 'OCT',
	},
	[MonthlyType.November]: {
		index: 11,
		short: 'NOV',
	},
	[MonthlyType.December]: {
		index: 12,
		short: 'DEC',
	},
};

const AllCronSettingList = Object.keys(CronSettingType);
const SupportedCronSettingList = [
	CronSettingType.Weekly,
	CronSettingType.Monthly,
];

const DefaultCronSetting = CronSettingType.Weekly;
const DefaultSecond = 0;
const DefaultMinute = 0;
const DefaultHour = 6;
const DefaultDay = 1;
const DefaultWeek = 2; // SUN = 1, MON = 2, etc...
const WeekDay = 'SUN,MON,TUE,WED,THU,FRI,SAT';
const OneDay = 24 * 60 * 60 * 1000;
const OneYear = 365 * OneDay;

export {
	CronSettingType,
	CronSettingTypeConfig,
	WeeklyType,
	WeeklyTypeConfig,
	WeeklyShortType,
	WeeklyShortTypeConfig,
	MonthlyType,
	MonthlyTypeConfig,
	DefaultSecond,
	DefaultMinute,
	DefaultHour,
	DefaultDay,
	DefaultWeek,
	WeekDay,
	OneDay,
	OneYear,
	DefaultCronSetting,
	AllCronSettingList,
	SupportedCronSettingList,
};
