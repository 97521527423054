import {CompareType, SortDirection} from '../Component/ConnectionSortBy';
import {IConnectionFilterConfig} from './ConnectionFilterType';
import {ObjectType} from './ConnectionType';
import {FieldFilterType} from './FieldFilterConst';
import {FieldType} from './FieldType';

interface ITableCustomStyleConfig {
	[key: string]: (data?: ObjectType) => boolean;
}

const TableCustomStyleConfig: ITableCustomStyleConfig = {
	[FieldType.IsEntityRelationConfirmed]: (data?: ObjectType): boolean => {
		return (
			!!data &&
			FieldType.IsEntityRelationConfirmed in data &&
			!data.IsEntityRelationConfirmed
		);
	},
};

interface IFilterHook {
	data: ObjectType;
	fieldFilterList: FieldFilterType[];
	filterConfig: IConnectionFilterConfig;
	search?: string;
}

const filterHook = ({
	data,
	fieldFilterList,
	filterConfig,
	search,
}: IFilterHook): boolean => {
	const isFiltered = fieldFilterList.every((filterType) => {
		const val = data[filterType] as string;
		return val && filterConfig[filterType]![val]?.checked;
	});
	const isSearched =
		isFiltered &&
		Object.keys(FieldType).some((key) => {
			if (typeof data[key] === 'string') {
				const val = data[key] as string;
				return (
					!search || (val && val.toLowerCase().includes(search.toLowerCase()))
				);
			}
			return false;
		});
	return isSearched;
};

interface ICompareHook {
	compareType: CompareType;
	sortDirection: SortDirection;
	lhs: unknown;
	rhs: unknown;
}

const compareHook = ({
	compareType,
	sortDirection,
	lhs,
	rhs,
}: ICompareHook): number => {
	const alpha = sortDirection === SortDirection.Ascend ? 1 : -1;
	if (compareType === CompareType.Alphabet) {
		return (
			((lhs as string) || '').localeCompare((rhs as string) || '', undefined, {
				numeric: true,
			}) * alpha
		);
	}
	if (compareType === CompareType.Numeric) {
		const result = (lhs as number) - (rhs as number);
		return result === 0 ? 0 : result * alpha;
	}
	return alpha;
};

export {TableCustomStyleConfig, filterHook, compareHook};
export type {IFilterHook, ICompareHook};
