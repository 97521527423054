import {Subject} from '../../network.vendor';

const init = () => {
	if (!window['subject']) {
		window['subject'] = new Subject();
	}
};

/**
 * http service
 */
const messageService = {
	unsubscribeObservable: (observer) => {
		observables.removeObservable(observer.getName());
	},
	addSubscriber: (observer) => {
		window['subject'].subscribe(observer);
	},
	removeSubscriber: () => {},
	sendMessage: (message) => {
		window['subject'].next(message);
	},
};

init();

export default messageService;
