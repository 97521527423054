import {FeatureFlags, isFeatureFlagEnabled} from 'common/stores/tenantConfig';
import RBAC, {
	AccessGroups,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {JourneyStageNgState} from '../../../atlas/app/helpers/NavigateHelper';

enum ROUTE_PAGE {
	// #region Attributes
	ATTRIBUTE_GROUP = 'attributeGroup',
	ACTIVATE_ATTRIBUTES = 'activateAttributes',
	ENABLE_ATTRIBUTES = 'enableAttributes',
	EDIT_ATTRIBUTES = 'editAttributes',
	CDP_ATTRIBUTES = 'cdpAttributes',
	CONFIGURE_ATTRIBUTES = 'configureAttributes',
	WEB_PERSONALIZATION = 'webPersonalization',
	// #endregion Attributes
	// #region Jobs
	DATA_PROCESSING = 'dataProcessing',
	MODEL_JOBS = 'modelJobs',
	EXPORT_JOBS = 'exportJobs',
	ORPHAN_JOBS = 'orphanJobs',
	// #endregion Jobs
	// #region Data Management
	IMPORT_DATA = 'importData',
	DELETE_NEW_DATA = 'deleteNewData',
	// #endregion Data Management
	// #region Connections
	CONNECTORS = 'connectors',
	// #endregion Connections
	// #region Settings
	MANAGE_USERS = 'manageUsers',
	MANAGE_TEAMS = 'manageTeams',
	SUBSCRIPTION = 'subscription',
	SSO_SETTING = 'ssoSetting',
	CONFIGURE_CALENDAR = 'configureCalendar',
	// #endregion Settings
	// #region Journey Stage
	JOURNEY_STAGE = 'journeyStage',
	JOURNEY_STAGE_CONFUGURATION = 'journeyStageConfiguration',
	JOURNEY_STAGE_QUERY = 'journeyStageQuery',
	// #endregion Journey Stage
}

const AdminRouteMap = new Map<ROUTE_PAGE, string>([
	// #region Attributes
	[ROUTE_PAGE.ATTRIBUTE_GROUP, 'home.admin.attributeGroup'],
	[ROUTE_PAGE.ACTIVATE_ATTRIBUTES, 'home.admin.activateAttributes'],
	[ROUTE_PAGE.ENABLE_ATTRIBUTES, 'home.admin.enableAttributes'],
	[ROUTE_PAGE.EDIT_ATTRIBUTES, 'home.admin.editAttributes'],
	[ROUTE_PAGE.CDP_ATTRIBUTES, 'home.admin.cdpAttributes'],
	[ROUTE_PAGE.CONFIGURE_ATTRIBUTES, 'home.admin.configureAttributes'],
	[ROUTE_PAGE.WEB_PERSONALIZATION, 'home.admin.webPersonalization'],

	// #endregion Attributes
	// #region Jobs
	[ROUTE_PAGE.DATA_PROCESSING, 'home.admin.jobsDataProcessing'],
	[ROUTE_PAGE.MODEL_JOBS, 'home.admin.jobsModelJobs'],
	[ROUTE_PAGE.EXPORT_JOBS, 'home.admin.jobsExportJobs'],
	[ROUTE_PAGE.ORPHAN_JOBS, 'home.admin.jobsOrphanExport'],
	// #endregion Jobs
	// #region Data Management
	[ROUTE_PAGE.IMPORT_DATA, 'home.admin.dataManagementImport'],
	[ROUTE_PAGE.DELETE_NEW_DATA, 'home.admin.dataManagementDeleteNew'],
	// #endregion Data Management
	// #region Connections
	[ROUTE_PAGE.CONNECTORS, 'home.admin.connection'],
	// #endregion Connections
	// #region Settings
	[ROUTE_PAGE.MANAGE_USERS, 'home.admin.settingsManageUsers'],
	[ROUTE_PAGE.MANAGE_TEAMS, 'home.admin.settingsManageTeams'],
	[ROUTE_PAGE.SUBSCRIPTION, 'home.admin.settingsSubscription'],
	[ROUTE_PAGE.SSO_SETTING, 'home.admin.settingsSSO'],
	[ROUTE_PAGE.CONFIGURE_CALENDAR, 'home.admin.settingsConfigureCalendar'],
	// #endregion Settings
]);

const NoAdminRouteMap = new Map<ROUTE_PAGE, string>([
	// #region Attributes
	[ROUTE_PAGE.ATTRIBUTE_GROUP, 'home.attributes.groups'],
	[ROUTE_PAGE.ACTIVATE_ATTRIBUTES, 'home.attributes.activate'],
	[ROUTE_PAGE.ENABLE_ATTRIBUTES, 'home.attributes.enable'],
	[ROUTE_PAGE.EDIT_ATTRIBUTES, 'home.attributes.edit'],
	[ROUTE_PAGE.CDP_ATTRIBUTES, 'home.attributes.schema'],
	[ROUTE_PAGE.CONFIGURE_ATTRIBUTES, 'home.attributes.configureattributes'],
	[ROUTE_PAGE.WEB_PERSONALIZATION, 'home.attributes.personalization'],
	// #endregion Attributes
	// #region Jobs
	[ROUTE_PAGE.DATA_PROCESSING, 'home.jobs.data'],
	[ROUTE_PAGE.MODEL_JOBS, 'home.jobs.status'],
	[ROUTE_PAGE.EXPORT_JOBS, 'home.jobs.export'],
	[ROUTE_PAGE.ORPHAN_JOBS, 'home.jobs.orphan'],
	// #endregion Jobs
	// #region Data Management
	[ROUTE_PAGE.IMPORT_DATA, 'home.multipletemplates'],
	[ROUTE_PAGE.DELETE_NEW_DATA, 'home.deletenew'],
	// #endregion Data Management
	// #region Connections
	[ROUTE_PAGE.CONNECTORS, 'home.connectors'],
	// #endregion Connections
	// #region Settings
	[ROUTE_PAGE.MANAGE_USERS, 'home.users'],
	[ROUTE_PAGE.MANAGE_TEAMS, 'home.teams'],
	[ROUTE_PAGE.SUBSCRIPTION, 'home.subscription'],
	[ROUTE_PAGE.SSO_SETTING, 'home.ssoconfig'],
	[ROUTE_PAGE.CONFIGURE_CALENDAR, 'home.import.calendar'],
	// #endregion Settings
	// #region Journey Stage
	[ROUTE_PAGE.JOURNEY_STAGE, JourneyStageNgState.Library],
	[ROUTE_PAGE.JOURNEY_STAGE_CONFUGURATION, JourneyStageNgState.Configuration],
	[ROUTE_PAGE.JOURNEY_STAGE_QUERY, JourneyStageNgState.Query],
	// #endregion Journey Stage
]);

const isNewAdminPageEnabled = (): boolean => {
	const isAdmin = RBAC.userHasAccessLevel(AccessGroups.ADMINS);
	const hasAccessToAdmin = RBAC.hasAccess(
		RBACInterface.ADMIN_MENU,
		RBACActions.VIEW
	);

	return (
		isFeatureFlagEnabled(FeatureFlags.NEW_ADMIN_PAGE) &&
		isAdmin &&
		hasAccessToAdmin
	);
};

const getAdminRoute = (page: ROUTE_PAGE): string => {
	if (isNewAdminPageEnabled()) {
		const adminRoute = AdminRouteMap.get(page);

		return adminRoute || '';
	}

	const noAdminRoute = NoAdminRouteMap.get(page);

	return noAdminRoute || '';
};

const isNoAdminRoute = (ref: string): boolean => {
	const excludeRoutes = ['home.connectors', 'home.attributes.schema'];
	const noAdminRoutes = Array.from(NoAdminRouteMap.values()).filter(
		(route) => !excludeRoutes.includes(route)
	);
	return ['home.attributes', ...noAdminRoutes].includes(ref);
};

type Config = Record<string, unknown>;

/**
 * @this Prototype bound from angular $stateProvider
 */
interface StateProvider {
	state(state: string, config: Config): void;
}

function setMultipleStates(
	this: StateProvider,
	states: string[],
	config: Config
): StateProvider {
	states.forEach((name) => {
		this.state(name, {...config});
	});

	return this;
}

export {
	ROUTE_PAGE,
	AdminRouteMap,
	NoAdminRouteMap,
	isNewAdminPageEnabled,
	getAdminRoute,
	setMultipleStates,
	isNoAdminRoute,
};
