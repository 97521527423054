import {
	LatticeInsightQueryMap,
	DataCollectionQueryMap,
} from './datacloud.constants';
import {
	getPremiumAttributesLimitationMap,
	getStatsTopN,
} from './datacloud.queries';
import {QueryType} from './datacloud.enums';
import {StatsTopNResponse} from './datacloud.types';

const getMaxEnrichAttributes = async (): Promise<number | null> =>
	getPremiumAttributesLimitationMap().then(
		(response) => response.MaxEnrichAttributes || null
	);

/**
 * TODO: Remove this code?
 * This function is being used to select the right endpoint
 * for the datacloud service.
 * I have checked the code and it appears that datacloud
 * is not available in the newer version of RevUp (Atlas 4.0).
 *
 * Maybe we can delete all datacloud code in common?
 *
 * This appears to be a copy/paste of the legacy-common code,
 * that might be the reason this exists in common.
 *
 * Lpi(the old version of atlas) is render for old tenants
 * (LPI 3.0). In there I can see the datacloud page, but in atlas
 * I get an error and the page doesn't render correctly.
 *
 * I also checked the FFs to check if datacloud is behind one
 * but I couldn't find any.
 *
 * Double check with QA and PM to see if we can safely delete
 * the datacloud code in atlas/common in a diff task.
 * END TODO: DELETE ME??
 */
const getQueryUrl = (query: QueryType): string => {
	const isLatticeInsights = window.location.pathname.includes('datacloud');

	if (isLatticeInsights) {
		return LatticeInsightQueryMap.get(query) || '';
	}

	return DataCollectionQueryMap.get(query) || '';
};

const getCategories = async (): Promise<
	StatsTopNResponse['Categories'] | Record<string, unknown>
> => getStatsTopN().then(({Categories = {}}) => Categories);

export {getMaxEnrichAttributes, getQueryUrl, getCategories};
