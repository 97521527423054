import isNil from 'lodash/isNil';
import type {
	PeriodParams,
	ChangeCmpParams,
	ChangeValueParams,
	GetValueParams,
	CmpValue,
} from 'components/datacloud/query/advanced/tree/date-attribute/dateAttribute.types';
import {
	BucketType,
	Period,
	BucketCmp,
} from 'components/datacloud/query/query.enums';
import type {Bkt, Val, Vals} from 'components/datacloud/query/query.types';

const dateAttributeMap: Partial<Record<BucketCmp, string>> = {
	[BucketCmp.AFTER]: 'After',
	[BucketCmp.AFTER_NEXT]: 'After Next',
	[BucketCmp.BEFORE]: 'Before',
	[BucketCmp.BEFORE_LAST]: 'Before Last',
	[BucketCmp.BETWEEN]: 'Between Last',
	[BucketCmp.BETWEEN_NEXT]: 'Between Next',
	[BucketCmp.BETWEEN_DATE]: 'Between',
	[BucketCmp.EVER]: 'Ever',
	[BucketCmp.IN_CURRENT_PERIOD]: 'Current',
	[BucketCmp.IS_EMPTY]: 'Is Empty',
	[BucketCmp.IS_EMPTY_CAPITALIZE]: 'Is Empty',
	[BucketCmp.LAST]: 'Last',
	[BucketCmp.LATEST_DAY]: 'Latest Day',
	[BucketCmp.PRIOR_ONLY]: 'Only Prior to Last',
	[BucketCmp.WITHIN]: 'Previous',
};

const mapPeriods = (): Array<PeriodParams> => {
	return Object.values(Period).map((period) => ({
		name: period,
		displayName: `${period}(s)`,
	}));
};

const getPeriod = (bkt?: Bkt): string => bkt?.Fltr?.Period || '';

const changeCmp = ({
	bkt,
	cmp,
	period,
	values,
}: ChangeCmpParams): Bkt | undefined => {
	if (!isNil(bkt)) {
		const newBkt = {...bkt};

		const Fltr = newBkt.Fltr ? {...newBkt.Fltr} : {};

		newBkt.Fltr = {
			...Fltr,
			Cmp: cmp,
			Period: period,
			Vals: values,
		};

		return newBkt;
	}
};

const changeValue = ({cmp, values, index, value}: ChangeValueParams): Vals => {
	const newValues = [...values];
	if (
		cmp === BucketCmp.BETWEEN ||
		cmp === BucketCmp.BETWEEN_DATE ||
		cmp === BucketCmp.BETWEEN_NEXT
	) {
		newValues[index] = value;
	} else {
		newValues[0] = value;
	}
	return newValues;
};

const getValue = ({type, cmp, bkt, index}: GetValueParams): Val => {
	if (
		type === BucketType.Date ||
		(type === BucketType.Numerical && !isNil(bkt))
	) {
		const values = bkt?.Fltr?.Vals || [];
		if (
			cmp === BucketCmp.BETWEEN ||
			cmp === BucketCmp.BETWEEN_NEXT ||
			cmp === BucketCmp.BETWEEN_DATE
		) {
			return values[index];
		}
		if (
			(cmp === BucketCmp.WITHIN ||
				cmp === BucketCmp.LAST ||
				cmp === BucketCmp.BEFORE_LAST ||
				cmp === BucketCmp.BEFORE ||
				cmp === BucketCmp.AFTER ||
				cmp === BucketCmp.AFTER_NEXT) &&
			(index === values.length - 1 || values.length === 0)
		) {
			return values[values.length - 1];
		}
	}
	return;
};

const getCmpValueReadable = (displayName: string, bkt: Bkt): CmpValue => {
	const btkFilter = {...bkt.Fltr};
	const cmp = dateAttributeMap[btkFilter.Cmp || ''];
	const values = btkFilter.Vals;
	const period = `${btkFilter.Period}(s)`;

	switch (bkt.Fltr?.Cmp) {
		case BucketCmp.EVER:
		case BucketCmp.IS_EMPTY:
		case BucketCmp.LATEST_DAY:
			return {
				label: `${displayName}: `,
				value: `${cmp}`,
			};

		case BucketCmp.LAST:
		case BucketCmp.WITHIN:
			return {
				label: `${displayName}: `,
				value: `${cmp} ${values} ${period}`,
			};

		case BucketCmp.BEFORE:
		case BucketCmp.AFTER:
			return {
				label: `${displayName}: `,
				value: `${cmp} ${values}`,
			};

		case BucketCmp.BETWEEN:
		case BucketCmp.BETWEEN_NEXT:
		case BucketCmp.BETWEEN_DATE: {
			const valuesRange = values?.length ? `${values[0]} - ${values[1]}` : '';
			return {
				label: `${displayName}: `,
				value: `${cmp} ${valuesRange}`,
			};
		}
		case BucketCmp.IN_CURRENT_PERIOD:
		default:
			return {
				label: `${displayName}: `,
				value: `${cmp} ${period}`,
			};
	}
};

export {
	dateAttributeMap,
	mapPeriods,
	getValue,
	getPeriod,
	changeCmp,
	changeValue,
	getCmpValueReadable,
};
