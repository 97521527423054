import React from 'common/react-vendor';
import {DNBTextField} from '@dnb-uux-design-system/react';
import {InputAdornment, SearchOutlinedIcon} from 'common/dnb-uux-vendor';

const SearchFilter = ({
	search,
	onChange,
	placeholder = 'Search',
}: {
	search: string;
	onChange: (v: string) => void;
	placeholder?: string;
}): JSX.Element => (
	<DNBTextField
		InputProps={{
			startAdornment: (
				<InputAdornment position='start'>
					<SearchOutlinedIcon />
				</InputAdornment>
			),
		}}
		size='small'
		placeholder={placeholder}
		onChange={(e) => {
			onChange(e.target.value);
		}}
		value={search}
	/>
);

export default SearchFilter;
