import {
	Restriction,
	Restrictions,
} from 'common/components/datacloud/query/query.types';
import {ObjectType} from 'atlas/connectors/EIF/Data/ConnectionType';
import {
	Campaign,
	launchedChannel,
} from 'atlas/playbook/content/campaignstable/CampaignsTable';
import {Connections} from 'atlas/playbook/content/metadataLiveramplaunch/metadata.enums';
import {AttributeEntityType} from './AttributeConst';
import {AIRatingRecord} from '../ratingsengine/ratingsengine.types';

enum FieldType {
	TEXT = 'TEXT',
	NUMBER = 'NUMBER',
	BOOLEAN = 'BOOLEAN',
	INTEGER = 'INTEGER',
	DATE = 'DATE',
}

interface IImportFieldMapping {
	concatFieldNames: string[];
	fieldName: string;
	fieldType: FieldType;
	matchFieldName: string;
	screenName: string;
	userFieldName: string;
}

interface IListSegmentConfig {
	displayNames: Record<string, string>;
	forceEntityMatch: boolean;
	generateRandomId: boolean;
	nameToFixedVal: Record<string, string>;
}

interface IErrorDetail {
	errorCode: string; // LEDP_00000
	errorMsg: string;
	stackTrace: string;
}

enum ExternalSystem {
	DataVision = 'DataVision',
	InternalDefault = 'InternalDefault',
}

enum ListSystemType {
	DataVision = 'DataVision',
	SSVI = 'SSVI',
	CompanyList = 'CompanyList',
	ContactList = 'ContactList',
	InternalDefault = 'InternalDefault',
}

interface IListSegment {
	accountMapping: IImportFieldMapping;
	config: IListSegmentConfig;
	contactMapping: IImportFieldMapping;
	csvAdaptor: {
		importFieldMappings: IImportFieldMapping[];
	};
	dataTemplates: Record<string, string>;
	errorDetail: IErrorDetail;
	externalSegmentId: string;
	externalSystem: ExternalSystem;
	hasEIFImport: boolean;
	last_imported: number;
	s3DropFolder: string;
	systemType: ListSystemType;
}

interface IAttributeLookup {
	attribute: string;
	entity: AttributeEntityType;
}

enum SegmentType {
	Query = 'Query',
	List = 'List',
	TAM = 'TAM',
}

/**
 * My Data - segments made from data imported by user
 * My Company List - csv file of accounts imported by user
 * My Contact List - csv file of contacts imported by user
 * Explore Module Accounts - an account list received from Explore Module (listSegment.systemType==='CompanyList' && listSegment.externalSystem==='DataVision')
 * Explore Module Contacts - a contact list received from Explore Module
 */
enum SegmentDataType {
	MyData = 'My Data',
	MyCompanyList = 'My Company List',
	MyContactList = 'My Contact List',
	ExploreModuleAccounts = 'Explore Module Accounts',
	ExploreModuleContacts = 'Explore Module Contacts',
}

interface ITeam {
	TeamId: string;
	TeamName: string;
	created: number;
}

type IStatus =
	| 'Importing'
	| 'Pending'
	| 'Duplicating'
	| 'Syncing'
	| 'Processing'
	| 'Initialized'
	| 'Failed'
	| 'Active'
	| 'Success';

type IChannel = {
	id: string;
	name: string;
	system: Connections;
};

/**
 * Segment campaign interface.
 * @param playLaunchChannels Launched channels.
 * @param numberOfCampaigns Number of campaigns [FE Computed Only].
 * @param channelList Channel list. [FE Computed Only].
 */
interface ISegmentCampaign {
	plays: Campaign[];
	numberOfCampaigns: number;
	channelList: IChannel[];
}

/**
 * Segment info interface
 * Refers to API pls/datacollection/segments
 * @param accountRestriction For FE use only.
 * @param accountRestrictionString Account restriction json string.
 * @param account_restriction Account restriction object.
 * @param accounts Number of accounts.
 * @param contactRestriction For FE use only.
 * @param contactRestrictionString Contact restriction json string.
 * @param contact_restriction Contact restriction object.
 * @param memberRestriction For FE use only.
 * @param memberRestrictionString Segment restriction json string.
 * @param member_restriction Segment restriction object.
 * @param isSubSegment True if it is a sub segment.
 * @param listSegment List segment object.
 * @param name Segment unique name, could be used as unique id.
 * @param parentSegments Parent segments list, the linked parent concept.
 * @param customParentSegmentName Single parent segment name, comparing to linked parent concept.
 * @param subSegments Sub segments list.
 * @param numberOfCampaigns Number of campaigns that use this segment.
 * @param playLaunchChannels Launched channels.
 * @param channelList Channel list computed from @playLaunchChannels FE only.
 */
interface ISegment extends ObjectType {
	accountRestriction: Restrictions;
	accountRestrictionString: string;
	account_restriction: {
		restriction: Restriction;
	};
	accounts: number;
	contactRestriction: Restrictions;
	contactRestrictionString: string;
	contact_restriction: {
		restriction: Restriction;
	};
	contacts: number;
	counts_outdated: boolean;
	created: number;
	created_by: string;
	dataType: string;
	deleted: boolean;
	description: string;
	display_name: string;
	forFrontEnd: boolean;
	isSubSegment: boolean;
	is_master_segment: boolean;
	listSegment?: IListSegment;
	masterSegment: boolean;
	materialized: boolean;
	memberRestriction: Restrictions;
	memberRestrictionString: string;
	member_restriction: {
		restriction: Restriction;
	};
	modelLabelQueryString: string;
	name: string;
	parentSegments: string[];
	customParentSegmentName: string;
	segment_attributes: IAttributeLookup;
	status: IStatus;
	subSegments: string[];
	team: ITeam;
	teamId: string;
	type: SegmentType;
	updated: number;
	updated_by: string;
	viewOnly: boolean;
	latest_iteration?: AIRatingRecord;
	ssviCardName?: string;
	numberOfCampaigns: number;
	playLaunchChannels?: launchedChannel[];
	channelList: IChannel[];
	plays: Campaign[];
}

/**
 * Refill segment with more information that easy for FE usage.
 * @param segment Input
 * @return Refilled segment.
 */
const RefillSegment = (segment: ISegment): ISegment => {
	return {
		...segment,
		status: segment?.status || 'Active',
		channelList:
			(segment.playLaunchChannels || []).map(({id, lookupIdMap}) => {
				return {
					id,
					system: lookupIdMap.externalSystemName as Connections,
					name: lookupIdMap.orgName,
				};
			}) || [],
	};
};

export type {
	IImportFieldMapping,
	IStatus,
	ISegment,
	ISegmentCampaign,
	ITeam,
	IChannel,
};
export {
	SegmentType,
	SegmentDataType,
	ListSystemType,
	ExternalSystem,
	RefillSegment,
};
