import React from 'common/react-vendor';
import styles from './IdaasMigration.module.css';

export const IdaasResetPasswordMessage = ({
	redirectToPasswordUpdate,
}: {
	redirectToPasswordUpdate: () => void;
}): React.ReactElement => (
	<section>
		<h1 className={styles.title}>Update password</h1>
		<p className={styles.paragraph}>
			Dun & Bradstreet is upgrading their identity management system to support{' '}
			<strong>a single email and password</strong> across all your D&B products.
			To support this experience, you need to update your password for access to
			D&B Rev.Up ABX.
		</p>
		<button
			className={styles.button}
			type='button'
			onClick={redirectToPasswordUpdate}>
			Update password
		</button>
	</section>
);
