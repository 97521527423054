import React from 'common/react-vendor';
import {MPSSortByAttribute} from './marketplace-segment-types';

interface MPSSortIndicatorProps {
	criteria: MPSSortByAttribute;
	header: MPSSortByAttribute;
	sortAsc: boolean;
}

export const MPSSortIndicator = ({
	criteria,
	header,
	sortAsc,
}: MPSSortIndicatorProps): React.ReactElement => {
	return (
		<span>
			{criteria === header && sortAsc && (
				<img src='/atlas/assets/icons/arrow-down.svg' alt='sort' />
			)}
			{criteria === header && !sortAsc && (
				<img src='/atlas/assets/icons/arrow-up.svg' alt='sort' />
			)}
			{criteria !== header && (
				<img src='/atlas/assets/icons/arrow-up-down.svg' alt='sort' />
			)}
		</span>
	);
};
