import {react2angular} from 'common/react-vendor';
import {
	AdminRouteMap,
	NoAdminRouteMap,
	ROUTE_PAGE,
	setMultipleStates,
} from 'common/app/utilities/AdminPageUtility';
import {isEIFInboundEnabled} from 'common/stores/tenantConfig';
import {SchemaPage} from './SchemaTabs';
import {SchemaRoutes} from './consts';

angular
	.module('common.attributes.schema', [])
	// fixme: what's the type
	.config(function ($stateProvider: {
		state: (namespace: string, config: unknown) => void;
		states: (namespace: string[], config: Record<string, unknown>) => void;
	}) {
		const stateProvider = {
			...$stateProvider,
			states: setMultipleStates,
		};

		const CDPAttributesNames = [
			AdminRouteMap.get(ROUTE_PAGE.CDP_ATTRIBUTES) || '',
			NoAdminRouteMap.get(ROUTE_PAGE.CDP_ATTRIBUTES) || '',
		];

		stateProvider
			.states(CDPAttributesNames, {
				url: '/schema',
				onEnter: [
					'$state',
					($state: {get: (urlDefine: string) => void}) => {
						if (!isEIFInboundEnabled()) {
							$state.get('home.attributes');
						}
					},
				],
				views: {
					'main@': 'latticeSchema',
				},
				redirectTo: SchemaRoutes.account,
			})
			.state(SchemaRoutes.account, {
				url: '/accounts',
			})
			// @ts-ignore
			.state(SchemaRoutes.contact, {
				url: '/contacts',
			})
			.state(SchemaRoutes.activity, {
				url: '/activities/:type',
				params: {
					type: {
						dynamic: true,
						value: '',
					},
				},
			})
			.state(SchemaRoutes.product, {
				url: '/products/:type',
				params: {
					type: {
						dynamic: true,
						value: '',
					},
				},
			})
			.state(SchemaRoutes.general, {
				url: '/general/:type',
				params: {
					type: {
						dynamic: true,
						value: '',
					},
				},
			});
	})
	.component('latticeSchema', react2angular(SchemaPage, [], ['$state']));
