import {ObjectType} from 'atlas/connectors/EIF/Data/ConnectionType';
import {LogicalRestriction} from 'common/components/datacloud/query/query.types';

/**
 * Segment query route param interface.
 * Route refers to @SEGMENTS_EXPLORER
 * @param segment Segment name. 'Create' or other name for 'Edit'.
 * @param subSegment PLEASE UPDATE IT LATER //TODO
 * @param newAccountRestriction PLEASE UPDATE IT LATER //TODO
 * @param isTAM True is TAM segment. COULD BE REMOVED? //TODO
 * @param customParentSegmentName Custom parent segment name Refers to @ISegment
 */
interface ISegmentQueryParam extends ObjectType {
	segment: string;
	subSegment?: string;
	ssviCardName?: string;
	newAccountRestriction?: LogicalRestriction;
	isTAM?: boolean;
	customParentSegmentName?: string;
}

interface ISegmentQueryHandler {
	isCreate: () => boolean;
	isCustomParentSegment: () => boolean;
}

/**
 * Segment name for creation.
 */
const SegmentCreate = 'Create';

/**
 * Segment Query Handler class.
 * Implement interface of @ISegmentQueryHandler
 * Wrap data of @ISegmentQueryParam
 */
class SegmentQueryHandler implements ISegmentQueryHandler {
	private data: ISegmentQueryParam;

	constructor(data: ISegmentQueryParam) {
		this.data = data;
	}

	isCreate = (): boolean => {
		const {segment} = this.data;
		return SegmentCreate === segment;
	};

	isCustomParentSegment = (): boolean => {
		const {customParentSegmentName} = this.data;
		return !!customParentSegmentName;
	};
}

export type {ISegmentQueryParam, ISegmentQueryHandler};
export {SegmentCreate, SegmentQueryHandler};
