import React from 'common/react-vendor';
import {Provider} from 'common/node_modules/react-redux';
import {
	DNBTabs,
	DNBTab,
	DNBTypography,
	DNBButton,
	SettingsOutlinedIcon,
} from 'common/dnb-uux-vendor';
import CustomCDPSchemaDialog from './components/CustomCDPSchemaDialog';
import AttributeList from './components/AttributeList';
import store from './store';
import {DateTimeFormatsProvider} from './context/DateTimeFormatsContext';
import {
	CustomSchemaProvider,
	useCustomSchema,
} from './context/CustomSchemaContext';
import styles from './SchemaTabs.module.scss';
import {
	ESchemaGroups,
	schemaTabConfig,
	customizableSchemaGroups,
	customizableSchemaGroupLabels,
	schemaGroupToDocumentGroup,
	CustomizableSchemaGroup,
} from './consts';
import {getAllConnections} from './services/apiCalls';
import {SystemType} from '../Data/SystemType';
import {DocumentType} from '../Data/DocumentType';
import {TSchemaType, ISchemaPageProps} from './schemaTypes';

/**
 * This is the Lattice Schema page container.
 */
export function SchemaTabs(props: ISchemaPageProps): React.ReactElement {
	const currentTabGroup =
		(props.$state.current.name.split('.').pop() as ESchemaGroups) ||
		ESchemaGroups.Account;
	const [currentTab, setCurrentTab] = React.useState(
		props.$state.params.type || schemaTabConfig[currentTabGroup][0]?.value
	);
	const [tabHiddenConfig, setTabHiddenConfig] = React.useState<
		Partial<Record<DocumentType, boolean>>
	>({
		[DocumentType.G2Intent]: true,
		[DocumentType.TrustRadiusIntent]: true,
	});
	const {customEvents, customObjects} = useCustomSchema();
	const [customDialogOpen, setCustomDialogOpen] = React.useState(false);
	const enableCustom =
		currentTabGroup === ESchemaGroups.Activity ||
		currentTabGroup === ESchemaGroups.General;
	const customizableSchemaGroup = currentTabGroup as CustomizableSchemaGroup;

	function onTabChange(type: DocumentType): void {
		props.$state.go(props.$state.current.name, {type});
		setCurrentTab(type);
	}

	React.useEffect(() => {
		getAllConnections()
			.then(({data}) =>
				setTabHiddenConfig({
					[DocumentType.G2Intent]: !data.some(
						({connectionType}) => connectionType === SystemType.G2
					),
					[DocumentType.TrustRadiusIntent]: !data.some(
						({connectionType}) => connectionType === SystemType.TrustRadius
					),
				})
			)
			.catch(() => undefined);
	}, []);

	return (
		<Provider store={store}>
			<DateTimeFormatsProvider>
				<div className={styles.container}>
					{customizableSchemaGroups.includes(currentTabGroup) && (
						<div className={styles.header}>
							<DNBTypography
								variant='h5'
								sx={{
									textTransform: 'capitalize',
								}}>{`${currentTabGroup} Attributes`}</DNBTypography>
							{enableCustom && (
								<DNBButton
									variant='secondary'
									size='compact'
									startIcon={<SettingsOutlinedIcon />}
									onClick={() =>
										setCustomDialogOpen(true)
									}>{`Custom ${customizableSchemaGroupLabels[customizableSchemaGroup]}`}</DNBButton>
							)}
							{enableCustom && (
								<CustomCDPSchemaDialog
									open={customDialogOpen}
									customType={
										schemaGroupToDocumentGroup[customizableSchemaGroup]
									}
									title={`Manage Custom ${customizableSchemaGroupLabels[customizableSchemaGroup]}`}
									onClose={() => setCustomDialogOpen(false)}
								/>
							)}
						</div>
					)}
					<DNBTabs
						value={currentTab}
						onChange={(_event, targetSchema: DocumentType) =>
							onTabChange(targetSchema)
						}>
						{schemaTabConfig[currentTabGroup].map(({value, label}) =>
							tabHiddenConfig[value] ? null : (
								<DNBTab
									key={value}
									label={label}
									value={value}
									component='button'
									{...{
										'id': `attribute-tab-${value}`,
										'aria-controls': `attribute-tab-${value}`,
										'tabIndex': 0,
									}}
								/>
							)
						)}
						{currentTabGroup === ESchemaGroups.Activity &&
							customEvents.map((customEvent) => (
								<DNBTab
									key={customEvent}
									label={customEvent}
									value={customEvent}
									component='button'
									{...{
										'id': `attribute-tab-${customEvent}`,
										'aria-controls': `attribute-tab-${customEvent}`,
										'tabIndex': 0,
									}}
								/>
							))}
						{currentTabGroup === ESchemaGroups.General &&
							customObjects.map((customObject) => (
								<DNBTab
									key={customObject}
									label={customObject}
									value={customObject}
									component='button'
									{...{
										'id': `attribute-tab-${customObject}`,
										'aria-controls': `attribute-tab-${customObject}`,
										'tabIndex': 0,
									}}
								/>
							))}
					</DNBTabs>
					<AttributeList schemaType={currentTab as TSchemaType} />
				</div>
			</DateTimeFormatsProvider>
		</Provider>
	);
}

export const SchemaPage = (props: ISchemaPageProps): React.ReactElement => (
	<CustomSchemaProvider>
		<SchemaTabs {...props} />
	</CustomSchemaProvider>
);
