import {NumericRangeState, formatMaxTime, formatMinTime} from './index';

export enum QuickType {
	ThisWeek = 'This Week',
	LastWeek = 'Last Week',
	ThisMonth = 'This Month',
	LastMonth = 'Last Month',
	Custom = 'Custom',
	Last7Days = 'Last 7 Days',
	ThisQuarter = 'This Quarter',
	LastQuarter = 'Last Quarter',
	ThisYear = 'This Year',
	LastYear = 'Last Year',
	Today = 'Today',
	Yesterday = 'Yesterday',
	Last14Days = 'Last 14 Days',
	Last28Days = 'Last 28 Days',
	Last30Days = 'Last 30 Days',
	Last90Days = 'Last 90 Days',
	Last180Days = 'Last 180 Days',
	Last365Days = 'Last 365 Days',
	YearToDate = 'Year To Date',
	PreviousWeek = 'Previous Week',
	PreviousMonth = 'Previous Month',
	PreviousQuarter = 'Previous Quarter',
	PreviousYear = 'Previous Year',
}

export type changeParamType = {
	type: QuickType;
	timeRange: NumericRangeState;
};

type DateType = Date | null;

const getQuarterStartMonth = (quarter: number): number => {
	return (quarter - 1) * 3;
};

export const getTimeFromQuickType = (
	type: QuickType,
	latestDay?: Date
): changeParamType => {
	const today = latestDay || new Date();
	const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
	const thisSunday = new Date(
		today.getTime() - today.getDay() * 24 * 60 * 60 * 1000
	);
	const thisQuarter = Math.ceil((new Date().getMonth() + 1) / 3);
	let lowDate: DateType = null;
	let highDate: DateType = null;
	const commonLowDate = (value: number): Date => {
		return new Date(today.getTime() - value * 24 * 60 * 60 * 1000);
	};
	switch (type) {
		case QuickType.Today:
			lowDate = today;
			highDate = today;
			break;

		case QuickType.Yesterday:
			lowDate = yesterday;
			highDate = yesterday;
			break;

		case QuickType.ThisWeek:
			lowDate = thisSunday;
			highDate = today;
			break;

		case QuickType.LastWeek:
		case QuickType.PreviousWeek:
			lowDate = new Date(thisSunday.getTime() - 7 * 24 * 60 * 60 * 1000);
			highDate = new Date(thisSunday.getTime() - 24 * 60 * 60 * 1000);
			break;

		case QuickType.ThisMonth:
			lowDate = new Date(today.getFullYear(), today.getMonth(), 1);
			highDate = today;
			break;

		case QuickType.LastMonth:
		case QuickType.PreviousMonth:
			highDate = new Date(
				new Date(today.getFullYear(), today.getMonth(), 1).getTime() -
					24 * 60 * 60 * 1000
			);
			lowDate = new Date(highDate.getFullYear(), highDate.getMonth(), 1);
			break;

		case QuickType.Last7Days:
			lowDate = commonLowDate(6);
			highDate = today;
			break;

		case QuickType.Last14Days:
			lowDate = commonLowDate(13);
			highDate = today;
			break;

		case QuickType.Last28Days:
			lowDate = commonLowDate(27);
			highDate = today;
			break;

		case QuickType.Last30Days:
			lowDate = commonLowDate(29);
			highDate = today;
			break;

		case QuickType.Last90Days:
			lowDate = commonLowDate(89);
			highDate = today;
			break;

		case QuickType.Last180Days:
			lowDate = commonLowDate(179);
			highDate = today;
			break;

		case QuickType.Last365Days:
			lowDate = commonLowDate(364);
			highDate = today;
			break;

		case QuickType.ThisQuarter:
			lowDate = new Date(
				today.getFullYear(),
				getQuarterStartMonth(thisQuarter),
				1
			);
			highDate = today;
			break;

		case QuickType.LastQuarter:
		case QuickType.PreviousQuarter:
			if (thisQuarter === 1) {
				lowDate = new Date(today.getFullYear() - 1, 9, 1);
				highDate = new Date(
					new Date(today.getFullYear(), 0, 1).getTime() - 24 * 60 * 60 * 1000
				);
			} else {
				lowDate = new Date(
					today.getFullYear(),
					getQuarterStartMonth(thisQuarter - 1),
					1
				);
				highDate = new Date(
					new Date(
						today.getFullYear(),
						getQuarterStartMonth(thisQuarter - 1) + 3,
						1
					).getTime() -
						24 * 60 * 60 * 1000
				);
			}
			break;

		case QuickType.ThisYear:
		case QuickType.YearToDate:
			lowDate = new Date(today.getFullYear(), 0, 1);
			highDate = today;
			break;

		case QuickType.LastYear:
		case QuickType.PreviousYear:
			lowDate = new Date(today.getFullYear() - 1, 0, 1);
			highDate = new Date(
				new Date(today.getFullYear(), 0, 1).getTime() - 24 * 60 * 60 * 1000
			);
	}

	return {
		type,
		timeRange:
			lowDate && highDate
				? {
						low: formatMinTime(lowDate.getTime()),
						high: formatMaxTime(highDate.getTime()),
				  }
				: null,
	};
};
