import React from 'common/react-vendor';
import {
	AddOutlinedIcon,
	BackspaceOutlinedIcon,
	ContentCopyOutlinedIcon,
	DNBButtonProps,
	IosShareIcon,
	RefreshIcon,
	SaveOutlinedIcon,
} from 'common/dnb-uux-vendor';
import {
	ISegmentQueryParam,
	SegmentCreate,
} from 'common/components/datacloud/segment/SegmentQueryHelper';

/**
 * Export button setting.
 */
const ExportButton = (): DNBButtonProps => ({
	variant: 'secondary',
	startIcon: <IosShareIcon />,
	size: 'compact',
	title: 'Export',
});

const segmentQueryWithCustomParent = (
	segmentName: string
): ISegmentQueryParam => ({
	segment: SegmentCreate,
	customParentSegmentName: segmentName,
});

const getCreateButton = (title: string): DNBButtonProps => {
	return {
		variant: 'primary',
		startIcon: <AddOutlinedIcon />,
		size: 'compact',
		title,
	};
};

/**
 * Create subsegment button setting.
 * @param segment @ISegment
 */
const CreateSubsegmentButton = (): DNBButtonProps =>
	getCreateButton('Create New Subsegment');

/**
 * Duplicate segment button.
 */
const DuplicateSegmentButton = (): DNBButtonProps => ({
	variant: 'secondary',
	startIcon: <ContentCopyOutlinedIcon />,
	size: 'compact',
	title: 'Duplicate',
});

/**
 * Clear segment button.
 */
const ClearSegmentButton = (): DNBButtonProps => ({
	variant: 'secondary',
	startIcon: <BackspaceOutlinedIcon />,
	size: 'compact',
	title: 'Clear',
});

/**
 * Refresh query button.
 */
const RefreshCountButton = (): DNBButtonProps => ({
	variant: 'secondary',
	startIcon: <RefreshIcon />,
	size: 'compact',
	title: 'Refresh',
});

/**
 * Save segment button.
 */
const SaveSegmentButton = (): DNBButtonProps => ({
	variant: 'primary',
	startIcon: <SaveOutlinedIcon />,
	size: 'compact',
	title: 'Save Segment',
});

/**
 * Save stage button.
 */
const SaveStageButton = (): DNBButtonProps => ({
	variant: 'primary',
	startIcon: <SaveOutlinedIcon />,
	size: 'compact',
	title: 'Save Substage',
});

export {
	getCreateButton,
	segmentQueryWithCustomParent,
	ExportButton,
	CreateSubsegmentButton,
	DuplicateSegmentButton,
	ClearSegmentButton,
	RefreshCountButton,
	SaveSegmentButton,
	SaveStageButton,
};
