import React, {react2angular} from 'common/react-vendor';
import {SamlLogin} from 'common/app/services/LoginService';
import NgState from '../../../ng-state';

const {useEffect} = React;

interface User {
	DisplayName: string;
	EmailAddress: string;
}

interface Result {
	User: User;
}

export interface UserDocument {
	Result: Result;
	Ticket: Ticket;
}

interface Ticket {
	Uniqueness: string;
	Randomness: string;
}

const makeUserDocument = (rawUserDocument: string | null): UserDocument => {
	return rawUserDocument ? JSON.parse(rawUserDocument) : [];
};

export const Saml = (): JSX.Element => {
	const {tenantId} = NgState.getAngularState().params;
	const userDocument = makeUserDocument(
		new URLSearchParams(window.location.search).get('userDocument')
	);

	useEffect(() => {
		SamlLogin(userDocument)
			.then(() => {
				NgState.getAngularState().go('login.tenants');
			})
			.catch((err: Error) => console.error(err));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='login-saml-content'>
			<h2>SAML login</h2>
			<dl>
				<dt>Tenant Id</dt>
				<dd>{tenantId}</dd>

				<dt>Email</dt>
				<dd>
					{userDocument?.Result?.User?.DisplayName}
					{userDocument?.Result?.User?.EmailAddress}
				</dd>

				<dt>Randomness + Uniquness</dt>
				<dd>
					{userDocument?.Ticket?.Uniqueness}.
					<span className='randomness'>{userDocument?.Ticket?.Randomness}</span>
				</dd>
			</dl>
		</div>
	);
};

angular.module('login.saml', []).component('loginSaml', react2angular(Saml));
