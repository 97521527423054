import {titleCase} from 'title-case';
import {getString} from 'common/app/utilities/ResourceUtility';
import {AxiosResponse} from 'common/network.vendor';
import NgState from 'atlas/ng-state';
import type {System} from '../delete/DeleteDataService.types';

import {
	getLatticeSchema as getLatticeSchemaQuery,
	getExternalSystemMappings as getExternalSystemMappingsQuery,
	getPeriodsCalendar,
	getPeriodsDateRange,
	postFileFieldMappings,
	postFieldMappings,
	postValidateFileFieldMappings,
	postTemplate,
	postValidateCalendar,
	postPeriodsCalendar,
	postSystemMappingList,
} from './import.queries';
import {
	IMPORT_ENTITY,
	UNSPECIFIED_ERROR,
	PARDOT_SPEC_DATA,
	ELOQUA_SPEC_DATA,
	MARKETO_SPEC_DATA,
	LATTICE_SPEC_DATA,
	SALES_ACTIVITY_EVENT_SPEC_DATA,
	SALES_ACTIVITY_TASK_SPEC_DATA,
	TEMPLATE_TEXT,
} from './import.constants';
import {wizardEntitySteps} from './importWizard.constants';
import type {
	GetLatticeSchemaResult,
	GetFileFieldsReturn,
	PostFileFieldMappingsResult,
	PostFieldMappingsRequestParams,
	PostValidateFileFieldMappingsResponse,
	TemplateData,
	PeriodsCalendar,
	PostValidateCalendarResponse,
	GetPeriodsDateRangeResponse,
	PostSystemMappingListOpts,
	WizardEntity,
	EntitySteps,
	SpecTypeData,
	TemplateTextData,
} from './import.types';
import ImportUtils from './ImportUtils';
import type {Fields, Field} from './ImportUtils.types';
import {
	getImportProperty,
	getRouteSavedDocument,
	dispatchFieldDocumentSaved,
	dispatchFieldDocumentValues,
	dispatchImportPropertyValue,
	dispatchValidation,
	SaveObjects,
	FieldDocument,
	FieldDocumentSaved,
} from '../stores/import';

export const handlePromiseRejected = (reason?: AxiosResponse): string =>
	reason?.data?.errorMsg || UNSPECIFIED_ERROR;

// #region queries result handlers

/**
 * Once we start consuming 'getLatticeSchema' helper in TS files
 * we are going to be able to pass the expected Import Entity
 * which is going to help with the Intellisense, and help to
 * skip doing type assertions, i.e:
 *
 * getLatticeSchema<IMPORT_ENTITY.ACCOUNT>(IMPORT_ENTITY.ACCOUNT, 'file').then(
 *  (response) => (typeof response === 'string' ? response : response.Account)
 * )
 *
 * or
 *
 * getLatticeSchema<IMPORT_ENTITY.CONTACT>(IMPORT_ENTITY.CONTACT, 'file').then(
 *  (response) => (typeof response === 'string' ? response : response.Contact)
 * )
 */
const getLatticeSchema = async <E extends IMPORT_ENTITY>(
	entity?: IMPORT_ENTITY,
	feedType?: string
): Promise<GetLatticeSchemaResult<E> | string> =>
	getLatticeSchemaQuery<E>(entity, feedType).then((data) => {
		if (data?.Success === true) {
			ImportUtils.setLatticeSchema(data.Result);

			return data.Result;
		}

		const [errorMsg = UNSPECIFIED_ERROR] = data?.Errors || [];

		return errorMsg;
	}, handlePromiseRejected);

const getExternalSystemMappings = async (
	entity?: IMPORT_ENTITY,
	feedType?: string,
	includeAll?: boolean
): Promise<Fields | string> =>
	getExternalSystemMappingsQuery(entity, feedType, includeAll).then(
		(response) => {
			if (response.data && response.status === 200) {
				return response.data;
			}

			const [errorMsg = UNSPECIFIED_ERROR] = response?.data?.Errors || [];

			return errorMsg;
		},
		handlePromiseRejected
	);

const getFileFields = async (
	fileName: string,
	entity?: IMPORT_ENTITY,
	schema?: string,
	feedType?: string
): Promise<GetFileFieldsReturn> =>
	postFileFieldMappings(fileName, entity, schema, feedType).then(
		(data) => {
			if (data === null || !data.Success) {
				return {
					Success: false,
					ResultErrors:
						data?.Errors?.length > 0
							? data.Errors.join('\n')
							: getString('UNEXPECTED_SERVICE_ERROR'),
					Result: null,
				};
			}

			const {get} = NgState.getAngularState();

			const homeImport = get('home.import');

			if (homeImport?.data?.redux) {
				homeImport.data.redux.setInitialMapping(
					ImportUtils.getOriginalMapping(
						entity || IMPORT_ENTITY.ACCOUNT,
						data.Result.fieldMappings
					)
				);
			}

			return {
				Success: true,
				ResultErrors: data.Errors,
				Result: data.Result,
			};
		},
		(response) => {
			return {
				Success: false,
				ResultErrors: response?.data?.errorMsg,
			};
		}
	);

const saveFieldMappings = async (
	fileName: string,
	fieldDocument: PostFileFieldMappingsResult,
	params?: PostFieldMappingsRequestParams,
	forModeling?: boolean
): Promise<void | string> =>
	postFieldMappings(fileName, fieldDocument, params, forModeling).then(() => {
		return;
	}, handlePromiseRejected);

const validateFileFieldMappings = async (
	fileName: string,
	templateData: TemplateData,
	fieldDocument: PostFileFieldMappingsResult
): Promise<PostValidateFileFieldMappingsResponse | string> =>
	postValidateFileFieldMappings(fileName, templateData, fieldDocument).then(
		(data) => data,
		handlePromiseRejected
	);

const templateDataIngestion = async (
	fileName: string,
	importOnly: boolean,
	autoImportData: boolean,
	templateData: TemplateData
): Promise<void | string> =>
	postTemplate(fileName, importOnly, autoImportData, templateData).then(
		(data) => data,
		handlePromiseRejected
	);

const getCalendar = async (): Promise<Partial<PeriodsCalendar> | string> =>
	getPeriodsCalendar().then((data) => data || {}, handlePromiseRejected);

const validateCalendar = async (
	calendar: PeriodsCalendar
): Promise<PostValidateCalendarResponse | string> =>
	postValidateCalendar(calendar).then(
		(data) => data || {},
		handlePromiseRejected
	);

const saveCalendar = (
	calendar: PeriodsCalendar
): Promise<PeriodsCalendar | string> =>
	postPeriodsCalendar(calendar).then(
		(data) => data || {},
		handlePromiseRejected
	);

const getDateRange = async (
	year: number
): Promise<GetPeriodsDateRangeResponse | string> =>
	getPeriodsDateRange(year).then((data) => data || [], handlePromiseRejected);

const getSystemMappingList = async (
	opts?: PostSystemMappingListOpts
): Promise<System[] | string> =>
	postSystemMappingList(opts).then((data) => data || [], handlePromiseRejected);
// #endregion queries result handlers

// #region saveAndGoNextState
const saveDocumentFields = (state: string): void => {
	const period = state.lastIndexOf('.');
	const formerState = state.substring(0, period);

	const saveObject = getImportProperty<SaveObjects>('saveObjects')[state];

	let savedDocument = getRouteSavedDocument(formerState);

	if (saveObject) {
		savedDocument = ImportUtils.updateDocumentMapping(
			getImportProperty('entityType'),
			saveObject,
			savedDocument
		);
	}

	if (savedDocument) {
		dispatchFieldDocumentSaved(state, savedDocument);
	}
};

const saveAndGoNextState = (nextState?: string): void => {
	const {go, current} = NgState.getAngularState();

	saveDocumentFields(current.name);

	if (nextState) {
		dispatchFieldDocumentSaved(nextState, getRouteSavedDocument(current.name));

		go(nextState);
	}
};
// #endregion saveAndGoNextState

// #region mergeFieldDocument
const mapMultidimensionalAppendItems = (
	saveObjects: Fields[],
	currentFieldMappings: Fields
): Fields =>
	saveObjects.reduce((fields, savedFields) => {
		const appendFields = savedFields.reduce((appendFields, field) => {
			if (field.originalUserField) {
				/**
				 * if it has an orginal user field, like in the ID step, find the original field mapping and unmap it and set up the new mapping to be appended
				 */
				const currentField = currentFieldMappings.find(function (tmpField) {
					return (
						tmpField.userField === field.originalUserField &&
						tmpField.mappedField === field.mappedField
					);
				});

				const newField = {...currentField};

				if (
					field &&
					currentField &&
					(field.userField !== currentField.userField ||
						field.mappedField !== currentField.mappedField)
				) {
					currentField.mappedField = null; // if it's different, unmap it here
					currentField.mappedToLatticeField = false;

					if (field.append) {
						// and set it up to be append to fieldMappings later
						newField.userField = field.userField;
						newField.mappedField = field.mappedField;

						return [...appendFields, newField];
					}
				}
			} else if (field.append) {
				/**
				 * if it's new item, such as a third party id, append it
				 */
				const currentField = currentFieldMappings.find(
					(tmpField) => tmpField.userField === field.userField
				);

				return [...appendFields, {...currentField, ...field}];
			}

			return [...appendFields];
		}, [] as Fields);

		return [...fields, ...appendFields];
	}, [] as Fields);

interface SegmentResolve {
	main: Fields;
	appended: Fields;
}

type MergeFieldDocumentResolve = SegmentResolve | Fields;

interface MergeFieldDocumentOpts {
	segment: boolean;
	save: boolean;
}
const mergeFieldDocument = (
	opts?: MergeFieldDocumentOpts
): Promise<MergeFieldDocumentResolve> => {
	return new Promise<MergeFieldDocumentResolve>((resolve) => {
		const save = opts?.save !== false;
		const segment = opts?.segment || false;

		const {fieldMappings = []} =
			getImportProperty<FieldDocument>('fieldDocument') || {};

		const currentFieldMappings = [...fieldMappings];

		const appendItems = mapMultidimensionalAppendItems(
			Object.values(getImportProperty<SaveObjects>('saveObjects')),
			currentFieldMappings
		);

		if (segment) {
			resolve({main: currentFieldMappings, appended: appendItems});
		}

		const missingFields = appendItems.reduce((fields, field) => {
			const {userField, mappedField} = field;

			const hasField = currentFieldMappings.some(
				(tmpField) =>
					tmpField.userField === userField &&
					tmpField.mappedField === mappedField
			);

			if (!hasField) {
				return [...fields, {...field}];
			}

			return [...fields];
		}, [] as Fields);

		const tmpFieldMappings = [...currentFieldMappings, ...missingFields];

		if (save) {
			dispatchFieldDocumentValues({fieldMappings: tmpFieldMappings});
		}

		resolve(tmpFieldMappings);
	});
};
// #endregion mergeFieldDocument

const mergeAndGoNextState = (nextState: string): void => {
	const fieldDocument = getImportProperty<FieldDocument>('fieldDocument');

	const {go, current} = NgState.getAngularState();

	dispatchFieldDocumentValues({
		fieldMappings: getRouteSavedDocument(current.name),
		ignoredFields:
			fieldDocument?.ignoredFields === null ? [] : fieldDocument?.ignoredFields,
	});

	mergeFieldDocument()
		.then(() => {
			saveFieldMappings(
				getImportProperty('csvFileName'),
				getImportProperty('fieldDocument'),
				{
					feedType: getImportProperty('feedType'),
					entity: getImportProperty('entityType'),
				}
			);

			dispatchValidation('jobstatus', true);

			go(nextState);
		})
		.catch(
			(reason) => console.error(reason) // mergeFieldDocument never gets rejected, but the linter wants a catch
		);
};

const getWizardEntityStep = (step: WizardEntity): EntitySteps =>
	wizardEntitySteps[step || 'account'];

const getSpecTypeData = (specType: string): SpecTypeData => {
	switch (specType.toLowerCase()) {
		case 'pardot':
			return PARDOT_SPEC_DATA;
		case 'eloqua':
			return ELOQUA_SPEC_DATA;
		case 'marketo':
			return MARKETO_SPEC_DATA;
		case 'salesactivityevent':
			return SALES_ACTIVITY_EVENT_SPEC_DATA;
		case 'salesactivitytask':
			return SALES_ACTIVITY_TASK_SPEC_DATA;
		case 'lattice':
		default:
			return LATTICE_SPEC_DATA;
	}
};

const getTemplateText = (): TemplateTextData | null => {
	const templateData = getImportProperty<TemplateData>('templateData');

	if (templateData?.Object) {
		return TEMPLATE_TEXT[templateData.Object] || null;
	}

	return null;
};

const setSaveObject = (fields: Fields, key?: string): void => {
	const routeName = key || NgState.getAngularState().current.name || 'unknown';

	const newSaveObjects = getImportProperty<SaveObjects>('saveObjects');

	newSaveObjects[routeName] = fields;

	dispatchImportPropertyValue('saveObjects', newSaveObjects);
};

// #region deleteRouteFieldsFromImportStore
const deleteRouteFromSavedObject = (routeName: string): void => {
	const newSaveObjects = {...getImportProperty<SaveObjects>('saveObjects')};

	const keys = Object.keys(newSaveObjects);

	if (keys.length > 0) {
		delete newSaveObjects[routeName];

		dispatchImportPropertyValue('saveObjects', newSaveObjects);
	}
};

const deleteRouteFromDocumentSaved = (routeName: string): void => {
	const newFieldDocumentSaved = {
		...getImportProperty<FieldDocumentSaved>('fieldDocumentSaved'),
	};

	const keys = Object.keys(newFieldDocumentSaved);

	if (keys.length > 0) {
		delete newFieldDocumentSaved[routeName];

		dispatchImportPropertyValue('fieldDocumentSaved', newFieldDocumentSaved);
	}
};

const deleteRouteFieldsFromImportStore = (routeName: string): void => {
	deleteRouteFromSavedObject(routeName);
	deleteRouteFromDocumentSaved(routeName);
};
// #endregion deleteRouteFieldsFromImportStore

const getImportCalendar =
	async (): Promise<Partial<PeriodsCalendar> | void> => {
		const calendar = getImportProperty<PeriodsCalendar | null>('calendar');

		if (calendar) {
			return calendar;
		}

		return getCalendar().then((result) => {
			if (typeof result === 'object') {
				dispatchImportPropertyValue('calendar', {...result});

				return result;
			}
		});
	};

const saveImportCalendar = (calendar: PeriodsCalendar): Promise<void> =>
	saveCalendar(calendar).then((result) => {
		if (typeof result === 'object') {
			dispatchImportPropertyValue('calendar', {...result});
		}
	});

// #region getCalendarInfo
interface GetCalendarInfoReturn {
	modeDisplayName: string;
	mode: string;
}

const getCalendarInfo = (calendar?: PeriodsCalendar): GetCalendarInfoReturn => {
	if (calendar?.mode) {
		const mode = calendar.mode === 'STANDARD' ? 'STANDARD' : 'CUSTOM';

		return {
			modeDisplayName: titleCase(mode),
			mode,
		};
	}

	return {
		modeDisplayName: titleCase('NONE'),
		mode: 'NONE',
	};
};
// #endregion getCalendarInfo

// #region getMatchIdsItems
interface MatchIdsItem {
	userField?: string;
	systemName?: string;
}

type MatchIdsItems = MatchIdsItem[];

const getMatchIdsItems = (
	fieldsMappings: Fields,
	systemSelected: System | null = null,
	templateType: IMPORT_ENTITY
): MatchIdsItems =>
	fieldsMappings.reduce((items, field) => {
		if (
			systemSelected &&
			field.systemName &&
			field.idType === templateType &&
			field.systemName !== systemSelected.name
		) {
			return [
				...items,
				{
					userField: field.userField,
					systemName: field.systemName,
				},
			];
		}

		return [...items];
	}, [] as MatchIdsItems);
// #endregion getMatchIdsItems

const findSavedField = (
	savedObject: Fields,
	key: string,
	userFieldName?: string
): Field | undefined =>
	savedObject.find((field) => field[key] === userFieldName);

const updateSavedObjectCustomFields = (field: Field): void => {
	const {
		current: {name},
	} = NgState.getAngularState();

	const savedObject = getImportProperty<SaveObjects>('saveObjects')[name];

	if (savedObject) {
		const savedField = findSavedField(
			savedObject,
			'userField',
			field.userField
		);

		if (savedField === undefined) {
			setSaveObject([...savedObject, {...field}], name);
		} else {
			const modifiedField = {...field};

			if (field.type !== 'DATE') {
				modifiedField.dateFormatString = undefined;
				modifiedField.timeFormatString = undefined;
				modifiedField.timezone = undefined;
			}

			setSaveObject(
				[
					...savedObject,
					{
						...savedField,
						dateFormatString: modifiedField.dateFormatString || null,
						timeFormatString: modifiedField.timeFormatString || null,
						timezone: modifiedField.timezone || null,
						type: field.type || savedField.type,
					},
				],
				name
			);
		}
	} else {
		setSaveObject([{...field}], name);
	}
};

const updateSavedObjects = (fields: Fields): void => {
	const {
		current: {name},
	} = NgState.getAngularState();

	const savedObject = getImportProperty<SaveObjects>('saveObjects')[name];

	if (savedObject) {
		const newObject = fields.reduce((newFields, field) => {
			const savedField = findSavedField(
				savedObject,
				'userField',
				field.userField
			);

			if (savedField === undefined) {
				return [...newFields, field];
			}

			if (
				savedField !== undefined &&
				savedField.mappedField !== field.mappedField
			) {
				savedField.mappedField = field.mappedField;

				savedField.dateFormatString = field.dateFormatString || null;
				savedField.timeFormatString = field.timeFormatString || null;
				savedField.timezone = field.timezone || null;

				savedField.type = field.type || savedField.type;

				return [...newFields, savedField];
			}

			if (
				savedField !== undefined &&
				savedField.mappedField === field.mappedField
			) {
				savedField.dateFormatString =
					savedField.dateFormatString === undefined
						? field.dateFormatString || null
						: savedField.dateFormatString;

				savedField.timeFormatString =
					savedField.timeFormatString === undefined
						? field.timeFormatString || null
						: savedField.timeFormatString;

				savedField.timezone =
					savedField.timezone === undefined
						? field.timezone || null
						: savedField.timezone;

				savedField.type = field.type ? field.type : savedField.type;

				return [...newFields, savedField];
			}

			return [...newFields];
		}, [] as Fields);

		setSaveObject(newObject, name);
	} else {
		setSaveObject([...fields], name);
	}
};

export {
	getLatticeSchema,
	getExternalSystemMappings,
	getFileFields,
	saveFieldMappings,
	validateFileFieldMappings,
	templateDataIngestion,
	getCalendar,
	validateCalendar,
	saveCalendar,
	getDateRange,
	getSystemMappingList,
	saveAndGoNextState,
	mergeFieldDocument,
	mergeAndGoNextState,
	getWizardEntityStep,
	getSpecTypeData,
	getTemplateText,
	setSaveObject,
	deleteRouteFieldsFromImportStore,
	getImportCalendar,
	saveImportCalendar,
	getCalendarInfo,
	getMatchIdsItems,
	findSavedField,
	updateSavedObjectCustomFields,
	updateSavedObjects,
};
