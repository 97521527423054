import template from './sidebar.component.html';
import {invalidateAllAttributeGroups} from '../../query/query';
import NoticeService from '../../../notice/NoticeService';
import AttrConfigService from '../AttrConfigService';

angular
	.module('common.attributes.sidebar', [])
	.component('attrSidebar', {
		template,
		bindings: {
			attributeSets: '<',
			selectedAttributeSet: '<',
			onSelectAttributeSet: '<',
			isLoadingNewAttributeSet: '<',
		},
		controller() {
			const vm = this;
			const MAX_NUM_ATTRIBUTE_SETS = 50;

			vm.$onInit = function () {
				vm.selectedAttributeSetName = vm.selectedAttributeSet.name;
				vm.pageSize = 30;
				vm.showPagination = vm.attributeSets.length > vm.pageSize;
				vm.numPages = Math.ceil(vm.attributeSets.length / vm.pageSize);

				const attributeSetIndex = vm.attributeSets.findIndex(
					({name}) => name === vm.selectedAttributeSetName
				);
				vm.currentPage = Math.floor(attributeSetIndex / vm.pageSize);
			};

			vm.onClickAttributeSet = ($event) => {
				if (!vm.isLoadingNewAttributeSet) {
					vm.onSelectAttributeSet($event.target.dataset.attributeSetName);
				}
			};

			vm.generateNewDefaultAttributeSetName = () => {
				const existingDisplayNames = vm.attributeSets.map(
					({displayName}) => displayName
				);
				let nextAvailableNumber = vm.attributeSets.length + 1;
				let newDefaultAttributeSetName = `Attribute Group ${nextAvailableNumber}`;

				while (existingDisplayNames.includes(newDefaultAttributeSetName)) {
					nextAvailableNumber++;
					newDefaultAttributeSetName = `Attribute Group ${nextAvailableNumber}`;
				}

				return newDefaultAttributeSetName;
			};

			vm.onClickCreate = () => {
				if (!vm.checkIfUserHasTooManyAttributeSets()) {
					AttrConfigService.createNewAttributeSet(
						vm.generateNewDefaultAttributeSetName()
					).then((res) => {
						invalidateAllAttributeGroups();
						vm.selectNewlyCreatedAttributeSet(res);
					});
				}
			};

			vm.checkIfUserHasTooManyAttributeSets = () => {
				const hasTooManyAttributeSets =
					vm.attributeSets.length === MAX_NUM_ATTRIBUTE_SETS;

				if (hasTooManyAttributeSets) {
					NoticeService.warning({
						message:
							'You have reached a maximum limit of 50 attribute groups that you can create.',
						title: 'Warning',
					});
				}

				return hasTooManyAttributeSets;
			};

			vm.selectNewlyCreatedAttributeSet = (response) => {
				const newlyCreatedAttributeSetName = response.data.name;
				vm.onSelectAttributeSet(newlyCreatedAttributeSetName);
			};
		},
	})
	.filter('startFrom', function () {
		return function (input, start) {
			start = +start;
			return input.slice(start);
		};
	});
