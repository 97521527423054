/**
 * Copied from dnb-uux-design-system/react
 * ux want hide Expand icon if submenu is expanded, it's hard to be done by css override
 */
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem, {ListItemProps} from '@mui/material/ListItem';
import ListItemButton, {
	ListItemButtonProps,
} from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {styled} from '@mui/material/styles';
import React, {FC, ReactElement, useState} from 'react';
import {DNBDrawerItem} from '@dnb-uux-design-system/react';

interface DNBDrawerSideNavigationItemProps {
	selectedOption?: DNBDrawerItem['value'];
	item: DNBDrawerItem;
	onClick: (item: DNBDrawerItem) => void;
	isSubItem?: boolean;
}

interface StyledListItemProps extends ListItemProps {
	disabled?: boolean;
	isSubItem?: boolean;
	selected?: boolean;
}

interface StyledListItemButtonProps extends ListItemButtonProps {
	disabled?: boolean;
	isSubItem?: boolean;
}

const StyledListItem = styled(ListItem, {
	shouldForwardProp: (prop: string) =>
		prop !== 'isSubItem' && prop !== 'disabled',
})<StyledListItemProps>(({theme, disabled}) => ({
	'cursor': disabled ? 'not-allowed' : 'pointer',
	'color': theme.colors.ColorGrayPrimary,
	'height': theme.spacing(9),
	'opacity': disabled ? theme.opacity.OpacityDisabled : undefined,
	'paddingLeft': theme.spacing(2),
	'paddingRight': theme.spacing(4),
	'transition': 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
	'& .MuiListItemText-root': {
		marginLeft: theme.spacing(1),
	},
	'& .MuiListItemIcon-root': {
		color: theme.colors.ColorGraySecondary,
	},
	'&.Mui-selected': {
		'backgroundColor': 'transparent',
		'paddingLeft': 0,
		'&::before': {
			content: "''",
			display: 'block',
			width: theme.spacing(0.75),
			height: theme.spacing(9),
			borderRadius: '0px 1.5px 1.5px 0px',
			backgroundColor: theme.colors.ColorPrimaryBlueInteractive,
		},
		'& .MuiListItemIcon-root': {
			color: 'inherit',
		},
	},
}));

const StyledListItemButton = styled(ListItemButton, {
	shouldForwardProp: (prop: string) => prop !== 'isSubItem',
})<StyledListItemButtonProps>(({theme, isSubItem, disabled}) => ({
	'borderLeft': theme.borders.BorderMedium,
	'borderLeftColor': 'transparent',
	'borderRadius': theme.borders.BorderRadiusDefault,
	'cursor': disabled ? 'not-allowed' : 'pointer',
	'height': 'inherit',
	'paddingLeft': isSubItem ? theme.spacing(8) : theme.spacing(4),
	':hover': {
		backgroundColor: theme.colors.ColorGrayPrimaryAlpha1,
	},
	'&.Mui-selected': {
		'borderRadius': `0 ${theme.borders.BorderRadiusDefault} ${theme.borders.BorderRadiusDefault} 0`,
		'background': theme.colors.ColorPrimaryBlueInteractiveAlpha1,
		'color': theme.colors.ColorPrimaryBlueInteractive,
		'paddingLeft': isSubItem ? theme.spacing(9.25) : theme.spacing(5.25),
		'&:hover': {
			background: theme.colors.ColorPrimaryBlueInteractiveAlpha1,
		},
	},
}));

const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
	'minWidth': 'unset',
	'& .MuiSvgIcon-root': {
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
}));

const ExpandMenuButton = (): ReactElement => {
	return (
		<StyledListItemIcon>
			<ArrowForwardIosRoundedIcon />
		</StyledListItemIcon>
	);
};

export const DNBDrawerSideNavigationItem: FC<
	DNBDrawerSideNavigationItemProps
> = ({
	selectedOption,
	item,
	onClick,
	isSubItem = false,
}: DNBDrawerSideNavigationItemProps) => {
	const {
		disabled = false,
		expanded = false,
		icon,
		id,
		label,
		value,
		subMenu = [],
	} = item;
	const hasSubMenu = subMenu && subMenu.length > 0;
	const selected = selectedOption === value;
	const [isSubMenuExpanded, setIsSubMenuExpanded] = useState<boolean>(expanded);

	const handleOnItemClick = (): void => {
		if (hasSubMenu) {
			setIsSubMenuExpanded(!isSubMenuExpanded);
		} else {
			onClick(item);
		}
	};

	return (
		<>
			<StyledListItem
				disabled={disabled}
				disablePadding
				isSubItem={isSubItem}
				key={`${id}-item`}
				selected={selected}>
				<StyledListItemButton
					aria-label={(label as string) ?? `${id} divider`}
					disabled={disabled}
					isSubItem={isSubItem}
					key={`${id}-item-button`}
					onClick={() => handleOnItemClick()}
					selected={selected}>
					{icon && (
						// space between icon and text is 8px in mock, but it's not good to change ListItemText's margin-left
						<StyledListItemIcon sx={{marginRight: (theme) => theme.spacing(1)}}>
							{icon}
						</StyledListItemIcon>
					)}
					{label ? (
						<ListItemText
							primary={label}
							primaryTypographyProps={{variant: 'caption'}}
						/>
					) : null}
					{hasSubMenu && !isSubMenuExpanded && <ExpandMenuButton />}
				</StyledListItemButton>
			</StyledListItem>
			{hasSubMenu && (
				<Collapse
					in={isSubMenuExpanded}
					unmountOnExit
					key={`${id}-subMenu-collapse`}>
					<List component='div' disablePadding key={`${id}-subMenu-list`}>
						{subMenu.map((subItem) => {
							const {id: subItemId} = subItem;
							return (
								<DNBDrawerSideNavigationItem
									key={subItemId}
									selectedOption={selectedOption}
									item={subItem}
									onClick={(selectedItem) => onClick(selectedItem)}
									isSubItem
								/>
							);
						})}
					</List>
				</Collapse>
			)}
		</>
	);
};
