import axios, {AxiosPromise} from 'axios';
import {
	IPublishCustomerConfig,
	IAttributeGroupConfig,
	IAttributesConfig,
	ISegmentListConfig,
	IPostPublishCustomerData,
	IPersonalizationSegmentsConfig,
	IGetWorkflowStatusData,
} from '../configs/PersonalizationTypes';

const getBoardingStatusData = (): AxiosPromise<boolean> => {
	return axios.get('/pls/ssvi/setup/finished');
};

const getPersonalizationSegmentsData =
	(): AxiosPromise<IPersonalizationSegmentsConfig> => {
		return axios.get('/pls/ssvi/personalization/segments');
	};

const getSegmentsListData = (): AxiosPromise<Array<ISegmentListConfig>> => {
	return axios.get('/pls/datacollection/segments');
};

const getAllAttributeGroupData = (): AxiosPromise<
	Array<IAttributeGroupConfig>
> => {
	return axios.get('/pls/attrconfig/attributeset/selectable');
};

const getAttributeListData = (
	attributeGroupName: string
): AxiosPromise<Array<IAttributesConfig>> => {
	return axios.get(
		`/pls/attrconfig/attributeset/name/${attributeGroupName}/details`
	);
};

const postPublishCustomerData = (
	data: IPublishCustomerConfig
): AxiosPromise<IPostPublishCustomerData> => {
	return axios.post('/pls/ssvi/publishCustomerAttr', data);
};

const getWorkflowStatusData = (): AxiosPromise<IGetWorkflowStatusData> => {
	return axios.get('/pls/ssvi/customer-attr-workflow/status');
};

export {
	getBoardingStatusData,
	getSegmentsListData,
	getAllAttributeGroupData,
	getAttributeListData,
	getPersonalizationSegmentsData,
	postPublishCustomerData,
	getWorkflowStatusData,
};
