import {
	AdminRouteMap,
	NoAdminRouteMap,
	ROUTE_PAGE,
	setMultipleStates,
} from 'common/app/utilities/AdminPageUtility';
import template from './edit.component.html';
import AttrConfigService from '../AttrConfigService';
import AttrConfigStore from '../AttrConfigStore';

angular
	.module('common.attributes.edit', [
		'common.attributes.edit.filters',
		'common.attributes.edit.list',
	])
	.config(function ($stateProvider) {
		$stateProvider.states = setMultipleStates;

		const editAttributesNames = [
			AdminRouteMap.get(ROUTE_PAGE.EDIT_ATTRIBUTES),
			NoAdminRouteMap.get(ROUTE_PAGE.EDIT_ATTRIBUTES),
		];

		$stateProvider.states(editAttributesNames, {
			url: '/edit/:category',
			params: {
				category: {
					dynamic: false,
					value: 'My Attributes',
				},
			},
			onExit: function () {
				AttrConfigStore.reset();
			},
			resolve: {
				overview: () =>
					AttrConfigService.getOverview('name').then((r) => r.data),
				config: [
					'$q',
					'$stateParams',
					function ($q, $stateParams) {
						const deferred = $q.defer();
						const {category} = $stateParams;

						AttrConfigStore.set('category', category);

						AttrConfigService.getConfig('name', category).then(function (
							response
						) {
							AttrConfigStore.setData('config', response.data || []);
							deferred.resolve(response.data || []);
						});

						return deferred.promise;
					},
				],
			},
			views: {
				'subsummary@': 'attrSubheader',
				'main@': 'attrEdit',
				'adminSidebar@': {component: 'adminSidebar'},
			},
		});
	})
	.component('attrEdit', {
		template,
		bindings: {
			overview: '<',
			config: '<',
		},
		controller($q) {
			const vm = this;

			vm.store = AttrConfigStore;
			vm.filters = vm.store.get('filters');
			vm.showModal = false;

			vm.uiCanExit = () => {
				if (!vm.store.isChanged()) {
					return true;
				}

				const deferred = $q.defer();

				vm.onConfirm = () => {
					deferred.resolve(true);
					vm.showModal = false;
				};
				vm.closeModal = () => {
					deferred.reject('user cancelled action');
					vm.showModal = false;
					HideSpinner();
				};

				vm.showModal = true;

				return deferred.promise;
			};
		},
	});
