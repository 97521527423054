import {Nullable} from 'atlas/connectors/EIF/GenericType';
import {
	fromCronExpression,
	getCronSettingType,
} from '../../../Util/CronSettingUtil';
import {
	DefaultBeta,
	DefaultLastImportStatus,
	DefaultLocked,
	DefaultPriority,
	FieldType,
} from '../../FieldType';
import {IImportConnectionResponse} from '../FE/ImportResponse';
import {SystemType} from '../../SystemType';
import {BrokerOwnerType, IBrokerAuthResponse} from './BrokerAuth';
import {ConnectionType, ObjectType} from '../../ConnectionType';
import {DefaultCronSetting} from '../../CronSettingType';

interface IScheduler {
	cronExpression: Nullable<string>;
	startTime: number;
}

interface ICreateOrUpdateConnection extends ObjectType {
	created: number;
	update: number;
	connectionType: SystemType;
	authentication: IBrokerAuthResponse;
	displayName: string;
	name: string;
	description: string;
	scheduler: IScheduler;
	createdBy: string;
	updatedBy: string;
	active: boolean;
	fromSystem: boolean;
	priority: number;
	sourceId?: string;
}

interface ICreateOrUpdateConnectionRequest extends ObjectType {
	connectionType: SystemType;
	authentication: {name: string; owner?: BrokerOwnerType};
	displayName: string;
	name: string;
	description: string;
	scheduler: Nullable<IScheduler>;
	active: boolean;
	priority: number;
	version: string;
	application: string;
}

type ISystemTypeValidConfig = {
	[key in SystemType]?: (data: IImportConnectionResponse) => boolean;
};

const SystemTypeValidConfig: ISystemTypeValidConfig = {
	[SystemType.Salesforce]: ({
		AuthenticationUpdatedAt,
	}: IImportConnectionResponse): boolean => {
		return !!AuthenticationUpdatedAt;
	},
	[SystemType.AWS_S3]: ({FromSystem}: IImportConnectionResponse): boolean => {
		return !FromSystem;
	},
};

const isValidConnection = (data: IImportConnectionResponse): boolean => {
	const {SystemType} = data;
	const validate = SystemTypeValidConfig[SystemType as SystemType];
	return validate ? validate(data) : true;
};

const toImportConnectionResponse = (
	connection: ICreateOrUpdateConnection
): IImportConnectionResponse => {
	const {
		scheduler,
		authentication,
		update,
		createdBy,
		displayName,
		name,
		description,
		connectionType,
		active,
		fromSystem,
		priority,
		created,
		sourceId,
	} = connection;
	const {cronExpression, startTime} = scheduler || {};
	const {
		updated: authenticationUpdatedAt,
		name: authenticationName,
		brokerAuthInfo,
		owner,
	} = authentication || {};
	const {isSandbox, clientId, clientSecret, endPoint, pardotBusinessUnitId} =
		brokerAuthInfo || {};
	return {
		[FieldType.SystemName]: displayName,
		[FieldType.SystemType]: connectionType,
		[FieldType.Enabled]: active,
		[FieldType.Beta]: DefaultBeta,
		[FieldType.Locked]: DefaultLocked,
		[FieldType.Description]: description || '',
		[FieldType.Priority]: Number.isInteger(priority)
			? priority
			: DefaultPriority,
		[FieldType.LastImportStatus]: DefaultLastImportStatus,
		[FieldType.LastImportTime]: update,
		[FieldType.AuthenticationUpdatedAt]: authenticationUpdatedAt,
		[FieldType.IsSandbox]: isSandbox || false,
		[FieldType.ClientId]: clientId || '',
		[FieldType.ClientSecret]: clientSecret || '',
		[FieldType.EndPoint]: endPoint || '',
		[FieldType.UnitId]: pardotBusinessUnitId || '',
		[FieldType.AuthenticationName]: authenticationName,
		[FieldType.SystemId]: name,
		[FieldType.SyncStartDate]: startTime,
		[FieldType.Schedule]:
			(cronExpression &&
				getCronSettingType(fromCronExpression(cronExpression))) ||
			DefaultCronSetting,
		[FieldType.CronExpression]: cronExpression || '',
		[FieldType.CreatedBy]: createdBy || '',
		ConnectionType: ConnectionType.Import,
		FromSystem: !!fromSystem,
		[FieldType.LastUpdatedDate]: update,
		[FieldType.Created]: created,
		owner,
		sourceId,
	};
};

const toImportConnectionListResponse = (
	connectionList: ICreateOrUpdateConnection[]
): IImportConnectionResponse[] => {
	return connectionList
		.map(toImportConnectionResponse)
		.filter(isValidConnection);
};

const toImportConnectionRequest = ({
	SystemName,
	SystemType,
	Description,
	Priority,
	AuthenticationName,
	SystemId,
	SyncStartDate,
	CronExpression,
	owner,
}: IImportConnectionResponse): ICreateOrUpdateConnectionRequest => {
	return {
		authentication: {
			name: AuthenticationName,
			owner,
		},
		scheduler: SyncStartDate
			? {
					startTime: SyncStartDate,
					cronExpression: CronExpression,
			  }
			: null,
		connectionType: SystemType,
		displayName: SystemName,
		name: SystemId,
		description: Description,
		active: true,
		priority: Priority,
		version: 'V1',
		application: 'Lattice',
	};
};

const ConnectionUrl = '/pls/inboundchannel/connection';
const getImportConnectionUrlById = (systemId: string): string => {
	return `${ConnectionUrl}/${systemId}`;
};
const ConnectionPrioritiesUrl = '/pls/inboundchannel/connection/priority/batch';

export {
	toImportConnectionResponse,
	toImportConnectionListResponse,
	toImportConnectionRequest,
	ConnectionUrl,
	ConnectionPrioritiesUrl,
	getImportConnectionUrlById,
	isValidConnection,
};
export type {
	IScheduler,
	ICreateOrUpdateConnection,
	ICreateOrUpdateConnectionRequest,
};
