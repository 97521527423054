import React, {react2angular} from 'common/react-vendor';
import {AxiosResponse} from 'common/network.vendor';
import {
	GetSessionDocument,
	handleLoginSuccess,
} from 'common/app/services/LoginService';
import {Passwordless} from './passwordless.component';
import {redirectToApp} from '../login.service';

// TODO: find a better place to define login objects
interface Tenant {
	Contract: string;
	DisplayName: string;
	EntitledApps: string;
	Identifier: string;
	JobNotificationLevel: Record<string, unknown>;
	NotificationLevel: string;
	NotificationType: string;
	Pid: number;
	RegisteredTime: number;
	Status: string;
	TenantType: string;
	UIVersion: string;
}

// Use react wrapper to create separation from angular-related callbacks
const PasswordlessWrapper = ({
	$state,
}: {
	$state: {go: (arg0: string) => void}; // TODO: figure out how to use ng.ui.IStateProvider
}): React.ReactElement => (
	<Passwordless
		redirectToLoginForm={() => $state.go('login.form')}
		handleLoginSuccess={(response: AxiosResponse) => {
			const promisifiedHandleLoginSuccess = (): Promise<unknown> =>
				new Promise((resolve) => handleLoginSuccess(response, resolve));

			/** Temporary hack to select right tenant until backend passes tenant identifier in JWT body */
			const url = sessionStorage.getItem('referringUrl');
			if (!url) {
				$state.go('login.form');
				return;
			}
			const tenantId = new URL(url).pathname.split('/')[3];
			const tenant = response.data.Result.Tenants.find(
				(potentialTenant: Tenant) => potentialTenant.DisplayName === tenantId
			);
			const username = response.data.UserName;

			promisifiedHandleLoginSuccess()
				.then(() => GetSessionDocument(tenant, username))
				.then(() => redirectToApp(tenant))
				.catch((e) => {
					console.error(
						'Passwordless login flow failed, redirecting to classic login',
						e,
						e.stack
					);
					$state.go('login.form');
				});
		}}
	/>
);

angular
	.module('login.passwordless', [])
	.component(
		'passwordless',
		react2angular(PasswordlessWrapper, [], ['$state'])
	);
