import {IDocumentTypeUIConfig} from './DocumentType';

export const DocumentTypeUIConfig: IDocumentTypeUIConfig = {
	Account: {
		title: 'Accounts',
	},
	Contact: {
		title: 'Contacts',
	},
	Lead: {
		title: 'Leads',
	},
	ProductPurchase: {
		title: 'Product Purchases',
	},
	ProductBundle: {
		title: 'Product Bundles',
	},
	ProductHierarchy: {
		title: 'Product Hierarchy',
	},
	WebVisit: {
		title: 'Web Visit',
	},
	Opportunity: {
		title: 'Opportunities',
	},
	MarketingActivity: {
		title: 'Marketing Activities',
	},
	CustomIntent: {
		title: 'D&B Buyer Intent',
	},
	G2Intent: {
		title: 'G2 Buyer Intent',
	},
	TrustRadiusIntent: {
		title: 'Trust Radius Intent',
	},
	CampaignActivity: {
		title: 'Campaign Activity',
	},
	Impression: {
		title: 'Impression',
	},
	PerformanceReport: {
		title: 'Performance Report',
	},
	SalesActivityEvent: {
		title: 'Events',
	},
	SalesActivityTask: {
		title: 'Tasks',
	},
	LeadHistory: {
		title: 'Lead History',
	},
	WebVisitPathPattern: {
		title: 'Website Path',
	},
	WebVisitSourceMedium: {
		title: 'Visit Source',
	},
	OpportunityStageName: {
		title: 'Opportunity Stage',
	},
	OpportunityContactRole: {
		title: 'Opportunity Contact Role',
	},
	MarketingActivityType: {
		title: 'Marketing ActivityType',
	},
	Campaign: {
		title: 'Campaigns',
	},
	Asset: {
		title: 'Asset',
	},
	AdGroup: {
		title: 'Ad Group',
	},
	SalesActivityType: {
		title: 'Sales Activities',
	},
	SalesActivityEventType: {
		title: 'Event ActivityType',
	},
	SalesActivityTaskType: {
		title: 'Task ActivityType',
	},
	LatticeRecommendation: {
		title: 'Lattice Recommendation',
	},
	LeadCategory: {
		title: 'Lead Category',
	},
	User: {
		title: 'User',
	},
	Audience: {
		title: 'Audience',
	},
	CampaignBudget: {
		title: 'Campaign Budget',
	},
	CampaignElements: {
		title: 'Campaign Elements',
	},
	VisitWebpage: {
		title: 'Visit Webpage',
	},
	FillOutForm: {
		title: 'Fill Out Form',
	},
	ClickLink: {
		title: 'Click Link',
	},
	SendEmail: {
		title: 'Send Email',
	},
	EmailDelivered: {
		title: 'Email Delivered',
	},
	EmailBounced: {
		title: 'Email Bounced',
	},
	InterestingMoment: {
		title: 'Interesting Moment',
	},
	OpenEmail: {
		title: 'Open Email',
	},
	ClickEmail: {
		title: 'Click Email',
	},
	NewLead: {
		title: 'New Lead',
	},
	BounceBack: {
		title: 'Bounceback',
	},
	EmailClickThrough: {
		title: 'Email Clickthrough',
	},
	EmailOpen: {
		title: 'Email Open',
	},
	EmailSend: {
		title: 'Email Send',
	},
	FormSubmit: {
		title: 'Form Submit',
	},
	PageView: {
		title: 'Page View',
	},
	Subscribe: {
		title: 'Subscribe',
	},
	Unsubscribe: {
		title: 'Unsubscribe',
	},
	ActivityWebVisit: {
		title: 'Web Visit',
	},
	// User will custom their own names
	CustomEvent: {
		title: '',
	},
	CustomObject: {
		title: '',
	},
};
