import './UnderscoreUtility';

import './ObjectUtilities';
import './DateTimeUtilities';
import './EncryptionUtility';
import './AdminPageUtility';

export default angular.module('com.le.common.utilities', [
	'mainApp.appCommon.utilities.UnderscoreUtility',
	'mainApp.appCommon.utilities.ObjectUtility',
	'mainApp.appCommon.utilities.EncryptionUtility',
	'mainApp.appCommon.utilities.AdminPageUtility',
]);
