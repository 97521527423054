import NoticeService, {NOTICE_UPDATE_EVENT} from './NoticeService';
import template from './notice.component.html';

angular.module('common.notice', []).component('noticeMessage', {
	template,
	controller: function ($document, $scope) {
		this.notice = NoticeService.get();

		$document.on(NOTICE_UPDATE_EVENT, (e) => {
			this.notice = e.detail;
			setTimeout(() => {
				$scope.$apply(() => {
					this.notice.visible = false;
				});				
			}, this.notice.delay);
			if (this.notice.needApply) {
				$scope.$apply();
			}
		});
	},
});
