import {ISegment} from 'atlas/data/SegmentConst';
import {
	MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT,
} from 'atlas/navigation/header/components/page-navigation.utils';
import StateHistory from 'atlas/StateHistory';
import React, {NgStateService} from 'common/react-vendor';
import {
	BaseSegmentHelper,
	IBaseSegmentHelper,
	ISegmentTabParam,
} from './SegmentTabsHelper';
import {AccountFitModelingHelper} from './TabsHelper/AccountFitModelingHelper';

const useSegmentTabHelper = (
	state: NgStateService,
	segment: ISegment,
	stateParam?: ISegmentTabParam
): IBaseSegmentHelper => {
	const lastToState = StateHistory.lastTo()?.name;
	const segmentHelper = React.useMemo(() => {
		if (!lastToState || !stateParam) return;
		const isAccountFittingModel =
			lastToState.includes(MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT) ||
			lastToState.includes(MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT);
		return isAccountFittingModel
			? new AccountFitModelingHelper(
					state,
					!!stateParam.aiModel,
					segment,
					stateParam.tab!
			  )
			: new BaseSegmentHelper(state, !!stateParam.aiModel, segment);
	}, [lastToState, segment, state, stateParam]);
	return segmentHelper as IBaseSegmentHelper;
};

export {useSegmentTabHelper};
