import template from './edit-filters.component.html';
import AttrConfigStore from '../../AttrConfigStore';

angular
	.module('common.attributes.edit.filters', [])
	.component('attrEditFilters', {
		template,
		bindings: {
			filters: '<',
		},
		controller: function ($state) {
			const vm = this;

			vm.$onInit = function () {
				vm.section = AttrConfigStore.getSection($state.current.name);
			};
		},
	});
