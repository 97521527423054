import {
	Bkt,
	LogicalRestriction,
	Restrictions,
} from 'common/components/datacloud/query/query.types';

/**
 * Sync with LogicalOperator.java of BE.
 */
enum Operator {
	AND = 'AND',
	OR = 'OR',
}

/**
 * Sync with ComparisonType.java of BE
 */
enum CompareType {
	IS_NULL = 'IS_NULL',
	IS_NOT_NULL = 'IS_NOT_NULL',
	EQUAL = 'EQUAL',
	NOT_EQUAL = 'NOT_EQUAL',
	GREATER_THAN = 'GREATER_THAN',
	GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
	LESS_THAN = 'LESS_THAN',
	LESS_OR_EQUAL = 'LESS_OR_EQUAL',
	IN_COLLECTION = 'IN_COLLECTION',
	NOT_IN_COLLECTION = 'NOT_IN_COLLECTION',
	CONTAINS = 'CONTAINS',
	NOT_CONTAINS = 'NOT_CONTAINS',
	STARTS_WITH = 'STARTS_WITH',
	ENDS_WITH = 'ENDS_WITH',
	GTE_AND_LTE = 'GTE_AND_LTE',
	GTE_AND_LT = 'GTE_AND_LT',
	GT_AND_LTE = 'GT_AND_LTE',
	GT_AND_LT = 'GT_AND_LT',
	EVER = 'EVER',
	IS_EMPTY = 'IS_EMPTY',
	LAST = 'LAST',
	LATEST_DAY = 'LATEST_DAY',
	IN_CURRENT_PERIOD = 'IN_CURRENT_PERIOD',
	BETWEEN = 'BETWEEN',
	BETWEEN_NEXT = 'BETWEEN_NEXT',
	PRIOR_ONLY = 'PRIOR_ONLY',
	FOLLOWING = 'FOLLOWING',
	WITHIN = 'WITHIN',
	BEFORE = 'BEFORE',
	BEFORE_LAST = 'BEFORE_LAST',
	AFTER = 'AFTER',
	AFTER_NEXT = 'AFTER_NEXT',
	BETWEEN_DATE = 'BETWEEN_DATE',
	FUTURE_WITHIN = 'FUTURE_WITHIN',
}

interface ICompareConfig {
	displayName: string;
}

type ICompareTypeMap = {
	[key in CompareType]: ICompareConfig;
};

const CompareTypeMap: ICompareTypeMap = {
	EVER: {displayName: 'Ever'},
	LATEST_DAY: {displayName: 'Latest Day'},
	IN_CURRENT_PERIOD: {displayName: 'Current'},
	WITHIN: {displayName: 'Previous'},
	LAST: {displayName: 'Last'},
	BETWEEN: {displayName: 'Between Last'},
	BETWEEN_NEXT: {displayName: 'Between Next'},
	BETWEEN_DATE: {displayName: 'Between'},
	BEFORE_LAST: {displayName: 'Before Last'},
	BEFORE: {displayName: 'Before'},
	AFTER: {displayName: 'After'},
	AFTER_NEXT: {displayName: 'After Next'},
	IS_EMPTY: {displayName: 'Is Empty'},
	IS_NULL: {displayName: 'is empty'},
	IS_NOT_NULL: {displayName: 'is present'},
	EQUAL: {displayName: 'is equal to'},
	NOT_EQUAL: {displayName: 'is not'},
	GREATER_THAN: {displayName: 'is greater than'},
	GREATER_OR_EQUAL: {displayName: 'is greater than or equal to'},
	LESS_THAN: {displayName: 'is less than'},
	LESS_OR_EQUAL: {displayName: 'is less than or equal to'},
	IN_COLLECTION: {displayName: 'is equal to'},
	NOT_IN_COLLECTION: {displayName: 'is not'},
	CONTAINS: {displayName: 'contains'},
	NOT_CONTAINS: {displayName: 'not contains'},
	STARTS_WITH: {displayName: 'starts with'},
	ENDS_WITH: {displayName: 'ends with'},
	GTE_AND_LTE: {
		displayName: 'is greater than or equal and lesser than or equal',
	},
	GTE_AND_LT: {displayName: 'is between'},
	GT_AND_LTE: {displayName: 'is greater than and lesser or equal'},
	GT_AND_LT: {displayName: 'is greater than and less than'},
	PRIOR_ONLY: {displayName: 'only prior to last'},
	FOLLOWING: {displayName: 'FOLLOWING'}, // time but not in FE
	FUTURE_WITHIN: {displayName: 'FUTURE_WITHIN'}, // time but not in FE.
};

/**
 * Sync with PeriodStrategy.java of BE
 */
enum PeriodType {
	Day = 'Day',
	Week = 'Week',
	Month = 'Month',
	Quarter = 'Quarter',
	Year = 'Year',
}

interface ITimeFilter {
	Cmp: CompareType;
	Period: PeriodType;
	Vals: unknown[];
}

enum EntityType {
	Account = 'Account',
	Contact = 'Contact',
	Member = 'Member',
}

interface IEntityConfig {
	title: string;
}

type IEntityConfigMap = Record<EntityType, IEntityConfig>;
const EntityConfigMap: IEntityConfigMap = {
	Account: {title: 'Select Account Attributes'},
	Contact: {title: 'Select Contact Attributes'},
	Member: {title: 'Select a segment'},
};

/**
 * Time series restriction interface
 * @param cmp Compare type
 * @param cnt Number array
 * @param restriction Logic restriction
 */
interface IActivityRestriction {
	cmp: CompareType;
	cnt: number[];
	restriction: ILogicalRestriction;
}

/**
 * Segment restriction
 * @param entity Entity
 * @param ignored True on ignored
 * @param relation Segment relation
 * @param segmentName Segment name
 * @param bkt Bucket data
 */
interface ISegmentMemberRestriction {
	entity: string; // AccountSegmentMember
	ignored: boolean;
	relation: string; // INCLUDE
	segmentName: string;
	bkt?: Bkt;
}

/**
 * Logic restriction interface
 * @param operator Refers to @Operator
 * @param compositeSegmentBlock True on segment block
 * @param restrictions List of restriction
 */
interface ILogicalRestriction {
	operator: Operator;
	compositeSegmentBlock: boolean;
	restrictions: Restrictions;
}

interface IActivityRestrictionType {
	activityRestriction: IActivityRestriction;
}

interface ISegmentMemberRestrictionType {
	segmentMemberRestriction: ISegmentMemberRestriction;
}

interface IRootRestriction {
	restriction: LogicalRestriction;
}

export type {
	ICompareConfig,
	ICompareTypeMap,
	ITimeFilter,
	ISegmentMemberRestriction,
	ILogicalRestriction,
	IActivityRestriction,
	ISegmentMemberRestrictionType,
	IActivityRestrictionType,
	IRootRestriction,
	IEntityConfig,
	IEntityConfigMap,
};
export {
	Operator,
	CompareType,
	PeriodType,
	EntityType,
	CompareTypeMap,
	EntityConfigMap,
};
