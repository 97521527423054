import template from './transaction-item.component.html';
import {
	getBucketCmp,
	getPeriodValue,
	getValues as getTreeValues,
} from '../tree.helpers';
import {cmpMap, transactionMap} from '../tree.constants';
import {BucketCmp, TransactionKey} from '../../../query.enums';

angular
	.module('common.datacloud.query.builder.tree.edit.transaction', [])
	.directive('transactionDirective', function () {
		return {
			restrict: 'E',
			scope: {
				type: '=',
				bucketrestriction: '=',
				purchased: '=',
			},
			template,
			controllerAs: 'vm',
			controller: function ($scope) {
				const vm = $scope.vm;

				vm.init = function () {};
				vm.init();

				vm.getPeriod = function () {
					const period = getPeriodValue({
						bucketRestriction: $scope.bucketrestriction,
						type: $scope.type,
						subType: TransactionKey.Time,
					});

					// TODO: Refactor to use 'BucketCmp.EVER' and remove 'EVER_LOW_CASE' from BucketCmp
					if (
						period != 'Date' &&
						vm.getCmp(TransactionKey.Time) !== BucketCmp.EVER_LOW_CASE
					) {
						return period + '(s)';
					}
				};

				vm.showSubTypeSelection = function (subType) {
					switch (subType) {
						case TransactionKey.Amt:
						case TransactionKey.Qty: {
							return $scope.purchased == true;
						}
						default:
							return true;
					}
				};

				vm.getCmp = function (subType) {
					const ret = getBucketCmp({
						bucketRestriction: $scope.bucketrestriction,
						type: $scope.type,
						subType,
					});

					switch (subType) {
						case TransactionKey.Time: {
							// TODO: Refactor to use 'BucketCmp.EVER' and remove 'EVER_LOW_CASE' from BucketCmp
							const cmp =
								ret === '' ? BucketCmp.EVER_LOW_CASE : transactionMap[ret];
							return cmp;
						}
						case TransactionKey.Amt:
						case TransactionKey.Qty: {
							return ret === '' ? BucketCmp.ANY : cmpMap[ret];
						}
						default: {
							return ret;
						}
					}
				};
				vm.showLabel = function (subType) {
					const cmp = vm.getCmp(subType);
					if (subType === TransactionKey.Amt) {
						return cmp !== BucketCmp.ANY;
					}
				};

				vm.getValues = function (subType) {
					const ret = getTreeValues({
						bucketRestriction: $scope.bucketrestriction,
						type: $scope.type,
						subType,
					});

					switch (ret.length) {
						case 0: {
							return '';
						}
						case 1: {
							if (subType === TransactionKey.Time && ret[0] === -1) {
								return '';
							}
							return ret[0];
						}
						case 2: {
							return ret[0] + ' - ' + ret[1];
						}
						default:
							return '';
					}
				};
			},
		};
	});
