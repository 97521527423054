import {getMetadataForCategory} from '../../datacloud.service.vanilla';
import AttrConfigStore from '../AttrConfigStore';
import template from './category.component.html';

angular.module('common.attributes.category', []).component('attrCategory', {
	template,
	bindings: {
		overview: '<',
		categoryMetadata: '<',
	},
	controller() {
		const vm = this;

		vm.store = AttrConfigStore;

		vm.$onInit = function () {
			vm.category = vm.store.get('category');
		};

		vm.categoryString = (legacyName) => {
			const metadata = getMetadataForCategory(vm.categoryMetadata, legacyName);
			const category = metadata ? metadata.displayName : legacyName;
			if (category.toLowerCase().endsWith('attributes')) return category;
			return `${category} Attributes`;
		};
	},
});
