import {MappingField} from './ListSource.utils';

const firstNameField: MappingField = {
	key: 'FirstName',
	label: 'First Name',
	isRequired: false,
};

const lastNameField: MappingField = {
	key: 'LastName',
	label: 'Last Name',
	isRequired: false,
};

const titleField: MappingField = {
	key: 'Title',
	label: 'Title',
	isRequired: false,
};

const cityField: MappingField = {
	key: 'City',
	label: 'City',
	isRequired: false,
};

const stateField: MappingField = {
	key: 'State',
	label: 'State',
	isRequired: false,
};

const countryField: MappingField = {
	key: 'Country',
	label: 'Country',
	isRequired: false,
};

const postalCodeField: MappingField = {
	key: 'PostalCode',
	label: 'Postal Code',
	isRequired: false,
};

const companyNameField: MappingField = {
	key: 'CompanyName',
	label: 'Company Name',
	isRequired: false,
};

const phoneNumberField: MappingField = {
	key: 'PhoneNumber',
	label: 'PhoneNumber',
	isRequired: false,
};

const accountNameField: MappingField = {
	key: 'CompanyName',
	label: 'Account Name',
	isRequired: false,
};

export const websiteField: MappingField = {
	key: 'Website',
	label: 'Website',
	isRequired: true,
};

export const dunsField: MappingField = {
	key: 'DUNS',
	label: 'DUNS',
	isRequired: true,
};

export const emailField: MappingField = {
	key: 'Email',
	label: 'Email Address',
	isRequired: true,
};

export const contactMappingFields: MappingField[] = [
	emailField,
	firstNameField,
	lastNameField,
	titleField,
	cityField,
	stateField,
	countryField,
	postalCodeField,
	companyNameField,
	phoneNumberField,
];

export const accountMappingFields: MappingField[] = [
	accountNameField,
	websiteField,
	dunsField,
	postalCodeField,
	cityField,
	stateField,
	countryField,
];
