import React from 'common/react-vendor';
import {usePromise} from 'common/app/utilities/data-fetching';
import {
	CategoryMetadata,
	getMetadataForCategory,
	loadCategoryMetadata,
	TopBucketDisplayStrategy,
} from 'common/components/datacloud/datacloud.service.vanilla';
import {Spinner} from 'widgets/spinner';
import {
	Category,
	Column,
	ColumnCustomizationModal,
	Selections,
} from 'widgets/column-customization/column-customization-modal';
import {createPersistedState} from 'common/app/utilities/persistance';
import {ColumnProps, Side} from 'widgets/dataviews/table/Table';
import LocalStorageUtility from 'common/widgets/utilities/local-storage.utility';
import {ColumnWidths} from 'components/datacloud/query/results/rebuild/resizableColumnsProvider';
import {ReactComponent as ColumnsIcon} from 'widgets/column-customization/columns.svg';
import {
	accountMappingFields,
	contactMappingFields,
} from 'atlas/segmentation/listsource/MappingForm.consts';
import {MappingField} from 'atlas/segmentation/listsource/ListSource.utils';
import {useGetAttributeSet} from 'atlas/connectors/EIF/Configuration/Activation/Component/FieldMapping/hooks/useAttributeGroup';
import {
	CustomColumnsParams,
	isListSegment,
	LAST_SELECTED_ATTRIBUTE_GROUP_CACHE_KEY,
	TableAction,
	TableQuery,
} from '.';
import {getCell, getHeader} from './table-content';
import {
	AttributeGroupAttribute,
	getAttributeGroup,
	getAttributeGroupAttributes,
} from '../../query';
import styles from './column-customization.module.css';
import {getSessionSegmentState} from './segment.helpers';

const {createContext, useContext, useState, useMemo, useCallback} = React;

export const DEFAULT_COLUMN_GROUP_ID = 'Default_Group';
const DEFAULT_FROZEN_COLUMNS = ['Account.CompanyName'];

export type ColumnIds = [frozenColumns: string[], scrollableColumns: string[]];

export type CustomizedColumns = [
	frozen: AttributeGroupAttribute[],
	scrollable: AttributeGroupAttribute[]
];

// map of attribute group ids to the array of selected, ordered attribute ids
export interface Customizations {
	[groupID: string]: ColumnIds;
}

// inference is better here
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const createCustomColumnsProvider = (key: string) =>
	createPersistedState<Customizations>(key, {});

const ColumnsContext = createContext<{
	onEdit: (group: string) => void;
	selectedGroup: string;
	onSelectGroup: (group: string) => void;
	customizations: Customizations;
	columns: CustomizedColumns;
	columnWidths: ColumnWidths;
	setWidth: (colId: string, newWidth: number) => void;
} | null>(null);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- It can be inferred
const useCustomizationCtx = (componentName: string) => {
	const ctx = useContext(ColumnsContext);
	if (!ctx)
		throw new Error(
			`${componentName} must be rendered within a <ColumnProvider />`
		);
	return ctx;
};

// #region getListSegmentColumns
const mapMappingFieldToColumn = (
	{key, label}: MappingField,
	Entity: string
): AttributeGroupAttribute =>
	({
		AttrName: key,
		DisplayName: label,
		Entity,
	} as AttributeGroupAttribute);

const getListSegmentColumns = (): CustomizedColumns => {
	if (getSessionSegmentState()?.isCompanyList) {
		return [
			[],
			accountMappingFields.map((field) =>
				mapMappingFieldToColumn(field, 'Account')
			),
		];
	}

	return [
		[],
		contactMappingFields.map((field) =>
			mapMappingFieldToColumn(field, 'Contact')
		),
	];
};
// #endregion getListSegmentColumns

interface ColumnProviderProps {
	children: React.ReactNode;
	customizations: Customizations;
	onCustomize: React.Dispatch<React.SetStateAction<Customizations>>;
	columnWidths: ColumnWidths;
	setColumnWidths: React.Dispatch<React.SetStateAction<ColumnWidths>>;
	selectedGroup: string;
	onSelectGroup: (group: string) => void;
	isAttributeAllowed?: (attr: AttributeGroupAttribute) => boolean;
	defaultSelections?: Record<string, ColumnIds>;
	setAttributeGroup: React.Dispatch<CustomColumnsParams>;
}

export function ColumnProvider({
	children,
	customizations,
	onCustomize,
	columnWidths,
	setColumnWidths,
	selectedGroup,
	onSelectGroup,
	isAttributeAllowed,
	defaultSelections,
	setAttributeGroup,
}: ColumnProviderProps): JSX.Element {
	const columns = usePromise(
		loadCustomizations,
		selectedGroup,
		customizations[selectedGroup],
		isAttributeAllowed,
		defaultSelections?.[selectedGroup]
	);

	const categoryMeta = usePromise(loadCategoryMetadata);
	const [editing, onEdit] = useState<string | null>(null);
	const editGroup = usePromise(loadAttributeGroupNullable, editing);
	const editGroupAttributes = usePromise(
		loadFilteredAttributeGroupAttributes,
		editing,
		isAttributeAllowed
	);

	const setWidth = useCallback(
		(colId: string, newWidth: number) => {
			setColumnWidths((ws) => ({...ws, [colId]: newWidth}));
		},
		[setColumnWidths]
	);
	React.useEffect(() => {
		if (columns.data) {
			const group = columns.data.map((attrs) =>
				attrs.map(({AttrName, Entity}) => `${Entity}.${AttrName}`)
			) as ColumnIds;
			setAttributeGroup({groupID: selectedGroup, group});
		}
	}, [columns.data, customizations, selectedGroup, setAttributeGroup]);

	if (columns.error)
		return (
			<details>
				<summary>
					There was an error loading the columns.{' '}
					<button type='button' onClick={columns.revalidate}>
						Retry
					</button>
				</summary>
				<p>{columns.error.message}</p>
			</details>
		);

	if (categoryMeta.error)
		return (
			<details>
				<summary>
					There was an error loading the categories.{' '}
					<button type='button' onClick={categoryMeta.revalidate}>
						Retry
					</button>
				</summary>
				<p>{categoryMeta.error.message}</p>
			</details>
		);

	if (!columns.data) return <Spinner />;

	return (
		<ColumnsContext.Provider
			value={{
				onEdit,
				selectedGroup,
				onSelectGroup,
				customizations,
				columns: isListSegment() ? getListSegmentColumns() : columns.data,
				columnWidths,
				setWidth,
			}}>
			{editing && editGroupAttributes.data && categoryMeta.data && (
				<AttributeGroupCustomizationModal
					title={`Customize Columns: ${editGroup.data?.displayName}`}
					categoryMetadata={categoryMeta.data}
					initialSelectedAttributes={
						customizations[editing] ??
						idsFromCustomizedColumns(
							initialCustomizations(
								editGroupAttributes.data,
								defaultSelections?.[editing]
							)
						)
					}
					usableAttributes={editGroupAttributes.data}
					onCancel={() => onEdit(null)}
					onSave={(newValue) => {
						onEdit(null);
						onCustomize((all) => ({...all, [editing]: newValue}));
					}}
				/>
			)}
			{children}
		</ColumnsContext.Provider>
	);
}

export function ColumnSelect(): JSX.Element {
	const {onEdit, selectedGroup, onSelectGroup} =
		useCustomizationCtx('<ColumnSelect />');
	const {isFetching, error, data} = useGetAttributeSet();
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState('');

	if (error)
		return <div className={styles.selectWrapper}>Columns: Failed to load.</div>;
	if (isFetching)
		return (
			<div className={styles.selectWrapper}>
				Columns: <i className='fa fa-spinner fa-pulse' />
			</div>
		);
	if (!data?.length)
		return (
			<div className={styles.selectWrapper}>Columns: No attribute groups</div>
		);

	const group = data.find((g) => g.name === selectedGroup);
	const displayGroups = [...data]
		.filter(({displayName}) =>
			displayName.toLowerCase().includes(search.toLowerCase())
		)
		.sort((a, b) => {
			// default group first
			if (a.name === DEFAULT_COLUMN_GROUP_ID) return -1;
			// rest sorted alphabetically
			return a.displayName > b.displayName ? 1 : -1;
		});

	// If group was not found, we potentially could have tried to find an attribute group that was deleted.
	// Reset the last selected attribute group cache and select the default group, which is guaranteed to be present.
	if (!group) {
		LocalStorageUtility.setTenantCache(
			LAST_SELECTED_ATTRIBUTE_GROUP_CACHE_KEY,
			DEFAULT_COLUMN_GROUP_ID
		);
		onSelectGroup(DEFAULT_COLUMN_GROUP_ID);
	}

	return (
		<div
			className={styles.selectWrapper}
			onBlur={(e) => {
				if (
					// If relatedTarget is null or non-nodelike
					!(e.relatedTarget instanceof Node) ||
					// Or it's not within the div
					!e.currentTarget.contains(e.relatedTarget)
				)
					// close the dropdown
					setOpen(false);
			}}>
			<button
				type='button'
				disabled={!group}
				onClick={() => onEdit(group!.name)}>
				<ColumnsIcon style={{verticalAlign: 'middle'}} />
			</button>
			Columns:{' '}
			<button type='button' onClick={() => setOpen(!open)}>
				<span style={{fontWeight: 'bolder'}}>
					{group ? group.displayName : 'Choose one'}
				</span>{' '}
				<i
					className='fa fa-caret-down'
					aria-hidden='true'
					title='Select a column group'
				/>
			</button>
			{open && (
				<>
					<div className={styles.selectDropdown}>
						<input
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder='Search groups'
							className={styles.selectDropdownSearch}
						/>
						{displayGroups.length ? (
							displayGroups.map(({name, displayName}) => (
								<div key={name} className={styles.selectOption}>
									<button
										className={styles.textEllipsis}
										title={displayName}
										type='button'
										onClick={() => {
											setOpen(false);
											LocalStorageUtility.setTenantCache(
												LAST_SELECTED_ATTRIBUTE_GROUP_CACHE_KEY,
												name
											);
											onSelectGroup(name);
										}}>
										{displayName}
									</button>
								</div>
							))
						) : (
							<div style={{marginBottom: '1em'}}>No results</div>
						)}
					</div>
				</>
			)}
		</div>
	);
}

export function useCustomColumns(
	query: TableQuery,
	dispatch: React.Dispatch<TableAction>,
	onOpenAccount?: React.Dispatch<Record<string, string>>
): [columns: ColumnProps<common.Entity>[], lookups: common.QueryAttribute[]] {
	const {
		columns: attributes,
		columnWidths,
		setWidth,
	} = useCustomizationCtx('Components that call useCustomColumns');
	const columns = useMemo<ColumnProps<common.Entity>[]>(() => {
		return attributes.flatMap((attrs, idx) =>
			attrs.map((attr) => ({
				key: getAttributeId(attr),
				// first attributes array is "fixed attributes" (so fixed to left); second attributes array is "floating attributes"
				freeze: idx === 0 ? Side.Left : undefined,
				width: columnWidths[getAttributeId(attr)],
				onResize: (newWidth) => setWidth(getAttributeId(attr), newWidth),
				header: getHeader(attr, query, dispatch),
				Cell: getCell(attr, onOpenAccount),
			}))
		);
	}, [attributes, columnWidths, setWidth, query, dispatch, onOpenAccount]);
	const lookups = useMemo(() => {
		const lookups = attributes.flat().map((attr) => ({
			attribute: {attribute: attr.AttrName, entity: attr.Entity},
		}));
		// Always add account ID to query (for both accounts and contacts tables)
		lookups.push({attribute: {attribute: 'AccountId', entity: 'Account'}});
		// Only add contact ID to query for contact tables (when at least one lookup is "Contact" entity)
		if (lookups.some((lookup) => lookup.attribute.entity === 'Contact')) {
			lookups.push({attribute: {attribute: 'ContactId', entity: 'Contact'}});
		}
		return lookups;
	}, [attributes]);
	return [columns, lookups];
}

function initialCustomizations(
	attributes: AttributeGroupAttribute[],
	defaults?: ColumnIds
): CustomizedColumns {
	if (defaults) return applyCustomizations(defaults, attributes);
	return [
		attributes.filter((attr) =>
			DEFAULT_FROZEN_COLUMNS.includes(getAttributeId(attr))
		),
		attributes.filter(
			(attr) => !DEFAULT_FROZEN_COLUMNS.includes(getAttributeId(attr))
		),
	];
}

function idsFromCustomizedColumns([frozen, scrollable]: CustomizedColumns): [
	string[],
	string[]
] {
	return [frozen.map(getAttributeId), scrollable.map(getAttributeId)];
}

async function loadCustomizations(
	groupID: string,
	selections?: [string[], string[]],
	predicate: (attr: AttributeGroupAttribute) => boolean = () => true,
	defaults?: ColumnIds
): Promise<CustomizedColumns> {
	const [serverAttributes, meta] = await Promise.all([
		getAttributeGroupAttributes(groupID),
		loadCategoryMetadata(),
	]);
	const attributes = serverAttributes.filter(predicate).map(renameAttr(meta));
	if (selections) {
		return applyCustomizations(selections, attributes);
	}
	return initialCustomizations(attributes, defaults);
}

async function loadAttributeGroupNullable(
	group: string | null
): Promise<common.AttributeGroup | null> {
	return typeof group === 'string' ? getAttributeGroup(group) : null;
}

async function loadFilteredAttributeGroupAttributes(
	group: string | null,
	predicate?: (attr: AttributeGroupAttribute) => boolean
): Promise<AttributeGroupAttribute[] | null> {
	if (!group) return null;
	const [attrs, meta] = await Promise.all([
		getAttributeGroupAttributes(group),
		loadCategoryMetadata(),
	]);
	return predicate
		? attrs.filter(predicate).map(renameAttr(meta))
		: attrs.map(renameAttr(meta));
}

// This is a simple solution to fix the display names. If we need to show the
// names in more complicated ways down the line it should be done at the render,
// not when the data is initially loaded (i.e. don't rename them all & clobber
// the original DisplayName like this does)
function renameAttr(
	meta: CategoryMetadata[]
): (attr: AttributeGroupAttribute) => AttributeGroupAttribute {
	return (a) => ({
		...a,
		DisplayName: getAttributeName(a, getMetadataForCategory(meta, a.Category)),
	});
}

function getAttributeName(
	{DisplayName, Subcategory}: AttributeGroupAttribute,
	meta?: CategoryMetadata
): string {
	switch (meta?.topBucketDisplayStrategy) {
		case TopBucketDisplayStrategy.Subcategory:
			return `${Subcategory} - ${DisplayName}`;
		case TopBucketDisplayStrategy.Attribute:
		default:
			return DisplayName;
	}
}

// TODO: write syncing code to prune attributes that are no longer in the group (May not be needed if we just port ordered attr groups to the backend)

interface AttributeGroupCustomizationModalProps {
	title: string;
	initialSelectedAttributes: ColumnIds;
	usableAttributes: AttributeGroupAttribute[];
	categoryMetadata: CategoryMetadata[];
	onSave: (c: ColumnIds) => void;
	onCancel: () => void;
}

function AttributeGroupCustomizationModal({
	title,
	initialSelectedAttributes: [frozen, scrollable],
	usableAttributes,
	categoryMetadata,
	onSave,
	onCancel,
}: AttributeGroupCustomizationModalProps): JSX.Element {
	const initialSelections: Selections = useMemo(
		() => ({frozen, scrollable}),
		[frozen, scrollable]
	);
	const [categories, columns] = useMemo(() => {
		const categories: Record<string, Category> = {};
		const columns: Record<string, Column> = {};
		for (const attr of usableAttributes) {
			const meta = getMetadataForCategory(categoryMetadata, attr.Category);
			if (!categories.hasOwnProperty(attr.Category)) {
				categories[attr.Category] = {
					id: attr.Category,
					name: meta?.displayName ?? attr.Category,
					colIds: [],
				};
			}
			const attrId = getAttributeId(attr);
			categories[attr.Category]!.colIds.push(attrId);
			columns[attrId] = {
				name: attr.DisplayName,
			};
		}
		return [Object.values(categories), columns];
	}, [usableAttributes, categoryMetadata]);
	const columnGroups = useMemo(
		() => [
			{id: 'frozen', name: 'Frozen'},
			{id: 'scrollable', name: 'Scrollable'},
		],
		[]
	);
	const handleSave = useCallback(
		({frozen, scrollable}: Selections) => {
			onSave([frozen || [], scrollable || []]);
		},
		[onSave]
	);

	return (
		<ColumnCustomizationModal
			title={title}
			initialSelections={initialSelections}
			categories={categories}
			columns={columns}
			columnGroups={columnGroups}
			defaultGroupId='scrollable'
			onCancel={onCancel}
			onSave={handleSave}
		/>
	);
}

export function getAttributeId(attr: AttributeGroupAttribute): string {
	return `${attr.Entity}.${attr.AttrName}`;
}

export function attributeHasId(
	id: string,
	attr: AttributeGroupAttribute
): boolean {
	return id === getAttributeId(attr);
}

function withId(
	id: string,
	all: AttributeGroupAttribute[]
): AttributeGroupAttribute | undefined {
	return all.find((attr) => attributeHasId(id, attr));
}

function withIds(
	ids: string[],
	all: AttributeGroupAttribute[]
): AttributeGroupAttribute[] {
	return ids
		.map((id) => withId(id, all))
		.filter((attr): attr is AttributeGroupAttribute => !!attr);
}

function applyCustomizations(
	[frozen, scrollable]: ColumnIds,
	attributes: AttributeGroupAttribute[]
): CustomizedColumns {
	return [withIds(frozen, attributes), withIds(scrollable, attributes)];
}
