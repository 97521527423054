import {
	setKeyCacheData,
	getKeyCacheData,
	clearKeyCacheData,
} from 'common/app/utilities/cacheUtility/cacheUtility';

const SEGMENT_STATE = 'segmentState';

type SegmentState = {
	isCompanyList?: boolean;
	isContactList?: boolean;
};

export const getSessionSegmentState = (): SegmentState | null =>
	getKeyCacheData<SegmentState | null>(SEGMENT_STATE, 'sessionStorage');

export const setSessionSegmentState = (state: SegmentState): void => {
	setKeyCacheData<SegmentState>(SEGMENT_STATE, 'sessionStorage', state);
};

export const clearSessionSegmentState = (): void => {
	clearKeyCacheData(SEGMENT_STATE, 'sessionStorage');
};
