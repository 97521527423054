// @ts-nocheck
// TODO: there are lots of typescript errors here from legacy code that need to be resolved, then remove @ts-nocheck
import LocalStorageUtility from 'common/widgets/utilities/local-storage.utility';
import UserValues from 'common/widgets/utilities/user-values.utility';
import {getString} from 'common/app/utilities/ResourceUtility';
import {clearCacheStorage} from 'common/app/utilities/cacheUtility/cacheUtility';
import {
	clearSession,
	handleResponseErrors,
} from 'common/app/services/SessionService';
import {axios} from 'common/network.vendor';
import {refetchTenantConfigCache} from 'common/stores/tenantConfig';
import NgState from '../../../login/ng-state';
import {generateRandomString} from '../utilities/StringUtilities.ts';
import {Params} from '../../../login/app/login/ParamsInterface';
import {UserDocument} from '../../../login/app/login/saml/Saml.component';

interface Tenant {
	Identifier: string;
	DisplayName: string;
	Pid: number;
	RegisteredTime: number;
	UIVersion: string;
	Status: string;
	TenantType: string;
	EntitledApps: string;
	Product: string;
}

enum InvalidIdaasLoginStatuses {
	internalUserNotInIdaas = '00',
	internalUserAlreadyInIdaas = '01',
	externalUserNotInIdaas = '10',
	externalUserAlreadyInIdaas = '11',
}

interface LoginResponse {
	data: {
		UserName: string;
		FirstName: string;
		LastName: string;
		Uniqueness: string;
		Randomness: string;
		LoginStatus: InvalidIdaasLoginStatuses | null;
		Errors: null | Array<unknown>; // TODO: type Errors
		Success: boolean;
		AuthenticationRoute: null | unknown; // TODO: type AuthenticationRoute
		Result: {
			Tenants: Array<Tenant>;
			MustChangePassword: boolean;
			PasswordLastModified: number;
		};
	};
}

interface ILatticeSettings {
	Login?: {
		TenantsList?: {
			History?: Record<string, Tenant>;
		};
	};
}

export const handleLoginSuccess = (
	response: LoginResponse,
	resolve: Promise.resolve
): Promise => {
	let result = response.data;

	if (result.Success) {
		const setBrowserStorageLoginValues = (): void => {
			LocalStorageUtility.setTokenDocument(
				`${result.Uniqueness}.${result.Randomness}`
			);
			result.Result = {
				...result.Result,
				UserName: result.UserName,
				FirstName: result.FirstName,
				LastName: result.LastName,
			};
			LocalStorageUtility.setLoginDocument(result.Result);
			LocalStorageUtility.setCookieSetTime(new Date().getTime());
		};

		const invalidIdaasLoginStatus = result.LoginStatus;

		if (invalidIdaasLoginStatus) {
			const invalidIdaasLoginStatusHandlers = {
				[InvalidIdaasLoginStatuses.internalUserNotInIdaas]: () =>
					NgState.getAngularState().go(
						'login.idaasLoginWithDnbCredentialsMessage'
					),
				[InvalidIdaasLoginStatuses.internalUserAlreadyInIdaas]: () =>
					NgState.getAngularState().go(
						'login.idaasLoginWithDnbCredentialsMessage'
					),
				[InvalidIdaasLoginStatuses.externalUserNotInIdaas]: () => {
					// Persist login values to browser storage for use in password reset
					setBrowserStorageLoginValues();
					NgState.getAngularState().go('login.idaasResetPasswordMessage');
				},
				[InvalidIdaasLoginStatuses.externalUserAlreadyInIdaas]: () => {
					NgState.getAngularState().go(
						'login.idaasLoginWithDnbCredentialsMessage'
					);
				},
			};

			invalidIdaasLoginStatusHandlers[invalidIdaasLoginStatus]();

			return;
		}

		setBrowserStorageLoginValues();

		resolve(result);
	} else {
		const errors = result && result.Errors ? result.Errors : [];

		result = {
			Success: false,
			errorMessage: errors[0],
		};

		resolve(result.errorMessage);
	}
};

const setTenantHistory = (username: string, tenant?: Tenant): void => {
	if (!username) {
		return;
	}

	const LatticeSettings: ILatticeSettings =
		LocalStorageUtility.getObject('LatticeSettings') ?? {};

	if (!LatticeSettings.Login) {
		LatticeSettings.Login = {};
	}

	if (!LatticeSettings.Login.TenantsList) {
		LatticeSettings.Login.TenantsList = {};
	}

	if (!LatticeSettings.Login.TenantsList.History) {
		LatticeSettings.Login.TenantsList.History = {};
	}

	if (!tenant) {
		LatticeSettings.Login.TenantsList.History[username] = [];
	} else if (!LatticeSettings.Login.TenantsList.History[username]) {
		LatticeSettings.Login.TenantsList.History[username] = [tenant];
	} else {
		LatticeSettings.Login.TenantsList.History[username].unshift(tenant);
	}

	LocalStorageUtility.setObject('LatticeSettings', LatticeSettings);
};

export const OVERRIDE_SSO_REDIRECT_QUERY_PARAM_KEY = 'OVERRIDE_SSO_REDIRECT';

export const redirectToOktaSSO = (): void => {
	axios
		.get(`/pls/enterprisesso/login?redirectTo=${window.location.origin}/login/`)
		.then(function (response) {
			if (!response.data.Success)
				throw new Error(JSON.stringify(response.data));

			window.location.href = response.data.Result;
		})
		.catch(function (error) {
			console.error('Redirect to Okta SSO error', error);

			// Intentional hard refresh. If redirect to Okta SSO fails, fallback to normal login flow
			const url = new URL(window.location.href);
			url.searchParams.append(OVERRIDE_SSO_REDIRECT_QUERY_PARAM_KEY, true);

			window.location.href = url;
		});
};

export const GetSessionDocument = async (
	tenant: Record<string, unknown>,
	username: string
): null | Promise => {
	if (tenant == null) {
		return null;
	}

	return new Promise((resolve, reject) => {
		axios({
			method: 'POST',
			url: '/pls/attach',
			data: JSON.stringify(tenant),
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(
				async (response) => {
					const {data} = response;
					let result = false;

					if (data != null && data.Success === true) {
						LocalStorageUtility.setSessionDocument(data.Result);
						data.Result.User.Tenant = tenant;
						result = data;

						LocalStorageUtility.setClientSession(data.Result.User);
						setTenantHistory(username, tenant);
						await refetchTenantConfigCache();
						resolve(result);
					}

					if (result.Result.User.AccessLevel === null) {
						handleResponseErrors(401);
						resolve(result);
					}
				},
				(response, status) => {
					handleResponseErrors(status);
					resolve(response.data);
				}
			)
			.catch((err) => {
				reject(err);
			});
	});
};

export const Login = (username: string, password: string): Promise => {
	const params = JSON.stringify({
		Username: username,
		// base64 with 16 random str here, is implicit behavior designed to add security through obscurity
		// and not add any safety in the face of a real attack
		Password: `${generateRandomString()}${btoa(password)}`,
	});
	return new Promise((resolve, reject) => {
		axios({
			method: 'POST',
			url: '/pls/lattice-login',
			data: params,
			headers: {
				'ErrorDisplayMethod': 'none',
				'Content-Type': 'application/json',
			},
		})
			.then(
				function onSuccess(response) {
					handleLoginSuccess(response, resolve);
				},
				function onError(response) {
					const result = {
						Success: false,
						errorMessage: getString('LOGIN_UNKNOWN_ERROR'),
					};

					if (response.data && response.data.errorCode === 'LEDP_18001') {
						result.errorMessage = getString('DEFAULT_LOGIN_ERROR_TEXT');
					}

					resolve(result.errorMessage);
				}
			)
			.catch((e) => {
				reject(e);
			});
	});
};

export const GetLoginDoc = (): Promise => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'GET',
			url: `/pls/login-doc`,
			headers: {
				ErrorDisplayMethod: 'none',
			},
		})
			.then(
				function onSuccess(response) {
					handleLoginSuccess(response, resolve);
				},
				function onError(response) {
					const result = {
						Success: false,
						errorMessage: getString('LOGIN_UNKNOWN_ERROR'),
					};

					if (response.data && response.data.errorCode === 'LEDP_18001') {
						result.errorMessage = getString('DEFAULT_LOGIN_ERROR_TEXT');
					}

					resolve(result);
				}
			)
			.catch((e) => {
				reject(e);
			});
	});
};

export const SamlLogin = (
	sessionDocument: Record<string, unknown> | UserDocument
): Promise => {
	return new Promise((resolve, reject) => {
		const result = sessionDocument;
		if (result != null && result !== '') {
			result.Ticket.AuthenticationRoute = result.AuthenticationRoute; // FIXME this is just here until backend starts passing it

			LocalStorageUtility.setTokenDocument(
				`${result.Ticket.Uniqueness}.${result.Ticket.Randomness}`
			);

			// backend will construct DisplayName with FirstName and LastName, separated by space
			const displayName = result.Result.User.DisplayName;
			const index = displayName.indexOf(' ');

			if (index) {
				result.Result.FirstName = displayName.substring(0, index);
				result.Result.LastName = displayName.substring(index + 1);
			}

			result.Result.UserName = result.Result.User.EmailAddress;
			result.Result.Tenants = result.Ticket.Tenants;

			LocalStorageUtility.setLoginDocument(result.Result);

			resolve(result);
		}

		resolve(result);
	});
};

export const PostToJwt = (params: Params): Promise => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'POST',
			url: '/pls/jwt/handle_request',
			data: {
				requestParameters: params,
			},
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(
				function onSuccess(data) {
					resolve(data.data);
				},
				function onError(data, status) {
					handleResponseErrors(status);

					resolve(data.data);
				}
			)
			.catch((e) => {
				reject(e);
			});
	});
};

export const Logout = (params?: Record<string, unknown>): Promise => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'GET',
			data: '',
			url: `/pls/logout?redirectTo=${window.location.origin}`,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(
				// TODO: this Logout onSuccess handler needs documentation - how is SAML handled?
				function onSuccess(data, status) {
					if (data != null && data.data.Success === true) {
						clearCacheStorage();
						const loginDocument = LocalStorageUtility.getLoginDocument();

						const authenticationRoute =
							loginDocument && loginDocument.AuthenticationRoute
								? loginDocument.AuthenticationRoute
								: null;

						const tenantId = UserValues.getTenantId() ?? null;

						LocalStorageUtility.clear(false);

						const sloUrl = data.data.Result;

						if (sloUrl !== null && sloUrl.indexOf('http') === 0) {
							setTimeout(function () {
								window.location.href = sloUrl;
							}, 1000);
						} else {
							let paramString = params
								? `?${Object.keys(params)
										.map(function (k) {
											return `${encodeURIComponent(
												k
											)}=${encodeURIComponent(params[k])}`;
										})
										.join('&')}`
								: '';

							setTimeout(function () {
								if (authenticationRoute === 'SSO') {
									window.open(
										`/login/saml/${tenantId}/logout${paramString}`,
										'_self'
									);
								} else {
									paramString = params
										? `${paramString}&logout=true`
										: '?logout=true';

									setTimeout(function () {
										window.open(`/login${paramString}`, '_self');
									}, 300);
								}
							}, 300);
						}
					} else {
						handleResponseErrors(status);
					}
					resolve(data);
				},
				function onError(data) {
					// If we experience an error while logging out,
					// fail gracefully by clearing the session and reloading the page.
					clearCacheStorage();
					clearSession();

					resolve(data.data);
				}
			)
			.catch((e) => {
				reject(e);
			});
	});
};

export const entitledForDnB = (tenant: Tenant): boolean => {
	if (tenant && tenant.EntitledApps) {
		const entitledForDnB = tenant.EntitledApps.split(',').some(
			(app) => app === 'DnB'
		);
		return !entitledForDnB;
	}
	return !!tenant;
};

export const getTenantHistory = (): Record<string, unknown>[] => {
	const LatticeSettings = LocalStorageUtility.getObject('LatticeSettings');

	const username = LocalStorageUtility.getLoginDocument()?.UserName;

	const currentTenant = UserValues.getTenantName();

	const history =
		LatticeSettings?.Login?.TenantsList?.History?.[username] ?? [];

	const helper = {};

	history.forEach((t) => {
		if (t.DisplayName !== currentTenant && entitledForDnB(t)) {
			helper[t.Identifier] = t;
		}
	});

	return Object.values(helper);
};

export const clearTenantHistory = (username: string): void => {
	setTenantHistory(username);
};
