export default {
	FallbackSrc: function () {
		'ngInject';

		const fallbackSrc = {
			link: function postLink(scope, iElement, iAttrs) {
				iElement.bind('error', function () {
					angular.element(this).attr('src', iAttrs.fallbackSrc);
					angular.element(this).css({display: 'initial'}); // removes onerror hiding image
				});
			},
		};

		return fallbackSrc;
	},
};
