import {react2angular} from 'common/react-vendor';
import LeSwitch from 'widgets/switch';
import {toggle} from 'widgets/toggle/index';
import '../tabs/subjourneyheader/substage.header.ng';

angular
	.module('common.datacloud.query', [
		'common.datacloud.query.substageditheader',
		'common.datacloud.query.builder.queryButtonGroup',
		'common.datacloud.query.builder.parentContainer',
		'common.datacloud.query.builder',
		'common.datacloud.query.results',
		'common.datacloud.query.ac360',
	])
	.component('leSwitch', react2angular(LeSwitch))
	.component('toggle', react2angular(toggle, ['checked', 'onChange', 'label', 'dataTest']));
