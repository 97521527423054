import {isEmpty} from 'lodash';
import {choicesList, compareList} from './transactionStore.constants';
import type {PeriodData} from './transactionStore.types';
import {getPeriodsNames} from './transactionStore.queries';
import {Period} from '../../../query.enums';
import type {CompareData} from '../../../query.types';

const getChoicesList = (): CompareData[] => [...choicesList];

const getCompareList = (): CompareData[] => [...compareList];

const getPeriodDataList = async (): Promise<PeriodData[]> => {
	const periodList: Period[] | undefined = await getPeriodsNames();

	if (isEmpty(periodList)) {
		return [];
	}

	return periodList.map((period: Period) => ({
		name: period,
		displayName: `${period}(s)`,
		value: period === Period.Week ? 7 : 1,
	}));
};

export {getChoicesList, getCompareList, getPeriodDataList};
