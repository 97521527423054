import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {PrecheckResponse, PurchaseHistory} from './ConfigureAttributes.types';

const getPurchaseHistory = async (): Promise<PurchaseHistory[]> =>
	axiosInstance
		.get('/pls/datacollection/metrics/PurchaseHistory/active')
		.then((response) => response.data);

const savePurchaseHistory = (
	data: PurchaseHistory[]
): Promise<PurchaseHistory[]> =>
	axiosInstance
		.post('/pls/datacollection/metrics/PurchaseHistory', data)
		.then((response) => response.data);

const getPrecheck = async (): Promise<PrecheckResponse> =>
	axiosInstance
		.get('/pls/datacollection/precheck')
		.then((response) => response.data);

export default {getPurchaseHistory, savePurchaseHistory, getPrecheck};
