import {ISegment} from 'atlas/data/SegmentConst';
import {
	MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT,
} from 'atlas/navigation/header/components/page-navigation.utils';
import {NgStateService} from 'common/react-vendor';
import {ISegmentHelper} from '../SegmentHelper';
import {
	BaseSegmentHelper,
	IBaseSegmentHelper,
	ITabType,
} from '../SegmentTabsHelper';

const isAccountFitModelingEnv = (route: string): boolean =>
	[
		MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT,
		MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT,
	].includes(route);

/**
 * Tab param from angular route.
 */
type ITabParam =
	| 'createSegment'
	| 'attributes'
	| 'picker'
	| 'createSegmentAccounts'
	| 'createSegmentContacts';

const TabParamTypeMap: Record<ITabParam, ITabType> = {
	createSegment: 'builder',
	attributes: 'attributes',
	picker: 'attributes',
	createSegmentAccounts: 'accounts',
	createSegmentContacts: 'contacts',
};

type IAccountFitModelingHelper = IBaseSegmentHelper & {
	tab: ITabParam;
};

class AccountFitModelingHelper implements IAccountFitModelingHelper {
	public baseSegmentHelper: IBaseSegmentHelper;

	public tab: ITabParam;

	constructor(
		$state: NgStateService,
		aiModel: boolean,
		segment: ISegment,
		tab: ITabParam
	) {
		this.tab = tab;
		this.baseSegmentHelper = new BaseSegmentHelper($state, aiModel, segment);
	}

	getState = (): NgStateService => this.baseSegmentHelper.getState();

	isAIModel = (): boolean => this.baseSegmentHelper.isAIModel();

	getSegmentHelper = (): ISegmentHelper =>
		this.baseSegmentHelper.getSegmentHelper();

	getRoute = (): string => this.baseSegmentHelper.getRoute();

	getTabType = (): ITabType => {
		const {tab} = this;
		return TabParamTypeMap[tab] || 'builder';
	};

	clickTab = (type: ITabType): void => {
		const {getRoute, getState} = this;
		const countingRoutes = ['accounts', 'contacts'];
		const toUpper = (name: string): string =>
			name.charAt(0).toUpperCase() + name.slice(1);
		getState().go(getRoute(), {
			tab: `createSegment${countingRoutes.includes(type) ? toUpper(type) : ''}`,
		});
	};
}

export type {ITabParam, IAccountFitModelingHelper};
export {isAccountFitModelingEnv, AccountFitModelingHelper};
