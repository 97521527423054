import React from 'common/react-vendor';
import {
	designTokens,
	DNBBreadcrumbs,
	DNBLink,
	DNBTypography,
	DNBBreadcrumbsProps,
} from 'common/dnb-uux-vendor';

interface ICommonBreadcrumbs {
	onClick?: () => void;
	isLink: boolean;
	text: string;
	hide?: boolean;
}

interface ICommonBreadcrumbsList {
	breadcrumbsList: ICommonBreadcrumbs[];
	sx?: DNBBreadcrumbsProps['sx'];
}

const CommonBreadcrumbs = ({
	breadcrumbsList,
	sx,
}: ICommonBreadcrumbsList): React.ReactElement => {
	return (
		<DNBBreadcrumbs sx={{fontSize: '12px', width: '800px', ...sx}}>
			{breadcrumbsList.map(({onClick, isLink, text}) => {
				return isLink ? (
					<DNBLink
						key={text}
						size='small'
						underline='hover'
						variant='inline'
						display='block'
						noWrap
						title={text}
						maxWidth={500}
						onClick={onClick}>
						{text}
					</DNBLink>
				) : (
					<DNBTypography
						key={text}
						variant='caption'
						component='div'
						noWrap
						title={text}
						maxWidth={500}
						sx={{color: designTokens.TokenColors.ColorGraySecondary}}>
						{text}
					</DNBTypography>
				);
			})}
		</DNBBreadcrumbs>
	);
};

export type {ICommonBreadcrumbs};
export {CommonBreadcrumbs};
