import {DNBTypography} from 'common/dnb-uux-vendor';
import React from 'common/react-vendor';
import {TextColumnItem} from '../Table/Component/TextColumnItem';
import styles from './TileBody.module.scss';

enum ItemType {
	Text = 0,
	Date,
	DateTime,
	Enum,
}

interface ITileBodyRowItemValue {
	valueType: ItemType;
	value: string;
	stylePrefix?: string;
}

interface ITileBodyRowItem {
	itemKey: string;
	itemValue: ITileBodyRowItemValue;
}

const TileBodyRowItem = ({
	itemKey,
	itemValue,
}: ITileBodyRowItem): React.ReactElement => {
	const {valueType, value, stylePrefix} = itemValue;
	return (
		<div className={styles.TileBodyRowItem}>
			<DNBTypography variant='caption-bold' sx={{mr: 1}}>
				{itemKey}:
			</DNBTypography>
			<TextColumnItem
				value={value}
				valueType={valueType}
				stylePrefix={stylePrefix}
			/>
		</div>
	);
};

interface ITileBody {
	rowItemList: ITileBodyRowItem[];
}

const TileBody = ({rowItemList}: ITileBody): React.ReactElement => {
	return (
		<div className={styles.TileBody}>
			{rowItemList.map(({itemKey, itemValue}) => (
				<TileBodyRowItem
					key={itemKey}
					itemKey={itemKey}
					itemValue={itemValue}
				/>
			))}
		</div>
	);
};

export {TileBody, ItemType};
export type {ITileBody, ITileBodyRowItem, ITileBodyRowItemValue};
