import {ObjectType} from '../ConnectionType';
import {IApiResponse} from './ApiType';

enum UIActionStatus {
	Error = 'Error',
}

enum UIActionView {
	Banner = 'Banner',
	Notice = 'Notice',
}

interface IUIAction extends ObjectType {
	status: UIActionStatus;
	message: string;
	code: string;
	title: string;
	view: UIActionView;
}

interface IUIActionData extends ObjectType {
	UIAction: IUIAction;
}

const getUIAction = (response: IApiResponse): IUIAction => {
	const {data} = response;
	const {UIAction} = data as IUIActionData;
	return UIAction;
};

interface IUIActionV2 extends ObjectType {
	Errors: string[];
	Success: boolean;
}

interface IUIActionV3 {
	errorCode: string;
	errorMsg: string;
}

export {UIActionStatus, UIActionView, getUIAction};
export type {IUIAction, IUIActionData, IUIActionV2, IUIActionV3};
