import type {CubeStats} from './Cube.types';
import type {BucketList, Bkt} from '../../../query.types';

enum AttributeEntity {
	Account = 'Account',
	Contact = 'Contact',
	Campaign = 'Campaign',
	Product = 'Product',
	ProductHierarchy = 'ProductHierarchy',
	Transaction = 'Transaction',
	PeriodTransaction = 'PeriodTransaction',
	PurchaseHistory = 'PurchaseHistory',
	DepivotedPurchaseHistory = 'DepivotedPurchaseHistory',
	CuratedAccount = 'CuratedAccount',
	CuratedContact = 'CuratedContact',
	AnalyticPurchaseState = 'AnalyticPurchaseState',
	Rating = 'Rating',
	LatticeAccount = 'LatticeAccount',
	PrimeAccount = 'PrimeAccount',
	ActivityStream = 'ActivityStream',
	Catalog = 'Catalog',
	WebVisitProfile = 'WebVisitProfile',
	ContactWebVisitProfile = 'ContactWebVisitProfile',
	Opportunity = 'Opportunity',
	AccountMarketingActivity = 'AccountMarketingActivity',
	ContactMarketingActivity = 'ContactMarketingActivity',
	AccountSalesActivity = 'AccountSalesActivity',
	ContactSalesActivity = 'ContactSalesActivity',
	CustomIntent = 'CustomIntent',
	AccountCampaignLaunchHistory = 'AccountCampaignLaunchHistory',
	ContactCampaignLaunchHistory = 'ContactCampaignLaunchHistory',
	TPS = 'TPS',
	DUNSIP = 'DUNSIP',
	AccountSegmentMember = 'AccountSegmentMember',
	ContactSegmentMember = 'ContactSegmentMember',
	ActivitySegmentStats = 'ActivitySegmentStats',
	AccountSegmentStats = 'AccountSegmentStats',
	ContactSegmentStats = 'ContactSegmentStats',
	AccountSegmentCount = 'AccountSegmentCount',
	ContactSegmentCount = 'ContactSegmentCount',
	TimeLine = 'TimeLine',
	AccountTimeLineSubQuery = 'AccountTimeLineSubQuery',
	ContactTimeLineSubQuery = 'ContactTimeLineSubQuery',
}

enum AttributeFlagNode {
	ARRAY = 'ARRAY',
	BINARY = 'BINARY',
	BOOLEAN = 'BOOLEAN',
	MISSING = 'MISSING',
	NULL = 'NULL',
	NUMBER = 'NUMBER',
	OBJECT = 'OBJECT',
	POJO = 'POJO',
	STRING = 'STRING',
}

enum LogicalData {
	Id = 'Id',
	InternalId = 'InternalId',
	Date = 'Date',
	Event = 'Event',
	StageName = 'StageName',
	Reference = 'Reference',
	RowId = 'RowId',
	Opportunity = 'Opportunity',
	Metric = 'Metric',
	Timestamp = 'Timestamp',
}

enum TimeSeriesEntity {
	Opportunity = 'Opportunity',
	MarketingActivity = 'MarketingActivity',
	SalesActivityTask = 'SalesActivityTask',
	SalesActivityEvent = 'SalesActivityEvent',
	WebVisit = 'WebVisit',
	CustomIntent = 'CustomIntent',
}

enum RefreshFrequency {
	Release = 'Release',
	Week = 'Week',
}

enum State {
	Active,
	Inactive,
	Deprecated,
}

enum Tag {
	Internal = 'Internal',
	External = 'External',
	InternalTransform = 'InternalTransform',
	ExternalTransform = 'ExternalTransform',
}

interface Attribute {
	ApprovedUsage: string[];
	AssociatedDataRules: string[];
	AttrName: string;
	AttributeFlagsMap: AttributeFlagsMap;
	BitOffset: number;
	CanEnrich: boolean;
	CanInternalEnrich: boolean;
	CanModel: boolean;
	CanSegment: boolean;
	Category: string;
	ColumnId: string;
	ColumnName: string;
	DataLicense: string;
	DataType: string;
	Description: string;
	DiscretizationStrategy: string;
	DisplayName: string;
	Entity: AttributeEntity;
	FilterTags: string[];
	FundamentalType: string;
	Groups: Record<string, boolean>;
	ImportanceOrdering: number;
	IsAdminDisabledForModel: boolean;
	IsCampaignDerivedField: boolean;
	IsCoveredByMandatoryRule: boolean;
	IsCoveredByOptionalRule: boolean;
	IsExportable: boolean;
	IsHiddenForRemodelingUI: boolean;
	IsHiddenInCategoryTile: boolean;
	IsPremium: boolean;
	JavaClass: string;
	LastDataRefresh: string;
	LogicalDataType: LogicalData;
	MatchDestination: string;
	NumBits: number;
	PhysicalName: string;
	PredictivePower: number;
	RefreshFrequency: RefreshFrequency;
	SecondaryDisplayName: string;
	ShouldDeprecate: number;
	State: State;
	StatisticalType: string;
	Stats?: AttributeStats;
	Subcategory: string;
	SubcategoryAltName?: string;
	Tags: Tag[];
	cube: CubeStats;
	topbkt: Bkt;
}

interface AttributeFlag {
	array: boolean;
	bigDecimal: boolean;
	bigInteger: boolean;
	binary: boolean;
	boolean: boolean;
	containerNode: boolean;
	double: boolean;
	empty: boolean;
	float: boolean;
	floatingPointNumber: boolean;
	int: boolean;
	integralNumber: boolean;
	long: boolean;
	missingNode: boolean;
	nodeType: AttributeFlagNode;
	null: boolean;
	number: boolean;
	object: boolean;
	pojo: boolean;
	short: boolean;
	textual: boolean;
	valueNode: boolean;
}

type AttributeFlagsMap = Record<string, AttributeFlag>;

interface AttributeStats {
	AttrType?: string;
	Bkts?: BucketList;
	Cnt: number;
}

export type {Attribute, AttributeFlag, AttributeFlagsMap, AttributeStats};
export {AttributeEntity, TimeSeriesEntity};
