import {PlaybookWizardActionType} from './enums';
import type {
	PlaybookWizardAction,
	PlaybookWizardState,
	PlaybookWizardValidation,
} from './types';

const clearPlaybookWizardStore = (): PlaybookWizardAction => {
	return {
		type: PlaybookWizardActionType.CLEAR_PLAYBOOK_WIZARD_STORE,
	};
};

const setPlaybookWizardPropertyValue = <PlaybookWizardValue>(
	key: keyof PlaybookWizardState,
	value: PlaybookWizardValue
): PlaybookWizardAction => {
	return {
		type: PlaybookWizardActionType.SET_PLAYBOOK_WIZARD_PROPERTY_VALUE,
		payload: {
			key,
			value,
		},
	};
};

const setPlaybookWizardValidationValue = (
	key: keyof PlaybookWizardValidation,
	value: boolean
): PlaybookWizardAction => {
	return {
		type: PlaybookWizardActionType.SET_PLAYBOOK_WIZARD_VALIDATION_VALUE,
		payload: {
			key,
			value,
		},
	};
};

export {
	clearPlaybookWizardStore,
	setPlaybookWizardPropertyValue,
	setPlaybookWizardValidationValue,
};
