import React from 'common/react-vendor';
import {
	DNBTextField,
	DNBTextFieldProps,
	SearchOutlinedIcon,
	IconButton,
	InputAdornment,
	CloseIcon,
} from 'common/dnb-uux-vendor';

interface SearchInputProps {
	size?: DNBTextFieldProps['size'];
	placeholder?: string;
	onChange: DNBTextFieldProps['onChange'];
	onClear(): void;
	value: DNBTextFieldProps['value'];
}

const SearchInput = ({
	size = 'compact',
	placeholder = 'Search',
	onChange,
	onClear,
	value,
}: SearchInputProps): React.ReactElement => {
	return (
		<DNBTextField
			size={size}
			placeholder={placeholder}
			InputProps={{
				startAdornment: (
					<InputAdornment position='start'>
						<SearchOutlinedIcon />
					</InputAdornment>
				),
				endAdornment: (
					<InputAdornment position='end'>
						<IconButton
							aria-label='clear search field'
							onClick={onClear}
							edge='end'>
							<CloseIcon />
						</IconButton>
					</InputAdornment>
				),
			}}
			value={value}
			onChange={onChange}
		/>
	);
};

export {SearchInput};
