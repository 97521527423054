import {getString} from 'common/app/utilities/ResourceUtility';
import React, {react2angular} from 'common/react-vendor';
import NgState from '../../../ng-state';

export const Forgot = (): JSX.Element => {
	const cancelForgotPasswordClick = (event: React.MouseEvent): void => {
		if (event != null) {
			event.preventDefault();
		}

		NgState.getAngularState().go('login.form');
	};

	return (
		<>
			<div className='form-signin'>
				<div className='form-header'>
					<h3 className='form-signin-heading'>
						{getString('FORGOT_PASSWORD_TITLE')}
					</h3>
					<p>
						{'Please reset your password from '}
						<a href='http://sso.dnb.com'>sso.dnb.com</a>
					</p>
				</div>
				<div className='form-actions'>
					<div className='secondary-actions'>
						<button
							type='button'
							className='button blue-button'
							onClick={cancelForgotPasswordClick}>
							{getString('BUTTON_CANCEL_LABEL')}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

angular
	.module('login.forgot', [])
	.component('loginForgotPassword', react2angular(Forgot, [], []));
