import React from 'common/react-vendor';
import {DNBTypography} from 'common/dnb-uux-vendor';
import classNames from 'classnames';
import {SystemType} from '../../../Data/SystemType';
import {getConnectionIcon} from '../../../Data/SystemConst';
import styles from './NameColumnItem.module.scss';

interface INameColumnItem {
	style?: string;
	isPrimary?: boolean;
	systemType: SystemType;
	systemName: string;
	GDSPId?: string;
}

const NameColumnItem = ({
	style,
	isPrimary,
	systemType,
	systemName,
	GDSPId,
}: INameColumnItem): React.ReactElement => {
	const icon = getConnectionIcon(systemType, GDSPId);
	return (
		<div
			title={systemName}
			className={classNames(styles.NameColumnItem, style)}>
			<img className={styles.SystemType} src={icon} alt={icon} />
			<DNBTypography
				variant={isPrimary ? 'compact-bold' : 'compact-body'}
				noWrap
				color={(theme) => theme.colors.ColorGraySecondary}
				sx={{mx: 3}}>
				{systemName}
			</DNBTypography>
		</div>
	);
};

export {NameColumnItem};
export type {INameColumnItem};
