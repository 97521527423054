import {Box, DNBTab, DNBTabs} from 'common/dnb-uux-vendor';
import React, {NgStateService} from 'common/react-vendor';
import {setJourneyEntitiesProperty} from 'common/stores/query';
import StateHistory from 'atlas/StateHistory';
import {NgStateParams} from 'atlas/ratingsengine/AccountFitModelingWizard/types/accountFitModelingAngular.types';
import {SegmentNgState} from 'atlas/helpers/NavigateHelper';
import {IJourneyStageOverview} from 'atlas/segmentation/SegmentDashboard/Data/SegmentJourneyType';
import {getQuerybuilderCount} from './QueryHelper';
import {ITabType, RouteTabTypeMap} from './SegmentTabsHelper';
import {ITab, TabType, TabLabel} from './SegmentTabs';
import {ISegmentSubstage} from '../subjourneyheader/SubstageEditHeader';

const TabsConfig = (overview?: IJourneyStageOverview): ITab[] => {
	const {totalAccount = 0, totalContact = 0} =
		overview || ({} as IJourneyStageOverview);
	return [
		{
			label: (
				<TabLabel
					label={TabType.QueryBuilder}
					value='substage'
					count={{value: getQuerybuilderCount()}}
				/>
			),
			value: 'substage',
		},
		{
			label: (
				<TabLabel
					label={TabType.Accounts}
					value='accounts'
					count={{value: totalAccount}}
				/>
			),
			value: 'accounts',
		},
		{
			label: (
				<TabLabel
					label={TabType.Contacts}
					value='contacts'
					count={{value: totalContact}}
				/>
			),
			value: 'contacts',
		},
	];
};

interface IJourneyTabs {
	$state: NgStateService;
	$stateParams?: NgStateParams;
	subStageOverview?: IJourneyStageOverview;
}

const JourneyTabs = ({
	$state,
	$stateParams,
	subStageOverview,
}: IJourneyTabs): React.ReactElement => {
	const {segment, stageId, subStageId} = $stateParams || {};
	const defaultValue = React.useMemo(() => {
		const lastTo = StateHistory.lastTo()?.name || '';
		return RouteTabTypeMap[lastTo] || 'builder';
	}, []);
	const [value, setValue] = React.useState(defaultValue);
	const clickTab = React.useCallback(
		(value: ITabType) => {
			if (value === 'substage') {
				$state.go(
					SegmentNgState.Substage,
					{
						segment,
						stageId,
						subStageId,
					} as ISegmentSubstage,
					{reload: true}
				);
			} else if (value === 'accounts') {
				$state.go(
					SegmentNgState.SubstageAccounts,
					{
						segment,
						stageId,
						stages: [subStageId],
					},
					{reload: true}
				);
			} else if (value === 'contacts') {
				$state.go(
					SegmentNgState.SubstageContacts,
					{
						segment,
						stageId,
						stages: [subStageId],
					},
					{reload: true}
				);
			}
		},
		[$state, segment, stageId, subStageId]
	);
	return (
		<Box sx={{backgroundColor: 'white'}}>
			<DNBTabs
				value={value}
				sx={{pl: 6, pb: 4}}
				onChange={(_event, value) => {
					setValue(value);
					setJourneyEntitiesProperty(undefined);
					clickTab(value);
				}}>
				{TabsConfig(subStageOverview)
					.filter(({hidden}) => !hidden)
					.map((tab) => (
						<DNBTab key={tab.value} {...tab} />
					))}
			</DNBTabs>
		</Box>
	);
};

export {JourneyTabs};
