import {getAdsAccountListUrl} from 'atlas/connectors/EIF/Data/API/BE/GetAdsAccountList';
import {AxiosResponse} from 'common/network.vendor';
import {
	ConnectionPrioritiesUrl,
	ConnectionUrl,
	getImportConnectionUrlById,
} from './BE/CreateOrUpdateConnection';
import {
	ActivationConnectionUrl,
	AttributeSetUrl,
	getActivationConnectionListUrl,
	getActivationConnectionUrlById,
	getAvailableLookupIdsByAudienceType,
	getCustomObjectsUrl,
	getGroupAttributes,
	getSystemFields,
	PlsTrayUserDocument,
} from './BE/ActivationConnection';
import {ObjectType} from '../ConnectionType';
import {
	BrokerAuthInfoUrl,
	BrokerAuthUrl,
	BrokerWithTrayUrl,
} from './BE/BrokerAuth';
import {BrokerAuthActivationUrl} from './BE/ActivationAuth';
import {
	getActivationConnectionListResponse,
	getActivationConnectionResponse,
	getFEResponse,
	getImportConnectionListResponse,
	getImportConnectionResponse,
	getImportSourceByIdResponse,
	getImportSourceResponse,
	getFieldsFromCurrentMapResponse,
} from './EIFApiUtils';
import {IImportConnectionResponse} from './FE/ImportResponse';
import {
	ApiMethod,
	ApiResponseType,
	ApiType,
	IApiDataType,
	IAPIFetcher,
	IApiResponse,
	IAPITypeConfig,
	IDefaultResponse,
} from './ApiType';
import {UpdateAuthUrl} from './BE/UpdateAuth';
import {
	getSourceListPerConnectionUrl,
	GetSourceListUrl,
} from './BE/GetSourceList';
import {getPauseResumeSourceListUrl} from './BE/PauseResumeSource';
import {DocumentType} from '../DocumentType';
import {getCreateSourceUrl} from './BE/CreateSource';
import {getDeleteSourceUrl} from './BE/DeleteSource';
import {IActivationConnectionResponse} from './FE/ActivationResponse';
import {
	GetSourceIdListUrl,
	GetTargetSourceIdListUrl,
	GetTargetSourceListUrl,
	ICdpAttribute,
	IGetAttributeIdList,
	toGetSourceIdList,
	toGetTargetSourceList,
	updateRelationShipListUrl,
} from './BE/RelationShip';
import {
	IImportSourceResponse,
	IImportSourceV2Response,
} from './FE/ImportSourceResponse';
import {
	getSourceByIdUrl,
	toImportSourceResponseByIdV2,
} from './BE/GetSourceById';
import {
	getSourceInfoByIdUrl,
	GetSourceInfoUrl,
	UpdateSourceInfoUrl,
} from './BE/GetUpdateSource';
import {IInboundSourceInfo} from './BE/InboundSource';
import {ICommonResponse} from './FE/CommonStatusResponse';
import {IGetSourcePerConnection} from './BE/GetSourcePerConnection';
import {getOneOffImportUrl} from './BE/OneOffImport';
import {GetTenantTeamsUrl} from '../../Configuration/Activation/Component/TeamItem';
import {getCurrentMap} from './BE/GetCurrentMap';

const GetImportConnectionList: IAPIFetcher<IImportConnectionResponse[]> = {
	convertHook: getImportConnectionListResponse,
};

const GetImportConnectionById: IAPIFetcher<IImportConnectionResponse> = {
	convertHook: getImportConnectionResponse,
};

const GetSourceList: IAPIFetcher<IImportSourceResponse[]> = {
	convertHook: getImportSourceResponse,
};

const GetSourceListPerConnection: IAPIFetcher<IImportSourceResponse[]> = {
	convertHook: getImportSourceResponse,
};

const GetSourceById: IAPIFetcher<IImportSourceResponse> = {
	convertHook: getImportSourceByIdResponse,
};

const GetSourceIdList: IAPIFetcher<ICdpAttribute[]> = {
	convertHook: (data: IApiDataType) =>
		getFEResponse<IGetAttributeIdList, ICdpAttribute[]>(
			data,
			toGetSourceIdList
		),
};

const GetTargetSourceIdList: IAPIFetcher<ICdpAttribute[]> = {
	convertHook: (data: IApiDataType) =>
		getFEResponse<IGetAttributeIdList, ICdpAttribute[]>(
			data,
			toGetSourceIdList
		),
};

const GetTargetSourceList: IAPIFetcher<IImportSourceResponse[]> = {
	convertHook: (data: IApiDataType) =>
		getFEResponse<IGetSourcePerConnection, IImportSourceResponse[]>(
			data,
			toGetTargetSourceList
		),
};

const GetActivationConnectionList: IAPIFetcher<
	IActivationConnectionResponse[]
> = {
	convertHook: getActivationConnectionListResponse,
};

const GetActivationConnectionById: IAPIFetcher<IActivationConnectionResponse> =
	{
		convertHook: getActivationConnectionResponse,
	};

const GetSourceInfoById: IAPIFetcher<IImportSourceV2Response> = {
	convertHook: (data: IApiDataType) =>
		getFEResponse<ICommonResponse<IInboundSourceInfo>, IImportSourceV2Response>(
			data,
			toImportSourceResponseByIdV2
		),
};

const GetSourceInfo: IAPIFetcher<IImportSourceV2Response> = {
	convertHook: (data: IApiDataType) =>
		getFEResponse<ICommonResponse<IInboundSourceInfo>, IImportSourceV2Response>(
			data,
			toImportSourceResponseByIdV2
		),
};

const GetCurrentMap: IAPIFetcher<string[]> = {
	convertHook: getFieldsFromCurrentMapResponse,
};

type IAPIFetcherConfig = {
	[key in ApiType]?: IAPIFetcher<IApiDataType>;
};

const APIFetcherConfig: IAPIFetcherConfig = {
	GetImportConnectionList,
	GetImportConnectionById,
	GetSourceList,
	GetSourceListPerConnection,
	GetSourceById,
	GetSourceIdList,
	GetTargetSourceIdList,
	GetTargetSourceList,
	GetActivationConnectionList,
	GetActivationConnectionById,
	GetSourceInfoById,
	GetSourceInfo,
	GetCurrentMap,
};

const APITypeConfig: IAPITypeConfig = {
	// inbound apis.
	[ApiType.GetImportConnectionList]: {
		url: () => ConnectionUrl,
		responseType: ApiResponseType.Array,
	},
	[ApiType.CreateConnection]: {
		url: () => ConnectionUrl,
		method: ApiMethod.post,
	},
	[ApiType.UpdateConnection]: {
		url: () => ConnectionUrl,
		method: ApiMethod.put,
	},
	[ApiType.GetImportConnectionById]: {
		url: ({id}: ObjectType) => getImportConnectionUrlById(id as string),
	},
	[ApiType.DeleteConnectionById]: {
		url: ({id}: ObjectType) => getImportConnectionUrlById(id as string),
		method: ApiMethod.delete,
	},
	[ApiType.BrokerAuthInfo]: {
		url: () => BrokerAuthInfoUrl,
		method: ApiMethod.post,
	},
	[ApiType.BrokerAuth]: {
		url: () => BrokerAuthUrl,
		method: ApiMethod.post,
	},
	[ApiType.BrokerWithTray]: {
		url: () => BrokerWithTrayUrl,
		method: ApiMethod.post,
	},
	[ApiType.GetSourceListPerConnection]: {
		url: ({name}: ObjectType) => getSourceListPerConnectionUrl(name as string),
		method: ApiMethod.get,
		responseType: ApiResponseType.Array,
	},
	[ApiType.GetSourceList]: {
		url: () => GetSourceListUrl,
		method: ApiMethod.get,
		responseType: ApiResponseType.Array,
	},
	[ApiType.GetSourceById]: {
		url: ({id}: ObjectType) => getSourceByIdUrl(id as string),
		method: ApiMethod.get,
	},
	[ApiType.DeleteSource]: {
		url: ({id}: ObjectType) => getDeleteSourceUrl(id as string),
		method: ApiMethod.delete,
		responseType: ApiResponseType.Object,
	},
	[ApiType.PauseResumeSource]: {
		url: ({id, enabled}: ObjectType) =>
			getPauseResumeSourceListUrl(id as string, enabled as boolean),
		method: ApiMethod.put,
		responseType: ApiResponseType.Object,
	},
	[ApiType.CreateSource]: {
		url: ({channelName, documentType, displayName}: ObjectType) =>
			getCreateSourceUrl(
				channelName as string,
				documentType as DocumentType,
				displayName as string
			),
		method: ApiMethod.post,
		responseType: ApiResponseType.Object,
	},
	[ApiType.UpdateAuth]: {
		url: () => UpdateAuthUrl,
		method: ApiMethod.post,
	},
	[ApiType.GetDocumentTypeList]: {
		url: () => '',
		method: ApiMethod.get,
	},
	[ApiType.GetUniqueIDList]: {
		url: () => '',
		responseType: ApiResponseType.Array,
	},
	OneOffImport: {
		url: ({id}: ObjectType) => getOneOffImportUrl(id as string),
		method: ApiMethod.post,
	},
	// relationship apis,
	[ApiType.GetSourceIdList]: {
		url: () => GetSourceIdListUrl,
		method: ApiMethod.post,
		responseType: ApiResponseType.Array,
	},
	[ApiType.GetTargetSourceIdList]: {
		url: () => GetTargetSourceIdListUrl,
		method: ApiMethod.post,
		responseType: ApiResponseType.Array,
	},
	[ApiType.GetTargetSourceList]: {
		url: () => GetTargetSourceListUrl,
		method: ApiMethod.post,
		responseType: ApiResponseType.Array,
	},
	[ApiType.UpdateRelationShipList]: {
		url: ({sourceId}: ObjectType) =>
			updateRelationShipListUrl(sourceId as string),
		method: ApiMethod.post,
	},
	// for activation
	[ApiType.GetActivationConnectionList]: {
		url: () => getActivationConnectionListUrl(true),
		responseType: ApiResponseType.Array,
	},
	[ApiType.GetActivationConnectionById]: {
		url: ({id}: ObjectType) => getActivationConnectionUrlById(id as string),
	},
	[ApiType.CreateActivationConnection]: {
		url: () => ActivationConnectionUrl,
		method: ApiMethod.post,
	},
	[ApiType.UpdateActivationConnection]: {
		url: ({id}: ObjectType) => getActivationConnectionUrlById(id as string),
		method: ApiMethod.put,
	},
	[ApiType.DeleteActivationConnectionByName]: {
		url: ({configId}: ObjectType) =>
			getActivationConnectionUrlById(configId as string),
		method: ApiMethod.delete,
	},
	[ApiType.BrokerAuthActivation]: {
		url: () => BrokerAuthActivationUrl,
		method: ApiMethod.post,
	},
	[ApiType.GetTrayUserdocument]: {
		url: () => PlsTrayUserDocument,
		responseType: ApiResponseType.Object,
	},
	[ApiType.GetAvailableLookupIds]: {
		url: ({audienceType}: ObjectType) =>
			getAvailableLookupIdsByAudienceType(audienceType as string),
		responseType: ApiResponseType.Object,
	},
	[ApiType.GetAttributeGroupsData]: {
		url: () => AttributeSetUrl,
		responseType: ApiResponseType.Array,
	},
	[ApiType.GetSystemFieldsData]: {
		url: ({SystemType, authId, audienceType}: ObjectType) =>
			getSystemFields(
				SystemType as string,
				authId as string,
				audienceType as string | undefined
			),
		responseType: ApiResponseType.Array,
	},
	[ApiType.GetGroupAttributes]: {
		url: ({attributeSetName, externalSystemName}: ObjectType) =>
			getGroupAttributes(
				attributeSetName as string,
				externalSystemName as string
			),
		responseType: ApiResponseType.Array,
	},
	[ApiType.UpdateConnectionPriorities]: {
		url: () => ConnectionPrioritiesUrl,
		method: ApiMethod.post,
	},
	[ApiType.GetCustomObjects]: {
		url: ({authId}: ObjectType) => getCustomObjectsUrl(authId as string),
		responseType: ApiResponseType.Object,
	},
	GetSourceInfoById: {
		url: ({id}: ObjectType) => {
			return getSourceInfoByIdUrl(id as string);
		},
	},
	GetSourceInfo: {
		url: () => GetSourceInfoUrl,
		method: ApiMethod.post,
	},
	UpdateSourceInfo: {
		url: () => UpdateSourceInfoUrl,
		method: ApiMethod.post,
	},
	[ApiType.GetAccountList]: {
		url: getAdsAccountListUrl,
	},
	[ApiType.GetTenantTeams]: {
		url: () => GetTenantTeamsUrl,
	},
	[ApiType.GetCurrentMap]: {
		url: ({id}) => getCurrentMap(id as string),
	},
};

const DefaultObjectApiResponse: IApiResponse = {
	data: {},
};

const DefaultArrayApiResponse: IApiResponse = {
	data: [],
};

const DefaultResponse: IDefaultResponse = {
	[ApiResponseType.Array]: DefaultArrayApiResponse,
	[ApiResponseType.Object]: DefaultObjectApiResponse,
};

const isValidResponse = ({status}: IApiResponse | AxiosResponse): boolean => {
	return status === 200;
};

export {APITypeConfig, APIFetcherConfig, DefaultResponse, isValidResponse};
