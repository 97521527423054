/**
 * README
 *
 * This file extends react2angular: https://github.com/coatue-oss/react2angular/blob/master/index.tsx
 * The purpose is to add a fourth argument "Wrapper" that allows us to wrap our react component in some high-level component.
 * The immediate need for this functionality is to support Material UI's theme provider, but it can also be used for React strict mode.
 * It is generally not best practice to fork a library locally, but this is a small function that will be deprecated soon once the angular migration is complete.
 */

/* eslint-disable @typescript-eslint/explicit-function-return-type */
// @ts-nocheck
import {IAugmentedJQuery} from 'angular';
import fromPairs from 'lodash.frompairs';
import NgComponent from 'ngcomponent';
import * as React from 'react';
import {createRoot} from 'react-dom/client';

export function react2angularWithWrapper<Props>(
	Class: React.ComponentType<React.PropsWithChildren<Props>>,
	bindingNames: (keyof Props)[] | null = null,
	injectNames: string[] = [],
	Wrapper: (props: React.PropsWithChildren) => React.ReactElement
): angular.IComponentOptions {
	/* eslint-disable react/forbid-foreign-prop-types */
	const names =
		bindingNames ||
		(Class.propTypes && (Object.keys(Class.propTypes) as (keyof Props)[])) ||
		[];
	/* eslint-enable react/forbid-foreign-prop-types */
	return {
		bindings: fromPairs(names.map((_) => [_, '<'])),
		controller: [
			'$element',
			...injectNames,
			class extends NgComponent<Props> {
				isDestroyed = false;

				injectedProps: {[name: string]};

				constructor(
					private $element: IAugmentedJQuery,
					...injectedProps: unknown[]
				) {
					super();
					this.injectedProps = {};
					this.root = createRoot(this.$element[0]);
					injectNames.forEach((name, i) => {
						this.injectedProps[name] = injectedProps[i];
					});
				}

				static get $$ngIsClass() {
					return true;
				}

				render() {
					if (!this.isDestroyed) {
						this.root.render(
							<Wrapper>
								<Class {...this.props} {...this.injectedProps} />
							</Wrapper>
						);
					}
				}

				componentWillUnmount() {
					this.isDestroyed = true;
					this.root.unmount();
				}
			},
		],
	};
}

/* eslint-enable @typescript-eslint/explicit-function-return-type */
