import React from 'common/react-vendor';
import {
	ApartmentOutlinedIcon,
	ContactsIcon,
	DNBIconButton,
	JoinInnerIcon,
	ExpandMoreIcon,
	ExpandLessIcon,
	DeleteOutlineIcon,
	AddIcon,
	RemoveIcon,
	EditOutlinedIcon,
	ContentCopyIcon,
	DNBButton,
} from 'common/dnb-uux-vendor';
import SvgUnion from '../../../../../atlas/assets/svgrs/segmentation/Union';

interface IIconMap {
	[key: string]: React.ReactElement;
}
const IconMap: IIconMap = {
	ApartmentOutlinedIcon: <ApartmentOutlinedIcon />,
	ContactsIcon: <ContactsIcon />,
	SvgUnion: <SvgUnion />,
	JoinInnerIcon: <JoinInnerIcon />,
	ExpandMoreIcon: <ExpandMoreIcon />,
	ExpandLessIcon: <ExpandLessIcon />,
	DeleteOutlineIcon: <DeleteOutlineIcon />,
	AddIcon: <AddIcon />,
	RemoveIcon: <RemoveIcon />,
	EditOutlinedIcon: <EditOutlinedIcon />,
	ContentCopyIcon: <ContentCopyIcon />,
};

const AdvancedQueryIcon = ({
	icon,
	title,
	dataTest,
	className,
	disabled,
	onClick,
	isButton = false,
	buttonText,
	size = 'small',
}: {
	icon: string;
	title?: string;
	dataTest?: string;
	className?: string;
	disabled?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	isButton?: boolean;
	buttonText?: string;
	size?: 'default' | 'compact' | 'small';
}): React.ReactElement => {
	return !isButton ? (
		<DNBIconButton
			className={className}
			disabled={disabled}
			title={title}
			data-test={dataTest}
			size={size}
			onClick={(e) => onClick?.(e)}>
			{IconMap[icon]}
		</DNBIconButton>
	) : (
		<DNBButton
			size={size}
			sx={{lineHeight: 1, padding: 0, cursor: 'pointer'}}
			variant='text'
			className={className}
			data-test={dataTest}
			disabled={disabled}
			title={title}
			startIcon={IconMap[icon]}
			onClick={(e) => onClick?.(e)}>
			{buttonText}
		</DNBButton>
	);
};

export {AdvancedQueryIcon};
