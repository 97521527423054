import {isProductionHostname} from 'common/app/utilities/EnvUtility';
import {Query} from 'common/components/datacloud/datacloud.types';
import LocalStorageUtility from 'widgets/utilities/local-storage.utility';
import {
	ExternalSystem,
	ISegment,
	ListSystemType,
	SegmentDataType,
} from '../data/SegmentConst';

export const createHooversUrl = (path: string): string => {
	const hooversDomain = isProductionHostname()
		? 'https://app.hoovers.dnb.com/login-redirect'
		: 'https://dev-app.hoovers.dnb.com/login-redirect';

	const hooversUrl = new URL(hooversDomain);
	hooversUrl.searchParams.append(
		'username',
		LocalStorageUtility.getLoginDocument().UserName
	);
	hooversUrl.searchParams.append('provider', 'okta');
	hooversUrl.searchParams.append('RelayState', path);
	return hooversUrl.toString();
};

export const getSubSegmentStatus = (status: string): string =>
	status === 'Active' ? 'Active' : 'Pending';

export const shouldIgnoreAccountCount = ({
	dataType,
	listSegment,
}: ISegment): boolean => {
	if (dataType) {
		return [
			SegmentDataType.ExploreModuleContacts,
			SegmentDataType.MyContactList,
		].includes(dataType as SegmentDataType);
	}

	// A workaround judgement used in Campaign Overview page which api returns without dataType
	return (
		(listSegment?.systemType === ListSystemType.DataVision &&
			listSegment.externalSystem === ExternalSystem.DataVision) ||
		(listSegment?.systemType === ListSystemType.ContactList &&
			listSegment.externalSystem === ExternalSystem.InternalDefault)
	);
};

export const segmentFormat = (segment: Query): Query => {
	const status = segment.status ?? 'Active';
	return {
		...segment,
		status: segment.isSubSegment ? getSubSegmentStatus(status) : status,
		...(segment.team && segment.team.TeamName
			? {teamName: segment.team.TeamName}
			: {}),
	};
};

export const getSegmentDisplayDataType = (dataType: string): string =>
	dataType === 'D&B Data' ? SegmentDataType.ExploreModuleContacts : dataType;

const redirectToDataVision = (externalSegmentId?: string): void => {
	const hooversUrl = createHooversUrl(
		externalSegmentId
			? `/datavision/#/search/${externalSegmentId}`
			: `/datavision/#/search/new`
	);
	window.open(hooversUrl, '_blank');
};

export default redirectToDataVision;
