import dateFormat from 'dateformat';

const FormatStringDate = (
	dateString: string | number | null,
	includeTime: boolean | null
): string => {
	const hasToIncludeTime = includeTime || false;

	if (dateString === null || dateString === '') {
		return '';
	}

	const dateObj = new Date(dateString);

	if (hasToIncludeTime) {
		return `${dateObj.toLocaleDateString()} ${dateObj.toLocaleTimeString()}`;
	}

	return dateObj.toLocaleDateString();
};

const FormatEpochDate = (dateString: string | null): string => {
	if (dateString === null || dateString === '') {
		return '';
	}

	const epochTime = parseInt(dateString, 10) * 1000;
	const dateObj = new Date();

	dateObj.setTime(epochTime);

	return dateObj.toUTCString();
};

const FormatShortDate = (dateString: string | null): string => {
	if (dateString === null) {
		return '';
	}

	return dateFormat(new Date(dateString), 'm/d/yyyy');
};

export {FormatStringDate, FormatEpochDate, FormatShortDate};
