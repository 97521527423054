import {
	NoAdminRouteMap,
	ROUTE_PAGE,
} from 'common/app/utilities/AdminPageUtility';
import {ISessionTenant} from 'common/widgets/utilities/local-storage.utility';
import {SchemaRoutes} from 'atlas/connectors/EIF/schema/consts';
import {SegmentNgState} from '../../../helpers/NavigateHelper';
import {WebInsightsRoutes} from '../../../webInsights/configs/WebInsightsConst';
import {WebInsightsTabsType} from '../../../webInsights/configs/WebInsightsTypes';

// #region Models
export const MODLES_NEW = 'home.models_new';
export const MODEL_DASHBOARD = 'home.ratingsengine.dashboard';
export const MODEL_RATINGS = 'home.model.ratings';
export const MODEL_NOTES = 'home.ratingsengine.dashboard.notes';
export const MODEL_RULES =
	'home.ratingsengine.dashboard.segment.attributes.rules';
export const MODEL_ATTRIBUTE_LIST = 'home.model.datacloud';
export const MODEL_ATTRIBUTE_ANALYSIS = 'home.model.attributes';
export const MODEL_PERFORMANCE = 'home.model.performance';
export const MODEL_SUMMARY = 'home.model.summary';

// #region New Modeling
export const MODELS_CREATE_ACCOUNT_FIT_MODELING =
	'home.ratingsengine.createAccountFitModeling';

export const MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT =
	'home.ratingsengine.createAccountFitModeling.createSegment';
export const MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT_ATTRIBUTES =
	'home.ratingsengine.createAccountFitModeling.createSegment.attributes';
export const MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT_ATTRIBUTES_PICKER =
	'home.ratingsengine.createAccountFitModeling.createSegment.picker';

export const MODELS_CREATE_ACCOUNT_FIT_MODELING_SUCCESS_CRITERIA =
	'home.ratingsengine.createAccountFitModeling.successCriteria';
export const MODELS_CREATE_ACCOUNT_FIT_MODELING_SUCCESS_CRITERIA_ATTRIBUTES =
	'home.ratingsengine.createAccountFitModeling.successCriteria.attributes';
export const MODELS_CREATE_ACCOUNT_FIT_MODELING_SUCCESS_CRITERIA_ATTRIBUTES_PICKER =
	'home.ratingsengine.createAccountFitModeling.successCriteria.picker';

export const MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT =
	'home.ratingsengine.createAccountFitModeling.scoringSegment';
export const MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT_ATTRIBUTES =
	'home.ratingsengine.createAccountFitModeling.scoringSegment.attributes';
export const MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT_ATTRIBUTES_PICKER =
	'home.ratingsengine.createAccountFitModeling.scoringSegment.picker';
// #endregion New Modeling

export const HAS_MODELS_SUB_PAGE_NAVIGATION = [
	MODEL_DASHBOARD,
	MODEL_RATINGS,
	MODEL_NOTES,
	MODEL_RULES,
];
export const VIEWS_MODEL_SUB_PAGE_NAVIGATION = [
	MODEL_ATTRIBUTE_LIST,
	MODEL_ATTRIBUTE_ANALYSIS,
	MODEL_PERFORMANCE,
	MODEL_SUMMARY,
];
export const MODELS_CHILD_ROUTES = [
	MODELS_CREATE_ACCOUNT_FIT_MODELING,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT_ATTRIBUTES,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT_ATTRIBUTES_PICKER,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SUCCESS_CRITERIA,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SUCCESS_CRITERIA_ATTRIBUTES,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SUCCESS_CRITERIA_ATTRIBUTES_PICKER,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT_ATTRIBUTES,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT_ATTRIBUTES_PICKER,
];
// #endregion Models

// #region play
export const PLAYLISTCHANNELS = 'home.playlistchannels';
export const CAMPAIGN_LIST_GROUPS = 'home.campaigngroups';
export const CAMPAIGN_LIST_LAUNCH_HISTORY = 'home.newlaunchhistory';
export const CAMPAIGN_LIST_CHANNELS = 'home.channels';
export const CAMPAIGN_OVERVIEW = 'home.playbook.overview';
export const CAMPAIGN_TARGETS = 'home.playbook.dashboard.targets.accounts';

// #region talking points
export const CAMPAIGN_INSIGHTS = 'home.playbook.dashboard.insights'; // Old version

export const CAMPAIGN_TALKING_POINTS = 'home.playbook.dashboard.talkingpoints'; // New version with ai
export const CAMPAIGN_CREATE_AI_TALKING_POINT =
	'home.playbook.dashboard.createAiTalkingPoint';
// #endregion talking points

export const CAMPAIGN_LAUNCHHISTORY = 'home.playbook.dashboard.launchhistory';
export const CAMPAIGN_LIVERAMPLAUNCH = 'home.playbook.liveramplaunch';

export const HAS_CAMPAIGN_SUB_PAGE_NAVIGATION = [
	CAMPAIGN_OVERVIEW,
	CAMPAIGN_TARGETS,
	CAMPAIGN_INSIGHTS,
	CAMPAIGN_TALKING_POINTS,
	CAMPAIGN_CREATE_AI_TALKING_POINT,
	CAMPAIGN_LAUNCHHISTORY,
	CAMPAIGN_LIVERAMPLAUNCH,
];
// #endregion play

export const SEGMENTS_NEW = 'home.segments_new';
export const SEGMENTS_MARKETPLACE = 'home.marketplaceSegments';
export const SEGMENTS_ACCOUNTLIST = 'home.accountlist';
export const SEGMENTS_CONTACTLIST = 'home.contactlist';
export const HAS_SEGMENTS_SUB_PAGE_NAVIGATION = [
	SEGMENTS_NEW,
	SEGMENTS_MARKETPLACE,
	SEGMENTS_ACCOUNTLIST,
	SEGMENTS_CONTACTLIST,
];
export const SEGMENTS_EXPLORER = 'home.segment.explorer.builder';
export const SEGMENTS_CONTACTS = 'home.segment.contacts';
export const SEGMENTS_ACCOUNTS = 'home.segment.accounts';
export const SEGMENTS_ACCOUNTS360 = 'home.segment.accounts.contacts';
export const SEGMENTS_JOURNEYACCOUNTS360 = `${SegmentNgState.JourneyAccounts}.contacts`;
export const SEGMENTS_CHILDROUTES = [
	SEGMENTS_EXPLORER,
	SEGMENTS_CONTACTS,
	SEGMENTS_ACCOUNTS,
	SEGMENTS_ACCOUNTS360,
	SEGMENTS_JOURNEYACCOUNTS360,
	SegmentNgState.Dashboard,
	SegmentNgState.Subsegments,
	SegmentNgState.JourneyAccounts,
	SegmentNgState.JourneyContacts,
	SegmentNgState.Substage,
	SegmentNgState.SubstageAccounts,
	SegmentNgState.SubstageContacts,
];
export const ATTRIBUTE_SCHEMA = 'home.attributes.schema';
export const SCHEMA_CHILD_ROUTES = [
	SchemaRoutes.account,
	SchemaRoutes.contact,
	SchemaRoutes.activity,
	SchemaRoutes.product,
	SchemaRoutes.general,
];

// #region Admin
export const ADMIN = 'home.admin';
// #region Attributes
export const ADMIN_ATTRIBUTE_GROUP = 'home.admin.attributeGroup';
export const ADMIN_ACTIVATE_ATTRIBUTES = 'home.admin.activateAttributes';
export const ADMIN_ENABLE_ATTRIBUTES = 'home.admin.enableAttributes';
export const ADMIN_EDIT_ATTRIBUTES = 'home.admin.editAttributes';
export const ADMIN_CDP_ATTRIBUTES = 'home.admin.cdpAttributes';
export const ADMIN_CONFIGURE_ATTRIBUTES = 'home.admin.configureAttributes';
export const ADMIN_WEB_PERSONALIZATION = 'home.admin.webPersonalization';
// #endregion Attributes
// #region Jobs
export const ADMIN_JOBS_DATA_PROCESSING = 'home.admin.jobsDataProcessing';
export const ADMIN_JOBS_MODEL = 'home.admin.jobsModelJobs';
export const ADMIN_JOBS_EXPORT = 'home.admin.jobsExportJobs';
export const ADMIN_JOBS_ORPHAN = 'home.admin.jobsOrphanExport';
// #endregion Jobs
// #region Data Management
export const ADMIN_DATA_MANAGEMENT_IMPORT = 'home.admin.dataManagementImport';
export const ADMIN_DATA_MANAGEMENT_DELETE_NEW =
	'home.admin.dataManagementDeleteNew';
export const ADMIN_DATA_MANAGEMENT_EIF_DELETE = 'home.autodelete';
// #endregion Data Management
// #region Connections
export const ADMIN_CONNECTION = 'home.admin.connection';
// #endregion Connections
// #region Settings
export const ADMIN_SETTINGS_MANAGE_USERS = 'home.admin.settingsManageUsers';
export const ADMIN_SETTINGS_MANAGE_TEAMS = 'home.admin.settingsManageTeams';
export const ADMIN_SETTINGS_SUBSCRIPTION = 'home.admin.settingsSubscription';
export const ADMIN_SETTINGS_SSO = 'home.admin.settingsSSO';
export const ADMIN_SETTINGS_CONFIGURE_CALENDAR =
	'home.admin.settingsConfigureCalendar';
// #endregion Settings

export const HAS_ADMIN_SUB_PAGE_NAVIGATION = [
	ADMIN_ATTRIBUTE_GROUP,
	ADMIN_ACTIVATE_ATTRIBUTES,
	ADMIN_ENABLE_ATTRIBUTES,
	ADMIN_EDIT_ATTRIBUTES,
	ADMIN_CONFIGURE_ATTRIBUTES,
	ADMIN_WEB_PERSONALIZATION,
	ADMIN_JOBS_DATA_PROCESSING,
	ADMIN_JOBS_MODEL,
	ADMIN_JOBS_EXPORT,
	ADMIN_JOBS_ORPHAN,
	ADMIN_DATA_MANAGEMENT_IMPORT,
	ADMIN_DATA_MANAGEMENT_DELETE_NEW,
	ADMIN_DATA_MANAGEMENT_EIF_DELETE,
	ADMIN_CONNECTION,
	ADMIN_SETTINGS_MANAGE_USERS,
	ADMIN_SETTINGS_MANAGE_TEAMS,
	ADMIN_SETTINGS_SUBSCRIPTION,
	ADMIN_SETTINGS_SSO,
	ADMIN_SETTINGS_CONFIGURE_CALENDAR,
];
// #endregion Admin

// #region Connectors
export const CONNECTORS = 'home.connectors';

export const CONNECTION_CONFIGURATION = 'home.configureconnector';
export const ADD_CONNECTION = 'home.addconnection';
export const PICK_CONNECTION = 'home.pickconnection';
export const ADD_SOURCE = 'home.addsource';
export const RELATIONSHIP_SETTING = 'home.relationshipsetting';
export const MANAGE_PRIORITIES = 'home.managepriorities';
export const EDIT_MAPPING = 'home.editMapping';
export const ONE_OFF_IMPORT = 'home.oneoffimport';

export const HAS_CONNECTORS_SUB_PAGE_NAVIGATION = [
	CONNECTION_CONFIGURATION,
	ADD_CONNECTION,
	PICK_CONNECTION,
	ADD_SOURCE,
	RELATIONSHIP_SETTING,
	EDIT_MAPPING,
	ONE_OFF_IMPORT,
];
// #endregion Connectors

export const REPORTING = 'home.report';

export const RULE_BASED = 'RULE_BASED';
export const CUSTOM_EVENT = 'CUSTOM_EVENT';
export const AI = 'AI';

export const NAVIGATION_STRUCTURE: Record<string, string[]> = {
	[MODLES_NEW]: [
		...HAS_MODELS_SUB_PAGE_NAVIGATION,
		...VIEWS_MODEL_SUB_PAGE_NAVIGATION,
		...MODELS_CHILD_ROUTES,
	],
	[PLAYLISTCHANNELS]: [
		...HAS_CAMPAIGN_SUB_PAGE_NAVIGATION,
		CAMPAIGN_LIST_GROUPS,
		CAMPAIGN_LIST_CHANNELS,
		CAMPAIGN_LIST_LAUNCH_HISTORY,
	],
	[WebInsightsRoutes[WebInsightsTabsType.Overview]]: [
		...Object.values(WebInsightsRoutes),
	],
	[SEGMENTS_NEW]: HAS_SEGMENTS_SUB_PAGE_NAVIGATION,
	[CONNECTORS]: HAS_CONNECTORS_SUB_PAGE_NAVIGATION,
	[ADMIN]: HAS_ADMIN_SUB_PAGE_NAVIGATION,
};

export const CAMPAIGN_CREATE = 'home.playbook.create.segment';
export const CAMPAIGN_CREATE_MODEL = 'home.playbook.create.segment.rating';
export const CAMPAIGN_CREATE_NAME = 'home.playbook.create.segment.rating.name';

export const CAMPAIGN_CHILDROUTES = [
	CAMPAIGN_CREATE,
	CAMPAIGN_CREATE_MODEL,
	CAMPAIGN_CREATE_NAME,
];

export const DELETE_CONFIGURATION = 'home.deleteconfiguration';

const noAdminConfigureAttribute = NoAdminRouteMap.get(
	ROUTE_PAGE.CONFIGURE_ATTRIBUTES
);

export const ADMIN_CHILDROUTES = [
	// #region Connections
	CONNECTION_CONFIGURATION,
	ADD_CONNECTION,
	// #endregion Connections
	// #region Data Management
	DELETE_CONFIGURATION,
	// #endregion Data Management
	// #region Configure Attribute
	`${noAdminConfigureAttribute}.spend_change`,
	`${noAdminConfigureAttribute}.spend_over_time`,
	`${noAdminConfigureAttribute}.share_of_wallet`,
	`${noAdminConfigureAttribute}.margin`,
	`${noAdminConfigureAttribute}.done`,
	// #endregion Configure Attribute
];

export const NAVIGATION_CHILDROUTES: Record<string, string[]> = {
	[PLAYLISTCHANNELS]: CAMPAIGN_CHILDROUTES,
	[SEGMENTS_NEW]: SEGMENTS_CHILDROUTES,
	[ATTRIBUTE_SCHEMA]: SCHEMA_CHILD_ROUTES,
	[ADMIN]: ADMIN_CHILDROUTES,
};

export interface NavItem {
	transitioning: () => boolean;
	active: () => boolean;
	disabled?: (() => boolean) | boolean;
	icon: string;
	if: boolean;
	label: string;
	sref: string;
	srefObj?: {
		params: Record<string, string>;
		state: string;
	};
	srefactive?: string;
}

export interface NavItems extends Array<NavItem> {}

export interface Play {
	targetSegment: {
		type: string;
	};

	[prop: string]: unknown;
}

export interface IIteration {
	id: string;
	modelingJobStatus: string;
	iteration: number;
	created: number;
	createdBy: string;
	modelSummaryId?: string;
}

export interface RatingsDashboard {
	iterations?: IIteration[];
	summary?: {
		publishedIterationId?: string;
		scoringIterationId?: string;
		isPublished?: boolean;
		bucketMetadata?: unknown;
	};

	[prop: string]: unknown;
}

export interface IScoringIteration {
	modelSummaryId?: string;
	id?: string;
}

export interface RatingsEngine {
	type?: string;
	published_iteration?: unknown;
	scoring_iteration?: {
		rule: IScoringIteration;
		AI: IScoringIteration;
	};
	latest_iteration?: {
		rule: IIteration;
		AI: IIteration;
	};

	[prop: string]: unknown;
}

export interface ILoginDocument {
	FirstName: string;
	LastName: string;
	MustChangePassword: boolean;
	PasswordLastModified: number;
	Tenants: ISessionTenant[];
	Timestamp: number;
	UserName: string;
	AuthenticationRoute?: string;
}
