import React from 'common/react-vendor';
import {ITextColumnItem, TextColumnItem} from './TextColumnItem';
import styles from './TextColumnItem.module.scss';

const TextColumnListItem = (
	textColumnList: ITextColumnItem[]
): React.ReactElement => {
	const [...list] = textColumnList;
	return (
		<div className={styles.TextColumnContainer}>
			{list.map(({value, valueType}: ITextColumnItem) => {
				return (
					<TextColumnItem key={value} value={value} valueType={valueType} />
				);
			})}
		</div>
	);
};

export {TextColumnListItem};
