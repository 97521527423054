import isEmpty from 'lodash/isEmpty';
import {store} from 'store';
import type {CompanyProfilesResponse, LookupState} from './types';
import {clearStore} from './actions';

const getLookupStore = (): LookupState => store.getState().lookup;

const getCompanyProfiles = (): CompanyProfilesResponse | null =>
	getLookupStore().companyProfiles;

const getCompanyProfilesBody = (): Record<string, unknown> =>
	getLookupStore().companyProfilesBody;

const getCompanyInfo = (): Record<string, string | boolean> | undefined =>
	getCompanyProfiles()?.companyInfo;

const isCompanyInfoEmpty = (): boolean =>
	isEmpty(getLookupStore().companyProfiles?.companyInfo);

const isCompanyInfoUnmatched = (): boolean => {
	const companyInfo = getCompanyInfo();

	if (!isCompanyInfoEmpty() && companyInfo) {
		return 'IsMatched' in companyInfo && !companyInfo.IsMatched;
	}

	return false;
};

const dispatchClearLookupStore = (): void => {
	store.dispatch(clearStore());
};

export {
	getCompanyInfo,
	getCompanyProfiles,
	getCompanyProfilesBody,
	getLookupStore,
	isCompanyInfoEmpty,
	isCompanyInfoUnmatched,
	dispatchClearLookupStore,
};
