import React from 'common/react-vendor';
import NgState from 'atlas/ng-state';
import {
	AddOutlinedIcon,
	DeleteOutlineOutlinedIcon,
	designTokens,
	DNBDialog,
	DNBTypography,
	EditOutlinedIcon,
	FileUploadOutlinedIcon,
	HistoryOutlinedIcon,
	SettingsOutlinedIcon,
	VpnKeyOutlinedIcon,
} from 'common/dnb-uux-vendor';
import {getAdminRoute, ROUTE_PAGE} from 'common/app/utilities/AdminPageUtility';
import {DialogButton} from 'common/composite-uux-components/dialog-drawer/DialogDrawer';
import {
	IContextMenuItem,
	IContextMenuItemParam,
	ISourceModal,
} from '../Component/Table/Component/ContextMenu';
import {fetch} from '../Util/FetchWrapper';
import {ApiType} from './API/ApiType';
import {ICommonStatusResponse} from './API/FE/CommonStatusResponse';
import {IImportSourceResponse} from './API/FE/ImportSourceResponse';
import {URLParams} from './URLParams';
import {AngularStateList} from './ConnectionConst';
import {ConnectionType} from './ConnectionType';
import {FieldType} from './FieldType';
import {ImportSubsystemType} from './ImportSubsystemType';
import {
	FileBasedSystemTypes,
	MultipleMarketingActivitySystems,
	SystemType,
} from './SystemType';
import {showS3Credentials} from '../Component/S3Credentials';
import {DefaultRBACMap} from '../RBAC/EIFRBAC';
import {DocumentType} from './DocumentType';

type ISourceContextMenuItem = IContextMenuItem<IImportSourceResponse>;
type ISourceContextMenuItemParam = IContextMenuItemParam<IImportSourceResponse>;

const SourceContextMenuConfig: ISourceContextMenuItem[] = [
	{
		icon: (
			<SettingsOutlinedIcon
				fontSize='small'
				sx={{
					color: designTokens.TokenColors.ColorGraySecondary,
				}}
			/>
		),
		text: 'Source Settings',
		filterHook: ({
			data: {IsEmptySource},
			RBACMap = DefaultRBACMap,
		}: ISourceContextMenuItemParam): boolean => {
			const {DRILL: enableAction} = RBACMap;
			return enableAction && !IsEmptySource;
		},
		onClick: ({SystemType, SourceId}: IImportSourceResponse): void => {
			NgState.getAngularState().go(AngularStateList.RelationShipSetting, {
				SystemType,
				SourceId,
			});
		},
	},
	{
		icon: (
			<EditOutlinedIcon
				fontSize='small'
				sx={{
					color: designTokens.TokenColors.ColorGraySecondary,
				}}
			/>
		),
		text: 'Edit Mapping',
		filterHook: ({
			RBACMap = DefaultRBACMap,
		}: ISourceContextMenuItemParam): boolean => {
			const {EDIT: enableAction} = RBACMap;
			return enableAction;
		},
		onClick: ({
			IsEmptySource,
			SourceId,
			SystemType,
			SystemId,
		}: IImportSourceResponse): void => {
			if (IsEmptySource) {
				NgState.getAngularState().go(AngularStateList.AddSource, {
					[FieldType.SourceId]: SourceId,
					[FieldType.SystemType]: SystemType,
					[FieldType.SystemId]: SystemId,
				});
			} else {
				NgState.getAngularState().go(AngularStateList.EditMapping, {
					[URLParams.SourceUniqId]: SourceId,
				});
			}
		},
	},
	{
		icon: (
			<AddOutlinedIcon
				fontSize='small'
				sx={{
					color: designTokens.TokenColors.ColorGraySecondary,
				}}
			/>
		),
		text: 'Add New Marketing Activities',
		filterHook: ({
			data: {CDPObject, SystemType},
			RBACMap = DefaultRBACMap,
		}: ISourceContextMenuItemParam): boolean => {
			const {EDIT: enableAction} = RBACMap;
			const isMultipleMarketingActivitySystem =
				MultipleMarketingActivitySystems.includes(SystemType);
			const isMarketingActivityCDPObject =
				CDPObject === DocumentType.MarketingActivity;
			return (
				enableAction &&
				isMultipleMarketingActivitySystem &&
				isMarketingActivityCDPObject
			);
		},
		onClick: ({
			SourceId,
			SystemType,
			SystemId,
		}: IImportSourceResponse): void => {
			NgState.getAngularState().go(AngularStateList.AddNewMarketingActivities, {
				[FieldType.SourceId]: SourceId,
				[FieldType.SystemType]: SystemType,
				[FieldType.SystemId]: SystemId,
			});
		},
	},
	{
		icon: (
			<FileUploadOutlinedIcon
				fontSize='small'
				sx={{
					color: designTokens.TokenColors.ColorGraySecondary,
				}}
			/>
		),
		text: 'Do a One-off Import',
		filterHook: ({
			data: {IsEmptySource, SystemType, IsEntityRelationConfirmed},
			RBACMap = DefaultRBACMap,
		}: ISourceContextMenuItemParam): boolean => {
			const {EDIT: enableAction} = RBACMap;
			return (
				enableAction &&
				FileBasedSystemTypes.includes(SystemType) &&
				!IsEmptySource &&
				IsEntityRelationConfirmed
			);
		},
		onClick: ({SystemType, SourceId}: IImportSourceResponse): void => {
			NgState.getAngularState().go(AngularStateList.OneOffImport, {
				SystemType,
				SourceId,
			});
		},
	},
	{
		icon: (
			<HistoryOutlinedIcon
				fontSize='small'
				sx={{
					color: designTokens.TokenColors.ColorGraySecondary,
				}}
			/>
		),
		text: 'View Import History',
		onClick: (): void => {
			NgState.getAngularState().go(getAdminRoute(ROUTE_PAGE.DATA_PROCESSING), {
				jobCreationSuccess: null,
			});
		},
	},
	{
		icon: (
			<VpnKeyOutlinedIcon
				fontSize='small'
				sx={{
					color: designTokens.TokenColors.ColorGraySecondary,
				}}
			/>
		),
		text: 'View Credentials',
		filterHook: ({
			data: {SystemType: systemType},
			RBACMap = DefaultRBACMap,
		}: ISourceContextMenuItemParam): boolean => {
			const {CREATE: enableAction} = RBACMap;
			return enableAction && systemType === SystemType.AWS_S3;
		},
		onClick: ({S3Location}: IImportSourceResponse): void => {
			showS3Credentials(S3Location);
		},
	},
	{
		icon: (
			<DeleteOutlineOutlinedIcon
				fontSize='small'
				sx={{
					color: designTokens.TokenColors.ColorGraySecondary,
				}}
			/>
		),
		text: 'Delete Source',
		filterHook: ({
			RBACMap = DefaultRBACMap,
		}: ISourceContextMenuItemParam): boolean => {
			const {CREATE: enableAction} = RBACMap;
			return enableAction;
		},
		Modal: ({
			data,
			isDeleteDialogShow,
			closeDeleteDialog,
		}: ISourceModal): React.ReactElement => {
			const {SourceId: id} = data as IImportSourceResponse;
			const delSource = (): void => {
				fetch({
					apiType: ApiType.DeleteSource,
					param: {id},
				})
					.then(({data}) => {
						const {Success} = data as ICommonStatusResponse;
						if (Success) {
							const {SystemId, ...Others} = NgState.getAngularState().params;
							NgState.getAngularState().go(
								SystemId
									? AngularStateList.ConnectionConfiguration
									: AngularStateList.ConnectionList,
								{
									connectionType: ConnectionType.Import,
									subSystemType: ImportSubsystemType.Source,
									...Others,
								},
								{reload: true}
							);
						} else {
							console.log('Source delete fail.');
						}
					})
					.catch((error) => {
						console.log(error);
					});
			};

			return (
				<DNBDialog
					variant='destructive'
					title='Delete Source'
					open={isDeleteDialogShow}
					onClose={closeDeleteDialog}
					primaryCTALabel={DialogButton.Delete}
					primaryCTA={() => delSource()}
					secondaryCTALabel={DialogButton.Cancel}
					secondaryCTA={closeDeleteDialog}>
					<DNBTypography>
						If you delete this source all data from this source will no longer
						load to the CDP. Do you still want to delete this source?
					</DNBTypography>
				</DNBDialog>
			);
		},
	},
];

export {SourceContextMenuConfig};
