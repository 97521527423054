import UserValues from './user-values.utility';
import LocalStorageUtility from './local-storage.utility';

/*
 * it might be necessary to force the client to wipe the
 * Application storage if things are not updating right
 * for them. if you increment this number that will occur
 */
const version = 6;

/*
	sets up a space and populates local storage with select Application data
	this allows app settings and other data to be sticky across sessions
*/
class ApplicationStorageUtility {
	constructor() {
		let storage = this.getAppStorage();

		if (!storage || storage.version != version) {
			storage = this.initAppStorage(version);
		}

		let space = this.getUserSpace(storage);

		if (!space) {
			space = this.initUserStorage(storage);
		}
	}

	initAppStorage(version) {
		const storage = {
			version,
			global: {},
		};

		LocalStorageUtility.setObject(this.getTenant(), storage);

		return storage;
	}

	initUserStorage(storage = this.getAppStorage()) {
		const user = this.getUser();

		if (user) {
			storage[user] = {};
		}

		return storage[user];
	}

	getAppStorage() {
		return LocalStorageUtility.getObject(this.getTenant());
	}

	setAppStorage(storage) {
		LocalStorageUtility.setObject(this.getTenant(), storage);
	}

	getUser() {
		const LoginDocument = LocalStorageUtility.getLoginDocument();
		const {UserName} = LoginDocument ? LoginDocument : {};
		return UserName || 'Unknown.User';
	}

	getTenant() {
		return UserValues.getTenantId() || 'Unknown.Tenant';
	}

	getUserSpace() {
		const user = this.getUser();
		let storage = this.getAppStorage();

		storage = this.validate(storage, user);

		return storage[user];
	}

	setUserSpace(space) {
		const user = this.getUser();
		let storage = this.getAppStorage();

		storage = this.validate(storage, user);

		storage[user] = space;

		this.setAppStorage(storage);
	}

	validate(storage, user) {
		if (!storage) {
			storage = this.initAppStorage();
		}

		if (!storage[user]) {
			this.initUserStorage(storage);
		}

		return storage;
	}

	set(path, value) {
		const space = this.getUserSpace();
		space[path] = value;
		this.setUserSpace(space);
	}

	get(path) {
		const space = this.getUserSpace();
		return path ? space[path] : space;
	}
}

const instance = new ApplicationStorageUtility();
Object.freeze(instance);

export default instance;
