import {DirectionData, DirectionMap} from './percentStore.types';
import {BucketCmp, Direction} from '../../../query.enums';
import {CompareData, CompareMap} from '../../../query.types';

const compareMap: CompareMap = new Map([
	[
		BucketCmp.AS_MUCH_AS,
		{
			name: BucketCmp.AS_MUCH_AS,
			displayName: 'By as Much as',
		},
	],
	[
		BucketCmp.AT_LEAST,
		{
			name: BucketCmp.AT_LEAST,
			displayName: 'By at Least',
		},
	],
	[
		BucketCmp.BETWEEN,
		{
			name: BucketCmp.BETWEEN,
			displayName: 'Between',
		},
	],
]);

const compareMapValues: CompareData[] = [...compareMap.values()];

const directionMap: DirectionMap = new Map([
	[
		Direction.DEC,
		{
			name: Direction.DEC,
			displayName: 'Decrease',
			readable: 'Decreased',
		},
	],
	[
		Direction.INC,
		{
			name: Direction.INC,
			displayName: 'Increase',
			readable: 'Increased',
		},
	],
]);

const directionMapValues: DirectionData[] = [...directionMap.values()];

export {compareMap, compareMapValues, directionMap, directionMapValues};
