import React from 'common/react-vendor';

import {DNBDialog} from 'common/dnb-uux-vendor';
import {Logout} from 'common/app/services/LoginService';
import localStorageUtility from 'common/widgets/utilities/local-storage.utility';

const TIME_COOKIE_TIMEOUT = 1000 * 60 * (23 * 60 + 54); // 23 hours 55 minutes for Backend set users cookie expired
const TIME_INTERVAL_INACTIVITY_BEFORE_WARNING = 1000 * 60 * 60 * 3; // 3 hours for sales users
const TIME_INTERVAL_WARNING_BEFORE_LOGOUT = 1000 * 30; // 30 seconds

const TIME_INTERVAL_SESSION_TIMEOUT =
	TIME_INTERVAL_INACTIVITY_BEFORE_WARNING + TIME_INTERVAL_WARNING_BEFORE_LOGOUT;

const getLastActivityInitialState = (): Date => {
	const lastActivityStored =
		localStorageUtility.getSessionLastActiveTimestamp();

	if (lastActivityStored && lastActivityStored !== 'null') {
		return new Date(lastActivityStored);
	}

	return new Date();
};

export default function SessionTimeout(): React.ReactElement {
	const [show, setShow] = React.useState<boolean>(false);
	const [lastActivity, setLastActivity] = React.useState<Date>(
		getLastActivityInitialState()
	);

	const isSessionExpired = React.useCallback((): boolean => {
		const curTime = new Date().getTime();
		const cookieExpiredTime = localStorageUtility.getCookieSetTime();
		if (cookieExpiredTime) {
			return cookieExpiredTime < curTime - TIME_COOKIE_TIMEOUT;
		}
		return lastActivity.getTime() < curTime - TIME_INTERVAL_SESSION_TIMEOUT;
	}, [lastActivity]);

	const refresh = React.useCallback((): void => {
		setLastActivity(new Date());
		localStorageUtility.setSessionLastActiveTimestamp(Date());
	}, []);

	const hasLoginDocument = React.useCallback((): boolean => {
		return !!localStorageUtility.getLoginDocument();
	}, []);

	React.useEffect(() => {
		if (!hasLoginDocument()) {
			Logout();
			return;
		}
		if (!show && !isSessionExpired()) {
			document.body.addEventListener('mousemove', refresh);
			document.body.addEventListener('keydown', refresh);
		}

		return () => {
			document.body.removeEventListener('mousemove', refresh);
			document.body.removeEventListener('keydown', refresh);
		};
	}, [hasLoginDocument, show, refresh, isSessionExpired]);

	React.useEffect(() => {
		if (isSessionExpired()) {
			Logout();
		}
		setShow(false);
		const warningTimeout = setTimeout(() => {
			setShow(true);
		}, TIME_INTERVAL_INACTIVITY_BEFORE_WARNING);
		const logoutTimeout = setTimeout(() => {
			Logout();
		}, TIME_INTERVAL_SESSION_TIMEOUT);
		return () => {
			clearTimeout(warningTimeout);
			clearTimeout(logoutTimeout);
		};
	}, [isSessionExpired, lastActivity]);

	return (
		<DNBDialog
			title='Session Inactivity Warning'
			open={show}
			onClose={refresh}
			primaryCTALabel='Stay Logged In'
			primaryCTA={refresh}
			tertiaryCTA={Logout}
			tertiaryCTALabel='Logout'>
			Your session is about to expire due to inactivity.
		</DNBDialog>
	);
}
