enum SelectStatus {
	All = 'All',
	None = 'None',
	Partial = 'Partial',
}

/**
 * Option interface.
 * @label Option label
 * @value Option value
 * @hide Flag indicates if option is hide
 * @checked Flag indicates if option is checked with user interaction
 * @count Option value counting.
 */
interface IOption {
	label: string;
	value: string;
	hide?: boolean;
	checked: boolean;
	count?: number;
}

const DefaultOption: IOption = {label: '', value: '', checked: false};

type IOptionGroup = Record<string, IOption>;

const getSelectStatus = (optionGroup: IOptionGroup): SelectStatus => {
	const selectCount = Object.keys(optionGroup).reduce((pre, key) => {
		return optionGroup[key]?.checked ? pre + 1 : pre;
	}, 0);
	if (selectCount === 0) {
		return SelectStatus.None;
	}
	if (selectCount === Object.keys(optionGroup).length) {
		return SelectStatus.All;
	}
	return SelectStatus.Partial;
};

export {SelectStatus, DefaultOption, getSelectStatus};
export type {IOption, IOptionGroup};
