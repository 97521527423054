import {
	designTokens,
	DNBTypography,
	DragIndicatorOutlinedIcon,
} from 'common/dnb-uux-vendor';
import classNames from 'classnames';
import React from 'common/react-vendor';
import {DefaultOption, IOption} from '../../../Data/FilterOptionType';
import {CommonCheckButton} from './CommonCheckButton';
import styles from './CommonMultipleSelectGroup.module.scss';

interface ICommonMultipleSelectGroup {
	optionList: Record<string, IOption>;
	onOptionChanged?: (val: Record<string, IOption>) => void;
	search?: string;
	isShowCount?: boolean;
	isDraggable?: boolean;
	onDragStart?: (e: React.DragEvent<HTMLLIElement>) => void;
	onDragEnd?: (e: React.DragEvent<HTMLLIElement>) => void;
	onDragOver?: (e: React.DragEvent<HTMLOListElement>) => void;
}

const CommonMultipleSelectGroup = ({
	optionList,
	onOptionChanged,
	search,
	isShowCount,
	isDraggable,
	onDragStart,
	onDragEnd,
	onDragOver,
}: ICommonMultipleSelectGroup): React.ReactElement => {
	const onCheckChange = (val: string): void => {
		if (!optionList[val]) {
			return;
		}
		const newOptionList = {...optionList};
		const {label, value, checked} = newOptionList[val] || DefaultOption;
		newOptionList[val] = {label, value, checked: !checked};
		onOptionChanged?.(newOptionList);
	};
	return (
		<ol className={styles.CommonMultipleSelectGroup} onDragOver={onDragOver}>
			{Object.values(optionList)
				.filter(({hide}) => !hide)
				.filter(
					({label}) =>
						!search || label.toLowerCase().includes(search.toLowerCase())
				)
				.map(({label, value, count, checked}) => {
					return (
						<li
							data-id={value}
							key={value}
							className={classNames({
								[styles.commonCheckboxGroup!]: true,
								[styles.isDraggable!]: isDraggable,
							})}
							draggable={isDraggable}
							onDragStart={isDraggable ? onDragStart : undefined}
							onDragEnd={isDraggable ? onDragEnd : undefined}>
							{isDraggable && (
								<span className={styles.draggableIcon}>
									<DragIndicatorOutlinedIcon
										fontSize='small'
										sx={{color: designTokens.TokenColors.ColorGraySecondary}}
									/>
								</span>
							)}
							<CommonCheckButton
								checked={checked}
								label={label}
								value={value}
								onCheckChange={onCheckChange}
							/>
							{isShowCount && (
								<DNBTypography
									variant='compact-body'
									sx={{
										color: designTokens.TokenColors.ColorGraySecondary,
										pr: 6,
									}}>
									{count || 0}
								</DNBTypography>
							)}
						</li>
					);
				})}
		</ol>
	);
};

export {CommonMultipleSelectGroup};
export type {ICommonMultipleSelectGroup};
