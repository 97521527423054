import {
	CommonHeaderMessage,
	IColumnConfig,
	IPersonalizationTypeConfig,
	PersonalizationType,
	WorkflowStatus,
} from './PersonalizationTypes';

const PersonalizationTypeConfig: IPersonalizationTypeConfig = {
	[PersonalizationType.Segment]: 'Segment',
	[PersonalizationType.SegmentType]: 'Segment Type',
	[PersonalizationType.Team]: 'Team',
	[PersonalizationType.Accounts]: 'Accounts',
	[PersonalizationType.Contacts]: 'Contacts',
	[PersonalizationType.LastUpdated]: 'Last Updated',
};

const SegmentColumnUIConfig: Array<IColumnConfig> = [
	{
		field: PersonalizationType.Segment,
		headerName: PersonalizationTypeConfig[PersonalizationType.Segment],
		width: 400,
	},
	{
		field: PersonalizationType.SegmentType,
		headerName: PersonalizationTypeConfig[PersonalizationType.SegmentType],
		width: 200,
	},
	{
		field: PersonalizationType.Team,
		headerName: PersonalizationTypeConfig[PersonalizationType.Team],
		width: 200,
	},
	{
		field: PersonalizationType.Accounts,
		headerName: PersonalizationTypeConfig[PersonalizationType.Accounts],
		width: 150,
	},
	{
		field: PersonalizationType.Contacts,
		headerName: PersonalizationTypeConfig[PersonalizationType.Contacts],
		width: 150,
	},
	{
		field: PersonalizationType.LastUpdated,
		headerName: PersonalizationTypeConfig[PersonalizationType.LastUpdated],
		width: 300,
	},
];

const defaultBaseTotal = 1000000;
const selectedLimitNumber = 10;
const selectLimitMessage =
	'You can select only up to 10 segments for web personalization.';
const accountLimitMessage =
	'You can select only up to 1,000,000 accounts(DUNS) for web personalization.';
const successMessage =
	'You have successfully personalized the visitor intelligence with your selected segments and attributes.';
const failedMessage =
	'An error has occurred while we were processing your selected segments and attributes. Please contact D&B Support for assistance.';
const pendingMessage =
	'We are currently processing your selected attributes and it may take up to 20 minutes.';

const CommonHeaderMessageConfig = {
	[CommonHeaderMessage.Steps]: {
		title: 'Configure Web Personalization Data Feed',
		description: 'Select from first party segments and attribute groups',
	},
	[CommonHeaderMessage.Segment]: {
		title: 'What segments would you like to target?',
		description:
			'Select the segment(s) you would like to target through visitor intelligence with web personalization. Note: only segments from My Data will be available to select.',
	},
	[CommonHeaderMessage.AttributeGroup]: {
		title:
			'Do you want to further filter your selected segments by an attribute group?',
		description:
			'Only those attribute groups with no more than 20 attributes can be selected here. ',
	},
};

const defaultAttributeGroupOption = [
	{
		label: 'Select an attribute group',
		value: '',
	},
];

const SaveActivateDialogConfig = {
	SkipAttributeGroups:
		'Are you sure you want to save and activate the selected segments for web personalization?',
	SelectedAttributeGroups:
		'Are you sure you want to save and activate the selected attributes for web personalization?',
};

const SuccessArray = [WorkflowStatus.COMPLETED];
const PendingArray = [
	WorkflowStatus.PENDING,
	WorkflowStatus.RUNNING,
	WorkflowStatus.ENQUEUED,
	WorkflowStatus.READY,
];
const FailedArray = [
	WorkflowStatus.FAILED,
	WorkflowStatus.CANCELLED,
	WorkflowStatus.SKIPPED,
	WorkflowStatus.KILLED,
	WorkflowStatus.PENDING_RETRY,
	WorkflowStatus.RETRIED,
	WorkflowStatus.PENDING_SUPPORT_REVIEW,
];
export {
	SegmentColumnUIConfig,
	defaultBaseTotal,
	selectedLimitNumber,
	selectLimitMessage,
	accountLimitMessage,
	CommonHeaderMessageConfig,
	successMessage,
	defaultAttributeGroupOption,
	SaveActivateDialogConfig,
	failedMessage,
	pendingMessage,
	SuccessArray,
	PendingArray,
	FailedArray,
};
