import {ISegment} from 'atlas/data/SegmentConst';
import {SVGProps} from 'common/react-vendor';
import {LogicalRestriction} from '../../../common/components/datacloud/query/query.types';
import {IEditDialog} from './Components/NameDescriptionHeader';
import MyDataIcon from '../../assets/svgrs/segmentation/Create/MyDataIcon';
import DNBSegmentIcon from '../../assets/svgrs/segmentation/Create/DNBSegmentIcon';
import MyAccountIcon from '../../assets/svgrs/segmentation/Create/MyAccountIcon';
import MyContactIcon from '../../assets/svgrs/segmentation/Create/MyContactIcon';

export enum SystemTypes {
	Contact = 'Contact',
	Account = 'Account',
}

export enum ListSegmentSystemTypes {
	CompanyList = 'CompanyList',
	ContactList = 'ContactList',
}

export const SegmentStatus = {
	ACTIVE: 'Active',
	PENDING: 'Pending',
	DRAFT: 'Draft',
	ARCHIVED: 'Archived',
	FAILED: 'Failed',
	IMPORTING: 'Importing',
	DUPLICATING: 'Duplicating',
};

const MyAttributesRoute = 'home.segment.explorer.attributes';
const QueryBuilderRoute = 'home.segment.explorer.builder';
const addButtonLabel = 'Create Segment';

interface IAddButtonParams {
	segment: string;
	subSegment: string;
	isTAM: boolean;
	ssviCardName?: string;
	newAccountRestriction: LogicalRestriction | null;
}
const addButtonParams: IAddButtonParams = {
	segment: 'Create',
	subSegment: '',
	isTAM: false,
	ssviCardName: '',
	newAccountRestriction: null,
};
enum SegmentCreateType {
	Query = 'Query',
	DNB = 'D&B',
	AccountList = 'Account List',
	ContactList = 'Contact List',
}

interface ISegmentCreateDialog {
	isDialogOpen: boolean;
	setIsDialogOpen: (open: boolean) => void;
}

type ISegmentCreateConfig = {
	type: SegmentCreateType;
	title: string;
	Icon: (props: SVGProps<SVGSVGElement>) => React.ReactElement;
	description: string;
	helperText: string;
	open?: (v2: boolean) => void;
};

const SegmentCreateConfig: ISegmentCreateConfig[] = [
	{
		type: SegmentCreateType.Query,
		title: 'My Data Segment',
		Icon: MyDataIcon,
		description: 'Create a list from the records available in the CDP.',
		helperText:
			'Create a list of accounts or contacts from the records available in the CDP.',
	},
	{
		type: SegmentCreateType.DNB,
		title: 'D&B Data Segment',
		Icon: DNBSegmentIcon,
		description: 'Purchase and add data from the Explore Module.',
		helperText:
			'Purchase accounts and contacts from the Explore Module and add them to the CDP. Accounts and Contacts acquired are matched to the D&B Data Cloud and will be available for activation.',
	},
	{
		type: SegmentCreateType.AccountList,
		title: 'My Account List',
		Icon: MyAccountIcon,
		description: 'Upload a CSV file of accounts to the CDP.',
		helperText:
			'Upload a CSV file of accounts to the CDP. These accounts are matched to the D&B Data Cloud and will be available for activation.',
	},
	{
		type: SegmentCreateType.ContactList,
		title: 'My Contact List',
		Icon: MyContactIcon,
		description: 'Upload a CSV file of contacts to the CDP.',
		helperText:
			'Upload a CSV file of contacts to the CDP. These contacts are matched to the D&B Data Cloud and will be available for activation.',
	},
];

type ISegmentEditDialog = IEditDialog & ISegment;

export type {
	ISegmentCreateDialog,
	IAddButtonParams,
	ISegmentEditDialog,
	ISegmentCreateConfig,
};

export {
	addButtonLabel,
	MyAttributesRoute,
	QueryBuilderRoute,
	addButtonParams,
	SegmentCreateConfig,
	SegmentCreateType,
};
