import React from 'common/react-vendor';
import classNames from 'classnames';
import styles from './le-banner-simple.module.scss';

export interface LeBannerSimpleProps {
	content: string;
	type: 'warning' | 'error' | 'success' | 'info' | '';
	showCloseIcon?: boolean;
	isStatic?: boolean;
	onClick?: () => void;
}

export interface BannerConfig {
	content: string;
	type: LeBannerSimpleProps['type'];
	hidden: boolean;
}

export function LeBannerSimple({
	content,
	type,
	showCloseIcon,
	isStatic = true,
	onClick,
}: LeBannerSimpleProps): JSX.Element {
	return (
		<div
			onClick={onClick}
			onAnimationEnd={!isStatic ? onClick : undefined}
			className={classNames(styles.reactBannerContainer, 'no-margin', 'mb-1', {
				[styles.timeout!]: !isStatic,
			})}>
			<div
				className={classNames(styles.leBanner, `${styles[type]}`, 'no-margin')}>
				<div className={styles.leBannerIconContainer}>
					<i className={styles.leBannerIcon} />
				</div>
				<div className={classNames(styles.leBannerContent, 'pr-1')}>
					<div className={styles.leBannerMessage}>{content}</div>
				</div>
				{showCloseIcon && (
					<div className={styles.leBannerIconContainer}>
						<i className={styles.leCloseIcon} />
					</div>
				)}
			</div>
		</div>
	);
}
