import '../common/assets/sass/lattice.scss';
import './app/app.component.scss';
import './templates-main';
import 'angular-ui-bootstrap';
import 'app/utilities/utilities.index';
import 'components/components.index';
import 'app/directives/ngEnterDirective';
import './app/login/login.routes';
import './app/login/login.component';
import './app/login/login.service';
import './app/login/form/LoginForm.component';
import './app/login/forgot/Forgot.component';
import './app/login/tenants/tenants.component';
import './app/login/saml/Saml.component';
import './app/login/saml/logout/Logout.component';
import './app/login/saml/error/Error.component';
import './app/login/passwordless/passwordless.component.ng';
import './app/login/IdaasMigration/IdaasResetPasswordMessage.component.ng';
import './app/login/IdaasMigration/IdaasForgotPasswordMessage.component.ng';
import './app/login/IdaasMigration/IdaasLoginWithDnbCredentialsMessage.component.ng';
import 'components/banner/banner.component';
import 'components/modal/modal.component';
import 'components/notice/notice.component';
import 'components/exceptions/exceptions.component';
import 'components/pagination/pagination.component';
import './app/app.component';
import './app/app.routes';

angular.module('com.le.login', ['templates-main', 'loginApp']);
