import React from 'common/react-vendor';
import {
	FreeStyleDialogContent,
	FreeStyleStickyFooter,
	getFreeStyleDialog,
} from 'common/composite-uux-components/dialog-drawer/DialogDrawer';
import {DialogComponent} from 'atlas/segmentation/Components/Dialog/DialogComponent';
import {Box, DNBButton, DNBTypography} from 'common/dnb-uux-vendor';
import {NewlyAddedId} from 'atlas/JourneyStage/Data/JourneyStageData';
import {ICell} from 'atlas/JourneyStage/JourneyStageConfiguration/Component/EditableCell';
import {INameDescriptionData} from 'atlas/segmentation/Components/NameDescriptionHeader';
import {IGenericAction} from './DialogType';
import './JourneyStageEditDialog.scss';

const StyledJourneyStageEditDialog = getFreeStyleDialog({
	width: '576px',
	height: '388px',
});

type IJourneyStageEditDialog = {
	journeyStageData: INameDescriptionData;
} & ICell<boolean> &
	IGenericAction<INameDescriptionData>;

const JourneyStageEditDialog = ({
	journeyStageData,
	data: open,
	setData: setOpen,
	onOkay,
	onCancel,
}: IJourneyStageEditDialog): React.ReactElement => {
	const {
		id = NewlyAddedId,
		name: defaultName,
		description: defaultDescription,
	} = journeyStageData;
	const [name, setName] = React.useState(defaultName);
	const [description, setDescription] = React.useState(defaultDescription);
	const isEditMode = id !== NewlyAddedId;
	return (
		<StyledJourneyStageEditDialog
			id='JourneyStageEditDialog'
			open={open}
			onClose={() => setOpen(false)}>
			<Box sx={{padding: '24px 24px 0'}}>
				<DNBTypography variant='h6'>
					{isEditMode ? 'Edit Buyer Journey Stage' : 'Add New Journey Stage'}
				</DNBTypography>
			</Box>
			<FreeStyleDialogContent sx={{gap: 4}}>
				<div>
					<DialogComponent.Section
						title='Journey Stage Name'
						cell={{data: name, setData: setName}}
						maxLength={255}
					/>
				</div>
				<div id='JourneyStageEditDialogDescription'>
					<DialogComponent.Section
						title='Description'
						cell={{
							data: description,
							setData: setDescription,
						}}
						maxLength={1000}
						multiline
					/>
				</div>
			</FreeStyleDialogContent>
			<FreeStyleStickyFooter>
				<DNBButton
					variant='secondary'
					onClick={() => {
						onCancel?.();
						setOpen(false);
					}}>
					Cancel
				</DNBButton>
				<DNBButton
					disabled={!name}
					onClick={() => {
						onOkay?.({id, name, description} as INameDescriptionData);
						setOpen(false);
					}}>
					Save
				</DNBButton>
			</FreeStyleStickyFooter>
		</StyledJourneyStageEditDialog>
	);
};

export {JourneyStageEditDialog};
