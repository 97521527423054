import {SourceMappingResponse} from '../../AddSource/api/apiCalls';
import {IApiDataType} from './ApiType';
import {
	IActivationConnection,
	IActivationConnectionList,
	toActivationConnectionListResponse,
	toActivationConnectionResponse,
} from './BE/ActivationConnection';
import {
	ICreateOrUpdateConnection,
	toImportConnectionListResponse,
	toImportConnectionResponse,
} from './BE/CreateOrUpdateConnection';
import {toFieldsByCurrentMap} from './BE/GetCurrentMap';
import {
	IGetSourceByIdResponse,
	toImportSourceResponseById,
} from './BE/GetSourceById';
import {
	IGetSourcePerConnection,
	toImportSourceResponse,
} from './BE/GetSourcePerConnection';
import {IActivationConnectionResponse} from './FE/ActivationResponse';
import {IImportConnectionResponse} from './FE/ImportResponse';
import {IImportSourceResponse} from './FE/ImportSourceResponse';

function getFEResponse<
	LHS extends IApiDataType | string,
	RHS extends IApiDataType
>(data: IApiDataType, toFEResponse: (data: LHS) => RHS): RHS {
	return toFEResponse(data as LHS);
}

const getImportConnectionResponse = (
	data: IApiDataType
): IImportConnectionResponse => {
	return getFEResponse<ICreateOrUpdateConnection, IImportConnectionResponse>(
		data,
		toImportConnectionResponse
	);
};

const getImportConnectionListResponse = (
	data: IApiDataType
): IImportConnectionResponse[] => {
	return getFEResponse<
		ICreateOrUpdateConnection[],
		IImportConnectionResponse[]
	>(data, toImportConnectionListResponse);
};

const getActivationConnectionResponse = (
	data: IApiDataType
): IActivationConnectionResponse => {
	return getFEResponse<IActivationConnection, IActivationConnectionResponse>(
		data,
		toActivationConnectionResponse
	);
};

const getActivationConnectionListResponse = (
	data: IApiDataType
): IActivationConnectionResponse[] => {
	return getFEResponse<
		IActivationConnectionList,
		IActivationConnectionResponse[]
	>(data, toActivationConnectionListResponse);
};

const getImportSourceResponse = (
	data: IApiDataType
): IImportSourceResponse[] => {
	return getFEResponse<IGetSourcePerConnection, IImportSourceResponse[]>(
		data,
		toImportSourceResponse
	);
};

const getImportSourceByIdResponse = (
	data: IApiDataType
): IImportSourceResponse => {
	return getFEResponse<IGetSourceByIdResponse, IImportSourceResponse>(
		data,
		toImportSourceResponseById
	);
};

const getFieldsFromCurrentMapResponse = (data: IApiDataType): string[] => {
	return getFEResponse<SourceMappingResponse, string[]>(
		data,
		toFieldsByCurrentMap
	);
};

export {
	getFEResponse,
	getImportSourceByIdResponse,
	getImportConnectionResponse,
	getImportConnectionListResponse,
	getImportSourceResponse,
	getActivationConnectionResponse,
	getActivationConnectionListResponse,
	getFieldsFromCurrentMapResponse,
};
