type StyleObject = Record<string, string>;

interface ModalCallbackArgs {
	name?: string;
	action?: 'ok' | 'cancel';
	data?: unknown;
}

type ModalCallback = (args: ModalCallbackArgs) => void;

interface ModalOptions {
	name: string;
	title: string;
	confirmcolor: string;
	confirmstyle: StyleObject;
	type: 'sm';
	dischargetext: string;
	confirmtext: string;
	icon: string;
	iconstyle: StyleObject;
	headerconfig: StyleObject;
	showclose: boolean;
	showcancel: boolean;
	callback: ModalCallback;
	message: string;
}

const CONFIG_TEMPLATE: Partial<ModalOptions> = {
	type: 'sm',
	dischargetext: 'Cancel',
	confirmtext: 'Confirm',
	icon: 'fa fa-exclamation-triangle',
	iconstyle: {},
	confirmstyle: {},
	showclose: true,
};

const MODAL_CONFIG: Record<string, Partial<ModalOptions>> = {
	warning: {
		...CONFIG_TEMPLATE,
		name: 'generic_warning',
		title: 'Application Warning',
		confirmcolor: 'yellow-button',
		headerconfig: {'background-color': '#ffbd48', 'color': 'white'},
		showcancel: true,
	},
	success: {
		...CONFIG_TEMPLATE,
		name: 'generic_success',
		title: 'Success',
		icon: 'fa fa-info-circle',
		confirmcolor: 'green-button',
		headerconfig: {'background-color': '#6fbe4a', 'color': 'white'},
		showcancel: false,
	},
	error: {
		...CONFIG_TEMPLATE,
		name: 'generic_error',
		title: 'Error',
		confirmcolor: 'white-button',
		headerconfig: {'background-color': '#D0242F', 'color': 'white'},
		showcancel: false,
	},
	info: {
		...CONFIG_TEMPLATE,
		name: 'generic_info',
		title: 'Info',
		icon: 'fa fa-info-circle',
		confirmcolor: 'blue-button',
		headerconfig: {'background-color': '#629acc', 'color': 'white'},
		showcancel: false,
	},
	generic: {
		...CONFIG_TEMPLATE,
		name: 'generic_modal',
		title: '',
		icon: '',
		confirmcolor: 'blue-button',
		headerconfig: {'background-color': 'white', 'color': 'black'},
		showcancel: true,
	},
};

const objToStyle = (style?: StyleObject): string => {
	if (!style) return '';
	return Object.entries(style)
		.map(([k, v]) => `${k}:${v}`)
		.join(';');
};

const generate = (config: Partial<ModalOptions>): void => {
	const configName = config.name || `modal_${Date.now()}`;
	const cancel = (e: Event): void => {
		document.getElementById(configName)?.remove();
		if (config.callback) config.callback({action: 'cancel', data: e});
	};
	const ok = (): void => {
		document.getElementById(configName)?.remove();
		if (config.callback) config.callback({action: 'ok'});
	};
	const modal = document.createElement('div');
	modal.id = 'leModalContainer';
	modal.innerHTML = `
	<div
	class="le_modal opened"
	id="${configName}"
>
	<div class="le-modal-content ${config.type}">
		<div class="le-modal-header" style="${objToStyle(config.headerconfig)}">
			${config.showclose ? `<span class="close cancelAction">&times;</span>` : ''}
			<div class="le-title-container">
				<span class="ico-container" style="${objToStyle(config.headerconfig)}">
					<i
						class="title-icon ${config.icon}"
						aria-hidden="true"
						style="${objToStyle(config.iconstyle)}"
					></i>
				</span>
				<p class="le-title" title="${config.title}">
					${config.title}
				</p>
			</div>
		</div>

		${
			config.message
				? `<div class="le-modal-body">
			${config.message}
		</div>`
				: ''
		}

		<div class="le-modal-footer">
			<button
				class="button ${config.confirmcolor} okAction"
				style="${objToStyle(config.confirmstyle)}"
			>
				${config.confirmtext}
			</button>
			${
				config.showcancel
					? `<button
				class="button gray-button cancelAction"
			>
				${config.dischargetext}
			</button>`
					: ''
			}
		</div>
	</div>
</div>
	`;
	document.body.appendChild(modal);
	document
		.querySelectorAll(`#${config.name} .cancelAction`)
		.forEach((el) => el.addEventListener('click', cancel));
	document
		.querySelectorAll(`#${config.name} .okAction`)
		.forEach((el) => el.addEventListener('click', ok));
};

const success = (
	opts: Partial<ModalOptions>,
	callback?: ModalCallback
): void => {
	generate({...MODAL_CONFIG.success, ...opts, callback});
};

const warning = (
	opts: Partial<ModalOptions>,
	callback?: ModalCallback
): void => {
	generate({...MODAL_CONFIG.warning, ...opts, callback});
};

const error = (opts: Partial<ModalOptions>, callback?: ModalCallback): void => {
	generate({...MODAL_CONFIG.error, ...opts, callback});
};

const info = (opts: Partial<ModalOptions>, callback?: ModalCallback): void => {
	generate({...MODAL_CONFIG.info, ...opts, callback});
};

const generic = (
	opts: Partial<ModalOptions>,
	callback?: ModalCallback
): void => {
	generate({...MODAL_CONFIG.generic, ...opts, callback});
};

/**
 * @deprecated This service was created to replace the old Modal service where it was deeply embedded in the business logic.
 * These methods should not be used moving forward.
 * Use the UUX DNBDialog if possible, otherwise the dnb-dialog angular component is ok.
 */
export default {
	success,
	warning,
	error,
	info,
	generic,
};
