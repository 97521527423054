import {react2angular} from 'common/react-vendor';
import {
	AdminRouteMap,
	NoAdminRouteMap,
	ROUTE_PAGE,
	setMultipleStates,
} from 'common/app/utilities/AdminPageUtility';
import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {isCDPPersonalizationEnabled} from 'common/stores/tenantConfig';
import PersonalizationPage from './components/PersonalizationComponent';

angular
	.module('common.attributes.personalization', [])
	.config(function ($stateProvider: {
		state: (namespace: string, config: unknown) => void;
		states: (namespace: string[], config: Record<string, unknown>) => void;
	}) {
		const stateProvider = {
			...$stateProvider,
			states: setMultipleStates,
		};

		const WebPersonalizationNames = [
			AdminRouteMap.get(ROUTE_PAGE.WEB_PERSONALIZATION) || '',
			NoAdminRouteMap.get(ROUTE_PAGE.WEB_PERSONALIZATION) || '',
		];

		stateProvider.states(WebPersonalizationNames, {
			url: '/personalization',
			onEnter: [
				'$state',
				($state: {get: (urlDefine: string) => void}) => {
					const CDPPersonalizationEnabled = isCDPPersonalizationEnabled();
					const hasPersonalizationAccess = RBAC.hasAccess(
						RBACInterface.PERSONALIZATION,
						RBACActions.VIEW
					);
					if (!(CDPPersonalizationEnabled && hasPersonalizationAccess)) {
						$state.get('home.attributes');
					}
				},
			],
			views: {
				'main@': 'attrPersonalization',
				'adminSidebar@': {component: 'adminSidebar'},
			},
		});
	})
	.component(
		'attrPersonalization',
		react2angular(PersonalizationPage, [], ['$state'])
	);
