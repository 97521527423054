/**
 * TODO: Delete this angular file once all components that uses dateAttribute gets refactor.
 * TODO: DELETE this file once there are no more instances of <date-attribute />.
 */

import {react2angular} from 'common/react-vendor';
import {DateAttributeWithAngularProps} from './DateAttribute/DateAttribute';

angular
	.module('common.datacloud.query.builder.tree.edit.date.attribute')
	.component(
		'dateAttribute',
		react2angular(DateAttributeWithAngularProps, ['bucketrestriction', 'type'])
	);
