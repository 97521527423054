import {useApi, UseApiConfig} from 'common/app/hooks/useApi';
import type {UseApiReturn} from 'common/app/hooks/useApi';
import {
	createOrUpdateSegment,
	CreateOrUpdateSegmentResponse,
} from 'common/components/datacloud/segment/segment.queries';
import type {Query} from 'common/components/datacloud/datacloud.types';

type UseCreateOrUpdateSegmentParams = {
	segment: Query;
	isClone?: boolean;
} & UseApiConfig<CreateOrUpdateSegmentResponse>;

export const useCreateOrUpdateSegment = ({
	segment,
	isClone,
	...apiConfig
}: UseCreateOrUpdateSegmentParams): UseApiReturn<CreateOrUpdateSegmentResponse> =>
	useApi<CreateOrUpdateSegmentResponse>(
		'createOrUpdateSegment',
		() => createOrUpdateSegment(segment, isClone),
		apiConfig
	);
