import ReactDOM, {createPortal} from 'react-dom';
import {createRoot} from 'react-dom/client';
import ReactModal from 'react-modal';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import $ from 'jquery';
import angular from 'angular';
import React, {Suspense, SVGProps} from 'react';
import ReactTooltip from 'react-tooltip';
import Papaparse from 'papaparse';
import {DNBThemeProvider} from '@dnb-uux-design-system/react';
import {ThemeProvider} from '@mui/material/styles';

// #region yup
/**
 * Yup always relies on the Promise global object
 * to handle asynchronous values as well as Set and Map.
 * For browsers that do not support these,
 * you'll need to include a polyfill, such as core-js:
 */
import 'core-js/es/promise';
import 'core-js/es/set';
import 'core-js/es/map';
// #endregion yup

import {react2angularWithWrapper} from './react2angularWithWrapper';
import {revUpTheme} from './theme';

export default React;
export const {Component} = React;
export * from '@uirouter/react';
export type {StateService as NgStateService} from '@uirouter/angularjs';
export {
	ReactDOM,
	createRoot,
	PropTypes,
	$,
	ReactTooltip,
	ReactModal,
	ReactSelect,
	Papaparse,
	createPortal,
};

export type {SVGProps};

export const DnbThemeProviderWrapper = ({
	children,
}: React.PropsWithChildren): React.ReactElement => (
	<Suspense fallback={<div className='loader' />}>
		<ThemeProvider theme={revUpTheme}>
			<DNBThemeProvider>{children}</DNBThemeProvider>
		</ThemeProvider>
	</Suspense>
);

export const react2angular = <Props,>(
	Class: React.ComponentType<React.PropsWithChildren<Props>>,
	bindingNames?: (keyof Props)[] | null,
	injectNames?: string[]
): angular.IComponentOptions =>
	react2angularWithWrapper(
		Class,
		bindingNames,
		injectNames,
		DnbThemeProviderWrapper
	);
