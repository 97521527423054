import {ICommonMultipleSelectGroup} from '../AddConnection/Component/Common/CommonMultipleSelectGroup';
import {IConnectionSortBy} from '../Component/ConnectionSortBy';
import {ConnectionType, ObjectArray, ObjectType} from './ConnectionType';
import {ImportSubsystemType} from './ImportSubsystemType';

enum DisplayMode {
	Tile = 'Tile',
	Table = 'Table',
}

/**
 * DisplayModeComponent interface
 * @param dataList: data list
 * @param filterHook: data filter hook
 * @param sortHook: data sort hook
 * @param sortBy: Sort by interface
 * @param columnOption: Column option filter
 * @param filterEnabled: Flag indicates if filter is enabled or not
 * @param reorderColumns: The reordered column name list
 */
interface IDisplayModeComponent {
	dataList: ObjectArray;
	filterHook: (data: ObjectType) => boolean;
	sortHook: (lhs: ObjectType, rhs: ObjectType) => number;
	sortBy: IConnectionSortBy;
	columnOption: ICommonMultipleSelectGroup;
	filterEnabled: boolean;
	onColumnOrderChange?: (reorderList: string[]) => void;
	reorderColumns: string[];
}

type IDisplayModeComponentConfig = {
	[key in DisplayMode]?: (props: IDisplayModeComponent) => React.ReactElement;
};

interface IDisplayModeConfig {
	showDisplayMode: boolean;
	displayMode: DisplayMode;
	displayModeConfig: IDisplayModeComponentConfig;
}

type IDisplayModeSubSystemConfig = {
	[key in ImportSubsystemType]: IDisplayModeConfig;
};

type IDisplayModeConnectionConfig = {
	[key in ConnectionType]: IDisplayModeSubSystemConfig;
};

export {DisplayMode};
export type {
	IDisplayModeConfig,
	IDisplayModeComponent,
	IDisplayModeSubSystemConfig,
	IDisplayModeConnectionConfig,
	IDisplayModeComponentConfig,
};
