import React from 'common/react-vendor';
import isDeepEqual from 'fast-deep-equal/react';
import {APIFetcherConfig, isValidResponse} from '../Data/API/ApiConst';
import {IApiDataType} from '../Data/API/ApiType';
import {fetch, IFetch, isFetchPropValid} from '../Util/FetchWrapper';

/**
 * @interface IUseGroupFetch
 *
 * @field data is generic T extends IApiDataType[]
 * data undefined if any fetch get error.
 * data T if all fetches are complete successfully.
 * @field isLoading indicates if group fetch is processing.
 * @field isError indicates if error happens during fetch.
 */
interface IUseGroupFetch<T extends IApiDataType[]> {
	data?: T;
	isLoading: boolean;
	isError: boolean;
}

/**
 * useGroupFetch to make a group of fetch
 *
 * @param props IFetch[] fetch configuration list
 * @returns IUseGroupFetch the result of the group fetch.
 */
const useGroupFetch = <T extends IApiDataType[]>(
	props: IFetch[]
): IUseGroupFetch<T> => {
	const [data, setData] = React.useState<T>();
	const [isLoading, setLoading] = React.useState(true);
	const [isError, setError] = React.useState(false);
	const propRef = React.useRef(props);
	if (!isDeepEqual(propRef.current, props)) {
		propRef.current = props;
	}
	React.useEffect(() => {
		const fetchData = async (): Promise<void> => {
			setLoading(true);
			const responseList = await Promise.all(props.map((prop) => fetch(prop)));
			const error = responseList.some((response) => !isValidResponse(response));
			if (!error) {
				const newDataList = [];
				for (let i = 0; i < responseList.length; ++i) {
					const response = responseList[i];
					const {apiType} = props[i]!;
					let {data} = response!;
					const {convertHook} = (apiType && APIFetcherConfig[apiType]) || {};
					data = convertHook ? convertHook(data) : data;
					newDataList.push(data);
				}
				setData(newDataList as T);
			}
			setError(error);
			setLoading(false);
		};
		if (props.every((prop) => isFetchPropValid(prop))) {
			fetchData();
		} else {
			setLoading(false);
		}
		return () => {
			setLoading(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [propRef.current]);
	return {
		data,
		isLoading,
		isError,
	};
};

/**
 * @interface IUseFetch
 *
 * @field data T extends IApiDataType
 * @field isLoading indicates if fetch is processing.
 */
interface IUseFetch<T extends IApiDataType> {
	data: T;
	isLoading: boolean;
}

/**
 * useFetch to make a single fetch.
 *
 * @param props IFetch the fetch configuration.
 * @returns IUseFetch the result of fetch.
 */
const useFetch = <T extends IApiDataType>(props: IFetch): IUseFetch<T> => {
	const {data, isLoading} = useGroupFetch([props]);
	return {
		data: data?.[0] as T,
		isLoading,
	};
};

export {useFetch, useGroupFetch};
export type {IUseGroupFetch};
