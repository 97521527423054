const NULL_BUCKET_NAME = 'Not Populated';

const TOP_CATEGORIES = 8;

const NULL_BUCKET_NAMES = ['NULL', 'NONE', 'NOT AVAILABLE'];

const NOT_AVAILABLE_NAMES = ['NULL', 'NOT AVAILABLE'];

const RANGE_BUCKET_NAMES = ['Employee Range', 'Revenue Range'];

enum FUNDAMENTAL_TYPE {
	ALPHA = 'alpha',
	BOOLEAN = 'boolean',
	CURRENCY = 'currency',
	DATE = 'date',
	EMAIL = 'email',
	ENUM = 'enum',
	NUMERIC = 'numeric',
	PERCENTAGE = 'percentage',
	PHONE = 'phone',
	URI = 'uri',
	YEAR = 'year',
}

export {
	NULL_BUCKET_NAME,
	TOP_CATEGORIES,
	NULL_BUCKET_NAMES,
	RANGE_BUCKET_NAMES,
	FUNDAMENTAL_TYPE,
	NOT_AVAILABLE_NAMES,
};
