import {ISegment} from 'atlas/data/SegmentConst';
import {useSelector} from 'common/redux-vendor';
import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACInterface,
	RBACActions,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {
	Box,
	DNBTab,
	DNBTabs,
	DNBTag,
	DNBTypography,
} from 'common/dnb-uux-vendor';
import React, {NgStateService} from 'common/react-vendor';
import {
	getQueryProperty,
	setJourneyEntitiesProperty,
} from 'common/stores/query';
import {isSegmentationV2Enabled} from 'common/stores/tenantConfig';
import {QueryState} from 'common/stores/query/types';
import {getSessionSegmentState} from '../../query/results/rebuild/segment.helpers';
import {getAttributeCount, getQuerybuilderCount} from './QueryHelper';
import {ITabCount} from './SegmentHelper';
import {
	IBaseSegmentHelper,
	ISegmentTabParam,
	ITabType,
} from './SegmentTabsHelper';
import {useSegmentTabHelper} from './useSegmentTabHelper';

enum TabType {
	Attributes = 'attributes',
	Dashboard = 'Dashboard',
	CustomSubsegments = 'Custom Subsegments',
	QueryBuilder = 'Query Builder',
	Accounts = 'Accounts',
	Contacts = 'Contacts',
}

type ITab = {
	label: React.ReactElement;
	value: ITabType;
	disabled?: boolean;
	hidden?: boolean;
};

type ITabLabel = {
	value: ITabType;
	label: string;
	count?: ITabCount;
	journeyCount?: ITabCount;
};

const TabLabel = ({
	label,
	count,
	journeyCount,
}: ITabLabel): React.ReactElement => {
	const countStr = count
		? ` ( ${count.value < 0 ? 'Calculating' : count.value.toLocaleString()} )`
		: ' ';
	const showTag = !!journeyCount;
	return (
		<Box
			display='flex'
			flexDirection='row'
			gap='1rem'
			justifyContent='center'
			alignItems='center'>
			<DNBTypography
				sx={{fontWeight: 'unset'}}
				variant='compact-bold'>{`${label}${countStr}`}</DNBTypography>
			{showTag && (
				<DNBTag
					type='type-2'
					isLoading={journeyCount.loading}
					sx={{mr: 0, mb: 0}}
					label={
						journeyCount.loading ? '' : journeyCount.value.toLocaleString()
					}
				/>
			)}
		</Box>
	);
};

const TabsConfig = (segmentHelper: IBaseSegmentHelper): ITab[] => {
	const journeyCounts = useSelector(
		(state: {query: QueryState}) => state?.query.journeyCounts
	);
	const {accounts, contacts} = useSelector(
		(state: {query: QueryState}) => state?.query.counts
	);
	const {isCompanyList, isContactList} = getSessionSegmentState() ?? {};
	return [
		{
			label: (
				<TabLabel
					label={TabType.Attributes}
					value='attributes'
					count={{value: getAttributeCount()}}
				/>
			),
			value: 'attributes',
			hidden:
				!RBAC.hasAccess(RBACInterface.ATTRIBUTES_TAB, RBACActions.VIEW) ||
				isSegmentationV2Enabled(),
		},
		{
			label: <TabLabel label={TabType.Dashboard} value='dashboard' />,
			value: 'dashboard',
			hidden: !segmentHelper.getSegmentHelper().showDashboard(),
		},
		{
			label: <TabLabel label={TabType.CustomSubsegments} value='subsegments' />,
			value: 'subsegments',
			hidden: !segmentHelper.getSegmentHelper().showDashboard(),
		},
		{
			label: (
				<TabLabel
					label={TabType.QueryBuilder}
					value='builder'
					count={{value: getQuerybuilderCount()}}
				/>
			),
			value: 'builder',
			hidden: isCompanyList || isContactList,
		},
		{
			label: (
				<TabLabel
					label={TabType.Accounts}
					value='accounts'
					count={accounts}
					journeyCount={journeyCounts?.accounts}
				/>
			),
			disabled: accounts.value < 0,
			value: 'accounts',
			hidden: isContactList,
		},
		{
			label: (
				<TabLabel
					label={TabType.Contacts}
					value='contacts'
					count={contacts}
					journeyCount={journeyCounts?.contacts}
				/>
			),
			disabled: contacts.value < 0,
			value: 'contacts',
			hidden: isCompanyList,
		},
	];
};

interface ISegmentTabs {
	$state: NgStateService;
	$stateParams?: ISegmentTabParam;
}

const SegmentTabs = ({
	$state,
	$stateParams,
}: ISegmentTabs): React.ReactElement => {
	const segment = React.useMemo(
		() => getQueryProperty<ISegment>('segment') || {name: 'Create'},
		[]
	);
	const tabHelper = useSegmentTabHelper($state, segment, $stateParams);
	const defaultValue = tabHelper.getTabType();
	const [value, setValue] = React.useState(defaultValue);
	return (
		<DNBTabs
			value={value}
			sx={{
				pl: 6,
				pb: 4,
				width: '100%',
				boxShadow: 'inset 0px -1px 0px #cbcdce',
			}}
			onChange={(_event, value) => {
				setValue(value);
				setJourneyEntitiesProperty(undefined);
				tabHelper.clickTab(value);
			}}>
			{TabsConfig(tabHelper)
				.filter(({hidden}) => !hidden)
				.map((tab) => (
					<DNBTab key={tab.value} {...tab} />
				))}
		</DNBTabs>
	);
};

export type {ITab, ITabLabel};
export {SegmentTabs, TabType, TabLabel};
