import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';

type IRBACMap = {
	[key in RBACActions]: boolean;
};

const DefaultRBACMap: IRBACMap = {
	VIEW: true,
	DRILL: true,
	EDIT: true,
	CREATE: true,
};

const RBACMap = (rbacInterface: RBACInterface): IRBACMap => {
	return {
		VIEW: RBAC.hasAccess(rbacInterface, RBACActions.VIEW),
		DRILL: RBAC.hasAccess(rbacInterface, RBACActions.DRILL),
		EDIT: RBAC.hasAccess(rbacInterface, RBACActions.EDIT),
		CREATE: RBAC.hasAccess(rbacInterface, RBACActions.CREATE),
	};
};

export type {IRBACMap};
export {DefaultRBACMap, RBACMap};
