import RBAC, {
	AccessGroups,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import LocalStorageUtility from 'common/widgets/utilities/local-storage.utility';
import UserValues from 'common/widgets/utilities/user-values.utility';
import {store} from 'store';
import {
	getDataCloudProperty,
	getDataCloudMetadataProperty,
	setDataCloudPropertyValue,
	getEnrichments,
} from 'common/stores/datacloud';
import {
	FeatureFlags,
	isFeatureFlagEnabled,
	isHorizontalNavigationEnabled,
	isSegmentationV2Enabled,
} from 'common/stores/tenantConfig';
import {setPublicProperty, getQueryProperty} from 'common/stores/query';
import {getTopPredictorExport} from '../../../../../atlas/app/AppCommon/topPredictor/topPredictor.helpers';
import {queryBuilderUnload} from '../../query/queryBuilder.utilities';
import {refreshCounts, resetRestrictions} from '../../query/query.helpers';
import {
	ACCOUNTS_PAGE_VIEW_KEY,
	CONTACTS_PAGE_VIEW_KEY,
} from '../../query/results/rebuild';
import getSanitizedSegmentByConfig, {
	ViewType,
	showExportBanner,
} from './SubheaderUtility';
import {
	getSegmentByName,
	createOrUpdateSegment,
	updateSegmentCounts,
	createOrUpdateSegmentExport,
	getTeams,
} from '../../segment/segment.queries';
import HealthService from '../../../../app/services/HealthService';
import NoticeService from '../../../notice/NoticeService';
import StateHistory from '../../../../../atlas/app/StateHistory';
import {getRatingsEngineProperty} from '../../../../../atlas/app/stores/ratingsEngine';
import {
	MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT_ATTRIBUTES,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SUCCESS_CRITERIA,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SUCCESS_CRITERIA_ATTRIBUTES,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT_ATTRIBUTES,
} from '../../../../../atlas/app/navigation/header/components/page-navigation.utils';

angular
	.module('common.datacloud.tabs.subheader', [])
	.controller(
		'SubHeaderTabsController',
		function (
			$state,
			$rootScope,
			$scope,
			$stateParams,
			$timeout,
			CreateSegmentModal,
			DuplicateSegmentModal,
			ExportSegmentModal,
			Segment
		) {
			const vm = this;

			const currentRating = getRatingsEngineProperty('currentRating');

			let model =
				currentRating && Object.keys(currentRating).length
					? currentRating
					: null;
			const clientSession = LocalStorageUtility.getClientSession();

			vm.Segment = Segment;
			if ($stateParams.segment && !model) {
				model = Segment || null; // cheap fix for if this is a segment and not a model and there is no model
			}

			angular.extend(vm, {
				enrichments: [],
				stateParams: $stateParams,
				segment: $stateParams.segment,
				section: $stateParams.section,
				show_lattice_insights: isFeatureFlagEnabled(
					FeatureFlags.LATTICE_INSIGHTS
				),
				showSegmentationV2: isSegmentationV2Enabled(),
				canSaveSegment:
					RBAC.userHasAccessLevel(AccessGroups.EXCLUDE_SALES) &&
					RBAC.hasAccess(RBACInterface.SEGMENTS, RBACActions.CREATE),
				canEditSegment: model
					? !model.viewOnly &&
					  RBAC.hasAccess(RBACInterface.SEGMENTS, RBACActions.EDIT)
					: true,
				canEditAIModel: $stateParams.aiModel
					? RBAC.hasAccess(RBACInterface.MODELS, RBACActions.EDIT)
					: false,
				userHasTeams: clientSession.TeamIds && clientSession.TeamIds.length,
				public: getQueryProperty('public'),
				builderClicked: false,
				attribuesClicked: false,
				isSaving: false,
				enableSaveSegmentMsg: false,
				isListSegment: getQueryProperty('segment')?.type === 'List',
				header: {
					exportSegment: {
						class: 'white-button select-label',
						click: false,
						icon: 'fa fa-chevron-down',
						iconlabel: 'Export',
						iconclass: 'save button white-button select-more',
						iconrotate: true,
						icondisabled: false,
						showSpinner: false,
					},
				},
				counts: getQueryProperty('counts'),
				teams: [],
				enableHorizontalNavigation:
					isHorizontalNavigationEnabled() &&
					// We don't want to display the new redesigns on the ai model or segment pages yet
					!$stateParams.aiModel,
				isSuccessCriteria: $state.current.name.includes(
					'home.ratingsengine.createAccountFitModeling.successCriteria'
				),
			});

			function checkStatusBeforeExport(exportType, $event) {
				$event.preventDefault();

				HealthService.checkSystemStatus().then(() => {
					vm.toggleExportDropdown(true); // disable dropdown
					vm.exportSegment(exportType);
				});
			}

			vm.init = () => {
				const bucketCount =
					getQueryProperty('accountRestriction').restriction.logicalRestriction
						.restrictions.length +
					getQueryProperty('contactRestriction').restriction.logicalRestriction
						.restrictions.length;
				if (
					bucketCount == 0 ||
					('home.segment.explorer.builder' === StateHistory.lastTo().name &&
						bucketCount != 0 &&
						vm.segment !== 'Create')
				) {
					setPublicProperty('enableSaveSegmentButton', false);
				}

				const counts = getQueryProperty('counts');

				this.header.exportSegment.items = [
					{
						label: 'Enriched Accounts',
						icon: 'fa fa-building-o',
						class: 'aptrinsic-export-enriched-accounts',
						click: checkStatusBeforeExport.bind(null, 'ACCOUNT'),
						disabledif: !counts.accounts.loading && !counts.accounts.value,
					},
					{
						label: 'Enriched Contacts with Account Attributes',
						icon: 'fa fa-briefcase',
						class: 'aptrinsic-export-enriched-contacts-accounts',
						click: checkStatusBeforeExport.bind(null, 'ACCOUNT_AND_CONTACT'),
						disabledif:
							!counts.accounts.loading &&
							!counts.accounts.loading &&
							(counts.accounts.value === 0 || counts.contacts.value === 0),
					},
					{
						label: 'Enriched Contacts (No Account Attributes)',
						icon: 'fa fa-users',
						class: 'aptrinsic-export-enriched-contacts',
						click: checkStatusBeforeExport.bind(null, 'CONTACT'),
						disabledif: !counts.contacts.loading && !counts.contacts.value,
					},
				];

				getEnrichments().then((result) => {
					vm.enrichments = result;
				});

				getTeams().then((response) => {
					vm.teams = response;
				});
			};

			vm.getPickerItem = () => getDataCloudProperty('pickerObject');

			vm.getIterationFilterNumber = (type) => {
				switch (type) {
					case 'all':
						return vm.enrichments.length;
					case 'used':
						return vm.enrichments.filter(
							(item) => item.ImportanceOrdering !== undefined
						).length;
					case 'warnings':
						return vm.enrichments.filter((item) => item.HasWarnings).length;
					case 'disabled':
						return vm.enrichments.filter(
							(item) => item.ApprovedUsage[0] === 'None'
						).length;
					default:
						throw new Error(`No such filter: '${type}'`);
				}
			};

			vm.getAccountName = () =>
				getDataCloudMetadataProperty('accountName') || $stateParams.AccountId;

			vm.clickIterationFilter = (type) => {
				store.dispatch(
					setDataCloudPropertyValue('ratingIterationFilter', type)
				);

				$rootScope.$broadcast('iterationFilterChange', type);
			};

			vm.checkIterationFilter = (type) => {
				const filter = getDataCloudProperty('ratingIterationFilter');

				return filter === type;
			};

			vm.checkState = (type) => {
				const state = $state.current.name;

				const map = {
					'home.segment.explorer.attributes': 'attributes',
					'home.segment.explorer.builder': 'builder',
					'home.segment.explorer.enumpicker': 'picker',
					'home.segment.accounts.contacts.noActivity': 'contacts.noActivity',
					'home.segment.accounts.contacts.list': 'contacts.list',
					'home.segment.accounts.contacts.activity': 'contacts.activity',
					'home.segment.accounts.contacts.dashboard': 'contacts.dashboard',
					'home.segment.accounts': 'accounts',
					'home.segment.contacts': 'contacts',
					'home.model.datacloud': 'model_iteration',
				};

				vm.public = getQueryProperty('public');

				if ($state.current.name.includes('createAccountFitModeling')) {
					switch ($stateParams.tab) {
						case 'createSegment':
							return 'builder' === type;
						case 'attributes':
						case 'picker':
							return 'attributes' === type;
						case 'createSegmentAccounts':
							return 'accounts' === type;
						case 'createSegmentContacts':
							return 'contacts' === type;
					}
				}

				return map[state] === type;
			};

			vm.enableNewSubheaderUI = () =>
				vm.checkState('accounts') || vm.checkState('contacts');

			vm.clickAccountsList = () => {
				$timeout(() => {
					$state.go('home.segment.accounts');
				}, 1);
			};

			vm.clickContactsList = () => {
				$timeout(() => {
					$state.go('home.segment.accounts.contacts.list', {});
				}, 1);
			};

			vm.clickActivityTimeline = () => {
				$timeout(() => {
					$state.go('home.segment.accounts.contacts.activity', {ContactId: ''});
				}, 1);
			};

			vm.clickBuilder = () => {
				const state = vm.ifInModel(
					'home.model.analysis.explorer.builder',
					'home.segment.explorer.builder'
				);

				vm.builderClicked = true;
				vm.attribuesClicked = false;
				const stateParams = {
					...$stateParams,
					pageTitle: null,
				};

				if ($state.current.name.includes('createAccountFitModeling')) {
					if (
						$state.current.name ===
							MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT ||
						$state.current.name ===
							MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT
					) {
						return;
					}

					const isCreateSegment = $state.current.name.includes('createSegment');

					const isScoringSegment =
						$state.current.name.includes('scoringSegment');

					const secondaryName = isScoringSegment
						? MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT
						: MODELS_CREATE_ACCOUNT_FIT_MODELING_SUCCESS_CRITERIA;
					const name = isCreateSegment
						? MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT
						: secondaryName;

					const tab =
						isCreateSegment || isScoringSegment
							? 'createSegment'
							: 'successCriteria';

					$timeout(() => {
						$state.go(name, {
							...stateParams,
							tab,
						});
					}, 1);

					return;
				}

				$timeout(() => {
					$state.go(state, stateParams);
				}, 1);
			};

			vm.clickAttributes = () => {
				const state = vm.ifInModel(
					'home.model.analysis.explorer.attributes',
					'home.segment.explorer.attributes'
				);

				vm.builderClicked = false;
				vm.attribuesClicked = true;

				if ($state.current.name.includes('createAccountFitModeling')) {
					if (
						$state.current.name ===
							MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT_ATTRIBUTES ||
						$state.current.name ===
							MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT_ATTRIBUTES
					) {
						return;
					}

					const isCreateSegment = $state.current.name.includes('createSegment');

					const isScoringSegment =
						$state.current.name.includes('scoringSegment');

					const secondaryName = isScoringSegment
						? MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT_ATTRIBUTES
						: MODELS_CREATE_ACCOUNT_FIT_MODELING_SUCCESS_CRITERIA_ATTRIBUTES;

					const name = isCreateSegment
						? MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT_ATTRIBUTES
						: secondaryName;

					$timeout(() => {
						$state.go(name, {
							...$stateParams,
							tab: 'attributes',
						});
					}, 1);

					return;
				}

				$timeout(() => {
					$state.go(state, $stateParams);
				}, 1);
			};

			vm.clickedExport = () => {
				const data = LocalStorageUtility.getObject('ModelStore.data'); // using ModelStore.getData() causes build error
				const csvRows = getTopPredictorExport(data);
				const lineArray = [];

				csvRows.forEach((infoArray) => {
					const line = infoArray.join(',');
					lineArray.push(line);
				});

				const csvContent = lineArray.join('\n');
				const element = document.createElement('a');

				element.setAttribute(
					'href',
					`data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`
				);
				element.setAttribute('download', 'attributes.csv');
				element.style.display = 'none';

				document.body.appendChild(element);
				element.click();
				document.body.removeChild(element);
			};

			vm.clickPickerBack = () => {
				const state = StateHistory.lastFrom();
				const params = StateHistory.lastFromParams();

				$state.go(state.name, params);
			};

			vm.clickSegmentButton = (parms) => {
				const state = vm.ifInModel(
					'home.model.segmentation',
					'home.segments_new'
				);

				const opts = parms ? {} : {notify: true};

				$state.go(state, parms, opts);
			};

			vm.clearSegment = () => {
				resetRestrictions(undefined, () => $scope.$apply());
				setPublicProperty('enableSaveSegmentButton', false);
				$rootScope.$broadcast('clearSegment');
			};

			vm.saveSegmentCounts = () => {
				updateSegmentCounts($stateParams.segment);
			};

			vm.saveSegment = () => {
				const segmentName = $stateParams.segment;
				const isNewSegment = segmentName === 'Create';

				if (isNewSegment) {
					CreateSegmentModal.show(segmentName, vm.teams, $stateParams.isTAM);
				} else {
					const xhrSaveSegment = (segmentData) => {
						const {name} = segmentData;
						const displayName = segmentData.display_name;

						if (!displayName) {
							setPublicProperty('enableSaveSegmentButton', true);
							return;
						}

						const {description} = segmentData;
						const {teamId} = segmentData;

						const segmentConfig = {
							name,
							display_name: displayName,
							description,
							teamId,
							page_filter: {
								row_offset: 0,
								num_rows: 10,
							},
						};
						if ($stateParams.isTAM) {
							segmentConfig.type = 'TAM';
						}
						if (vm.showSegmentationV2) {
							segmentConfig.materialized = segmentData.materialized;
						}
						const sanitizedSegment = getSanitizedSegmentByConfig(segmentConfig);
						setPublicProperty('enableSaveSegmentButton', false);
						vm.isSaving = true;
						createOrUpdateSegment(sanitizedSegment).then((result) => {
							NoticeService.success({
								title: 'The segment was saved successfully',
							});

							vm.enableSaveSegmentMsg = true;
							$timeout(() => {
								vm.enableSaveSegmentMsg = false;

								// Dark magic to be able to persist the segment in the react component AccountFitModelingWizard
								window.postMessage({segment: result.data});
							}, 3500);

							vm.saved = true;
							vm.isSaving = false;
						});
					};

					setPublicProperty('enableSaveSegmentButton', false);

					const xhrGetSegmentResult = (result) => {
						xhrSaveSegment(result);
					};

					getSegmentByName(segmentName).then(xhrGetSegmentResult);
				}
			};

			vm.changeSettings = () => {
				const iteration = getRatingsEngineProperty('remodelIteration');
				const modelId = iteration.modelSummaryId;
				const {rating_id: ratingId} = $stateParams;
				const {aiModel} = $stateParams;
				const url = 'home.ratingsengine.dashboard.training';

				$state.go(
					url,
					{
						rating_id: ratingId,
						modelId,
						aiModel,
					},
					{reload: true}
				);
			};

			vm.inModel = () => {
				const name = $state.current.name.split('.');
				return name[1] === 'model';
			};

			vm.ifInModel = (_model, not) => (vm.inModel() ? _model : not);

			vm.exportSegment = (
				exportType,
				attributeSetName,
				exportTimestamp,
				visibleColumns
			) => {
				const segmentName = $stateParams.segment;
				setPublicProperty('resetLabelIncrementor', true);

				const onSuccess = attributeSetName
					? (success) => {
							if (success) showExportBanner();
							ExportSegmentModal.toggleLoadingState(false);
							ExportSegmentModal.hide();
					  }
					: (success) => {
							if (success) showExportBanner();
							vm.toggleExportDropdown(false);
					  };

				if (segmentName === 'Create') {
					const segmentExportConfig = {
						type: exportType,
						addExportTimestamp: exportTimestamp,
						...(visibleColumns ? {visibleColumns} : {}),
					};
					const sanitizedSegment =
						getSanitizedSegmentByConfig(segmentExportConfig);
					createOrUpdateSegmentExport(sanitizedSegment, attributeSetName).then(
						onSuccess
					);
				} else {
					getSegmentByName(segmentName).then((result) => {
						const segmentData = result;
						const segmentExportConfig = {
							segmentName,
							export_prefix: segmentData.display_name,
							type: exportType,
							addExportTimestamp: exportTimestamp,
							...(visibleColumns ? {visibleColumns} : {}),
						};
						const sanitizedSegment =
							getSanitizedSegmentByConfig(segmentExportConfig);
						createOrUpdateSegmentExport(
							sanitizedSegment,
							attributeSetName
						).then(onSuccess);
					});
				}
			};

			vm.toggleExportDropdown = (bool) => {
				vm.header.exportSegment.icondisabled = bool;
				vm.header.exportSegment.showSpinner = bool;
			};

			vm.disableExport = () => {
				const accountsAvailable = vm.counts.accounts.value;
				const contactsAvailable = vm.counts.contacts.value;
				vm.header.exportSegment.items[0].disabledif = !accountsAvailable;
				vm.header.exportSegment.items[1].disabledif = !contactsAvailable;
				vm.header.exportSegment.items[2].disabledif =
					!accountsAvailable || !contactsAvailable;
			};

			vm.exportSegmentFromModal = (
				exportType,
				attributeSetName,
				exportTimestamp,
				visibleColumns
			) => {
				HealthService.checkSystemStatus().then(() => {
					LocalStorageUtility.set(
						`${$stateParams.segment}_last_attribute_set_exported`,
						attributeSetName
					);
					vm.exportSegment(
						exportType,
						attributeSetName,
						exportTimestamp,
						visibleColumns
					);
				});
			};

			vm.toggleExportModal = () => {
				const accountsAvailable = !!vm.counts.accounts.value;
				const contactsAvailable = !!vm.counts.contacts.value;
				const defaultAttributeSetSelection =
					LocalStorageUtility.get(
						`${$stateParams.segment}_last_attribute_set_exported`
					) || 'Default_Group';

				const canExportSegments = accountsAvailable || contactsAvailable;

				const onClickExport = vm.exportSegmentFromModal;

				if (!canExportSegments) {
					NoticeService.warning({
						message:
							'Cannot export segment: This segment contains no accounts or contacts.',
					});
					return;
				}

				let viewType = ViewType.Generic;
				const keyPrefix = UserValues.getLongFormTenantId();
				let currentAttributeGroupView = null;
				if ($state.current.name === 'home.segment.accounts') {
					viewType = ViewType.Account;
					const queryKey = `${keyPrefix}.${ACCOUNTS_PAGE_VIEW_KEY}`;
					currentAttributeGroupView = JSON.parse(
						sessionStorage.getItem(queryKey)
					)?.attributeGroup;
				}
				if ($state.current.name === 'home.segment.contacts') {
					viewType = ViewType.Contact;
					const queryKey = `${keyPrefix}.${CONTACTS_PAGE_VIEW_KEY}`;
					currentAttributeGroupView = JSON.parse(
						sessionStorage.getItem(queryKey)
					)?.attributeGroup;
				}

				ExportSegmentModal.show(
					vm.segment,
					accountsAvailable,
					contactsAvailable,
					defaultAttributeSetSelection,
					onClickExport,
					viewType,
					currentAttributeGroupView,
					vm.enrichments
				);
			};

			vm.refreshCounts = () => {
				const segmentName = $stateParams.segment;
				if (segmentName === 'Create') {
					return;
				}

				vm.isRefreshing = true;
				vm.public.resetLabelIncrementor = true;

				refreshCounts()
					.then(() => {
						vm.saveSegmentCounts();
					})
					.finally(() => {
						vm.isRefreshing = false;

						$scope.$apply();
					});
			};

			vm.duplicateSegment = () => {
				DuplicateSegmentModal.show(Segment, vm.teams);
			};

			vm.init();

			$scope.$watch('vm.public.enableSaveSegmentButton', () => {
				queryBuilderUnload();
			});
		}
	);
