import React, {react2angular} from 'common/react-vendor';
import {IdaasLoginWithDnbCredentialsMessage} from './IdaasLoginWithDnbCredentialsMessage';
import {SessionStorageKeys} from './idaas-constants';

const IdaasLoginWithDnbCredentialsMessageWrapper = ({
	$state,
}: {
	$state: {go: (arg0: string) => void};
}): React.ReactElement => (
	<IdaasLoginWithDnbCredentialsMessage
		redirectToLoginWithBanner={() => {
			$state.go('login.form');
			sessionStorage.setItem(
				SessionStorageKeys.showLoginWithDnbCredsBanner,
				'true'
			);
		}}
	/>
);

angular
	.module('login.idaasLoginWithDnbCredentialsMessage', [])
	.component(
		'idaasLoginWithDnbCredentialsMessage',
		react2angular(IdaasLoginWithDnbCredentialsMessageWrapper, [], ['$state'])
	);
