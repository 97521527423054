import React from 'common/react-vendor';
import {
	DNBButton,
	FirstPageIcon,
	LastPageIcon,
	NavigateBeforeIcon,
	NavigateNextIcon,
} from 'common/dnb-uux-vendor';
import './le-pagination.scss';

const {useState, useEffect} = React;

interface CallbackResponse {
	from: number;
	to: number;
	current: number;
}

interface PaginationProps {
	total: number;
	perPage: number;
	start: number;
	className: string;
	callback(page: CallbackResponse): void;
}

const LePagination = ({
	total,
	perPage,
	start,
	className,
	callback,
}: PaginationProps): React.ReactElement => {
	const [currentPage, setCurrentPage] = useState(start);
	const totalPages = Math.ceil(total / perPage);

	const getFromTo = (page: number): CallbackResponse => {
		const from = (page - 1) * perPage;
		let to = page * perPage;
		const newPage = page;
		if (to > total) {
			to = total;
		}
		setCurrentPage(newPage);
		return {from, to, current: newPage};
	};

	useEffect(() => {
		setCurrentPage(start);
	}, [start]);

	return (
		<>
			{totalPages > 1 && (
				<div className={`le-pagination ${className || ''}`}>
					<DNBButton
						disabled={currentPage === 1}
						name='borderless-former'
						size='compact'
						variant='text'
						onClick={() => callback(getFromTo(1))}
						startIcon={<FirstPageIcon />}
					/>
					<DNBButton
						disabled={currentPage === 1}
						name='borderless-firts'
						size='compact'
						variant='text'
						onClick={() => callback(getFromTo(currentPage - 1))}
						startIcon={<NavigateBeforeIcon />}
					/>
					<span className='le-pagination-center'>
						<span className='le-pagination-pagenum'>{currentPage}</span>
						<span>/</span>
						<span className='le-pagination-pagetotal'>{totalPages}</span>
					</span>
					<DNBButton
						disabled={currentPage === totalPages}
						name='borderless-next'
						size='compact'
						variant='text'
						onClick={() => callback(getFromTo(currentPage + 1))}
						startIcon={<NavigateNextIcon />}
					/>
					<DNBButton
						disabled={currentPage === totalPages}
						name='borderless-firts'
						size='compact'
						variant='text'
						onClick={() => callback(getFromTo(totalPages))}
						startIcon={<LastPageIcon />}
					/>
				</div>
			)}
		</>
	);
};
export {LePagination};
