import {TenantConfigActionType} from './enums';
import {
	TenantFeatureFlags,
	TenantModules,
	TenantProducts,
	TenantConfigAction,
	TenantConfigState,
} from './types';

const setFeatureFlags = (
	featureFlags: TenantFeatureFlags
): TenantConfigAction => {
	return {
		type: TenantConfigActionType.SET_FEATURE_FLAGS,
		payload: featureFlags,
	};
};

const setModules = (modules: TenantModules): TenantConfigAction => {
	return {
		type: TenantConfigActionType.SET_MODULES,
		payload: modules,
	};
};

const setProducts = (products: TenantProducts): TenantConfigAction => {
	return {
		type: TenantConfigActionType.SET_PRODUCTS,
		payload: products,
	};
};

const setIsFetching = (isFetching: boolean): TenantConfigAction => {
	return {
		type: TenantConfigActionType.SET_IS_FETCHING,
		payload: isFetching,
	};
};

const setTenantConfig = (
	tenantConfig: TenantConfigState
): TenantConfigAction => {
	return {
		type: TenantConfigActionType.SET_TENANT_CONFIG,
		payload: tenantConfig,
	};
};

export {
	setFeatureFlags,
	setModules,
	setProducts,
	setIsFetching,
	setTenantConfig,
};
