export const SUCCESS = 200;

export default class Response {
	constructor(xhrResponse, status = 200, msg = '', data = []) {
		this.xhrResponse = xhrResponse;
		this.status = status;
		this.msg = msg;
		this.data = data;
	}

	getStatus() {
		return this.status;
	}

	getData() {
		return this.data;
	}
}
