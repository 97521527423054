import React, {Component, PropTypes} from 'common/react-vendor';
import './le-refine.scss';
import {store, injectAsyncReducer} from 'store';

const CONST = {
	SET_LEREFINE_CONFIG: 'SET_LEREFINE_CONFIG',
	SET_LEREFINE_TOGGLE: 'SET_LEREFINE_TOGGLE',
	SET_LEREFINE_ACTION: 'SET_LEREFINE_ACTION',
};

const actions = {
	setConfig: (payload) => {
		return store.dispatch({
			type: 'SET_LEREFINE_CONFIG',
			payload: payload,
		});
	},
	setAction: (payload) => {
		return store.dispatch({
			type: 'SET_LEREFINE_ACTION',
			payload: payload,
		});
	},
	setToggle: () => {
		return store.dispatch({
			type: 'SET_LEREFINE_TOGGLE',
		});
	},
};

const reducer = (state = {}, {type, payload}) => {
	switch (type) {
		case CONST.SET_LEREFINE_CONFIG:
			return {
				...state,
				...payload,
			};
		case CONST.SET_LEREFINE_ACTION:
			return {
				...state,
				label: payload.label,
				action: payload.action,
				icon: payload.icon,
				total: payload.total,
			};
		case CONST.SET_LEREFINE_TOGGLE:
			return {
				...state,
				visible: !state.visible,
			};
		default:
			return state;
	}
};

export default class LeRefine extends Component {
	constructor(props) {
		super(props);

		this.state = {
			label: '',
			visible: false,
			items: [],
		};
	}

	componentDidMount() {
		injectAsyncReducer(store, this.props.namespace || this.getPath(), reducer);
		this.unsubscribe = store.subscribe(this.handleChange);
		actions.setConfig(this.props.config);
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	handleChange = () => {
		const state = store.getState()[this.props.namespace || this.getPath()];
		this.setState(state);
	};

	getPath() {
		return `${this.props.path || 'filter'}.${this.props.name || 'refine'}`;
	}

	getButtonClasses(config, additional) {
		let classes = 'button white-button icon-button select-';
		classes += additional ? additional : '';
		classes += config.visible ? ' open' : '';
		return classes;
	}

	render() {
		if (!this.props || !this.props.config) {
			return '';
		}

		const config = this.state;

		return (
			<div className='select-menu white-select-menu filter-select'>
				<button
					type='button'
					title='Refine Property'
					className={this.getButtonClasses(config, 'label')}
					onClick={actions.setToggle}>
					{config.label}
				</button>
				<button
					type='button'
					title='Refine Property'
					className={this.getButtonClasses(config, 'more')}
					onClick={actions.setToggle}>
					<span className='fa fa-filter' />
				</button>
				{config.visible && config.items.length > 0 ? (
					<ul className='model-menu' onClick={this.clickToggle}>
						{this.renderItems(this.props.config.items)}
					</ul>
				) : (
					''
				)}
			</div>
		);
	}

	renderItems(items) {
		return items.map((item, i) => {
			if (item.if == undefined || item.if) {
				return (
					<li
						key={i + 'LeRefineItem' + item.label}
						className={item.class}
						onClick={() => {
							actions.setToggle();
							actions.setAction(item);
						}}>
						<span>{item.label}</span>
						{this.renderItemIcon(item)}
					</li>
				);
			}
		});
	}

	renderItemIcon(item) {
		if (item.icon) {
			return <i className={this.getIconClasses(item)} />;
		}
		if (item.total != undefined) {
			return (
				<span className={'badge ' + (item.badge || 'darkgray-badge')}>
					{item.total}
				</span>
			);
		}
	}
}

LeRefine.propTypes = {
	config: PropTypes.object.isRequired,
};
