import * as reduxStore from './redux-store';
import ngRedux from 'ng-redux';

const configureStore = reduxStore.configureStore;

export const store = reduxStore.store;
export const injectAsyncReducer = reduxStore.injectAsyncReducer;
export const inject = injectAsyncReducer;
export const mount = reduxStore.mount;
export const redux = reduxStore.redux;
export default configureStore;

angular.module('mainApp.core.redux', [ngRedux]).config(($ngReduxProvider) => {
	$ngReduxProvider.provideStore(store);
});
