import React, {NgStateService, StateParams} from 'common/react-vendor';
import {store} from 'store';
import {Provider} from 'common/redux-vendor';
import {Box} from 'common/dnb-uux-vendor';
import {SubstageEditHeader} from 'common/components/datacloud/tabs/subjourneyheader/SubstageEditHeader';
import {JourneyTabs} from 'common/components/datacloud/tabs/tabs/JourneyTabs';
import {ISubJourneyStage} from 'atlas/segmentation/SegmentDashboard/ApiCalls';
import {IJourneyStageData} from 'atlas/JourneyStage/Data/JourneyStageData';
import {IJourneyStageOverview} from 'atlas/segmentation/SegmentDashboard/Data/SegmentJourneyType';

type IStageSummary = {
	$state: NgStateService;
	$stateParams: StateParams;
	$scope: {
		$parent: {
			$resolve: {
				JourneyStage?: IJourneyStageData;
				SubJourneyStage?: ISubJourneyStage;
				JourneyStageOverview?: IJourneyStageOverview[];
			};
		};
	};
};

const StageSummary = ({
	$state,
	$stateParams,
	$scope: {
		$parent: {
			$resolve: {SubJourneyStage, JourneyStageOverview},
		},
	},
}: IStageSummary): React.ReactElement => {
	const {subStageId} = $stateParams;
	const isCreateMode = !subStageId || subStageId === 'Create';
	return (
		<Provider store={store}>
			<Box sx={{backgroundColor: 'white'}}>
				<div>
					<SubstageEditHeader $state={$state} subStage={SubJourneyStage} />
				</div>
				{!isCreateMode && (
					<div>
						<JourneyTabs
							$state={$state}
							$stateParams={$stateParams}
							subStageOverview={
								JourneyStageOverview?.filter(
									({stageId}) => SubJourneyStage?.pid === stageId
								)?.[0]
							}
						/>
					</div>
				)}
			</Box>
		</Provider>
	);
};

export {StageSummary};
