import {ObjectType} from '../../ConnectionType';
import {IBrokerAuthInfo} from './BrokerAuth';

interface IUpdateAuthRequestBody extends ObjectType {
	authentication: {
		name: string;
		brokerAuthInfo: IBrokerAuthInfo;
	};
	name: string;
}

const UpdateAuthUrl = '/pls/inboundchannel/connection/auth';

export {UpdateAuthUrl};
export type {IUpdateAuthRequestBody};
