export const getDays = (timestamp) => {
	if (!timestamp) {
		return false;
	}

	const MILLISECOND_PER_DAY = 24 * 60 * 60 * 1000;
	const numDaysAgoPasswordLastModified = Math.floor(
		(Date.now() - timestamp) / MILLISECOND_PER_DAY
	);

	return numDaysAgoPasswordLastModified;
};

export const getDateNinetyDaysAway = (timestamp) => {
	if (!timestamp) {
		return false;
	}
	const MILLISECOND_PER_DAY = 24 * 60 * 60 * 1000;
	const dateTimeNinetyDaysAway = timestamp + MILLISECOND_PER_DAY * 90;
	return dateTimeNinetyDaysAway;
};

export const isTimestampFartherThanNinetyDaysAgo = (timestamp) => {
	return getDays(timestamp) >= 90;
};

export const timeAgo = (toTimestamp, fromTimestamp, inSeconds) => {
	const to = inSeconds ? new Date(toTimestamp * 1000) : new Date(toTimestamp);
	const fromInSeconds = inSeconds
		? new Date(toTimestamp * 1000)
		: new Date(toTimestamp);
	const from = fromTimestamp ? fromInSeconds : new Date();
	const diff = (from - to) / 1000;
	const units = [
		{name: 'second', limit: 60, inSeconds: 1},
		{name: 'minute', limit: 3600, inSeconds: 60},
		{name: 'hour', limit: 86400, inSeconds: 3600},
		{name: 'day', limit: 604800, inSeconds: 86400},
		{name: 'week', limit: 2629743, inSeconds: 604800},
		{name: 'month', limit: 31556926, inSeconds: 2629743},
		{name: 'year', limit: null, inSeconds: 31556926},
	];

	if (diff < 5) {
		return 'now';
	}

	let i = 0;
	let unit;
	while ((unit = units[i++])) {
		if (diff < unit.limit || !unit.limit) {
			const adjustedDiff = Math.floor(diff / unit.inSeconds);
			return `${adjustedDiff} ${unit.name}${adjustedDiff > 1 ? 's' : ''} ago`;
		}
	}
};
