enum Aggregation {
	SUM = 'SUM',
	AVG = 'AVG',
	EACH = 'EACH',
	AT_LEAST_ONCE = 'AT_LEAST_ONCE',
}

enum BucketCmp {
	GT_AND_LT = 'GT_AND_LT',
	GT_AND_LTE = 'GT_AND_LTE',
	GTE_AND_LT = 'GTE_AND_LT',
	GTE_AND_LTE = 'GTE_AND_LTE',
	BETWEEN = 'BETWEEN',
	BETWEEN_DATE = 'BETWEEN_DATE',
	GREATER_THAN = 'GREATER_THAN',
	GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
	NOT_EQUAL = 'NOT_EQUAL',
	EQUAL = 'EQUAL',
	LESS_THAN = 'LESS_THAN',
	LESS_OR_EQUAL = 'LESS_OR_EQUAL',
	AFTER = 'AFTER',
	BEFORE = 'BEFORE',
	WITHIN = 'WITHIN',
	PRIOR_ONLY = 'PRIOR_ONLY',
	IN_COLLECTION = 'IN_COLLECTION',
	NOT_IN_COLLECTION = 'NOT_IN_COLLECTION',
	YES = 'Yes',
	NO = 'No',
	EMPTY = '',
	IS = 'is',
	IS_EMPTY = 'empty',
	IS_EMPTY_CAPITALIZE = 'IS_EMPTY',
	LOW_CASE_BETWEEN = 'between',
	IS_NULL = 'IS_NULL',
	IS_NOT_NULL = 'IS_NOT_NULL',
	CONTAINS = 'CONTAINS',
	NOT_CONTAINS = 'NOT_CONTAINS',
	STARTS_WITH = 'STARTS_WITH',
	IN_CURRENT_PERIOD = 'IN_CURRENT_PERIOD',
	EVER = 'EVER',
	EVER_LOW_CASE = 'ever',
	ANY = 'Any',
	ANY_CAPITALIZE = 'ANY',
	BETWEEN_LT = 'BETWEEN_LT',
	PREVIOUS = 'PREVIOUS',
	PRIOR_ONLY_LT = 'PRIOR_ONLY_LT',
	ENDS_WITH = 'ENDS_WITH',
	LAST = 'LAST',
	LATEST_DAY = 'LATEST_DAY',
	BETWEEN_NEXT = 'BETWEEN_NEXT',
	FOLLOWING = 'FOLLOWING',
	BEFORE_LAST = 'BEFORE_LAST',
	AFTER_NEXT = 'AFTER_NEXT',
	FUTURE_WITHIN = 'FUTURE_WITHIN',
	AT_LEAST = 'AT_LEAST',
	AS_MUCH_AS = 'AS_MUCH_AS',
}

enum TransactionKey {
	Qty = 'Qty',
	Amt = 'Amt',
	Time = 'Time',
	Avg = 'Avg',
}

enum Period {
	Day = 'Day',
	Week = 'Week',
	Month = 'Month',
	Quarter = 'Quarter',
	Year = 'Year',
}

enum Selector {
	SPENT = 'SPENT',
	UNIT = 'UNIT',
}

enum Entity {
	LatticeAccount = 'LatticeAccount',
	Account = 'Account',
	Contact = 'Contact',
	Member = 'Member',
	CuratedAccount = 'CuratedAccount',
	Rating = 'Rating',
	PurchaseHistory = 'PurchaseHistory',
	Unknown = 'Unknown',
}

enum BucketType {
	Numerical = 'Numerical',
	Boolean = 'Boolean',
	Enum = 'Enum',
	Date = 'Date',
	String = 'String',
	Transaction = 'Transaction',
	Percent = 'Percent',
}

enum TreeType {
	TimeSeries = 'TimeSeries',
	PercentChange = 'PercentChange',
	String = 'String',
}

enum Direction {
	INC = 'INC',
	DEC = 'DEC',
}

enum Operator {
	AND = 'AND',
	OR = 'OR',
}

export {
	Aggregation,
	BucketCmp,
	TransactionKey,
	Direction,
	Period,
	Selector,
	Entity,
	BucketType,
	TreeType,
	Operator,
};
