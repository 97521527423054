import {ISegment} from 'atlas/data/SegmentConst';
import {IGenericAction} from 'atlas/JourneyStage/Component/Dialogs/DialogType';
import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACInterface,
	RBACActions,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {
	DNBTypography,
	DNBIconButton,
	EditOutlinedIcon,
	DNBButton,
	Grid,
	DNBButtonProps,
} from 'common/dnb-uux-vendor';
import React from 'common/react-vendor';
import {getQueryProperty} from 'common/stores/query';

/**
 * INameDescriptionData interface.
 * @param id Unique ID
 * @param name Display name
 * @param description Description
 */
interface INameDescriptionData {
	id: string | number;
	name: string;
	description: string;
}

type IEditDialog = {
	open: boolean;
} & IGenericAction<INameDescriptionData>;

/**
 * INameDescriptionHeader
 * @param data Data that @INameDescriptionData
 * @param buttons Buttons configuration.
 * @param Dialog Dialog component @IEditDialog
 * @param action Action callback from Dialog.
 */
interface INameDescriptionHeader {
	data: INameDescriptionData;
	buttons: DNBButtonProps[];
	Dialog: (prop: IEditDialog) => React.ReactElement;
	action?: IGenericAction<INameDescriptionData>;
}

/**
 * Name description header component.
 * @param prop @INameDescriptionHeader
 */
const NameDescriptionHeader = ({
	data,
	buttons,
	Dialog,
	action,
}: INameDescriptionHeader): React.ReactElement => {
	const {name: defaultDisplayName, description: defaultDescription} = data;
	const [displayName, setDisplayName] = React.useState(defaultDisplayName);
	const [description, setDescription] = React.useState(defaultDescription);
	const [isEditDialogOpen, setEditDialogOpen] = React.useState(false);
	const segment = getQueryProperty<ISegment>('segment');
	const canEdit =
		!segment ||
		(!segment.viewOnly &&
			RBAC.hasAccess(RBACInterface.SEGMENTS, RBACActions.EDIT));
	return (
		<>
			{isEditDialogOpen && (
				<Dialog
					open={isEditDialogOpen}
					onCancel={() => {
						action?.onCancel?.();
						setEditDialogOpen(false);
					}}
					onOkay={(data) => {
						setEditDialogOpen(false);
						if (!data) return;
						const {name, description} = data;
						setDisplayName(name);
						setDescription(description);
						action?.onOkay?.(data);
					}}
				/>
			)}
			<Grid container justifyContent='space-between' alignItems='center'>
				<Grid container item sx={{width: '900px'}}>
					<Grid
						container
						item
						flexDirection='row'
						alignItems='center'
						flexWrap='nowrap'>
						<DNBTypography variant='h5'>{displayName}</DNBTypography>
						<DNBIconButton
							size='default'
							disabled={!canEdit}
							onClick={() => setEditDialogOpen(true)}>
							<EditOutlinedIcon />
						</DNBIconButton>
					</Grid>
					{description && (
						<Grid
							container
							item
							flexDirection='row'
							alignItems='center'
							flexWrap='nowrap'>
							<DNBTypography
								variant='compact-body'
								title={description}
								noWrap
								color={(theme) => theme.colors.ColorGraySecondary}>
								{description}
							</DNBTypography>
						</Grid>
					)}
				</Grid>
				<Grid container item justifyContent='flex-end' xs='auto' gap={2}>
					{buttons.map(({title, ...others}) => (
						<DNBButton key={title} title={title} {...others}>
							{title}
						</DNBButton>
					))}
				</Grid>
			</Grid>
		</>
	);
};

export type {INameDescriptionData, INameDescriptionHeader, IEditDialog};
export {NameDescriptionHeader};
