import React, {ReactTooltip} from 'common/react-vendor';
import {
	CommonMultipleSelectGroup,
	ICommonMultipleSelectGroup,
} from '../../AddConnection/Component/Common/CommonMultipleSelectGroup';
import {ConnectionSearch} from '../Search/ConnectionSearch';
import './CustomizeColumnContextMenu.scss';
import Styles from './CustomizeColumnContextMenu.module.scss';

const CustomizeColumnContextMenuId = 'CustomizeColumnContextMenuId';

const CustomizeColumnContextMenu = ({
	optionList,
	onOptionChanged,
}: ICommonMultipleSelectGroup): React.ReactElement => {
	const [search, setSearch] = React.useState('');
	return (
		<ReactTooltip
			id={CustomizeColumnContextMenuId}
			effect='solid'
			arrowColor='transparent'
			globalEventOff='mousedown'
			clickable
			event='click'
			place='bottom'
			offset={{top: 10, left: 120}}>
			<div className={Styles.CustomizeColumnContextMenu}>
				<div className={Styles.Header}>Customize Columns</div>
				<ConnectionSearch
					search={search}
					placeHolder='Filter by Name'
					onSearchChanged={setSearch}
				/>
				<CommonMultipleSelectGroup
					optionList={optionList}
					onOptionChanged={onOptionChanged}
					search={search}
				/>
			</div>
		</ReactTooltip>
	);
};

export {CustomizeColumnContextMenu, CustomizeColumnContextMenuId};
