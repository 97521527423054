import React from 'common/react-vendor';
import classNames from 'classnames';
import 'common/widgets/table/table.scss';
import {
	IColumnConfig,
	IConnectionHeaderComponent,
} from '../../Data/ConnectionConst';
import {IConnectionSortBy, SortDirection} from '../ConnectionSortBy';
import {
	FieldType,
	FieldTypeConfig,
	SystemNameDisplayName,
} from '../../Data/FieldType';
import {TableCustomStyleConfig} from '../../Data/ConnectionTableConst';
import styles from './ConnectionTableItem.module.scss';
import {IRBACMap} from '../../RBAC/EIFRBAC';
import {GenericFetcherContext} from '../../Configuration/Context/GenericFetcherContext';
import {ObjectType} from '../../Data/ConnectionType';
import {GenericFetcherContextType} from '../../Configuration/Context/GenericFetcherContextConst';

interface IConnectionTableHeaderComponent {
	name: string;
	sortBy?: IConnectionSortBy;
}

const NameTableHeaderComponent = ({
	name,
	sortBy,
}: IConnectionTableHeaderComponent): React.ReactElement => {
	if (sortBy) {
		const {
			option,
			optionList,
			onSortOptionChanged,
			sortDirection,
			onSortDirectionChanged,
		} = sortBy;
		const itemTitle =
			name === SystemNameDisplayName
				? FieldTypeConfig[FieldType.SystemName]
				: name;
		let className = classNames('sortIcon', 'notSortingBy', 'fa fa-sort');
		// If we're sorting by the current itemKey, show the current sort direction
		if (itemTitle === option.title) {
			className = classNames('sortIcon', `fa fa-sort-${sortDirection}`);
		}
		return (
			<div
				className={styles.NameTableHeaderComponent}
				onClick={() => {
					const currentOption = optionList.find(
						({title}) => title === itemTitle
					);
					if (!currentOption) return;
					onSortOptionChanged(currentOption);
					onSortDirectionChanged(
						sortDirection === SortDirection.Ascend
							? SortDirection.Descend
							: SortDirection.Ascend
					);
				}}>
				<span className={styles.Text} title={name}>
					{name}
				</span>
				<i className={className} />
			</div>
		);
	}
	return <span title={name}>{name}</span>;
};

interface IConnectionTableItem {
	columnConfigList: IColumnConfig[];
	headerProps?: IConnectionHeaderComponent;
	data?: ObjectType;
	row: number;
	onItemClicked?: (data?: ObjectType) => void;
}

const ConnectionTableItem = ({
	columnConfigList,
	headerProps,
	data,
	row,
	onItemClicked,
}: IConnectionTableItem): React.ReactElement => {
	const {
		data: {DRILL: enableAction},
	} = React.useContext(
		GenericFetcherContext<IRBACMap>(GenericFetcherContextType.IRBACMap)
	);
	const FixColumnSize = 48;
	const totalSize = columnConfigList
		.filter(({hide}) => !hide)
		.reduce((pre, {size}) => {
			return pre + size;
		}, 0);
	let currentSize = 0;
	const maxInfo = {maxSize: 0, maxIndex: 0};
	const sizeList = Object.values(columnConfigList)
		.filter(({hide}) => !hide)
		.map(({size, ...others}, index) => {
			const newSize = Math.round((size / totalSize) * FixColumnSize);
			const {maxSize} = maxInfo;
			if (newSize > maxSize) {
				maxInfo.maxSize = newSize;
				maxInfo.maxIndex = index;
			}
			currentSize += newSize;
			return {size: newSize, ...others};
		});
	const {maxIndex} = maxInfo;
	sizeList[maxIndex]!.size += FixColumnSize - currentSize;
	const extraClassName = Object.keys(TableCustomStyleConfig).map((key) => {
		const customStyle = styles[key];
		return (
			customStyle && {
				[customStyle]: TableCustomStyleConfig[key]?.(data),
			}
		);
	});
	const isHeader = !!headerProps;
	return (
		<div
			className={classNames(
				`${styles.EIFCommonTable}`,
				'le-table-row',
				`row-${row}`,
				{
					'le-table-header': isHeader,
				},
				extraClassName
			)}>
			{sizeList.map(({size, key, HeaderComponent, Component}, col) => {
				return (
					size > 0 && (
						<ul
							key={key}
							className={classNames(
								'le-table-cell',
								`le-table-col-span-${size}`,
								`cell-${row}-${col}`,
								key
							)}
							onClick={() => {
								if (enableAction) {
									onItemClicked?.(data);
								}
							}}>
							<li className='le-table-cell-content'>
								{isHeader
									? HeaderComponent && <HeaderComponent {...headerProps} />
									: Component && <Component data={data} />}
							</li>
						</ul>
					)
				);
			})}
		</div>
	);
};

interface IConnectionTableHeader {
	columnConfigList: IColumnConfig[];
	headerProps: IConnectionHeaderComponent;
}

const ConnectionTableHeader = ({
	columnConfigList,
	headerProps,
}: IConnectionTableHeader): React.ReactElement => {
	return (
		<ConnectionTableItem
			columnConfigList={columnConfigList}
			headerProps={headerProps}
			row={0}
		/>
	);
};

export {ConnectionTableItem, ConnectionTableHeader, NameTableHeaderComponent};
export type {IConnectionTableItem, IConnectionTableHeader};
