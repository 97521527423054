import {cacheQueryResponse} from 'common/app/utilities/cacheUtility/cacheUtility';
import {BucketCmp, Period} from 'common/components/datacloud/query/query.enums';
import ConfigAttrQueries from './ConfigureAttributes.queries';
import {
	AttributeStep,
	ConfigurationAttributes,
	PrecheckResponse,
	PHPeriod,
	PurchaseHistory,
	SpendOvertimeSaveObject,
	DefaultOption,
	SpendOvertimeOption,
} from './ConfigureAttributes.types';

const EMPTY_CONFIG_ATTR = {
	options: {},
	saved: [],
};

let configAttr: ConfigurationAttributes = {...EMPTY_CONFIG_ATTR};

const clear = (): void => {
	configAttr = {...EMPTY_CONFIG_ATTR};
};

const getPurchaseHistory = async (): Promise<PurchaseHistory[]> =>
	// Cache is not good here, configAttr will be cleared every time we exit the page,
	// if we re-enter the page, we'll get old data from cache, not the newly submitted one.
	ConfigAttrQueries.getPurchaseHistory().then((result) => {
		configAttr.purchaseHistory = result;
		return result;
	});

const savePurchaseHistory = (): Promise<PurchaseHistory[]> => {
	return ConfigAttrQueries.savePurchaseHistory(configAttr.purchaseHistory!);
};

const getOptions = (): Record<string, SpendOvertimeOption | DefaultOption> =>
	configAttr.options;

const setOptions = <Options>(options: Options): void => {
	configAttr.options = {
		...configAttr.options,
		...options,
	};
};

const getSteps = (
	steps: Record<string, AttributeStep> = {}
): Record<string, AttributeStep> => {
	const data = configAttr.purchaseHistory;
	if (configAttr.steps) {
		return configAttr.steps;
	}
	const _data: Record<string, PurchaseHistory[]> = {};
	data!.forEach((item) => {
		_data[item.metrics] = _data[item.metrics] || [];
		_data[item.metrics]!.push(item);
	});
	const newSteps = {...steps};
	for (const step of Object.values(newSteps)) {
		const types = step.type.split(',');
		for (const type of Object.values(types)) {
			step.data = step.data || {};
			if (_data[type]) {
				step.data[type] = _data[type]!;
			}
		}
	}
	return newSteps;
};

const addSpendOvertimeSaveObject = (
	metric: string,
	_option: SpendOvertimeSaveObject
): void => {
	for (const opt of Object.values(_option)) {
		for (const [cmp, option] of Object.entries(opt)) {
			const vals = Object.values(option.Val).map((v) =>
				parseInt(v as string, 10)
			);
			vals.sort((a, b) => {
				return a - b;
			}); // sort this for backend

			const period: PHPeriod = {
				Cmp: (cmp !== 'null' ? cmp : BucketCmp.BETWEEN) as BucketCmp,
				Vals: vals,
				Period: option.Period as Period,
			};
			const timestamp = Number(new Date());
			const obj = {
				metrics: metric,
				periods: [period],
				type: 'PurchaseHistory',
				created: timestamp,
				updated: timestamp,
				eol: false,
				IsEOL: false,
			};

			if (period.Vals && period.Vals[0] && period.Vals[1]) {
				configAttr.purchaseHistory!.push(obj);
			}
		}
	}
};

const addDefaultSaveObject = (
	metric: string,
	_option: DefaultOption,
	addVals: boolean
): void => {
	const periods: PHPeriod[] = [];
	for (const [cmp, option] of Object.entries(_option)) {
		const period: PHPeriod = {
			Cmp: cmp as BucketCmp,
			Vals: [option.Val],
			Period: option.Period as Period,
		};
		if (addVals) {
			if (periods.length) {
				period.Vals = [
					(periods[0]!.Vals![0] as number) + 1,
					(periods[0]!.Vals![0] as number) + (period.Vals![0] as number),
				];
			}
		}
		if (period.Vals && Number.isInteger(period.Vals[0])) {
			periods.push(period);
		}
	}
	const timestamp = Number(new Date());
	const obj = {
		metrics: metric,
		periods,
		type: 'PurchaseHistory',
		created: timestamp,
		updated: timestamp,
		eol: false,
		IsEOL: false,
	};
	if (obj.periods.length) {
		configAttr.purchaseHistory!.push(obj);
	}
};

const setSaved = (step: string): void => {
	if (!configAttr.saved.includes(step)) {
		configAttr.saved.push(step);
	}
};

const saveSteps = (step: string): void => {
	for (const [metric, _option] of Object.entries(configAttr.options)) {
		configAttr.purchaseHistory = configAttr.purchaseHistory?.filter(
			(item) => item.metrics !== metric
		);
		if (metric === 'TotalSpendOvertime' || metric === 'AvgSpendOvertime') {
			addSpendOvertimeSaveObject(metric, _option);
		} else {
			addDefaultSaveObject(
				metric,
				_option as DefaultOption,
				metric === 'SpendChange'
			);
		}
	}
	configAttr.purchaseHistory = configAttr.purchaseHistory?.filter((ph) => ph);
	setSaved(step);
};

const getPrecheck = (): Promise<PrecheckResponse> =>
	cacheQueryResponse('getPrecheck', () =>
		ConfigAttrQueries.getPrecheck().then((result) => {
			configAttr.precheck = result;
			return result;
		})
	);

const getSaved = (): string[] => {
	return configAttr.saved;
};

export default {
	clear,
	getPurchaseHistory,
	savePurchaseHistory,
	getOptions,
	setOptions,
	getSteps,
	saveSteps,
	getPrecheck,
	getSaved,
};
