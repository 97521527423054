import React from 'common/react-vendor';
import {
	ApartmentIcon,
	ContactsOutlinedIcon,
	SellOutlinedIcon,
	DescriptionOutlinedIcon,
} from 'common/dnb-uux-vendor';
import {getAdminRoute, ROUTE_PAGE} from 'common/app/utilities/AdminPageUtility';
import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {
	isCDPPersonalizationEnabled,
	isEIFInboundEnabled,
	isTimeSeriesSegmentEnabled,
} from 'common/stores/tenantConfig';
import EventIcon from './Event';

const Dependencies = [
	'common.attributes.header',
	'common.attributes.subheader',
	'common.attributes.category',
	'common.attributes.controls',
	'common.attributes.filters',
	'common.attributes.reportstatus',
	'common.attributes.categories',
	'common.attributes.list',
	'common.attributes.enable',
	'common.attributes.schema',
	'common.attributes.activate',
	'common.attributes.edit',
	'common.attributes.groups',
	'common.attributes.sidebar',
	'common.attributes.attributesSidebar',
	'common.attributes.breadcrumbs',
	'common.attributes.personalization',
];

const States = ($stateProvider) => {
	$stateProvider.state('home.attributes', {
		url: '/attributes',
		params: {
			pageIcon: 'ico-attributes',
			pageTitle: 'Attribute Admin',
		},
		onEnter: ($state) => {
			if (!RBAC.hasAccess(RBACInterface.ATTR_ADMIN, RBACActions.VIEW)) {
				$state.go('home');
			}
		},
		resolve: {
			menus: () => {
				const hasSchemaAccess = RBAC.hasAccess(
					RBACInterface.EIF_CDP_SCHEMA,
					RBACActions.VIEW
				);
				const CDPPersonalizationEnabled = isCDPPersonalizationEnabled();
				const timeSeriesSegmentEnabled = isTimeSeriesSegmentEnabled();
				const hasPersonalizationAccess = RBAC.hasAccess(
					RBACInterface.PERSONALIZATION,
					RBACActions.VIEW
				);
				return [
					{
						sref: 'home.attributes.activate',
						label: 'Activate Attributes',
						info: 'These are premium or non-premium attributes that can be added before enabling them for specific features. Each comes with a default limit and you can increase the limit for premium attributes by purchasing with us.',
					},
					{
						sref: 'home.attributes.enable',
						label: 'Enable & Disable Attributes',
						info: 'Control which attributes are available for specific features such as Segmentation or Modeling.',
					},
					isEIFInboundEnabled() &&
						hasSchemaAccess && {
							sref: 'home.attributes.schema',
							label: 'My Attributes',
							expanded: true,
							subMenu: [
								{
									sref: 'home.attributes.schema.account',
									label: 'Accounts',
									icon: <ApartmentIcon />,
								},
								{
									sref: 'home.attributes.schema.contact',
									label: 'Contacts',
									icon: <ContactsOutlinedIcon />,
								},
								timeSeriesSegmentEnabled && {
									sref: 'home.attributes.schema.activity',
									label: 'Activities',
									icon: <EventIcon />,
								},
								{
									sref: 'home.attributes.schema.product',
									label: 'Products',
									icon: <SellOutlinedIcon />,
								},
								timeSeriesSegmentEnabled && {
									sref: 'home.attributes.schema.general',
									label: 'General',
									icon: <DescriptionOutlinedIcon />,
								},
							].filter(Boolean),
						},
					{
						sref: 'home.attributes.edit',
						label: 'Edit Name & Description',
						info: 'Customize the display name and descriptions for any first party attribute that would make most sense for your organization.',
					},
					{
						sref: getAdminRoute(ROUTE_PAGE.CONFIGURE_ATTRIBUTES),
						label: 'Product Spend Profile Attributes',
						disabled: !RBAC.hasAccess(
							RBACInterface.CONFIGURE_ATTR,
							RBACActions.VIEW
						),
					},
					{
						sref: 'home.attributes.groups',
						label: 'Attribute Groups',
						info: 'Ability to group large number of attributes together to ease operation such as Exporting a Segment.',
					},
					CDPPersonalizationEnabled &&
						hasPersonalizationAccess && {
							sref: 'home.attributes.personalization',
							label: 'Web Personalization Feed',
						},
				].filter(Boolean);
			},
		},
		views: {
			'breadcrumbs@': 'attributesBreadCrumb',
			'newSidebar@': 'attributesSidebar',
		},
		redirectTo: 'home.attributes.activate',
	});
};

angular.module('common.attributes', Dependencies).config(States);
