import LocalStorageUtility, {
	IClientSessionSession,
} from 'common/widgets/utilities/local-storage.utility';
import {
	Service,
	Right,
	RBACInterface,
	RBACActions,
	RBACCampaignTags,
	RBACModelTags,
	RBACJobTags,
	PredefinedRoles,
} from './RBAC.enums';

export type IAccessLevel = {name: PredefinedRoles; ordinal: number};

const ACCESS_LEVELS = Object.freeze({
	SALES: {name: 'SALES', ordinal: -1},
	EXTERNAL_USER: {name: 'EXTERNAL_USER', ordinal: 0},
	EXTERNAL_ADMIN: {name: 'EXTERNAL_ADMIN', ordinal: 1},
	INTERNAL_USER: {name: 'INTERNAL_USER', ordinal: 2},
	INTERNAL_ADMIN: {name: 'INTERNAL_ADMIN', ordinal: 3},
	SUPER_ADMIN: {name: 'SUPER_ADMIN', ordinal: 4},
}) as Record<PredefinedRoles, IAccessLevel>;

export const AccessGroups = Object.freeze({
	ALL: [
		PredefinedRoles.SALES,
		PredefinedRoles.EXTERNAL_USER,
		PredefinedRoles.EXTERNAL_ADMIN,
		PredefinedRoles.INTERNAL_USER,
		PredefinedRoles.INTERNAL_ADMIN,
		PredefinedRoles.SUPER_ADMIN,
	],
	EXCLUDE_SALES: [
		PredefinedRoles.EXTERNAL_USER,
		PredefinedRoles.EXTERNAL_ADMIN,
		PredefinedRoles.INTERNAL_USER,
		PredefinedRoles.INTERNAL_ADMIN,
		PredefinedRoles.SUPER_ADMIN,
	],
	INTERNAL_OR_ADMIN: [
		PredefinedRoles.EXTERNAL_ADMIN,
		PredefinedRoles.INTERNAL_USER,
		PredefinedRoles.INTERNAL_ADMIN,
		PredefinedRoles.SUPER_ADMIN,
	],
	INTERNAL: [
		PredefinedRoles.INTERNAL_USER,
		PredefinedRoles.INTERNAL_ADMIN,
		PredefinedRoles.SUPER_ADMIN,
	],
	ADMINS: [
		PredefinedRoles.EXTERNAL_ADMIN,
		PredefinedRoles.INTERNAL_ADMIN,
		PredefinedRoles.SUPER_ADMIN,
	],
	INTERNAL_ADMINS: [
		PredefinedRoles.INTERNAL_ADMIN,
		PredefinedRoles.SUPER_ADMIN,
	],
});

let rights: ISessionRights | undefined;
let statements: ISessionStatements | undefined;
let userAccessLevel: PredefinedRoles | undefined;

/* test helpers */
export const testReset = (): void => {
	rights = undefined;
	statements = undefined;
	userAccessLevel = undefined;
};

const fetchSession = (): IClientSessionSession | null =>
	LocalStorageUtility.getClientSession();

export interface ISessionStatementActionsTags
	extends Partial<Record<RBACActions, boolean>> {
	TAGS?: (RBACCampaignTags | RBACModelTags | RBACJobTags)[] | undefined;
}
export type ISessionStatements = Partial<
	Record<RBACInterface, ISessionStatementActionsTags>
>;

const getAvailableStatements = (): ISessionStatements => {
	if (!statements) {
		const constructedStatements: ISessionStatements = {};
		const statementsBackend = fetchSession()?.Statements || [];

		statementsBackend.forEach((s) => {
			if (s.effect === 'ACCEPT') {
				const statementActions: ISessionStatementActionsTags = {};

				s.actions.forEach((a) => {
					statementActions[a] = true;
				});

				statementActions.TAGS = [...s.tags];

				constructedStatements[s.rbacInterface] = statementActions;
			} else if (!constructedStatements[s.rbacInterface]) {
				constructedStatements[s.rbacInterface] = {};
			}
		});

		statements = constructedStatements;
	}

	return statements;
};

export const userHasRBACEnabled = (): boolean => {
	return Boolean(fetchSession()?.RoleLabel);
};

export const userHasOldPredefinedRoles = (): boolean => {
	const roleLabel = fetchSession()?.RoleLabel?.split('|')[0];
	return (
		roleLabel !== PredefinedRoles.SALES &&
		Object.values(PredefinedRoles).includes(roleLabel as PredefinedRoles)
	);
};

export type IHasAccess = (
	rbacInterface: RBACInterface,
	action: RBACActions
) => boolean;
const hasAccess: IHasAccess = (rbacInterface, action) => {
	// if statements are undefined, grant access
	// important to not restrict as we roll out the backend
	// Also, Temporary RBAC check for 2.0 roles during 3.0 migration
	// Exception for MANAGE_USERS/TEAMS Statements
	if (
		!userHasRBACEnabled() ||
		(userHasOldPredefinedRoles() &&
			![RBACInterface.MANAGE_TEAMS, RBACInterface.MANAGE_USERS].includes(
				rbacInterface
			))
	) {
		return true;
	}

	const availableStatements = getAvailableStatements();

	return Boolean(availableStatements?.[rbacInterface]?.[action]);
};

export type IHasTag = (
	rbacInterface: RBACInterface,
	tag: RBACCampaignTags | RBACModelTags | RBACJobTags
) => boolean;
const hasTag: IHasTag = (rbacInterface, tag) => {
	if (!userHasRBACEnabled() || userHasOldPredefinedRoles()) {
		return true;
	}

	const availableStatements = getAvailableStatements();

	return Boolean(availableStatements?.[rbacInterface]?.TAGS?.includes(tag));
};

const getAccessLevel = (level?: PredefinedRoles): IAccessLevel => {
	return (
		ACCESS_LEVELS[level ?? PredefinedRoles.SALES] ??
		ACCESS_LEVELS[PredefinedRoles.SALES] // catch level isn't defined in Access_levels
	);
};

const getUserAccessLevel = (): IAccessLevel => {
	if (!userAccessLevel) {
		userAccessLevel = fetchSession()?.AccessLevel;
	}
	return getAccessLevel(userAccessLevel);
};

const userHasAccessLevel = (levels: PredefinedRoles[]): boolean => {
	if (Array.isArray(levels)) {
		return levels.includes(getUserAccessLevel().name);
	}
	return false;
};

const userHasAtlasRBACAccess = (): boolean =>
	hasAccess(RBACInterface.MY_DATA, RBACActions.VIEW) ||
	hasAccess(RBACInterface.SEGMENTS, RBACActions.VIEW) ||
	hasAccess(RBACInterface.MODELS, RBACActions.VIEW) ||
	hasAccess(RBACInterface.CAMPAIGNS, RBACActions.VIEW) ||
	hasAccess(RBACInterface.CONNECTORS, RBACActions.VIEW) ||
	hasAccess(RBACInterface.REPORTING, RBACActions.VIEW);

const userHasAtlasAccess = (): boolean => {
	// Temporary RBAC check for 2.0 roles during 3.0 migration
	// Exception for MANAGE_USERS/TEAMS Statements
	if (userHasOldPredefinedRoles()) {
		return true;
	}

	return userHasRBACEnabled()
		? userHasAtlasRBACAccess()
		: userHasAccessLevel(AccessGroups.EXCLUDE_SALES);
};

export type ISessionRights = Record<Service, Record<Right, boolean>>;
const getAvailableRights = (): ISessionRights => {
	if (!rights) {
		rights = fetchSession()?.AvailableRights ?? ({} as ISessionRights);
	}
	return rights;
};

export type IHasRight = (right: Right, service: Service) => boolean;
const currentUserMay: IHasRight = (right, service) => {
	const availableRights = getAvailableRights();
	return Boolean(availableRights?.[service]?.[right]);
};

export default {
	hasAccess,
	hasTag,
	currentUserMay,
	userHasAccessLevel,
	userHasAtlasAccess,
	getUserAccessLevel,
	getAccessLevel,
	ACCESS_LEVELS,
};
