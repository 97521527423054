import React from 'common/react-vendor';
import UserValues from 'common/widgets/utilities/user-values.utility';

const {useState, useReducer, useRef, useEffect} = React;

const keyPrefix = UserValues.getLongFormTenantId();
export const getPrefixedKey = (key: string): string => `${keyPrefix}.${key}`;

// Usage:
// Outside of your component:
// const useMyState = createPersistedState('storageKey', initialState, optionalValidationFunction)
// Then within your component:
// const [state, setState] = useMyState();

export function createPersistedState<State>(
	key: string,
	init: State,
	storageArea = localStorage,
	isValid: (val: unknown) => val is State = alwaysValid
): () => [State, React.Dispatch<React.SetStateAction<State>>] {
	if (!isValid(init))
		throw new Error(
			'The initialState passed to createPersistedState was invalid according to the custom validator.'
		);
	const prefixedKey = getPrefixedKey(key);
	return () => {
		const [state, setState] = useState(() =>
			deserialize(storageArea.getItem(prefixedKey), init, isValid)
		);

		// Sync state with storageArea
		useEffect(() => {
			storageArea.setItem(prefixedKey, JSON.stringify(state));
		}, [state]);

		// Sync storageArea with state
		useEffect(() => {
			// A tab doesn't recieve storage events from itself, only other tabs, so
			// calling setState here doesn't cause an infinite loop
			const storageHandler = (e: StorageEvent): void => {
				if (e.storageArea !== storageArea || e.key !== prefixedKey) return;
				// If `e.newValue` is null, invalid json, or `isValid` returns false,
				// it will fall back to `init`
				const nextState = deserialize(e.newValue, init, isValid);
				setState(nextState);
			};
			window.addEventListener('storage', storageHandler);
			return () => window.removeEventListener('storage', storageHandler);
		}, []);

		return [state, setState];
	};
}

// Usage:
// Outside of your component:
// const useMyReducer = createPersistedReducer('storageKey', reducer, initialState, optionalValidationFunction)
// Then within your component:
// const [state, dispatch] = useMyReducer();
// When the value in storageArea changes, referential equality is lost

// This is a wrapper that exists so we can use `instanceof` for type narrowing
// in the union and determine whether to pass the action on to the
// user-supplied reducer or return the new state
// FIXME: maybe this should be renamed since it shares a name with
// `React.SetStateAction`
class SetStateAction<State> {
	// The constructor isn't useless; it's created by typescript
	// eslint-disable-next-line no-useless-constructor
	constructor(public readonly value: State) {}
}

export function createPersistedReducer<
	Reducer extends React.Reducer<
		React.ReducerState<Reducer>,
		React.ReducerAction<Reducer>
	>
>(
	key: string,
	reducer: Reducer,
	init: React.ReducerState<Reducer>,
	storageArea = localStorage,
	isValid: (val: unknown) => val is React.ReducerState<Reducer> = alwaysValid
): () => [
	React.ReducerState<Reducer>,
	React.Dispatch<React.ReducerAction<Reducer>>
] {
	if (!isValid(init))
		throw new Error(
			'The initialState passed to createPersistedReducer was invalid according to the custom validator.'
		);
	const prefixedKey = `${keyPrefix}.${key}`;
	const wrappedReducer = (
		state: React.ReducerState<Reducer>,
		action:
			| SetStateAction<React.ReducerState<Reducer>>
			| React.ReducerAction<Reducer>
	): React.ReducerState<Reducer> => {
		if (action instanceof SetStateAction) return action.value;
		return reducer(state, action);
	};
	return () => {
		const initialState = useRef<React.ReducerState<Reducer> | null>(null);
		if (!initialState.current)
			initialState.current = deserialize(
				storageArea.getItem(prefixedKey),
				init,
				isValid
			);
		// Current is non-null because it was defined on the line above
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const [state, dispatch] = useReducer(wrappedReducer, initialState.current!);

		// Sync state with storageArea
		useEffect(() => {
			storageArea.setItem(prefixedKey, JSON.stringify(state));
		}, [state]);

		// Sync storageArea with state
		useEffect(() => {
			// A tab doesn't recieve storage events from itself, only other tabs, so
			// calling setState here doesn't cause an infinite loop
			const storageHandler = (e: StorageEvent): void => {
				if (e.storageArea !== storageArea || e.key !== prefixedKey) return;
				// If `e.newValue` is null, invalid json, or `isValid` returns false,
				// it will fall back to `init`
				const nextState = deserialize(e.newValue, init, isValid);
				dispatch(new SetStateAction(nextState));
			};
			window.addEventListener('storage', storageHandler);
			return () => window.removeEventListener('storage', storageHandler);
		}, []);

		return [state, dispatch];
	};
}

// @ts-ignore: val isn't used by definition of `alwaysValid`
function alwaysValid<Value>(val: unknown): val is Value {
	return true;
}

function deserialize<Value>(
	serializedVal: string | null,
	fallback: Value,
	isValid: (val: unknown) => val is Value
): Value {
	if (serializedVal === null) return fallback;
	try {
		const val = JSON.parse(serializedVal);
		if (!isValid(val)) return fallback;
		// this ... merge is used to help us avoid errors while adding new attributes and combining with saved view
		return {...fallback, ...val};
	} catch {
		return fallback;
	}
}
