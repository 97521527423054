enum QueryType {
	Attributes,
	StatsTopN,
	StatsCubes,
}

enum EntityMapping {
	Account = 'Account',
	AccountActivityStream = 'AccountActivityStream',
	Contact = 'Contact',
	ContactActivityStream = 'ContactActivityStream',
}

export {QueryType, EntityMapping};
