import {BucketCmp, Period} from 'components/datacloud/query/query.enums';

const defaultPeriods = [
	{value: Period.Week, label: `${Period.Week}(s)`},
	{value: Period.Month, label: `${Period.Month}(s)`},
	{value: Period.Quarter, label: `${Period.Quarter}(s)`},
	{value: Period.Year, label: `${Period.Year}(s)`},
];
const allPeriods = [
	{value: Period.Day, label: `${Period.Day}(s)`},
	...defaultPeriods,
];

const cmpOptions = [
	{value: BucketCmp.EVER, label: 'Ever'},
	{value: BucketCmp.LATEST_DAY, label: 'Latest Day'},
	{value: BucketCmp.IN_CURRENT_PERIOD, label: 'Current'},
	{value: BucketCmp.WITHIN, label: 'Previous'},
	{value: BucketCmp.LAST, label: 'Last'},
	{value: BucketCmp.BETWEEN, label: 'Between Last'},
	{value: BucketCmp.BETWEEN_NEXT, label: 'Between Next'},
	{value: BucketCmp.BETWEEN_DATE, label: 'Between'},
	{value: BucketCmp.BEFORE_LAST, label: 'Before Last'},
	{value: BucketCmp.BEFORE, label: 'Before'},
	{value: BucketCmp.AFTER, label: 'After'},
	{value: BucketCmp.AFTER_NEXT, label: 'After Next'},
	{value: BucketCmp.IS_EMPTY_CAPITALIZE, label: 'Is Empty'},
];

const firstTimeFrameCmps = [
	BucketCmp.BETWEEN_NEXT,
	BucketCmp.AFTER_NEXT,
	BucketCmp.WITHIN,
	BucketCmp.BETWEEN,
	BucketCmp.BEFORE_LAST,
];
const secondTimeFrameCmps = [
	BucketCmp.BETWEEN_DATE,
	BucketCmp.BEFORE,
	BucketCmp.AFTER,
];

const toPeriodCmps = [BucketCmp.BETWEEN_NEXT, BucketCmp.BETWEEN];
const toTimeCmps = [BucketCmp.BETWEEN_DATE];

const periodNumberCmps = [
	...firstTimeFrameCmps,
	BucketCmp.LAST,
	BucketCmp.BEFORE_LAST,
	BucketCmp.BETWEEN,
];
const periodSelectCmps = [...firstTimeFrameCmps, BucketCmp.IN_CURRENT_PERIOD];

const allPeriodsCmps = [
	BucketCmp.BETWEEN_NEXT,
	BucketCmp.BETWEEN,
	BucketCmp.AFTER_NEXT,
	BucketCmp.BEFORE_LAST,
];

export {
	defaultPeriods,
	allPeriods,
	cmpOptions,
	firstTimeFrameCmps,
	secondTimeFrameCmps,
	toPeriodCmps,
	toTimeCmps,
	periodNumberCmps,
	periodSelectCmps,
	allPeriodsCmps,
};
