import React from 'common/react-vendor';
import {DNBButton, DNBDialog, theme} from '@dnb-uux-design-system/react';
import {CircularProgress} from '@mui/material';
import AttrConfigStore from '../AttrConfigStore';

interface AttrControlsProps {
	total: number;
	label: string;
	page: 'activate' | 'enable';
	save: () => void;
	overview: {Selections: {id: string; DisplayName: string; Limit: number}[]};
	section: string;
}

export const AttrControls: React.FC<
	React.PropsWithChildren<AttrControlsProps>
> = ({total, label, page, save, overview, section}: AttrControlsProps) => {
	const [confirmOpen, setConfirmOpen] = React.useState(false);
	const [saving, setSaving] = React.useState(AttrConfigStore.get('saving'));
	let limit = -1;

	const category = AttrConfigStore.get('category');

	if (page === 'enable') {
		limit = AttrConfigStore.getUsageLimit(overview, section) || -1;
	} else {
		const tab = overview.Selections.find(function (tab) {
			return (tab.id || tab.DisplayName) === category;
		});
		limit = tab?.Limit || -1;
	}
	AttrConfigStore.set('limit', limit);

	return (
		<>
			<DNBDialog
				onClose={() => {
					setConfirmOpen(false);
				}}
				open={confirmOpen}
				title='Activation'
				primaryCTA={() => {
					setConfirmOpen(false);
					setSaving(true);
					save();
				}}
				primaryCTALabel='Yes, Activate'
				secondaryCTA={() => {
					setConfirmOpen(false);
				}}
				secondaryCTALabel='Cancel'>
				<div>
					Once you activate these premium attributes, you won&apos;t be able to
					deactivate. Contact your D&B representative to upgrade your data
					licenses.
				</div>
			</DNBDialog>
			<span>
				{total} {limit > 0 && ` / ${limit} `} selected
			</span>
			<DNBButton
				sx={{ml: theme.spacing(3)}}
				onClick={() => {
					const payload = AttrConfigStore.generatePayload();

					if (page === 'activate' && payload.Select.length > 0) {
						setConfirmOpen(true);
					} else {
						setSaving(true);
						save();
					}
				}}
				endIcon={
					saving && (
						<CircularProgress
							aria-label='circular progress bar'
							variant='indeterminate'
							size={theme.spacing(5)}
						/>
					)
				}
				size='compact'
				disabled={saving || !AttrConfigStore.isChanged()}>
				{saving ? 'Saving...' : label}
			</DNBButton>
		</>
	);
};
