import React from 'common/react-vendor';
import styles from './tri-state-checkbox.module.scss';

export type TriStateCheckboxState = 'checked' | 'unchecked' | 'partial';

interface TriStateCheckboxProps {
	state: TriStateCheckboxState;
	label?: string;

	onClick(): void;
}

export const TriStateCheckbox = ({
	state,
	label,
	onClick,
}: TriStateCheckboxProps): React.ReactElement => {
	const icons = {
		checked: 'assets/svgs/icons/checkbox-checked.svg',
		unchecked: 'assets/svgs/icons/checkbox-unchecked.svg',
		partial: 'assets/svgs/icons/checkbox-partial.svg',
	};

	return (
		<div
			className={styles.triStateCheckbox}
			onClick={onClick}
			tabIndex={0}
			onKeyUp={(e) => {
				if (e.key === ' ' || e.key === 'Enter') {
					onClick();
				}
			}}>
			<img alt={state} src={icons[state]} />
			<div>{label}</div>
		</div>
	);
};
