import {LookupStoreActionType} from './enums';
import {LookupState, LookupAction} from './types';

const clearStore = (): LookupAction => {
	return {
		type: LookupStoreActionType.CLEAR_STORE,
	};
};

const setLookupPropertyValue = <LookupValue>(
	key: keyof LookupState,
	value: LookupValue
): LookupAction => {
	return {
		type: LookupStoreActionType.SET_LOOKUP_PROPERTY_VALUE,
		payload: {
			key,
			value,
		},
	};
};

export {clearStore, setLookupPropertyValue};
