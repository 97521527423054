import {ImportActionType} from './enums';
import type {
	ImportAction,
	TemplatesState,
	ImportState,
	ImportStoreState,
} from './types';

const importInitialState: ImportState = {
	fieldDocument: null,
	unmappedFields: null,
	accountIdState: {
		accountDedupeField: null,
		dedupeType: 'custom',
		fields: ['Id'],
	},
	validation: {
		ids: false,
		thirdpartyids: true,
		latticefields: false,
		matchtoaccounts: true,
		customfields: true,
		jobstatus: false,
		producthierarchy: false,
	},
	saveObjects: {},
	fieldDocumentSaved: {},
	thirdpartyidFields: {
		fields: [],
		map: [],
	},
	calendar: null,
};

const templatesInitialState: TemplatesState = {
	secondaryTemplates: null,
	templateAction: 'create-template',
	schema: null,
	entityType: null,
	feedType: null,
	templateData: null,
	postBody: null,
	autoImport: true,
	validationStatus: null,
	csvFileName: null,
	csvFile: null,
};

const initialState: ImportStoreState = {
	...importInitialState,
	...templatesInitialState,
};

const importReducer = (
	state = initialState,
	action: ImportAction
): ImportStoreState => {
	switch (action.type) {
		case ImportActionType.CLEAR_IMPORT_STATE:
			return {
				...state,
				...importInitialState,
			};
		case ImportActionType.SET_IMPORT_PROPERTY_VALUE:
			return {
				...state,
				[action.payload.key]: action.payload.value,
			};
		case ImportActionType.UPDATE_FIELD_DOCUMENT_VALUES: {
			if (state.fieldDocument) {
				return {
					...state,
					fieldDocument: {
						...state.fieldDocument,
						...action.payload,
					},
				};
			}

			return {
				...state,
			};
		}
		case ImportActionType.SET_FIELD_DOCUMENT_SAVED:
			return {
				...state,
				fieldDocumentSaved: {
					...state.fieldDocumentSaved,
					[action.payload.key]: action.payload.value,
				},
			};
		case ImportActionType.SET_VALIDATION:
			return {
				...state,
				validation: {
					...state.validation,
					[action.payload.key]: action.payload.value,
				},
			};
		default:
			return state;
	}
};

export {importReducer};
