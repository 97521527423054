import {ISegment} from 'atlas/data/SegmentConst';
import {
	API,
	ISubJourneyStage,
} from 'atlas/segmentation/SegmentDashboard/ApiCalls';
import {
	DuplicateDialog,
	DuplicateDialogTitle,
	DuplicateJourneyStageMessage,
} from 'atlas/JourneyStage/Component/Dialogs/DuplicateDialog';
import {
	ClearSegmentButton,
	RefreshCountButton,
	DuplicateSegmentButton,
	SaveStageButton,
} from 'atlas/segmentation/Components/SegmentTitleButtons';
import {
	clearSegment,
	saveSubJourneyStage,
} from 'atlas/segmentation/Helper/SegmentHelper';
import {updateSegmentCounts} from 'common/components/datacloud/segment/segment.queries';
import {SegmentCreate} from 'common/components/datacloud/segment/SegmentQueryHelper';
import {ExportDialog} from 'common/components/Dialog/ExportDialog';
import React from 'common/react-vendor';
import {getQueryProperty} from 'common/stores/query';
import {QueryState} from 'common/stores/query/types';
import {useSelector} from 'react-redux';
import {Box, DNBSnackbar} from 'common/dnb-uux-vendor';
import {JourneyStageEditDialog} from 'atlas/JourneyStage/Component/Dialogs/JourneyStageEditDialog';
import {NewJourneyStageData} from 'atlas/JourneyStage/Data/JourneyStageData';
import {SegmentNgState} from 'atlas/helpers/NavigateHelper';
import {IMessage} from 'atlas/segmentation/SegmentDashboard/cards/BuyerJourneyCard';
import {refreshCounts} from '../../query.helpers';
import {SegmentButtonGroup} from './SegmentButtonGroup';
import {ISubstageButtonGroup} from './query.button.group.ng';

const SubstageButtonGroup = ({
	$state,
	stage,
	subStage,
}: ISubstageButtonGroup): React.ReactElement => {
	const segment = getQueryProperty<ISegment>('segment') || {
		name: SegmentCreate,
	};
	const queryStore = useSelector((state: {query: QueryState}) => state?.query);
	const publicQueryStore = queryStore?.public || {};
	const isCreate = !subStage;
	const [isExportDialogOpen, setExportDialogOpen] = React.useState(false);
	const [isSaveStageOpen, setSaveStageOpen] = React.useState(false);
	const [isDuplicateDialogOpen, setDuplicateDialogOpen] = React.useState(false);
	const [isProcessing, setProcessing] = React.useState(false);
	const buttonList = React.useMemo(
		() =>
			isCreate
				? [
						{...ClearSegmentButton(), onClick: () => clearSegment()},
						{
							...SaveStageButton(),
							disabled: !publicQueryStore.enableSaveSegmentButton,
							onClick: () => setSaveStageOpen?.(true),
						},
				  ]
				: [
						{
							...RefreshCountButton(),
							disabled: publicQueryStore.enableSaveSegmentButton,
							onClick: () => {
								refreshCounts()
									.then(() => {
										updateSegmentCounts(segment.name);
									})
									.catch(() => undefined);
							},
						},
						{
							...DuplicateSegmentButton(),
							onClick: () => setDuplicateDialogOpen?.(true),
						},
						{
							...SaveStageButton(),
							disabled: !publicQueryStore.enableSaveSegmentButton,
							onClick: () => {
								if (!subStage) return;
								saveSubJourneyStage(segment.name, stage.id, {
									name: subStage.name,
									description: subStage.description,
									id: subStage.pid,
								});
								$state.go(
									SegmentNgState.Dashboard,
									{segment: segment.name},
									{reload: true}
								);
							},
						},
				  ],
		[
			$state,
			isCreate,
			publicQueryStore.enableSaveSegmentButton,
			segment.name,
			stage,
			subStage,
		]
	);
	const [message, setMessage] = React.useState<IMessage>({
		variant: 'success',
		snackbarText: '',
	});
	return (
		<Box m={6}>
			<DNBSnackbar
				variant={message.variant}
				snackbarText={message.snackbarText}
				isOpen={!!message.snackbarText}
				duration={3000}
				onClickCTA={() => {
					setMessage((pre) => ({...pre, snackbarText: ''}));
				}}
				handleClose={() => setMessage((pre) => ({...pre, snackbarText: ''}))}
			/>
			{stage && (
				<JourneyStageEditDialog
					data={isSaveStageOpen}
					setData={setSaveStageOpen}
					journeyStageData={NewJourneyStageData}
					onOkay={(data) => {
						if (!data) return;
						saveSubJourneyStage(segment.name, stage.id, data);
						$state.go(
							SegmentNgState.Dashboard,
							{segment: segment.name},
							{reload: true}
						);
					}}
				/>
			)}
			{subStage && (
				<DuplicateDialog
					title={DuplicateDialogTitle.Stage}
					description={DuplicateJourneyStageMessage(subStage.name)}
					isProcessing={isProcessing}
					data={isDuplicateDialogOpen}
					setData={setDuplicateDialogOpen}
					onOkay={async () => {
						const {
							buyerJourneyStage,
							name,
							description,
							segment,
							accountRestriction,
							contactRestriction,
						} = subStage;
						if (!buyerJourneyStage?.id) return;
						setProcessing(true);
						const {status} = await API.createOrUpdateSubJourneyStage({
							name: `${name} (Cloned)`,
							description,
							segmentName: segment?.name,
							buyerJourneyStageId: buyerJourneyStage.id,
							accountRestriction,
							contactRestriction,
						} as ISubJourneyStage);
						setProcessing(false);
						setMessage?.({
							variant: status === 200 ? 'success' : 'error',
							snackbarText: `Journey stage ${name} is duplicated ${
								status === 200 ? 'successfully' : 'fail'
							}`,
						});
					}}
				/>
			)}
			{isExportDialogOpen && (
				<ExportDialog data={isExportDialogOpen} setData={setExportDialogOpen} />
			)}
			<SegmentButtonGroup buttonList={buttonList} />
		</Box>
	);
};

export {SubstageButtonGroup};
