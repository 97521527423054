import React from 'common/react-vendor';
import {DNBCheckbox} from 'common/dnb-uux-vendor';
import classNames from 'classnames';
import './CommonCheckButton.scss';

interface ICommonCheckButton {
	checked: boolean;
	label: string;
	value?: string;
	className?: string;
	disabled?: boolean;
	onCheckChange: (val: string) => void;
}

const CommonCheckButton = ({
	checked,
	label,
	value,
	className = '',
	disabled,
	onCheckChange,
}: ICommonCheckButton): React.ReactElement => {
	return (
		<div id='checkboxContent' key={label} className={classNames(className)}>
			<DNBCheckbox
				name={label}
				size='small'
				id={label}
				label={label}
				checked={checked}
				disabled={disabled}
				onChange={() => {
					onCheckChange(value || label);
				}}
			/>
		</div>
	);
};

export {CommonCheckButton};
export type {ICommonCheckButton};
