import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import moment from 'moment';
import type {
	BucketRestriction,
	InputRangeConfig,
	Vals,
} from '../../../../query.types';
import {
	BucketType,
	TreeType,
	BucketCmp,
	TransactionKey,
	Entity,
} from '../../../../query.enums';
import {
	getEntity,
	getPeriodValue,
	getValue,
	GetValuesBasedOnPositionReturn,
	removeKey,
	getValues,
} from '../../tree.helpers';
import {changePurchaseHistoryCmp} from '../../tree-purchase-history.helpers';
import {timeConfig} from '../transactionStore.constants';
import {timeFromCmps, timeToCmps} from './transactionEditItem.constants';
import {PeriodData} from '../transactionStore.types';
import {SubType} from '../../tree.types';

interface GetTimeFramePeriodParams {
	bucketRestriction?: BucketRestriction;
	type?: BucketType | TreeType;
	periodList?: PeriodData[];
}
const getTimeFramePeriod = ({
	bucketRestriction,
	type,
	periodList,
}: GetTimeFramePeriodParams): string => {
	const periodTmp = getPeriodValue({
		bucketRestriction,
		type,
		subType: TransactionKey.Time,
	});

	if (periodTmp !== '') {
		return periodTmp;
	}

	if (!isEmpty(periodList)) {
		/**
		 * Since isEmpty checks that the value is not
		 * undefined | null | length === 0 we can
		 * safely do a non-null assertion.
		 */
		const [firstPeriodOption = {name: ''}] = periodList!;
		return firstPeriodOption.name;
	}

	return '';
};

interface GetTimeFrameConfigParams {
	bucketRestriction?: BucketRestriction;
	type?: BucketType | TreeType;
	timeCmp: BucketCmp | '';
}
const getTimeFrameConfig = ({
	bucketRestriction,
	type,
	timeCmp,
}: GetTimeFrameConfigParams): InputRangeConfig => {
	const isDateBucket =
		getPeriodValue({
			bucketRestriction,
			type,
			subType: TransactionKey.Time,
		}) === BucketType.Date;

	const fromInitial = isDateBucket
		? getValue({
				bucketRestriction,
				type,
				index: 0,
				subType: TransactionKey.Time,
		  })
		: undefined;

	const toInitial = isDateBucket
		? getValue({
				bucketRestriction,
				type,
				index: 1,
				subType: TransactionKey.Time,
		  })
		: undefined;

	const parseInitialValue = (
		value: GetValuesBasedOnPositionReturn
	): string | undefined =>
		value ? moment(value).format('YYYY-MM-DD') : undefined;

	return {
		...timeConfig,
		from: {
			...timeConfig.from,
			initial: parseInitialValue(fromInitial),
			visible: timeCmp ? timeFromCmps.includes(timeCmp) : false,
		},
		to: {
			...timeConfig.to,
			initial: parseInitialValue(toInitial),
			visible: timeCmp ? timeToCmps.includes(timeCmp) : false,
		},
	};
};

interface GetNumericalConfigValuesParams {
	bucketRestriction?: BucketRestriction;
	type?: BucketType | TreeType;
	subType: SubType;
}
interface GetNumericalConfigValuesReturn {
	fromValue: number | undefined;
	toValue: number | undefined;
}
const getNumericalConfigValues = ({
	bucketRestriction,
	type,
	subType,
}: GetNumericalConfigValuesParams): GetNumericalConfigValuesReturn => {
	const fromInitial = getValue({
		bucketRestriction,
		type,
		index: 0,
		subType,
	});

	const toInitial = getValue({
		bucketRestriction,
		type,
		index: 1,
		subType,
	});

	const parseInitialValue = (
		value: GetValuesBasedOnPositionReturn
	): number | undefined =>
		!isNil(value) && value >= 0 ? Number(value) : undefined;

	return {
		fromValue: parseInitialValue(fromInitial),
		toValue: parseInitialValue(toInitial),
	};
};

interface GetSubTypeConfigParams {
	bucketRestriction?: BucketRestriction;
	type?: BucketType | TreeType;
	subType: SubType;
	config: InputRangeConfig;
}
const getSubTypeConfig = ({
	bucketRestriction,
	type,
	subType,
	config,
}: GetSubTypeConfigParams): InputRangeConfig => {
	const {fromValue, toValue} = getNumericalConfigValues({
		bucketRestriction,
		type,
		subType,
	});

	return {
		...config,
		from: {
			...config.from,
			value: fromValue,
			max: toValue?.toString() || '',
		},
		to: {
			...config.to,
			value: toValue,
			min: fromValue?.toString() || '0',
		},
	};
};

interface UpdatePurchaseHistoryCmpParams {
	bucketRestriction?: BucketRestriction;
	type?: TreeType;
	value: BucketCmp;
	subType: SubType;
}
const updatePurchaseHistoryCmp = ({
	bucketRestriction,
	type,
	value,
	subType,
}: UpdatePurchaseHistoryCmpParams): BucketRestriction | undefined => {
	if (getEntity(bucketRestriction) === Entity.PurchaseHistory) {
		return changePurchaseHistoryCmp({
			bucketRestriction,
			type,
			value,
			subType,
		});
	}

	return bucketRestriction;
};

interface RemoveBucketKeyParams {
	bucketRestriction?: BucketRestriction;
	type?: TreeType;
	value: BucketCmp;
	subType: SubType;
}
const removeBucketKey = ({
	bucketRestriction,
	type,
	value,
	subType,
}: RemoveBucketKeyParams): BucketRestriction | undefined => {
	if (value === BucketCmp.ANY_CAPITALIZE) {
		return removeKey({
			bucketRestriction,
			type,
			subType,
		});
	}

	return bucketRestriction;
};

interface GetNumericalRangeValuesParams {
	bucketRestriction?: BucketRestriction;
	type?: TreeType;
	subType: SubType;
}
const getNumericalRangeValues = ({
	bucketRestriction,
	type,
	subType,
}: GetNumericalRangeValuesParams): Vals => {
	const rangeValues = getValues({
		bucketRestriction,
		type,
		subType,
	});

	return typeof rangeValues === 'string' ? [] : rangeValues;
};

export {
	getTimeFrameConfig,
	getTimeFramePeriod,
	getSubTypeConfig,
	updatePurchaseHistoryCmp,
	removeBucketKey,
	getNumericalRangeValues,
};
