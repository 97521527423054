export const ActionType = {
	IMPORT: 'Import',
	DELETE: 'Delete',
	SCORING: 'Scoring',
	SEGMENT_EDITED: 'Segment Edited',
	CURATED_ATTRIBUTES_ACTIVATED: 'Curated Attributes Activated',
	BUSINESS_CALENDAR_CHANGED: 'Business Calendar Changed',
	ATTRIBUTES_ACTIVATED: 'Attributes Activated',
	ATTRIBUTES_DEACTIVATED: 'Attributes Deactivated',
	REFRESH: 'Refresh',
};
Object.freeze(ActionType);

export const ActionMap = {
	'cdlDataFeedImportWorkflow': ActionType.IMPORT,
	'Import': ActionType.IMPORT,
	'cdlOperationWorkflow': ActionType.DELETE,
	'Delete': ActionType.DELETE,
	'Soft Delete': ActionType.DELETE,
	'Hard Delete': ActionType.DELETE,
	'Delete By Upload': ActionType.DELETE,
	'Delete By Daterange': ActionType.DELETE,
	'Replace': ActionType.DELETE,
	'Scoring': ActionType.SCORING,
	'Segment Edited': ActionType.SEGMENT_EDITED,
	'segmentChange': ActionType.SEGMENT_EDITED,
	'Curated Attributes Activated': ActionType.CURATED_ATTRIBUTES_ACTIVATED,
	'Business Calendar Changed': ActionType.BUSINESS_CALENDAR_CHANGED,
	'Attributes Activated': ActionType.ATTRIBUTES_ACTIVATED,
	'attributeManagementActivation': ActionType.ATTRIBUTES_ACTIVATED,
	'Attributes Deactivated': ActionType.ATTRIBUTES_DEACTIVATED,
	'attributeManagementDeactivation': ActionType.ATTRIBUTES_DEACTIVATED,
	'Data Cloud Refresh': ActionType.REFRESH,
	'Intent Data Refresh': ActionType.REFRESH,
};

export const ActionIconMap = {
	[ActionType.IMPORT]: 'ico ico-action-import',
	[ActionType.DELETE]: 'ico ico-action-delete',
	[ActionType.SCORING]: 'ico ico-action-scoring',
	[ActionType.SEGMENT_EDITED]: 'ico ico-action-segmentedited',
	[ActionType.CURATED_ATTRIBUTES_ACTIVATED]:
		'ico ico-action-curatedattributesupdated',
	[ActionType.BUSINESS_CALENDAR_CHANGED]:
		'ico ico-action-businesscalendarchanged',
	[ActionType.ATTRIBUTES_ACTIVATED]: 'ico ico-action-attributesactivated',
	[ActionType.ATTRIBUTES_DEACTIVATED]: 'ico ico-action-attributesdeactivated',
	[ActionType.REFRESH]: 'ico ico-action-refresh',
};

export const EntityType = {
	ACCOUNTS: 'Accounts',
	CONTACTS_LEADS: 'Contact/Leads',
	DNB_INTENT_DATA: 'D&B Buyer Intent',
	EVENTS: 'Event/Stream/Time Series',
	GENERAL_DATA_TYPE: 'General Data Type',
	SalesActivity: 'Sales Activity',
	G2Intent: 'G2 Buyer Intent',
	TrustRadiusIntent: 'Trust Radius Intent',
};
Object.freeze(EntityType);

export const EntityMap = {
	Accounts: EntityType.ACCOUNTS,
	Contacts: EntityType.CONTACTS_LEADS,
	Leads: EntityType.CONTACTS_LEADS,
	ProductPurchases: EntityType.EVENTS,
	ProductBundles: EntityType.GENERAL_DATA_TYPE,
	ProductHierarchy: EntityType.GENERAL_DATA_TYPE,
	WebVisit: EntityType.EVENTS,
	WebVisitPathPattern: EntityType.GENERAL_DATA_TYPE,
	WebVisitSourceMedium: EntityType.EVENTS,
	Opportunity: EntityType.EVENTS,
	OpportunityStageName: EntityType.EVENTS,
	MarketingActivity: EntityType.EVENTS,
	MarketingActivityType: EntityType.GENERAL_DATA_TYPE,
	CustomIntent: EntityType.DNB_INTENT_DATA,
	CampaignActivity: EntityType.GENERAL_DATA_TYPE,
	Campaign: EntityType.GENERAL_DATA_TYPE,
	Asset: EntityType.GENERAL_DATA_TYPE,
	AdGroup: EntityType.GENERAL_DATA_TYPE,
	Impression: EntityType.EVENTS,
	PerformanceReport: EntityType.EVENTS,
	SalesActivityEvent: EntityType.SalesActivity,
	SalesActivityTask: EntityType.SalesActivity,
	SalesActivityEventType: EntityType.SalesActivity,
	SalesActivityTaskType: EntityType.SalesActivity,
	G2Intent: EntityType.G2Intent,
	TrustRadiusIntent: EntityType.TrustRadiusIntent,
};

export const EntityIconMap = {
	[EntityType.ACCOUNTS]: 'ico ico-entity-accounts',
	[EntityType.CONTACTS_LEADS]: 'ico ico-entity-contactleads',
	[EntityType.DNB_INTENT_DATA]: 'ico ico-entity-dnbintentdata',
	[EntityType.EVENTS]: 'ico ico-entity-event',
	[EntityType.GENERAL_DATA_TYPE]: 'ico ico-entity-generaldatatype',
	[EntityType.SalesActivity]: 'ico ico-entity-salesactivity',
	[EntityType.G2Intent]: 'ico ico-entity-g2intent',
	[EntityType.TrustRadiusIntent]: 'ico ico-entity-trustradiusintent',
};

export const JobStatus = {
	PENDING: 'Pending',
	RUNNING: 'Running',
	COMPLETED: 'Completed',
	FAILED: 'Failed',
	CANCELLED: 'Cancelled',
	SKIPPED: 'Skipped',
	READY: 'Ready',
	PENDING_RETRY: 'Pending Retry',
	RETRIED: 'Retried',
	ENQUEUED: 'Enqueued',
	KILLED: 'Killed',
	PENDING_SUPPORT_REVIEW: 'Pending Support Review',
};
Object.freeze(JobStatus);

export const JobRunningFilter = [
	JobStatus.ENQUEUED,
	JobStatus.PENDING,
	JobStatus.RUNNING,
];

export const JobType = {
	DATA_PROCESSING_ANALYSIS: 'pa',
	MODEL: 'model',
	SEGMENT_EXPORT: 'export',
	ORPHAN_EXPORT: 'orphan',
};
Object.freeze(JobType);

export const WorkflowJobMap = {
	processAnalyzeWorkflow: JobType.DATA_PROCESSING_ANALYSIS,
	customEventModelingWorkflow: JobType.MODEL,
	fitModelWorkflow: JobType.MODEL,
	importMatchAndModelWorkflow: JobType.MODEL,
	modelAndEmailWorkflow: JobType.MODEL,
	crossSellImportMatchAndModelWorkflow: JobType.MODEL,
	segmentExportWorkflow: JobType.EXPORT_JOB_TYPES,
	entityExportWorkflow: JobType.EXPORT_JOB_TYPES,
	orphanRecordsExportWorkflow: JobType.ORPHAN_EXPORT,
};

export const JobWorkflowMap = {
	[JobType.DATA_PROCESSING_ANALYSIS]: ['processAnalyzeWorkflow'],
	[JobType.MODEL]: [
		'customEventModelingWorkflow',
		'importMatchAndModelWorkflow',
		'modelAndEmailWorkflow',
		'crossSellImportMatchAndModelWorkflow',
	],
	[JobType.SEGMENT_EXPORT]: ['segmentExportWorkflow', 'entityExportWorkflow'],
	[JobType.ORPHAN_EXPORT]: ['orphanRecordsExportWorkflow'],
};

// may add other subJobTypes when need
export const SubJobType = Object.freeze({
	DATA_REPLACE_JOB_TYPE: 'dataReplace',
	DATA_IMPORT_JOB_TYPE: 'cdlDataFeedImportWorkflow',
});
