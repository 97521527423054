const IconBasePath = '/atlas/assets/svgs/EIF/';

const EnableIcon = `${IconBasePath}ToggleOn.svg`;
const DisableIcon = `${IconBasePath}ToggleOff.svg`;

const EnableIcon2 = `${IconBasePath}ToggleOn2.svg`;
const DisableIcon2 = `${IconBasePath}ToggleOff2.svg`;

const LockedIcon = `${IconBasePath}Icon/Locked.svg`;

export {
	IconBasePath,
	EnableIcon,
	DisableIcon,
	EnableIcon2,
	DisableIcon2,
	LockedIcon,
};
