import {isEqual} from 'lodash';
import React, {Component, PropTypes} from 'common/react-vendor';
import {redux} from 'store';
import './le-button-toggles.scss';

export default class LeButtonToggles extends Component {
	constructor(props) {
		super(props);

		this.redux = redux({
			path: props.namespace || '@filter.buttontoggles',
			state: props.config,
			component: this,
			appCache: props.noAppCache ? null : ['values'], // save state & changes to local storage
		});
	}

	/**
	 * FIXME
	 * This is an initial fix for a deeper problem with redux and the item list component
	 * where the props and state are not in sync.
	 * A better fix should be done later when more time is allowed
	 */
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps?.config?.items?.length !== undefined &&
			!isEqual(nextProps?.config?.items, this.redux.get()?.items)
		) {
			this.redux.set({items: nextProps.config.items});
		}
	}

	id(index) {
		return `filter-list-option-${this.props.namespace}-${index}`;
	}

	checked(item) {
		const state = this.redux.get();
		let ret = false;

		(state.values || []).forEach((value) => {
			if (item.label === value.label) {
				ret = true;
			}
		});

		return ret;
	}

	click = (item) => {
		const state = this.redux.get();
		let index = -1;

		state.values.forEach((value, i) => {
			if (value.label == item.label) {
				index = i;
			}
		});

		if (index > -1) {
			state.values.splice(index, 1);
		} else {
			state.values.push(item);
		}

		this.redux.set(state);
	};

	renderItemImage({label, image, total}) {
		return image ? (
			<div className='image'>
				<img className={label} src={image} alt={label} />
			</div>
		) : (
			<i className={label} title={`${label}: ${total}`} />
		);
	}

	renderItems(items) {
		return items.map((item, index) => {
			if (item.if == undefined || item.if) {
				return (
					<li key={'LeButtonTogglesItem' + item.label} className={item.class}>
						<label htmlFor={this.id(index)} title={item.title}>
							<input
								type='checkbox'
								id={this.id(index)}
								onChange={() => this.click(item)}
								checked={this.checked(item)}
							/>
							<div className='item'>{this.renderItemImage(item)}</div>
						</label>
					</li>
				);
			}
		});
	}

	render() {
		if (!this.props || !this.props.config) {
			return '';
		}

		const state = this.redux.get();

		return (
			<div className={`filter-list le-button-toggles ${this.props.className}`}>
				<span className='filter-list-label'>{state.label}</span>
				<ul className='filter-list-container'>
					{this.renderItems(state.items)}
				</ul>
			</div>
		);
	}
}

LeButtonToggles.propTypes = {
	config: PropTypes.object.isRequired,
};
