import UserValues from 'common/widgets/utilities/user-values.utility';

const getSegmentExportDownloadLink = (exportId: string): string => {
	const tenantId = UserValues.getTenantId();

	return `/files/datacollection/segments/export/${exportId}/download?TenantId=${tenantId}`;
};

const getOrphanExportDownloadLink = (exportId: string): string => {
	const tenantId = UserValues.getTenantId();

	return `/files/datacollection/orphans/orphanexport/${exportId}?TenantId=${tenantId}`;
};

export {getOrphanExportDownloadLink, getSegmentExportDownloadLink};
