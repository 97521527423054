import {react2angular} from 'common/react-vendor';

import {SegmentEditHeader} from './segment.header';

angular
	.module('common.datacloud.tabs.segmenteditheader', [])
	.component(
		'segmentEditHeader',
		react2angular(SegmentEditHeader, ['vmSegment', 'onChange'], ['$state'])
	);
