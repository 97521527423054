// Hostnames used in production (all apps except Tenant Console)
export const productionHostnames = [
	'app.lattice-engines.com',
	'app2.lattice-engines.com',
];
export const developHostnames = ['testapp.lattice-engines.com'];

// Why check hostnames instead of process.env.NODE_ENV?
// process.env.NODE_ENV is set to "production" in staging environments (like testapp.lattice-engines.com)
// We want our QA team to be able to catch fatal errors in these staging environments, but we want
// these fatal errors to fail gracefully in "real" production.
export const isProductionHostname = (): boolean =>
	productionHostnames.includes(window.location.hostname);
