import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {cacheQueryResponse} from 'common/app/utilities/cacheUtility/cacheUtility';
import type {GetTenantConfigResponse} from './types';

/*
	Store '/pls/tenantconfig' in localStorage, so we won't call this api every time when page reload
   due to `setTenantConfigStore` in redux-store.js
*/
const getTenantConfig = async (
	refetch = false
): Promise<GetTenantConfigResponse> =>
	cacheQueryResponse(
		'tenantconfig',
		() =>
			axiosInstance
				.get<GetTenantConfigResponse>('/pls/tenantconfig')
				.then(({data}) => data),
		{
			refetch,
			storage: 'localStorage',
		}
	);

export {getTenantConfig};
