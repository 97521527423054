import {store, injectAsyncReducer} from 'store';
import {actions, reducer} from '../../widgets/banner/le-banner.redux';

class ReactMessagingService {
	constructor() {
		if (!ReactMessagingService.instance) {
			ReactMessagingService.instance = this;
			injectAsyncReducer(store, 'reactmessaging', reducer);
		}

		return ReactMessagingService.instance;
	}

	showBanner(response, options, callback) {
		const configuration = this.getConfiguration(response, options, callback);
		const type = configuration.type;

		switch (type) {
			case 'error':
				actions.error(store, configuration);
				break;
			case 'info':
				actions.info(store, configuration);
				break;
			case 'success':
				actions.success(store, configuration);
				break;
			case 'warning':
				actions.warning(store, configuration);
				break;
		}
	}

	getConfiguration(response, options) {
		const payload = response.data,
			uiAction = payload.UIAction || {},
			method = (uiAction.status || 'error').toLowerCase(),
			http_err = response.statusText,
			http_code = response.status,
			url = response.config ? response.config.url : '',
			message =
				uiAction.message || payload.errorMsg || payload.error_description;

		let title =
			typeof uiAction.title != 'undefined'
				? uiAction.title
				: http_code + ' "' + http_err + '" ' + url;

		if (response.status === 200 && payload.Errors) {
			title = payload.Errors.join();
		}

		return {...{title, message, type: method}, ...options};
	}
}

const instance = new ReactMessagingService();
Object.freeze(instance);

export default instance;
