import {GridRowSelectionModel} from 'common/dnb-uux-vendor';

enum PersonalizationType {
	Segment = 'display_name',
	SegmentType = 'dataType',
	Team = 'TeamName',
	Accounts = 'accounts',
	Contacts = 'contacts',
	LastUpdated = 'lastUpdated',
}

enum PersonalizationPageType {
	Processing = 'Processing',
	Steps = 'Steps',
}

enum HeaderType {
	Steps = 'Steps',
	Title = 'Title',
}

enum CommonHeaderSize {
	CompactBody = 'CompactBody',
	Subtitle2 = 'Subtitle2',
}

enum CommonHeaderMessage {
	Steps = 'Steps',
	Segment = 'Segment',
	AttributeGroup = 'AttributeGroup',
	Attributes = 'Attributes',
}

enum WorkflowStatus {
	PENDING = 'Pending',
	RUNNING = 'Running',
	COMPLETED = 'Completed',
	FAILED = 'Failed',
	CANCELLED = 'Cancelled',
	SKIPPED = 'Skipped',
	READY = 'Ready',
	PENDING_RETRY = 'Pending Retry',
	RETRIED = 'Retried',
	ENQUEUED = 'Enqueued',
	KILLED = 'Killed',
	PENDING_SUPPORT_REVIEW = 'Pending Support Review',
}

type IPersonalizationTypeConfig = {
	[key in PersonalizationType]: string;
};

interface IColumnConfig {
	field: PersonalizationType;
	headerName: string;
	width: number;
}

interface IOptions {
	label: string;
	value: string;
}

interface IAttributesConfig {
	Attribute: string;
	DisplayName: string;
	Entity: string;
	Category: string;
}

interface IPublishCustomerConfig {
	attributeSetName: string | null;
	segmentNames: GridRowSelectionModel;
}

interface IPersonalizationSegmentsConfig {
	numOfAccounts: number;
	segmentNames: GridRowSelectionModel;
	attributeGroup: {
		name: string;
	};
}

interface ISegmentListConfig {
	accounts: number;
	contacts: number;
	display_name: string;
	name: string;
	status: string;
	team: {
		TeamName: string;
	};
	listSegment: {
		systemType: string;
	};
	teamId: string;
	type: string;
	dataType: string;
	lastUpdated?: string | null;
}

interface IAttributeGroupConfig {
	displayName: string;
	name: string;
}

interface IPostPublishCustomerData {
	Result: string;
	Success: boolean;
}

interface IGetWorkflowStatusData {
	jobStatus?: WorkflowStatus;
	endTimestamp?: number;
}

export {
	PersonalizationType,
	PersonalizationPageType,
	HeaderType,
	CommonHeaderSize,
	CommonHeaderMessage,
	WorkflowStatus,
};
export type {
	IPersonalizationTypeConfig,
	IColumnConfig,
	IOptions,
	IAttributesConfig,
	IPublishCustomerConfig,
	IPersonalizationSegmentsConfig,
	ISegmentListConfig,
	IAttributeGroupConfig,
	IPostPublishCustomerData,
	IGetWorkflowStatusData,
};
