import {IActivationConnectionResponse} from 'atlas/connectors/EIF/Data/API/FE/ActivationResponse';
import React from 'common/react-vendor';
import {ApiType} from 'atlas/connectors/EIF/Data/API/ApiType';
import styles from 'atlas/connectors/EIF/Configuration/Import/Component/PriorityContentItem.module.scss';
import {CommonLabelSelect} from 'atlas/connectors/EIF/Configuration/Import/Component/CommonLabelSelect';
import {GenericFetcher} from 'atlas/connectors/EIF/Fetcher';
import {ObjectArray} from 'atlas/connectors/EIF/Data/ConnectionType';
import type {Team} from 'atlas/playbook/content/metadataLiveramplaunch/types';
import {FetcherContext} from '../../Context/FetcherContext';

const GetTenantTeamsUrl = '/pls/teams';

type TeamList = Team[] & ObjectArray;

const TeamItem = (): React.ReactElement => {
	const {data, setData} = React.useContext(FetcherContext);
	const {TeamId: team = ''} = data as IActivationConnectionResponse;

	return (
		<GenericFetcher<TeamList> apiType={ApiType.GetTenantTeams}>
			{(teamListData) => {
				const selectOptions = teamListData?.map(
					({TeamId = '', TeamName = ''}) => ({
						label: TeamName,
						value: TeamId,
					})
				);

				return (
					<div className={styles.PriorityContentItem}>
						<CommonLabelSelect
							label='Team'
							caption='Choose a team to assign this connection to.'
							placeholder='Select a team'
							option={team}
							optionList={selectOptions || []}
							onOptionChanged={(option) =>
								setData({...data, IsDirty: true, TeamId: option})
							}
						/>
					</div>
				);
			}}
		</GenericFetcher>
	);
};

export {TeamItem, GetTenantTeamsUrl};
