import React, {PropTypes} from 'common/react-vendor';
import './le-dropdown.scss';
import '../../../assets/sass/_tooltips.scss';

export const LEFT = 'left';
export const RIGHT = 'right';
const {useState, useEffect, useRef} = React;

export default function LeDropdown(props) {
	const [visible, setVisible] = useState(false);
	const [disabled] = useState(props.disabled || false);
	const dropdownRef = useRef();

	useEffect(() => {
		const clickHandler = (e) => {
			e.preventDefault();
			if (disabled || dropdownRef.current.contains(e.target)) {
				return;
			}
			setVisible(false);
		};

		if (visible) {
			document.addEventListener('click', clickHandler, {once: true});
		}
	}, [visible, disabled]);

	const createIcon = () => {
		return <i className={props.config.icon} />;
	};

	const getIconByPosition = (position) => {
		switch (position) {
			case LEFT:
				if (!props.config.iconside || position == props.config.iconside)
					return createIcon();
				break;
			case RIGHT:
				if (position == props.config.iconside) return createIcon();

				break;
		}
	};

	const getIcon = (position) => {
		return props.config.icon ? getIconByPosition(position) : null;
	};

	const getLabel = () => {
		if (props.config.label && props.config.label !== '') {
			return <span className='le-button-label'>{props.config.label}</span>;
		}
		return '';
	};

	const getClasses = () => {
		return `button ${props.config.classNames ? props.config.classNames : ''}${
			visible ? '-open' : ''
		} ${disabled ? 'disabled' : ''}`;
	};

	const renderItems = (items) => {
		return items.map((item) => {
			return item.visible ? (
				<li
					className='le-dropdown-list-item'
					key={'LeDropdownItem' + item.label.replace(/ /g, '')}
					onClick={() => {
						setVisible(false);
						item.open();
					}}>
					<span className={`${item.disabled ? 'disabled' : ''}`}>
						{item.label}
					</span>
				</li>
			) : null;
		});
	};

	return (
		<div ref={dropdownRef} className='le-dropdown'>
			<button
				onClick={() => setVisible(!visible)}
				disabled={disabled || props.config.disabled}
				className={getClasses()}>
				{getIcon(LEFT)}
				{getLabel()}
				<i className={`fa ${visible ? 'fa-caret-up' : 'fa-caret-down'}`} />
			</button>
			{visible && props.config.items.length > 0 ? (
				<ul className='le-dropdown-list'>{renderItems(props.config.items)}</ul>
			) : (
				''
			)}
		</div>
	);
}

LeDropdown.propTypes = {
	label: PropTypes.string,
	items: PropTypes.shape({
		label: PropTypes.string,
		open: PropTypes.func,
	}),
};

export const LeDropdownNoLeItemBarAdapter = (config) => {
	return <LeDropdown {...config} />;
};
