import {addDays, getWeekOfMonth} from 'date-fns';
import {
	CronSettingType,
	WeeklyShortType,
	WeeklyShortTypeConfig,
	WeeklyType,
	WeeklyTypeConfig,
} from '../Data/CronSettingType';
import {DailyDetailType} from '../Configuration/Import/Component/ScheduleDailyComponent';
import {
	MonthlyDetailType,
	WhichWeekType,
} from '../Configuration/Import/Component/ScheduleMonthlyComponent';

// Daily (Everyday / Every weekday)
// 0 0 6 ? * * *
// 0 0 6 ? * MON,TUE,WED,THU,FRI *
// Weekly (Every Monday)
// 0 0 6 ? * MON *
// Monthly (1st day / 1st week Monday)
// 0 0 6 1 * ? *
// 0 0 6 ? * 2#1 *	// monday#1st
// Seconds	Minutes	Hours	Day Of Month	Month	Day Of Week	Year
// https://www.freeformatter.com/cron-expression-generator-quartz.html
enum CronFieldType {
	Second = 'Second',
	Minute = 'Minute',
	Hour = 'Hour',
	DayOfMonth = 'DayOfMonth',
	Month = 'Month',
	DayOfWeek = 'DayOfWeek',
	Year = 'Year',
}

type CronExpression = {
	[key in CronFieldType]?: string;
};

const DefaultSecond = 0;
const DefaultMinute = 0;
const DefaultHour = 6;
const DefaultDay = 1;
const DefaultWeek = 2; // SUN = 1, MON = 2, etc...
const DefaultWhichWeek = 1; // First, Second, Third, Fourth
const WeekDay = 'MON,TUE,WED,THU,FRI';

const EverydayCronExpression: CronExpression = {
	[CronFieldType.Second]: `${DefaultSecond}`,
	[CronFieldType.Minute]: `${DefaultMinute}`,
	[CronFieldType.Hour]: `${DefaultHour}`,
	[CronFieldType.DayOfMonth]: '?',
	[CronFieldType.Month]: '*',
	[CronFieldType.DayOfWeek]: '*',
	[CronFieldType.Year]: '*',
};

const EverydayCronExpressionOf12Hour: CronExpression = {
	[CronFieldType.Second]: `${DefaultSecond}`,
	[CronFieldType.Minute]: `${DefaultMinute}`,
	[CronFieldType.Hour]: `${12}`,
	[CronFieldType.DayOfMonth]: '?',
	[CronFieldType.Month]: '*',
	[CronFieldType.DayOfWeek]: '*',
	[CronFieldType.Year]: '*',
};

const MonthlyPerDayCronExpression: CronExpression = {
	[CronFieldType.Second]: `${DefaultSecond}`,
	[CronFieldType.Minute]: `${DefaultMinute}`,
	[CronFieldType.Hour]: `${DefaultHour}`,
	[CronFieldType.DayOfMonth]: `${DefaultDay}`,
	[CronFieldType.Month]: '*',
	[CronFieldType.DayOfWeek]: '?',
	[CronFieldType.Year]: '*',
};

const MonthlyPerWeekCronExpression: CronExpression = {
	[CronFieldType.Second]: `${DefaultSecond}`,
	[CronFieldType.Minute]: `${DefaultMinute}`,
	[CronFieldType.Hour]: `${DefaultHour}`,
	[CronFieldType.DayOfMonth]: '?',
	[CronFieldType.Month]: '*',
	[CronFieldType.DayOfWeek]: `${DefaultWeek}#${DefaultWhichWeek}`,
	[CronFieldType.Year]: '*',
};

const fromCronExpression = (expression: string): CronExpression | null => {
	const tokens = expression?.split(' ');
	if (tokens?.length !== Object.keys(CronFieldType).length) {
		return null;
	}
	const result = Object.keys(CronFieldType).reduce((pre, key, index) => {
		return {...pre, [key as CronFieldType]: tokens[index]};
	}, {});
	return result;
};

const getCronSettingType = (
	cron: CronExpression | null
): CronSettingType | null => {
	if (!cron) {
		return null;
	}
	if (cron.DayOfWeek === '?') {
		return CronSettingType.Monthly;
	}
	if (cron.DayOfMonth === '?') {
		if (cron.DayOfWeek === '*') {
			return CronSettingType.Daily;
		}
		if (cron.DayOfWeek === WeekDay) {
			return CronSettingType.Daily;
		}
		if (
			Object.values(WeeklyTypeConfig).filter(({short}) => {
				return short === cron.DayOfWeek;
			}).length === 1
		) {
			return CronSettingType.Weekly;
		}
		if (cron.DayOfWeek?.split('#').length === 2) {
			return CronSettingType.Monthly;
		}
	}
	return CronSettingType.Daily;
};

const getDailyDetailType = (
	cronSettingType: CronSettingType,
	cron: CronExpression
): DailyDetailType => {
	if (cronSettingType === CronSettingType.Daily) {
		return cron.DayOfWeek === '*'
			? DailyDetailType.PerEveryDay
			: DailyDetailType.PerWeekDay;
	}
	return DailyDetailType.PerEveryDay;
};

const getWeeklyType = (
	cronSettingType: CronSettingType,
	cron: CronExpression
): WeeklyType => {
	if (cronSettingType === CronSettingType.Weekly) {
		const weeklyShortType = cron.DayOfWeek as WeeklyShortType;
		return WeeklyShortTypeConfig[weeklyShortType];
	}
	return WeeklyType.Monday;
};

// Index 0-6 maps to Date's day (Sunday/Monday/Tuesday/Wednesday/Thursday/Friday/Saturday)
const weeklyTypeMap = Object.values(WeeklyType);

const getWeeklyTypeOfTomorrow = (): WeeklyType =>
	weeklyTypeMap[(new Date().getDay() + 1) % 7]!;

const getMonthlyDetailType = (
	cronSettingType: CronSettingType,
	cron: CronExpression
): MonthlyDetailType => {
	if (cronSettingType === CronSettingType.Monthly) {
		return cron.DayOfWeek === '?'
			? MonthlyDetailType.PerDay
			: MonthlyDetailType.PerWeek;
	}
	return MonthlyDetailType.PerDay;
};

const getMonthlyDetailDayOfTomorrow = (): number =>
	addDays(new Date(), 1).getDate();

const getMonthlyDetailDay = (
	cronSettingType: CronSettingType,
	cron: CronExpression
): number => {
	const monthlyDetailType = getMonthlyDetailType(cronSettingType, cron);
	if (
		cronSettingType === CronSettingType.Monthly &&
		monthlyDetailType === MonthlyDetailType.PerDay &&
		cron.DayOfMonth
	) {
		return parseInt(cron.DayOfMonth, 10);
	}
	return getMonthlyDetailDayOfTomorrow();
};

// Index 0-4 maps to week 1-5
const whichWeeklyTypeMap = Object.values(WhichWeekType);

const getMonthlyWhichWeekDayOfTomorrow = (): [WhichWeekType, WeeklyType] => {
	const tomorrow = addDays(new Date(), 1);
	return [
		whichWeeklyTypeMap[getWeekOfMonth(tomorrow) - 1]!,
		weeklyTypeMap[tomorrow.getDay()]!,
	];
};

const getMonthlyWhichWeekDay = (
	cronSettingType: CronSettingType,
	cron: CronExpression
): [WhichWeekType, WeeklyType] => {
	const monthlyDetailType = getMonthlyDetailType(cronSettingType, cron);
	if (
		cronSettingType === CronSettingType.Monthly &&
		monthlyDetailType === MonthlyDetailType.PerWeek
	) {
		const tokens = cron.DayOfWeek?.split('#');
		if (tokens && tokens.length === 2) {
			const whichDay =
				Object.keys(WeeklyType)[parseInt(tokens[0] || '1', 10) - 1] ||
				WeeklyType.Monday;
			const whichWeek =
				Object.keys(WhichWeekType)[parseInt(tokens[1] || '1', 10) - 1] ||
				WhichWeekType.First;
			return [whichWeek as WhichWeekType, whichDay as WeeklyType];
		}
	}
	return getMonthlyWhichWeekDayOfTomorrow();
};

const toCronExpression = (cronExpression: CronExpression): string => {
	const {Second, Minute, Hour, DayOfMonth, Month, DayOfWeek, Year} =
		cronExpression;
	return `${Second} ${Minute} ${Hour} ${DayOfMonth} ${Month} ${DayOfWeek} ${Year}`;
};

const dailyCronExpression = (
	dailyDetailType: DailyDetailType
): CronExpression => {
	if (dailyDetailType === DailyDetailType.PerEveryDay) {
		return EverydayCronExpression;
	}
	return {...EverydayCronExpression, [CronFieldType.DayOfWeek]: WeekDay};
};

const weeklyCronExpression = (weeklyType: WeeklyType): CronExpression => {
	const {short} = WeeklyTypeConfig[weeklyType];
	return {
		...EverydayCronExpression,
		[CronFieldType.DayOfWeek]: short,
	};
};

const getTomorrowWeeklyCronExpression = (): string => {
	const newCronExpression = weeklyCronExpression(getWeeklyTypeOfTomorrow());
	return toCronExpression(newCronExpression);
};

const monthlyCronExpression = (
	monthlyDetailType: MonthlyDetailType,
	detailMonthDay: number,
	detailWhichWeek: WhichWeekType,
	detailWhichDay: WeeklyType
): CronExpression => {
	if (monthlyDetailType === MonthlyDetailType.PerDay) {
		return {
			...MonthlyPerDayCronExpression,
			[CronFieldType.DayOfMonth]: `${detailMonthDay}`,
		};
	}
	const whichWeek = Object.keys(WhichWeekType).indexOf(detailWhichWeek) + 1;
	const whichDay = Object.keys(WeeklyType).indexOf(detailWhichDay) + 1;
	return {
		...MonthlyPerWeekCronExpression,
		[CronFieldType.DayOfWeek]: `${whichDay}#${whichWeek}`,
	};
};

export {
	CronFieldType,
	EverydayCronExpression,
	EverydayCronExpressionOf12Hour,
	fromCronExpression,
	getCronSettingType,
	getDailyDetailType,
	getWeeklyType,
	getTomorrowWeeklyCronExpression,
	getMonthlyDetailType,
	getMonthlyDetailDay,
	getMonthlyWhichWeekDay,
	toCronExpression,
	dailyCronExpression,
	weeklyCronExpression,
	monthlyCronExpression,
	getWeeklyTypeOfTomorrow,
	getMonthlyDetailDayOfTomorrow,
	getMonthlyWhichWeekDayOfTomorrow,
};
