import React from 'common/react-vendor';
import storageUtil from 'common/widgets/utilities/app-storage.utility';
import {
	ArrowBackIcon,
	ArrowForwardIcon,
	DNBButton,
	DNBDialog,
	DNBDivider,
	DNBLink,
	DNBTypography,
} from 'common/dnb-uux-vendor';
import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import styles from './PersonalizationComponent.module.scss';
import {
	CommonHeaderMessageConfig,
	defaultBaseTotal,
	selectedLimitNumber,
	successMessage,
	SaveActivateDialogConfig,
	SuccessArray,
	PendingArray,
	FailedArray,
	pendingMessage,
	failedMessage,
} from '../configs/PersonalizationConst';
import PersonalizationSegment from './PersonalizationSegment';
import PersonalizationAttributeGroup from './PersonalizationAttributeGroup';
import {usePersonalization} from '../hook/usePersonalization';
import {postPublishCustomerData} from '../services/apiCalls';
import CommonHeader from './CommonHeader';
import {
	CommonHeaderMessage,
	CommonHeaderSize,
	HeaderType,
	PersonalizationPageType,
} from '../configs/PersonalizationTypes';
import {SuccessBanner, WorkflowStatusBanner} from './PersonalizationBanner';

export default function PersonalizationSteps(): React.ReactElement {
	const {useState} = React;
	const {
		context: personalizationContext,
		setContext: personalizationUpdateContext,
	} = usePersonalization();
	const {
		isBoarding,
		isStartModalShow,
		currentStep,
		selectionModel,
		total,
		groupOption,
		workflowData,
	} = personalizationContext;
	const {setIsStartModalShow, setCurrentStep, setPageType} =
		personalizationUpdateContext;

	const storageNamespace = 'webPersonalizationDialogStatus';
	const successStorageNamespace = 'webPersonalizationWorkflowSuccessBanner';
	const [isSaveDialogShow, setSaveDialogShow] = useState(false);
	const [showSuccessBanner, setShowSuccessBanner] = useState(true);
	const isClosedByHand = storageUtil.get(storageNamespace) || false;
	const isClosedSuccessBannerByHand =
		storageUtil.get(successStorageNamespace) || false;

	function backToPreviousStep(): void {
		setCurrentStep((previousStep) => previousStep - 1);
	}

	const publishCustomerFun = async (): Promise<void> => {
		const data = {
			segmentNames: selectionModel,
			attributeSetName: groupOption === '' ? null : groupOption,
		};
		await postPublishCustomerData(data);
	};

	function onSaveActivateConfirm(): void {
		setPageType(PersonalizationPageType.Processing);
		storageUtil.set(successStorageNamespace, false);
		publishCustomerFun();
	}

	// TODO: Do we want to change this link too?
	const LearnMoreUrl =
		'https://lattice-engines.zendesk.com/knowledge/articles/12335314865559/en-us?brand_id=360000325873';
	const gotoLearnMorePage = (): void => {
		window.open(LearnMoreUrl, '_blank');
	};

	const setIsStartModalStatus = (): void => {
		setIsStartModalShow(false);
		storageUtil.set(storageNamespace, true);
	};

	const setShowSuccessBannerStatus = (): void => {
		setShowSuccessBanner(false);
		storageUtil.set(successStorageNamespace, true);
	};

	const hasPersonalizationAccess = RBAC.hasAccess(
		RBACInterface.PERSONALIZATION,
		RBACActions.EDIT
	);

	return (
		<>
			<CommonHeader
				headerType={HeaderType.Title}
				size={CommonHeaderSize.Subtitle2}
				title={CommonHeaderMessageConfig[CommonHeaderMessage.Steps].title}
				description={
					CommonHeaderMessageConfig[CommonHeaderMessage.Steps].description
				}
			/>
			{SuccessArray.includes(workflowData.jobStatus!) &&
				showSuccessBanner &&
				!isClosedSuccessBannerByHand && (
					<SuccessBanner
						tipsBannerMessage={successMessage}
						onClose={() => setShowSuccessBannerStatus()}
					/>
				)}
			{PendingArray.includes(workflowData.jobStatus!) && (
				<WorkflowStatusBanner
					tipsBannerMessage={pendingMessage}
					variant='info'
				/>
			)}
			{FailedArray.includes(workflowData.jobStatus!) && (
				<WorkflowStatusBanner
					tipsBannerMessage={failedMessage}
					variant='error'
				/>
			)}
			<div className={styles.personalizationContainer}>
				<div className={styles.personalizationContainerContent}>
					{currentStep === 0 && <PersonalizationSegment />}
					{currentStep === 1 && <PersonalizationAttributeGroup />}
				</div>
			</div>
			<div className={styles.personalizationButtonGroup}>
				<DNBDivider variant='lightIncidental' />
				<div className={styles.personalizationButtons}>
					<div className={styles.personalizationButtonsLeft}>
						{currentStep === 1 && (
							<DNBButton
								size='compact'
								variant='secondary'
								onClick={() => backToPreviousStep()}
								startIcon={<ArrowBackIcon />}>
								Back
							</DNBButton>
						)}
					</div>
					{hasPersonalizationAccess && (
						<div className={styles.personalizationButtonsRight}>
							{currentStep === 0 ? (
								<DNBButton
									variant='primary'
									size='compact'
									disabled={
										selectionModel.length === 0 ||
										selectionModel.length > selectedLimitNumber ||
										total > defaultBaseTotal
									}
									onClick={() => setCurrentStep(1)}
									endIcon={<ArrowForwardIcon />}>
									Continue
								</DNBButton>
							) : (
								<DNBButton
									variant='primary'
									size='compact'
									sx={{ml: 3}}
									onClick={() => setSaveDialogShow(true)}>
									Save & Activate
								</DNBButton>
							)}
						</div>
					)}
				</div>
			</div>
			{!isBoarding && !isClosedByHand && (
				<DNBDialog
					size='compact'
					title='Welcome to Admin Page for Web Personalization Data'
					open={isStartModalShow}
					hasCloseButton
					// @ts-ignore, The parameter setting of the onClose method of DNBDialog is unreasonable, and MUI itself supports passing parameters here.
					onClose={(_event: unknown, reason: string) =>
						reason !== 'backdropClick' && setIsStartModalStatus()
					}
					primaryCTALabel='Get Started'
					primaryCTA={() => setIsStartModalStatus()}>
					<DNBTypography
						variant='compact-body'
						component='p'
						sx={{mb: 4, color: (theme) => theme.colors.ColorGrayPrimary}}>
						Web Personalization Data Feed Configuration creates a customized
						experiences for visitors of your website. Unlock the power of Rev.Up
						on-site personalization capability to target your prioritized
						audiences.
						<DNBLink
							size='small'
							underline='always'
							variant='inline'
							onClick={gotoLearnMorePage}
							sx={{ml: 1}}>
							Learn More
						</DNBLink>
					</DNBTypography>
				</DNBDialog>
			)}
			<DNBDialog
				size='compact'
				title='Save & Activate'
				open={isSaveDialogShow}
				hasCloseButton
				// @ts-ignore, The parameter setting of the onClose method of DNBDialog is unreasonable, and MUI itself supports passing parameters here.
				onClose={(_event: unknown, reason: string) =>
					reason !== 'backdropClick' && setSaveDialogShow(false)
				}
				primaryCTALabel='Yes'
				primaryCTA={() => onSaveActivateConfirm()}
				secondaryCTALabel='Cancel'
				secondaryCTA={() => setSaveDialogShow(false)}>
				<DNBTypography
					variant='compact-body'
					component='p'
					sx={{mb: 4, color: (theme) => theme.colors.ColorGrayPrimary}}>
					{groupOption === ''
						? SaveActivateDialogConfig.SkipAttributeGroups
						: SaveActivateDialogConfig.SelectedAttributeGroups}
				</DNBTypography>
				<DNBTypography
					variant='compact-body'
					component='p'
					sx={{color: (theme) => theme.colors.ColorGrayPrimary}}>
					Please be aware, based on the segment size, it may take about 10-20
					minutes for the API trigger to process the selected attributes.
				</DNBTypography>
			</DNBDialog>
		</>
	);
}
