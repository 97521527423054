import {getEntitiesCounts} from 'common/components/datacloud/query/query.helpers';
import {Query} from 'common/components/datacloud/datacloud.types';
import {ISegment} from 'atlas/data/SegmentConst';
import {getUserEmail} from 'atlas/ratingsengine/ratingsengine.helpers';
import {
	BucketCmp,
	Operator,
} from 'common/components/datacloud/query/query.enums';
import NgState from 'atlas/ng-state';
import {getPrefixedKey} from 'common/app/utilities/persistance';
import {
	Play,
	RatingModelsCoverageMap,
	TargetSegment,
} from './content/metadataLiveramplaunch/types';
import {
	getAccountsCount as getAccountsCountQuery,
	getPlayLaunchCountPlaySet as getPlayLaunchCountPlaySetQuery,
	getPlayLaunchCount as getPlayLaunchCountQuery,
	savePlay as savePlayQuery,
	playLaunchesPlaySet,
	getPlay as getPlayQuery,
	getRatingSegmentCounts,
	getRatingSegmentCountsByJourney,
} from './playbook.queries';
import type {
	GetAccountsDataQuery,
	GetPlayLaunchCountPlaySetParams,
	GetPlayLaunchCountParams,
	PlayLaunchesPlaySetParams,
	GetRatingSegmentCountsOpts,
} from './playbook.types';
import {
	dispatchPlaybookWizardPropertyValue,
	getPlaybookWizardProperty,
} from '../stores/playbookWizard';
import {LaunchesResponse} from './content/launchhistorytable/launchHistoryTable.types';
import {getPriorDateFromNowTimestamp} from './content/launchhistorytable/LaunchHistory.helpers';

export const getAccountsCount = async (
	query: Partial<GetAccountsDataQuery>
): Promise<number> => {
	if (query?.page_filter) {
		// eslint-disable-next-line no-param-reassign
		delete query.page_filter;
	}

	return getAccountsCountQuery(query).then((response) => response);
};

export const getPlayLaunchCountPlaySet = async (
	params: GetPlayLaunchCountPlaySetParams & {orgId?: string}
): Promise<string | number> => {
	const data = params.orgId
		? {
				orgIds: [params.orgId],
		  }
		: {};

	return getPlayLaunchCountPlaySetQuery(
		{
			playName: params.playName,
			launchStates: params.launchStates ?? '',
			startTimestamp: params.startTimestamp ?? 0,
			offset: params.offset ?? 0,
		},
		data
	).then((response) => response);
};

export const getPlayLaunchCount = async (
	params: GetPlayLaunchCountParams
): Promise<string | number> =>
	getPlayLaunchCountQuery({
		playName: params.playName,
		launchStates: params.launchStates ?? '',
		startTimestamp: params.startTimestamp ?? 0,
		offset: params.offset ?? 0,
		orgId: params.orgId ?? '',
		externalSysType: params.externalSysType ?? '',
	}).then((response) => response);

export const savePlay = async (opts: Play): Promise<string | Play> =>
	savePlayQuery({
		...opts,
		createdBy: opts.createdBy ?? getUserEmail(),
		updatedBy: getUserEmail(),
	}).then((data) => {
		dispatchPlaybookWizardPropertyValue('currentPlay', data);

		return data;
	});

export const getPlayLaunchesPlaySet = async (
	params: PlayLaunchesPlaySetParams & {orgId?: string}
): Promise<string | LaunchesResponse> => {
	const data = params.orgId
		? {
				orgIds: [params.orgId],
		  }
		: {};

	return playLaunchesPlaySet(
		{
			playName: params.playName,
			launchStates: params.launchStates ?? '',
			sortby: params.sortby,
			descending: params.descending,
			startTimestamp: 0,
			offset: params.offset ?? 0,
			max: params.max ?? 10,
			status: 'ACTIVE,DRAFT,ARCHIVED',
		},
		data
	).then((data) => data);
};

/**
 * Patch play with journey stage information.
 * @param Play Input play
 * @return Patched play
 * @remark If journey exists, replace targetSegment name/accounts/contacts
 * with buyerJourneyStage for UI display.
 */
export const patchPlayWithJourneyStage = (play: Play): Play => ({
	...play,
	targetSegment:
		play.buyerJourneyStage || play.buyerJourneySubStage
			? ({
					...play.targetSegment,
					display_name: [
						play.targetSegment?.display_name,
						play.buyerJourneyStage?.buyerJourneyMapName ||
							play.buyerJourneySubStage?.buyerJourneyStage?.buyerJourneyMapName,
						play.buyerJourneyStage?.name ||
							play.buyerJourneySubStage?.buyerJourneyStage?.name,
						play.buyerJourneySubStage?.name,
					]
						.filter((data) => !!data)
						.join('.'),
					accounts:
						play.buyerJourneyStage?.accounts ||
						play.buyerJourneySubStage?.accounts,
					contacts:
						play?.buyerJourneyStage?.contacts ||
						play.buyerJourneySubStage?.contacts,
			  } as TargetSegment)
			: play.targetSegment,
});

export const getPlay = async (play_name: string): Promise<string | Play> =>
	getPlayQuery(play_name).then((data) => {
		const newData =
			typeof data === 'object' ? patchPlayWithJourneyStage(data) : data;
		dispatchPlaybookWizardPropertyValue('currentPlay', newData);
		return newData;
	});

export const playbookWizardNextSaveAndGoto = async (
	nextState: string
): Promise<void> => {
	const settings = getPlaybookWizardProperty<Partial<Play>>('settings');

	const opts = settings;
	let changed = false;

	const currentPlay = getPlaybookWizardProperty<Play>('currentPlay');

	if (currentPlay?.name) {
		opts.name = currentPlay.name;
	}

	if (settings) {
		if (currentPlay) {
			// eslint-disable-next-line guard-for-in
			for (const key in settings) {
				const settingsProperty = key as keyof Play;

				const setting = settings[settingsProperty];

				if (currentPlay[settingsProperty] !== setting) {
					changed = true;
					break;
				}
			}
		} else {
			changed = true;
		}

		if (changed) {
			const {go} = NgState.getAngularState() ?? {};

			return savePlay(opts).then(function (play) {
				if (play && typeof play === 'object' && play.name) {
					go(nextState, {play_name: play.name}, {reload: true});
				}
			});
		}
	}
};

// #region launchAccountsCoverage
type LaunchAccountsCoverageResponse = {
	engineId?: string;
	errorMap?: Record<string, string>;
	ratingModelsCoverageMap: RatingModelsCoverageMap;
};

type MapCoverageMapCounts = {
	accounts: number;
	contacts: number;
};

const mapCoverageMap = (
	counts: MapCoverageMapCounts
): LaunchAccountsCoverageResponse => {
	return {
		errorMap: {},
		ratingModelsCoverageMap: {
			accountCount: 0,
			unscoredAccountCount: counts.accounts,
			contactCount: 0,
			unscoredContactCount: counts.contacts,
			bucketCoverageCounts: [],
		},
	};
};

const getRatingCount = async (
	data: Play,
	opts: LaunchAccountsCoverageOpts
): Promise<LaunchAccountsCoverageResponse> => {
	const {sendEngineId, getDestinationAccountId = null} = opts;

	const currentPlay = getPlaybookWizardProperty<Play>('currentPlay');

	const engineId = data?.ratingEngine?.id ?? '';

	const getExcludeItems = opts.getExcludeItems ?? false;

	const getSegmentsOpts: GetRatingSegmentCountsOpts = {
		loadContactsCount: true,
		loadContactsCountByBucket: true,
	};

	if (getExcludeItems) {
		getSegmentsOpts.lookupId = getDestinationAccountId;
		getSegmentsOpts.restrictNullLookupId = true;
	}

	const segmentName = currentPlay?.targetSegment?.name ?? '';
	const isBuyerJourney =
		!!data?.buyerJourneyStage || !!data?.buyerJourneySubStage;
	const isSubJourney = !!data?.buyerJourneySubStage;
	const getRating = isBuyerJourney
		? () =>
				getRatingSegmentCountsByJourney(
					segmentName,
					[engineId],
					isSubJourney
						? data?.buyerJourneySubStage?.pid
						: data.buyerJourneyStage?.id,
					isSubJourney ? data?.buyerJourneyStage?.id : undefined
				)
		: () => getRatingSegmentCounts(segmentName, [engineId], getSegmentsOpts);
	return getRating().then((result) => {
		if (typeof result === 'object') {
			const accountsCoverage: LaunchAccountsCoverageResponse = {
				errorMap: result.errorMap,
				ratingModelsCoverageMap: result.ratingModelsCoverageMap
					? result.ratingModelsCoverageMap[
							Object.keys(result.ratingModelsCoverageMap)[0]!
					  ]!
					: {},
			};

			if (sendEngineId) {
				accountsCoverage.engineId = engineId;
			}

			return accountsCoverage;
		}

		const segment = currentPlay?.targetSegment;

		return mapCoverageMap({
			accounts: segment?.accounts ?? 0,
			contacts: segment?.contacts ?? 0,
		});
	});
};

const getEntityExcludeItems = async (
	segment: ISegment
): Promise<LaunchAccountsCoverageResponse> => {
	const template: Query = {
		account_restriction: {
			restriction: {
				logicalRestriction: {
					operator: Operator.AND,
					restrictions: [
						segment?.account_restriction?.restriction,
						{
							bucketRestriction: {
								attr: 'Account.',
								bkt: {
									Cmp: BucketCmp.IS_NOT_NULL,
									Id: 1,
									Vals: [],
								},
								ignored: false,
							},
						},
					],
				},
			},
		},
		page_filter: {
			num_rows: 10,
			row_offset: 0,
		},
	};

	return getEntitiesCounts(template).then(function (result) {
		return mapCoverageMap({
			accounts: result.Account || 0,
			contacts: result.Contact || 0,
		});
	});
};

type LaunchAccountsCoverageOpts = {
	sendEngineId: boolean;
	getExcludeItems: boolean;
	getDestinationAccountId: string;
};
export const launchAccountsCoverage = async (
	play: Play,
	opts: LaunchAccountsCoverageOpts
): Promise<LaunchAccountsCoverageResponse> =>
	new Promise((resolve) => {
		if (
			typeof play === 'object' &&
			play?.ratingEngine &&
			play?.ratingEngine?.id
		) {
			resolve(getRatingCount(play, opts));
			return;
		}

		const currentPlay = getPlaybookWizardProperty<Play>('currentPlay');

		const segment = currentPlay?.targetSegment;

		const getExcludeItems = opts.getExcludeItems ?? false;

		if (getExcludeItems) {
			resolve(getEntityExcludeItems(segment as unknown as ISegment));
			return;
		}

		resolve(
			mapCoverageMap({
				accounts: segment?.accounts ?? 0,
				contacts: segment?.contacts ?? 0,
			})
		);
	});
// #endregion launchAccountsCoverage

export const getLaunchHistoryStartTime = (): number => {
	const prefixedKey = getPrefixedKey('launchHistoryTableView');
	const store = localStorage.getItem(prefixedKey);
	if (store) {
		const {startTimestamp} = JSON.parse(store);
		return startTimestamp || getPriorDateFromNowTimestamp();
	}
	return getPriorDateFromNowTimestamp();
};
