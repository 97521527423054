import moment from 'moment';
import Pikaday from 'pikaday';
import {TransactionKey} from '../../../../query.enums';
import type {InputRangeConfig, InputChangeData} from '../../../../query.types';
import {DATE_FORMAT} from './DateRange';

interface OnSelectDateParams {
	date: Date;
	index: number;
	setState(state: string): void;
	onChange(input: InputChangeData): void;
}

const onSelectDate = ({
	date,
	index,
	setState,
	onChange,
}: OnSelectDateParams): void => {
	const newDate = moment(date).format(DATE_FORMAT);

	setState(newDate);
	onChange({index, value: newDate, type: TransactionKey.Time});
};

interface CreatePickadayParams {
	field: HTMLInputElement;
	index: number;
	setState(state: string): void;
	onChange(input: InputChangeData): void;
}

const createPickaday = ({
	field,
	index,
	setState,
	onChange,
}: CreatePickadayParams): Pikaday => {
	return new Pikaday({
		field,
		format: DATE_FORMAT,
		onSelect: (date) =>
			onSelectDate({
				date,
				index,
				setState,
				onChange,
			}),
	});
};

const resetDatePicker = (config: InputRangeConfig): InputRangeConfig => {
	return {
		...config,
		from: {
			...config.from,
			value: undefined,
			initial: undefined,
		},
		to: {
			...config.to,
			value: undefined,
			initial: undefined,
		},
	};
};

export {createPickaday, resetDatePicker, onSelectDate};
