import {useCache} from 'common/app/hooks/useCache';
import React from 'common/react-vendor';
import {IDateTimeFormatAPIResponse} from '../schemaTypes';
import {getDateTimeFormats} from '../services/apiCalls';

enum FormatTypes {
	Timezones = 'timezones',
	DateFormats = 'dateFormats',
	TimeFormats = 'timeFormats',
}

type DateTimeOption = {
	text: string;
	value: string;
};

interface DateTimeFormatContext {
	[FormatTypes.Timezones]: DateTimeOption[];
	[FormatTypes.DateFormats]: DateTimeOption[];
	[FormatTypes.TimeFormats]: DateTimeOption[];
}
const {createContext, useContext} = React;

const defaultFormats: DateTimeFormatContext = {
	[FormatTypes.Timezones]: [],
	[FormatTypes.DateFormats]: [],
	[FormatTypes.TimeFormats]: [],
};

export const DateTimeFormatsContext =
	createContext<DateTimeFormatContext>(defaultFormats);

function getTimeZoneText(timezoneValue: string): string {
	return timezoneValue === 'Time Zone is Part of Value'
		? 'ISO 8601 (Time Zone is Part of Value)'
		: timezoneValue;
}

export const DateTimeFormatsProvider: React.FC<
	React.PropsWithChildren<unknown>
> = ({children}: {children?: React.ReactNode}) => {
	const {data: formats = defaultFormats} = useCache('dateFormats', () =>
		getDateTimeFormats().then(({data}: {data: IDateTimeFormatAPIResponse}) => {
			if (data.Success) {
				const {timezones, date_format, time_format} = data.Result;
				return {
					timezones: timezones.map((timezone) => ({
						text: getTimeZoneText(timezone),
						value: timezone,
					})),
					dateFormats: date_format.map((dateFormat) => ({
						text: dateFormat,
						value: dateFormat,
					})),
					timeFormats: time_format.map((timeFormat) => ({
						text: timeFormat,
						value: timeFormat,
					})),
				};
			}
		})
	);

	return (
		<DateTimeFormatsContext.Provider value={{...formats}}>
			{children}
		</DateTimeFormatsContext.Provider>
	);
};

export const useDateTimeFormats = (): DateTimeFormatContext =>
	useContext(DateTimeFormatsContext);
