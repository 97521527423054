import {
	DNBButton,
	DNBCheckbox,
	DNBRadio,
	DNBRadioGroup,
	DNBSelect,
	DNBSelectOption,
	DNBTypography,
} from '@dnb-uux-design-system/react';
import {CircularProgress} from '@mui/material';
import {AttributeSet} from 'atlas/connectors/EIF/Configuration/Activation/Component/FieldMapping/types/attributeGroup.types';
import {GenericFetcher} from 'atlas/connectors/EIF/Fetcher';
import {
	FreeStyleDialogContent,
	FreeStyleStickyFooter,
	getFreeStyleDialog,
} from 'common/composite-uux-components/dialog-drawer/DialogDrawer';
import React from 'common/react-vendor';
import {IEntityType} from '../datacloud/journeystage/journey.helpers';
import {isListSegment} from '../datacloud/query/results/rebuild';
import {showExportBanner} from '../datacloud/tabs/subheader/SubheaderUtility';
import {IAttributeGroup, IExportDialog} from './ExportDialog';
import {
	exportSegment,
	getAttributeMapUrl,
	getAttributeSetUrl,
	getCurrentAttributeGroup,
	getVisibleColumns,
	IExportEntityType,
} from './ExportHelper';

type IExportAttributeType = 'Current View' | 'Attribute Group';

type IExportEntityDialog = IExportDialog & {
	entity: IExportEntityType;
	entityType: IEntityType;
};

const TitleMap: Record<IExportEntityType, string> = {
	ACCOUNT: 'Accounts',
	ACCOUNT_AND_CONTACT: 'Contacts',
};

const StyledExportEntityDialog = getFreeStyleDialog({
	minWidth: '768px',
	width: '768px',
	maxHeight: '400px',
});

/**
 * Export dialog by entity.
 * @param prop @IIExportDialog
 */
const ExportEntityDialog = ({
	entity,
	entityType,
	data: open,
	setData: setOpen,
}: IExportEntityDialog): React.ReactElement => {
	const [exportType, setExportType] =
		React.useState<IExportAttributeType>('Current View');
	const [exportTimestamp, setExportTimeStamp] = React.useState(false);
	const [attributeGroup, setAttributeGroup] = React.useState(
		getCurrentAttributeGroup()
	);
	const [isExporting, setExporting] = React.useState(false);

	const attributesOptions = [
		{
			value: 'Current View',
			hide: false,
		},
		{
			value: 'Attribute Group',
			hide: isListSegment(),
		},
	];

	return (
		<>
			<StyledExportEntityDialog open={open} onClose={() => setOpen(false)}>
				<GenericFetcher<AttributeSet> apiConfig={{url: getAttributeMapUrl}}>
					{({attributesMap}) => (
						<>
							<DNBTypography variant='h6' ml={6} mt={6} mb={3}>
								{`Export ${TitleMap[entity]}`}
							</DNBTypography>
							<FreeStyleDialogContent>
								<DNBTypography>
									Select the attributes you want to export
								</DNBTypography>
								<DNBRadioGroup
									value={exportType}
									onChange={(_, value) =>
										setExportType(value as IExportAttributeType)
									}>
									{attributesOptions.map(
										({value, hide}) =>
											!hide && (
												<DNBRadio
													key={value}
													id={value}
													disabled={isExporting}
													value={value}
													label={value}
												/>
											)
									)}
									<GenericFetcher<IAttributeGroup[]>
										loader={<CircularProgress />}
										apiConfig={{url: getAttributeSetUrl}}>
										{(data) =>
											exportType === 'Attribute Group' && (
												<DNBSelect
													optionsMaxHeight='280px'
													value={attributeGroup}
													disabled={isExporting}
													onChange={(_, value) => {
														if (!value) return;
														setAttributeGroup(value);
													}}>
													{data.map(({name, displayName}) => (
														<DNBSelectOption
															key={name}
															value={name}
															label={displayName}>
															<DNBTypography variant='compact-body'>
																{displayName}
															</DNBTypography>
														</DNBSelectOption>
													))}
												</DNBSelect>
											)
										}
									</GenericFetcher>
								</DNBRadioGroup>
								<DNBTypography>
									Would you like to export timestamp?
								</DNBTypography>
								<DNBCheckbox
									checked={exportTimestamp}
									label='Export Timestamp'
									disabled={isExporting}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setExportTimeStamp(e.target.checked)
									}
								/>
							</FreeStyleDialogContent>
							<FreeStyleStickyFooter>
								<DNBButton
									variant='secondary'
									size='compact'
									onClick={() => setOpen(false)}>
									Cancel
								</DNBButton>
								<DNBButton
									variant='primary'
									size='compact'
									isLoading={isExporting}
									onClick={async () => {
										try {
											setExporting(true);
											const success = await exportSegment(
												entity,
												exportTimestamp,
												attributeGroup,
												exportType === 'Current View'
													? getVisibleColumns(
															attributesMap as Record<string, string[]>,
															entity
													  )
													: undefined,
												entityType
											);
											if (success) {
												showExportBanner();
											}
										} finally {
											setExporting(false);
											setOpen(false);
										}
									}}>
									Export
								</DNBButton>
							</FreeStyleStickyFooter>
						</>
					)}
				</GenericFetcher>
			</StyledExportEntityDialog>
		</>
	);
};

export {ExportEntityDialog};
