import type {SpecTypeData, TemplateTextRecord} from './import.types';
import {getImportProperty} from '../stores/import';

enum IMPORT_ENTITY {
	ACCOUNT = 'Account',
	CONTACT = 'Contact',
	PRODUCT = 'Product',
	TRANSACTION = 'Transaction',
	ACTIVITY_STREAM = 'ActivityStream',
	CATALOG = 'Catalog',
}

enum IMPORT_STATUS {
	ACTIVE = 'Active',
	PAUSE = 'Pause',
}

enum IMPORT_SYSTEM_TYPE {
	Salesforce = 'Salesforce',
	Marketo = 'Marketo',
	Pardot = 'Pardot',
	Eloqua = 'Eloqua',
	Website = 'Website',
	Other = 'Other',
	DataVision = 'DataVision',
}

const UNSPECIFIED_ERROR = 'unspecified error';

const DATE_FORMAT_TOOLTIP =
	'Date Format is important for our database to map your data correctly. This is only valid for Date Type data.';

// #region PARDOT_SPEC_DATA
const PARDOT_CONTENT_PRESET = {
	created_at: '2019-01-01T21:23:26Z',
	prospect_id: '1022322774',
	campaign_id: '30601',
	visitor_id: '5',
};

const PARDOT_SPEC_DATA: SpecTypeData = {
	url: 'assets/downloads/marketing-activity-pardot.csv',
	tableData: {
		headers: [
			'created_at',
			'prospect_id',
			'type_name',
			'campaign_id',
			'visitor_id',
		],
		content: [
			{
				...PARDOT_CONTENT_PRESET,
				type_name: 'Interesting Moment',
			},
			{
				...PARDOT_CONTENT_PRESET,
				type_name: 'Email Actions',
			},
			{
				...PARDOT_CONTENT_PRESET,
				type_name: 'Email Delivered',
			},
		],
	},
};
// #endregion PARDOT_SPEC_DATA

// #region ELOQUA_SPEC_DATA
const ELOQUA_CONTENT_PRESET = {
	ActivityDate: '2019-01-01T21:23:26Z',
	ContactId: '1022322774',
	CampaignId: '30601',
};

const ELOQUA_SPEC_DATA: SpecTypeData = {
	url: 'assets/downloads/marketing-activity-eloqua.csv',
	tableData: {
		headers: [
			'ActivityDate',
			'ContactId',
			'ActivityType',
			'CampaignId',
			'VisitorExternalId',
		],
		content: [
			{
				...ELOQUA_CONTENT_PRESET,
				ActivityType: 'Interesting Moment',
				VisitorExternalId: '5',
			},
			{
				...ELOQUA_CONTENT_PRESET,
				ActivityType: 'Email Actions',
				VisitorExternalId: '6',
			},
			{
				...ELOQUA_CONTENT_PRESET,
				ActivityType: 'Email Delivered',
				VisitorExternalId: '7',
			},
		],
	},
};
// #endregion ELOQUA_SPEC_DATA

// #region MARKETO_SPEC_DATA
const MARKETO_CONTENT_PRESET = {
	ActivityDate: '2019-01-01T21:23:26Z',
	leadId: '1022322774',
	ActivityTypeId: '27',
	campaign_run_id: '30601',
};

const MARKETO_SPEC_DATA: SpecTypeData = {
	url: 'assets/downloads/marketing-activity-marketo.csv',
	tableData: {
		headers: [
			'ActivityDate',
			'leadId',
			'ActivityTypeId',
			'ActivityType',
			'campaign_run_id',
			'marketoGUID',
		],
		content: [
			{
				...MARKETO_CONTENT_PRESET,
				ActivityType: 'Interesting Moment',
				marketoGUID: '5',
			},
			{
				...MARKETO_CONTENT_PRESET,
				ActivityType: 'Email Actions',
				marketoGUID: '6',
			},
			{
				...MARKETO_CONTENT_PRESET,
				ActivityType: 'Email Delivered',
				marketoGUID: '7',
			},
		],
	},
};
// #endregion MARKETO_SPEC_DATA

// #region LATTICE_SPEC_DATA
const LATTICE_CONTENT_PRESET = {
	ActivityDate: '2019-01-01T21:23:26Z',
	LeadId: '1022322774',
	CampaignId: '30601',
};

const LATTICE_SPEC_DATA: SpecTypeData = {
	url: 'assets/downloads/marketing-activity-lattice.csv',
	tableData: {
		headers: [
			'ActivityDate',
			'LeadId',
			'ActivityType',
			'CampaignId',
			'CookieId',
		],
		content: [
			{
				...LATTICE_CONTENT_PRESET,
				ActivityType: 'Interesting Moment',
				CookieId: '5',
			},
			{
				...LATTICE_CONTENT_PRESET,
				ActivityType: 'Email Actions',
				CookieId: '6',
			},
			{
				...LATTICE_CONTENT_PRESET,
				ActivityType: 'Email Delivered',
				CookieId: '7',
			},
		],
	},
};
// #endregion LATTICE_SPEC_DATA

// #region SALES_ACTIVITY_EVENT_SPEC_DATA
const SALES_ACTIVITY_EVENT_PRESET = {
	ActivityDate: '2021-09-29T000:00:00.0000Z',
	Type: 'Event',
	EndDateTime: '2021-09-29T000:00:00.0001Z',
};

const SALES_ACTIVITY_EVENT_SPEC_DATA: SpecTypeData = {
	url: 'assets/downloads/sales-activity-event.csv',
	tableData: {
		headers: [
			'ID',
			'ActivityDate',
			'AccountId',
			'Type',
			'LeadId',
			'EndDateTime',
		],
		content: [
			{
				...SALES_ACTIVITY_EVENT_PRESET,
				ID: '6a472e89',
				AccountId: '74dcb0ca',
				LeadId: 'aac1d66d',
			},
			{
				...SALES_ACTIVITY_EVENT_PRESET,
				ID: '1f6709be',
				AccountId: '00fe37cb',
				LeadId: 'd56effa1',
			},
			{
				...SALES_ACTIVITY_EVENT_PRESET,
				ID: '6a4ff124',
				AccountId: '78f8a5fa',
				LeadId: 'b89bd0a8',
			},
		],
	},
};
// #endregion SALES_ACTIVITY_EVENT_SPEC_DATA

// #region SALES_ACTIVITY_TASK_SPEC_DATA
const SALES_ACTIVITY_TASK_PRESET = {
	ActivityDate: '2021-09-29T000:00:00.0000Z',
	Type: 'Task',
};

const SALES_ACTIVITY_TASK_SPEC_DATA: SpecTypeData = {
	url: 'assets/downloads/sales-activity-task.csv',
	tableData: {
		headers: ['ID', 'AccountId', 'ActivityDate', 'IsClosed', 'Type', 'LeadId'],
		content: [
			{
				...SALES_ACTIVITY_TASK_PRESET,
				ID: '6dd1956b',
				AccountId: '3464fdf5',
				IsClosed: 'TRUE',
				LeadId: '3fc336ad',
			},
			{
				...SALES_ACTIVITY_TASK_PRESET,
				ID: '0310a223',
				AccountId: '38d3147b',
				IsClosed: 'FALSE',
				LeadId: 'a2fa93e3',
			},
			{
				...SALES_ACTIVITY_TASK_PRESET,
				ID: '74702b6d',
				AccountId: 'a3c35668',
				IsClosed: 'FALSE',
				LeadId: '18c025a1',
			},
		],
	},
};
// #endregion SALES_ACTIVITY_TASK_SPEC_DATA

const TEMPLATE_TEXT: TemplateTextRecord = {
	'Website Path': {
		description:
			'Upload a CSV file containing one row for each Web Pages with its attributes In separate columns. You can always update and add Web Pages later.',
		link: {
			href: 'assets/downloads/web-pages-example.csv',
			text: '',
		},
	},
	'Visit Source': {
		description:
			'Upload a CSV file containing one row for each Web Visit Source with its attributes In separate columns. You can always update and add Web Visit Source later.',
		link: {
			href: 'assets/downloads/web-visit-source-example.csv',
			text: '',
		},
	},
};

const IMPORT_WIZARD_VALIDATION_STORE = {
	getValidation: (type: string): boolean =>
		getImportProperty<Record<string, boolean>>('validation')[type]!,
};

export {
	IMPORT_ENTITY,
	IMPORT_STATUS,
	IMPORT_SYSTEM_TYPE,
	UNSPECIFIED_ERROR,
	DATE_FORMAT_TOOLTIP,
	PARDOT_SPEC_DATA,
	ELOQUA_SPEC_DATA,
	MARKETO_SPEC_DATA,
	LATTICE_SPEC_DATA,
	SALES_ACTIVITY_EVENT_SPEC_DATA,
	SALES_ACTIVITY_TASK_SPEC_DATA,
	TEMPLATE_TEXT,
	IMPORT_WIZARD_VALIDATION_STORE,
};
