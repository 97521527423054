import React, {react2angular} from 'common/react-vendor';
import type {StateService} from '@uirouter/angularjs';
import {isDescending, sortKey} from 'common/widgets/dataviews/column-sort';
import {useApi} from 'common/app/hooks/useApi';
import {Restriction} from 'components/datacloud/query/query.types';
import {getQueryProperty} from 'common/stores/query';
import {getSegments} from '../../../segment/segment.queries';
import {sanitizeSegment} from '../../../segment/segment.helpers';
import {mapSegmentNames} from '../../../../../../atlas/app/segments/segments.helpers';
import {getEntities} from '../../query';
import {AccountsPage, ContactsPage, QueryResult, TableQuery} from '.';
import './index.ng.css';
import {queryBuilderExit} from '../../queryBuilder.utilities';
import {getEntitiesCounts} from '../../query.helpers';
import {Query} from '../../../datacloud.types';
import {setSessionSegmentState} from './segment.helpers';

const {useCallback, useMemo} = React;

type RootScope = {
	$apply(): void;
};

// Angular adaptor for results page
export interface ResultsPageAdapterProps {
	entityType: 'accounts' | 'contacts';
	$state: StateService;
	$stateParams: common.$stateParams;
	$rootScope: RootScope;
}

function ResultsPageAdapter({
	entityType = 'accounts',
	$state,
	$stateParams,
	$rootScope,
}: ResultsPageAdapterProps): JSX.Element {
	const {segment} = $stateParams;

	const {data: segments} = useApi<Query[]>('getSegments', () => getSegments());

	const segmentNames = useMemo<Record<string, string>>(
		() => mapSegmentNames(segments),
		[segments]
	);

	const runQuery = useCallback(
		async (
			{textSearch, pagination, sort}: TableQuery,
			lookups: common.QueryAttribute[]
		): Promise<QueryResult> => {
			// sanitizeSegment mutates its arg, so we'll isolate any possibility of
			// mutation to the externals
			const restrictions = sanitizeSegment({
				account_restriction: angular.copy(
					getQueryProperty<Restriction>('accountRestriction')
				),
				contact_restriction: angular.copy(
					getQueryProperty<Restriction>('contactRestriction')
				),
				member_restriction: angular.copy(
					getQueryProperty<Restriction>('memberRestriction')
				),
				name: '',
				display_name: '',
				restrict_without_sfdcid: false,
			});

			const query: Query = {
				...restrictions,
				free_form_text_search: textSearch,
				restrict_without_sfdcid: false,
				page_filter: {
					row_offset: pagination.page * pagination.pageSize,
					num_rows: pagination.pageSize,
				},
				lookups,
			};

			const [entity, attribute] = sortKey(sort)?.split('.') || [];
			if (
				entity &&
				attribute &&
				// TODO: document implicit behavior (sort only takes effect if we also lookup the attribute value)
				lookups.some(
					({attribute: attr}) =>
						attr.entity === entity && attr.attribute === attribute
				)
			) {
				query.sort = {
					attributes: [
						{
							attribute: {
								attribute,
								entity,
							},
						},
					],
					descending: isDescending(sort),
				};
			}

			// Start both requests in parallel
			const entitiesPromise = getEntities(entityType, query);
			const entityCountPromise = getEntitiesCounts(query)
				.then(({Account, Contact}) => {
					switch (entityType) {
						case 'accounts':
							return Account;
						case 'contacts':
							return Contact;
						default:
							throw new Error('Unknown entity type');
					}
				})
				.finally(() => {
					$rootScope.$apply();
				});

			return {
				entities: await entitiesPromise,
				entityCount: await entityCountPromise,
			};
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[entityType]
	);

	const openAccount = useCallback(
		(queryParams: Record<string, string>) => {
			const account360URL = 'home.segment.accounts.contacts';
			$state.go(account360URL, queryParams, {
				reload: true,
			});
		},
		[$state]
	);

	const onChangeSegment = useCallback(
		(segmentName: string) => {
			const queryBuilderConfig = {
				$state,
			};

			const segment = segments?.find(({name}) => name === segmentName);

			queryBuilderExit(queryBuilderConfig)
				.then((exit) => {
					if (exit) {
						setSessionSegmentState({
							isCompanyList: segment?.listSegment?.systemType === 'CompanyList',
							isContactList: segment?.listSegment?.systemType === 'ContactList',
						});

						if (segment?.listSegment?.systemType) {
							$state.go(
								`home.segment.${
									segment.listSegment.systemType === 'CompanyList'
										? 'accounts'
										: 'contacts'
								}`,
								{segment: segmentName},
								{reload: true}
							);
							return;
						}

						$state.go(`home.segment.${entityType}`, {segment: segmentName});
					} else {
						console.error('user cancelled action');
					}
				})
				.catch((err) => console.error(err, err.stack));
		},
		[$state, entityType, segments]
	);

	switch (entityType) {
		case 'accounts':
			return (
				<div className='results-page'>
					<AccountsPage
						entityType={entityType}
						segment={segment}
						segmentNames={segmentNames}
						runQuery={runQuery}
						onOpenAccount={openAccount}
						onChangeSegment={onChangeSegment}
					/>
				</div>
			);
		case 'contacts':
			return (
				<div className='results-page'>
					<ContactsPage
						entityType={entityType}
						segment={segment}
						onChangeSegment={onChangeSegment}
						segmentNames={segmentNames}
						runQuery={runQuery}
						onOpenAccount={openAccount}
					/>
				</div>
			);
		default:
			return (
				<div className='results-page'>
					Error: &apos;{entityType}&apos; is an unknown entity type
				</div>
			);
	}
}

export const ngResultsPage = react2angular(
	ResultsPageAdapter,
	['entityType'],
	['$state', '$stateParams', '$rootScope']
);

angular
	.module('common.datacloud.query')
	.component('resultsPage', ngResultsPage);
