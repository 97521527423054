import React from 'common/react-vendor';
import {Spinner} from 'widgets/spinner';
import {IApiDataType} from './Data/API/ApiType';
import {useFetch, useGroupFetch} from './Hook/useFetch';
import {IFetch} from './Util/FetchWrapper';

type IGenericFetcher<T extends IApiDataType> = IFetch & {
	loader?: React.ReactNode;
	children?: (data: T) => React.ReactNode;
};

type IFetcher = IGenericFetcher<IApiDataType>;

const GenericFetcher = <T extends IApiDataType>({
	loader = <Spinner />,
	children,
	...fetchProp
}: IGenericFetcher<T>): React.ReactElement => {
	const {data, isLoading} = useFetch<T>({...fetchProp});
	return <>{isLoading ? loader : data && children?.(data)}</>;
};

const Fetcher = (props: IGenericFetcher<IApiDataType>): React.ReactElement => {
	return <GenericFetcher<IApiDataType> {...props} />;
};

interface IGroupFetcher<T extends IApiDataType[]> {
	fetchList: IFetch[];
	children?: (data: T) => React.ReactNode;
}

const GroupFetcher = <T extends IApiDataType[]>({
	children,
	fetchList,
}: IGroupFetcher<T>): React.ReactElement => {
	const {data, isLoading} = useGroupFetch<T>(fetchList);
	return <>{isLoading ? <Spinner /> : data && children?.(data)}</>;
};

export {Fetcher, GenericFetcher, GroupFetcher};
export type {IFetcher, IGenericFetcher, IGroupFetcher};
