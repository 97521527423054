import {ImportActionType} from './enums';
import type {
	ImportAction,
	ImportStoreState,
	PartialFieldDocument,
} from './types';
import {Fields} from '../../import/ImportUtils.types';

const clearImportState = (): ImportAction => {
	return {
		type: ImportActionType.CLEAR_IMPORT_STATE,
	};
};

const setImportPropertyValue = <ImportValue>(
	key: keyof ImportStoreState,
	value: ImportValue
): ImportAction => {
	return {
		type: ImportActionType.SET_IMPORT_PROPERTY_VALUE,
		payload: {
			key,
			value,
		},
	};
};

const updateFieldDocumentValues = (
	payload: PartialFieldDocument
): ImportAction => {
	return {
		type: ImportActionType.UPDATE_FIELD_DOCUMENT_VALUES,
		payload,
	};
};

const setFieldDocumentSaved = (key: string, value: Fields): ImportAction => {
	return {
		type: ImportActionType.SET_FIELD_DOCUMENT_SAVED,
		payload: {
			key,
			value,
		},
	};
};

const setValidation = (key: string, value: boolean): ImportAction => {
	return {
		type: ImportActionType.SET_VALIDATION,
		payload: {
			key,
			value,
		},
	};
};

export {
	clearImportState,
	setImportPropertyValue,
	updateFieldDocumentValues,
	setFieldDocumentSaved,
	setValidation,
};
