import {
	AdminRouteMap,
	NoAdminRouteMap,
	ROUTE_PAGE,
} from 'common/app/utilities/AdminPageUtility';
import {store as reduxStore} from 'store';
import {clearDataCloudStore} from 'common/stores/datacloud';
import AttrConfigService from './AttrConfigService';
import {
	CategoryMetadata,
	getMetadataForCategory,
	loadCategoryMetadata,
} from '../datacloud.service.vanilla';
import {
	AnyResponse,
	AttrConfigData,
	AttrConfigFilter,
	AttrConfigFilters,
	AttrConfigObj,
	AttrConfigPayload,
	AttributeSet,
	Attribute,
	IAttributeGroup,
	IDefaultAttrConfigPayload,
} from './AttrConfig.types';

const DEFAULT_FILTERS: AttrConfigFilters = {
	page: 1,
	pagesize: 25,
	sortPrefix: '+',
	queryText: '',
	showFilterBy: false,
	show: {
		Selected: false,
		IsPremium: false,
	},
	hide: {
		Selected: false,
		IsPremium: false,
	},
	disabled: {
		Selected: false,
		IsPremium: true,
	},
};

const DEFAULT_DATA: AttrConfigData = {
	original: {},
	config: {},
	overview: {},
	buckets: {},
	lastRefresh: '',
};

const store: AttrConfigObj = {
	filters: {...DEFAULT_FILTERS},
	limit: -1,
	selected: [],
	start_selected: [],
	TotalFilteredAttrs: [],
	category: '',
	accesslevel: '',
	data: {...DEFAULT_DATA},
	saving: false,
	attributeGroup: undefined,
	totalSelected: undefined,
	refresh: false,
};

const reset = (): void => {
	store.filters = {...DEFAULT_FILTERS};
	store.limit = -1;
	store.selected = [];
	store.start_selected = [];
	store.TotalFilteredAttrs = [];
	store.category = '';
	store.accesslevel = '';
	store.data = {...DEFAULT_DATA};
	store.saving = false;
	store.attributeGroup = undefined;
	store.totalSelected = undefined;
	store.refresh = false;
};

const set = <ACOKey extends keyof AttrConfigObj>(
	property: ACOKey,
	value: AttrConfigObj[ACOKey]
): void => {
	store[property] = value;
};

const get = <ACOKey extends keyof AttrConfigObj>(
	property: ACOKey
): AttrConfigObj[ACOKey] => {
	return store[property];
};

const setData = (
	type: keyof Omit<AttrConfigData, 'lastRefresh'>,
	data: Record<string, unknown>
): void => {
	store.data[type] = data;
};

const getData = (
	property: keyof Omit<AttrConfigData, 'lastRefresh'>
): Record<string, unknown> => {
	return store.data[property];
};

const searchFilter = (attr: Attribute): boolean => {
	const text = store.filters.queryText.toLowerCase();
	const ret = !!(
		attr.SubCategory?.toLowerCase().includes(text) ||
		attr.DisplayName.toLowerCase().includes(text) ||
		attr.Description?.toLowerCase().includes(text) ||
		attr.Attributes?.some((a) => {
			return (
				a.Description?.toLowerCase().includes(text) ||
				a.DisplayName.toLowerCase().includes(text)
			);
		})
	);
	return ret;
};

const getFiltering = (): AttrConfigFilter => {
	const {filters} = store;
	const {show, hide} = filters;
	const obj: AttrConfigFilter = {};

	Object.keys(show).forEach((property) => {
		if (show[property] === true) {
			obj[property] = true;
		}
	});

	Object.keys(hide).forEach((property) => {
		if (hide[property] === true) {
			obj[property] = false;
		}
	});
	return obj;
};

const getSection = (routeName: string): string | undefined => {
	switch (routeName) {
		case AdminRouteMap.get(ROUTE_PAGE.ACTIVATE_ATTRIBUTES):
		case NoAdminRouteMap.get(ROUTE_PAGE.ACTIVATE_ATTRIBUTES):
			return 'activate';
		case AdminRouteMap.get(ROUTE_PAGE.ENABLE_ATTRIBUTES):
		case NoAdminRouteMap.get(ROUTE_PAGE.ENABLE_ATTRIBUTES):
			return 'enable';
		case AdminRouteMap.get(ROUTE_PAGE.EDIT_ATTRIBUTES):
		case NoAdminRouteMap.get(ROUTE_PAGE.EDIT_ATTRIBUTES):
			return 'edit';
		case AdminRouteMap.get(ROUTE_PAGE.ATTRIBUTE_GROUP):
		case NoAdminRouteMap.get(ROUTE_PAGE.ATTRIBUTE_GROUP):
			return 'groups';
		case AdminRouteMap.get(ROUTE_PAGE.WEB_PERSONALIZATION):
		case NoAdminRouteMap.get(ROUTE_PAGE.WEB_PERSONALIZATION):
			return 'personalization';
		default:
			return undefined;
	}
};

const getActiveTabData = (
	routeName: string,
	stateParams: Record<string, string>
): Record<string, unknown> => {
	const page = getSection(routeName);
	const param = page === 'activate' ? 'category' : 'section';
	const active = stateParams[param];
	const data = store.data.overview;
	return (
		data?.Selections?.find((tab) => {
			return tab.DisplayName === active;
		}) || {}
	);
};

const getGroupSelectedTotal = (): number => {
	let total = store.totalSelected || 0;
	if (!store.refresh) {
		total += store.selected.length - store.start_selected.length;
	}
	return total > 0 ? total : 0;
};

const getSelectedTotal = (
	routeName: string,
	stateParams: Record<string, string>,
	isSavingEnabled = false
): number => {
	const section = getSection(routeName);
	if (isSavingEnabled && section === 'groups') {
		return getGroupSelectedTotal();
	}
	let total = store.selected.length;
	let tab;

	if (section === 'enable' || (!isSavingEnabled && section === 'groups')) {
		tab = getActiveTabData(routeName, stateParams);
		if (tab.Selected) {
			total = (tab.Selected as number) + (total - store.start_selected.length);
		}
	}
	return total > 0 ? total : 0;
};

const getUsageLimit = (
	overview: Record<string, unknown>,
	area: string
): number | undefined => {
	const ret = (
		overview.Selections as Array<{DisplayName: string; Limit: number}>
	).find((tab) => {
		return tab.DisplayName === area;
	})?.Limit;
	return ret;
};

const getBucketData = async (
	category: string,
	subcategory: string
): Promise<unknown> => {
	const metadata = await loadCategoryMetadata();
	const {isPremium} = getMetadataForCategory(
		metadata,
		category
	) as CategoryMetadata;
	if (!isPremium) return [];
	return AttrConfigService.getBucketData(category, subcategory).then((data) => {
		store.data.buckets[subcategory] = data.data;
		return data;
	});
};

const isChanged = (): boolean => {
	if (!store.data.original.Subcategories) {
		return false;
	}

	let hasChanged = false;
	const subcategories = store.data.original.Subcategories;

	store.data.config.Subcategories!.forEach((subcategory, index) => {
		subcategory?.Attributes.forEach((attribute, i) => {
			if (
				subcategories[index]!.Attributes[i]!.Selected !== attribute.Selected
			) {
				hasChanged = true;
			}
		});
	});
	return hasChanged;
};

const generatePayloadForAttributeSetNew = (
	attributeGroup: IAttributeGroup
): Record<string, string[]> => {
	if (!attributeGroup) return {};
	const attributesToSend = Object.entries(attributeGroup).map(([key, data]) => {
		return [
			key,
			data.config.Subcategories?.map((sc) =>
				sc.Attributes.filter((a) => a.Selected).map((a) => a.Attribute)
			).flat(),
		];
	});
	return Object.fromEntries(attributesToSend);
};

const generatePayloadForAttributeSet = (
	category: string
): Record<string, string[]> => {
	const attributesToSend: string[] = store.data.config
		.Subcategories!.map((sc) =>
			sc.Attributes.filter((a) => a.Selected).map((a) => a.Attribute)
		)
		.flat();
	return {
		[category]: attributesToSend,
	};
};

const generatePayloadFromData = (data: AttrConfigData): AttrConfigPayload => {
	const {original, config} = data;

	const payload: AttrConfigPayload = {
		Select: [],
		Deselect: [],
	};
	config.Subcategories!.forEach((subcategory, index) => {
		const oSub = original.Subcategories![index];

		subcategory.Attributes.forEach((attr) => {
			const oAttr = oSub!.Attributes.find((item) => {
				return attr.Attribute === item.Attribute;
			});

			if (oAttr?.Selected !== attr.Selected) {
				if (attr.Selected) {
					payload.Select.push(attr.Attribute);
				} else {
					payload.Deselect.push(attr.Attribute);
				}
			}
		});
	});

	return payload;
};

const generatePayloadNew = (): AttrConfigPayload => {
	return generatePayloadFromData(store.data);
};

const generatePayload = (): AttrConfigPayload => {
	const {original} = store.data;

	const payload: AttrConfigPayload = {
		Select: [],
		Deselect: [],
	};
	store.data.config.Subcategories!.forEach((subcategory, index) => {
		const oSub = original.Subcategories![index];

		subcategory.Attributes.forEach((attr) => {
			const oAttr = oSub!.Attributes.find((item) => {
				return attr.Attribute === item.Attribute;
			});
			if (oAttr?.Selected !== attr.Selected) {
				if (attr.Selected) {
					payload.Select.push(attr.Attribute);
				} else {
					payload.Deselect.push(attr.Attribute);
				}
			}
		});
	});
	return payload;
};

const saveConfig = (
	routeName: string,
	stateParams: {section: string}
): Promise<AnyResponse> => {
	const {category} = store;
	const activate = getSection(routeName) === 'activate';
	const type = activate ? 'activation' : 'usage';
	const data = generatePayloadNew();
	const usage = {
		...(!activate && {usage: stateParams.section}),
	};

	store.saving = true;

	return AttrConfigService.putConfig(type, category, usage, data).then(
		(result) => {
			store.data.original = store.data.config;

			reduxStore.dispatch(clearDataCloudStore());

			window.ShowSpinner('Refreshing Data');
			return result;
		}
	);
};

const generateDefaultAttributeGroupPayload = (
	attributeGroup: IAttributeGroup
): IDefaultAttrConfigPayload[] => {
	const attributesToSend = Object.entries(attributeGroup).map(([key, data]) => {
		return {categoryName: key, attributeSet: generatePayloadFromData(data)};
	});
	return attributesToSend;
};

const saveDefaultAttributeGroupConfig = (
	attributeGroup: IAttributeGroup,
	routeName: string,
	stateParams: {section: string}
): Promise<AnyResponse> => {
	const activate = getSection(routeName) === 'activate';
	const type = activate ? 'activation' : 'usage';
	const data = generateDefaultAttributeGroupPayload(attributeGroup);
	const usage = {
		...(!activate && {usage: stateParams.section}),
	};

	store.saving = true;

	return AttrConfigService.putConfig(type, '', usage, {
		attributeMap: data,
	}).then((result) => {
		store.data.original = store.data.config;

		reduxStore.dispatch(clearDataCloudStore());

		window.ShowSpinner('Refreshing Data');
		store.saving = false;
		return result;
	});
};

const saveAttributeGroupConfigNew = (
	attributeSet: AttributeSet,
	attributeGroup: IAttributeGroup
): Promise<AnyResponse> => {
	store.saving = true;

	return AttrConfigService.updateAttributeSetAttributes(
		attributeSet.name,
		generatePayloadForAttributeSetNew(attributeGroup)
	).then(function (result) {
		if (result.status >= 200 && result.status < 300) {
			setData('original', store.data.config);

			reduxStore.dispatch(clearDataCloudStore());

			window.ShowSpinner('Refreshing Data');
		}
		store.saving = false;
		return result;
	});
};

const saveAttributeGroupConfig = (
	attributeSet: AttributeSet,
	category: string
): Promise<AnyResponse> => {
	store.saving = true;

	return AttrConfigService.updateAttributeSetAttributes(
		attributeSet.name,
		generatePayloadForAttributeSet(category)
	).then(function (result) {
		if (result.status >= 200 && result.status < 300) {
			setData('original', store.data.config);

			reduxStore.dispatch(clearDataCloudStore());

			window.ShowSpinner('Refreshing Data');
		}
		store.saving = false;
		return result;
	});
};

const refreshAttributes = (): Promise<unknown> =>
	AttrConfigService.refreshAttributes();

export default {
	reset,
	set,
	get,
	setData,
	getData,
	searchFilter,
	getFiltering,
	getSection,
	getActiveTabData,
	getSelectedTotal,
	getUsageLimit,
	getBucketData,
	isChanged,
	generatePayload,
	saveConfig,
	saveAttributeGroupConfig,
	saveAttributeGroupConfigNew,
	refreshAttributes,
	saveDefaultAttributeGroupConfig,
};
