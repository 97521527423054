import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {cacheQueryResponse} from 'common/app/utilities/cacheUtility/cacheUtility';
import {Period} from '../../../query.enums';

const getPeriodsNames = async (): Promise<Period[]> =>
	cacheQueryResponse('getPeriodsNames', () =>
		axiosInstance
			.get<Period[]>('/pls/datacollection/periods/names')
			.then(({data}) => data)
	);

export {getPeriodsNames};
