import {Connections} from '../metadataLiveramplaunch/metadata.enums';

export const segmentConnections = [
	Connections.DATA_VISION,
	Connections.VENDEMORE,
];

export const dataExtensionConnections = [
	Connections.Salesforce_Marketing_Cloud,
];

export const noDataExtensionNames = ['Subscriber Email', 'Subscriber Key'];
