import React from 'common/react-vendor';
import {
	CircularProgress,
	DNBButton,
	DNBTooltip,
	DNBTypography,
	theme,
} from 'common/dnb-uux-vendor';
import {Refresh} from '@mui/icons-material';
import AttrConfigService from '../AttrConfigService';

type StatusMessageType = 'Never' | 'Ready' | 'Generating' | 'Error';

interface ReportStatusStatus {
	lastRefreshTime?: string | number;
	status: StatusMessageType;
}

interface ReportStatusProps {
	status: ReportStatusStatus;
}

export const ReportStatus: React.FC<
	React.PropsWithChildren<ReportStatusProps>
> = ({status}: ReportStatusProps) => {
	const [currentStatus, setCurrentStatus] =
		React.useState<ReportStatusStatus>(status);
	const [dateString, setDateString] = React.useState<string>('');

	React.useEffect(() => {
		setDateString(
			currentStatus.lastRefreshTime
				? new Date(currentStatus.lastRefreshTime).toLocaleDateString()
				: ''
		);
	}, [currentStatus.lastRefreshTime]);

	const refresh = (): void => {
		// eslint-disable-next-line promise/catch-or-return
		AttrConfigService.refreshProfileReport().then((response) => {
			console.log(response);
			setCurrentStatus(response as unknown as ReportStatusStatus);
		});
	};

	return (
		<DNBTypography variant='caption'>
			{currentStatus.status === 'Never' && (
				<span>
					Attribute counts for all categories shown below have not been
					calculated.{' '}
				</span>
			)}
			{currentStatus.status === 'Ready' && (
				<span>
					Attribute counts for all categories shown below were last calculated
					on {dateString}.{' '}
				</span>
			)}
			{currentStatus.status === 'Generating' && (
				<span>
					Attribute counts for all categories shown below{' '}
					{dateString ? `were last calculated on ${dateString} and ` : ''}
					are currently recalculating.{' '}
				</span>
			)}
			{currentStatus.status === 'Error' && (
				<>
					<span>
						Attribute counts for all categories shown below{' '}
						{dateString
							? ` were last calculated on ${dateString}.`
							: 'have not been calculated.'}{' '}
					</span>
					<span style={{color: theme.colors.ColorNotificationWarning}}>
						(Last refresh unsuccessful; please try again){' '}
					</span>
				</>
			)}

			{currentStatus.status === 'Generating' ? (
				<CircularProgress size={10} />
			) : (
				<DNBTooltip
					arrow
					placement='top'
					content='Recalculating can take hours depending on the quantity of data involved.'>
					<DNBButton
						variant='text'
						endIcon={<Refresh />}
						size='small'
						onClick={refresh}>
						Refresh
					</DNBButton>
				</DNBTooltip>
			)}
		</DNBTypography>
	);
};
