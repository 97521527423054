// #region Enums
enum ApprovedUsage {
	None = 'None',
	Model = 'Model',
	ModelAndModelInsights = 'ModelAndModelInsights',
	ModelAndAllInsights = 'ModelAndAllInsights',
	IndividualDisplay = 'IndividualDisplay',
}

enum DataType {
	String = 'STRING',
	LongString = 'LONGSTRING',
	Date = 'DATE',
	DateTime = 'DATETIME',
	Time = 'TIME',
	Double = 'DOUBLE',
	Float = 'FLOAT',
	Int = 'INT',
	Integer = 'INTEGER',
	Short = 'SHORT',
	Long = 'LONG',
	Boolean = 'BOOLEAN',
	Bit = 'BIT',
	Epoch = 'EPOCHTIME',
}

enum FundamentalType {
	Email = 'EMAIL',
	Probability = 'PROBABILITY',
	Phone = 'PHONE',
	Enum = 'ENUM',
	URI = 'URI',
	Currency = 'CURRENCY',
	Percent = 'PERCENT',
	Year = 'YEAR',
	Boolean = 'BOOLEAN',
	Numeric = 'NUMERIC',
}

// #endregion Enums

// #region Function Interfaces
interface Bucket {
	CorrelationSign: number;
	Count: number;
	IsVisible: boolean;
	Lift: number;
	LowerInclusive: number | null;
	Name: string;
	UncertaintyCoefficient: number;
	UpperExclusive: number | null;
	Values: [string | null];
}

interface AttributeMetadata {
	ApprovedUsage: string[];
	Category: string;
	DataType: string | null;
	Description: string;
	DisplayName: string;
	Elements: Bucket[];
	FundamentalType: FundamentalType | null;
	Name: string;
	Tags: string[] | null;
	UncertaintyCoefficient: number;
}

// #endregion Function Interfaces

// #region Types
type Value = string | number | null;
// #endregion Types

export {ApprovedUsage, DataType, FundamentalType};
export type {Bucket, AttributeMetadata, Value};
