import {ISourceReadyState} from '../AddSource/Hook/useSourceReadyState';
import {SystemType} from './SystemType';

/*
 * Keep sync from EntityType.java and InboundChannel.java from ledp repo.
 * key -> type, value -> display name.
 */
enum DocumentType {
	// Account
	Account = 'Account',
	// Contact
	Contact = 'Contact',
	Lead = 'Lead',
	// Transaction
	ProductPurchase = 'ProductPurchase',
	// Product
	ProductBundle = 'ProductBundle',
	ProductHierarchy = 'ProductHierarchy',
	// ActivityStream
	WebVisit = 'WebVisit',
	Opportunity = 'Opportunity',
	MarketingActivity = 'MarketingActivity',
	CustomIntent = 'CustomIntent',
	CampaignActivity = 'CampaignActivity',
	Impression = 'Impression',
	PerformanceReport = 'PerformanceReport',
	SalesActivityEvent = 'SalesActivityEvent',
	SalesActivityTask = 'SalesActivityTask',
	LeadHistory = 'LeadHistory',
	VisitWebpage = 'VisitWebpage',
	FillOutForm = 'FillOutForm',
	ClickLink = 'ClickLink',
	SendEmail = 'SendEmail',
	EmailDelivered = 'EmailDelivered',
	EmailBounced = 'EmailBounced',
	InterestingMoment = 'InterestingMoment',
	OpenEmail = 'OpenEmail',
	ClickEmail = 'ClickEmail',
	NewLead = 'NewLead',
	BounceBack = 'BounceBack',
	EmailClickThrough = 'EmailClickThrough',
	EmailOpen = 'EmailOpen',
	EmailSend = 'EmailSend',
	FormSubmit = 'FormSubmit',
	PageView = 'PageView',
	Subscribe = 'Subscribe',
	Unsubscribe = 'Unsubscribe',
	ActivityWebVisit = 'ActivityWebVisit',
	// Catalog
	WebVisitPathPattern = 'WebVisitPathPattern',
	WebVisitSourceMedium = 'WebVisitSourceMedium',
	OpportunityStageName = 'OpportunityStageName',
	OpportunityContactRole = 'OpportunityContactRole',
	MarketingActivityType = 'MarketingActivityType',
	Campaign = 'Campaign',
	Asset = 'Asset',
	AdGroup = 'AdGroup',
	SalesActivityType = 'SalesActivityType',
	SalesActivityEventType = 'SalesActivityEventType',
	SalesActivityTaskType = 'SalesActivityTaskType',
	LatticeRecommendation = 'LatticeRecommendation',
	LeadCategory = 'LeadCategory',
	User = 'User',
	Audience = 'Audience',
	CampaignBudget = 'CampaignBudget',
	CampaignElements = 'CampaignElements',
	G2Intent = 'G2Intent',
	TrustRadiusIntent = 'TrustRadiusIntent',
	CustomEvent = 'CustomEvent',
	CustomObject = 'CustomObject',
}

enum DocumentTypeGroup {
	Account = 'Account',
	Contact = 'Contact',
	Transaction = 'Transaction',
	ActivityStream = 'ActivityStream',
	Catalog = 'Catalog',
}

enum CustomDocumentTypeGroup {
	event = 'Event',
	object = 'Object',
}

interface IDocumentTypeNode {
	main: DocumentType;
	sub?: DocumentType[];
}

type IDocumentTypeNodeSystemMap = Partial<
	Record<SystemType, () => IDocumentTypeNode[]>
>;

type IDocumentGroupType = Record<
	DocumentTypeGroup,
	(systemType: SystemType) => IDocumentTypeNode[]
>;

type IDocumentTypeGroup = Record<DocumentType, DocumentTypeGroup>;

interface IDocumentTypeConfig {
	title: string;
	getCustomDocumentTypeGroup?: () => CustomDocumentTypeGroup | undefined;
	getReadyState?: (params: ISourceReadyState) => {message: string};
}

type IDocumentTypeUIConfig = Record<DocumentType, IDocumentTypeConfig>;

interface IIconConfig {
	color: string;
	fontSize: string;
}

interface ISvgIcon {
	color: string;
	path: string;
	componentTag: (prop: IIconConfig) => React.ReactElement;
}

type IDocumentTypeGroupUIConfig = Record<
	DocumentTypeGroup,
	IDocumentTypeConfig
>;

type IDocumentTypeGroupIconConfig = Partial<
	Record<DocumentTypeGroup, ISvgIcon>
>;

type ISubDocumentTypeMap = Partial<Record<DocumentType, DocumentType[]>>;

export {DocumentType, DocumentTypeGroup, CustomDocumentTypeGroup};
export type {
	ISvgIcon,
	IIconConfig,
	IDocumentGroupType,
	IDocumentTypeNode,
	IDocumentTypeNodeSystemMap,
	IDocumentTypeGroup,
	IDocumentTypeGroupUIConfig,
	IDocumentTypeGroupIconConfig,
	IDocumentTypeUIConfig,
	ISubDocumentTypeMap,
};
