/**
 * TODO: Delete this component if we get rid of
 * the old UI at some point...
 */
import React from 'common/react-vendor';
import {styled} from 'common/dnb-uux-vendor';
import NgState from '../../../ng-state';

const BACK_STATE = 'backState';

interface BackState {
	backLabel?: string;
	backName?: string;
	backParams?: Record<string, string> | Record<never, never>;
	currentState?: string;
	hide?: boolean;
}

/**
 * Since this is an old back button component
 * that displays on tenants that have the old views,
 * I think we don't need the extra effort to create a
 * redux store for the states.
 *
 * Most of the tenants uses the newer UI, so I guess
 * at some point we are going to have the same UI
 * on all tenants, once that happens we can DELETE this
 * file.
 */
const setBackButtonStates = (newBackState: BackState): void => {
	window.sessionStorage.setItem(BACK_STATE, JSON.stringify(newBackState));
};

const getBackButtonStates = (): BackState =>
	JSON.parse(window.sessionStorage.getItem(BACK_STATE) || '{}');

const removeBackButtonStates = (): void =>
	window.sessionStorage.removeItem(BACK_STATE);

const getBackLabel = (): string => getBackButtonStates().backLabel || '';

const getBackName = (): string => getBackButtonStates().backName || '';

const getBackParams = (): Record<string, string> | Record<never, never> =>
	getBackButtonStates().backParams || {};

const getBackCurrentState = (): string | undefined =>
	getBackButtonStates().currentState;

const isBackHidden = (): boolean => {
	const isHidden = getBackButtonStates().hide;

	return isHidden === undefined ? true : isHidden;
};

const StyledButton = styled('button')`
	background-color: transparent;
	border: none;
	padding: 10px 15px 10px 25px !important;
`;

const BackButton = (): React.ReactElement | null => {
	React.useEffect(() => {
		return () => {
			if (
				!NgState.getAngularState().current.name.includes(getBackCurrentState())
			) {
				window.sessionStorage.removeItem(BACK_STATE);
			}
		};
	}, []);

	if (isBackHidden()) {
		return null;
	}

	const goBack = (): void => {
		NgState.getAngularState().go(getBackName(), getBackParams(), {
			reload: true,
		});
	};

	return (
		<StyledButton
			type='button'
			className='header-back-name'
			title={getBackLabel()}
			aria-label='navigate back'
			onClick={goBack}>
			{getBackLabel()}
		</StyledButton>
	);
};

export {BackButton, setBackButtonStates, removeBackButtonStates};
