import axios, {AxiosInstance} from 'axios';
import messageService from 'common/app/utilities/message-service';
import Message, {BANNER, ERROR} from 'common/app/utilities/message';
import {applyPromiseCache} from './axiosPromiseCache';

interface UIActionResponse {
	UIAction: {
		status: string;
		message?: string;
		title: string;
		view: string;
	};
}

const getAxiosInstance = (): AxiosInstance => {
	const instance = axios.create({
		baseURL: '',
		headers: {
			'Content-Type': 'application/json',
		},
	});

	applyPromiseCache(instance);

	instance.interceptors.response.use(
		(response) => {
			if (
				response?.data?.UIAction?.title ||
				response?.data?.UIAction?.message ||
				response?.data?.Errors
			) {
				messageService.sendMessage(new Message(response, '', '', '', ''));
			}
			return response;
		},
		(error) => {
			if (axios.isCancel(error)) {
				console.error('Request canceled: ', error.message);
				return;
			}

			messageService.sendMessage(
				new Message(
					error.response,
					BANNER,
					ERROR,
					error.response?.statusText,
					`${error.response?.config.url} ${error.message}`
				)
			);

			return Promise.reject(error);
		}
	);

	return instance;
};

/**
 * We should start to use this axios instance
 * instead of the httpService with Observers.
 *
 * In case we need to make a request in a
 * React Component, we can use the useApi hook.
 * projects/common/app/hooks/useApi.ts:35
 */
const axiosInstance = getAxiosInstance();

export {axiosInstance};
export type {UIActionResponse};
