import React from 'common/react-vendor';
import {Spinner} from 'widgets/spinner';
import styles from './index.module.css';

export interface OverlayProps {
	content?: React.ReactNode;
	backgroundColor?: string;
	pointerEvents?: boolean;
	children: React.ReactNode;
}

export function Overlay({
	content,
	backgroundColor = '#fffc',
	pointerEvents = false,
	children,
}: OverlayProps): JSX.Element {
	return (
		<div className={styles.wrapper}>
			<>{children}</>
			{content ? (
				<div
					className={styles.overlay}
					style={{
						backgroundColor,
						pointerEvents: pointerEvents ? 'all' : 'auto',
					}}>
					{content}
				</div>
			) : null}
		</div>
	);
}

export interface LoadingOverlayProps {
	loading: boolean;
	message?: string;
	width?: number;
	height?: number;
	children: React.ReactNode;
}

export function LoadingOverlay({
	loading,
	message,
	width,
	height,
	children,
}: LoadingOverlayProps): JSX.Element {
	return (
		<Overlay
			content={
				loading ? (
					<>
						<Spinner width={width} height={height} />
						{message}
					</>
				) : null
			}>
			{children}
		</Overlay>
	);
}
