import {store} from 'store';
import React, {react2angular} from 'common/react-vendor';
import {Provider} from 'common/redux-vendor';
import {QueryBuilderListCard} from './QueryBuilderList';
import {IQueryBuilderListCard} from './QueryBuilderListTypes';
import {AdvancedQueryIcon} from '../AdvancedQueryIcon';

const QueryBuilderListWithRedux = (
	prop: IQueryBuilderListCard
): JSX.Element => {
	return (
		<Provider store={store}>
			<QueryBuilderListCard {...prop} />
		</Provider>
	);
};

angular
	.module('common.datacloud.query.builder.list', [])
	.component(
		'queryBuilderList',
		react2angular(QueryBuilderListWithRedux, ['scope'], [])
	);
/**
 * <query-builder-list />.
 */

angular
	.module('common.datacloud.query.builder.iconDirective', [])
	.component(
		'advancedQueryIconDirective',
		react2angular(AdvancedQueryIcon, [
			'icon',
			'className',
			'onClick',
			'disabled',
			'title',
			'dataTest',
			'isButton',
			'buttonText',
			'size',
		])
	);
/**
 * <advanced-query-icon-directive icon="JoinInnerIcon"></advanced-query-icon-directive>
 */
