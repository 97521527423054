import template from './banner.component.html';
import {Banner} from '../../app/services/BannerService';

angular
	.module('common.banner', [])
	.directive('ngHtmlCompile', function ($compile) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				scope.$watch(attrs.ngHtmlCompile, function (newValue) {
					element.html(newValue);

					$compile(element.contents())(scope);
				});
			},
		};
	})
	.component('bannerMessage', {
		template,
		controller: function ($timeout, $scope) {
			const vm = this;

			vm.banners = Banner.get();

			$scope.$watch(
				function () {
					return localStorage.getItem('banners');
				},
				function (value) {
					vm.banners = JSON.parse(value);
				}
			);

			vm.closeBanner = function (name) {
				Banner.close(name);
			};

			vm.isVisible = function () {
				let visible = false;

				vm.banners.forEach(function (banner, index) {
					if (banner.show === true) {
						visible = true;
					}
				});

				return visible;
			};

			vm.showTimeStamps = function (banner) {
				const now = Date.now();
				const times = [];

				banner.timestamp.forEach(function (ts, index) {
					const elapsed = new Date(now - ts),
						currentIndex = index + 1,
						secs = elapsed.getSeconds(),
						mins = elapsed.getMinutes(),
						fn = function (num, text) {
							return (num == 1 ? text : text + 's') + ' ';
						};

					let time = '    Iteration ' + currentIndex + ':    now';

					if (mins > 0) {
						time =
							'    Iteration ' +
							currentIndex +
							':    ' +
							mins +
							fn(mins, ' minute') +
							secs +
							fn(secs, ' second') +
							'ago';
					} else if (secs > 0) {
						time =
							'    Iteration ' +
							currentIndex +
							':    ' +
							secs +
							fn(secs, ' second') +
							'ago';
					}

					times.push(time);
				});

				const preface =
					'This banner' +
					(banner.name ? ' "' + banner.name + '" ' : ' ') +
					'has been triggered ' +
					banner.badge +
					' times.\n\n';

				return preface + times.join('\n');
			};

			vm.resizeBanner = function () {
				const $_banner_message = angular.element(
						'banner-message .messaging-message'
					),
					initialWidth = $_banner_message.parent().innerWidth() - 20;

				$timeout(function () {
					const $banner_message = angular.element(
							'banner-message .messaging-message'
						),
						hasLongWord = $banner_message.find('.long-word').length;

					if (hasLongWord) {
						$banner_message.width(initialWidth);
					}
				});
			};
		},
	});
