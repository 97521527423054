import React, {StateParams} from 'common/react-vendor';
import {IEntityType} from 'common/components/datacloud/journeystage/journey.helpers';
import {Query} from 'common/components/datacloud/datacloud.types';
import {EntityPage} from './EntityPage';
import {EntitySessionKeyMap, IJourneyEntityParam} from './JourneyEntity';

type ISubstageEntityParam = IJourneyEntityParam & {
	subStageId?: string;
};

type ISubstageEntity = {
	$stateParams: StateParams;
	entityType: IEntityType;
};

/**
 * Base substage entity component.
 * @param prop @ISubstageEntity
 */
const SubstageEntity = ({
	$stateParams,
	entityType,
}: ISubstageEntity): React.ReactElement => {
	const {
		segment = '',
		stageId = '',
		subStageId = '',
	} = $stateParams as ISubstageEntityParam;
	React.useEffect(() => {
		sessionStorage.setItem(
			EntitySessionKeyMap[entityType],
			`${[subStageId].join(',')}-${stageId}`
		);
		return () => {
			sessionStorage.removeItem(EntitySessionKeyMap[entityType]);
		};
	}, [entityType, stageId, subStageId]);
	return subStageId ? (
		<EntityPage
			stageId={parseInt(stageId, 10)}
			stageIds={[parseInt(subStageId, 10)]}
			entityType={entityType}
			segment={segment}
			restriction={{} as Query}
		/>
	) : (
		<>Error entity page without subStageId</>
	);
};

/**
 * Root react component for Accounts with journey substage filter.
 */
const SubstageAccount = (prop: ISubstageEntity): React.ReactElement => {
	return <SubstageEntity {...prop} entityType='accounts' />;
};

/**
 * Root react component for Contacts with journey substage filter.
 */
const SubstageContact = (prop: ISubstageEntity): React.ReactElement => {
	return <SubstageEntity {...prop} entityType='contacts' />;
};

export {SubstageEntity, SubstageAccount, SubstageContact};
