import {AxiosInstance, AxiosRequestConfig} from 'axios';
import md5 from 'crypto-js/md5';

interface CachedAxiosConfig extends AxiosRequestConfig {
	cacheId: string;
}

// Cache to avoid sending multiple same requests at same time
interface ICachedPromise<T> extends Promise<T> {
	resolve?: (result: T) => void;
	reject?: (error: T) => void;
}
export const defer = function <T>(): ICachedPromise<T> {
	let res: ICachedPromise<T>['resolve'];
	let rej: ICachedPromise<T>['reject'];
	const p = new Promise<T>((resolve, reject) => {
		res = resolve;
		rej = reject;
	}) as ICachedPromise<T>;
	p.resolve = res;
	p.reject = rej;
	return p;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cachedPromiseMap = new Map<string, ICachedPromise<any>>();
const getCachedKey = (config: AxiosRequestConfig): string =>
	md5(
		JSON.stringify({
			method: config.method,
			url: config.url,
			params: config.params,
			data: config.data,
		})
	).toString();

export const applyPromiseCache = (instance: AxiosInstance): void => {
	instance.interceptors.request.use((config) => {
		const cachedKey = getCachedKey(config);
		const cachedPromise = cachedPromiseMap.get(cachedKey);
		if (cachedPromise) {
			/* eslint-disable no-param-reassign */
			config.adapter = () => cachedPromise;
		} else {
			cachedPromiseMap.set(cachedKey, defer());
			(config as CachedAxiosConfig).cacheId = cachedKey;
			/* eslint-enable no-param-reassign */
		}

		return config;
	});

	instance.interceptors.response.use(
		(response) => {
			const cachedKey = (response.config as CachedAxiosConfig).cacheId;
			const cachedPromise = cachedPromiseMap.get(cachedKey);
			if (cachedPromise) {
				cachedPromise.resolve?.(response);
				cachedPromiseMap.delete(cachedKey);
			}
			return response;
		},
		(error) => {
			const cachedKey = (error?.config as CachedAxiosConfig)?.cacheId;
			const cachedPromise = cachedPromiseMap.get(cachedKey);
			if (cachedPromise) {
				cachedPromise.reject?.(error);
				cachedPromiseMap.delete(cachedKey);
			}

			return Promise.reject(error);
		}
	);
};
