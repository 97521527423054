import {DocumentType, CustomDocumentTypeGroup} from '../Data/DocumentType';
import {ECdpDataType} from './schemaTypes';

export const CDP_SCHEMA_FIELD = 'Custom Fields';

export const schemaNamespace = 'cdpSchema';
export const attributeNamespace = 'schemaAttribute';

const MAX_LENGTH_TEXT = 2048;
const MAX_LENGTH_INTEGER = 18;
const MAX_LENGTH_NUMBER = 14;

export const MAX_LENGTH_DIC: Partial<Record<ECdpDataType, number>> = {
	[ECdpDataType.Text]: MAX_LENGTH_TEXT,
	[ECdpDataType.Integer]: MAX_LENGTH_INTEGER,
	[ECdpDataType.Number]: MAX_LENGTH_NUMBER,
};

export enum ESchemaGroups {
	Account = 'account',
	Contact = 'contact',
	Activity = 'activity',
	Product = 'product',
	General = 'general',
}

export type CustomizableSchemaGroup =
	| ESchemaGroups.Activity
	| ESchemaGroups.General;

export const customizableSchemaGroups = [
	ESchemaGroups.Activity,
	ESchemaGroups.General,
];

export const customizableSchemaGroupLabels = {
	[ESchemaGroups.Activity]: 'Activities',
	[ESchemaGroups.General]: 'Objects',
};

export const schemaGroupToDocumentGroup = {
	[ESchemaGroups.Activity]: CustomDocumentTypeGroup.event,
	[ESchemaGroups.General]: CustomDocumentTypeGroup.object,
};

export enum SchemaRoutes {
	account = 'home.attributes.schema.account',
	contact = 'home.attributes.schema.contact',
	activity = 'home.attributes.schema.activity',
	product = 'home.attributes.schema.product',
	general = 'home.attributes.schema.general',
}

export const schemaTabConfig: Record<
	ESchemaGroups,
	Array<{value: DocumentType; label: string}>
> = {
	[ESchemaGroups.Account]: [
		{
			value: DocumentType.Account,
			label: 'Accounts',
		},
	],
	[ESchemaGroups.Contact]: [
		{
			value: DocumentType.Contact,
			label: 'Contacts',
		},
	],
	[ESchemaGroups.Activity]: [
		{
			value: DocumentType.CustomIntent,
			label: 'D&B Buyer Intent',
		},
		{
			value: DocumentType.MarketingActivity,
			label: 'Marketing Activities',
		},
		{
			value: DocumentType.Opportunity,
			label: 'Opportunities',
		},
		{
			value: DocumentType.SalesActivityEvent,
			label: 'Sales Activities',
		},
		{
			value: DocumentType.WebVisit,
			label: 'Web Visits',
		},
		{
			value: DocumentType.G2Intent,
			label: 'G2 Buyer Intent',
		},
		{
			value: DocumentType.TrustRadiusIntent,
			label: 'TrustRadius Intent',
		},
	],
	[ESchemaGroups.Product]: [
		{
			value: DocumentType.ProductPurchase,
			label: 'Product Purchases',
		},
		{
			value: DocumentType.ProductBundle,
			label: 'Product Bundles',
		},
		{
			value: DocumentType.ProductHierarchy,
			label: 'Product Hierarchy',
		},
	],
	[ESchemaGroups.General]: [
		{
			value: DocumentType.Campaign,
			label: 'Campaigns',
		},
	],
};
