import {react2angular} from 'react2angular';
import NgState from 'common/app/utilities/ng-state';
import RBAC, {
	AccessGroups,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import LocalStorageUtility from 'common/widgets/utilities/local-storage.utility';
import {loadCategoryMetadata} from 'common/components/datacloud/datacloud.service.vanilla';
import {
	getEnrichments,
	getDataCloudProperty,
	//getCube,
} from 'common/stores/datacloud';
import {ROUTE_PAGE, getAdminRoute} from 'common/app/utilities/AdminPageUtility';
import {
	SegmentQueryHandler,
	SegmentCreate,
} from 'common/components/datacloud/segment/SegmentQueryHelper';
import dataCloudExplorerTemplate from './explorer/explorer.component.html';
import segmentExportTemplate from './explorer/segmentexport/segmentexport.component.html';
import dataCloudTabsTemplate from './tabs/datacloud/datacloud.component.html';
import myDataTabsTemplate from './tabs/mydata/mydata.component.html';
import subHeaderTabsTemplate from './tabs/subheader/subheader.component.html';
//import {FilterService} from '../../../atlas/app/AppCommon/services/FilterService';
import {MarketplaceSegments} from '../../../atlas/app/marketplace-segments/MarketplaceSegments';
import {
	queryBuilderExit,
	queryBuilderRoutes,
} from './query/queryBuilder.utilities';
import {Operator} from './query/query.enums';
import {
	getSegmentExportByExportId,
	//getSegments,
	getSegmentByName,
} from './segment/segment.queries';
import {getPremiumAttributesLimitation} from './datacloud.queries';
import {getMaxEnrichAttributes, getCategories} from './datacloud.helpers';
import {
	Modules,
	isEIFInboundEnabled,
	isEntityMatchEnabled,
	isSegmentationV2Enabled,
	hasModule,
} from 'common/stores/tenantConfig';
import {
	dispatchQueryPropertyValue,
	getQueryProperty,
	dispatchSetAddBucketTreeRoot,
	dispatchClearQueryStore,
	setSegments,
} from 'common/stores/query';
import {Banner} from 'common/app/services/BannerService';
import {setBackButtonStates} from '../../../atlas/app/navigation/header/back/BackButton';
import {
	setupStore,
	resolveQueryRestriction,
	createSegmentRestriction,
} from './query/query.helpers';
import {getMyDataState} from '../../../atlas/app/navigation/sidebar/sidebar.helpers';
import {NoData} from './explorer/nodata/NoData';

/**
 * TODO: Should we delete/comment all
 * the data cloud code??
 */
angular
	.module('common.datacloud', [
		'common.datacloud.explorer',
		'common.datacloud.valuepicker',
		'common.datacloud.tabs.datacloud',
		'common.datacloud.tabs.mydata',
		'common.datacloud.tabs.subheader',
		'common.datacloud.query',
		'common.datacloud.explorer.export',
		'lp.jobs.orphan.export',
	])
	.component('marketplaceSegments', react2angular(MarketplaceSegments))
	.component('noData', react2angular(NoData))
	.run(function () {
		const states = {
			'home.datacloud.*': 'lattice', // TODO: Is this page still available?
			'home.segment.*': 'customer',
			'home.segments*': 'customer',
			'home.ratingsengine.*': 'customer',
		};
	})
	.provider('DataCloudResolves', function DataCloudResolvesProvider() {
		this.$get = function DataCloudResolvesFactory() {
			return {
				main: {
					Enrichments: [
						async function () {
							return getEnrichments();
						},
					],
					CategoryMetadata: [() => loadCategoryMetadata()],
					EnrichmentTopAttributes: [
						'$q',
						'Enrichments',
						'CollectionStatus',
						async function ($q, Enrichments, CollectionStatus) {
							const deferred = $q.defer();

							let tenantIsAtlas = false;

							// UIVersion of 4.0 should correspond to Atlas,
							// while a UIVersion of 3.0 should correspond to LPI.
							if (
								LocalStorageUtility.getClientSession()?.Tenant?.UIVersion &&
								LocalStorageUtility.getClientSession()?.Tenant?.UIVersion ===
									'4.0'
							) {
								tenantIsAtlas = true;
							}

							const hasCollectionStatus =
								CollectionStatus != null &&
								(CollectionStatus.AccountCount > 0 ||
									CollectionStatus.ContactCount > 0);

							if (
								(tenantIsAtlas && hasCollectionStatus) ||
								(!tenantIsAtlas && (Enrichments || []).length !== 0)
							) {
								return getCategories();
							}

							return deferred.promise;
						},
					],
					EnrichmentPremiumSelectMaximum: [
						async function () {
							return getPremiumAttributesLimitation();
						},
					],
					EnrichmentSelectMaximum: [
						async function () {
							return getMaxEnrichAttributes();
						},
					],
					// below resolves are needed. Do not removed
					// override at child state when needed
					LookupResponse: [
						function () {
							return {attributes: null};
						},
					],
					QueryRestriction: [
						function () {
							return null;
						},
					],
					WorkingBuckets: [
						function () {
							return null;
						},
					],
					RatingsEngineModels: [
						function () {
							return null;
						},
					],
				},
			};
		};
	})
	.config(function ($stateProvider, DataCloudResolvesProvider) {
		const sameSegmentTabTransitionCheck = ($state) => {
			return (
				queryBuilderRoutes.includes(
					$state.transition._targetState._identifier
				) &&
				queryBuilderRoutes.includes($state.current.name) &&
				!($state.transition._targetState._params.category === '')
			);
		};
		const DataCloudResolves = DataCloudResolvesProvider.$get().main;

		$stateProvider
			.state('home.datacloud', {
				url: '/datacloud',
				resolve: DataCloudResolves,
				redirectTo: 'home.datacloud.explorer',
			})
			.state('home.datacloud.explorer', {
				url: '/explorer/:section/:category/:subcategory',
				params: {
					pageIcon: 'ico-enrichment',
					pageTitle: 'Data Cloud Explorer',
					LoadingText: 'Loading DataCloud Attributes',
					section: 'edit',
					category: {dynamic: true, value: ''},
					subcategory: {dynamic: true, value: ''},
				},
				resolve: {
					LookupResponse() {
						return {attributes: null};
					},
				},
				views: {
					'summary@': {
						controller: 'DataCloudTabsController',
						controllerAs: 'vm',
						template: dataCloudTabsTemplate,
					},
					'main@': {
						controller: 'DataCloudController',
						controllerAs: 'vm',
						template: dataCloudExplorerTemplate,
					},
				},
			})
			.state('home.datacloud.insights', {
				url: '/tabs',
				params: {
					pageIcon: 'ico-enrichment',
					pageTitle: 'Data Cloud Explorer',
					LoadingText: 'Loading DataCloud Attributes',
					section: 'insights',
					category: {dynamic: true, value: ''},
					subcategory: {dynamic: true, value: ''},
				},
				views: {
					'main@': {
						controller: 'DataCloudController',
						controllerAs: 'vm',
						template: dataCloudExplorerTemplate,
					},
				},
			})
			.state('home.segment', {
				url: '/segment/:segment',
				params: {
					section: 'segment.analysis',
					segment: 'Create',
					reload: true,
				},
				onExit: [
					'$state',
					function ($state) {
						if (sameSegmentTabTransitionCheck($state)) {
							return;
						}

						const enrichmentsState = getDataCloudProperty('enrichments') || [];

						const enrichments = enrichmentsState.filter(function (item) {
							return item.SegmentChecked;
						});

						enrichments.forEach(function (item) {
							// eslint-disable-next-line no-param-reassign
							delete item.SegmentChecked;
						});

						dispatchClearQueryStore();
					},
				],
				onEnter: [
					async function () {
						await setSegments();
					},
				],
				resolve: angular.extend({}, DataCloudResolves, {
					RerouteToNoData: [
						'$state',
						'$stateParams',
						'CollectionStatus',
						function ($state, $stateParams, CollectionStatus) {
							if (
								CollectionStatus &&
								CollectionStatus.AccountCount === 0 &&
								CollectionStatus.ContactCount === 0
							) {
								$state.go(
									'home.nodata',
									{
										tenantName: $stateParams.tenantName,
										segment: $stateParams.segment,
									},
									{reload: true}
								);
							}
						},
					],
					QueryRestriction: [
						'$stateParams',
						'$state',
						'$q',
						function ($stateParams, $state, $q) {
							const {
								segment: segmentName,
								modelId,
								tenantName,
								subSegment,
								ssviCardName,
								newAccountRestriction,
								customParentSegmentName,
							} = $stateParams;
							const paramHandler = new SegmentQueryHandler($stateParams);
							if (sameSegmentTabTransitionCheck($state)) {
								const segment = getQueryProperty('segment');
								/**
								 * 1 create $stateParams.segment === 'Create' && segment === null
								 * 2 edit segment?.name === $stateParams.segment
								 */
								if (
									(paramHandler.isCreate() && !segment?.name) ||
									segment?.name === segmentName
								) {
									return;
								}
							}
							const deferred = $q.defer();
							const callBack = (result) => deferred.resolve(result);
							if (paramHandler.isCreate()) {
								if (paramHandler.isCustomParentSegment()) {
									const member_restriction = createSegmentRestriction(
										customParentSegmentName,
										Operator.AND
									);
									setupStore({
										member_restriction,
										name: SegmentCreate,
										customParentSegmentName,
									});
									callBack({memberRestrictions: member_restriction});
								} else {
									resolveQueryRestriction({
										subSegment,
										newAccountRestriction,
										isEdit: false,
										callBack,
										ssviCardName,
									});
								}
							} else {
								getSegmentByName(segmentName)
									.then(function (result) {
										if (segmentName && !result) {
											if (modelId) {
												$state.go(
													'home.model.segmentation',
													{modelId},
													{notify: true, reload: true}
												);
											} else {
												$state.go(
													'home.segments',
													{tenantName},
													{notify: true, reload: true}
												);
											}
										} else {
											return setupStore(result);
										}
									})
									.then(function () {
										resolveQueryRestriction({
											subSegment: null,
											newAccountRestriction: null,
											isEdit: true,
											callBack,
										});
									})
									.catch(() => {});
							}
							return deferred.promise;
						},
					],
					AccountsCoverage: [
						function () {
							return null;
						},
					],
					Config: [
						function () {
							return null;
						},
					],
					Segment: [
						'$stateParams',
						'$state',
						'$q',
						function ($stateParams, $state, $q) {
							const deferred = $q.defer();
							const segmentName = $stateParams.segment;

							if (segmentName !== 'Create') {
								getSegmentByName(segmentName)
									.then(function (result) {
										deferred.resolve(result);
									})
									.catch(() => {});
							} else {
								deferred.resolve(null);
							}
							return deferred.promise;
						},
					],
				}),
				views: {
					'summary@': {
						controller: 'MyDataTabsController',
						controllerAs: 'vm',
						template: myDataTabsTemplate,
					},
					'subsummary@': {
						controller: 'SubHeaderTabsController',
						controllerAs: 'vm',
						template: subHeaderTabsTemplate,
					},
				},
				redirectTo: 'home.segment.explorer',
			})
			.state('home.segment.explorer', {
				url: '/explorer',
				onExit: [
					function () {
						dispatchSetAddBucketTreeRoot(null);
					},
				],
				params: {
					section: 'segment.analysis',
				},
				redirectTo: 'home.segment.explorer.attributes',
			})
			.state('home.segment.explorer.attributes', {
				url: '/attributes/:category/:subcategory',
				params: {
					segment: 'segment.name',
					pageTitle: 'My Data',
					pageIcon: 'ico-analysis',
					section: 'segment.analysis',
					category: {dynamic: true, value: ''},
					subcategory: {dynamic: true, value: ''},
				},
				onEnter: [
					'$state',
					'$stateParams',
					async function ($state, $stateParams) {
						if (isSegmentationV2Enabled()) {
							$state.go('home');
						}

						if (
							!RBAC.hasAccess(RBACInterface.ATTRIBUTES_TAB, RBACActions.VIEW)
						) {
							$state.go(getMyDataState(), {
								...$stateParams,
								pageTitle: 'Accounts',
							});
						}
						const name = $stateParams.segment;

						const isCreateState = name === 'Create';

						if (!isCreateState) {
							await getSegmentByName(name).then((result) => {
								setBackButtonStates({
									backLabel: result.display_name,
									backName: 'home.segments_new',
									currentState: 'home.segment.explorer.attributes',
									hide: false,
								});
							});
						}
					},
				],
				onExit: ($state, $q) => {
					Banner.reset();
					dispatchQueryPropertyValue('cancelUpdateBucketCalls', true);
					const queryBuilderConfig = {
						$state,
					};
					const deferred = $q.defer();
					queryBuilderExit(queryBuilderConfig).then((exit) => {
						if (exit) {
							deferred.resolve(true);
						} else {
							deferred.reject('user cancelled action');
							HideSpinner();
						}
					});
					return deferred.promise;
				},
				resolve: {
					LookupResponse: [
						function () {
							return {attributes: null};
						},
					],
				},
				views: {
					'main@': {
						controller: 'DataCloudController',
						controllerAs: 'vm',
						template: dataCloudExplorerTemplate,
					},
					'header.back@': 'backNav',
				},
			})
			.state('home.nodata', {
				url: '/nodata',
				onEnter: ($state) => {
					NgState.setAngularState($state);
				},
				params: {
					pageTitle: 'My Data',
					pageIcon: 'ico-analysis',
				},
				views: {
					'main@': 'noData',
				},
			})
			.state('home.exportSegment', {
				url: '/export/:exportID',
				params: {
					pageTitle: 'Segment Export',
					pageIcon: 'ico-analysis',
					section: 'segment.analysis',
				},
				resolve: {
					SegmentExport: [
						'$stateParams',
						function ($stateParams) {
							return getSegmentExportByExportId($stateParams.exportID);
						},
					],
				},
				views: {
					'main@': {
						controller: 'SegmentExportController',
						controllerAs: 'vm',
						template: segmentExportTemplate,
					},
				},
			})
			.state('home.orphanexport', {
				url: '/orphanexport/:exportID',
				params: {
					pageTitle: 'Orphan Export',
					pageIcon: 'ico-analysis',
				},
				views: {
					'main@': 'orphanExport',
				},
			})
			.state('home.marketplaceSegments', {
				url: '/marketplace-segments',
				params: {
					pageTitle: 'Marketplace Segments',
					pageIcon: 'ico-segments',
				},
				views: {
					'main@': 'marketplaceSegments',
				},
			});
	});
