import classnames from 'classnames';
import React from 'common/react-vendor';
import {
	BucketCmp,
	BucketType,
	Period,
	TreeType,
} from 'common/components/datacloud/query/query.enums';
import {
	BucketRestriction,
	InputChangeData,
	Val,
} from 'common/components/datacloud/query/query.types';
import {
	changeTimeframePeriod,
	getBucketCmp,
	getPeriodValue,
} from 'common/components/datacloud/query/advanced/tree/tree.helpers';
import {changeCmp, changeValue, getValue} from '../dateAttribute.helpers';
import {DateRange} from '../../edit/DateRange/DateRange';
import {NumericalRange} from '../../edit/NumericalRange/NumericalRange';
import {
	defaultPeriods,
	allPeriods,
	cmpOptions,
	secondTimeFrameCmps,
	toPeriodCmps,
	toTimeCmps,
	periodNumberCmps,
	periodSelectCmps,
	allPeriodsCmps,
} from './dateAttributeEdit.constants';
import {
	getInitialPeriodNumberConfig,
	getInitialPeriodTimeConfig,
} from './dateAttributeEdit.helpers';
import {isNumericalRangeValid} from '../../edit/NumericalRange/numericalRange.validations';
import styles from './DateAttributeEdit.module.scss';

const {useState, useCallback} = React;

interface DateAttributeEditProps {
	bucketRestriction: BucketRestriction;
	type: BucketType | TreeType;
	onNumericalRangeValidation(isValid: boolean): void;
	onBucketRestrictionChange(newBucketRestriction?: BucketRestriction): void;
}

const DateAttributeEdit = ({
	bucketRestriction,
	type,
	onNumericalRangeValidation,
	onBucketRestrictionChange,
}: DateAttributeEditProps): React.ReactElement => {
	const timeCmp =
		getBucketCmp({
			bucketRestriction,
			type,
			subType: BucketType.Date,
		}) || BucketCmp.EVER;

	const [periodNumberConfig, setPeriodNumberConfig] = useState(
		getInitialPeriodNumberConfig(timeCmp, bucketRestriction.bkt)
	);
	const [periodTimeConfig, setPeriodTimeConfig] = useState(
		getInitialPeriodTimeConfig(timeCmp, bucketRestriction.bkt)
	);

	const [selectedPeriod, setSelectedPeriod] = useState<Period>(
		getPeriodValue({
			bucketRestriction,
			type: type as BucketType & TreeType,
			subType: BucketType.Date,
		}) as Period
	);

	const periodsList = allPeriodsCmps.includes(timeCmp)
		? allPeriods
		: defaultPeriods;

	// #region show input flags
	const isTimeFrameVisible = secondTimeFrameCmps.includes(timeCmp);

	const isPeriodNumberVisible = periodNumberCmps.includes(timeCmp);

	const isPeriodSelectVisible = periodSelectCmps.includes(timeCmp);

	const isToPeriodVisible = toPeriodCmps.includes(timeCmp);

	const isToTimeVisible = toTimeCmps.includes(timeCmp);
	// #endregion show input flags

	const setInitialStates = (
		newCmp: BucketCmp,
		newBucketRestriction: BucketRestriction
	): void => {
		onNumericalRangeValidation(!periodNumberCmps.includes(newCmp));

		setPeriodNumberConfig((previousState) => ({
			...previousState,
			from: {
				...previousState.from,
				value: undefined,
				min: getInitialPeriodNumberConfig(newCmp, bucketRestriction.bkt)?.from
					?.min,
			},
			to: {
				...previousState.to,
				value: undefined,
			},
		}));

		setPeriodTimeConfig((previousState) => ({
			...previousState,
			from: {
				...previousState.from,
				initial: undefined,
			},
			to: {
				...previousState.to,
				initial: undefined,
			},
		}));

		setSelectedPeriod(
			getPeriodValue({
				bucketRestriction: newBucketRestriction,
				type,
				subType: BucketType.Date,
			}) as Period
		);
	};

	const handleCmpChange = (newCmp: BucketCmp): void => {
		const comparisonArray = [BucketCmp.IN_CURRENT_PERIOD, BucketCmp.WITHIN];

		let period: Period | BucketType = Period.Day;

		if (comparisonArray.includes(newCmp)) {
			period = Period.Week;
		}

		if (secondTimeFrameCmps.includes(newCmp)) {
			period = BucketType.Date;
		}

		const newBucketRestriction = {
			...bucketRestriction,
			bkt: changeCmp({
				bkt: bucketRestriction.bkt,
				cmp: newCmp,
				period,
				values: [],
			}),
		};

		onBucketRestrictionChange(newBucketRestriction);

		setInitialStates(newCmp, newBucketRestriction);
	};

	const changePeriod = (Period: Period): void => {
		onBucketRestrictionChange(
			changeTimeframePeriod({
				bucketRestriction,
				type: type as TreeType & BucketType,
				value: {
					Period,
					Vals: bucketRestriction?.bkt?.Fltr?.Vals,
				},
			})
		);
	};

	const updateBucketRestriction = useCallback(
		(index: number, value: Val): BucketRestriction => {
			const newBucketRestriction = {...bucketRestriction};

			if (newBucketRestriction.bkt?.Fltr?.Vals) {
				newBucketRestriction.bkt.Fltr.Vals = changeValue({
					cmp: timeCmp,
					values: newBucketRestriction?.bkt?.Fltr?.Vals as Val[],
					index,
					value,
				});

				onBucketRestrictionChange(newBucketRestriction);
			}

			return newBucketRestriction;
		},
		/**
		 * We can skip 'timeCmp' since that is a constant
		 * that depends on the bucketRestriction.
		 *
		 * If we add 'timeCmp' we would need to encapsulate
		 * the value in a useMemo.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[bucketRestriction, onBucketRestrictionChange]
	);

	const handlePeriodTimeChange = useCallback(
		({index, value}: InputChangeData): void => {
			const newBucketRestriction = updateBucketRestriction(index, value);

			setPeriodTimeConfig((previousState) => ({
				...previousState,
				from: {
					...previousState.from,
					initial: getValue({
						type: BucketType.Date,
						cmp: timeCmp,
						bkt: newBucketRestriction.bkt,
						index: 0,
					}),
				},
				to: {
					...previousState.to,
					initial: getValue({
						type: BucketType.Date,
						cmp: timeCmp,
						bkt: newBucketRestriction.bkt,
						index: 1,
					}),
				},
			}));

			onBucketRestrictionChange(newBucketRestriction);
		},
		/**
		 * We can skip 'timeCmp' since that is a constant
		 * that depends on the bucketRestriction.
		 *
		 * If we add 'timeCmp' we would need to encapsulate
		 * the value in a useMemo.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateBucketRestriction]
	);

	const handlePeriodNumberChange = ({index, value}: InputChangeData): void => {
		const newBucketRestriction = updateBucketRestriction(index, value);

		const fromValue = getValue({
			type: BucketType.Date,
			cmp: timeCmp,
			bkt: newBucketRestriction.bkt,
			index: 0,
		});

		const toValue = getValue({
			type: BucketType.Date,
			cmp: timeCmp,
			bkt: newBucketRestriction.bkt,
			index: 1,
		});

		onNumericalRangeValidation(
			isNumericalRangeValid({
				vals: [fromValue, toValue],
				rangeConfig: periodNumberConfig,
				showFrom: isPeriodNumberVisible,
				showTo: isToPeriodVisible,
			})
		);

		setPeriodNumberConfig((previousState) => ({
			...previousState,
			from: {
				...previousState.from,
				value: fromValue,
			},
			to: {
				...previousState.to,
				value: toValue,
			},
		}));
	};

	const handleTimeframeSelectChange = (
		event: React.ChangeEvent<HTMLSelectElement>
	): void => {
		const value = event.target.value as BucketCmp;

		if (value) {
			handleCmpChange(value);
		}
	};

	const handlePeriodSelectChange = (
		event: React.ChangeEvent<HTMLSelectElement>
	): void => {
		const value = event.target.value as Period;

		if (value) {
			changePeriod(value);
			setSelectedPeriod(value);
		}
	};

	return (
		<div className='query-section-item-operation date-attribute'>
			<div className='flex-container'>
				<div className='flex-container time-frame'>
					<div className={classnames('date-element-title', styles.flexCenter)}>
						in timeframe
					</div>
					<select
						onChange={(event) => handleTimeframeSelectChange(event)}
						value={timeCmp}>
						{cmpOptions.map(({value, label}) => (
							<option key={value} value={value}>
								{label}
							</option>
						))}
					</select>
				</div>

				{isTimeFrameVisible && (
					<DateRange
						config={periodTimeConfig}
						showFrom={isTimeFrameVisible}
						showTo={isToTimeVisible}
						bucketRestriction={bucketRestriction}
						onChange={handlePeriodTimeChange}
					/>
				)}

				{isPeriodNumberVisible && (
					<NumericalRange
						config={periodNumberConfig}
						showMessage={true}
						showFrom={isPeriodNumberVisible}
						showTo={isToPeriodVisible}
						bucketRestriction={bucketRestriction}
						onChange={handlePeriodNumberChange}
					/>
				)}

				{!isTimeFrameVisible &&
					!isPeriodSelectVisible &&
					isPeriodNumberVisible && (
						<div className={styles.flexCenter}>Day(s)</div>
					)}

				{isPeriodSelectVisible && (
					<select
						onChange={(event) => handlePeriodSelectChange(event)}
						value={selectedPeriod}>
						{periodsList.map(({value, label}) => (
							<option key={value} value={value}>
								{label}
							</option>
						))}
					</select>
				)}
			</div>
		</div>
	);
};

/**
 * TODO: Angular directive components can't use camelCase attributes...
 * Since we are migrating everything to React I feel that ideally
 * we should keep using camelCases for props, that is why I am creating this temporary
 * wrapper to pass the angular attributes to the React component...
 *
 * DONT use this component!
 * Use DateAttributeEdit if you are refactoring an angular component to React.
 * This component purpose is only to be use in the temporary
 * date-range.component react2angular
 *
 * TODO: DELETE ME Once all instances of <date-attribute-edit />
 * are removed...
 */

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
const DateAttributeEditWithAngularProps = ({
	bucketrestriction,
	type,
	onnumericalrangevalidation,
	onbucketrestrictionchange,
}: Record<string, any>): React.ReactElement => (
	<DateAttributeEdit
		bucketRestriction={bucketrestriction}
		type={type}
		onNumericalRangeValidation={onnumericalrangevalidation}
		onBucketRestrictionChange={onbucketrestrictionchange}
	/>
);

export {DateAttributeEditWithAngularProps};
