import template from './percent-item.component.html';
import {
	getCompareReadable,
	getDirectionReadable,
	getValuesFormatted,
} from 'common/components/datacloud/query/advanced/tree/percent/percentStore.helpers';

angular
	.module('common.datacloud.query.builder.tree.edit.percent.item', [])
	.component('percentAttribute', {
		template,

		bindings: {
			bucketrestriction: '=',
		},

		controller: function () {
			this.$onInit = function () {};

			this.getDirection = function () {
				return getDirectionReadable(this.bucketrestriction);
			};

			this.getCmp = function () {
				return getCompareReadable(this.bucketrestriction);
			};

			this.getValues = function () {
				return getValuesFormatted(this.bucketrestriction);
			};
		},
	});
