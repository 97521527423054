import moment from 'moment';
import * as yup from 'yup';
import type {SchemaOf, TestContext} from 'yup';
import type {BucketRestriction} from '../../../../query.types';
import {BucketCmp} from '../../../../query.enums';

// #region Yup Schema InputConfig
interface IsValidRangeParams {
	input: 'from' | 'to';
	context: TestContext;
	value?: string;
}
const isValidRange = ({input, context, value}: IsValidRangeParams): boolean => {
	const isRange = context.parent.showFrom && context.parent.showTo;

	if (!isRange) {
		return true;
	}

	const fromValue = input === 'from' ? value : context.parent.from;
	const toValue = input === 'to' ? value : context.parent.to;

	if (moment(fromValue).isSame(toValue)) {
		return false;
	}

	return moment(fromValue).isBefore(toValue);
};

interface RangeSchema {
	showFrom?: boolean;
	from?: string;
	showTo?: boolean;
	to?: string;
}

const schema = (): SchemaOf<RangeSchema> =>
	yup.object().shape({
		showFrom: yup.boolean(),
		from: yup.string().when('showFrom', {
			is: true,
			then: yup
				.string()
				.required()
				.notOneOf(['any (*)'])
				.test('range', 'value out of range', (value, context) =>
					isValidRange({
						input: 'from',
						context,
						value,
					})
				),
		}),
		showTo: yup.boolean(),
		to: yup.string().when('showTo', {
			is: true,
			then: yup
				.string()
				.required()
				.notOneOf(['any (*)'])
				.test('range', 'value out of range', (value, context) =>
					isValidRange({input: 'to', context, value})
				),
		}),
	});
// #endregion Yup Schema InputConfig

interface IsFieldValidParams {
	key: keyof RangeSchema;
	rangeSchema: SchemaOf<RangeSchema>;
	rangeValue: RangeSchema;
}
const isFieldValid = ({
	key,
	rangeSchema,
	rangeValue,
}: IsFieldValidParams): boolean => {
	/**
	 * Try to execute the yup method validateSyncAt.
	 * If the function fails, yup throws an error.
	 * If it's valid, yup returns the current value.
	 *
	 * The function will fail if the above schema is not
	 * completed for the field.
	 */
	try {
		const fieldValue = rangeSchema.validateSyncAt(key, rangeValue);

		return typeof fieldValue === 'string' || typeof fieldValue === 'undefined';
	} catch (error) {
		// TODO: Should we display the error?
		return false;
	}
};

const isDateRangeValid = (bucketRestriction: BucketRestriction): boolean => {
	const dateRangeCmpTypes = ['BETWEEN_DATE', 'BEFORE', 'AFTER'];

	const timeCmp = bucketRestriction?.bkt?.Txn?.Time?.Cmp || '';
	const fltrCmp = bucketRestriction?.bkt?.Fltr?.Cmp || '';

	const isDateRangeCmp = dateRangeCmpTypes.some(
		(cmp) => cmp === timeCmp || cmp === fltrCmp
	);

	/**
	 * If the cmp is not of type date range
	 * that means that the component is hidden and
	 * we can skip the validations
	 */
	if (!isDateRangeCmp) {
		return true;
	}

	const isRangeDate =
		timeCmp === BucketCmp.BETWEEN_DATE || fltrCmp === BucketCmp.BETWEEN_DATE;

	const bucketValues =
		bucketRestriction?.bkt?.Fltr?.Vals ||
		bucketRestriction?.bkt?.Txn?.Time?.Vals;

	const [firstValue, secondValue] = bucketValues || [];

	/**
	 * The existing forms manage the range values
	 * with an array, and if the 'from' input is hidden
	 * the toValue is push to the first place of the array.
	 * That is why if it is not a range we chose the
	 * first value.
	 */
	const toValue = isRangeDate ? firstValue : undefined;
	const rangeToValue = isRangeDate ? secondValue : toValue;

	return schema().isValidSync({
		showFrom: isDateRangeCmp,
		from: firstValue,
		showTo: isRangeDate,
		to: rangeToValue,
	});
};

export {schema, isFieldValid, isDateRangeValid};
