import {isRulesBasedModelMode, setPublicProperty} from 'common/stores/query';
import template from './tree-item-edit.component.html';
import {
	changeBooleanValue as changeTreeBooleanValue,
	changeCmpValue as changeTreeCmpValue,
	changeValue,
	changeValues,
	getBooleanModel,
	getCmp,
	getCubeBktList as getTreeCubeBktList,
	getOperationValue,
	getValue,
	resetBktValues,
	showType,
	showEmptyOption,
	changeNumericalCmpValue,
} from '../tree.helpers';
import {
	numericalOperations,
	stringOperations,
	noInputs,
	segmentRelationOptions,
	segmentEntityOptions,
} from '../tree.constants';
import {getEntityFieldBuckets, getActivityEntityBuckets} from '../tree.queries';
import {BucketType} from '../../../query.enums';
import {isNumericalRangeValid} from './NumericalRange/numericalRange.validations';
import {isDateRangeValid} from './DateRange/dateRange.validations';
import NoticeService from '../../../../../notice/NoticeService';
import {getListRestrictionsWithTooManyValuesError} from '../../../query.helpers';

angular
	.module('common.datacloud.query.builder.tree.edit', [
		'common.datacloud.query.builder.tree.edit.date.attribute',
	])
	.directive('queryItemEditDirective', function () {
		return {
			restrict: 'E',
			scope: {
				vm: '=',
			},
			require: 'ngModel',
			template,
			controllerAs: 'vm',
			controller($scope, $filter, $timeout) {
				const {vm} = $scope;

				vm.isTransactionEditValid = true;
				vm.isDateAttributeValid = true;

				vm.booleanChanged = false;
				vm.showFromNumerical = false;
				vm.showToNumerical = false;
				vm.vals = vm.vals || [];
				vm.buckets = vm.buckets || [];
				vm.loading = vm.buckets.length === 0;
				vm.form = $scope.form || {};
				if (vm.showSegmentationV2 && !vm.root.isNewRedesign && vm.item.Entity !== 'Rating') {
					vm.header = ['VALUE'];
				} else {
					vm.header = ['VALUE', 'RECORDS'];
				}
				vm.chipsOperations = [
					'EQUAL',
					'IN_COLLECTION',
					'NOT_EQUAL',
					'NOT_IN_COLLECTION',
					'CONTAINS',
					'NOT_CONTAINS',
				];

				vm.init = () => {
					const isSegment = vm.tree.hasOwnProperty('segmentMemberRestriction');
					const bucketKey = isSegment
						? 'segmentMemberRestriction'
						: 'bucketRestriction';
					vm.isActivity =
						vm.showTimeSeries &&
						!!vm.tree[bucketKey].entityType &&
						vm.showItem('String');
					vm.stringPlaceholder = vm.isActivity
						? 'Search for attributes'
						: 'Add new value';
					vm.initVariables();
					vm.resetCmp();
				};

				vm.initVariables = () => {
					vm.string_operations = stringOperations;

					if (vm.showItem('Boolean') && !vm.showItem('Percent')) {
						vm.booleanValue = getBooleanModel(vm.tree.bucketRestriction);
					}

					if (vm.showItem('String')) {
						convertEqualToCollection();
						const value = getOperationValue({
							bucketRestriction: vm.tree.bucketRestriction,
							type: BucketType.String,
						});

						vm.operation = getCmp(vm.tree.bucketRestriction);

						vm.vals = value || [];
						if (vm.isActivity) {
							if (vm.buckets.length === 0 && vm.vals) {
								vm.buckets.push(vm.tree.bucketRestriction.bkt);
							}
							vm.getBuckets();
						}
					}
					if (vm.showItem('Enum')) {
						convertEqualToCollection();
						switch (vm.item.FundamentalType) {
							case 'enum':
							case 'email':
							case 'alpha':
								vm.operation = getCmp(vm.tree.bucketRestriction);
								break;
							case 'numeric':
								vm.operation = getCmp(vm.tree.bucketRestriction);

								vm.string_operations = numericalOperations;

								initNumericalRange();
								break;
						}
						if (!vm.showSegmentationV2 || vm.item.Entity === 'Rating') {
							vm.header[1] = vm.item.Entity + 's';
						}
						vm.vals = vm.tree.bucketRestriction.bkt.Vals;
						if (vm.buckets.length === 0 && vm.vals) {
							vm.buckets.push(vm.tree.bucketRestriction.bkt);
						}
						if (
							!vm.root.entityList ||
							(vm.showSegmentationV2 &&
								vm.root.entityList &&
								vm.root.entityList[vm.entity].includes(vm.item.Entity) &&
								vm.showChips())
						) {
							vm.getBuckets();
						}
					}
					if (vm.showItem('Numerical')) {
						vm.operation = getCmp(vm.tree.bucketRestriction);

						vm.vals = vm.tree.bucketRestriction.bkt.Vals;

						initNumericalRange();
					}
					if (vm.showItem('Date')) {
					}
					if (vm.showItem('Member')) {
						vm.relation = vm.tree.segmentMemberRestriction.relation;
						vm.relation_options = segmentRelationOptions;
						vm.entity = vm.tree.segmentMemberRestriction.entity;
						vm.entity_options = segmentEntityOptions;
						const [segment] = $filter('filter')(
							vm.root.segmentsList,
							{name: vm.tree.segmentMemberRestriction.segmentName},
							true
						);
						vm.DisplayName = segment.display_name;
					}
				};

				vm.changeBooleanValue = function () {
					vm.booleanChanged = true;
					let value = vm.booleanValue;
					const fn = (operation) => {
						vm.tree.bucketRestriction.bkt.Cmp = vm.operation = operation;

						vm.tree.bucketRestriction = changeTreeCmpValue({
							bucketRestriction: vm.tree.bucketRestriction,
							value: vm.operation,
						});

						return '';
					};

					switch (value) {
						case 'Present':
							value = fn('IS_NOT_NULL');
							break;
						case 'Empty':
							value = fn('IS_NULL');
							break;
						case 'Yes':
							fn('EQUAL');
							break;
						case 'No':
							fn('EQUAL');
							break;
					}

					vm.tree.bucketRestriction = changeTreeBooleanValue(
						vm.tree.bucketRestriction,
						value
					);

					vm.checkBoolOpSelected(value);
				};

				vm.checkBoolOpSelected = function (value) {
					const bkt = vm.tree.bucketRestriction.bkt;

					switch (bkt.Cmp) {
						case 'EQUAL':
							return value == (bkt.Vals?.length > 0 ? bkt.Vals[0] : '');
						case 'IS_NULL':
							return value == 'Empty';
						case 'IS_NOT_NULL':
							return value == 'Present';
					}

					return false;
				};

				vm.isNumerical = function () {
					const funType = vm.item.FundamentalType;
					let bktType = '';
					if (vm.item.cube && vm.item.cube.Bkts) {
						bktType = vm.item.cube.Bkts.Type;
					}
					return (
						funType == 'enum' || funType == 'numeric' || bktType == 'Numerical'
					);
				};

				vm.changeCmpValue = function () {
					vm.clear = true;
					const _operation = vm.tree.bucketRestriction.bkt.Cmp;
					const numerical = vm.isNumerical();

					switch (vm.operation) {
						case 'EQUAL':
						case 'IN_COLLECTION':
							vm.operation = 'IN_COLLECTION';
							break;
						case 'NOT_EQUAL':
						case 'NOT_IN_COLLECTION':
							vm.operation = 'NOT_IN_COLLECTION';
							break;
						case 'CONTAINS':
						case 'NOT_CONTAINS':
							break;
						default:
							if (vm.vals != '') {
								vm.vals.length = 0;
							}
					}

					if (
						vm.chipsOperations.indexOf(_operation) < 0 &&
						vm.chipsOperations.indexOf(vm.operation) > -1
					) {
						vm.vals.length = 0;
					}

					if (numerical) {
						showNumericalRange();

						if (vm.rangeConfig) {
							vm.rangeConfig.from.value = undefined;
							vm.rangeConfig.to.value = undefined;
						}

						vm.tree.bucketRestriction = changeNumericalCmpValue(
							vm.tree.bucketRestriction,
							vm.operation
						);
					} else {
						vm.tree.bucketRestriction = changeTreeCmpValue({
							bucketRestriction: vm.tree.bucketRestriction,
							value: vm.operation,
						});
					}
					vm.initVariables();
				};

				vm.showChips = function () {
					const equals = [
						'EQUAL',
						'IN_COLLECTION',
						'NOT_EQUAL',
						'NOT_IN_COLLECTION',
					];
					const isPreset = vm.editMode == 'Preset';
					const check = vm.showItem;
					const cmp = equals.indexOf(vm.operation) > -1;
					return (
						!isPreset &&
						cmp &&
						(check('Enum') || check('Numerical') || check('String'))
					);
				};

				vm.showChipsNoList = function () {
					const equals = ['CONTAINS', 'NOT_CONTAINS'];
					const isPreset = vm.editMode == 'Preset';
					const check = vm.showItem;
					const cmp = equals.indexOf(vm.operation) > -1;
					return (
						!isPreset &&
						cmp &&
						(check('Enum') || check('Numerical') || check('String'))
					);
				};

				vm.updateChips = (items) => {
					if (vm.clear) {
						vm.vals.length = 0;
						vm.clear = false;
					}

					let uniqueUpdatedValues = [
						...new Set([...items.map(({Lbl}) => Lbl), ...vm.vals]),
					];

					const error = getListRestrictionsWithTooManyValuesError({
						...vm.tree,
						bucketRestriction: {
							...vm.tree.bucketRestriction,
							bkt: {
								...vm.tree.bucketRestriction.bkt,
								Vals: uniqueUpdatedValues,
							},
						},
					});

					if (error) {
						uniqueUpdatedValues = [];
						NoticeService.warning(error);
					}

					vm.tree.bucketRestriction = changeValues(
						vm.tree.bucketRestriction,
						uniqueUpdatedValues
					);

					vm.vals = uniqueUpdatedValues;

					vm.changeCmpValue();
				};

				vm.getBuckets = function () {
					if (!vm.isActivity) {
						const id = vm.item.ColumnId;
						const entity = vm.item.Entity;

						getEntityFieldBuckets(entity, id).then(function (response) {
							vm.loading = false;
							vm.buckets.length = 0;

							if (response && response.Bkts && response.Bkts.List) {
								response.Bkts.List.forEach((item) => {
									if (vm.root.isNewRedesign || item?.Cnt > 0) {
										vm.buckets.push(item);
									}
								});
							}

							vm.clear = true;
							$scope.$apply();
						});
					} else {
						const {attr, entityType} = vm.tree.bucketRestriction;
						const [entity, attributeId] = attr.split('.');

						getActivityEntityBuckets(entityType, entity, attributeId).then(
							function (response) {
								vm.loading = false;
								vm.buckets.length = 0;

								if (response) {
									response.forEach((val, index) => {
										const newItem = {
											Cmp: 'EQUAL',
											Cnt: 1,
											Id: index + 1,
											Lbl: val,
											Vals: [val],
										};
										vm.buckets.push(newItem);
									});
								}

								vm.clear = true;
								$scope.$apply();
							}
						);
					}
				};

				vm.getOperationValue = function (operation, index) {
					return getOperationValue({
						bucketRestriction: vm.tree.bucketRestriction,
						type: operation,
						index,
					});
				};

				vm.getCubeBktList = function () {
					return getTreeCubeBktList(vm.item.cube);
				};

				vm.showInput = function (operation) {
					const not_hidden = noInputs.indexOf(operation) < 0;
					const is_alpha = vm.checkAlphaNumeric(operation) === 'alpha';
					return not_hidden && is_alpha;
				};

				vm.showNumericRange = function (operation) {
					const not_hidden = noInputs.indexOf(operation) < 0;
					const is_numeric = vm.checkAlphaNumeric(operation) === 'numeric';
					showNumericalRange(operation);
					return not_hidden && is_numeric;
				};

				vm.checkAlphaNumeric = function (operation) {
					return {
						STARTS_WITH: 'alpha',
						ENDS_WITH: 'alpha',
						GREATER_THAN: 'numeric',
						GREATER_OR_EQUAL: 'numeric',
						LESS_THAN: 'numeric',
						LESS_OR_EQUAL: 'numeric',
						GT_AND_LT: 'numeric',
						GT_AND_LTE: 'numeric',
						GTE_AND_LT: 'numeric',
						GTE_AND_LTE: 'numeric',
					}[operation];
				};

				vm.showUnsetButton = () => isRulesBasedModelMode();

				vm.showEmptyOption = function () {
					return showEmptyOption(vm.tree.bucketRestriction);
				};

				vm.showItem = function (typeToShow) {
					if (vm.showSegmentationV2 && vm.tree.segmentMemberRestriction) {
						return vm.type === typeToShow;
					}

					const {
						tree: {bucketRestriction},
						type,
					} = vm;

					return showType({
						bucketRestriction,
						type,
						typeToShow,
					});
				};				

				vm.validatedNumber = function () {
					const operations = ['IN_COLLECTION', 'NOT_IN_COLLECTION'];
					if (operations.includes(vm.operation) && vm.type === 'Numerical') {
						return vm.vals.filter((number) => !isNaN(Number(number))).length === vm.vals.length;
					}
					return true;
				};

				vm.isValid = function () {
					setPublicProperty(
						'enableSaveSegmentButton',
						$scope.form.$valid === true
					);
					const validatedNumber = vm.validatedNumber();

					const fromValue = vm.getOperationValue(vm.type, 0);
					const toValue = vm.getOperationValue(vm.type, 1);

					const isValidRange = isNumericalRangeValid({
						vals: [fromValue, toValue],
						rangeConfig: vm.rangeConfig,
						showFrom: vm.showFromNumerical,
						showTo: vm.showToNumerical,
					});

					const isValidDate = isDateRangeValid(vm.tree.bucketRestriction);

					return (
						$scope.form.$valid &&
						validatedNumber &&
						isValidRange &&
						isValidDate &&
						vm.isTransactionEditValid &&
						vm.isDateAttributeValid
					);
				};

				vm.callbackChangedNumericalValue = function ({index, value}) {
					changeValue({
						bucketRestriction: vm.tree.bucketRestriction,
						type: vm.type,
						value,
						index,
					});
					$scope.$apply();
				};

				// hacky fix for vanishing operation value -lazarus
				vm.resetCmp = function () {
					const operation = vm.operation;
					vm.operation = '';
					$timeout(() => (vm.operation = operation), 16);
				};

				function finishEditing() {
					vm.editing = false;
					if (vm.tree.bucketRestriction) {
						const {entityType} = vm.tree.bucketRestriction;
						if (!entityType) {
							/**
							 * The updateBucketCount function that
							 * is inheriting the controller its the
							 * function from tree.component.js
							 *
							 * https://github.com/dnb-main/lat-leui/blob/develop/projects/common/components/datacloud/query/advanced/tree/tree.component.js#L408
							 *
							 * If the tree.component.js gets refactor
							 * we need to consider updating this too.
							 *
							 * Module name: 'common.datacloud.query.builder.tree'
							 * Directive name: 'queryTreeDirective'
							 *
							 * Leaving these names in case someone makes the refactor
							 * and makes a global search of these names.
							 */
							vm.updateBucketCount();
						}
					}
					vm.root.updateCount();
					vm.root.setTotalRuleCount();
				}

				vm.clickSet = function ($event) {
					if (vm.tree.bucketRestriction) {
						// add any free text in ChipsController query as an item
						if (
							vm.ChipsController &&
							vm.ChipsController.query.toString() &&
							vm.ChipsController.customVals
						) {
							vm.ChipsController.addCustomValuesWithDelimiterCheck(
								vm.ChipsController.query
							);
						}

						// Verify that saving this restriction passes validation
						const error = getListRestrictionsWithTooManyValuesError({
							...vm.tree,
							bucketRestriction: {
								...vm.tree.bucketRestriction,
								bkt: {
									...vm.tree.bucketRestriction.bkt,
									Vals: vm.vals,
								},
							},
						});

						if (error) {
							NoticeService.warning(error);
							vm.vals.length = 0;
						}

						// is string operation, make sure vm.vals populates bucketRestriction
						if (
							noInputs.indexOf(vm.operation) == -1 &&
							vm.string_operations[vm.operation]
						) {
							vm.tree.bucketRestriction.bkt.Vals = vm.vals;
						}

						// if vals is empty, set operation to check IF PRESENT
						const vals = vm.tree.bucketRestriction.bkt.Vals || [];

						if (noInputs.indexOf(vm.operation) == -1 && vals.length === 0) {
							vm.tree.bucketRestriction.bkt.Cmp = vm.operation = 'IS_NOT_NULL';

							// if (vm.item.FundamentalType == 'numeric') {
							// vm.changeNumericalCmpValue();
							// } else {
							vm.changeCmpValue();
							// }
						}

						// In the rules based modeling page, clicking set should enable the
						// item (set ignored to false)
						if (isRulesBasedModelMode())
							vm.tree.bucketRestriction.ignored = false;
					}
					if (vm.tree.segmentMemberRestriction) {
						if (isRulesBasedModelMode())
							vm.tree.segmentMemberRestriction.ignored = false;
					}

					$event.preventDefault();
					$event.stopPropagation();

					finishEditing();
				};

				vm.clickUnset = ($event) => {
					vm.tree.bucketRestriction.bkt = {};
					vm.tree.bucketRestriction.ignored = true;

					$event.preventDefault();
					$event.stopPropagation();
					finishEditing();
				};

				vm.clickEditMode = function (value) {
					if (!vm.showSegmentationV2 || vm.item.Entity === 'Rating') {
						vm.editMode = value;
						if (value !== 'Custom') {
							var bucket = vm.getCubeBktList()[0];
							if (bucket) {
								(vm.editMode == 'Preset') == bucket.Lbl;
							}
							vm.changePreset(bucket);
						} else {
							vm.tree.bucketRestriction = changeTreeCmpValue({
								bucketRestriction: vm.tree.bucketRestriction,
								value: vm.type,
							});
							vm.initVariables();
						}
					}
				};

				function initNumericalRange(reset) {
					if (!reset) {
						const fromNumerical = getValue({
							bucketRestriction: vm.tree.bucketRestriction,
							type: vm.type,
							index: 0,
						});
						const toNumerical = getValue({
							bucketRestriction: vm.tree.bucketRestriction,
							type: vm.type,
							index: 1,
						});
						const from =
							fromNumerical != null ? Number(fromNumerical) : undefined;
						const to = toNumerical != null ? Number(toNumerical) : undefined;
						vm.rangeConfig = {
							from: {
								name: 'from-numerical',
								value: from,
								index: 0,
								type: 'Numerical',
								step: 1,
							},
							to: {
								name: 'to-numerical',
								value: to,
								index: 1,
								type: 'Numerical',
								step: 1,
							},
						};
						showNumericalRange();
					} else {
						vm.rangeConfig = {
							from: {
								name: 'from-numerical',
								value: undefined,
								index: 0,
								type: 'Numerical',
								step: 1,
							},
							to: {
								name: 'to-numerical',
								value: undefined,
								index: 1,
								type: 'Numerical',
								step: 1,
							},
						};

						vm.tree.bucketRestriction = resetBktValues({
							bucketRestriction: vm.tree.bucketRestriction,
							type: vm.type,
						});

						setTimeout(() => {
							showNumericalRange();
						}, 0);
					}

					return vm.rangeConfig;
				}

				function showNumericalRange(operation) {
					operation = operation || vm.operation;
					switch (operation) {
						case 'GREATER_OR_EQUAL':
						case 'GREATER_THAN':
							vm.showFromNumerical = true;
							vm.showToNumerical = false;
							break;
						case 'LESS_THAN':
						case 'LESS_OR_EQUAL':
							vm.showFromNumerical = false;
							vm.showToNumerical = true;
							break;
						case 'GT_AND_LT':
						case 'GT_AND_LTE':
						case 'GTE_AND_LTE':
						case 'GTE_AND_LT':
							vm.showFromNumerical = true;
							vm.showToNumerical = true;
							break;
						default:
							vm.showFromNumerical = false;
							vm.showToNumerical = false;
					}
				}

				function convertEqualToCollection() {
					const map = {
						EQUAL: 'IN_COLLECTION',
						NOT_EQUAL: 'NOT_IN_COLLECTION',
					};
					const bucket = vm.tree.bucketRestriction.bkt;
					bucket.Cmp = map[bucket.Cmp] || bucket.Cmp;
				}

				vm.handleBucketRestrictionChange = (newBucketRestriction) => {
					vm.tree.bucketRestriction = newBucketRestriction;
				};

				vm.handleNumericalRangeValidation = (isValid) => {
					vm.isTransactionEditValid = isValid;
				};

				vm.setBooleanChanged = (newValue) => {
					vm.booleanChanged = newValue;
				};

				vm.handleDateAttributeValidation = (isValid) => {
					vm.isDateAttributeValid = isValid;
					$scope.$apply();
				};

				vm.init();
			},
		};
	});
