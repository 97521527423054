import React from 'common/react-vendor';
import {
	SvgIconProps,
	GridColDef,
	DNBMenuItemsProps,
	GridFeatureMode,
} from 'common/dnb-uux-vendor';
import {ITrendlineComponent} from 'common/components/trendLine/TrendlineComponent';
import {AptrinsicIdentifyData} from 'common/lib/aptrinsic/aptrinsic.helpers';
import {QuickType} from 'common/widgets/dataviews/numeric-range/quickTimeRange.helpers';
import {ICommonPie} from 'common/components/pie/CommonPie';
import {ActivityAttribute} from './WebInsightsOverviewTypes';
import {AccountVisit} from './WebInsightsCompanyVisitAnalysisTypes';
import {ICommonWorldMap} from '../components/Common/CommonWorldMap';

type ObjectType = Record<string, unknown>;
type ObjectArray = ObjectType[];

enum WebInsightsTabsType {
	Overview = 'webInsightsOverview',
	CompanyVisitAnalysis = 'webInsightsCompanyVisitAnalysis',
	CompanyDetail = 'webInsightsCompanyLists',
	CompanyDetailPageGroup = 'webInsightsCompanyListPageGroup',
	PageGroupAnalysis = 'webInsightsPageGroupAnalysis',
	PageGroupDetail = 'webInsightsPageGroupDetail',
	PageGroupDetailCompany = 'webInsightsPageGroupDetailCompany',
	DashboardConfiguration = 'webInsightsDashboardConfiguration',
	SSVIOverview = 'SSVIOverview',
}

enum PageGroupListRequestName {
	PageGroupInfo = 'page_group_detail_page_group_info',
}

enum PageGroupFields {
	PageGroup = 'page_group_analysis.page_group_name_with_link',
}

enum CompanyListRequestName {
	CompanyInfo = 'company_detail_company_info',
}

enum CompanyDetailFields {
	AccountDetail = 'account_detail.account_detail',
	AccountId = 'account_detail.account_id',
	DunsNumber = 'account_detail.duns_number',
	EmployeeRange = 'account_detail.employee_range',
	Industry = 'account_detail.industry',
	RevenueRange = 'account_detail.revenue_range',
	State = 'account_detail.state',
}

enum DownloadFileType {
	CSV = 'CSV',
	PNG = 'PNG',
}

enum SortsType {
	desc = 'desc',
	asc = 'asc',
}

enum CommonFiltersConfig {
	domain = 'page_url',
	account_list_mapping_list_name = 'account_list_mapping.list_name',
	time_range = 'time_range',
	isp_status = 'isp_status',
	utm_campaign = 'utm_campaign',
	utm_source = 'utm_source',
	match_status = 'match_status',
	utm_content = 'utm_content',
	industry = 'industry',
	revenue_range = 'revenue_range',
	employee_range = 'employee_range',
	country = 'country',
	job_function = 'job_function',
	job_seniority = 'job_seniority',
	time_zone = 'timezone',
	page_groups_page_group = 'page_groups.page_group',
}

enum CommonSelectLabelConfig {
	DomainName = 'Domain Name',
	Company = 'Target Company List',
	DateRange = 'Date Range',
	ISP = 'ISP',
	Campaign = 'Campaign',
	Channel = 'Channel',
	MatchType = 'Match Type',
	Content = 'Content',
	Industry = 'Industry',
	Revenue = 'Revenue',
	EmployeeCount = 'Employee Count',
	Country = 'Country',
	JobFunction = 'Job Function',
	JobSeniority = 'Job Seniority',
	PageGroup = 'Page Group',
	TimeZone = 'Time Zone',
}

enum SelectType {
	Single = 'Single',
	Multiple = 'Multiple',
	TimeRange = 'TimeRange',
}

enum MenuType {
	Campaigns = 'campaigns',
	Channels = 'channels',
	Contents = 'contents',
	Industries = 'industries',
	Revenues = 'revenues',
	EmployeesCount = 'employees count',
	Countries = 'countries',
	JobFunctions = 'job functions',
	JobSeniorities = 'job seniorities',
	PageGroups = 'page groups',
}

enum LineChartXDisplayType {
	Daily = 'Daily',
	Weekly = 'Weekly',
	Monthly = 'Monthly',
	Quarterly = 'Quarterly',
	SemiAnnual = 'SemiAnnual',
}

enum LineChartFormatType {
	Month = 'Month',
	Day = 'Day',
}

enum CommonSelectLabelPlaceholderConfig {
	AllDomains = 'All Domains',
	SelectTargetCompanyList = 'Select Target Company List',
	AllVisitorTypes = 'All Visitor Types',
	AllCampaigns = 'All Campaigns',
	AllChannels = 'All Channels',
	AllMatchTypes = 'All Match Types',
	AllContents = 'All Content',
	AllIndustries = 'All Industries',
	AllRevenueBands = 'All Revenue Bands',
	AllEmployeeCountBands = 'All Employee Count Bands',
	AllCountries = 'All Countries',
	AllJobFunctions = 'All Job Functions',
	AllJobSeniorityLevels = 'All Job Seniority Levels',
	AllPageGroups = 'All Page Groups',
}

interface IBaseOption {
	label: string;
	value: string;
}

type IOverviewFiltersMap = {
	[key in CommonFiltersConfig]: IFilterValueOption;
};

type ICommonWebInsightsFiltersMap = {
	[key in CommonFiltersConfig]: ICommonFilterListConfig;
};

type IWebInsightsTabsType = {
	[key in WebInsightsTabsType]: string;
};

type IWebInsightsTab = {
	value: WebInsightsTabsType;
	url: string;
	label: string;
	show: boolean;
};

type onHandelFiltersChangeType = (
	type: CommonFiltersConfig,
	filterValue: string,
	timeRange?: TimeRangeType
) => void;

interface IWebInsightsHeader {
	title: string;
	description: string;
}

interface IFilterValueOption {
	display_name: string;
	value: string;
}

interface ISaveDashboardFilter {
	filter_value: Array<IFilterValueOption>;
	name: string;
	pid: number;
}

interface ISessionStorageFilter {
	filter_value: Array<IFilterValueOption>;
	pid: number;
	isUpdateFilters: boolean;
}

interface ISaveNewDashboardFilter extends Omit<ISaveDashboardFilter, 'pid'> {}

interface IDelDashboardFilter {
	Success: boolean;
}

enum FieldValueType {
	Number = 'numeric',
	Text = 'alpha',
}

type TimeRangeType = {
	low: string;
	high: string;
};

interface ITimeZoneOption {
	label: string;
	value: string;
	group: string;
}

interface IMetricsResponse {
	card: {
		columns: {
			dataType: FieldValueType;
			dbName: string;
			displayName: string;
			hasLink: boolean;
			isPercentage: boolean;
			isPercentageWithCount: boolean;
			isAverage: boolean;
			isSort: boolean;
			isXAxis: boolean;
			isYAxis: boolean;
		}[];
		description: string;
		hasIndex: boolean;
		displayName: string;
		name: string;
	};
	data: Record<string, string | number>[];
}

enum CommonBlockItemType {
	TABLE = 'Table',
	PIE = 'Pie',
	LINE = 'Line',
	CUSTOM = 'Custom',
	MAP = 'Map',
}

type ColumnConfigType = Record<string, Omit<GridColDef, 'field'>>;

type HandleLinkType = (record: ObjectType) => void;
interface ICommonBlockItemConfig {
	cardName?: string;
	width?: string | number;
	widthFunction?: (props: {
		doesFilterOpen: boolean;
		doesHideGraph: boolean;
	}) => string | number;
	hide?: boolean;
	hideFunction?: (props: {doesFilterOpen: boolean}) => boolean;
	type: CommonBlockItemType;
	customRender?: (rows: ObjectArray) => React.ReactNode;
	tableProps?: {
		handleLink?: HandleLinkType;
		columnConfig?: ColumnConfigType;
		columns?: GridColDef[];
		rows?: ObjectArray;
		sortingMode?: GridFeatureMode;
	};
	pieProps?: ICommonPie<ObjectType>;
	lineProps?: ITrendlineComponent<ObjectType>;
	mapProps?: ICommonWorldMap;
}

interface ICommonCardBlock {
	key: string;
	title?: string;
	description?: string;
	className?: string;
	showHideGraph?: boolean;
	requestParams: IDataTilesParams;
	components?: ICommonBlockItemConfig[];
	canCreateSegment?: boolean;
	createSegmentParams?: {
		activityAttributes: ActivityAttribute[];
	};
	cardContent?: ICardContent[];
	cardId?: string;
	hasTotal?: boolean;
}

interface IModal {
	doesOpen: boolean;
	handleCloseDialog: () => void;
}
interface ICommonDownloadModal extends IModal {
	title?: string;
	dashboardName: string;
	requestParams: IDataTilesParams;
	cardId?: string;
	getDownloadPNG?: (value: boolean) => void;
}

interface ICommonExpandModal extends IModal {
	cardConfig: ICommonCardBlock;
	dashboardName: string;
	cardId?: string;
}

interface IWebInsightsMenus {
	menuConfig: Array<ICommonMoreMenuItem>;
}

interface ICommonMoreMenuItem extends DNBMenuItemsProps {
	text: string;
	subText?: string;
}

interface IBaseCommonFilters {
	commonFilterListConfig: Array<ICommonFilterListConfig>;
}

interface ICommonTopFilters extends IBaseCommonFilters {
	downloadParams: IDataTilesParams;
	showFilters?: boolean;
	timezone: string;
	handelRefresh: () => void;
	onHandelFiltersChange: onHandelFiltersChangeType;
	handelResetFilters: () => void;
	hasActiveFilters?: boolean;
}

interface ICommonLeftFilterHeader {
	onSaveFiltersChange: (name: string) => Promise<ISaveDashboardFilter>;
	onDelFiltersChange: () => Promise<IDelDashboardFilter>;
	onFilterGroupChange: (value: number) => void;
}

interface ICommonLeftFilters
	extends ICommonLeftFilterHeader,
		IBaseCommonFilters {
	onHandelFiltersChange: onHandelFiltersChangeType;
}

interface ICommonFilterListConfig {
	key: CommonSelectLabelConfig;
	title?: CommonSelectLabelConfig;
	filterType: CommonFiltersConfig;
	selectType: SelectType;
	menuType?: MenuType;
	icon?: SvgIconProps;
	minWidth?: string;
	placeholder?: CommonSelectLabelPlaceholderConfig;
}

interface ICommonFilterList extends IBaseCommonFilters {
	onHandelFiltersChange: onHandelFiltersChangeType;
}

interface IBaseCommonSelect {
	title?: string;
	checkedValue: string;
}

interface ICommonSingleSelect extends IBaseCommonSelect {
	optionList: Array<string>;
	onSingleChange: (value: string) => void;
	icon?: SvgIconProps;
	minWidth?: string;
	tooltipContent: string;
	filterType?: CommonFiltersConfig;
	placeholder?: CommonSelectLabelPlaceholderConfig;
}

interface ICommonMultipleSelect extends IBaseCommonSelect {
	optionList: Array<string>;
	menuType?: MenuType;
	onMultipleChange: (data: Array<string>) => void;
	placeholder?: CommonSelectLabelPlaceholderConfig;
}

interface ICommonTimeRangeSelect extends IBaseCommonSelect {
	optionList: Array<QuickType>;
	timeRange: TimeRangeType;
	onTimeRangeChange: (type: QuickType, timeRange: TimeRangeType) => void;
}

interface ICommonSelect extends IBaseCommonSelect {
	menuType?: MenuType;
	selectType: SelectType;
	onSingleChange: (value: string) => void;
	onMultipleChange: (data: Array<string>) => void;
	icon?: SvgIconProps;
	minWidth?: string;
	tooltipContent: string;
	filterType: CommonFiltersConfig;
	placeholder?: CommonSelectLabelPlaceholderConfig;
}

interface IFiltersMenuOptionList extends IBaseOption {
	checked: boolean;
}

interface IDataTilesParams {
	cardNameList: Array<string>;
	start_date?: string;
	end_date?: string;
	timezone?: string;
	filters?: ObjectType;
	limit?: number;
	sorts?: Array<string>;
}

interface IWebInsightsInfo {
	PixelExists: boolean;
	SSVIInfo: {
		contract_end_time: number;
		pixel_installed: boolean;
		wizard_complete: boolean;
	};
	AptrinsicIdentifyData: AptrinsicIdentifyData;
}

interface ICommonSwitchMenu {
	name: string;
	checkedValue: string;
	optionList: IBaseOption[];
	onSwitchChange: (label: string, value: string) => void;
}

interface ICardContent {
	'company_name'?: string;
	'total_identified'?: number;
	'page_groups.page_group'?: string;
	'page_group_analysis.company_name_with_link_and_group'?: string;
	[AccountVisit.companyName]?: string;
	[AccountVisit.companyDunsNumber]?: string;
	[AccountVisit.state]?: string;
}

enum CommonModalTextConfig {
	CreateSegment = 'Create Segment',
	ResetFilters = 'Reset Filters',
	ViewerTimeZone = 'Viewer Time Zone',
	DownloadData = 'Download Data',
	ExpandView = 'Expand View',
	Cancel = 'Cancel',
	Done = 'Done',
	Download = 'Download',
	Update = 'Update',
	Delete = 'Delete',
	DeleteSavedCustomFilters = 'Delete Saved Custom Filters',
	Save = 'Save',
	SaveCustomFilters = 'Save Custom Filters',
	DashboardTimeZone = 'Dashboard Time Zone',
}

type IValuesResponse = {
	[key in CommonFiltersConfig]?: string[];
};

enum SaveCustomFilterType {
	UpdateCurrentFilter = 'Update current filter',
	SaveAsANewFilter = 'Save as a new filter',
}

export type {
	ObjectType,
	ObjectArray,
	IWebInsightsTabsType,
	IOverviewFiltersMap,
	ICommonWebInsightsFiltersMap,
	IWebInsightsHeader,
	ICommonTopFilters,
	IWebInsightsMenus,
	IModal,
	ColumnConfigType,
	ICommonCardBlock,
	HandleLinkType,
	ICommonBlockItemConfig,
	ICommonDownloadModal,
	ICommonExpandModal,
	ICommonMoreMenuItem,
	IWebInsightsTab,
	ICommonLeftFilters,
	ICommonLeftFilterHeader,
	ICommonFilterList,
	ICommonSelect,
	ICommonSingleSelect,
	ICommonMultipleSelect,
	ICommonTimeRangeSelect,
	ISaveDashboardFilter,
	ISaveNewDashboardFilter,
	IDelDashboardFilter,
	IFiltersMenuOptionList,
	IFilterValueOption,
	IDataTilesParams,
	TimeRangeType,
	ITimeZoneOption,
	IMetricsResponse,
	IWebInsightsInfo,
	ICommonSwitchMenu,
	ICardContent,
	ICommonFilterListConfig,
	IValuesResponse,
	ISessionStorageFilter,
};

export {
	WebInsightsTabsType,
	PageGroupListRequestName,
	PageGroupFields,
	CompanyListRequestName,
	CompanyDetailFields,
	DownloadFileType,
	SortsType,
	CommonFiltersConfig,
	CommonBlockItemType,
	CommonSelectLabelConfig,
	SelectType,
	MenuType,
	FieldValueType,
	LineChartFormatType,
	LineChartXDisplayType,
	CommonSelectLabelPlaceholderConfig,
	CommonModalTextConfig,
	SaveCustomFilterType,
};
