import type {StateService} from '@uirouter/angularjs';
import React from 'common/react-vendor';
import {ISegment} from 'atlas/data/SegmentConst';
import {SegmentNgState} from 'atlas/helpers/NavigateHelper';
import {
	getQueryProperty,
	dispatchQueryPropertyValue,
} from 'common/stores/query';
import {SegmentTitleHeader} from 'atlas/segmentation/Components/SegmentTitleHeader';
import {Box} from 'common/dnb-uux-vendor';
import {
	CommonBreadcrumbs,
	ICommonBreadcrumbs,
} from '../../../../../atlas/app/connectors/EIF/Component/CommonBreadcrumbs';

/**
 * ISegmentEditHeader
 * @param $state Angular state
 * @remarks Tightly coupled with angurlar functions, will be removed after
 * the whole things are converted using React.
 */
interface ISegmentEditHeaderX {
	$state: StateService;
}

/**
 * SegmentEditHeader
 * @param prop @ISegmentEditHeader
 * @returns
 */
function SegmentEditHeaderX({$state}: ISegmentEditHeaderX): JSX.Element {
	const segment = getQueryProperty<ISegment>('segment') || {};
	const {display_name, description, customParentSegmentName} = segment;
	const [name, setName] = React.useState(display_name);
	const [, setDescription] = React.useState(description);
	const breadcrumbsList: ICommonBreadcrumbs[] = React.useMemo(() => {
		const list = getQueryProperty('segments') as ISegment[];
		return [
			{
				text: 'Segments',
				onClick: () => {
					$state.go('home.segments_new', {}, {reload: true});
				},
				isLink: true,
			},
			{
				text:
					list.filter(({name}) => name === customParentSegmentName)?.[0]
						?.display_name || '',
				isLink: true,
				onClick: () => {
					$state.go(SegmentNgState.Dashboard, {
						segment: customParentSegmentName,
					});
				},
				hide: !customParentSegmentName,
			},
			{
				text: name || 'Create a Segment',
				isLink: false,
			},
		];
	}, [$state, customParentSegmentName, name]);
	const showInput = !!display_name;
	return (
		<Box
			sx={{
				backgroundColor: 'white',
			}}>
			<Box
				sx={{
					margin: 6,
					display: 'flex',
					flexDirection: 'column',
					gap: 6,
				}}>
				<CommonBreadcrumbs
					breadcrumbsList={breadcrumbsList.filter(({hide}) => !hide)}
				/>
				{showInput && (
					<SegmentTitleHeader
						buttons={[]}
						action={{
							onOkay: (newData) => {
								if (!newData) return;
								const {name, description} = newData;
								setName(name);
								setDescription(description);
								dispatchQueryPropertyValue('segment', {
									...segment,
									display_name: name,
									description,
								});
							},
						}}
					/>
				)}
			</Box>
		</Box>
	);
}

export type {ISegmentEditHeaderX};
export {SegmentEditHeaderX};
