import isEmpty from 'lodash/isEmpty';
import {Query} from 'components/datacloud/datacloud.types';

const mapSegmentNames = (segmentList?: Query[]): Record<string, string> => {
	if (isEmpty(segmentList)) {
		return {};
	}

	/**
	 * We can use the Non-null assertion operator
	 * since we already check that the value is not
	 * null | undefined | length === 0
	 */
	return segmentList!.reduce((segments, segment) => {
		if (segment.name) {
			return {
				...segments,
				[segment.name]: segment.display_name || '',
			};
		}

		return {
			...segments,
		};
	}, {} as Record<string, string>);
};

export {mapSegmentNames};
