import {
	AdminRouteMap,
	getAdminRoute,
	NoAdminRouteMap,
	ROUTE_PAGE,
	setMultipleStates,
} from 'common/app/utilities/AdminPageUtility';
import template from './groups.component.html';
import {
	getMetadataForCategory,
	loadCategoryMetadata,
} from '../../datacloud.service.vanilla';
import {invalidateAllAttributeGroups, invalidateGroup} from '../../query/query';
import {Banner} from '../../../../app/services/BannerService';
import messageService from 'common/app/utilities/message-service';
import Message, {NOTIFICATION, SUCCESS} from 'common/app/utilities/message';
import NoticeService from '../../../notice/NoticeService';
import AttrConfigService from '../AttrConfigService';
import AttrConfigStore from '../AttrConfigStore';

angular
	.module('common.attributes.groups', ['common.modal'])
	.config(function ($stateProvider) {
		$stateProvider.states = setMultipleStates;

		const routeMapNames = [
			AdminRouteMap.get(ROUTE_PAGE.ATTRIBUTE_GROUP),
			NoAdminRouteMap.get(ROUTE_PAGE.ATTRIBUTE_GROUP),
		];

		$stateProvider.states(routeMapNames, {
			url: '/groups/:section/:category/:subcategory?attributeSetName',
			params: {
				section: {
					dynamic: false,
					value: 'Export',
				},
				category: {
					dynamic: false,
					value: 'My Attributes',
				},
				subcategory: {
					dynamic: true,
					value: '',
				},
				attributeSetName: {
					dynamic: false,
					value: 'Default_Group',
				},
			},
			onExit: [
				'$transition$',
				'$stateParams',
				function ($transition$, $stateParams) {
					const to = $transition$._targetState._definition.name;
					const toAttributeSetName =
						$transition$._targetState._params.attributeSetName;
					const {attributeSetName} = $stateParams;
					if (
						!routeMapNames.includes(to) ||
						toAttributeSetName !== attributeSetName
					) {
						AttrConfigStore.reset();
					}
					Banner.reset();
				},
			],
			resolve: {
				overview: [
					'$stateParams',
					($stateParams) =>
						AttrConfigService.getUsageOverviewByAttributeSet(
							$stateParams.attributeSetName
						).then(({data}) => data),
				],
				config: [
					'$q',
					'$state',
					'$stateParams',
					'overview',
					($q, $state, $stateParams, overview) => {
						const deferred = $q.defer();
						const {attributeSetName, category, section} = $stateParams;

						const tab = overview.Selections.filter(
							({DisplayName}) => DisplayName === section
						);
						const [{Categories: categories}] = tab;

						AttrConfigStore.set('category', category);
						AttrConfigStore.set('categories', categories);

						const attributeGroup = AttrConfigStore.get('attributeGroup');
						const totalSelected = AttrConfigStore.get('totalSelected');
						if (totalSelected === undefined) {
							AttrConfigStore.set('totalSelected', tab?.[0].Selected);
						}

						if (attributeGroup?.[category]) {
							const storeConfig = attributeGroup?.[category].config || [];
							AttrConfigStore.setData('config', storeConfig);
							deferred.resolve(storeConfig);
						} else {
							AttrConfigService.getConfig('usage', category, {
								attributeSetName,
								usage: section,
							}).then(function (response) {
								AttrConfigStore.setData('config', response.data || []);
								deferred.resolve(response.data || []);
							});
						}

						return deferred.promise;
					},
				],
				attributeSets: () =>
					AttrConfigService.getAttributeSets().then(({data}) =>
						data.sort((a, b) => {
							// always default group first, then most recently created
							if (a.name === 'Default_Group') return -1;
							if (b.name === 'Default_Group') return 1;

							return a.created > b.created ? -1 : 1;
						})
					),

				selectedAttributeSet: [
					'$stateParams',
					($stateParams) =>
						AttrConfigService.getAttributeSet(
							$stateParams.attributeSetName
						).then(({data}) => data),
				],
				categoryMetadata: loadCategoryMetadata,
			},
			views: {
				'main@': 'attrGroups',
				'adminSidebar@': {component: 'adminSidebar'},
			},
		});
	})
	.component('attrGroups', {
		template,
		bindings: {
			overview: '<',
			config: '<',
			attributeSets: '<',
			selectedAttributeSet: '<',
			categoryMetadata: '<',
		},
		controller($rootScope, $state, $stateParams, $timeout, $scope, $q) {
			const vm = this;
			const {attributeSetName, category, section} = $stateParams;

			Banner.clear('info');

			vm.store = AttrConfigStore;
			vm.filters = vm.store.get('filters');
			vm.showModal = false;

			vm.isUpdatingName = false;

			vm.setAttributeGroup = () => {
				const category = vm.store.get('category');
				const data = vm.store.get('data');
				const attributeGroup = vm.store.get('attributeGroup');

				if (data) {
					const newSelected = data.config.Selected;
					const originalSelected = data.original.Selected;
					const totalSelected = vm.store.get('totalSelected');
					vm.store.set(
						'totalSelected',
						totalSelected + newSelected - originalSelected
					);
					vm.store.set('refresh', true);
				}

				vm.store.set('attributeGroup', {
					...attributeGroup,
					[category]: angular.copy(data),
				});
			};

			vm.groupsChange = () => {
				const attributeGroup = vm.store.get('attributeGroup');
				if (attributeGroup) {
					return true;
				} else {
					return vm.store.isChanged();
				}
			};

			vm.uiCanExit = (Transition) => {
				const goingTo =
					typeof Transition.targetState().identifier() === 'string'
						? Transition.targetState().identifier()
						: Transition.targetState().identifier().name;
				if (goingTo === '.' || !vm.groupsChange()) {
					if (vm.store.isChanged()) {
						vm.setAttributeGroup();
					}
					return true;
				}
				return vm.showWarningModal();
			};

			vm.showWarningModal = async () => {
				const deferred = $q.defer();

				vm.onConfirm = () => {
					deferred.resolve(true);
					vm.showModal = false;
				};
				vm.closeModal = () => {
					deferred.reject('user cancelled action');
					vm.showModal = false;
					HideSpinner();
				};
				vm.showModal = true;
				return deferred.promise;
			};

			vm.checkAttributesStatusBeforeLeave = async () => {
				const deferred = $q.defer();
				if (vm.groupsChange()) {
					vm.showWarningModal().then(() => {
						deferred.resolve(true);
					});
				} else {
					deferred.resolve(true);
				}
				return deferred.promise;
			};
			vm.isLoadingNewAttributeSet = false;
			vm.showDeleteModal = false;

			vm.$onInit = function () {
				vm.selectedCategory = vm.store.get('category');
				vm.categories = vm.store.get('categories');
				vm.categoriesMap = vm.pruneEmptyCategories(angular.copy(vm.categories));
				vm.categories = Object.keys(vm.categoriesMap).sort();
				vm.store.setData('overview', vm.overview);

				// if no attributeSetName is provided, the server returns the default attribute set.
				vm.isDefaultAttributeSetLoaded =
					attributeSetName === 'Default_Group' || !attributeSetName;

				// lazy clone
				vm.modifiedAttributeSet = JSON.parse(
					JSON.stringify(vm.selectedAttributeSet)
				);

				vm.createdDate = new Date(
					vm.modifiedAttributeSet.created
				).toLocaleDateString();
				vm.updatedDate = new Date(
					vm.modifiedAttributeSet.updated
				).toLocaleDateString();

				vm.isEditingDisplayName = false;
				vm.isEditingDescription = false;

				vm.store.set('limit', vm.store.getUsageLimit(vm.overview, section));
				vm.store.set('refresh', false);
			};

			vm.pruneEmptyCategories = function (categoriesMap) {
				Object.keys(categoriesMap).forEach(function (name) {
					const count = categoriesMap[name];

					if (count === 0) {
						delete categoriesMap[name];
					}
				});

				return categoriesMap;
			};

			vm.metadata = (id) => getMetadataForCategory(vm.categoryMetadata, id);

			vm.save = function () {
				const onSuccess = () => {
					invalidateGroup(vm.selectedAttributeSet.name);
					messageService.sendMessage(
						new Message(
							null,
							NOTIFICATION,
							SUCCESS,
							'Your changes have been saved.',
							''
						)
					);
				};
				// need update store attributeGroup before save
				if (vm.store.isChanged()) {
					vm.setAttributeGroup();
				}
				const attributeGroup = vm.store.get('attributeGroup');

				if (vm.isDefaultAttributeSetLoaded) {
					AttrConfigStore.saveDefaultAttributeGroupConfig(
						attributeGroup,
						$state.current.name,
						$stateParams
					).then(() => {
						AttrConfigStore.reset();
						$state.reload();
					});
				} else {
					AttrConfigStore.saveAttributeGroupConfigNew(
						vm.selectedAttributeSet,
						attributeGroup
					).then(() => {
						AttrConfigStore.reset();
						$state.reload();
						onSuccess();
					});
				}
			};

			vm.selectCategory = function (nextCategory, originalCategory) {
				const {displayName} = getMetadataForCategory(
					vm.categoryMetadata,
					nextCategory
				);
				ShowSpinner(
					`Loading ${displayName} Data`,
					'div.attr-results-container'
				);

				$state
					.go('.', {
						...$stateParams,
						category: nextCategory,
					})
					.catch(() => {
						vm.selectedCategory = originalCategory;
					});
			};

			vm.isFilterSelected = function () {
				return (
					vm.filters.show.Selected ||
					vm.filters.hide.Selected ||
					vm.filters.show.IsPremium ||
					vm.filters.hide.IsPremium
				);
			};

			vm.onClickEditDisplayName = () => {
				vm.isEditingDisplayName = !vm.isEditingDisplayName;
				$timeout(() => {
					$('#attr-group-display-name-input').focus();
				});
			};

			vm.onClickEditDescription = () => {
				vm.isEditingDescription = !vm.isEditingDescription;
				$timeout(() => {
					$('#attr-group-description-input').focus();
				});
			};

			vm.updateDisplayNameIfModified = ($event) => {
				const newDisplayName = $event.target.value.trim();
				const oldDisplayName = vm.selectedAttributeSet.displayName;
				const isNameDifferent = newDisplayName !== oldDisplayName;
				const isNameTooLong = newDisplayName.length > 50;
				const isNameTooShort = newDisplayName.length < 1;

				if (isNameTooLong) {
					NoticeService.warning({
						message: 'Attribute Group names must be 50 characters or less.',
						title: 'Warning:',
					});
					return;
				}

				if (isNameTooShort) {
					NoticeService.warning({
						message: 'Name must not be empty.',
						title: 'Warning:',
					});
					return;
				}

				if (isNameDifferent && !isNameTooLong) {
					vm.isUpdatingName = true;

					AttrConfigService.updateAttributeSetDisplayName(
						vm.selectedAttributeSet.name,
						newDisplayName
					)
						.then(({data, response}) => {
							invalidateGroup(vm.selectedAttributeSet.name);
							if (data?.displayName) {
								const {displayName} = data;
								vm.selectedAttributeSet = {
									...vm.selectedAttributeSet,
									displayName,
								};

								const index = vm.attributeSets.findIndex(
									({name}) => name === vm.selectedAttributeSet.name
								);
								vm.attributeSets[index].displayName = displayName;
							} else if (response?.data) {
								if (
									response.data.UIAction?.message.includes('already exists')
								) {
									response.data.UIAction.message =
										response.data.UIAction.message.replace(
											vm.selectedAttributeSet.name,
											newDisplayName
										);
								}
								messageService.sendMessage(new Message(response));
							}
						})
						.finally(() => {
							vm.isUpdatingName = false;

							$rootScope.$apply();
						});
				}

				vm.isEditingDisplayName = false;
			};

			vm.updateDescriptionIfModified = ($event) => {
				const newDescription = $event.target.value.trim();
				const oldDescription = vm.selectedAttributeSet.description;

				const isDescriptionDifferent = newDescription !== oldDescription;
				const isDescriptionTooLong = newDescription.length > 255;
				const isDescriptionTooShort = newDescription.length < 1;

				if (isDescriptionTooLong) {
					NoticeService.warning({
						message: 'Description must be 255 characters or less.',
						title: 'Warning:',
					});
					return;
				}

				if (isDescriptionTooShort) {
					NoticeService.warning({
						message: 'Description must not be empty.',
						title: 'Warning:',
					});
					return;
				}

				if (isDescriptionDifferent && !isDescriptionTooLong) {
					AttrConfigService.updateAttributeSetDescription(
						vm.selectedAttributeSet.name,
						newDescription
					)
						.then(({data: {description}}) => {
							invalidateGroup(vm.selectedAttributeSet.name);
							vm.selectedAttributeSet = {
								...vm.selectedAttributeSet,
								description,
							};

							const index = vm.attributeSets.findIndex(
								({name}) => name === vm.selectedAttributeSet.name
							);
							vm.attributeSets[index].description = description;
						})
						.finally(() => {
							$rootScope.$apply();
						});
				}

				vm.isEditingDescription = false;
			};

			vm.onClickDuplicateButton = () => {
				vm.checkAttributesStatusBeforeLeave().then(() => {
					if (
						!vm.checkIfUserHasTooManyAttributeSets() &&
						!vm.checkIfDisplayNameWillBeTooLong()
					) {
						AttrConfigService.cloneAttributeSet(vm.selectedAttributeSet).then(
							(response) => {
								AttrConfigStore.reset();
								vm.onSelectAttributeSet(response.data.name);
								invalidateAllAttributeGroups();
							}
						);
					}
				});
			};

			vm.checkIfUserHasTooManyAttributeSets = () => {
				const hasTooManyAttributeSets = vm.attributeSets.length === 50;

				if (hasTooManyAttributeSets) {
					NoticeService.warning({
						message:
							'You have reached a maximum limit of 50 attribute groups that you can create.',
						title: 'Warning',
					});
				}

				return hasTooManyAttributeSets;
			};

			vm.checkIfDisplayNameWillBeTooLong = () => {
				const isNewDisplayNameTooLong =
					`${vm.selectedAttributeSet.displayName} (Copy)`.length > 50;

				if (isNewDisplayNameTooLong) {
					NoticeService.warning({
						message:
							'The new Attribute Group name will be more than 50 characters.',
						title: 'Warning',
					});
				}

				return isNewDisplayNameTooLong;
			};

			vm.onClickClearButton = () => {
				vm.checkAttributesStatusBeforeLeave()
					.then(() => {
						AttrConfigStore.reset();
						vm.onSelectAttributeSet(vm.selectedAttributeSet.name, category);
						invalidateAllAttributeGroups();
					})
					.then(() => {
						$scope.$apply();
					});
			};

			vm.onClickDeleteButton = () => {
				vm.checkAttributesStatusBeforeLeave()
					.then(() => {
						vm.showDeleteModal = true;
					})
					.then(() => {
						$scope.$apply();
					});
			};

			vm.closeDeleteModal = () => {
				vm.showDeleteModal = false;
				$scope.$apply();
			};

			vm.onConfirmDelete = () => {
				AttrConfigService.deleteAttributeSet(vm.selectedAttributeSet.name).then(
					() => {
						AttrConfigStore.reset();
						invalidateAllAttributeGroups(vm.selectedAttributeSet.name);
						vm.onSelectAttributeSet('Default_Group');
					},
					() => {
						// no-op.  Error banner shows automatically
					}
				);
				vm.closeDeleteModal();
			};

			vm.onSelectAttributeSet = (
				attributeSetName,
				category = 'My Attributes'
			) => {
				vm.isLoadingNewAttributeSet = true;
				ShowSpinner();
				$state
					.transitionTo(
						getAdminRoute(ROUTE_PAGE.ATTRIBUTE_GROUP),
						{...$stateParams, attributeSetName, category, subcategory: ''},
						{reload: true, inherit: false, notify: false}
					)
					.then(() => {
						vm.isLoadingNewAttributeSet = false;
						HideSpinner();
					})
					.catch(() => {
						vm.isLoadingNewAttributeSet = false;
					});
			};
			vm.getSelected = () => {
				return vm.store.getSelectedTotal(
					$state.current.name,
					$stateParams,
					true
				);
			};
		},
	});
