import React from 'common/react-vendor';
import {CustomDocumentTypeGroup} from '../../Data/DocumentType';
import {getCustomSchemaList} from '../services/apiCalls';

interface ICustomSchemaContext {
	customEvents: string[];
	customObjects: string[];
	setCustomEvents: React.Dispatch<React.SetStateAction<string[]>>;
	setCustomObjects: React.Dispatch<React.SetStateAction<string[]>>;
}
const {createContext, useContext, useEffect, useState} = React;

const emptyFn = (): void => undefined;
const defaultCustomSchemas: ICustomSchemaContext = {
	customEvents: [],
	customObjects: [],
	setCustomEvents: emptyFn,
	setCustomObjects: emptyFn,
};

export const CustomSchemaContext =
	createContext<ICustomSchemaContext>(defaultCustomSchemas);

export const CustomSchemaProvider: React.FC<
	React.PropsWithChildren<unknown>
> = ({children}: {children?: React.ReactNode}) => {
	const [customEvents, setCustomEvents] = useState(
		defaultCustomSchemas.customEvents
	);
	const [customObjects, setCustomObjects] = useState(
		defaultCustomSchemas.customObjects
	);

	useEffect(() => {
		getCustomSchemaList(CustomDocumentTypeGroup.event)
			.then((response) => setCustomEvents(response.data))
			.catch(() => undefined);
		getCustomSchemaList(CustomDocumentTypeGroup.object)
			.then((response) => setCustomObjects(response.data))
			.catch(() => undefined);
	}, []);

	return (
		<CustomSchemaContext.Provider
			value={{
				customEvents,
				setCustomEvents,
				customObjects,
				setCustomObjects,
			}}>
			{children}
		</CustomSchemaContext.Provider>
	);
};

export const useCustomSchema = (): ICustomSchemaContext =>
	useContext(CustomSchemaContext);
