import {Modules, hasModule} from 'common/stores/tenantConfig';

angular
	.module('common.wizard.progress', ['common.exceptions'])
	.controller(
		'ImportWizardProgress',
		function (
			$rootScope,
			WizardProgressContext,
			WizardProgressItems,
			WizardValidationStore
		) {
			const vm = this;

			angular.extend(vm, {
				items: WizardProgressItems,
				context: WizardProgressContext,
				wizard: '.',
				rootState: (function () {
					return `home.${WizardProgressContext}.`;
				})(),
				itemMap: {},
				hideModelStep: false,
				listSegmentEnabled: false,
				ratings: null,
				isFetchingRatings: false,
			});

			vm.init = function () {
				vm.items.forEach(function (item) {
					vm.itemMap[vm.rootState + item.state.split('.').pop()] = item;
				});
				vm.enableListSegment = hasModule(Modules.LIST_SEGMENT);
				if (vm.context === 'playbook.create') {
					vm.isFetchingRatings = true;

					WizardValidationStore.getRatings({active: true})
						.then((ratings) => {
							vm.ratings = ratings;
						})
						.catch((err) => {
							console.err(err);
						})
						.finally(() => {
							vm.isFetchingRatings = false;
							$rootScope.$apply();
						});
				}

				// vm.current = vm.itemMap[vm.rootState + $state.current.name.split('.').pop()];
				// vm.currentState = vm.current.state;
				// vm.previousState = '';
			};

			vm.hideModelStep = () => {
				const hideModelItem = () => {
					vm.items.forEach((item) => {
						const tempItem = item;
						if (tempItem.label === 'Model') {
							tempItem.hide = true;
						}
					});
				};
				const showModelItem = () => {
					vm.items.forEach((item) => {
						const tempItem = item;
						if (tempItem.label === 'Model') {
							tempItem.hide = false;
						}
					});
				};
				if (vm.ratings && vm.ratings.length === 0) {
					hideModelItem();
					return true;
				}
				if (vm.enableListSegment && WizardValidationStore.getHideModelStep) {
					if (WizardValidationStore.getHideModelStep()) {
						hideModelItem();
					} else {
						showModelItem();
					}
				}
				return true;
			};

			vm.isDisable = function (item) {
				return item.progressDisabled === true;
			};

			vm.click = function ($event) {
				// This is a hack. This needs to be replaced by conditional CSS classes on the li's
				$event.preventDefault();
			};

			vm.init();
		}
	);
