import {CgTalkingPointActionType} from './enums';
import type {CgTalkingPointAction, CgTalkingPointState} from './types';

const clearCgTalkingPointStore = (): CgTalkingPointAction => {
	return {
		type: CgTalkingPointActionType.CLEAR_CG_TALKING_POINT_STORE,
	};
};

const setCgTalkingPointPropertyValue = <CgTalkingPointValue>(
	key: keyof CgTalkingPointState,
	value: CgTalkingPointValue
): CgTalkingPointAction => {
	return {
		type: CgTalkingPointActionType.SET_CG_TALKING_POINT_PROPERTY_VALUE,
		payload: {
			key,
			value,
		},
	};
};

export {clearCgTalkingPointStore, setCgTalkingPointPropertyValue};
