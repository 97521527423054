import React from 'common/react-vendor';
import SourceModal from './Modal/SourceModal';
import {getS3Credentials} from '../AddSource/api/apiCalls';
import styles from './S3Credentials.module.scss';
import {CopyComponent} from './CopyComponent';

export const S3CredentialKeys = {
	Protocol: 'Protocol',
	AccessKey: 'Access Key',
	Secret: 'Secret',
	AWSRegion: 'AWS Region',
	Bucket: 'Bucket',
	DropFolder: 'DropFolder',
};

export async function showS3Credentials(s3Path: string): Promise<void> {
	const s3CredentialsResponse = await getS3Credentials();
	if (s3CredentialsResponse) {
		const {data: s3CredentialsResponseData} = s3CredentialsResponse;
		const S3Credentials = {
			[S3CredentialKeys.Protocol]: 'Amazon S3',
			[S3CredentialKeys.AccessKey]: s3CredentialsResponseData.AccessKey,
			[S3CredentialKeys.Secret]: s3CredentialsResponseData.SecretKey,
			[S3CredentialKeys.AWSRegion]: s3CredentialsResponseData.Region,
			[S3CredentialKeys.Bucket]: s3CredentialsResponseData.Bucket,
			[S3CredentialKeys.DropFolder]: s3CredentialsResponseData.DropBox,
		};
		SourceModal.show({
			title: 'S3 Credentials',
			type: 'info',
			children: (
				<ul>
					<li>
						<p>
							S3 Path:{' '}
							<CopyComponent
								text={s3Path}
								className={`${styles.pointText} ${styles.copyWrapper}`}
							/>
						</p>
					</li>
					{Object.values(S3CredentialKeys).map((S3Credential) => (
						<li key={S3Credential}>
							<p>
								{S3Credential}:{' '}
								<span className={styles.pointText}>
									{S3Credentials[S3Credential]}
								</span>
							</p>
						</li>
					))}
				</ul>
			),
		});
	}
}
