import {styled} from '@mui/material';
import {DNBAccordion} from 'common/dnb-uux-vendor';

const StyledDNBAccordion = styled(DNBAccordion)`
	/* 
	TODO: Maybe we won't need to override the styles 
	of the DNBAccordion once the Accordion spike gets finished.
	https://github.com/dnb-main/dnb-uux/issues/223
	*/
	h6 {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: calc(100% - ${({theme}) => theme.spacing(4)});
	}
`;

export {StyledDNBAccordion};
