import {MakePercentage} from 'common/app/utilities/NumberUtility';
import {isEntityMatchEnabled} from 'common/stores/tenantConfig';
import {
	getSegmentByName,
	createOrUpdateSegment,
} from '../../segment/segment.queries';
import {store} from 'store';
import {setMetadataPropertyValue} from 'common/stores/datacloud';
import {getQueryProperty} from 'common/stores/query';
import {
	getRatingSegmentCounts,
	getTargetData,
	getAccountsData,
	getTargetCount,
} from '../../../../../atlas/app/playbook/playbook.queries';
import {
	getAccountsCount,
	getPlay,
} from '../../../../../atlas/app/playbook/playbook.helpers';
import {
	getPlaybookWizardProperty,
	dispatchPlaybookWizardPropertyValue,
	dispatchPlaybookWizardValidationValue,
} from '../../../../../atlas/app/stores/playbookWizard';
import {
	setAccounts,
	setContacts,
	getEntitiesCounts,
	setupStore,
} from '../query.helpers';

angular
	.module('common.datacloud.query.results', [])
	.controller(
		'QueryResultsCtrl',
		function (
			$scope,
			$state,
			$stateParams,
			$rootScope,
			$timeout,
			NoSFIdsCount,
			Config,
			Accounts,
			AccountsCoverage,
			Contacts,
			orgs
		) {
			const vm = this;

			angular.extend(vm, {
				resourceType: $state.current.name.substring(
					$state.current.name.lastIndexOf('.') + 1
				),
				modelId: $stateParams.modelId,
				inModel: $state.current.name.split('.')[1] === 'model',
				section: $stateParams.section,
				page: $stateParams.pageTitle,
				accountQuerySort: $stateParams.accountQuerySort, // used for when we call accounts/data on /suppressions page
				accounts: Accounts,
				counts: {},
				contacts: Contacts,
				noSFCount: NoSFIdsCount,
				loading: true,
				saving: false,
				saved: false,
				segment: getQueryProperty('segment'),
				accountRestriction: getQueryProperty('accountRestriction'),
				contactRestriction: getQueryProperty('contactRestriction'),
				current: 1,
				pagesize: 10,
				showAccountPagination: false,
				showContactPagination: false,
				showErrorApi: false,
				search: '',
				searchOptions: {
					updateOn: 'default blur',
					debounce: 1500,
				},
				accountsCoverage: AccountsCoverage
					? AccountsCoverage.ratingModelsCoverageMap
					: null,
				excludeNonSalesForce: false,
				sortType: 'CompanyName',
				sortDesc: false,
				sortReverse: false,
				config: Config,
				currentTargetTab: 'Accounts',
				tmpAccounts: [],
				tmpContacts: [],
				recommendationCounts: {},
				destinationOrgId: null,
				launchUnscored: getPlaybookWizardProperty('launchUnscored'),
				bypassBuckets: false,
				orgs,
				exportFolder: '',
				EnableEntityMatch: isEntityMatchEnabled(),
			});
			vm.isEmpty = (obj) => {
				for (const key in obj) {
					if (obj.hasOwnProperty(key)) return false;
				}
				return true;
			};
			vm.init = function () {
				// Set Counts for Segment and PLay Targets
				if (vm.section === 'segment.analysis') {
					vm.counts = getQueryProperty('counts');
				} else {
					vm.showErrorApi = !vm.isEmpty(AccountsCoverage.errorMap);
					vm.selectedBuckets = [];
					if (vm.section === 'wizard.targets') {
						// Now instead we get sum of scored and unscored accounts
						const unscoredAccountCount = vm.accountsCoverage
							? vm.accountsCoverage.unscoredAccountCount
							: 0;
						const accountCount = vm.accountsCoverage
							? vm.accountsCoverage.accountCount
							: 0;
						const numAccounts = unscoredAccountCount + accountCount;

						vm.unscoredAccounts = {
							total: unscoredAccountCount,
							percentage: MakePercentage(
								unscoredAccountCount,
								unscoredAccountCount + accountCount,
								'%',
								1
							),
						};

						// setting defaults
						let bucketsToLaunch = null;

						const currentPlay = getPlaybookWizardProperty('currentPlay');

						vm.launchUnscored = !currentPlay?.ratingEngine;

						if (currentPlay?.launchHistory.mostRecentLaunch != null) {
							bucketsToLaunch =
								currentPlay?.launchHistory.mostRecentLaunch.bucketsToLaunch;
							vm.launchUnscored =
								currentPlay?.launchHistory.mostRecentLaunch.launchUnscored;
							vm.topNCount =
								currentPlay?.launchHistory.mostRecentLaunch.topNCount;
							vm.topNClicked = !!vm.topNCount;
						}

						vm.launchUnscoredClick();
						vm.makeRecommendationCounts();

						// Create array (vm.selectedBuckets) of bucket names (e.g. ["A", "B", "C"])
						// to be used when launching play, and assign percentage to the bucket for display purposes
						if (vm.accountsCoverage) {
							vm.accountsCoverage.bucketCoverageCounts.forEach((bucket) => {
								if (bucketsToLaunch == null) {
									vm.selectedBuckets.push(bucket.bucket);
								} else if (
									bucketsToLaunch.length &&
									bucketsToLaunch.indexOf(bucket.bucket) !== -1
								) {
									vm.selectedBuckets.push(bucket.bucket);
								}

								// Use this if you want to round up to nearest integer percentage
								// If you do use this, use this in the view HTML ({{ ::bucket.percentage }}%)
								// result is (1%) for 0.3%
								// bucket.percentage = Math.ceil((bucket.count / numAccounts) * 100);

								// Use this if you want more precise percentage in the display
								// If you do use this, use this in the view HTML ({{ ::bucket.percentage | percentage: 1 }})
								// result is (0.3%) for 0.3%
								bucket.percentage = bucket.count / numAccounts;
							});
						}
						vm.getExportFolder(vm.destinationOrgId, vm.orgs);
					} else if (vm.section === 'dashboard.targets') {
						getPlay($stateParams.play_name, true)
							.then((data) => {
								if (
									data &&
									data.ratingEngine &&
									data.ratingEngine.bucketMetadata
								) {
									const buckets = data.ratingEngine.bucketMetadata;
									buckets.forEach((bucket) => {
										vm.selectedBuckets.push(bucket.bucket_name);
									});
								}
							})
							.finally(() => {
								$rootScope.$apply();
							});
					}
				}
			};

			function updatePage() {
				vm.loading = true;
				const offset = (vm.current - 1) * vm.pagesize;

				if (vm.section === 'segment.analysis') {
					// My Data or Segment Account & Contacts pages
					var dataQuery = {
						free_form_text_search: vm.search,
						account_restriction: vm.accountRestriction,
						contact_restriction: vm.contactRestriction,
						preexisting_segment_name: $stateParams.segment,
						restrict_with_sfdcid: vm.excludeNonSalesForce,
						lookups: [
							{
								attribute: {
									entity: 'Account',
									attribute: 'AccountId',
								},
							},
							{
								attribute: {
									entity: 'Account',
									attribute: 'CompanyName',
								},
							},
							{
								attribute: {
									entity: 'Account',
									attribute: 'City',
								},
							},
							{
								attribute: {
									entity: 'Account',
									attribute: 'Website',
								},
							},
							{
								attribute: {
									entity: 'Account',
									attribute: 'State',
								},
							},
							{
								attribute: {
									entity: 'Account',
									attribute: 'Country',
								},
							},
						],
						main_entity: 'Account',
						page_filter: {
							num_rows: vm.pagesize,
							row_offset: offset,
						},
						sort: {
							attributes: [
								{
									attribute: {
										attribute: vm.sortType,
										entity: 'Account',
									},
								},
							],
						},
					};

					if (vm.page === 'Accounts') {
						setAccounts(dataQuery).then((response) => {
							vm.accounts = response.data;
							vm.loading = false;
						});
					} else if (vm.page === 'Contacts') {
						setContacts(dataQuery).then((response) => {
							vm.contacts = response.data;
							vm.loading = false;
						});
					}

					// "property": 'attribute',
					// "attribute": vm.sortType,
					// "entity": vm.page

					const query = {
						free_form_text_search: vm.search,
						account_restriction: vm.accountRestriction,
						contact_restriction: vm.contactRestriction,
						restrict_without_sfdcid: false,
						page_filter: {
							num_rows: vm.pagesize,
							row_offset: offset,
						},
						sort: {
							attributes: [
								{
									attribute: {
										entity: vm.page,
										attribute: vm.sortType,
									},
								},
							],
							descending: false,
						},
					};

					getEntitiesCounts(query)
						.then((data) => {
							vm.counts[vm.page.toLowerCase()].value =
								data[vm.page == 'Contacts' ? 'Contact' : 'Account'];
							vm.counts[vm.page.toLowerCase()].loading = false;

							if (vm.page == 'Accounts' || vm.page === 'Playbook') {
								vm.showAccountPagination = data.Account > 10;
								vm.showContactPagination = false;
							}

							if (vm.page == 'Contacts') {
								vm.showAccountPagination = false;
								vm.showContactPagination = data.Contact > 10;
							}
						})
						.finally(() => {
							$rootScope.$apply();
						});
				} else {
					// Targets page for create Play flow
					var dataQuery = {
						free_form_text_search: vm.search || '',
						restrictNotNullSalesforceId: false,
						entityType: 'Account',
						bucketFieldName: 'ScoreBucket',
						maximum: vm.pagesize,
						offset,
						sortBy: vm.sortType,
						descending: vm.sortDesc,
						selectedBuckets: vm.selectedBuckets,
					};

					dispatchPlaybookWizardValidationValue('targets', false);
					getPlay($stateParams.play_name, true)
						.then((data) => {
							// Get play rating engine and create array object literal for getting the counts.
							const engineId =
								data.ratingEngine && data.ratingEngine.id
									? data.ratingEngine.id
									: '';
							const engineIdObject = engineId ? [{id: engineId}] : [];

							vm.hasModel = !!engineId;

							// Get Account Data
							if (engineId) {
								vm.loadingTargetData = true;
								getTargetData(engineId, dataQuery)
									.then((results) => {
										vm.accounts = results.data;
										vm.loadingTargetData = false;
									})
									.finally(() => {
										$rootScope.$apply();
									});
							} else {
								const accountQuery = {
									free_form_text_search: vm.search || '',
									preexisting_segment_name: data.targetSegment.name,
									lookups: [
										{
											attribute: {
												entity: 'Account',
												attribute: 'AccountId',
											},
										},
										{
											attribute: {
												entity: 'Account',
												attribute: 'LDC_Name',
											},
										},
										{
											attribute: {
												entity: 'Account',
												attribute: 'CompanyName',
											},
										},
										{
											attribute: {
												entity: 'Account',
												attribute: 'Website',
											},
										},
									],
									page_filter: {
										num_rows: vm.pagesize,
										row_offset: offset,
									},
									sort: {
										attributes: [
											{
												attribute: {
													entity: vm.accountQuerySort || vm.page,
													attribute: vm.sortType,
												},
											},
										],
										descending: false,
									},
									account_restriction: data.targetSegment.account_restriction,
									contact_restriction: data.targetSegment.contact_restriction,
								};

								getAccountsData(accountQuery)
									.then((results) => {
										vm.accounts = results.data;
										vm.bypassBuckets = true;
									})
									.finally(() => {
										$rootScope.$apply();
									});
							}

							// Get Account Counts for Pagination
							if (!vm.search) {
								vm.noBuckets = [
									{
										bucket: 'A',
										count: 0,
									},
									{
										bucket: 'B',
										count: 0,
									},
									{
										bucket: 'C',
										count: 0,
									},
									{
										bucket: 'D',
										count: 0,
									},
									{
										bucket: 'E',
										count: 0,
									},
									{
										bucket: 'F',
										count: 0,
									},
								];
								const getSegmentsOpts = {
									loadContactsCount: true,
									loadContactsCountByBucket: true,
								};
								if (engineIdObject.length) {
									getRatingSegmentCounts(
										data.targetSegment.name,
										[engineId],
										getSegmentsOpts
									)
										.then((data) => {
											const accountsCoverage =
												data.ratingModelsCoverageMap &&
												data.ratingModelsCoverageMap[engineId]
													? data.ratingModelsCoverageMap[engineId]
													: {bucketCoverageCounts: []};

											const filteredAccountsCoverage =
												accountsCoverage.bucketCoverageCounts.filter(
													(bucket) =>
														vm.selectedBuckets.indexOf(bucket.bucket) >= 0
												);

											const calculateCountsFromFiltered = function (array) {
												let accounts = 0;
												for (let i = 0; i < array.length; i++) {
													accounts += filteredAccountsCoverage[i].count;
												}

												const count = accounts;
												return count;
											};

											if (
												vm.section === 'create.targets' ||
												vm.section === 'dashboard.targets'
											) {
												if (!vm.bypassBuckets) {
													vm.counts = {
														accounts: {
															value: calculateCountsFromFiltered(
																filteredAccountsCoverage
															),
														},
														contacts: {
															value:
																vm.accountsCoverage &&
																vm.accountsCoverage.contactCount
																	? vm.accountsCoverage.contactCount
																	: 0,
														},
													};
												}
											} else {
												const accountValue = calculateCountsFromFiltered(
													filteredAccountsCoverage
												);
												const contactsValue = vm.accountsCoverage
													? vm.accountsCoverage.contactCount
													: 0;
												vm.counts = {
													accounts: {
														value: accountValue,
													},
													contacts: {
														value: contactsValue,
													},
												};
											}

											if (vm.counts.accounts.value > 10) {
												vm.showAccountPagination = true;
												vm.showContactPagination = false;
											}

											if (
												vm.section == 'wizard.targets' &&
												vm.selectedBuckets.length == 0
											) {
												vm.showAccountPagination = false;
											}

											vm.updateTopNCount();
										})
										.finally(() => {
											$rootScope.$apply();
										});
								} else {
									// no rating engine
									var countsQuery = {
										preexisting_segment_name: data.targetSegment.name,
									};

									getAccountsCount(countsQuery).then((data) => {
										vm.counts = {
											accounts: {
												value: data,
											},
										};
									});
								}
							} else if (vm.search) {
								if (engineId) {
									var countsQuery = {
										freeFormTextSearch: vm.search || '',
										restrictNotNullSalesforceId: vm.excludeNonSalesForce,
										entityType: 'Account',
										selectedBuckets: vm.selectedBuckets,
									};

									getTargetCount(engineId, countsQuery)
										.then((data) => {
											vm.counts.accounts.value = data;

											vm.showAccountPagination = vm.counts.accounts.value > 10;
											vm.showContactPagination = false;

											if (
												vm.section == 'wizard.targets' &&
												vm.selectedBuckets.length == 0
											) {
												vm.counts.accounts.value = 0;
												vm.showAccountPagination = false;
											}
										})
										.finally(() => {
											$rootScope.$apply();
										});
								} else {
									var countsQuery = {
										free_form_text_search: vm.search,
										preexisting_segment_name: data.targetSegment.name,
									};

									getAccountsCount(countsQuery).then((data) => {
										vm.counts.accounts.value = data;
										vm.showAccountPagination = vm.counts.accounts.value > 10;
									});
								}
							}
						})
						.finally(() => {
							$rootScope.$apply();
						});

					vm.loading = false;
				}

				vm.checkSaveButtonState();
			}

			vm.setValidation = function () {
				if (vm.showError == true || vm.recommendationCounts.launched <= 0) {
					dispatchPlaybookWizardValidationValue('targets', false);
				} else {
					dispatchPlaybookWizardValidationValue('targets', true);
				}
			};

			vm.updateTopNCount = function () {
				vm.topNCount = Math.floor(vm.topNCount);

				if (vm.topNClicked && (vm.topNCount <= 0 || vm.topNCount == null)) {
					vm.showError = true;
				} else {
					vm.showError = false;
				}
			};

			vm.topNInputClick = function ($event) {
				vm.topNClicked = true;
				$event.target.select();
				vm.updateTopNCount();
				vm.makeRecommendationCounts();
			};

			vm.excludeNonSalesForceCheckbox = function (excludeAccounts) {
				excludeAccounts = !excludeAccounts;

				if (excludeAccounts) {
					vm.excludeNonSalesForce = true;
				} else {
					vm.excludeNonSalesForce = false;
				}

				updatePage();
			};

			vm.clickCompanyName = function (account) {
				store.dispatch(
					setMetadataPropertyValue('accountName', account.CompanyName)
				);

				$state.go('home.segment.accounts.contacts', {
					a360_accountId: account.AccountId,
				});
			};

			vm.bucketClick = function (bucket) {
				const index = vm.selectedBuckets.indexOf(bucket.bucket);

				if (index > -1) {
					vm.selectedBuckets.splice(index, 1);
				} else {
					vm.selectedBuckets.push(bucket.bucket);
				}

				// reset topNcount on bucket click, issue with sync and faster update speed
				updatePage();
				vm.makeRecommendationCounts();
			};

			vm.getExportFolder = function (orgId, orgs) {
				let folder = '';
				if (orgId === 'Lattice_S3') {
					folder = orgs.find((org) => org.orgId === orgId);
				}
				if (folder.exportFolder) {
					vm.exportFolder = folder.exportFolder;
				}
			};

			vm.launchUnscoredClick = function () {
				$timeout(() => {
					dispatchPlaybookWizardPropertyValue(
						'launchUnscored',
						vm.launchUnscored
					);
					updatePage();
					vm.makeRecommendationCounts();
				});
			};

			vm.showNoResultsText = function (accounts, contacts) {
				var accounts = accounts || {};
				var contacts = contacts || {};
				switch (vm.page) {
					case 'Accounts':
						return accounts.length === 0;

					case 'Playbook':
						return accounts.length === 0;

					case 'Contacts':
						return contacts.length === 0;

					default:
						return accounts.length === 0 && contacts.length === 0;
				}
			};

			const prevQuery = vm.search;
			vm.submitQuery = function () {
				vm.loading = true;
				vm.current = 1;

				if (
					vm.search &&
					prevQuery &&
					vm.search.toUpperCase() === prevQuery.toUpperCase()
				) {
					return;
				}
				updatePage();
			};

			vm.refineQuery = function () {
				return vm.inModel()
					? 'home.model.analysis.explorer.builder'
					: 'home.segment.explorer.builder';
			};

			$rootScope.$on('clearSegment', (e) => {
				vm.clearSearch();
			});

			vm.clearSearch = function () {
				vm.search = '';
				vm.current = 1;

				vm.submitQuery();
			};

			vm.sort = function (columnName) {
				vm.sortType = columnName;
				vm.sortDesc = !vm.sortDesc;
				vm.current = 1;

				updatePage();
			};

			vm.checkSaveButtonState = function () {
				const oldVal = getQueryProperty('defaultRestrictions');
				const newAccountVal = JSON.stringify(
					getQueryProperty('accountRestriction')
				);
				const newContactVal = JSON.stringify(
					getQueryProperty('contactRestriction')
				);
				const newVal = newAccountVal + newContactVal;

				if (oldVal === newVal) {
					vm.saveSegmentEnabled = false;
					vm.saved = false;
				} else {
					vm.saveSegmentEnabled = true;
				}
			};

			vm.inModel = function () {
				const name = $state.current.name.split('.');
				return name[1] == 'model';
			};

			vm.hideMessage = function () {
				vm.saved = false;
			};

			vm.saveSegment = function () {
				const segmentName = $stateParams.segment;
				const ts = new Date().getTime();

				if (segmentName === 'Create') {
					const accountRestriction = getQueryProperty('accountRestriction');
					const contactRestriction = getQueryProperty('contactRestriction');
					const segment = {
						name: `segment${ts}`,
						display_name: `segment${ts}`,
						account_restriction: accountRestriction,
						contact_restriction: contactRestriction,
						page_filter: {
							row_offset: 0,
							num_rows: 10,
						},
					};

					createOrUpdateSegment(segment).then((response) => {
						setupStore(response.data);

						vm.saveSegmentEnabled = false;
						$state.go('.', {segment: `segment${ts}`}, {notify: false});
						vm.saved = true;
					});
				} else {
					getSegmentByName(segmentName).then((result) => {
						const segmentData = result;
						const accountRestriction = getQueryProperty('accountRestriction');
						const contactRestriction = getQueryProperty('contactRestriction');
						const segment = {
							name: segmentData.name,
							display_name: segmentData.display_name,
							account_restriction: accountRestriction,
							contact_restriction: contactRestriction,
							page_filter: {
								row_offset: 0,
								num_rows: 10,
							},
						};

						createOrUpdateSegment(segment).then((response) => {
							setupStore(response.data);

							vm.saveSegmentEnabled = false;
							$state.go('.', {segment: `segment${ts}`}, {notify: false});
							vm.saved = true;
						});
					});
				}
			};

			vm.makeRecommendationCounts = function () {
				// var opts = opts || {};

				if (!vm.accountsCoverage || !vm.accountsCoverage.bucketCoverageCounts) {
					vm.recommendationCounts = null;
					return false;
				}
				const sections = {
					total: 0,
					selected: 0,
					suppressed: 0,
					launched: 0,
					contacts: 0,
				};
				const buckets = {};

				// vm.accountsCoverage.bucketCoverageCounts.forEach(function(count) {
				//     sections.total += parseInt(count.count);
				// });
				// sections.total = vm.accountsCoverage.accountCount + vm.accountsCoverage.unscoredAccountCount;

				sections.total =
					getPlaybookWizardProperty('currentPlay')?.targetSegment.accounts;

				// sections.unscored = (vm.launchUnscored ? vm.unscoredAccounts.total : 0);
				// sections.selected += sections.unscored;

				let _contacts = 0;
				for (const i in vm.selectedBuckets) {
					var bucket = vm.selectedBuckets[i];
					let count = vm.accountsCoverage.bucketCoverageCounts.find(
						(value) => value.bucket === bucket
					);

					count = count || {count: 0};
					sections.selected += parseInt(count.count);
					_contacts += parseInt(count.contactCount || 0);
				}

				// if(resetTopNCount){
				//     vm.topNCount = sections.selected;
				// }

				sections.selected = vm.launchUnscored
					? vm.accountsCoverage.unscoredAccountCount + sections.selected
					: sections.selected;

				// if(resetTopNCount){
				//     vm.topNCount = sections.selected;
				// }

				if (vm.topNClicked && vm.topNCount <= sections.selected) {
					sections.launched = vm.topNCount <= 0 ? 0 : vm.topNCount;
					// if(vm.topNCount <= 0){
					//     sections.launched = 0;
					// }
					// else {
					//     sections.launched = vm.topNCount;
					// }
				} else {
					sections.launched = sections.selected;
				}

				sections.suppressed =
					sections.total >= sections.launched
						? sections.total - sections.launched
						: sections.total;

				sections.contacts =
					_contacts +
					(vm.launchUnscored
						? vm.accountsCoverage.unscoredContactCount
							? vm.accountsCoverage.unscoredContactCount
							: 0
						: 0); // vm.accountsCoverage.contactCount || 0; // need to find campaign with contactCount to test this

				// sections.suppressed = parseInt(sections.total - sections.selected);

				// sections.launched = vm.topNCount && opts.suppressed ? vm.topNCount : sections.selected;//(sections.selected > sections.suppressed ? sections.total - sections.suppressed : sections.selected));

				// var $topNCountEl = angular.element('input#topNCount');

				// if($topNCountEl.is(':checked')) {
				//     sections.suppressed = Math.max(sections.total - vm.topNCount, sections.suppressed) || 0;
				// }

				vm.recommendationCounts = sections;

				vm.setValidation();
			};

			$scope.$watch('vm.current', (newValue, oldValue) => {
				vm.loading = true;
				updatePage();
			});

			vm.init();
		}
	)
	.filter('percentage', [
		'$filter',
		function ($filter) {
			return function (input, decimals) {
				return `${$filter('number')(input * 100, decimals)}%`;
			};
		},
	]);
