type NoticeType = 'error' | 'success' | 'warning' | 'info';

interface NoticeOptions {
	visible?: boolean;
	title?: string;
	message?: string;
	delay?: number;
	type?: NoticeType;
	needApply?: boolean;
}

const DEFAULT_OPTIONS: NoticeOptions = {
	visible: false,
	type: 'info',
	title: '',
	message: '',
	delay: 3500,
	needApply: false,
};

export const NOTICE_UPDATE_EVENT = 'NoticeUpdateEvent';

let currentNotice = {...DEFAULT_OPTIONS};

const get = (): NoticeOptions => {
	return currentNotice || DEFAULT_OPTIONS;
};

const dispatch = (notice: NoticeOptions): void => {
	document.dispatchEvent(
		new CustomEvent(NOTICE_UPDATE_EVENT, {detail: notice})
	);
};

const set = (opts: NoticeOptions, type: NoticeType): void => {
	currentNotice = {
		...DEFAULT_OPTIONS,
		...opts,
		type,
		visible: true,
	};
	dispatch(currentNotice);
};

export default {
	error(opts: NoticeOptions): void {
		set(opts, 'error');
	},
	info(opts: NoticeOptions): void {
		set(opts, 'info');
	},
	warning(opts: NoticeOptions): void {
		set(opts, 'warning');
	},
	success(opts: NoticeOptions): void {
		set(opts, 'success');
	},
	get,
};
