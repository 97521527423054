import {get} from 'lodash';
import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import LocalStorageUtility, {
	IClientSessionSession,
} from 'widgets/utilities/local-storage.utility';
import UserValues from 'widgets/utilities/user-values.utility';
import {SubscriptionData} from 'atlas/helpers/subscription.helpers';
import { getTenantConfig } from 'common/stores/tenantConfig';
import { cacheQueryResponse } from 'common/app/utilities/cacheUtility/cacheUtility';

const defaultSubscription = {
	currentSubscription: {
		contractEndDate: null,
		contractStartDate: null,
		dayLeft: null,
		contractProducts: [''],
		contractPackage: '',
	},
	subscriptionUsage: {
		accountAvailable: null,
		accountLimit: null,
		contactAvailable: null,
		contactLimit: null,
		pageViewAvailable: null,
		pageViewLimit: null,
	},
};

interface AptrinsicIdentifyData {
	tenantId?: number;
	username?: string;
	token?: string;
	role?: string;
	feature_flags: string;
	plan: string;
	accountLimit: number | null;
	accountsAvailable: number | null;
	contactLimit: number | null;
	contactsAvailable: number | null;
	pageviewLimit: number | null;
	pageviewsAvailable: number | null;
	contractStartDate: string | null;
	contractEndDate: string | null;
	daysLeft: string | null;
	WorkspaceCreationDate: number | null;
}

const getAptrinsicIdentifyData = async (): Promise<AptrinsicIdentifyData> => {
	const tenantName = UserValues.getTenantName();

	// #region LocalStorage data
	const {Tenants} = LocalStorageUtility.getLoginDocument();

	const ClientSession: IClientSessionSession | null =
		LocalStorageUtility.getClientSession();

	const RoleLabel = ClientSession ? ClientSession.RoleLabel : '';
	const AccessLevel = ClientSession ? ClientSession.AccessLevel : '';

	const username = ClientSession ? ClientSession.Identifier : ''

	const token = LocalStorageUtility.getTokenDocument() as string;
	// #endregion LocalStorage data

	// #region Feature flags parser
	const tenantConfig = await getTenantConfig();

	const flags: Record<string, boolean> = get(
		tenantConfig,
		'FeatureFlags',
		{}
	);
	const flagEntries = Object.entries(flags);

	const activeFlags = flagEntries.reduce(
		(flagNames, [flagName, isActive], currentIndex) => {
			const lastIndex = flagEntries.length - 1;
			const isLastIndex = currentIndex === lastIndex;

			if (isActive) {
				const separator = isLastIndex ? '' : ', ';

				return `${flagNames}${flagName}${separator}`;
			}

			if (isLastIndex) {
				return flagNames.replace(/,\s*$/, '');
			}

			return flagNames;
		},
		''
	);
	// #endregion Feature flags parser

	// #region Subscription destructuring
	const subscription = await cacheQueryResponse('subscription', () => axiosInstance.get<SubscriptionData>(
		'/pls/subscription'
	));

	const {currentSubscription, subscriptionUsage} = get(
		subscription,
		'data',
		defaultSubscription
	);

	const {
		contractEndDate,
		contractStartDate,
		dayLeft: daysLeft,
		contractProducts,
		contractPackage,
	} = currentSubscription;

	const {
		accountAvailable: accountsAvailable,
		accountLimit,
		contactAvailable: contactsAvailable,
		contactLimit,
		pageViewAvailable: pageviewsAvailable,
		pageViewLimit: pageviewLimit,
	} = subscriptionUsage;
	// #endregion Subscription destructuring

	// #region package parser
	const plan = contractProducts.reduce(
		(currentPlan, contractProduct, currentIndex) => {
			const lastIndex = contractProducts.length - 1;
			const separator =
				currentIndex === lastIndex
					? ` ${contractPackage}`
					: ` ${contractPackage}, `;

			return `${currentPlan}${contractProduct}${separator}`;
		},
		''
	);
	// #endregion package parser

	const {RegisteredTime = null, Pid} =
		Tenants.find(({DisplayName}) => DisplayName === tenantName) || {};

	return {
		tenantId: Pid,
		username,
		token,
		role: RoleLabel || AccessLevel,
		feature_flags: activeFlags,
		plan,
		accountLimit,
		accountsAvailable,
		contactLimit,
		contactsAvailable,
		pageviewLimit,
		pageviewsAvailable,
		contractStartDate,
		contractEndDate,
		daysLeft,
		WorkspaceCreationDate: RegisteredTime,
	};
};

export {getAptrinsicIdentifyData};
export type {AptrinsicIdentifyData};
