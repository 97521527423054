import React from 'common/react-vendor';

const MyContactIcon = (): React.ReactElement => (
	<svg width="79" height="56" viewBox="0 0 79 56" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M54.6117 17.2001C54.7217 17.8401 54.9917 18.33 55.2017 18.3C55.2359 18.2891 55.2667 18.2695 55.2913 18.2433C55.3158 18.217 55.3332 18.1849 55.3417 18.15C55.647 19.8362 56.2862 21.4443 57.2217 22.88V24.88L57.1317 24.79L56.7217 25.2101L56.3417 25.5701L56.4117 25.63C55.7526 26.2279 55.0319 26.7543 54.2617 27.2001L55.9717 27.8601C57.9399 28.4285 59.7593 29.4217 61.3017 30.77C63.8717 33.34 64.9217 43.0701 65.3017 47.6201C74.5817 46.9401 77.3017 44.13 77.3017 44.13C77.3017 44.13 76.6317 32.79 74.4817 30.65C72.3317 28.51 68.5917 28.76 65.1017 25.4L65.1817 25.3301L65.0517 25.2101C64.8778 25.0142 64.6907 24.8304 64.4917 24.66C64.4217 24.74 64.3417 24.7901 64.2717 24.8701V22.9401C65.3313 21.5429 66.075 19.9326 66.4517 18.2201C66.4517 18.2801 66.5317 18.3301 66.5817 18.3401C66.8017 18.3401 67.0617 17.8801 67.1817 17.2401C67.3017 16.6001 67.1817 16.04 67.0017 16.01C67.0017 16.01 66.9317 16.01 66.9017 16.01C67.196 14.0697 67.035 12.0876 66.4317 10.2201C65.8017 8.34009 63.0817 7.55 60.9717 7.5H60.7317C58.6217 7.5 55.9017 8.34009 55.2717 10.2201C54.6699 12.0809 54.5056 14.0555 54.7917 15.9901C54.5817 16.0501 54.5017 16.5801 54.6117 17.2001Z" stroke="#3F96B4" strokeWidth="2" strokeMiterlimit="10"/>
		<path d="M17.47 30.73C19.015 29.3831 20.8333 28.3869 22.8 27.8099C23.45 27.5699 24.12 27.3199 24.8 27.0399C24.0109 26.5659 23.2794 26.0022 22.62 25.36L22.69 25.2899L22.56 25.1699C22.3861 24.974 22.199 24.7903 22 24.62C21.93 24.7 21.85 24.75 21.78 24.83V22.8999C22.8396 21.5028 23.5833 19.8924 23.96 18.1799C23.96 18.2399 24.04 18.2899 24.1 18.2999C24.16 18.3099 24.58 17.84 24.69 17.2C24.8 16.56 24.69 16 24.51 15.97C24.51 15.97 24.44 15.97 24.41 15.97C24.7043 14.0296 24.5433 12.0474 23.94 10.1799C23.31 8.29993 20.59 7.50996 18.48 7.45996H18.24C16.13 7.45996 13.41 8.29993 12.78 10.1799C12.1782 12.0407 12.0139 14.0153 12.3 15.95C12.1 16.02 12.03 16.5499 12.14 17.1699C12.25 17.7899 12.52 18.2999 12.73 18.2699C12.7642 18.259 12.7951 18.2395 12.8196 18.2133C12.8441 18.187 12.8614 18.1549 12.87 18.12C13.1753 19.8062 13.8145 21.4143 14.75 22.85V24.85L14.66 24.7599C14.52 24.8899 14.39 25.0299 14.25 25.1799L13.87 25.5399L13.94 25.6C10.53 28.71 6.94001 28.53 4.82001 30.6C2.70001 32.67 2 44.08 2 44.08C2 44.08 4.63001 46.7899 13.51 47.5299C13.85 42.9699 14.91 33.29 17.47 30.73Z" stroke="#3F96B4" strokeWidth="2" strokeMiterlimit="10"/>
		<path d="M57.5002 31.88C54.6502 29.03 49.6402 29.36 45.0002 24.88L45.0903 24.78L44.9202 24.63C44.6907 24.3634 44.4399 24.1159 44.1702 23.89C44.0802 23.99 43.9803 24.07 43.8903 24.16V21.6001C45.3001 19.7339 46.2894 17.5847 46.7902 15.3C46.8075 15.3377 46.8325 15.3714 46.8635 15.3989C46.8944 15.4265 46.9308 15.4473 46.9703 15.4601C47.2503 15.4601 47.6103 14.85 47.7603 14C47.9103 13.15 47.7603 12.4001 47.5203 12.3501C47.4775 12.3403 47.433 12.3403 47.3903 12.3501C47.7816 9.75941 47.5659 7.11325 46.7603 4.62012C45.9203 2.12012 42.3002 1.07 39.4802 1H39.1302C36.3202 1.07 32.6902 2.12012 31.8502 4.62012C31.0445 7.10292 30.8289 9.73932 31.2203 12.3201C30.9503 12.4101 30.8502 13.1201 31.0002 13.9501C31.1502 14.7801 31.5002 15.46 31.7902 15.41C31.8338 15.394 31.8733 15.3687 31.9061 15.3359C31.9389 15.3031 31.9642 15.2636 31.9802 15.2201C32.3841 17.4661 33.2342 19.6082 34.4802 21.52V24.13L34.3602 24.02C34.1671 24.1968 33.9835 24.3837 33.8102 24.5801L33.3002 25.05L33.3903 25.14C28.8503 29.28 24.0202 29.0401 21.2302 31.8201C18.4402 34.6001 17.4902 49.8201 17.4902 49.8201C17.4902 49.8201 22.2103 54.71 39.3203 54.67H39.3802C56.4902 54.67 61.2203 49.8201 61.2203 49.8201C61.2203 49.8201 60.3702 34.75 57.5002 31.88Z" stroke="#3F96B4" strokeWidth="2" strokeMiterlimit="10"/>
	</svg>
);

export default MyContactIcon;
