import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {cacheQueryResponse} from 'common/app/utilities/cacheUtility/cacheUtility';
import {isEnableQueryBuilderRedesign} from 'common/stores/tenantConfig';
import {Attribute} from './query/advanced/tree/types/Attribute.types';

// This is the contract the backend is expected to uphold for category metadata
export interface CategoryMetadata {
	categoryEnumName: string; // The java enum name, which should be used in requests to the backend
	accountMasterCategory: string; // The unique ID used in the database table (PLS_MultiTenant.ATTRIBUTE_CATEGORY)
	displayName: string; // The user-facing name of the category
	legacyDisplayName: string; // This exists for legacy reasons. The old names
	// are used as category ids in many places throughout our stack, so moving
	// away from it is very difficult
	iconUri: string; // The URI of the category's icon
	themeColor: string; // The category's accent color
	defaultMaximumAttributes: number; // The default maximum number of attributes that can be put into this category
	isPremium: boolean;
	isVisible: boolean; // Whether this category should be shown in UI (filtering done by backend; should always be true)
	topBucketDisplayStrategy: TopBucketDisplayStrategy;

	// The following are used for sorting in different pages. Lower numbers come first
	uIOrderMyData?: number;
	uIOrderAttrAdminPremiumAttribute?: number;
}

export enum TopBucketDisplayStrategy {
	Attribute = 'A',
	Subcategory = 'S',
}

export enum CateGoryThemeColor {
	LightBrandBlue = '#3095B4',
	LightBlue = '#0092CC',
	LightPurple = '#BE47B5',
	DarkPurple = '#632A6E',
	LightViolet = '#BA4589',
	DarkViolet = '#802D5E',
	LightTeal = '#089E94',
	DarkTel = '#006D75',
	LightPink = '#BF4860',
	DarkPink = '#9B3054',
	Segment = '#5F86DA',
}

export function loadCategoryMetadata(): Promise<CategoryMetadata[]> {
	return cacheQueryResponse('categoryMetadataCache', () =>
		axiosInstance
			.get<CategoryMetadata[]>('/pls/attributecategory/categories', {
				withCredentials: true,
			})
			.then(({data}) => data)
	);
}

export const getMetadataForCategory = (
	metadata: CategoryMetadata[],
	ident: string
): CategoryMetadata | undefined =>
	metadata?.find(({categoryEnumName, displayName, legacyDisplayName}) =>
		[categoryEnumName, displayName, legacyDisplayName].includes(ident)
	);

const colorMap = new Map();
export const getCategoryThemeColor = (
	metadata?: CategoryMetadata,
	item?: Attribute | undefined
): string => {
	let color = metadata?.themeColor || CateGoryThemeColor.Segment;
	const enableQueryBuilderRedesign = isEnableQueryBuilderRedesign();
	if (item && enableQueryBuilderRedesign) {
		const key = `${item?.Entity}-${item?.Category}`;
		if (colorMap.has(key)) {
			color = colorMap.get(key);
		} else {
			color =
				Object.values(CateGoryThemeColor)?.[
					colorMap.size % Object.keys(CateGoryThemeColor).length
				] || CateGoryThemeColor.Segment;
			colorMap.set(key, color);
		}
	}
	return color;
};
