import template from './subcategorytile.component.html';
import '../tile.scss';

export default function () {
	return {
		restrict: 'A',
		scope: {
			vm: '=',
			count: '=',
			subcategory: '=',
			metadata: '<',
		},
		controllerAs: 'vm',
		template,
		controller($scope) {
			'ngInject';

			const vm = $scope.vm;

			angular.extend(vm, {});

			vm.getCount = (attribute) => {
				return attribute.TopBkt
					? attribute.TopBkt.Cnt
					: attribute.Count
					? attribute.Count
					: 0;
			};

			vm.inSubcategory = function (enrichment) {
				const category = vm.selected_categories[enrichment.Category],
					subcategories =
						category && category['subcategories']
							? category['subcategories']
							: [],
					subcategory = enrichment.Subcategory;

				if (enrichment.DisplayName && !subcategories.length) {
					// for case where this is used as a | filter in the enrichments ngRepeat on initial state
					return true;
				}

				if (!subcategories.length) {
					return false;
				}

				const selected =
					typeof category === 'object' &&
					subcategories.indexOf(subcategory) > -1;

				return selected;
			};

			vm.subcategoryClick = function (subcategory, $event) {
				const target = angular.element($event.target),
					currentTarget = angular.element($event.currentTarget);

				if (
					target.closest('[ng-click]:not(.ignore-ngclick)')[0] !==
					currentTarget[0]
				) {
					// do nothing, user is clicking something with it's own click event
				} else {
					vm.setSubcategory(vm.subcategory === subcategory ? '' : subcategory);
					vm.metadata.current = 1;
					vm.updateStateParams();
				}
			};
		},
	};
}
