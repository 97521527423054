import React, {react2angular} from 'common/react-vendor';
import {Provider} from 'common/redux-vendor';
import {store} from 'store';
import {IAC360SegmentHeader, AC360SegmentHeader} from './AC360Header';

const AC360HeaderWithRedux = ({...props}: IAC360SegmentHeader): JSX.Element => {
	return (
		<Provider store={store}>
			<AC360SegmentHeader {...props} />
		</Provider>
	);
};

angular
	.module('common.datacloud.tabs.ac360NewHeader', [])
	.component(
		'acHeader',
		react2angular(
			AC360HeaderWithRedux,
			['vmSegment'],
			['$state', '$stateParams']
		)
	);
