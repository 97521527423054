import React from 'common/react-vendor';
import {
	DNBTextField,
	DNBDialog,
	DNBTypography,
	DNBButton,
	DNBRadio,
	DNBRadioGroup,
	DNBSnackbar,
	IosShareIcon,
} from 'common/dnb-uux-vendor';

export enum FileType {
	'CSV' = 'CSV',
	'Excel' = 'Excel',
}

interface IExportCommonProps {
	tableKey?: string;
	defaultFileName: string;
	exportPromise: (opt: {fileName: string; format: FileType}) => Promise<void>;
}

interface IExportButtonProps extends IExportCommonProps {
	disabled: boolean;
}

interface IExportModalProps extends IExportCommonProps {
	handleCloseDialog: () => void;
}

function ExportModal(props: IExportModalProps): JSX.Element {
	const {handleCloseDialog, tableKey, defaultFileName, exportPromise} = props;
	const [fileName, setFileName] = React.useState(defaultFileName);
	const [fileType, setFileType] = React.useState(FileType.CSV);
	const [loading, setLoading] = React.useState(false);
	const [snackbarText, setSnackbarText] = React.useState('');

	const onExportConfirmPrimary = (): void => {
		setLoading(true);

		exportPromise({
			fileName,
			format: fileType,
		})
			.then(() => {
				setLoading(false);
				handleCloseDialog();
			})
			.catch(() => {
				setLoading(false);
				setSnackbarText(`export ${tableKey} error` || 'export error');
			});
	};
	return (
		<DNBDialog
			title='Export'
			size='compact'
			open
			// @ts-ignore.
			onClose={(_event: unknown, reason: string) =>
				reason !== 'backdropClick' && handleCloseDialog()
			}
			hasCloseButton
			primaryCTALabel={loading ? 'Exporting' : 'Export'}
			primaryCTA={() => onExportConfirmPrimary()}
			disabledPrimaryCTA={!fileName.trim() || loading}
			secondaryCTALabel='Cancel'
			secondaryCTA={() => handleCloseDialog()}>
			<DNBTypography variant='subtitle2'>Name your report</DNBTypography>
			<DNBTextField
				value={fileName}
				disabled={loading}
				onChange={(e) => setFileName(e.target.value)}
				size='compact'
				sx={{width: '100%'}}
			/>
			<DNBTypography variant='subtitle2' sx={{mt: 4}}>
				Choose file format
			</DNBTypography>
			<DNBRadioGroup
				value={fileType}
				onChange={(e) => setFileType(e.target.value as FileType)}>
				{Object.values(FileType).map((item) => (
					<DNBRadio
						label={item}
						value={item}
						id={item}
						disabled={loading}
						key={item}
						size='compact'
					/>
				))}
			</DNBRadioGroup>
			<DNBSnackbar
				variant='error'
				snackbarText={snackbarText}
				isOpen={!!snackbarText}
				duration={3000}
				handleClose={() => setSnackbarText('')}
			/>
		</DNBDialog>
	);
}

const Export = (props: IExportButtonProps): JSX.Element => {
	const {disabled, ...rest} = props;
	const [doesShowExportDialog, setDoesShowExportDialog] = React.useState(false);
	const handleCloseDialog = (): void => setDoesShowExportDialog(false);
	const handleExport = (): void => setDoesShowExportDialog(true);
	return (
		<>
			<DNBButton
				size='compact'
				variant='secondary'
				startIcon={<IosShareIcon />}
				disabled={disabled}
				onClick={handleExport}>
				Export
			</DNBButton>
			{doesShowExportDialog && (
				<ExportModal handleCloseDialog={handleCloseDialog} {...rest} />
			)}
		</>
	);
};

export default Export;
export type {IExportButtonProps};
