import React, {StateParams} from 'common/react-vendor';
import {FilterDrawer} from 'common/composite-uux-components/filter-drawer';
import {GenericFetcher} from 'atlas/connectors/EIF/Fetcher';
import {
	IJourneyStageData,
	IJourneyStageMapData,
} from 'atlas/JourneyStage/Data/JourneyStageData';
import {
	getJourneyStageListUrl,
	getJourneyStageMapPerSegment,
} from 'atlas/JourneyStage/ApiCalls';
import {ApiResponseType} from 'atlas/connectors/EIF/Data/API/ApiType';
import {
	IEntityType,
	sanitizedJourneyStages,
} from 'common/components/datacloud/journeystage/journey.helpers';
import {JourneyStageDataHandler} from 'atlas/JourneyStage/Data/JourneyStageDataHandler';
import {Restriction} from 'common/components/datacloud/query/query.types';
import {
	getQueryProperty,
	setJourneyEntitiesProperty,
} from 'common/stores/query';
import {Box} from 'common/dnb-uux-vendor';
import {EntityPage} from './EntityPage';

const AccountStageIdList = 'AccountStageIdList';
const ContactStageIdList = 'ContactStageIdList';

const EntitySessionKeyMap: Record<IEntityType, string> = {
	accounts: AccountStageIdList,
	contacts: ContactStageIdList,
};

type IJourneyEntityParam = {
	segment?: string;
	stageId?: string;
	stages?: string;
};

/**
 * JourneyEntityComponent interface
 * @param stageList Supported stage list.
 * @param currentStageList Pre-selected stage list.
 */
type IJourneyEntityComponent = IJourneyEntity & {
	stageList: IJourneyStageData[];
	currentStageList: number[];
};

/**
 * Journey entity component.
 * Used by two routes @SegmentNgState.JourneyAccounts @SegmentNgState.JourneyContacts
 */
const JourneyEntityComponent = ({
	$stateParams,
	entityType,
	stageList,
	currentStageList,
}: IJourneyEntityComponent): React.ReactElement => {
	const {segment = '', stageId} = $stateParams as IJourneyEntityParam;
	const [isFilterOpen, setFilterOpen] = React.useState(
		!!currentStageList.length
	);
	const defaultOptions = React.useMemo(
		() =>
			stageList.map(({name, id}) => ({
				label: name,
				value: `${id}`,
				hideCount: true,
			})),
		[stageList]
	);
	const mapFilterValues = React.useMemo(
		(): number[] => defaultOptions.map(({value}) => parseInt(value, 10)),
		[defaultOptions]
	);
	const [stageIds, setStageIds] = React.useState(currentStageList);
	const handleFilterChange = (checked: boolean, value: string): void => {
		const newVaue = parseInt(value, 10);
		const newFilters = checked
			? [...stageIds, newVaue]
			: stageIds.filter((filter) => filter !== newVaue);
		setStageIds(newFilters);
		if (newFilters.length === 0) {
			setJourneyEntitiesProperty(undefined);
		}
	};
	const handleSelectAllChange = (checked: boolean): void => {
		const newFilters = checked ? mapFilterValues : [];
		setStageIds(newFilters);
		if (newFilters.length === 0) {
			setJourneyEntitiesProperty(undefined);
		}
	};
	const restriction = {
		account_restriction: getQueryProperty<Restriction>('accountRestriction'),
		contact_restriction: getQueryProperty<Restriction>('contactRestriction'),
		member_restriction: getQueryProperty<Restriction>('memberRestriction'),
	};
	React.useEffect(() => {
		sessionStorage.setItem(
			EntitySessionKeyMap[entityType],
			stageId ? `${stageIds.join(',')}-${stageId}` : stageIds.join(',')
		);
		return () => {
			sessionStorage.removeItem(EntitySessionKeyMap[entityType]);
		};
	}, [entityType, stageId, stageIds]);
	return (
		<Box display='flex'>
			<FilterDrawer
				isOpen={isFilterOpen}
				filters={[
					{
						id: 'JourneyStage',
						name: 'Buyer Journey Stages',
						options: defaultOptions,
						selectedFilters: stageIds.map(String),
						onFilterChange: handleFilterChange,
						onSelectAllChange: handleSelectAllChange,
					},
				]}
				onSelectAllChange={handleSelectAllChange}
				hasActiveFilters={stageIds.length !== 0}
			/>
			<EntityPage
				stageIds={stageIds}
				filter={[isFilterOpen, setFilterOpen]}
				entityType={entityType}
				segment={segment}
				restriction={restriction}
			/>
		</Box>
	);
};

type IJourneyEntity = {
	$stateParams: StateParams;
	entityType: IEntityType;
};

/**
 * Base journey entity component.
 * @param prop @IJourneyEntity
 */
const JourneyEntity = ({
	$stateParams,
	entityType,
}: IJourneyEntity): React.ReactElement => {
	const {segment = '', stages = ''} = $stateParams as IJourneyEntityParam;
	const currentStageIdList = stages.split(',').map((id) => parseInt(id, 10));
	return (
		<GenericFetcher<IJourneyStageMapData>
			apiConfig={{
				url: () => getJourneyStageMapPerSegment(segment),
			}}>
			{({id}) => (
				<GenericFetcher<IJourneyStageData[]>
					apiConfig={{
						url: () => getJourneyStageListUrl(id),
						responseType: ApiResponseType.Array,
					}}
					query={{omitQuery: false}}>
					{(stageDataList) => {
						const stageIdList = stageDataList.map(({id}) => id);
						return (
							<JourneyEntityComponent
								$stateParams={$stateParams}
								entityType={entityType}
								stageList={stageDataList.map((data) =>
									new JourneyStageDataHandler(data).refill()
								)}
								currentStageList={sanitizedJourneyStages(
									currentStageIdList,
									stageIdList
								)}
							/>
						);
					}}
				</GenericFetcher>
			)}
		</GenericFetcher>
	);
};

/**
 * Root react component for Accounts with journey stage filter.
 */
const JourneyAccount = (prop: IJourneyEntity): React.ReactElement => {
	return <JourneyEntity {...prop} entityType='accounts' />;
};

/**
 * Root react component for Contacts with journey stage filter.
 */
const JourneyContact = (prop: IJourneyEntity): React.ReactElement => {
	return <JourneyEntity {...prop} entityType='contacts' />;
};

export type {IJourneyEntityParam};
export {EntitySessionKeyMap, JourneyEntity, JourneyAccount, JourneyContact};
