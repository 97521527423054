import React from 'common/react-vendor';

const {useState, useRef, useCallback, useEffect} = React;

// Once we're on react 18, we should replace this with suspense

export function usePromise<Input extends unknown[], Data>(
	fn: (...input: Input) => Promise<Data>,
	...input: Input
): {
	error: Error | null;
	data: Data | null;
	loading: boolean;
	revalidate: () => void;
	invalidate: () => void;
} {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [data, setData] = useState<Data | null>(null);
	const runId = useRef(0);
	const resolveId = useRef(0);

	const revalidate = useCallback(
		(): (() => void) => {
			const thisRun = ++runId.current;
			let canceled = false;
			const complete = (cb: () => void): void => {
				if (canceled) return;
				// If this run is more recent than the current resolution, we can update the data
				if (thisRun > resolveId.current) {
					// If we're the most recent run (i.e. no run has been started since starting this one), we're no longer in a loading state
					if (thisRun === runId.current) setLoading(false);
					resolveId.current = thisRun;
					cb();
				}
			};
			setError(null);
			setLoading(true);
			fn(...input)
				.then((res) => complete(() => setData(res)))
				.catch((err) =>
					complete(() =>
						setError(
							err instanceof Error
								? err
								: new Error('An unexpected error ocurred')
						)
					)
				);
			return () => {
				canceled = true;
				setLoading(false);
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps -- Input needs to be spread in because it's recreated with each function call
		[fn, ...input]
	);

	// Revalidate whenever the revalidation strategy changes
	useEffect(revalidate, [revalidate]);

	const invalidate = useCallback(() => {
		setData(null);
		revalidate();
	}, [revalidate]);

	return {error, data, loading, revalidate, invalidate};
}
