import React, {ReactDOM} from 'common/react-vendor';
import classNames from 'classnames';
import {
	CloseIcon,
	DNBButton,
	DNBIconButton,
	DNBTypography,
} from 'common/dnb-uux-vendor';
import {useMountPoint} from './mountPoint';
import classes from './Drawer.module.scss';

const {useEffect, useRef} = React;
const {createPortal} = ReactDOM;

export interface IDrawerProps {
	title?: React.ReactNode;
	onOk?: () => void;
	okText?: string;
	open?: boolean;
	onCancel?: () => void;
	hideFooter?: boolean;
	children?: React.ReactNode;
	confirmLoading?: boolean;
	maskStyle?: React.CSSProperties;
}

export default function Drawer({
	title,
	open,
	onOk,
	okText = 'Ok',
	confirmLoading,
	onCancel,
	hideFooter = false,
	children,
	maskStyle = {},
}: IDrawerProps): React.ReactElement | null {
	const documentBodyOverflow = useRef('');
	const mountPoint = useMountPoint();
	useEffect(() => {
		documentBodyOverflow.current = document.body.style.overflow;
	}, []);
	useEffect(() => {
		document.body.style.overflow = open
			? 'hidden'
			: documentBodyOverflow.current;
		return () => {
			document.body.style.overflow = documentBodyOverflow.current;
		};
	}, [open]);

	function onMaskClick(e: React.MouseEvent): void {
		if (e.target === e.currentTarget && onCancel) {
			onCancel();
		}
	}

	return (
		mountPoint &&
		createPortal(
			<div
				style={{...maskStyle}}
				className={`${classes.mask} ${open ? classes.show : classes.hide}`}
				onMouseDown={onMaskClick}>
				<div className={classes.drawer}>
					<div className={classes['drawer-header']}>
						<DNBTypography variant='h6'>{title}</DNBTypography>
						{hideFooter && (
							<DNBIconButton onClick={() => onCancel?.()}>
								<CloseIcon />
							</DNBIconButton>
						)}
					</div>
					<div className={classes['drawer-body']}>{children}</div>
					<div
						className={classNames({
							[classes['drawer-footer']!]: true,
							[classes['drawer-footer-hide']!]: hideFooter,
						})}>
						{onCancel && (
							<DNBButton variant='secondary' size='compact' onClick={onCancel}>
								Cancel
							</DNBButton>
						)}
						{onOk && (
							<DNBButton
								variant='primary'
								size='compact'
								onClick={onOk}
								isLoading={confirmLoading}>
								{okText}
							</DNBButton>
						)}
					</div>
				</div>
			</div>,
			mountPoint
		)
	);
}
