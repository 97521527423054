import {getDataCloudStore} from 'common/stores/datacloud';
import {getQueryProperty} from 'common/stores/query';
import {QueryState} from 'common/stores/query/types';
import {isSegmentationV2Enabled} from 'common/stores/tenantConfig';
import {getAllBuckets} from '../../query/query.helpers';
import {Restriction} from '../../query/query.types';
import {ITabCountMap} from './SegmentHelper';
import {ITabType} from './SegmentTabsHelper';

/**
 * Get the count for attribute tab.
 * @returns The count for attribute tab.
 */
const getAttributeCount = (): number => {
	const DataCloudStore = getDataCloudStore();
	return DataCloudStore.metadata.enrichmentsTotal || 0;
};

/**
 * Get count for query builder tab.
 * @returns The count for query builder tab.
 */
const getQuerybuilderCount = (): number => {
	const all = [
		{restriction: 'accountRestriction', enabled: true},
		{restriction: 'contactRestriction', enabled: true},
		{restriction: 'memberRestriction', enabled: isSegmentationV2Enabled()},
	]
		.filter(({enabled}) => enabled)
		.flatMap(({restriction}) =>
			getAllBuckets(
				getQueryProperty<Restriction>(restriction as keyof QueryState)
					?.restriction?.logicalRestriction?.restrictions
			)
		);
	return all.length || 0;
};

/**
 * Get count for account / contacts tab.
 * @param type @ITabType
 * @returns The count for account / contacts tab.
 */
const getEntityCounts = (type: ITabType): number => {
	const counts = getQueryProperty<ITabCountMap>('counts');
	return counts[type]?.value ?? 0;
};

/**
 * Get count for account / contacts tab with journey.
 * @param type @ITabType
 * @returns The count for account / contacts tab with journey.
 */
const getJourneyEntityCounts = (type: ITabType): number | undefined => {
	const counts = getQueryProperty<ITabCountMap>('journeyCounts');
	return counts?.[type]?.value;
};

export {
	getAttributeCount,
	getQuerybuilderCount,
	getEntityCounts,
	getJourneyEntityCounts,
};
