import React from 'react';
import {StateService} from '@uirouter/angularjs';
import {DNBDrawer} from 'common/dnb-uux-vendor';
import {DNBDrawerSideNavigationItem} from 'common/local-uux-components/sideNavigation/DNBDrawerSideNavigationItem';
import {SidebarLink} from '../AttrConfig.types';
import styles from './AttributesSidebar.module.scss';

export default function AttrSidebar({
	menus,
	$state,
}: {
	menus: SidebarLink[];
	$state: StateService;
}): React.ReactElement {
	const [isOpen, setIsOpen] = React.useState(true);
	const sidebarMenus = React.useMemo(() => {
		return menus.map(({sref, subMenu, ...rest}) => ({
			...rest,
			id: sref,
			value: sref,
			subMenu: subMenu?.map(({sref, label, ...rest}) => ({
				...rest,
				id: sref,
				value: sref,
				label,
			})),
		}));
	}, [menus]);
	return (
		<DNBDrawer
			relative
			border
			variant='persistent'
			open={isOpen}
			onClose={() => setIsOpen(false)}
			onToggle={() => setIsOpen((prev) => !prev)}
			className={styles.sidebar}>
			{sidebarMenus.map((option) => (
				<DNBDrawerSideNavigationItem
					key={option.value}
					item={option}
					onClick={({value, subMenu}) => {
						let targetLink = value;
						if (subMenu?.length) {
							targetLink = subMenu[0]!.value;
						}
						$state.go(targetLink, {}, {reload: true});
					}}
					selectedOption={$state.current.name}
				/>
			))}
		</DNBDrawer>
	);
}
