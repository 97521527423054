import {Restriction} from 'common/components/datacloud/query/query.types';
import {IJourneyStageData, IgnoreField, NewlyAddedId} from './JourneyStageData';

/**
 * Get cloned name.
 * @param name Original name.
 * @returns The cloned name.
 */
const clonedName = (name: string): string => `${name} (clone)`;

/**
 * Journey stage data handler.
 * @param data The journey stage raw data.
 * @param refill Refill journey stage raw data with computed FE fields.
 * @param isNewlyAdded Function indicates if stage is newly added.
 * @param duplicate Function to duplicate a journey stage data.
 */
interface IJourneyStageDataHandler {
	data: IJourneyStageData;
	refill: () => void;
	isNewlyAdded: () => boolean;
	duplicate: () => IJourneyStageData;
}

/**
 * Get the count of bucket restrictions.
 * @param restrictions @Restriction list
 * @returns The count of bucket restrictions.
 */
const countBucketRestriction = (restrictions?: Restriction[]): number => {
	return (restrictions || []).reduce((pre, restriction) => {
		if (restriction.activityRestriction) {
			return (
				pre +
				countBucketRestriction(
					restriction.activityRestriction.restriction.logicalRestriction
						?.restrictions
				)
			);
		}
		if (restriction.logicalRestriction) {
			return (
				pre +
				countBucketRestriction(restriction.logicalRestriction.restrictions)
			);
		}
		if (restriction.bucketRestriction) {
			return pre + 1;
		}
		return pre;
	}, 0);
};

class JourneyStageDataHandler implements IJourneyStageDataHandler {
	data: IJourneyStageData;

	constructor(data: IJourneyStageData) {
		this.data = data;
	}

	refill = (): IJourneyStageData => {
		const {accountRestriction, contactRestriction} = this.data;
		this.data.accountRestrictionObj = accountRestriction
			? (JSON.parse(accountRestriction) as Restriction)
			: undefined;
		this.data.contactRestrictionObj = contactRestriction
			? (JSON.parse(contactRestriction) as Restriction)
			: undefined;
		this.data.noOfQueries =
			countBucketRestriction(
				this.data.accountRestrictionObj?.restriction?.logicalRestriction
					?.restrictions
			) +
			countBucketRestriction(
				this.data.contactRestrictionObj?.restriction?.logicalRestriction
					?.restrictions
			);
		return this.data;
	};

	isNewlyAdded = (): boolean => this.data.id === NewlyAddedId;

	duplicate = (): IJourneyStageData => {
		const cloneData = {...this.data};
		Object.keys(IgnoreField).forEach((key) => delete cloneData[key]);
		return {
			...cloneData,
			name: clonedName(cloneData.name),
			stageNumber: cloneData.stageNumber + 1,
		};
	};
}

export type {IJourneyStageDataHandler};
export {JourneyStageDataHandler};
