import {store} from 'store';
import React, {react2angular, NgStateService} from 'common/react-vendor';
import {Provider} from 'common/redux-vendor';
import {IJourneyStageData} from 'atlas/JourneyStage/Data/JourneyStageData';
import {ParentSegmentContainer, ParentStageContainer} from './ParentContainer';

type IParentContainer = {
	$state?: NgStateService;
	titleText?: string;
	buttonText?: string;
	onClick?: () => void;
};

type IParentStageContainer = IParentContainer & {stage: IJourneyStageData};

const ParentSegmentContainerWithRedux = (
	prop: IParentContainer
): JSX.Element => {
	return (
		<Provider store={store}>
			<ParentSegmentContainer {...prop} />
		</Provider>
	);
};

const ParentStageContainerWithRedux = (
	prop: IParentStageContainer
): JSX.Element => {
	return (
		<Provider store={store}>
			<ParentStageContainer {...prop} />
		</Provider>
	);
};

angular
	.module('common.datacloud.query.builder.parentContainer', [])
	.component(
		'parentSegmentContainer',
		react2angular(ParentSegmentContainerWithRedux, [], ['$state'])
	)
	.component(
		'parentStageContainer',
		react2angular(ParentStageContainerWithRedux, ['stage'], ['$state'])
	);

export type {IParentContainer, IParentStageContainer};
