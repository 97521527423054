import React from 'common/react-vendor';

const MyDataIcon = (): React.ReactElement => (
	<svg width="79" height="53" viewBox="0 0 79 53" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9.81995 38.1401H69.63M69.63 38.46V1H9.63V38.46L1 51.83H78L69.63 38.46Z" stroke="#4196B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M29.4199 19.9098L37.0699 26.4098L49.54 13.8398" stroke="#4196B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
);

export default MyDataIcon;
