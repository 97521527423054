import React from 'common/react-vendor';
import {LePagination} from 'common/widgets/pagination/le-pagination';

/**
 * TODO: Angular directive components can't use camelCase attributes...
 * Since we are migrating everything to React I feel that ideally
 * we should keep using camelCases for props, that is why I am creating this
 * temporary wrapper to pass the angular attributes to the React component...
 *
 * DONT use this component!
 * This component purpose is only to be use in the temporary
 * date-attribute.component react2angular
 *
 * TODO: DELETE ME Once all instances of <pd-pagination-controls />
 * are removed...
 */

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
const LePaginationWithAngularProps = ({
	total,
	perpage,
	start,
	callback,
	classname,
}: Record<string, any>): React.ReactElement => (
	<LePagination
		total={total}
		perPage={perpage}
		start={start}
		callback={callback}
		className={classname}
	/>
);

export {LePaginationWithAngularProps};
