import {
	axiosInstance,
	UIActionResponse,
} from 'common/app/utilities/axiosUtility/axiosInstance';
import {cacheQueryResponse} from 'common/app/utilities/cacheUtility/cacheUtility';
import {RatingModelRule} from 'common/components/datacloud/datacloud.types';
import {Attribute} from 'common/components/datacloud/query/advanced/tree/types';
import {AxiosResponse} from 'common/network.vendor';
import {dispatchRatingsEnginePropertyValue} from 'atlas/stores/ratingsEngine';
import {RatingEnginesCoverageSegments} from '../playbook/content/metadataLiveramplaunch/types';
import {handlePromiseRejected} from '../import/import.helpers';
import {
	RatingEngine,
	CoverageRequest,
	CoverageResponse,
	SaveRatingOpts,
	SaveRatingResponse,
	SaveRatingParams,
	GetProductsParams,
	GetProductsResponse,
	SaveRulesOpts,
	SaveModel,
	GetRatingDashboardResponse,
	UpdateRatingModelOpts,
	UpdateRatingModelResponse,
	AIRatingRecord,
} from './ratingsengine.types';
import {AxiosUploadProgressHandler} from './AccountFitModelingWizard/types/models.types';

// #region GET
/**
 * It seems that this query is only being
 * used in the old Models page. I think that one
 * is not accessible to the user anymore. Maybe we can
 * delete it later???
 */
export const getRatings = async (
	active: boolean
): Promise<RatingEngine[] | string> =>
	axiosInstance
		.get<RatingEngine[]>(`/pls/ratingengines${active ? '?status=ACTIVE' : ''}`)
		.then(({data}) => data, handlePromiseRejected);

export const getProducts = async ({
	max = 1000,
	offset = 0,
}: GetProductsParams): Promise<GetProductsResponse | string> =>
	axiosInstance
		.get<GetProductsResponse>('/pls/products/data', {
			params: {
				max,
				offset,
			},
		})
		.then(({data}) => data, handlePromiseRejected);

export const getRating = async (id: string): Promise<RatingEngine | string> =>
	axiosInstance
		.get<RatingEngine>(`/pls/ratingengines/${id}`)
		.then(({data}) => data, handlePromiseRejected);

export const getRatingDashboard = async (
	id: string
): Promise<GetRatingDashboardResponse | string> =>
	axiosInstance
		.get<GetRatingDashboardResponse>(`/pls/ratingengines/${id}/dashboard`)
		.then(({data}) => data, handlePromiseRejected);

export const getRatingModel = async (
	ratingId: string,
	modelId: string
): Promise<AIRatingRecord | string> =>
	axiosInstance
		.get<AIRatingRecord>(
			`/pls/ratingengines/${ratingId}/ratingmodels/${modelId}`
		)
		.then(({data}) => data, handlePromiseRejected);

export const getRatingEnginesDependenciesModelView = async (
	id: string
): Promise<UIActionResponse | string> =>
	axiosInstance
		.get<UIActionResponse>(`/pls/ratingengines/${id}/dependencies/modelAndView`)
		.then(({data}) => data, handlePromiseRejected);
// #endregion GET

// #region POST
export const getScorableAccounts = async (
	ratingEngine: RatingEngine,
	engineId: string,
	iterationId: string
): Promise<number | string> =>
	cacheQueryResponse('getScorableAccounts', () =>
		axiosInstance
			.post<RatingEngine, AxiosResponse<number>>(
				`/pls/ratingengines/${engineId}/ratingmodels/${iterationId}/modelingquery/count`,
				ratingEngine,
				{
					params: {
						querytype: 'TARGET',
					},
				}
			)
			.then(({data}) => data, handlePromiseRejected)
	);

export const getRatingsChartData = async (
	CoverageRequest: CoverageRequest
): Promise<CoverageResponse | string> =>
	axiosInstance
		.post<CoverageRequest, AxiosResponse<CoverageResponse>>(
			'/pls/ratingengines/coverage',
			CoverageRequest
		)
		.then(({data}) => data, handlePromiseRejected);

export const saveRating = async (
	opts: SaveRatingOpts,
	params: SaveRatingParams,
	action = 'true'
): Promise<SaveRatingResponse | string> =>
	axiosInstance
		.post<SaveRatingOpts, AxiosResponse<SaveRatingResponse>>(
			`/pls/ratingengines?create-action=${action}`,
			opts,
			{
				params,
			}
		)
		.then(({data}) => {
			dispatchRatingsEnginePropertyValue('currentRating', data);
			return data;
		}, handlePromiseRejected);

export const saveRules = async (
	opts: SaveRulesOpts
): Promise<RatingModelRule | string> =>
	axiosInstance
		.post<SaveModel, AxiosResponse<RatingModelRule>>(
			`/pls/ratingengines/${opts.rating_id}/ratingmodels/${opts.model_id}`,
			opts.model
		)
		.then(({data}) => data, handlePromiseRejected);

export const createAIModel = async (
	ratingId: string,
	modelId: string,
	handleUploadProgress?: AxiosUploadProgressHandler
): Promise<string> =>
	axiosInstance
		.post<undefined, AxiosResponse<string>>(
			`/pls/ratingengines/${ratingId}/ratingmodels/${modelId}/model`,
			undefined,
			{
				onUploadProgress: handleUploadProgress,
			}
		)
		.then(({data}) => data, handlePromiseRejected);

export const updateRatingModel = async (
	ratingId: string,
	modelId: string,
	opts: UpdateRatingModelOpts
): Promise<UpdateRatingModelResponse | string> =>
	axiosInstance
		.post<UpdateRatingModelOpts, AxiosResponse<UpdateRatingModelResponse>>(
			`/pls/ratingengines/${ratingId}/ratingmodels/${modelId}`,
			opts
		)
		.then(({data}) => data, handlePromiseRejected);

export const saveIteration = async (
	engineId: string,
	iteration: AIRatingRecord
): Promise<AIRatingRecord | string> =>
	axiosInstance
		.post<AIRatingRecord, AxiosResponse<AIRatingRecord>>(
			`/pls/ratingengines/${engineId}/ratingmodels`,
			iteration
		)
		.then(({data}) => data, handlePromiseRejected);

export const launchModeling = async (
	engineId: string,
	modelId: string,
	attributes: Attribute[]
): Promise<string> =>
	axiosInstance
		.post<Attribute[], AxiosResponse<string>>(
			`/pls/ratingengines/${engineId}/ratingmodels/${modelId}/model`,
			attributes
		)
		.then(({data}) => data, handlePromiseRejected);

export const getTrainingCounts = async (
	ratingId: string,
	modelId: string,
	ratingEngine: RatingEngine,
	queryType: string
): Promise<number | string> =>
	axiosInstance
		.post<RatingEngine, AxiosResponse<number>>(
			`/pls/ratingengines/${ratingId}/ratingmodels/${modelId}/modelingquery/count`,
			ratingEngine,
			{
				params: {
					querytype: queryType,
				},
			}
		)
		.then(({data}) => data, handlePromiseRejected);

export const validateModel = async (
	ratingId: string,
	modelId?: string,
	ratingEngine?: RatingEngine
): Promise<boolean | string> =>
	axiosInstance({
		method: ratingEngine ? 'POST' : 'GET',
		url: `/pls/ratingengines/${ratingId}/ratingmodels/${modelId}/model/validate`,
		data: ratingEngine,
	}).then(({data}: AxiosResponse) => data, handlePromiseRejected);

export const getProductCoverage = async (
	ratingEngine: RatingEngine,
	productIds: string[],
	purchasedbeforeperiod: number | null
): Promise<RatingEnginesCoverageSegments | string> =>
	axiosInstance
		.post(
			'/pls/ratingengines/coverage/segment/products',
			{
				ratingEngine,
				productIds,
			},
			{
				params: {purchasedbeforeperiod},
			}
		)
		.then(({data}) => data, handlePromiseRejected);
// #endregion POST

// #region DELETE
/**
 * It seems that this query is only being
 * used in the old Models page. I think that one
 * is not accessible to the user anymore. Maybe we can
 * delete it later???
 */
export const deleteRating = async (
	ratingName: string
): Promise<boolean | string> =>
	axiosInstance
		.delete<boolean>(`/pls/ratingengines/${ratingName}`)
		.then(({data}) => data, handlePromiseRejected);
// #endregion DELETE
