import React, {Component} from 'common/react-vendor';
import {store, redux} from 'store';
import './le-itembar.scss';
import LeSort from 'widgets/filters/sort/le-sort';
import LeRefine from 'widgets/filters/refine/le-refine';
import LeFilterList from 'widgets/filters/list/le-list';
import LeDateRange from 'widgets/filters/date-range/le-date-range';
import LeRangeSlider from 'widgets/filters/range-slider/le-range-slider';
import LeButtonToggles from 'widgets/filters/button-toggles/le-button-toggles';
import LeSearch from 'widgets/filters/search/le-search';
import LeButtons from 'widgets/buttons/group/le-buttons';
import RebrandLeDropdown from 'widgets/rebrand/dropdown/le-dropdown';

const ReactComponents = {
	LeSort: LeSort,
	LeRefine: LeRefine,
	LeFilterList: LeFilterList,
	LeDateRange: LeDateRange,
	LeRangeSlider: LeRangeSlider,
	LeButtonToggles: LeButtonToggles,
	LeSearch: LeSearch,
	LeButtons: LeButtons,
	RebrandLeDropdown,
};

export default class LeItemBar extends Component {
	constructor(props) {
		super(props);

		const state = {shade: {}};

		this.redux = redux({
			path: props.namespace || '@view.itembar',
			state: Object.assign(state, props.config),
			component: this,
			appCache: ['shade'], // save state & changes to local storage
		});

		this.initial = {}; // default config cache for CLEAR button
		this.tabTarget = null;
	}

	componentDidMount() {
		const {tabs} = this.redux.get();

		if (tabs) {
			this.clickTab(tabs[0].target, tabs);
		}
	}

	clickShade = (name) => {
		const state = this.redux.get();
		state.shade[name] = !state.shade[name];
		this.redux.set(state);
	};

	clickTab = (target, tabs) => {
		const state = this.redux.get();
		tabs.forEach((item) => {
			state.shade[item.target] = item.target != target;
		});
		this.tabTarget = target;
		this.redux.set(state);
	};

	clickClear = () => {
		Object.keys(this.initial).forEach((name) => {
			const {namespace, config} = this.initial[name];

			store.dispatch({
				type: '_CLEAR',
				namespace,
			});
			store.dispatch({
				type: '_SET',
				payload: config,
				namespace,
			});
		});
	};

	render() {
		const state = this.redux.get(),
			config = state.itembar || {},
			items = config.order || this.props.children,
			settings = Object.assign(
				{},
				{
					vertical: false,
					visible: true,
					collapsible: true,
					header: false,
					tabs: false,
				},
				this.props.config || {}
			),
			{vertical, visible, header, tabs} = settings,
			className = `itemlist-filters-panel${visible ? ' open' : ''}`,
			content = (
				<ul
					className={`le-itembar${vertical ? ' vertical' : ' horizontal'}${
						tabs ? ' le-itembar-tabbed' : ''
					}`}>
					{vertical && header ? this.renderHeader(settings) : null}
					{vertical && tabs ? this.renderTabs(tabs) : null}
					{this.renderItems(
						items && items.length ? items : [items],
						config,
						settings
					)}
				</ul>
			);

		return settings.vertical ? (
			<div className={className}>{content}</div>
		) : (
			content
		);
	}

	renderTabs(tabs) {
		return (
			<li key='panel-control-tabs' className='panel-controls controls-tabs'>
				<ul>
					{tabs.map((tab, i) => {
						return (
							<li
								key={tab.label + i}
								className={
									(!this.tabTarget && i === 0) || this.tabTarget == tab.target
										? 'active'
										: ''
								}
								onClick={() => {
									this.clickTab(tab.target, tabs);
								}}>
								{tab.label}
							</li>
						);
					})}
				</ul>
			</li>
		);
	}

	renderHeader({icon = 'fa fa-filter', label = 'Filter By', clear}) {
		const showClear = typeof clear != 'undefined',
			htmlLabel = (
				<span className='panel-header-label'>
					{icon ? <i className={icon} /> : ''}
					{label ? label : ''}
				</span>
			),
			htmlClear = (
				<span className='pull-right'>
					<button
						className='button white-button'
						onClick={this.clickClear}
						disabled={clear}>
						Clear
					</button>
				</span>
			);

		return (
			<li className='panel-controls' key={label.toString()}>
				{htmlLabel}
				{showClear ? htmlClear : ''}
			</li>
		);
	}

	renderItems(items, config, settings) {
		const collapsible = settings.vertical && settings.collapsible,
			state = this.redux.get(),
			initial = {};

		const html = items.map((element, index) => {
			if (!element || !element.props || element.props.hide) {
				return '';
			}
			const containerClass = element.props.containerClass || element.props.containerclass || '';
			if (!element.props.uglifytag) {
				return (
					<li
						key={`${element.props.name}-li-${index}`}
						className={`le-itembar-item ${containerClass}`}>
						{element}
					</li>
				);
			}

			const name = element.type.name,
				namespace = element.props.namespace,
				props = this.getAttrs({}, name, element),
				clone = this.renderItem(
					element.props.uglifytag,
					props,
					props,
					state,
					initial
				);

			return (
				<li
					key={`${name.toString()}${index}`}
					className={`le-itembar-item ${containerClass} ${
						state.shade[namespace] ? ' closed' : ''
					}`}>
					{this.props.config
						? this.renderToggle(
								namespace,
								containerClass,
								collapsible
						  )
						: ''}
					{clone}
				</li>
			);
		});

		if (Object.keys(initial).length > 0) {
			this.initial = initial;
		}

		return html;
	}

	renderItem(name, item, props, state, initial) {
		const component = item.component || ReactComponents[name];
		const config = props || this.getAttrs(item, name);

		if (!state.initial || !state.initial[config.namespace]) {
			initial[config.namespace] = Object.assign({}, config);
		}
		return React.createElement(component, config);
	}

	renderToggle(name, classname, collapsible) {
		if (!collapsible) {
			return '';
		}

		return (
			<button
				className='button borderless-button filter-windowshade-toggle'
				onClick={() => {
					this.clickShade(name);
				}}>
				<i className='fa fa-angle-up' />
			</button>
		);
	}

	getAttrs(attrs, name, element) {
		const ret = Object.assign({}, attrs, element.props);
		delete ret.component;
		delete ret.containerClass;
		delete ret.containerclass;
		ret.update = this.update;
		ret.noAppCache = (this.props.config || {}).noAppCache;
		ret.path = this.props.name || this.props.store;
		ret.name = element.props.uglifytag || name;
		return ret;
	}
}
