import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import httpService from 'common/app/http/http-service';
import Observer from 'common/app/http/observer';
import {
	formatDateStrToUTC,
	handleSumRecords,
} from 'atlas/playbook/content/CampaignsCommon.helpers';
import {FileType} from './content/components/campaigns/export/Export';
import {calSumMetrics} from './content/channelstable/AccountTable.helpers';

export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const DRAFT = 'DRAFT';
export const ARCHIVED = 'ARCHIVED';
// Importing, Pending, Duplicating, Failed for segments status
export const IMPORTING = 'IMPORTING';
export const PENDING = 'PENDING';
export const DUPLICATING = 'DUPLICATING';
export const FAILED = 'FAILED';

export const CAMPAIGN_GROUPS = 'campaignGroups';
export const CAMPAIGN_LIST = 'campaignList';
export const LAUNCH_HISTORY = 'launchHistory';
export const CHANNELS = 'channels';

export const PAGE_ROUTE = {
	[CAMPAIGN_GROUPS]: 'home.campaigngroups',
	[CAMPAIGN_LIST]: 'home.playlistchannels',
	[LAUNCH_HISTORY]: 'home.newlaunchhistory',
	[CHANNELS]: 'home.channels',
};

export const FILTER_PAGE_KEY = {
	CampaignGroup: 'CampaignGroup',
	CampaignList: 'Campaign',
	ViewByLaunch: 'ViewByLaunch',
	ViewByChannel: 'ViewByChannel',
};

export const CONST_UNMAPPED_GROUP = 'UNMAPPED_TO_ANY_CAMPAIGN_GROUP';

export const ALL_LAUNCH_STATES = [
	'Queued',
	'PreProcessing',
	'Skipped',
	'Launching',
	'Distributing',
	'Launched',
	'Failed',
	'Canceled',
	'Syncing',
	'Synced',
	'SyncFailed',
	'PartialSync',
];

const launchStatus2LaunchState = {
	'Queued': 'Queued',
	'Launching': 'Launching,PreProcessing,Syncing',
	'Skipped': 'Skipped',
	'Distributing': 'Distributing',
	'Launched': 'Launched,Synced',
	'Failed': 'Failed, SyncFailed',
	'Canceled': 'Canceled',
	'Partial Launch': 'PartialSync',
};

export const ALL_LAUNCH_STATUSES = Object.keys(launchStatus2LaunchState);

export const getLaunchStates = (launchStatuses) => {
	return launchStatuses.map((status) => launchStatus2LaunchState[status]);
};

class CampaignService {
	constructor() {
		if (!CampaignService.instance) {
			CampaignService.instance = this;
		}
		return CampaignService.instance;
	}

	deletePlays = (plays = [], cb) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					setTimeout(() => {
						// PLS-23137 hack pending further discussion
						resolve(response.data);
					}, 1000);
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.delete('/pls/play/deletePlays', observer, {}, plays, cb);
		});
	};

	deletePlaySets = (playSets = [], cb) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data);
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.delete(
				'/pls/playset/deletePlaySets',
				observer,
				{},
				playSets,
				cb
			);
		});
	};

	getChannelConfig = (channel) => {
		if (
			channel &&
			channel.lookupIdMap &&
			channel.lookupIdMap.externalSystemName &&
			channel.channelConfig &&
			channel.lastLaunch
		) {
			return channel.channelConfig[
				channel.lookupIdMap.externalSystemName.toLowerCase()
			];
		}
		return null;
	};

	addExtraPlayData = (plays, isCalendarSchedule = false) => {
		const amendedPlays = [];
		plays.forEach((play) => {
			const channels = [];
			// Reconstruct playLaunchChannels for backward compatibility
			play.playLaunchChannels = (
				isCalendarSchedule
					? play.channelSchedules
					: play.playLaunchChannels || []
			).map(
				({
					lookupIdMap: {
						externalSystemName,
						externalSystemType,
						orgName,
						configId,
					},
					id,
					expirationDate,
					expirationPeriodString,
					nextScheduledLaunch,
					cronScheduleExpression,
				}) => {
					const result = {
						channelConfig: {
							[externalSystemName.toLowerCase()]: {
								systemName: externalSystemName,
							},
						},
						lookupIdMap: {
							externalSystemName,
							externalSystemType,
							orgName,
							configId,
						},
						id,
						lastLaunch: true,
						expirationDate,
						expirationPeriodString,
						nextScheduledLaunch,
						cronScheduleExpression,
					};
					return result;
				}
			);
			play.playLaunchChannels.forEach((channel) => {
				const config = this.getChannelConfig(channel);
				if (config) {
					channels.push(config.systemName);
				}
			});
			play.channels = channels;
			if (play.targetSegment) {
				play.accounts = play.targetSegment.accounts;
				play.contacts = play.targetSegment.contacts;
				if (
					play.targetSegment &&
					play.targetSegment.team &&
					play.targetSegment.team.TeamName
				) {
					play.teamName = play.targetSegment.team.TeamName;
				}
			}
			amendedPlays.push(play);
		});

		return amendedPlays;
	};

	updateStatus = (status, plays = []) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data);
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.put(
				`/pls/play/updateStatus?toStatus=${status}`,
				plays,
				observer
			);
		});
	};

	updatePlaySetStatus = (status, playSets = []) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data);
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.put(
				`/pls/playset/updatePlaySetStatus?toStatus=${status}`,
				playSets,
				observer
			);
		});
	};

	changePlays = (opts) => {
		const {plays = [], newPlays = [], properties = []} = opts;
		newPlays.forEach((newPlay) => {
			const index = plays.findIndex((play) => play.name === newPlay.name);
			if (index >= 0) {
				properties.forEach((property) => {
					plays[index][property] = newPlay[property];
				});
			}
		});
		return this.addExtraPlayData(plays);
	};

	changePlaySets = (opts) => {
		const {playSets = [], newPlaySets = [], properties = []} = opts;
		newPlaySets.forEach((newPlaySet) => {
			const index = playSets.findIndex(
				(playSet) => playSet.name === newPlaySet.name
			);
			if (index >= 0) {
				properties.forEach((property) => {
					playSets[index][property] = newPlaySet[property];
				});
			}
		});
		return playSets;
	};

	fetchStatusCount = (plays = []) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data);
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.post('/pls/play/countOfAutoLaunch', plays, observer);
		});
	};

	hasFiltersSet = (opts) => {
		const {state = {}, getViewConfig = {}, formatTimeStamp} = opts;
		if (getViewConfig.stores?.refine) {
			const refineKeys = getViewConfig.stores.refine;
			const activeRefineKeys = refineKeys.filter(
				(key) => state[key]?.values?.length
			);
			// filters with checkboxes (stores.refine)
			if (activeRefineKeys.length) {
				return true;
			}
		}
		// filters with ranges (stores.range)
		if (getViewConfig?.stores?.range) {
			const rangeKeys = getViewConfig.stores.range;
			const activeRangeKeys = rangeKeys.filter((key) => {
				const filter = state[key] || {};
				if (filter.input_high && filter.input_low) {
					// range sliders
					return (
						filter.input_high !== filter.max || filter.input_low !== filter.min
					);
				}
				if (filter.low || filter.high) {
					// dates
					// date filters only get high or low properties when that setting has been changed
					if (filter.low && filter.min) {
						// use formatTimeStamp becase actual timestamps can differ by minutes or seconds, etc.
						return formatTimeStamp(filter.low) !== formatTimeStamp(filter.min);
					}
					if (filter.high && filter.max) {
						return formatTimeStamp(filter.high) !== formatTimeStamp(filter.max);
					}
				}
			});
			if (activeRangeKeys.length) {
				return true;
			}
		}
		return false;
	};

	fetchPlaySets = (opts = {}) => {
		const {status} = opts;

		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data);
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.get('/pls/playset/playSets', observer, {}, {status});
		});
	};

	createPlaySet = (playSet = {}) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					setTimeout(() => {
						// PLS-23137 hack pending further discussion
						resolve(response.data);
					}, 1000);
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.post('/pls/playset/createOrUpdate', playSet, observer);
		});
	};

	duplicatePlay = (play, fromPlay) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					setTimeout(() => {
						// PLS-23137 hack pending further discussion
						resolve(response.data);
					}, 1000);
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.post(
				`/pls/play/duplicatePlay?fromPlay=${fromPlay}`,
				play,
				observer
			);
		});
	};

	duplicatePlaySet = (playSet, fromPlaySet) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					setTimeout(() => {
						// PLS-23137 hack pending further discussion
						resolve(response.data);
					}, 1000);
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.post(
				`/pls/playset/duplicatePlaySet?fromPlaySet=${fromPlaySet}`,
				playSet,
				observer
			);
		});
	};

	changePlaySet = (playSet, plays = []) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data);
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.put(
				`/pls/play/assignPlaysToSet?toPlaySet=${playSet || ''}`,
				plays,
				observer
			);
		});
	};

	updateLaunchSchedule = (playSet) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data);
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.put(`/pls/playset/updateLaunchSchedule`, playSet, observer);
		});
	};

	fetchLaunchCount = (launchStates, statuses) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data);
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			let url = '/pls/play/launches/dashboard/count?startTimestamp=0';
			if (launchStates) {
				url += `&launchStates=${launchStates}`;
			}
			if (statuses) {
				url += `&status=${statuses}`;
			}
			httpService.get(url, observer, {});
		});
	};

	fetchAccountFilters = (opts = {}) => {
		const {id, startTimestamp, endTimestamp} = opts || {};
		const url = '/pls/play/accounts/accountView/campaign';
		const body = {
			category: 'AccountPerformance',
			startTimestamp,
			endTimestamp,
			isoStartTimestamp: formatDateStrToUTC(startTimestamp),
			isoEndTimestamp: formatDateStrToUTC(endTimestamp, true),
			accountIds: id ? [id] : [],
		};
		return axiosInstance
			.post(url, body)
			.then((response) => response.data.accounts || []);
	};

	fetchAccountDetail = (opts = {}) => {
		const {id, playIds, startTimestamp, endTimestamp, breakDownType} =
			opts || {};
		const url = '/pls/play/accounts/accountView/detail';
		const body = {
			category: 'AccountPerformance',
			startTimestamp,
			endTimestamp,
			isoStartTimestamp: formatDateStrToUTC(startTimestamp),
			isoEndTimestamp: formatDateStrToUTC(endTimestamp, true),
			accountIds: [id],
			playIds,
			breakDownType,
		};
		return axiosInstance.post(url, body).then((response) =>
			response.data.accounts?.map((item, index) => {
				const CampaignsName = item.campaigns
					?.map(({metrics}) => metrics.CampaignName)
					.join('');
				return {
					...handleSumRecords(
						calSumMetrics,
						item.campaigns.map((campaign) => campaign.metrics)
					),
					date: item.date,
					id: item.date + item.CampaignsName + index,
					CampaignsName,
					campaigns: item.campaigns?.map(({metrics}) => ({
						...metrics,
					})),
				};
			})
		);
	};

	fetchPlacementDetail = (opts = {}) => {
		const {id, playIds, startTimestamp, endTimestamp, breakDownType} =
			opts || {};
		const url = '/pls/play/accounts/placementView/detail';
		const body = {
			category: 'Placement',
			startTimestamp,
			endTimestamp,
			isoStartTimestamp: formatDateStrToUTC(startTimestamp),
			isoEndTimestamp: formatDateStrToUTC(endTimestamp, true),
			domains: [id],
			playIds,
			breakDownType,
		};
		return axiosInstance.post(url, body).then((response) =>
			response.data.domains?.map((item, index) => ({
				date: item.date,
				id: item.date + item.metrics.Currency + index,
				...item.metrics,
			}))
		);
	};

	exportReportDetail = (opts = {}) => {
		const {url, format, fileName, startTimestamp, endTimestamp, ...restParams} =
			opts || {};
		const params = {
			fileName,
			format,
			startTimestamp,
			endTimestamp,
			isoStartTimestamp: formatDateStrToUTC(startTimestamp),
			isoEndTimestamp: formatDateStrToUTC(endTimestamp, true),
			...restParams,
		};

		return axiosInstance
			.post(url, params, {responseType: 'blob'})
			.then((response) => {
				this.downloadFile(response, fileName, format);
				return response.data;
			});
	};

	/**
	 * BEGIN: block of functions from angular launchhistory.component.js
	 */

	makeSystemMap = (uniqueLookupIdMapping = {}) => {
		const systemMap = {};
		for (const i in uniqueLookupIdMapping) {
			for (const j in uniqueLookupIdMapping[i]) {
				systemMap[uniqueLookupIdMapping[i][j].orgId] =
					uniqueLookupIdMapping[i][j];
			}
		}
		return systemMap;
	};

	isAccountBasedLaunch = (launchSummary) => {
		const channelConfig =
			launchSummary && launchSummary.channelConfig
				? launchSummary.channelConfig[
						Object.keys(launchSummary.channelConfig)[0]
				  ]
				: {};
		const audienceType = channelConfig.audienceType
			? channelConfig.audienceType.toLowerCase()
			: 'accounts';

		if (audienceType === 'accounts') {
			return true;
		}
		return false;
	};

	audienceType = (launch) => {
		if (launch && launch.channelConfig) {
			const {audienceType} =
				launch.channelConfig[Object.keys(launch.channelConfig)[0]];
			return audienceType;
		}
	};

	getSourceFileUrl = (tenantId) => {
		return `/files/datafiles/sourcefile?TenantId=${tenantId}`;
	};

	getSourceFilePath = (launchSummary, encoded, keys) => {
		const {integrationStatusMonitor} = launchSummary;
		const key = this.getSourceFileKey(launchSummary, keys);

		const filePath =
			integrationStatusMonitor && integrationStatusMonitor[key]
				? integrationStatusMonitor[key]
				: '';
		if (filePath) {
			return encoded
				? encodeURIComponent(filePath.substring(filePath.indexOf('dropfolder')))
				: filePath.substring(filePath.indexOf('dropfolder')); // to ensure backward compatibility
		}
		return '';
	};

	getSourceFileName = (launchSummary, keys) => {
		const {integrationStatusMonitor} = launchSummary;
		const key = this.getSourceFileKey(launchSummary, keys);
		const filePath = integrationStatusMonitor[key]
			? integrationStatusMonitor[key]
			: '';
		if (filePath) {
			return filePath.split('\\').pop().split('/').pop();
		}
	};

	getSourceFileKey = (launchSummary, keys, keydefault) => {
		const {integrationStatusMonitor} = launchSummary;
		var keys = keys || [];
		let key = keydefault || 'sourceFile';

		keys.every((k) => {
			if (integrationStatusMonitor && integrationStatusMonitor[k]) {
				key = k;
				return false;
			}
			return true;
		});
		return key;
	};

	getErrorFileName = (launchSummary) => {
		const {integrationStatusMonitor} = launchSummary;
		const filePath = integrationStatusMonitor.errorFile
			? integrationStatusMonitor.errorFile
			: '';
		if (filePath) {
			return filePath.split('\\').pop().split('/').pop();
		}
	};

	getErrorFilePath = (launchSummary, encoded) => {
		const {integrationStatusMonitor} = launchSummary;
		const filePath = integrationStatusMonitor.errorFile
			? integrationStatusMonitor.errorFile
			: '';
		if (filePath) {
			return encoded
				? encodeURIComponent(filePath.substring(filePath.indexOf('dropfolder')))
				: filePath.substring(filePath.indexOf('dropfolder')); // to ensure backward compatibility
		}
	};

	showDestinationFolder = (launchSummary, stateMap) => {
		const destinationSysName = systemMap[launchSummary.destinationOrgId]
			? systemMap[launchSummary.destinationOrgId].externalSystemName
			: '';
		return [AWS_S3, ELOQUA, SALESFORCE].includes(destinationSysName);
	};

	destination = (launch) => {
		let destination = '';
		if (launch.folderName) {
			destination += launch.folderName;
			if (launch.audienceName) {
				destination += ' > ';
			}
		}
		if (launch.audienceName) {
			destination += launch.audienceName;
		}
		return destination;
	};

	downloadFile = (response, fileName, fileType = FileType.CSV) => {
		const typeMap = {
			csv: 'text/csv;charset=utf-8;',
			excel:
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;',
		};
		const element = document.createElement('a');
		element.download = fileName;
		const file = new Blob([response.data], {
			type: typeMap[fileType.toLocaleLowerCase()],
		});
		const fileURL = window.URL.createObjectURL(file);
		element.href = fileURL;
		element.click();
	};

	deleteLink = (launch, tenantId) => {
		const filename = this.getSourceFileName(launch, ['deleteFile']);
		httpService.post(
			this.getSourceFileUrl(tenantId),
			{
				fileName: filename,
				filePath: this.getSourceFilePath(launch, false, ['deleteFile']),
				bucketName: launch.integrationStatusMonitor.s3Bucket,
			},
			new Observer((response) => {
				this.downloadFile(response, filename);
			}),
			null,
			{
				responseType: 'blob',
			}
		);
	};

	newLink = (launch, tenantId) => {
		const filename = this.getSourceFileName(launch);
		httpService.post(
			this.getSourceFileUrl(tenantId),
			{
				fileName: filename,
				filePath: this.getSourceFilePath(launch, false),
				bucketName: launch.integrationStatusMonitor.s3Bucket,
			},
			new Observer((response) => {
				this.downloadFile(response, filename);
			}),
			null,
			{
				responseType: 'blob',
			}
		);
	};

	errorLink = (launch, tenantId) => {
		const filename = this.getErrorFileName(launch);
		httpService.post(
			this.getSourceFileUrl(tenantId),
			{
				fileName: filename,
				filePath: this.getSourceFilePath(launch, false, ['errorFile']),
				bucketName: launch.integrationStatusMonitor.s3Bucket,
			},
			new Observer((response) => {
				this.downloadFile(response, filename);
			}),
			null,
			{
				responseType: 'blob',
			}
		);
	};

	/**
	 * END: launchhistory.component.js functions
	 */
}

const instance = new CampaignService();
Object.freeze(instance);

export default instance;
