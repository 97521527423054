import {MAX_LENGTH_DIC} from './consts';
import {IAttribute, IAttributeData, IAttributeUpdateData} from './schemaTypes';

/**
 * check if any new added/edited attribute(s) are duplicated by `screenName`
 *
 * @param dataSource all attribute data of
 * @param updatedData attribute(s) added/edited
 *
 * @returns {duplicatedAttributes: duplicated array, uniqAttributes: uniqArray}
 */
export function checkForDuplicatedAttributes(
	dataSource: Array<IAttributeData>,
	updatedData: Array<IAttributeUpdateData>
): {
	duplicatedAttributes: Array<IAttributeData>;
	uniqAttributes: Array<IAttributeUpdateData>;
} {
	const duplicatedAttributes: Array<IAttributeData> = [];
	const uniqAttributes: Array<IAttributeUpdateData> = [];
	const screenNameMap = new Map();
	dataSource.forEach((attribute) => {
		screenNameMap.set(attribute.screenName, attribute);
	});
	updatedData.forEach((attribute) => {
		const {screenName, property, propertyIndex} = attribute;
		const ifExistAttribute = screenNameMap.get(screenName);

		// Two situations here:
		// 1. When adding attributes, `property` and`propertyIndex` are undefined;
		// 2. When editing an attribute and the `screenName` not changed, we matched the original one.
		if (
			ifExistAttribute?.property === property &&
			ifExistAttribute?.propertyIndex === propertyIndex
		) {
			if (!ifExistAttribute) {
				// mark `propertyIndex` as -1 to check for duplicated attribute in `updatedData`
				screenNameMap.set(screenName, {...attribute, propertyIndex: -1});
				uniqAttributes.push(attribute);
			}
		} else if (ifExistAttribute) {
			duplicatedAttributes.push(ifExistAttribute);
		}
	});

	return {duplicatedAttributes, uniqAttributes};
}

export function renderRequiredText(nullable?: boolean | null): string {
	return nullable ? 'No' : 'Yes';
}

export function autoLimitCDPMaxLength(attributes: IAttribute[]): IAttribute[] {
	return attributes.map((attribute) => {
		let {cdpMaxLength} = attribute;
		// @ts-ignore
		if (cdpMaxLength > MAX_LENGTH_DIC[attribute.cdpDataType]) {
			cdpMaxLength = MAX_LENGTH_DIC[attribute.cdpDataType!];
		}
		return {...attribute, cdpMaxLength};
	});
}
