import {Modes} from './enums';

const defaultMode = Modes.Segmentation;

const stateModeMap: Record<string, Modes> = {
	'home.model.analysis.explorer.attributes': Modes.Segmentation,
	'home.segment.explorer.attributes': Modes.Segmentation,
	'home.ratingsengine.dashboard.segment.attributes.rules':
		Modes.RulesBasedModelDashboard,
	'home.ratingsengine.rulesprospects.segment.attributes.rules':
		Modes.RulesBasedModelDetails,
};

export {defaultMode, stateModeMap};
