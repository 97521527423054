import React, {Component} from 'common/react-vendor';
import {redux} from 'store';
import './le-search.scss';
import SearchFilterComponent from './SearchFilterComponent';

export default class LeSearch extends Component {
	constructor(props) {
		super(props);

		const state = {
			placeholder: 'Search',
			query: '',
			open: false,
			static: false,
			recurse_arrays: false, // search entire object, even down arrays - can be slow
			case: false, // case-sensitive search
		};

		this.redux = redux({
			path: props.namespace || '@filters.search',
			state: Object.assign(state, props.config),
			component: this,
			appCache: props.noAppCache ? null : ['query', 'open'], // save state & changes to local storage
		});
	}

	changeQuery = (value) => {
		const state = this.redux.get();
		state.query = value;
		this.redux.set(state);
	};

	render() {
		const state = this.redux.get();
		return (
			<SearchFilterComponent
				onChange={this.changeQuery}
				placeholder={state.placeholder}
				search={state.query}
				autoFocus={true}
			/>
		);
	}
}
