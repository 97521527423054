import React from 'common/react-vendor';
import {axios} from 'common/network.vendor';
import {Spinner} from 'common/widgets/spinner';
import classNames from 'classnames';
import styles from './marketplace-segments.module.scss';
import {
	MarketplaceSegment,
	MPSSortByAttribute,
} from './marketplace-segment-types';
import {MarketplaceSegmentFilters} from './filters/MarketplaceSegmentFilters';
import {MPSSortIndicator} from './MPSSortIndicator';

export const MarketplaceSegments: React.FC<
	React.PropsWithChildren<unknown>
> = () => {
	const [searchString, setSearchString] = React.useState('');

	const [mpsData, setMpsData] = React.useState<MarketplaceSegment[]>([]);
	const [showFilters, setShowFilters] = React.useState(false);
	const [showSortDropdown, setShowSortDropdown] = React.useState(false);

	type ItemsPerPageChoice = 10 | 25 | 50 | 100;
	const [itemsPerPage, setItemsPerPage] =
		React.useState<ItemsPerPageChoice>(25);
	const [currentPage, setCurrentPage] = React.useState(1);

	const [sortCriteria, setSortCriteria] =
		React.useState<MPSSortByAttribute>(null);
	const [sortAsc, setSortAsc] = React.useState(false);
	const [selectedFilters, setSelectedFilters] = React.useState<Set<string>>(
		new Set()
	);

	const sortCriteriaMap = {
		segment_name: 'Segment Name',
		reach: 'Reach',
		segment_desc: 'Description',
		digital_ads_cpm: 'Digital Ad Targeting Price',
		tv_ads_cpm: 'TV Targeting Price',
	};

	React.useEffect(() => {
		axios
			.get('/pls/marketplace/segments')
			.then((res) => {
				setMpsData(res.data);
			})
			.catch((e) => {
				console.error(e);
			});
	}, []);

	React.useEffect(() => {
		setCurrentPage(1);
	}, [selectedFilters, itemsPerPage, searchString]);

	const filterList = React.useCallback(
		(list: MarketplaceSegment[]): MarketplaceSegment[] => {
			return list.filter((mps) =>
				Array.from(selectedFilters)
					.map((f) => {
						return mps.segment_name.indexOf(f) >= 0;
					})
					.some((a) => a)
			);
		},
		[selectedFilters]
	);

	const searchList = React.useCallback(
		(list: MarketplaceSegment[]): MarketplaceSegment[] => {
			const s = searchString.toLowerCase();
			return list.filter(
				(mps) =>
					mps.segment_name.toLowerCase().indexOf(s) >= 0 ||
					mps.segment_desc.toLowerCase().indexOf(s) >= 0
			);
		},
		[searchString]
	);

	const onHeaderClick = (attribute: MPSSortByAttribute): void => {
		setSortAsc(sortCriteria === attribute ? !sortAsc : true);
		setSortCriteria(attribute);
	};

	const filteredMPS = React.useMemo(() => {
		let returnData = mpsData;
		if (selectedFilters.size > 0) {
			returnData = filterList(returnData);
		}
		if (searchString !== '') {
			returnData = searchList(returnData);
		}
		return returnData;
	}, [mpsData, searchString, selectedFilters, filterList, searchList]);

	const maxPages = React.useMemo(() => {
		return Math.ceil(filteredMPS.length / itemsPerPage);
	}, [filteredMPS, itemsPerPage]);

	return (
		<>
			{mpsData.length === 0 ? (
				<div className={styles.loading}>
					<Spinner />
				</div>
			) : (
				<div className={styles.marketplaceSegments}>
					{mpsData.length > 0 ? (
						<>
							<div
								className={classNames({
									[styles.mpsFilters!]: true,
									[styles.show!]: showFilters,
								})}>
								<div className={styles.mpsFiltersBox}>
									<MarketplaceSegmentFilters
										onFiltersChange={(newFilters) => {
											setSelectedFilters(newFilters);
										}}
										selectedFilters={selectedFilters}
									/>
								</div>
							</div>
							<div className={styles.mpsMain}>
								<div className={styles.mpsSearchContainer}>
									<button
										type='button'
										className={classNames({
											[styles.mpsToggleFilters!]: true,
											[styles.active!]: showFilters,
										})}
										onClick={() => setShowFilters(!showFilters)}>
										<img src='/atlas/assets/icons/filter.svg' alt='filter' />
									</button>
									<div className={styles.mpsSortSelect}>
										<div
											className={styles.mpsSortDropdown}
											onClick={() => setShowSortDropdown(!showSortDropdown)}>
											{sortCriteria ? sortCriteriaMap[sortCriteria] : 'Sort By'}
											<div
												className={classNames({
													[styles.mpsSortDropdownOptions!]: true,
													[styles.hide!]: !showSortDropdown,
												})}>
												<div onClick={() => setSortCriteria('segment_name')}>
													Segment Name
												</div>
												<div onClick={() => setSortCriteria('reach')}>
													Reach
												</div>
												<div onClick={() => setSortCriteria('segment_desc')}>
													Description
												</div>
												<div onClick={() => setSortCriteria('digital_ads_cpm')}>
													Digital Ad Targeting Price
												</div>
												<div onClick={() => setSortCriteria('tv_ads_cpm')}>
													TV Targeting Price
												</div>
											</div>
										</div>
										<button
											type='button'
											className={classNames({
												[styles.mpsToggleSort!]: true,
												[styles.asc!]: sortAsc,
											})}
											onClick={() => setSortAsc(!sortAsc)}>
											<img
												src={`/atlas/assets/icons/sort-${
													sortAsc ? 'asc' : 'desc'
												}.svg`}
												alt='sort'
											/>
										</button>
									</div>
									<div className={styles.mpsSearch}>
										<img
											src='assets/svgs/icons/search-black.svg'
											alt='Search'
										/>
										<input
											type='text'
											value={searchString}
											onChange={(e) => {
												setSearchString(e.target.value);
											}}
										/>
									</div>
								</div>
								<div className={styles.mpsStats}>
									{filteredMPS.length} Total
								</div>
								<div className={styles.tableContainer}>
									<table className={styles.mpsList}>
										{filteredMPS.length > 0 ? (
											<thead>
												<tr className={styles.mpsSegment}>
													<th onClick={() => onHeaderClick('segment_name')}>
														<div className={styles.mpsHeader}>
															Segment Name
															<MPSSortIndicator
																criteria={sortCriteria}
																header='segment_name'
																sortAsc={sortAsc}
															/>
														</div>
													</th>
													<th onClick={() => onHeaderClick('reach')}>
														<div className={styles.mpsHeader}>
															Reach
															<MPSSortIndicator
																criteria={sortCriteria}
																header='reach'
																sortAsc={sortAsc}
															/>
														</div>
													</th>
													<th onClick={() => onHeaderClick('segment_desc')}>
														<div className={styles.mpsHeader}>
															Description
															<MPSSortIndicator
																criteria={sortCriteria}
																header='segment_desc'
																sortAsc={sortAsc}
															/>
														</div>
													</th>
													<th
														className={styles.smallCol}
														onClick={() => onHeaderClick('digital_ads_cpm')}>
														<div className={styles.mpsHeader}>
															Digital Ad Targeting Price
															<MPSSortIndicator
																criteria={sortCriteria}
																header='digital_ads_cpm'
																sortAsc={sortAsc}
															/>
														</div>
													</th>
													<th
														className={styles.smallCol}
														onClick={() => onHeaderClick('tv_ads_cpm')}>
														<div className={styles.mpsHeader}>
															TV Targeting Price
															<MPSSortIndicator
																criteria={sortCriteria}
																header='tv_ads_cpm'
																sortAsc={sortAsc}
															/>
														</div>
													</th>
													<th />
												</tr>
											</thead>
										) : (
											<tbody>
												<tr>
													<td className={styles.noItems}>
														No items match your search/filter criteria.
													</td>
												</tr>
											</tbody>
										)}
										<tbody>
											{filteredMPS
												.sort((mps1, mps2) => {
													if (
														!sortCriteria ||
														mps1[sortCriteria] === mps2[sortCriteria]
													) {
														return 0;
													}
													const mpsA = sortAsc ? mps1 : mps2;
													const mpsB = sortAsc ? mps2 : mps1;
													return mpsA[sortCriteria] > mpsB[sortCriteria]
														? 1
														: -1;
												})
												.filter((_mps, i: number) => {
													const bottomIndex = (currentPage - 1) * itemsPerPage;
													const topIndex = currentPage * itemsPerPage;
													return i >= bottomIndex && i < topIndex;
												})
												.map((mps: MarketplaceSegment) => (
													<tr
														className={styles.mpsSegment}
														key={mps.segment_id}>
														<td>{mps.segment_name}</td>
														{/* <td>{mps.country}</td>
												<td>{mps.supplier}</td> */}
														<td>{mps.reach.toLocaleString()}</td>
														<td>{mps.segment_desc}</td>
														<td>${mps.digital_ads_cpm}</td>
														<td>${mps.tv_ads_cpm}</td>
														<td>
															<button
																className={styles.runAdButton}
																type='button'
																onClick={() => {
																	console.log(
																		`Running ad for ${mps.segment_id}`
																	);
																}}>
																Run an Ad
															</button>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
								<div className={styles.pagination}>
									<div>
										<select
											value={itemsPerPage}
											onChange={(e) =>
												setItemsPerPage(
													parseInt(e.target.value, 10) as ItemsPerPageChoice
												)
											}>
											<option value={10}>10</option>
											<option value={25}>25</option>
											<option value={50}>50</option>
											<option value={100}>100</option>
										</select>{' '}
										Items of {filteredMPS.length}
									</div>
									<div>
										Page{' '}
										<input
											type='number'
											value={currentPage}
											step={1}
											min={1}
											max={maxPages}
											onChange={(e) =>
												setCurrentPage(parseInt(e.target.value, 10))
											}
										/>{' '}
										of {maxPages}
									</div>
									<div>
										<button
											type='button'
											onClick={() => setCurrentPage(currentPage - 1)}
											disabled={currentPage <= 1}>
											<img
												src='/atlas/assets/icons/chevron-left.svg'
												alt='previous'
											/>
										</button>
										<button
											type='button'
											onClick={() => setCurrentPage(currentPage + 1)}
											disabled={currentPage >= maxPages}>
											<img
												src='/atlas/assets/icons/chevron-right.svg'
												alt='next'
											/>
										</button>
									</div>
								</div>
							</div>
						</>
					) : (
						<></>
					)}
				</div>
			)}
		</>
	);
};
