import {ObjectType} from '../../ConnectionType';
import {DocumentType} from '../../DocumentType';

const getCreateSourceUrl = (
	channelName: string,
	documentType: DocumentType,
	displayName: string
): string => {
	return `/pls/inboundsource/${channelName}/${documentType}?displayName=${displayName}`;
};

type IFieldDefinitionsRecordsMap = ObjectType;

interface ICreateSourceRequestBody {
	systemType: string;
	systemObject: string;
	fieldDefinitionsRecordsMap: IFieldDefinitionsRecordsMap;
	defaultSchema: boolean;
	schemaType: DocumentType;
}

export {getCreateSourceUrl};
export type {ICreateSourceRequestBody};
