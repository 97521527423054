import {DocumentType} from '../Data/DocumentType';

export enum ECdpDataType {
	Text = 'Text',
	Id = 'Id',
	Date = 'Date',
	Boolean = 'Boolean',
	Phone = 'Phone',
	Choice = 'Choice',
	Email = 'Email',
	Currency = 'Currency',
	Number = 'Number',
	Integer = 'Integer',
	Percent = 'Percent',
}

export interface IAttribute {
	fieldName?: string;
	fieldType?: string;
	cdpMinLength?: number;
	cdpMaxLength?: number;
	required?: boolean;
	description?: string;
	displayName?: string;

	approvedUsage?: string[];
	category?: string;
	cdpDataType?: ECdpDataType;
	columnName?: string;
	dateFormat?: string;
	defaultValue?: string | null;
	externalDisplayName?: string;
	externalSystemName?: string;
	externalSystemType?: string | null;
	fundamentalType?: string;
	idType?: string;
	ignored?: boolean;
	inCurrentImport?: boolean;
	logicalDataType?: string;
	mappedToLatticeId?: boolean;
	matchingColumnNames?: string[];
	mergingColumnNames?: string[];
	nullable?: boolean;
	screenName?: string;
	statisticalType?: string;
	subcategory?: string;
	timeFormat?: string;
	timeZone?: string;
	enrichAble?: boolean;
}

export interface IAttributeData extends IAttribute {
	property: string;
	propertyIndex: number;
	dataIndex: number;
}

export interface IAttributeUpdateData extends IAttribute {
	property?: string;
	propertyIndex?: number;
	dataIndex?: number;
}

export enum AttributeFormScene {
	Add = 'add',
	Edit = 'edit',
}

export interface IAtttributeState {
	submitting: boolean;
	uploadedData: {
		[filename: string]: Array<IAttributeData>;
	};
	scene: AttributeFormScene;
}

export type TSchemaType =
	| 'Account'
	| 'Contact'
	| 'MarketingActivity'
	| 'WebVisit'
	| 'Opportunity'
	| 'SalesActivityEvent'
	| 'CustomIntent'
	| 'Campaign'
	| 'G2Intent'
	| 'TrustRadiusIntent';

export type GenericFieldDefinitionsRecordsMap<T> = {
	'Account Matching Fields': Array<T>;
	'Analysis Fields': Array<T>;
	'Match IDs': Array<T>;
	'Other IDs': Array<T>;
	'Unique ID': Array<T>;
	'Custom Fields': Array<T>;
} & {
	[key: string]: Array<T>;
};
export type ISchemaFieldDefinitionsRecordsMap =
	GenericFieldDefinitionsRecordsMap<IAttribute>;

export interface ISchemaResponseData {
	defaultSchema: boolean;
	fieldDefinitionsRecordsMap: ISchemaFieldDefinitionsRecordsMap;
	schemaType: TSchemaType;
	systemName: string;
	systemObject: string;
	systemType: string;
}

export interface ISchemaRequestData extends ISchemaResponseData {}

export type TFilterKey =
	| 'fieldName'
	| 'fieldType'
	| 'cdpDataType'
	| 'cdpMinLength'
	| 'cdpMaxLength'
	| 'nullable'
	| 'required'
	| 'description';

export interface IState {
	loading: boolean;
	deleting: boolean;
	// original data from api
	schemaData: ISchemaResponseData | null;
	// flated data for ui-view
	dataSource: Array<IAttributeData>;
	drawerOpened: boolean;
}

export interface IStoreState {
	accountSchema: IState;
	contactSchema: IState;
	leadSchema: IState;
	cdpSchema: IState;
	schemaAttribute: IAtttributeState;
}

export interface IFilterListConfig {
	items: Array<{
		label: string;
		action: {[filterKey: string]: string};
		total: number;
	}>;
	label: string;
	values: Array<unknown>;
	toggles: boolean;
}

export type TRangeEdge = null | number;

export interface IRangeSliderConfig {
	label: string;
	property: string;
	min: TRangeEdge;
	max: TRangeEdge;
}

export interface IFilterConfig {
	cdpDataType?: IFilterListConfig;
	nullable?: IFilterListConfig;
	cdpMinLength?: IRangeSliderConfig;
	cdpMaxLength?: IRangeSliderConfig;
}

export interface IDateTimeFormatAPIResponse {
	Result: {
		date_format: Array<string>;
		time_format: Array<string>;
		timezones: Array<string>;
	};
	Success: boolean;
}

export interface ISchemaPageProps {
	$state: {
		go: (url: string, urlParams: {[key: string]: string}) => void;
		params: {type?: DocumentType};
		current: {name: string};
	};
}
