import {store} from 'store';
import {AbbreviateLargeNumber} from 'common/app/utilities/NumberUtility';
import {
	getDataCloudProperty,
	getCube,
	validFreeTextTypes,
	setDataCloudPropertyValue,
} from 'common/stores/datacloud';
import template from './attributetile.component.html';
import '../tile.scss';
import {
	getOperationLabel,
	getOperationValue,
} from '../../query/advanced/tree/tree.helpers';
import {BucketType} from '../../query/query.enums';
import NoticeService from '../../../notice/NoticeService';
import {dispatchRatingsEnginePropertyValue} from '../../../../../atlas/app/stores/ratingsEngine';

export default function () {
	'ngInject';

	return {
		restrict: 'A',
		scope: {
			vm: '=',
			count: '=',
			enrichment: '=',
			cube: '=',
			metadata: '<',
		},
		controllerAs: 'vm',
		template,
		controller($scope, $state) {
			'ngInject';

			const {vm} = $scope;

			angular.extend(vm, {
				enrichment: $scope.enrichment,
				cube: $scope.cube,
				bktlist: [],
			});

			vm.booleanStats = function (stats) {
				const booleans = {};

				for (const i in stats) {
					const stat = stats[i];
					booleans[stat.Lbl] = stat;
				}

				return booleans;
			};

			vm.getStatSort = function () {
				return vm.section == 're.model_iteration' ? '-Lift' : '-Cnt';
			};

			vm.goToEnumPicker = function (bucket, enrichment) {
				store.dispatch(
					setDataCloudPropertyValue('pickerObject', {
						item: enrichment,
					})
				);

				const entity = enrichment.Entity;
				const fieldname = enrichment.ColumnId;
				let state = '';

				switch ($state.current.name) {
					case 'home.ratingsengine.rulesprospects.segment.attributes.rules':
						state =
							'home.ratingsengine.rulesprospects.segment.attributes.rules.picker';
						break;
					case 'home.ratingsengine.dashboard.segment.attributes.add':
						state =
							'home.ratingsengine.dashboard.segment.attributes.rules.picker';
						break;
					case 'home.ratingsengine.createAccountFitModeling.createSegment.attributes':
						state =
							'home.ratingsengine.createAccountFitModeling.createSegment.picker';
						break;
					case 'home.ratingsengine.createAccountFitModeling.successCriteria.attributes':
						state =
							'home.ratingsengine.createAccountFitModeling.successCriteria.picker';
						break;
					case 'home.ratingsengine.createAccountFitModeling.scoringSegment.attributes':
						state =
							'home.ratingsengine.createAccountFitModeling.scoringSegment.picker';
						break;
					default:
						state = 'home.segment.explorer.enumpicker';
				}

				$state.go(state, {
					entity,
					fieldname,
				});
			};

			function getBarChartConfig() {
				if ($scope.barChartConfig === undefined) {
					$scope.barChartConfig = {
						data: {
							tosort: false,
							sortBy: 'Cnt',
							trim: true,
							top: 5,
						},
						chart: {
							header: 'Attributes Value',
							emptymsg: '',
							usecolor: false,
							color: '#2E6099',
							mousehover: true,
							type: 'integer',
							showstatcount: true,
							maxVLines: 3,
							showVLines: false,
						},
						vlines: {
							suffix: '',
						},
						columns: [
							{
								field: 'Cnt',
								label: 'Records',
								type: 'number',
								chart: true,
							},
						],
					};
				}
				return $scope.barChartConfig;
			}

			function getBarChartLiftConfig() {
				if ($scope.barChartLiftConfig === undefined) {
					$scope.barChartLiftConfig = {
						data: {
							tosort: false,
							sortBy: 'Cnt',
							trim: true,
							top: 5,
						},
						chart: {
							header: 'Attributes Value',
							emptymsg: '',
							usecolor: false,
							color: '#2E6099',
							mousehover: true,
							type: 'decimal',
							showstatcount: true,
							maxVLines: 3,
							showVLines: true,
						},
						vlines: {
							suffix: 'x',
						},
						columns: [
							{
								field: 'Lift',
								label: 'Lifts',
								type: 'string',
								suffix: 'x',
								chart: true,
							},
							{
								field: 'Cnt',
								label: 'Records',
								type: 'number',
								chart: false,
							},
						],
					};
				}
				return $scope.barChartLiftConfig;
			}

			vm.getChartConfig = function (list) {
				vm.bktlist = [];
				if (vm.cube) {
					vm.bktlist = vm.getData(vm.enrichment.Entity, vm.enrichment.ColumnId);
				} else {
					getCube().then(function (result) {
						vm.cube = result;

						vm.bktlist = vm.getData(
							vm.enrichment.Entity,
							vm.enrichment.ColumnId
						);
					});
				}

				if (list != null && list.length > 0 && list[0].Lift != undefined) {
					return getBarChartLiftConfig();
				}
				return getBarChartConfig();
			};

			vm.getData = function (entity, columnId) {
				const data = vm.cube[entity].Stats[columnId].Bkts.List;
				return data;
			};

			vm.chartRowClicked = function (stat, enrichment) {
				vm.selectSegmentAttributeRange(
					enrichment,
					stat,
					vm.section != 'segment.analysis'
				);
			};
			vm.AbbreviateLargeNumber = AbbreviateLargeNumber;

			vm.getOperationLabel = function (type, bucketRestriction) {
				return getOperationLabel({type, bucketRestriction});
			};

			vm.getOperationValue = function (bucketRestriction, type) {
				return getOperationValue({bucketRestriction, type});
			};

			vm.getQuerySnippet = function (enrichment, rule, type) {
				let querySnippet = `${enrichment.DisplayName} `;
				if (
					vm.cube &&
					vm.cube[enrichment.Entity].Stats[enrichment.ColumnId] &&
					vm.cube[enrichment.Entity].Stats[enrichment.ColumnId].Bkts
				) {
					// bucketable attributes
					querySnippet += 'is ';
					querySnippet +=
						type == 'Enum' &&
						rule.bucketRestriction.bkt.Vals &&
						rule.bucketRestriction.bkt.Vals.length > 1
							? vm.generateBucketLabel(rule.bucketRestriction.bkt)
							: rule.bucketRestriction.bkt.Lbl;
				} else {
					// non-bucketable attributes e.g. pure-string
					querySnippet += `${getOperationLabel({
						type: BucketType.String,
						bucketRestriction: rule.bucketRestriction,
					})} ${vm.getOperationValue(rule.bucketRestriction, 'String')}`;
				}
				return querySnippet;
			};

			vm.showFreeTextAttributeCard = function (enrichment) {
				return (
					vm.cube &&
					vm.isBktEmpty(enrichment) &&
					validFreeTextTypes.indexOf(enrichment.FundamentalType) >= 0 &&
					!vm.lookupMode &&
					['wizard.ratingsengine_segment', 'edit', 'team'].indexOf(
						vm.section
					) == -1
				);
			};

			vm.showInvalidAttributeCard = function (enrichment) {
				return (
					vm.cube &&
					vm.isBktEmpty(enrichment) &&
					validFreeTextTypes.indexOf(enrichment.FundamentalType) == -1 &&
					!vm.lookupMode &&
					['wizard.ratingsengine_segment', 'edit', 'team'].indexOf(
						vm.section
					) == -1
				);
			};

			vm.isBktEmpty = function (enrichment) {
				if (
					vm.cube &&
					vm.cube[enrichment.Entity] &&
					vm.cube[enrichment.Entity].Stats &&
					vm.cube[enrichment.Entity].Stats[enrichment.ColumnId]
				) {
					return (
						vm.cube[enrichment.Entity].Stats[enrichment.ColumnId].Bkts ==
							undefined ||
						vm.cube[enrichment.Entity].Stats[enrichment.ColumnId].Bkts.List ==
							undefined ||
						!vm.cube[enrichment.Entity].Stats[enrichment.ColumnId].Bkts.List
							.length
					);
				}
			};

			vm.AbbreviateLargeNumber = AbbreviateLargeNumber;

			vm.getBktListRating = function (enrichment) {
				return vm.cube[enrichment.Entity].Stats[enrichment.ColumnId].Bkts.List;
			};

			vm.getWarning = function (warning) {
				const warnings = getDataCloudProperty('warnings');

				return warnings[warning];
			};

			vm.select = function (quantity) {
				const iterationFilter = getDataCloudProperty('ratingIterationFilter');

				const categoryAttributes = vm.getAttributes([vm.category]);

				switch (quantity) {
					case 'selectall':
						switch (iterationFilter) {
							case 'all':
								categoryAttributes.forEach(
									(attr) => (attr.ApprovedUsage[0] = 'ModelAndAllInsights')
								);
							case 'used':
								const usedCategoryAttributes = categoryAttributes.filter(
									(item) => {
										return typeof item.ImportanceOrdering != 'undefined';
									}
								);
								usedCategoryAttributes.forEach(
									(attr) => (attr.ApprovedUsage[0] = 'ModelAndAllInsights')
								);
							case 'warnings':
								const warningsCategoryAttributes = categoryAttributes.filter(
									(item) => {
										return item.HasWarnings;
									}
								);
								warningsCategoryAttributes.forEach(
									(attr) => (attr.ApprovedUsage[0] = 'ModelAndAllInsights')
								);
							case 'disabled':
								const disabledCategoryAttributes = categoryAttributes.filter(
									(item) => {
										return item.ApprovedUsage[0] == 'None';
									}
								);
								disabledCategoryAttributes.forEach(
									(attr) => (attr.ApprovedUsage[0] = 'ModelAndAllInsights')
								);
						}

						var categories = vm.removeEmptyCategories(vm.categories);
						if (categories.indexOf(vm.category) === -1) {
							vm.setCategory(categories[0]);
						}

						NoticeService.success({
							message: `Enabled all ${vm.category} attributes for remodeling`,
						});
						break;
					default:
						switch (iterationFilter) {
							case 'all':
								categoryAttributes.forEach(
									(attr) => (attr.ApprovedUsage[0] = 'None')
								);
							case 'used':
								const usedCategoryAttributes = categoryAttributes.filter(
									(item) => {
										return typeof item.ImportanceOrdering != 'undefined';
									}
								);
								usedCategoryAttributes.forEach(
									(attr) => (attr.ApprovedUsage[0] = 'None')
								);
							case 'warnings':
								const warningsCategoryAttributes = categoryAttributes.filter(
									(item) => {
										return item.HasWarnings;
									}
								);
								warningsCategoryAttributes.forEach(
									(attr) => (attr.ApprovedUsage[0] = 'None')
								);
							case 'disabled':
								const disabledCategoryAttributes = categoryAttributes.filter(
									(item) => {
										return item.ApprovedUsage[0] == 'None';
									}
								);
								disabledCategoryAttributes.forEach(
									(attr) => (attr.ApprovedUsage[0] = 'None')
								);
						}

						var categories = vm.removeEmptyCategories(vm.categories);
						if (categories.indexOf(vm.category) === -1) {
							vm.setCategory(categories[0]);
						}
						NoticeService.warning({
							message: `Disabled all ${vm.category} attributes from remodeling`,
						});
				}
				dispatchRatingsEnginePropertyValue(
					'iterationEnrichments',
					vm.enrichments
				);
			};

			vm.toggleApprovedUsage = function (item) {
				switch (item.ApprovedUsage[0]) {
					case 'None':
						NoticeService.success({
							message: 'Enabled attribute for remodeling',
						});
						item.ApprovedUsage[0] = 'ModelAndAllInsights';
						break;
					default:
						NoticeService.warning({
							message: 'Disabled attribute from remodeling',
						});
						item.ApprovedUsage[0] = 'None';
				}
				dispatchRatingsEnginePropertyValue(
					'iterationEnrichments',
					vm.enrichments
				);
			};

			vm.checkApprovedUsage = function (item) {
				return item.ApprovedUsage[0] !== 'None';
			};

			vm.attrIsAdminDisabled = function (item) {
				/**
				 * Only disable attributes for re.model_iterations. When attributes are
				 * displayed in segment.analysis, they should never appear with disabled styling.
				 */
				return (
					vm.section === 're.model_iteration' && item.IsAdminDisabledForModel
				);
			};

			vm.checkImportance = function (item) {
				return 'ImportanceOrdering' in item;
			};
		},
	};
}
