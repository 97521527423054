import {ISegment} from 'atlas/data/SegmentConst';
import {SegmentNgState} from 'atlas/helpers/NavigateHelper';
import {
	SEGMENTS_JOURNEYACCOUNTS360,
	SEGMENTS_ACCOUNTS360,
} from 'atlas/navigation/header/components/page-navigation.utils';
import StateHistory from 'atlas/StateHistory';
import {NgStateService} from 'common/react-vendor';
import {ISegmentHelper, SegmentHelper} from './SegmentHelper';
import {ITabParam} from './TabsHelper/AccountFitModelingHelper';

/**
 * Tab type binded with ui tabs.
 */
type ITabType =
	| 'attributes'
	| 'builder'
	| 'accounts'
	| 'contacts'
	| 'dashboard'
	| 'subsegments'
	| 'substage';

type ISegmentTabParam = {
	aiModel?: boolean;
	tab?: ITabParam;
};

const RouteTabTypeMap: Record<string, ITabType> = {
	'home.segment.explorer.attributes': 'attributes',
	'home.segment.explorer.enumpicker': 'attributes',
	'home.segment.explorer.builder': 'builder',
	'home.segment.accounts': 'accounts',
	'home.segment.contacts': 'contacts',
	'home.segment.accounts.contacts': 'accounts',
	// Journey Stage newly added routes.
	[SegmentNgState.Dashboard]: 'dashboard',
	[SegmentNgState.Subsegments]: 'subsegments',
	[SegmentNgState.JourneyAccounts]: 'accounts',
	[SegmentNgState.Contact]: 'accounts',
	[SegmentNgState.JourneyContacts]: 'contacts',
	[SegmentNgState.Substage]: 'substage',
	[SegmentNgState.SubstageAccounts]: 'accounts',
	[SegmentNgState.SubstageContacts]: 'contacts',
};

type IBaseSegmentHelper = {
	getState: () => NgStateService;
	isAIModel: () => boolean;
	getSegmentHelper: () => ISegmentHelper;
	getRoute: () => string;
	getTabType: () => ITabType;
	clickTab: (type: ITabType) => void;
};

class BaseSegmentHelper implements IBaseSegmentHelper {
	private $state: NgStateService;

	private aiModel: boolean;

	private segmentHelper: ISegmentHelper;

	constructor($state: NgStateService, aiModel: boolean, segment: ISegment) {
		this.$state = $state;
		this.aiModel = aiModel;
		this.segmentHelper = new SegmentHelper(segment);
	}

	getState = (): NgStateService => this.$state;

	isAIModel = (): boolean => this.aiModel;

	getSegmentHelper = (): ISegmentHelper => this.segmentHelper;

	getRoute = (): string => this.$state.current.name || '';

	getTabType = (): ITabType => {
		const lastTo = StateHistory.lastTo()?.name || '';
		const lastFrom = StateHistory.lastFrom()?.name || '';

		if ([SEGMENTS_JOURNEYACCOUNTS360, SEGMENTS_ACCOUNTS360].includes(lastTo)) {
			return RouteTabTypeMap[lastFrom] || 'builder';
		}

		return RouteTabTypeMap[lastTo] || 'builder';
	};

	clickTab = (type: ITabType): void => {
		const {
			segmentHelper: {
				segment: {name},
				counts,
				showDashboard,
			},
		} = this;
		if (counts()[type] && counts()[type]!.value < 0) {
			return;
		}
		const getRoute = (route: string): string => `home.segment.${route}`;

		const explorerRoute = (route: string): string => {
			const explorerRoutes = ['attributes', 'builder'];
			return explorerRoutes.includes(route) ? `explorer.${route}` : route;
		};

		const journeyRoute = (route: string): string => {
			// route to journey account / contact if journey stage in enabled.
			if (showDashboard()) {
				const journeyRoutes = ['accounts', 'contacts'];
				return journeyRoutes.includes(route) ? `journey${route}` : route;
			}
			return route;
		};

		const stateParamsSegment = type === 'builder' ? 'builder' : 'Create';
		this.$state.go(
			getRoute(journeyRoute(explorerRoute(type))),
			{
				segment: name ?? stateParamsSegment,
			},
			{reload: 'home.segment'}
		);
	};
}

export type {ITabType, ISegmentTabParam, IBaseSegmentHelper};
export {BaseSegmentHelper, RouteTabTypeMap};
