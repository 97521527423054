import React from 'common/react-vendor';
import cx from 'classnames';
import {isNil} from 'lodash';
import {
	DNBButton,
	SortOutlinedIcon,
	designTokens,
	ArrowUpwardOutlinedIcon,
	ArrowDownwardOutlinedIcon,
	DNBTypography,
	SwapVertOutlinedIcon,
	IconButton,
} from 'common/dnb-uux-vendor';
import {MenuDropDown, MenuButton} from './menu';
import {sort, UseTransformation} from '../pipeline';
import styles from './styles.module.scss';

const {useState} = React;

export enum SortDirection {
	Asc,
	Desc,
}

// Either we're not currently sorting (null case) or we're sorting by a specific key in some direction
export type SortState<Key = string> = [Key, SortDirection] | null;

// These helpers exist so that the usage is decoupled from the state type. If
// typescript had a simple way to create opaque types, we could hide the
// implementation of SortState and keep the usage decoupled from the data modeling
export function sortKey<Key>(state: SortState<Key>): Key | null {
	return state ? state[0] : null;
}

export function sortDir(state: SortState<unknown>): SortDirection | null {
	return state?.[1] ?? null;
}

export function isAscending(state: SortState<unknown>): boolean {
	return !!state && state[1] === SortDirection.Asc;
}

export function isDescending(state: SortState<unknown>): boolean {
	return !!state && state[1] === SortDirection.Desc;
}

export function setSortKey<Key = string>(
	currentState: SortState<Key>,
	nextStateOrKey: SortState<Key> | Key
): SortState<Key> {
	if (isStateNotKey(nextStateOrKey)) return nextStateOrKey;
	const selectedKey = nextStateOrKey;
	// We're going from unsorted to sorted
	if (!currentState) return [selectedKey, SortDirection.Desc];

	const [currentKey, currentDirection] = currentState;
	// Switching sort keys
	if (currentKey !== selectedKey) return [selectedKey, SortDirection.Desc];
	// Change sort direction for the current key
	if (currentDirection === SortDirection.Desc)
		return [currentKey, SortDirection.Asc];
	// Here, currentDirection must be SortDirection.Asc, so go to unsorted
	return null;
}

export function setSortKeyReskin<Key = string>(
	currentState: SortState<Key>,
	nextStateOrKey: SortState<Key> | Key
): SortState<Key> {
	if (isStateNotKey(nextStateOrKey)) return nextStateOrKey;
	const selectedKey = nextStateOrKey;
	// We're going from unsorted to sorted
	if (!currentState) return [selectedKey, SortDirection.Desc];

	const [currentKey, currentDirection] = currentState;
	// Switching sort keys
	if (currentKey !== selectedKey) return [selectedKey, SortDirection.Desc];
	// Change sort direction for the current key
	if (currentDirection === SortDirection.Asc)
		return [currentKey, SortDirection.Desc];
	// Here, currentDirection must be SortDirection.Asc, so go to unsorted
	return null;
}

// Internal helper for setSortKey
function isStateNotKey<Key>(s: SortState<Key> | Key): s is SortState<Key> {
	return Array.isArray(s);
}

export type SortIconType = 'amount' | 'numeric' | 'alpha' | undefined;

interface SortIndicatorProps<Key> {
	sort: SortState<Key>;
	itemKey: Key;
	type?: 'amount' | 'numeric' | 'alpha';
	showIndicatorWhenUnsorted?: boolean;
}

export function SortIndicator<Key>({
	sort,
	itemKey,
	type,
	showIndicatorWhenUnsorted = false,
}: SortIndicatorProps<Key>): JSX.Element {
	const sortedBy = sortKey(sort);
	const templateType = type ? `-${type}` : '';

	// If we're not sorting by anything and don't need to show an indicator, return void
	if (!showIndicatorWhenUnsorted && !sortedBy) return <></>;

	let className = cx(
		styles.sortIcon,
		styles.notSortingBy,
		`fa fa-sort${templateType}`
	);
	// If we're sorting by the current key, show the current sort direction
	if (sortedBy === itemKey) {
		className = isAscending(sort)
			? cx(styles.sortIcon, `fa fa-sort${templateType}-asc`)
			: cx(styles.sortIcon, `fa fa-sort${templateType}-desc`);
	}

	return (
		<i
			className={className}
			style={{
				display: 'inline-block',
				width: 10,
			}}
		/>
	);
}

interface SortDropdownProps<Key> {
	sort: SortState<Key>;
	onChange: (sort: SortState<Key>) => void;
	keys: Key[];
	getName: (k: Key) => string;
}

export function SortDropdown<Key>({
	sort,
	onChange,
	keys,
	getName,
}: SortDropdownProps<Key>): JSX.Element {
	const curKey = sortKey(sort);
	const curDir = sortDir(sort);
	const [open, setOpen] = useState(false);

	const handleSetKey = (k: Key): void => {
		setOpen(false);
		onChange([k, curDir ?? SortDirection.Desc]);
	};

	const toggleDirection: React.MouseEventHandler<SVGSVGElement> = (e) => {
		if (!curKey) return;
		e.stopPropagation();
		const newDir =
			curDir !== SortDirection.Desc ? SortDirection.Desc : SortDirection.Asc;
		onChange([curKey, newDir]);
	};

	const SortIconButton = (
		<SortOutlinedIcon
			sx={{
				color: (theme) => theme.colors.ColorGraySecondary,
				fontSize: (theme) => theme.typography.FontSizeSubtitle1,
				transform:
					curKey && curDir === SortDirection.Asc ? 'rotateX(180deg)' : 'unset',
			}}
			onClick={toggleDirection}
		/>
	);
	const label = curKey ? getName(curKey) : 'Sort By';
	return (
		<MenuDropDown
			open={open}
			handleClose={() => setOpen(false)}
			menuButton={
				<MenuButton
					startIcon={SortIconButton}
					handleClick={() => setOpen(true)}
					id='sortDropdown'
					style={{
						maxWidth: '190px',
					}}
					open={open}>
					<DNBTypography
						variant='compact-body'
						title={label}
						sx={{
							color: (theme) =>
								curKey ? undefined : theme.colors.ColorGraySecondary,
						}}
						noWrap>
						{label}
					</DNBTypography>
				</MenuButton>
			}
			tooltip='Sort By'
			menuItems={keys.map((k) => ({
				text: getName(k),
				value: k as unknown as string,
				isSelected: curKey === k,
				onClick: () => handleSetKey(k),
			}))}
		/>
	);
}

export function SortButton<Key>({
	sort,
	itemKey,
	type,
	showIndicatorWhenUnsorted,
	children,
	...wrapperProps
}: SortIndicatorProps<Key> & React.HTMLProps<HTMLButtonElement>): JSX.Element {
	return (
		<button
			type='button'
			{...wrapperProps}
			className={cx(styles.sortButton, wrapperProps.className)}>
			<div className={styles.sortButtonContent}>{children}</div>
			<SortIndicator
				sort={sort}
				itemKey={itemKey}
				type={type}
				showIndicatorWhenUnsorted={showIndicatorWhenUnsorted}
			/>
		</button>
	);
}

interface SortMenuProps {
	sort: SortState;
	onClick: (sort: SortState) => void;
	itemKey: string;
}

const SortMenuConfig = {
	[SortDirection.Asc]: {
		startIcon: (
			<ArrowUpwardOutlinedIcon
				sx={{
					color: designTokens.TokenColors.ColorGrayPrimary,
					marginTop: '-4px',
				}}
			/>
		),
		text: 'Sort Ascending',
	},
	[SortDirection.Desc]: {
		startIcon: (
			<ArrowDownwardOutlinedIcon
				sx={{
					color: designTokens.TokenColors.ColorGrayPrimary,
					marginTop: '-2px',
				}}
			/>
		),
		text: 'Sort Descending',
	},
};

export function SortMenu({sort, itemKey, onClick}: SortMenuProps): JSX.Element {
	const sortOptions = [SortDirection.Asc, SortDirection.Desc];
	return (
		<ul className={`${styles.enumFilter} ${styles.enumFilterMaxHeight}`}>
			{sortOptions.map((value) => {
				return (
					<li key={`${itemKey}-${value}`}>
						<DNBButton
							variant='text'
							size='compact'
							onClick={() => onClick([itemKey, value])}
							disabled={sort?.[1] === value}
							startIcon={SortMenuConfig[value].startIcon}
							data-field={`${itemKey}-${value}`}
							fullWidth
							sx={{mt: 1, mb: 1, justifyContent: 'flex-start'}}
							style={{
								textDecoration: 'none',
							}}>
							<DNBTypography
								variant='compact-body'
								data-field={`${itemKey}-${value}`}
								flex={1}
								textAlign='left'
								color={(theme) => theme.colors.ColorGrayPrimary}>
								{SortMenuConfig[value].text}
							</DNBTypography>
						</DNBButton>
					</li>
				);
			})}
		</ul>
	);
}

export function SortIcon({
	sort,
	itemKey,
	onClick,
	showIndicatorWhenUnsorted = false,
}: {
	sort: SortState;
	itemKey: string;
	onClick: (sort: SortState) => void;
	showIndicatorWhenUnsorted?: boolean;
}): JSX.Element | null {
	if (sort?.[0] !== itemKey) {
		return showIndicatorWhenUnsorted ? (
			<IconButton onClick={() => onClick([itemKey, 0])}>
				<SwapVertOutlinedIcon
					fontSize='small'
					sx={{
						color: (theme) => theme.colors.ColorGraySecondary,
					}}
				/>
			</IconButton>
		) : null;
	}
	return (
		<IconButton onClick={() => onClick(sort?.[1] === 0 ? [itemKey, 1] : null)}>
			{sort?.[1] === 0 ? (
				<ArrowUpwardOutlinedIcon
					fontSize='small'
					sx={{
						color: (theme) => theme.colors.ColorGraySecondary,
					}}
				/>
			) : (
				<ArrowDownwardOutlinedIcon
					fontSize='small'
					sx={{
						color: (theme) => theme.colors.ColorGraySecondary,
					}}
				/>
			)}
		</IconButton>
	);
}

export type Ord = string | number | null | undefined;

// Either an `Item` is a map of keys to `Ord`s, in which case we can extract
// the value
export function columnSort<
	Item extends Record<keyof Item, Ord>
>(): UseTransformation<SortState<keyof Item>, Item[], Item[]>;
// Or we don't know what `Key` & `Item` are, and we need a way to extract an
// `Ord` given a `Key` and `Item`
export function columnSort<Key, Item>(
	createValueGetter: (k: Key) => (i: Item) => Ord
): UseTransformation<SortState<Key>, Item[], Item[]>;
export function columnSort<Key, Item>(
	createValueGetter?: (k: Key) => (i: Item) => Ord
): UseTransformation<SortState<Key>, Item[], Item[]> {
	return sort((s) => {
		if (!s) return () => 0;
		const [key, dir] = s;
		const value = createValueGetter
			? createValueGetter(key)
			: // @ts-ignore: Type safety is handled by the overload signature
			  defaultCreateValueGetter<Item>(key);
		return (a, b) => compare(value(a), value(b), dir);
	});
}

function defaultCreateValueGetter<Item extends Record<keyof Item, Ord>>(
	key: keyof Item
): (i: Item) => Ord {
	return (i) => i[key];
}

function compare(a: Ord, b: Ord, dir: SortDirection): number {
	const sign = dir === SortDirection.Desc ? -1 : 1;
	if (isNil(a) && isNil(b)) return 0;
	if (isNil(a)) return sign * -Infinity;
	if (isNil(b)) return sign * Infinity;
	if (typeof a === 'string' && typeof b === 'string')
		return sign * a.localeCompare(b);
	if (typeof a === 'number' && typeof b === 'number') return sign * (a - b);

	const errorMessage =
		typeof a !== typeof b
			? `Cannot compare a ${typeof a} with a ${typeof b}. When sorting a column, all items should be of the same type.`
			: `Comparison between ${typeof a}s is unimplemented.`;
	console.error(new Error(errorMessage));
	return 0;
}
