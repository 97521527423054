import LocalStorageUtility from 'common/widgets/utilities/local-storage.utility';
import {isTimestampFartherThanNinetyDaysAgo} from 'common/app/utilities/TimestampIntervalUtility';
import moment from 'moment';
import {
	clearTenantHistory,
	GetSessionDocument,
	GetLoginDoc,
	PostToJwt,
	Logout,
	entitledForDnB,
} from 'common/app/services/LoginService';
import {getString} from 'common/app/utilities/ResourceUtility';
import {login, set, redirectToLP, setHistory} from './login.service';
import {Banner} from 'common/app/services/BannerService';

angular.module('login').component('loginFrame', {
	template: require('./login.component.html'),
	bindings: {
		logindocument: '<',
		clientsession: '<',
	},
	controller($state, $location, $window) {
		const vm = this;

		vm.$onInit = async function () {
			vm.getString = getString;
			vm.login = login;
			vm.state = $state;
			// TODO: loginInProgress should be of type Record<TenantName, boolean>, not of type boolean. However it is used interchangeably as boolean.
			vm.loginInProgress = {};
			vm.isLoggedInWithTempPassword = vm.logindocument.MustChangePassword;
			vm.isPasswordOlderThanNinetyDays = isTimestampFartherThanNinetyDaysAgo(
				vm.logindocument.PasswordLastModified
			);
			vm.params = $location.$$search;

			switch ($state.current.name) {
				case 'login.form':
					if (vm.logindocument.UserName) {
						$state.go('login.tenants', {obj: vm.params});
					}
					break;
				case 'login.tenants':
					if (!vm.logindocument.UserName) {
						$state.go('login.form');
					}
					break;
				case 'login':
					if (
						Object.keys(vm.params).length != 0 &&
						vm.params.type == 'jwt' &&
						vm.logindocument.UserName
					) {
						PostToJwt(vm.params).then(function (result) {
							$window.location.href = result.url;
						});
					} else if (vm.params?.return_to) {
						const returnTo = vm.params.return_to;
						// must use localStorage instead of sessionStorage with redirect to okta and back, which ends session
						localStorage.setItem(
							'return_to',
							JSON.stringify({
								url: returnTo,
								expiration: moment(new Date()).add(15, 'minutes').toISOString(),
							})
						);
						$state.go('login.form');
					} else {
						vm.loginExternally();
					}
					break;
			}

			set(vm.logindocument, vm.clientsession);

			angular.element('body').addClass('initialized');
		};

		vm.clearHistory = function (username) {
			clearTenantHistory(username);
			setHistory();
		};

		vm.validatedTenant = (curTenant) => {
			const validatedTenants = vm.logindocument.Tenants ? vm.logindocument.Tenants
				.filter((tenant) => tenant.DisplayName === curTenant)
				.filter((tenant) => entitledForDnB(tenant)) : undefined;
			return validatedTenants?.length === 1;
		};

		vm.clickTenant = function (tenant, username) {
			if (entitledForDnB(tenant)) {
				if (typeof vm.loginInProgress === 'boolean') {
					vm.loginInProgress = {};
					vm.loginInProgress[tenant.DisplayName] = true;
				}

				GetSessionDocument(tenant, username).then(function (data) {
					if (data != null && data.Success === true) {
						redirectToLP(tenant);
					} else {
						vm.loginInProgress[tenant.DisplayName] = false;

						Banner.error({
							message: getString('TENANT_SELECTION_FORM_ERROR'),
						});
					}
				});
			} else {
				vm.loginInProgress[tenant.DisplayName] = false;

				Banner.error({
					message: getString('TENANT_SELECTION_FORM_ERROR'),
				});
			}
		};

		vm.returnTo = function () {
			const returnToRaw = localStorage.getItem('return_to');
			if (returnToRaw) localStorage.removeItem('return_to');
			const returnTo = returnToRaw && JSON.parse(returnToRaw);
			const returnToIsNotExpired =
				returnToRaw &&
				moment(returnTo.expiration).isAfter(moment(new Date()));
			if (returnToIsNotExpired) {
				window.location.href = returnTo.url;
			} else {
				$state.go('login.tenants');
			}
		};

		vm.loginExternally = async function () {
			const previousSession = LocalStorageUtility.getClientSession();
			const loginDocument = LocalStorageUtility.getLoginDocument();
			if (previousSession && loginDocument && !loginDocument.MustChangePassword) {
				vm.returnTo();
				return true;
			}
			vm.loginInProgress = true;
			GetLoginDoc()
				.then(function (result) {
					vm.loginInProgress = false;
					if (result !== null && result.Success === true) {
						set(
							LocalStorageUtility.getLoginDocument(),
							LocalStorageUtility.getClientSession()
						);

						try {
							vm.returnTo();
						} catch (e) {
							console.log(
								'the following error occurred while parsing localStorage.getItem("return_to")'
							);
							console.error(e);
							localStorage.removeItem('return_to');
							$state.go('login.tenants');
						}
					} else {
						$state.go('login.form');
					}
					return true;
				})
				.catch((error) => {
					console.error(error);
					$state.go('login.form');
				});
		};

		vm.showLoginHeaderMessage = function (message) {
			if (message == null) {
				return;
			}

			if (message.indexOf('Global Auth') > -1) {
				message = getString('LOGIN_GLOBAL_AUTH_ERROR');
			}

			Banner.error({
				message,
			});
		};

		vm.clickLogout = function ($event) {
			if ($event != null) {
				$event.preventDefault();
			}
			Logout();
		};

		vm.clickToLP = function () {
			redirectToLP();
		};
	},
});
