import React from 'common/react-vendor';
import {PersonalizationContextProvider} from '../context/PersonalizationContext';
import PersonalizationContainer from './PersonalizationContainer';

export default function PersonalizationComponent(): React.ReactElement {
	return (
		<PersonalizationContextProvider>
			<PersonalizationContainer />
		</PersonalizationContextProvider>
	);
}
