/**
 * TODO: Delete this angular file once all components that uses numericalRangeDirective gets refactor.
 * TODO: DELETE this file once there are no more instances of <numerical-range-directive />.
 */

import {react2angular} from 'common/react-vendor';
import {NumericalRangeWithAngularProps} from '../NumericalRange/NumericalRange';

angular
	.module(
		'common.datacloud.query.builder.tree.edit.transaction.edit.numerical.range',
		[]
	)
	.component(
		'numericalRangeDirective',
		react2angular(NumericalRangeWithAngularProps, [
			'config',
			'showfrom',
			'fromlabel',
			'fromdisabled',
			'showto',
			'tolabel',
			'todisabled',
			'showmessage',
			'changed',
			'bucketrestriction',
		])
	);
