import ServiceErrorUtility from './ServiceErrorUtility';

angular
	.module('common.exceptions', [])
	.service('ServiceErrorInterceptor', function ($q) {
		this.response = function (response) {
			ServiceErrorUtility.process(response);
			return response || $q.when(response);
		};

		this.request = function (response) {
			ServiceErrorUtility.process(response);
			return response || $q.when(response);
		};

		this.responseError = function (rejection) {
			ServiceErrorUtility.process(rejection);
			return $q.reject(rejection);
		};

		this.requestError = function (rejection) {
			ServiceErrorUtility.process(rejection);
			return $q.reject(rejection);
		};
	})
	.config(function ($httpProvider) {
		$httpProvider.interceptors.push('ServiceErrorInterceptor');
	});
