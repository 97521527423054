import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {getPrefixedKey} from 'common/app/utilities/persistance';
import {getQueryProperty} from 'common/stores/query';
import LocalStorageUtility from 'widgets/utilities/local-storage.utility';
import {isEmpty} from 'lodash';
import UserValues from 'common/widgets/utilities/user-values.utility';
import {
	getDeaultSelections,
	ACCOUNTS_PAGE_COLUMNS_KEY,
	ACCOUNTS_PAGE_VIEW_KEY,
	CONTACTS_PAGE_COLUMNS_KEY,
	CONTACTS_PAGE_VIEW_KEY,
	LAST_SELECTED_ATTRIBUTE_GROUP_CACHE_KEY,
} from 'components/datacloud/query/results/rebuild/index';
import {getDataCloudProperty} from 'common/stores/datacloud';
import {EntitySessionKeyMap} from 'atlas/segmentation/JourneyEntity/JourneyEntity';
import {ExportType, Query} from '../datacloud/datacloud.types';
import {getSessionSegmentState} from '../datacloud/query/results/rebuild/segment.helpers';
import {deleteAngularHashKey} from '../datacloud/segment/segment.helpers';
import getSanitizedSegmentByConfig from '../datacloud/tabs/subheader/SubheaderUtility';
import {Attribute} from '../datacloud/query/advanced/tree/types/Attribute.types';
import {SegmentCreate} from '../datacloud/segment/SegmentQueryHelper';
import {IEntityType} from '../datacloud/journeystage/journey.helpers';

/**
 * Get the current attribute group.
 * @returns current attribute group.
 */
const getCurrentAttributeGroup = (): string => {
	const {name = ''} = getQueryProperty<Query>('segment') || {};
	return (
		LocalStorageUtility.get(`${name || ''}_last_attribute_set_exported`) ||
		'Default_Group'
	);
};

type IExportEntityType = 'ACCOUNT' | 'ACCOUNT_AND_CONTACT';

type IVisibleColumn = {
	AttrName: string;
	Category: string;
};

type IExportFilter = {
	journeyStages: number[];
	stageIdForSubStages?: number;
	segmentName: string;
};

type IExportConfig = Query & {
	segmentName?: string;
	export_prefix?: string;
	addExportTimestamp?: boolean;
	visibleColumns?: IVisibleColumn[];
	atlasExportFilter?: IExportFilter;
};

const getVisibleColumns = (
	attributesMap: Record<string, string[]>,
	entity: IExportEntityType
): IVisibleColumn[] => {
	const columnKeys: Pick<Record<ExportType, string>, IExportEntityType> = {
		ACCOUNT: ACCOUNTS_PAGE_COLUMNS_KEY,
		ACCOUNT_AND_CONTACT: CONTACTS_PAGE_COLUMNS_KEY,
	};
	const key = columnKeys[entity];
	const prefixedKey = getPrefixedKey(key);
	const accountAndContactColumns = JSON.parse(
		localStorage.getItem(prefixedKey) || '{}'
	);
	const queryKeys: Pick<Record<ExportType, string>, IExportEntityType> = {
		ACCOUNT: ACCOUNTS_PAGE_VIEW_KEY,
		ACCOUNT_AND_CONTACT: CONTACTS_PAGE_VIEW_KEY,
	};
	const keyPrefix = UserValues.getLongFormTenantId();
	const queryKey = `${keyPrefix}.${queryKeys[entity]}`;
	const currentAttributeGroupView = JSON.parse(
		sessionStorage.getItem(queryKey) || '{}'
	)?.attributeGroup;
	const visibleColumns: string[][] = accountAndContactColumns?.[
		currentAttributeGroupView
	] || [...getDeaultSelections(entity === 'ACCOUNT')];
	let formatVisibleColumns: IVisibleColumn[] = [];
	if (!isEmpty(visibleColumns)) {
		const enrichments = getDataCloudProperty<Attribute[]>('enrichments') || [];
		formatVisibleColumns = visibleColumns
			.map((columns: string[]) => {
				return columns.map((columnString) => {
					// Here, the "columnString" is formatted like "SomeCategoryAbbreviation.AttributeName"
					// The category abbreviation is useless to us. We need to find the full formal category name from attributesMap,
					// which we preemptively requested when this modal mounted (/pls/attrconfig/attributeset/name/*) in case the current view is exported.
					const [categoryAbbreviation = '', AttrName = ''] =
						columnString.split('.');
					const getCategoryFromEnrichments = (): string | undefined => {
						const attribute = enrichments.find(
							({AttrName: name, Entity}) =>
								name === AttrName && Entity === categoryAbbreviation
						);
						return attribute?.Category;
					};
					const getCategoryFromAttributesMap = (): string | undefined => {
						const category = Object.entries(attributesMap).find(
							([_category, attributes]) => {
								return attributes.includes(AttrName);
							}
						);
						return category ? category[0] : undefined;
					};
					const Category =
						getCategoryFromEnrichments() || getCategoryFromAttributesMap();
					if (!Category) {
						console.error(
							'Error matching an attribute name to its category. This may indicate a bug within customizable columns or we are trying to export column that no longer exists. We will skip this column in the exported file.'
						);
						return undefined;
					}
					return {AttrName, Category} as IVisibleColumn;
				});
			})
			.flat()
			.filter((item) => !!item)
			.filter((columnDefinition) =>
				Boolean(columnDefinition)
			) as IVisibleColumn[];
	}
	return formatVisibleColumns;
};

const getAtlasExportFilter = (
	segmentName: string,
	entityType: IEntityType
): IExportFilter | undefined => {
	const sessionKey = EntitySessionKeyMap[entityType];
	const [stageIdListStr, stageIdForSubStages] = (
		sessionStorage.getItem(sessionKey) || ''
	).split('-');
	return stageIdListStr
		? {
				journeyStages: stageIdListStr.split(',').map((id) => parseInt(id, 10)),
				segmentName,
				stageIdForSubStages: stageIdForSubStages
					? parseInt(stageIdForSubStages, 10)
					: undefined,
		  }
		: undefined;
};

/**
 * Export a segment.
 * @param exportType @ExportType
 * @param exportTimestamp True on export timestamp.
 * @param attributeGroup Attribute group.
 * @remarks
 * Really wired legacy logic on type @ExportType override functionality on
 * the if code block, even if user select a export type from radio group.
 */
const exportSegment = (
	exportType: ExportType,
	exportTimestamp: boolean,
	attributeGroup: string,
	visibleColumns?: IVisibleColumn[],
	entityType?: IEntityType
): Promise<boolean> => {
	const {name, display_name} = getQueryProperty<Query>('segment') || {};
	const sessionSegmentState = getSessionSegmentState();
	let type = exportType;
	if (
		sessionSegmentState?.isCompanyList ||
		sessionSegmentState?.isContactList
	) {
		type = sessionSegmentState?.isCompanyList ? 'ACCOUNT' : 'CONTACT';
	}
	const isCreate = !name || name === SegmentCreate;
	return axiosInstance
		.post(
			'/pls/datacollection/segments/export',
			deleteAngularHashKey<IExportConfig>(
				getSanitizedSegmentByConfig({
					segmentName: isCreate ? undefined : name,
					export_prefix: display_name,
					addExportTimestamp: exportTimestamp,
					attributeSetName: attributeGroup,
					visibleColumns,
					atlasExportFilter:
						isCreate || !entityType
							? undefined
							: getAtlasExportFilter(name, entityType),
					type,
				} as Query)
			)
		)
		.then(() => true)
		.catch(() => false);
};

const getAttributeMapUrl = (): string => {
	const currentlySelectedAttributeGroup =
		LocalStorageUtility.getTenantCache(
			LAST_SELECTED_ATTRIBUTE_GROUP_CACHE_KEY
		) || 'Default_Group';
	return `/pls/attrconfig/attributeset/name/${currentlySelectedAttributeGroup}`;
};

const getAttributeSetUrl = (): string => '/pls/attrconfig/attributeset';

export type {IExportEntityType, IExportFilter, IExportConfig};

export {
	getCurrentAttributeGroup,
	getVisibleColumns,
	exportSegment,
	getAttributeSetUrl,
	getAttributeMapUrl,
};
