import httpService from 'app/http/http-service';
import Observer from 'app/http/observer';
import {store} from 'store';
import {v4 as uuid} from 'uuid';

const namespace = `teams-${uuid()}`;

const CONST = {
	INITIALIZE: 'INITIALIZE',
	GET_TEAMS: 'GET_TEAMS',
	MY_TEAMS: 'MY_TEAMS',
	OTHER_TEAMS: 'OTHER_TEAMS',
};

const initialState = {
	teams: null,
	myteams: null,
	otherteams: null,
};

export const actions = {
	initialize: (payload) => {
		return store.dispatch({
			type: CONST.INITIALIZE,
			namespace,
		});
	},
	getTeams: (opts, deferred) => {
		const {username, session, callback} = opts;
		const addUrl = [];

		if (session) {
			addUrl.push('/session'); // only gets teams for current users
		}
		if (username) {
			addUrl.push(`/username/${encodeURIComponent(username)}`);
		}

		deferred = deferred || {resolve: (data) => data};
		const observer = new Observer((response) => {
			httpService.unsubscribeObservable(observer);

			const data = [];
			response?.data.forEach((item) => {
				// hack to get fullname if createdBy exists
				if (item.CreatedBy) {
					item.CreatedBy.FullName = `${item.CreatedBy.FirstName} ${item.CreatedBy.LastName}`;
				}
				item.TeamMembers.forEach((member, index) => {
					item.TeamMembers[
						index
					].FullName = `${member.FirstName} ${member.LastName}`;
					item.TeamMembers[
						index
					].SearchKey = `${member.FullName} (${member.Email})`;
				});
				item.SegmentsCount = item.MetadataSegments?.length || 0;
				item.PlaysCount = item.Plays?.length || 0;
				item.ModelsCount = item.RatingEngines?.length || 0;
				data.push(item);
			});

			store.dispatch({
				type: CONST.GET_TEAMS,
				payload: data,
				namespace,
			});

			if (callback && typeof callback === 'function') {
				callback(data);
			}

			return deferred.resolve(data);
		});
		httpService.get(`/pls/teams${addUrl.join('')}`, observer, {});
	},
	addTeam: (opts = {}, deferred) => {
		const {teamObj} = opts; // {"teamMembers":["test@lattice-engines.com"],"teamName":"test"}
		const {callback} = opts;

		deferred = deferred || {resolve: (data) => data};
		const observer = new Observer((response) => {
			httpService.unsubscribeObservable(observer);
			if (callback && typeof callback === 'function') {
				callback(response.data);
			}
			return deferred.resolve(response.data);
		});
		httpService.post('/pls/teams/', teamObj, observer);
	},
	editTeam: (opts = {}, deferred) => {
		const teamId = opts.teamId || '';
		const {teamObj} = opts;
		const {callback} = opts;

		deferred = deferred || {resolve: (data) => data};
		const observer = new Observer((response) => {
			httpService.unsubscribeObservable(observer);
			if (callback && typeof callback === 'function') {
				callback(response.data);
			}
			return deferred.resolve(response.data);
		});
		httpService.put(`/pls/teams/teamId/${teamId}`, teamObj, observer);
	},
	deleteTeam: (opts = {}, deferred) => {
		const teamId = opts.teamId || '';
		const {callback} = opts;

		deferred = deferred || {resolve: (data) => data};
		const observer = new Observer((response) => {
			httpService.unsubscribeObservable(observer);
			if (callback && typeof callback === 'function') {
				callback(response.data);
			}
			return deferred.resolve(response.data);
		});
		httpService.delete(`/pls/teams/${teamId}`, observer);
	},
};

export const reducer = (state = initialState, action) => {
	if (action.namespace !== namespace) {
		return state;
	}

	switch (action.type) {
		case CONST.INITIALIZE:
			return initialState;
		case CONST.GET_TEAMS:
			return {
				...state,
				teams: action.payload,
			};
		case CONST.MY_TEAMS:
			return {
				...state,
				myteams: action.payload,
			};
		case CONST.OTHER_TEAMS:
			return {
				...state,
				otherteams: action.payload,
			};
		default:
			return state;
	}
};
