import React from 'common/react-vendor';
import cx from 'classnames';
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from 'react-beautiful-dnd';
import {Column, ColumnGroup, Selections, removeColumns} from './index';
import {ReactComponent as DragHandle} from './dragHandle.svg';
import styles from './index.module.css';

const {useCallback} = React;

interface ColumnOrderingProps {
	columns: Record<string, Column>;
	columnGroups: ColumnGroup[];
	selections: Selections;
	setSelections: React.Dispatch<React.SetStateAction<Selections>>;
}

export function ColumnOrdering({
	columns,
	columnGroups,
	selections,
	setSelections,
}: ColumnOrderingProps): JSX.Element {
	const selectionCount = Object.values(selections).flat().length;

	const onDragEnd = useCallback(
		({source, destination}: DropResult) => {
			if (!destination) return;
			setSelections((s) => {
				// Deep clone so we can mutate within this function
				const newSelections = Object.fromEntries(
					Object.entries(s).map(([k, cols]) => [k, [...cols]])
				);
				const src = newSelections[source.droppableId];
				const dst = newSelections[destination.droppableId];
				if (!src || !dst) return newSelections; // TODO: err log? If undef then there's a bug in rbdnd
				const [moved] = src.splice(source.index, 1);
				// Moved should always be defined unless there's a bug in react-beautiful-dnd
				if (moved) dst.splice(destination.index, 0, moved);
				return newSelections;
			});
		},
		[setSelections]
	);

	return (
		<div className={styles.order}>
			<div className={styles.selectedCount}>
				{selectionCount !== 1 ? (
					<>{selectionCount} Columns Selected</>
				) : (
					<>1 Column Selected</>
				)}
			</div>
			<DragDropContext onDragEnd={onDragEnd}>
				{columnGroups.map(({id: groupId, name}) => (
					<div key={groupId}>
						<h3 className={styles.columnGroupName}>{name}</h3>
						<Droppable key={groupId} droppableId={groupId}>
							{(provided) => (
								<div
									className={styles.columnGroup}
									{...provided.droppableProps}
									ref={provided.innerRef}>
									{selections[groupId]?.map((colId, idx) => {
										const column = columns[colId];
										return (
											<Draggable key={colId} draggableId={colId} index={idx}>
												{(provided, snapshot) => (
													<div
														className={styles.column}
														ref={provided.innerRef}
														{...provided.draggableProps}>
														<div
															className={styles.columnName}
															title={column?.name || colId}>
															<span
																className={cx(
																	styles.columnDragHandle,
																	snapshot.isDragging && styles.dragging
																)}
																{...provided.dragHandleProps}>
																<DragHandle />
															</span>
															{column?.name || colId}
														</div>
														<button
															type='button'
															className='button borderless-button'
															onClick={() =>
																setSelections(removeColumns([colId]))
															}>
															&times;
														</button>
													</div>
												)}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</div>
				))}
			</DragDropContext>
		</div>
	);
}
