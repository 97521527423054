import {store} from 'store';
import {setMetadataPropertyValue} from 'common/stores/datacloud';
import {TopBucketDisplayStrategy} from '../../datacloud.service.vanilla';
import {generateBucketLabel} from '../../query/query.helpers';
import template from './categorytile.component.html';
import '../tile.scss';

export default function () {
	return {
		restrict: 'A',
		scope: {
			vm: '=',
			count: '=',
			category: '=',
			metadata: '<',
		},
		controllerAs: 'vm',
		template,
		controller: function CategoryTileController($scope, moment) {
			'ngInject';

			const vm = $scope.vm;

			angular.extend(vm, {});

			$scope.$on('iterationFilterChange', function () {
				let categories = Object.keys(vm.categoryCounts).filter(function (
					value
				) {
					return vm.categoryCounts[value] > 0;
				});

				categories = vm.removeEmptyCategories(categories);
				vm.setCategory(categories[0]);
				vm.filterEmptySubcategories();
			});

			vm.setCategory = function (category) {
				if (vm.category !== category) {
					store.dispatch(setMetadataPropertyValue('category', category));

					vm.category = category;
				}
			};

			vm.categoryClass = (category) =>
				`category-${category
					.toLowerCase()
					.replace(/\s/g, '-')
					.replace('&', 'and')}`;

			vm.categoryIcon = function (category) {
				const path = '/assets/images/enrichments/subcategories/',
					renamedCategory = vm.subcategoryRenamer(category, ''),
					icon = renamedCategory + '.png';

				return path + icon;
			};

			/**
			 * Retrurn the path of the image starting from /assets
			 * In case of category === 'latticerating' or 'productspendprofile'
			 * it returns the category icon
			 * @param {*} category
			 * @param {*} subcategory
			 */
			vm.subcategoryIcon = function (category, subcategory) {
				const path = '/assets/images/enrichments/subcategories/',
					renamedCategory = vm.subcategoryRenamer(category),
					renamedSubCategory = vm.subcategoryRenamer(subcategory);

				let icon =
					renamedCategory +
					(renamedSubCategory ? '-' + renamedSubCategory : '') +
					'.png';

				switch (renamedCategory) {
					case 'latticeratings': {
						icon = 'latticeratings.png';
						break;
					}
					case 'productspendprofile': {
						icon = 'productspendprofile.png';
						break;
					}
				}
				return path + icon;
			};

			vm.categoryStartFrom = function () {
				const size = vm.category ? vm.categorySize : vm.pagesize,
					current = vm.metadata.currentCategory - 1,
					items = vm.categoriesMenu,
					length = items ? items.length : 0,
					result =
						current * size + size > length ? length - size : current * size;

				return result < 0 ? 0 : result;
			};

			vm.categoryLimitTo = function () {
				return vm.category ? vm.categorySize : vm.pagesize;
			};

			vm.categoryOrderBy = function categoryOrderBy() {
				let order;
				if (vm.section === 'segment.analysis') {
					order = [
						'!TopBkt',
						'!ImportanceOrdering',
						'-ImportanceOrdering',
						'-TopBkt.Cnt',
						'-Value',
					];
				} else {
					order = !vm.showHighlighting()
						? ['-ImportanceOrdering']
						: [
								'-HighlightHighlighted',
								'!ImportanceOrdering',
								'-ImportanceOrdering',
						  ];

					if (vm.lookupMode) {
						order.push((attribute) => (attribute.Value === 'Yes' ? -1 : 1));
					}

					order = order.concat(['-Count', '-Value']);
				}

				return order;
			};

			vm.categoryClick = function (category = '', $event) {
				const target = angular.element($event.target),
					currentTarget = angular.element($event.currentTarget);

				if (
					target.closest('[ng-click]:not(.ignore-ngclick)')[0] !==
					currentTarget[0]
				) {
					// do nothing, user is clicking something with it's own click event
				} else {
					if (vm.subcategory && vm.category == category) {
						vm.setSubcategory('');
						if (vm.subcategoriesExclude.indexOf(category) >= 0) {
							// don't show subcategories
							vm.setSubcategory(vm.subcategories[category][0]);
						}
					} else if (vm.category == category) {
						vm.setSubcategory('');
					} else {
						vm.setSubcategory('');
						if (vm.subcategoriesExclude.indexOf(category)) {
							vm.setSubcategory(vm.subcategories[category][0]);
						}
						vm.setCategory(category);
						vm.filterEmptySubcategories();
					}
					vm.metadata.current = 1;
					vm.updateStateParams();
				}
			};

			vm.getAttributeStat = function (attribute) {
				const enrichmentKey = attribute.Attribute || attribute.ColumnId,
					index = vm.enrichmentsMap[attribute.Entity + '.' + enrichmentKey],
					enrichment = vm.enrichments[index],
					attributeEntity = attribute.Entity,
					stats =
						vm.cube &&
						vm.cube[attributeEntity].Stats[enrichmentKey] &&
						vm.cube[attributeEntity].Stats[enrichmentKey] &&
						vm.cube[attributeEntity].Stats[enrichmentKey].Bkts &&
						vm.cube[attributeEntity].Stats[enrichmentKey].Bkts.List
							? vm.cube[attributeEntity].Stats[enrichmentKey].Bkts.List
							: null;

				let stat = stats && stats.length ? stats[0] : null;

				if (stats && stats.length > 1) {
					for (const i in stats) {
						if (stats[i] && stats[i].Rng) {
							if (
								vm.segmentAttributeInputRange[
									vm.makeSegmentsRangeKey(enrichment, stats[i].Rng)
								]
							) {
								stat = stats[i];
								break;
							}
						}
					}
				}

				return stat;
			};

			vm.getAttributeRange = function (attribute) {
				const stat = vm.getAttributeStat(attribute),
					range = stat && stat.Rng ? stat.Rng : {};

				return range;
			};

			vm.getAttributeLabel = (attribute, displayStrategy) => {
				switch (displayStrategy) {
					case TopBucketDisplayStrategy.Subcategory:
						return attribute.Subcategory;
					case TopBucketDisplayStrategy.Attribute:
						return attribute.DisplayName;
					default: {
						console.error(
							`Unknown display strategy ${displayStrategy} for attribute '${attribute.DisplayName}'`
						);
						return attribute.DisplayName;
					}
				}
			};

			vm.displayAttributeValue = function (attribute, property = 'Lbl') {
				const stat = vm.getAttributeStat(attribute);

				if (stat && stat[property]) {
					if (property === 'Lift') {
						return stat[property].toFixed(1) + 'x';
					}
					return stat[property];
				}
			};

			vm.generateBucketOperation = function (bkt) {
				return bkt.Cmp == 'NOT_EQUAL' ? 'is not' : 'is';
			};

			vm.generateBucketLabel = function (bkt) {
				bkt = generateBucketLabel(bkt);

				return bkt.Lbl || 'empty';
			};

			vm.getTitleTooltip = function (attribute) {
				if (attribute.Entity === 'PurchaseHistory') {
					return attribute.Subcategory;
				}
			};

			vm.getDateMap = function (category) {
				const categoryKey = category,
					timestamp =
						vm.collectionStatus && vm.collectionStatus.DateMap
							? vm.collectionStatus.DateMap[categoryKey]
							: '';

				if (timestamp) {
					return (
						'Last Data Refresh: ' + moment(timestamp).format('MMMM DD, YYYY')
					);
				}

				return '';
			};
		},
	};
}
