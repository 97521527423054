import {axios} from 'common/network.vendor';
import type {AxiosError} from 'axios';
import {Query} from '../datacloud.types';
import {getSessionSegmentState} from './results/rebuild/segment.helpers';
import {getCurrentSegmentName} from './query.helpers';

interface ServerErrorMsg {
	errorMsg: string;
}

const serverErrorMsg = (e: AxiosError<ServerErrorMsg>): never => {
	if (e.response?.data?.errorMsg) throw new Error(e.response.data.errorMsg);
	throw e;
};

// For now, the type guards will just operate on members of the Restriction type.
export const isLogicalRestriction = (
	r: common.Restriction
): r is common.LogicalRestriction =>
	Object.prototype.hasOwnProperty.call(r, 'logicalRestriction');

export const isBucketRestriction = (
	r: common.Restriction
): r is common.BucketRestriction =>
	Object.prototype.hasOwnProperty.call(r, 'bucketRestriction');

// Returning assignments simplifies the caching behavior of some of the networking code
/* eslint-disable no-return-assign */

// #region getEntities
interface EntitiesResponse {
	data: common.Entity[];
}

let controls = new AbortController();

export const getEntities = (
	entityType: 'accounts' | 'contacts',
	query: Query,
	useInternalAccountID = true
): Promise<common.Entity[]> => {
	const sessionSegmentState = getSessionSegmentState();

	if (
		sessionSegmentState?.isCompanyList ||
		sessionSegmentState?.isContactList
	) {
		const segmentName = getCurrentSegmentName();

		if (!segmentName) {
			return Promise.resolve([]);
		}

		controls.abort(new Error('canceling and executing new request'));
		controls = new AbortController();

		return axios
			.post<EntitiesResponse>(
				`/pls/${
					sessionSegmentState.isCompanyList ? 'accounts' : 'contacts'
				}/list`,
				query,
				{
					params: {
						segmentName,
					},
					signal: controls?.signal,
				}
			)
			.then(({data}) => data.data);
	}

	return axios
		.post<EntitiesResponse>(
			`/pls/${entityType}/data?use-internal-account-id=${useInternalAccountID}`,
			query
		)
		.then((r) => r.data.data)
		.catch(serverErrorMsg);
};
// #endregion getEntities

let allAttributeGroupsCache: Promise<common.AttributeGroup[]> | null = null;

export function invalidateAllAttributeGroups(): void {
	allAttributeGroupsCache = null;
}

export const getAllAttributeGroups = (): Promise<common.AttributeGroup[]> =>
	(allAttributeGroupsCache =
		allAttributeGroupsCache ||
		axios
			.get<common.AttributeGroup[]>('/pls/attrconfig/attributeset')
			.then((r) => r.data)).catch((e) => {
		invalidateAllAttributeGroups();
		return serverErrorMsg(e);
	});

const attributeGroupCache: Record<string, Promise<common.AttributeGroup>> = {};

function invalidateAttributeGroup(name: string): void {
	delete attributeGroupCache[name];
}

export const getAttributeGroup = (
	name: string
): Promise<common.AttributeGroup> =>
	(attributeGroupCache[name] =
		attributeGroupCache[name] ||
		axios
			.get<common.AttributeGroup>(`/pls/attrconfig/attributeset/name/${name}`)
			.then((r) => r.data)).catch((e) => {
		invalidateAttributeGroup(name);
		return serverErrorMsg(e);
	});

export interface AttributeGroupAttribute {
	AttrName: string;
	DisplayName: string;
	Entity: string;
	IsAdminDisabledForModel: string;
	IsCoveredByOptionalRule: string;
	IsCoveredByMandatoryRule: string;
	IsCampaignDerivedField: string;
	FundamentalType: string;
	Subcategory: string;
	Category: string;
	ColumnId: string;
}

const attributeGroupAttributesCache: Record<
	string,
	Promise<AttributeGroupAttribute[]>
> = {};

function invalidateAttributeGroupAttributes(name: string): void {
	delete attributeGroupAttributesCache[name];
}

export const getAttributeGroupAttributes = (
	name: string
): Promise<AttributeGroupAttribute[]> =>
	(attributeGroupAttributesCache[name] =
		attributeGroupAttributesCache[name] ||
		axios
			.get<AttributeGroupAttribute[]>(`/pls/datacollection/attributes/${name}`)
			.then((r) => r.data)).catch((e) => {
		invalidateAttributeGroupAttributes(name);
		// We need to add this return so ts annotates this with `never`
		return serverErrorMsg(e);
	});

/* eslint-enable no-return-assign */

export function invalidateGroup(group: string): void {
	invalidateAllAttributeGroups();
	invalidateAttributeGroup(group);
	invalidateAttributeGroupAttributes(group);
}
