/**
 * TODO: Delete this angular file once all components that uses enumAttribute gets refactor.
 * TODO: DELETE this file once there are no more instances of <enum-attribute />.
 */

import {react2angular} from 'common/react-vendor';
import {EnumAttributeWithAngularProps} from './EnumAttribute';

angular
	.module('common.datacloud.query.builder.tree.edit.enum.attribute', [])
	.component(
		'enumAttribute',
		react2angular(EnumAttributeWithAngularProps, ['id', 'values', 'suffix'])
	);
