import React from 'common/react-vendor';
import styles from './IdaasMigration.module.css';

export const IdaasForgotPasswordMessage = (): React.ReactElement => (
	<section>
		<h1 className={styles.title}>Forget your password?</h1>
		<p className={styles.paragraph}>
			Dun & Bradstreet is upgrading their identity management system to support{' '}
			<strong>a single email and password</strong> across all your D&B products.
			If you forget your password, please reset it in the D&B system settings.
		</p>
		<a
			target='_blank'
			rel='noopener noreferrer'
			href='https://help.lattice-engines.com/hc/en-us/articles/4407582361751-Using-D-B-SSO-for-CDP-login'>
			Reset password instructions
		</a>
	</section>
);
