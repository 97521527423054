// This modal does its own presentation because the current LeModal isn't
// reactive and has an imperative API. Once the component library is around we
// can switch to it for the modal

// TODO: Clean up styling

import React, {ReactDOM} from 'common/react-vendor';
import cx from 'classnames';
import {LeBannerSimple} from 'widgets/le-banner-simple/le-banner-simple';
import {isEmpty, isNil} from 'lodash';
import {useMemo} from 'react';
import {ColumnOrdering} from './column-ordering';
import {ColumnSelection} from './column-selection';
import styles from './index.module.css';

const {useLayoutEffect, useState, useRef} = React;
const {createPortal} = ReactDOM;

export interface Category {
	id: string;
	name: string;
	colIds: string[];
}

export interface Column {
	name: string;
	disabled?: boolean;
}

// Where the user is putting their columns
export interface ColumnGroup {
	id: string;
	name: string;
}

// Relation between column groups & columns
export interface Selections {
	[groupId: string]: string[];
}

export interface ColumnCustomizationModalProps {
	title: React.ReactNode;
	displayName?: string;
	categories: Category[];
	columns: Record<string, Column>;
	columnGroups: ColumnGroup[];
	initialSelections: Selections;
	defaultGroupId: string;
	onSave: (selections: Selections, newDisplayName?: string) => void;
	onCancel?: () => void;
	editName?: boolean;
	existingNames?: string[];
	closeOnBlur?: boolean;
}

const DUPLICATE_NAME_WARNING =
	'A view with this name already exists. Please assign a unique name.';

const INVALID_NAME_WARNING = 'Valid column names must contain a letter A-Z.';

function isExistingName(
	name: string,
	newName: string,
	existingNames: string[]
): boolean {
	if (name.toLowerCase() === newName.toLowerCase()) {
		// this covers both the case where the name hasn't changed at all, or where we have just changed the case
		return false;
	}
	return existingNames.some(
		(existingName) =>
			existingName.toLowerCase().trim() === newName.toLowerCase().trim()
	);
}

const hasMinimumRequiredChars = (name: string): boolean => {
	const regex = /(?=.*[a-zA-Z])/g;
	return regex.test(name);
};

const getWarningMessage = (
	isExistingDisplayName: boolean,
	isNewDisplayNameValid: boolean
): string | null => {
	if (isExistingDisplayName) {
		return DUPLICATE_NAME_WARNING;
	}

	if (!isNewDisplayNameValid) {
		return INVALID_NAME_WARNING;
	}

	return null;
};

export function ColumnCustomizationModal({
	title,
	displayName,
	categories,
	columns,
	columnGroups,
	initialSelections,
	defaultGroupId,
	onSave,
	onCancel,
	editName,
	existingNames,
	closeOnBlur = true,
}: ColumnCustomizationModalProps): JSX.Element {
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	if (!wrapperRef.current) wrapperRef.current = document.createElement('div');
	const [newDisplayName, setNewDisplayName] = useState(displayName || '');

	// README: This has to be a layout effect!
	// See https://github.com/atlassian/react-beautiful-dnd/issues/1756#issuecomment-933723320
	useLayoutEffect(() => {
		document.body.append(wrapperRef.current!);
		wrapperRef.current!.focus();
		return () => wrapperRef.current!.remove();
	}, []);

	const initFilteredSelections = useMemo(() => {
		const result = Object.keys(initialSelections).reduce((pre, groupId) => {
			return {
				...pre,
				[groupId]: (initialSelections[groupId] || []).filter(
					(col) => !columns[col]?.disabled
				),
			};
		}, {});
		return result as Selections;
	}, [columns, initialSelections]);
	// This is the unsaved changes a user makes to the column selections while editing
	const [localChanges, setLocalChanges] = useState(initFilteredSelections);

	const isExistingDisplayName =
		!isNil(displayName) &&
		isExistingName(displayName, newDisplayName, existingNames || []);

	const isNewDisplayNameEmpty = editName && isEmpty(newDisplayName.trim());

	const isNewDisplayNameValid = editName
		? hasMinimumRequiredChars(newDisplayName)
		: true;

	const isSaveDisabled =
		!Object.values(localChanges).flat().length ||
		isExistingDisplayName ||
		isNewDisplayNameEmpty ||
		!isNewDisplayNameValid;

	const warningMessage = getWarningMessage(
		isExistingDisplayName,
		isNewDisplayNameValid
	);

	return createPortal(
		<div
			className={styles.scrim}
			onMouseDown={(e) => {
				if (e.target === e.currentTarget && onCancel && closeOnBlur) {
					onCancel();
				}
			}}>
			<div className={styles.modalBody}>
				<div className={styles.header}>
					<h2>
						{displayName || editName ? (
							<div>
								Customize Columns:&nbsp;
								{editName ? (
									<span>
										<input
											placeholder='Untitled'
											value={newDisplayName}
											className={cx(styles.editableInput, 'ml-1')}
											type='text'
											onChange={(e) => setNewDisplayName(e.target.value)}
											maxLength={100}
										/>
									</span>
								) : (
									newDisplayName
								)}
							</div>
						) : (
							title
						)}
					</h2>
					<button
						type='button'
						className='button borderless-button'
						style={{fontSize: '1.6em'}}
						onClick={onCancel}>
						&times;
					</button>
				</div>
				<div className={styles.content}>
					<div className={styles.editor}>
						<ColumnSelection
							columns={columns}
							categories={categories}
							selections={localChanges}
							setSelections={setLocalChanges}
							defaultGroupId={defaultGroupId}
						/>
						<ColumnOrdering
							columns={columns}
							columnGroups={columnGroups}
							selections={localChanges}
							setSelections={setLocalChanges}
						/>
					</div>
				</div>
				<div className={styles.footer}>
					<span className={cx(styles.warningBanner, 'mr-1', 'ml-1')}>
						{warningMessage !== null && (
							<LeBannerSimple content={warningMessage} type='warning' />
						)}
					</span>
					<button
						type='button'
						className='button white-button mb-1'
						onClick={onCancel}>
						Cancel
					</button>
					&nbsp;
					<button
						type='button'
						className='button blue-button mb-1'
						disabled={isSaveDisabled}
						onClick={() => {
							if (Object.values(localChanges).flat().length)
								onSave(localChanges, newDisplayName);
						}}>
						Save
					</button>
				</div>
			</div>
		</div>,
		wrapperRef.current!
	);
}

export const removeColumns =
	(toRemove: string[]): React.SetStateAction<Selections> =>
	(selections) =>
		Object.fromEntries(
			Object.entries(selections).map(([groupId, cols]) => [
				groupId,
				cols.filter((colId) => !toRemove.includes(colId)),
			])
		);

export const addColumns =
	(
		toAdd: string[],
		groupId: string,
		columns: Record<string, Column>
	): React.SetStateAction<Selections> =>
	(selections) => {
		const allCols = Object.values(selections)
			.flat()
			.filter((column) => !columns[column]?.disabled);
		const uniqueAdditions = toAdd.filter((colId) => !allCols.includes(colId));
		const newCols = [
			...new Set((selections[groupId] || []).concat(uniqueAdditions)),
		];
		return {...selections, [groupId]: newCols};
	};
