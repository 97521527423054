export const TYPE_GENERIC = 'modal-generic';
export const TYPE_ERROR = 'modal-error';
export const TYPE_INFO = 'modal-info';
export const TYPE_SUCCESS = 'modal-success';
export const TYPE_WARNING = 'modal-warning';

export const TINY_SIZE = 'xs';
export const SMALL_SIZE = 'sm';
export const MEDIUM_SIZE = 'md';
export const LARGE_SIZE = 'lg';
