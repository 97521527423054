import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {cacheQueryResponse} from 'common/app/utilities/cacheUtility/cacheUtility';
import {AttributeStats} from './types';

const getEntityFieldBuckets = async (
	entity: string,
	fieldName: string
): Promise<AttributeStats> =>
	cacheQueryResponse(`getEntityFieldBuckets-${entity}-${fieldName}`, () =>
		axiosInstance
			.get<AttributeStats>(
				`/pls/datacollection/statistics/attrs/${entity}/${fieldName}`
			)
			.then(({data}) => data)
	);

const getActivityEntityBuckets = async (
	entityType: string,
	entity: string,
	attributeId: string
): Promise<string[]> =>
	cacheQueryResponse(
		`getActivityEntityBuckets-${entityType}-${entity}-${attributeId}`,
		() =>
			axiosInstance
				.get<string[]>(
					`/pls/activityAttributes/findByActivityAndAttributeId?activity=${entityType}&attributeId=${attributeId}&entity=${entity}`
				)
				.then(({data}) => data)
	);

export {getEntityFieldBuckets, getActivityEntityBuckets};
