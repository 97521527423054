import React from 'common/react-vendor';
import {MarketplaceSegmentFilter} from '../marketplace-segment-types';
import styles from './marketplace-segment-filters.module.scss';
import {
	TriStateCheckbox,
	TriStateCheckboxState,
} from './TriStateCheckbox/TriStateCheckbox';

interface MarketplaceSegmentFiltersProps {
	onFiltersChange(newFilters: Set<string>): void;

	selectedFilters: Set<string>;
}

export const MarketplaceSegmentFilters = ({
	onFiltersChange,
	selectedFilters,
}: MarketplaceSegmentFiltersProps): React.ReactElement => {
	const filters: MarketplaceSegmentFilter[] = [
		{
			label: 'Company Type',
		},
		{
			label: 'Company Age',
		},
		{
			label: 'Company Revenue',
		},
		{
			label: 'Company Size',
		},
		{
			label: 'Decision Maker Persona',
			searchTerm: 'Decision Makers',
		},
		{
			label: 'Industry',
			searchTerm: 'B2B Industry',
			subFilters: [
				{
					label: 'Accommodation and Food Services',
				},
				{
					label: 'Administrative',
					searchTerm: 'Industry > Administrative',
				},
				{
					label: 'Agriculture',
				},
				{
					label: 'Arts and Entertainment',
				},
				{
					label: 'Business Services',
				},
				{
					label: 'Construction',
				},
				{
					label: 'Educational Services',
				},
				{
					label: 'Energy and Raw Materials',
				},
				{
					label: 'Finance and Insurance',
				},
				{
					label: 'Government',
					searchTerm: 'Industry > Government',
				},
				{
					label: 'Healthcare',
					searchTerm: 'Industry > Healthcare',
				},
				{
					label: 'Management of Companies',
				},
				{
					label: 'Manufacturing',
					searchTerm: 'Industry > Manufacturing',
				},
				{
					label: 'Media',
				},
				{
					label: 'Non-professional Services',
				},
				{
					label: 'Real Estate',
					searchTerm: 'Industry > Real Estate',
				},
				{
					label: 'Retail Trade',
				},
				{
					label: 'Software/Information Technology',
				},
				{
					label: 'Telecommunications',
				},
				{
					label: 'Transportation and Warehousing',
				},
				{
					label: 'Utilities',
				},
				{
					label: 'Wholesale Trade',
				},
			],
		},
		{
			label: 'Job Function',
			searchTerm: 'B2B Job Function/Roles',
			subFilters: [
				{
					label: 'Administrative',
					searchTerm: 'Job Function/Roles > Administrative',
				},
				{
					label: 'Blue Collar',
				},
				{
					label: 'Business Owners/Board',
				},
				{
					label: 'Computing & IT',
				},
				{
					label: 'Education',
					searchTerm: 'Job Function/Roles > Education',
				},
				{
					label: 'Entrepreneurs',
				},
				{
					label: 'Executives',
					searchTerm: 'Job Function/Roles > Executives',
				},
				{
					label: 'Finance',
					searchTerm: 'Job Function/Roles > Finance',
				},
				{
					label: 'Franchise Owner',
				},
				{
					label: 'General Management',
				},
				{
					label: 'Government',
					searchTerm: 'Job Function/Roles > Government',
				},
				{
					label: 'Healthcare',
					searchTerm: 'Job Function/Roles > Healthcare',
				},
				{
					label: 'HR/Human Resources',
				},
				{
					label: 'Legal',
					searchTerm: 'Job Function/Roles > Legal',
				},
				{
					label: 'Marketing',
					searchTerm: 'Job Function/Roles > Marketing',
				},
				{
					label: 'Operations',
				},
				{
					label: 'Research & Development',
				},
				{
					label: 'Sales',
					searchTerm: 'Job Function/Roles > Sales',
				},
				{
					label: 'White Collar',
				},
				{
					label: 'Other',
					searchTerm: 'Job Function/Roles > Other',
				},
			],
		},
		{
			label: 'Location Size',
		},
		{
			label: 'Location Type',
		},
		{
			label: 'Ownership & Legal Structure',
		},
		{
			label: 'Predictive Analytics',
			searchTerm: 'B2B Predictive Analytics',
			subFilters: [
				{
					label: 'B2B Company Growth',
				},
				{
					label: 'Company Growth Signal',
				},
				{
					label: 'Finance',
					searchTerm: 'Predictive Analytics > Finance',
				},
				{
					label: 'Spending Power',
				},
				{
					label: 'Technology',
					searchTerm: 'Predictive Analytics > Technology >',
				},
				{
					label: 'Technology Purchase Intent',
				},
				{
					label: 'Financial Services Purchase Intent',
				},
			],
		},
	];

	const [expandedFilters, setExpandedFilters] = React.useState<Set<string>>(
		new Set()
	);

	const [partiallySelectedFilters, setPartiallySelectedFilters] =
		React.useState<Set<string>>(new Set());

	const toggleFilter = (label: string): void => {
		const newState = new Set([...selectedFilters]);
		if (newState.has(label)) {
			newState.delete(label);
		} else {
			newState.add(label);
		}
		const newPartiallySelectedFilters = new Set([...partiallySelectedFilters]);
		filters.forEach((filter) => {
			if (filter.subFilters) {
				const sfSelected = filter.subFilters.map((sf) => {
					return newState.has(sf.searchTerm || sf.label);
				});
				if (sfSelected.every((a) => a)) {
					newState.add(filter.searchTerm || filter.label);
					newPartiallySelectedFilters.delete(filter.searchTerm || filter.label);
				} else if (sfSelected.some((a) => a)) {
					newState.delete(filter.searchTerm || filter.label);
					newPartiallySelectedFilters.add(filter.searchTerm || filter.label);
				} else {
					newState.delete(filter.searchTerm || filter.label);
					newPartiallySelectedFilters.delete(filter.searchTerm || filter.label);
				}
			}
		});
		onFiltersChange(newState);
		setPartiallySelectedFilters(newPartiallySelectedFilters);
	};

	const toggleCategory = (label: string): void => {
		const category = filters.find((f) => {
			return f.searchTerm ? f.searchTerm === label : f.label === label;
		});

		const newPartiallySelectedFilters = new Set([...partiallySelectedFilters]);
		newPartiallySelectedFilters.delete(label);
		setPartiallySelectedFilters(newPartiallySelectedFilters);

		const newSelectedFilters = new Set([...selectedFilters]);

		if (selectedFilters.has(label) || partiallySelectedFilters.has(label)) {
			// unselect self and all children
			newSelectedFilters.delete(label);
			if (category?.subFilters) {
				category.subFilters.forEach((sf) => {
					newSelectedFilters.delete(sf.searchTerm || sf.label);
				});
			}
		} else {
			// select self and all children
			newSelectedFilters.add(label);
			if (category?.subFilters) {
				category.subFilters.forEach((sf) => {
					newSelectedFilters.add(sf.searchTerm || sf.label);
				});
			}
		}

		onFiltersChange(newSelectedFilters);
	};

	const toggleExpanded = (label: string): void => {
		const newState = new Set([...expandedFilters]);
		if (newState.has(label)) {
			newState.delete(label);
		} else {
			newState.add(label);
		}
		setExpandedFilters(newState);
	};

	return (
		<div className={styles.marketplaceSegmentFilters}>
			<div className={styles.mpsfHeader}>
				<div className={styles.mpsfTitle}>
					<img src='/atlas/assets/icons/filter.svg' alt='filter' />
					Filter By
				</div>
				{selectedFilters.size > 0 && (
					<button
						type='button'
						onClick={() => {
							onFiltersChange(new Set<string>());
							setPartiallySelectedFilters(new Set<string>());
						}}
						className={styles.mpsfClearButton}>
						Clear
					</button>
				)}
			</div>
			<div className={styles.mpsfBody}>
				{filters.map((f) => (
					<div key={f.searchTerm || f.label}>
						<div
							className={styles.mpsfFilter}
							onClick={(e) => {
								e.stopPropagation();
								toggleCategory(f.searchTerm || f.label);
							}}>
							<TriStateCheckbox
								state={(() => {
									let state: TriStateCheckboxState = 'unchecked';
									if (selectedFilters.has(f.searchTerm || f.label)) {
										state = 'checked';
									} else if (
										partiallySelectedFilters.has(f.searchTerm || f.label)
									) {
										state = 'partial';
									}
									return state;
								})()}
								onClick={() => {
									toggleFilter(f.searchTerm || f.label);
								}}
								label={f.label}
							/>
							<span className={styles.mpsfCounter}>
								{f.subFilters &&
									f.subFilters.find((sf) =>
										selectedFilters.has(sf.searchTerm || sf.label)
									) && (
										<>
											(
											{f.subFilters
												.map<number>((sf) =>
													selectedFilters.has(sf.searchTerm || sf.label) ? 1 : 0
												)
												.reduce((p, c) => p + c)}
											)
										</>
									)}
							</span>
							{f.subFilters && (
								<div
									className={styles.mpsfExpand}
									onClick={(e) => {
										e.stopPropagation();
										toggleExpanded(f.searchTerm || f.label);
									}}>
									<img
										alt='expand/collapse'
										src={
											expandedFilters.has(f.searchTerm || f.label)
												? 'assets/svgs/icons/chevron-up.svg'
												: 'assets/svgs/icons/chevron-down.svg'
										}
									/>
								</div>
							)}
						</div>
						{f.subFilters && expandedFilters.has(f.searchTerm || f.label) && (
							<div>
								{f.subFilters.map((sf) => (
									<div
										key={sf.searchTerm || sf.label}
										className={styles.mpsfSubFilter}
										onClick={(e) => {
											e.stopPropagation();
											toggleFilter(sf.searchTerm || sf.label);
										}}>
										<TriStateCheckbox
											state={
												selectedFilters.has(sf.searchTerm || sf.label)
													? 'checked'
													: 'unchecked'
											}
											onClick={() => {
												toggleFilter(sf.searchTerm || sf.label);
											}}
											label={sf.label}
										/>
									</div>
								))}
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};
