import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import {
	CategoryMetadata,
	getMetadataForCategory,
} from 'common/components/datacloud/datacloud.service.vanilla';
import {getString} from 'common/app/utilities/ResourceUtility';
import {
	GetAttributeBucketName,
	IsAllowedForInsights,
	IsPredictorBoolean,
	MISC_BUCKET_NAME,
} from '../utilities/AnalyticAttributeUtility';
import {
	AttributeCategory,
	ChartData,
	Element,
	Predictor,
	TopCategory,
	TopCategoryChildren,
	ModelSummary,
	TopCategories,
	SimpleBucket,
	SimpleBucketElement,
	SimpleBucketElementList,
} from './topPredictor.types';
import {
	TOP_CATEGORIES,
	NULL_BUCKET_NAME,
	NULL_BUCKET_NAMES,
	RANGE_BUCKET_NAMES,
	FUNDAMENTAL_TYPE,
	NOT_AVAILABLE_NAMES,
} from './topPredictor.constants';

// #region flags
const hasMatchingAttributeTag = (
	attributeMetadata: Predictor | null,
	isExternal: boolean
): boolean => {
	if (!attributeMetadata) {
		return false;
	}

	let tag = isExternal ? 'External' : 'Internal';

	const internalCategories = [
		'Lead Information',
		'Marketing Activity',
		'Account Information',
	];

	// DP-1883
	if (internalCategories.includes(attributeMetadata.Category)) {
		if (isExternal) {
			return false;
		}

		tag = 'Internal';
	}

	if (attributeMetadata.Tags === null) {
		return tag === 'Internal';
	}

	for (const predictorTag of attributeMetadata.Tags) {
		if (tag === predictorTag) {
			return true;
		}
	}

	return false;
};

const hasAttributeValidBuckets = (
	attributeMetadata: Predictor | null,
	totalLeads: number | null
): boolean => {
	if (!attributeMetadata || totalLeads === null) {
		return false;
	}

	for (const element of attributeMetadata.Elements) {
		const attributeValue = GetAttributeBucketName(element, attributeMetadata);

		const elementCount = element?.Count || 0;
		const totalPercent = ((elementCount / totalLeads) * 100).toFixed(0);
		const parsedPercent = parseInt(totalPercent, 10);

		const falsyAttributes = ['NULL', 'NOT AVAILABLE', ''];

		if (
			attributeValue !== null &&
			falsyAttributes.includes(attributeValue.toUpperCase()) &&
			parsedPercent >= 99.5
		) {
			return false;
		}
	}

	return true;
};

const isPredictorElementCategorical = (
	predictorElement: Element | null
): boolean => {
	if (predictorElement === null) {
		return false;
	}

	return (
		predictorElement.LowerInclusive === null &&
		predictorElement.UpperExclusive === null &&
		predictorElement.Values !== null &&
		predictorElement.Values.length > 0 &&
		predictorElement.Values[0] !== null
	);
};
// #endregion flags

// #region sort
const sortBySize = (a: TopCategory, b: TopCategory): number => {
	if (a.size > b.size) {
		return -1;
	}

	if (a.size < b.size) {
		return 1;
	}

	// a must be equal to b
	return 0;
};

const sortByUncertaintyCoefficient = (
	a: Predictor | TopCategory,
	b: Predictor | TopCategory
): number => {
	if (a.UncertaintyCoefficient > b.UncertaintyCoefficient) {
		return -1;
	}

	if (a.UncertaintyCoefficient < b.UncertaintyCoefficient) {
		return 1;
	}

	// a must be equal to b
	return 0;
};
// #endregion sort

// #region mappers
interface MapCategoryChildrenParams {
	modelSummary: ModelSummary | null;
	categoryName: TopCategory['name'] | null;
	categoryColor: TopCategory['color'];
	maxLength: number | null;
}
const mapCategoryChildren = ({
	modelSummary,
	categoryName,
	categoryColor,
	maxLength,
}: MapCategoryChildrenParams): TopCategoryChildren => {
	if (
		!categoryName ||
		modelSummary === null ||
		modelSummary.Predictors === null ||
		maxLength === null
	) {
		return [];
	}

	const predictors = [...modelSummary.Predictors];
	const categoryPredictors = predictors
		.filter((predictor) => categoryName === predictor.Category)
		.sort(sortByUncertaintyCoefficient);

	const validPredictors = [];

	for (const predictor of categoryPredictors) {
		if (validPredictors.length === maxLength) {
			break;
		}

		const hasValidBuckets = hasAttributeValidBuckets(
			predictor,
			modelSummary.ModelDetails.TotalLeads
		);

		const hasMatchingExternalTag = hasMatchingAttributeTag(predictor, true);
		const hasMatchingInternalTag = hasMatchingAttributeTag(predictor, false);

		const hasMatchingTag = hasMatchingExternalTag || hasMatchingInternalTag;

		if (IsAllowedForInsights(predictor) && hasValidBuckets && hasMatchingTag) {
			validPredictors.push({
				name: predictor.Name,
				categoryName,
				power: predictor.UncertaintyCoefficient,
				size: 1,
				color: categoryColor,
			});
		}
	}

	return validPredictors;
};

const mapCategories = (
	modelSummary: ModelSummary | null,
	categoryMetadata: CategoryMetadata[]
): TopCategories | null => {
	if (isEmpty(modelSummary?.Predictors)) {
		return null;
	}

	const predictors = [...modelSummary!.Predictors!];

	return predictors
		.sort(sortByUncertaintyCoefficient)
		.reduce((topCategories, predictor) => {
			const hasPredictorValidBuckets = hasAttributeValidBuckets(
				predictor,
				modelSummary!.ModelDetails.TotalLeads
			);

			const hasCategoryName = topCategories.some(
				({categoryName}) => categoryName === predictor.Category
			);

			if (
				IsAllowedForInsights(predictor) &&
				hasPredictorValidBuckets &&
				!isEmpty(predictor.Category) &&
				!hasCategoryName
			) {
				const metadata = getMetadataForCategory(
					categoryMetadata,
					predictor.Category
				);

				return [
					...topCategories,
					{
						name: predictor.Category,
						categoryName: predictor.Category,
						UncertaintyCoefficient: predictor.UncertaintyCoefficient,
						size: 1, // This doesn't matter because the inner ring takes on the size of the outer
						color: metadata?.themeColor || '',
						children: [],
						metadata,
					},
				];
			}

			return topCategories;
		}, [] as TopCategories);
};

interface MapChildrenSizeParams {
	categoryChildren: TopCategoryChildren;
	largeCategoriesCount?: number;
	mediumCategoriesCount?: number;
}
const mapChildrenSize = ({
	categoryChildren,
	largeCategoriesCount,
	mediumCategoriesCount,
}: MapChildrenSizeParams): TopCategoryChildren => {
	if (isEmpty(categoryChildren)) {
		return [];
	}

	const childrenLength = categoryChildren.length;

	let maxLargeCategories =
		largeCategoriesCount || Math.round(childrenLength * 0.16);

	let maxMediumCategories =
		mediumCategoriesCount || Math.round(childrenLength * 0.32);

	return categoryChildren.map((child) => {
		if (maxLargeCategories > 0) {
			maxLargeCategories--;

			return {
				...child,
				size: 6.55,
			};
		}

		if (maxMediumCategories > 0) {
			maxMediumCategories--;

			return {
				...child,
				size: 2.56,
			};
		}

		return {
			...child,
			size: 1,
		};
	});
};

const mapTopPercentage = (percentList: string[]): string[] => {
	// Find the bucket with the largest percentage
	let maxPercentage = '0';
	const largestPercentageIndex = percentList.reduce(
		(index, percent, currentIndex) => {
			const currentPercentage = percent === '<0.1' ? '0.1' : percent;

			if (currentPercentage > maxPercentage) {
				maxPercentage = currentPercentage;

				return currentIndex;
			}

			return index;
		},
		0
	);

	// Make the max percentage equal to 100 minus the sum of all the other percentages
	const topPercentage = percentList.reduce(
		(percentage, percent, currentIndex) => {
			if (currentIndex === largestPercentageIndex) {
				return percentage;
			}

			if (percent === '<0.1') {
				return percentage - 0.1;
			}

			const intPercent = +percent;
			return percentage - intPercent;
		},
		100.0
	);

	const newPercentList = [...percentList];

	newPercentList[largestPercentageIndex] = topPercentage.toFixed(0);

	return newPercentList;
};

const resetCategoriesActiveClass = (
	categoryList: AttributeCategory[] | null
): AttributeCategory[] => {
	if (isEmpty(categoryList)) {
		return [];
	}

	const newCategories = [...categoryList!];

	return newCategories.map((category) => ({...category, activeClass: ''}));
};

const mapTicks = (maxTickValue: number, maxTickNumber: number): number[] => {
	const steps = [0.5, 1, 2, 5, 10];

	// iterate options in steps, find the maximum appropriate step
	let step = steps.reduce((maximumStep, currentStep) => {
		return maxTickNumber * maximumStep >= maxTickValue
			? maximumStep
			: currentStep;
	}, 0);

	// continue doubling step until find an appropriate one
	while (maxTickNumber * step < maxTickValue) {
		step *= 2;
	}

	// construct ticks
	let tick = 0;
	const ticks = [tick];

	while (tick < maxTickValue) {
		tick += step;
		ticks.push(tick);
	}

	return ticks;
};
// #endregion mappers

const sortCategoriesByPowerSumDescending = (
	modelSummary: ModelSummary | null,
	categories: TopCategories | null
): TopCategories | null => {
	if (!categories) {
		return null;
	}

	const newCategories = [...categories];

	newCategories.sort((a, b) => {
		const aAttributes = mapCategoryChildren({
			modelSummary,
			categoryName: a.name,
			categoryColor: a.color,
			maxLength: 3,
		});
		const bAttributes = mapCategoryChildren({
			modelSummary,
			categoryName: b.name,
			categoryColor: b.color,
			maxLength: 3,
		});

		const aPower = aAttributes.reduce(
			(total, attribute) => total + attribute.power,
			0
		);
		const bPower = bAttributes.reduce(
			(total, attribute) => total + attribute.power,
			0
		);

		return bPower - aPower;
	});

	return newCategories;
};

// #region getters
const getSortedCategories = (
	modelSummary: ModelSummary | null,
	categoryMetadata: CategoryMetadata[]
): TopCategories | null =>
	sortCategoriesByPowerSumDescending(
		modelSummary,
		mapCategories(modelSummary, categoryMetadata)
	);

const getSuppressedCategories = (
	modelSummary: ModelSummary | null,
	categoryMetadata: CategoryMetadata[]
): TopCategories | null => {
	const categories = getSortedCategories(modelSummary, categoryMetadata);

	if (!categories) {
		return null;
	}

	const categoriesLength = categories.length;

	if (categoriesLength > TOP_CATEGORIES) {
		return categories.slice(TOP_CATEGORIES, categoriesLength);
	}

	return null;
};

const getTopCategories = (
	modelSummary: ModelSummary | null,
	categoryMetadata: CategoryMetadata[]
): TopCategories | null => {
	const categories = getSortedCategories(modelSummary, categoryMetadata);

	if (categories) {
		return categories.slice(0, TOP_CATEGORIES);
	}

	return null;
};

// #region getNumberOfAttributesByCategory
const getElementsCount = (elements: Element[], totalLeads: number): number => {
	return elements.reduce((totalElements, element) => {
		const elementCount = element?.Count || 0;

		const totalPercent = ((elementCount / totalLeads) * 100).toFixed(0);

		const parsedPercent = parseInt(totalPercent, 10);

		const isCategorical = isPredictorElementCategorical(element);

		if (isCategorical && parsedPercent < 1) {
			return totalElements;
		}

		return totalElements + 1;
	}, 0);
};

interface GetAttributeCountsParams {
	modelSummary: ModelSummary;
	categoryName: string;
	isExternal: boolean;
}
interface GetAttributeCountsReturn {
	categoryCount: number;
	totalAttributes: number;
}
const getAttributeCounts = ({
	modelSummary,
	categoryName,
	isExternal,
}: GetAttributeCountsParams): GetAttributeCountsReturn => {
	const counts = {
		categoryCount: 0,
		totalAttributes: 0,
	};

	if (!modelSummary.Predictors) {
		return counts;
	}

	return modelSummary.Predictors.reduce((accumulator, predictor) => {
		if (
			predictor.Category === categoryName &&
			hasMatchingAttributeTag(predictor, isExternal) &&
			IsAllowedForInsights(predictor) &&
			hasAttributeValidBuckets(predictor, modelSummary.ModelDetails.TotalLeads)
		) {
			const elementsCount = getElementsCount(
				predictor.Elements,
				modelSummary.ModelDetails.TotalLeads
			);

			return {
				categoryCount: accumulator.categoryCount + 1,
				totalAttributes: accumulator.totalAttributes + elementsCount,
			};
		}

		return accumulator;
	}, counts);
};

interface GetNumberOfAttributesByCategoryReturn {
	totalAttributeValues: number;
	total: number;
	categories: AttributeCategory[];
}
const getNumberOfAttributesByCategory = (
	isExternal: boolean,
	modelSummary: ModelSummary
): GetNumberOfAttributesByCategoryReturn => {
	const numberOfAttributes: GetNumberOfAttributesByCategoryReturn = {
		totalAttributeValues: 0,
		total: 0,
		categories: [],
	};

	if (isExternal === null || modelSummary.Predictors === null) {
		return numberOfAttributes;
	}

	return modelSummary.ChartData.children.reduce((accumulator, category) => {
		const {categoryCount, totalAttributes} = getAttributeCounts({
			modelSummary,
			categoryName: category.name,
			isExternal,
		});

		if (categoryCount > 0) {
			return {
				totalAttributeValues:
					accumulator.totalAttributeValues + totalAttributes,
				total: accumulator.total + categoryCount,
				categories: [
					...accumulator.categories,
					{
						name: category.name,
						count: categoryCount,
						color: category.color,
						activeClass: '',
						metadata: category.metadata,
					},
				],
			};
		}

		return {
			...accumulator,
			totalAttributeValues: accumulator.totalAttributeValues + totalAttributes,
		};
	}, numberOfAttributes);
};
// #endregion getNumberOfAttributesByCategory

const getModelChartData = (
	modelSummary: ModelSummary | null,
	categoryMetadata: CategoryMetadata[]
): ChartData | null => {
	if (isNil(modelSummary) || isEmpty(modelSummary.Predictors)) {
		return null;
	}

	const topCategories = getTopCategories(modelSummary, categoryMetadata)!;

	topCategories.sort(sortByUncertaintyCoefficient);

	const attributesPerCategory = 3;

	let numLargeCategories = Math.round(
		topCategories.length * attributesPerCategory * 0.16
	);

	let numMediumCategories = Math.round(
		topCategories.length * attributesPerCategory * 0.32
	);

	const children = topCategories.map((category) => {
		const categoryChildren = mapCategoryChildren({
			modelSummary,
			categoryName: category.name,
			categoryColor: category.color,
			maxLength: attributesPerCategory,
		});

		return {
			...category,
			children: categoryChildren.map((child) => {
				if (numLargeCategories > 0) {
					numLargeCategories--;

					return {
						...child,
						size: 6.55,
					};
				}

				if (numMediumCategories > 0) {
					numMediumCategories--;

					return {
						...child,
						size: 2.56,
					};
				}

				return {
					...child,
					size: 1,
				};
			}),
		};
	});

	children.sort(sortBySize);

	return {
		name: 'root',
		size: 1,
		color: '#FFFFFF',
		attributesPerCategory,
		children,
	};
};

const getAttributeByName = (
	attributeName: string | null,
	predictorList: Predictor[] | null
): Predictor | null => {
	if (attributeName === null || predictorList === null) {
		return null;
	}

	const attribute = predictorList.find(({Name}) => attributeName === Name);

	if (attribute) {
		return attribute;
	}

	return null;
};

// #region getSimpleBuckets
interface GetSimpleBucketSortPropertyParams {
	bucket: Element;
	bucketName: string;
	name: string;
	isContinuous: boolean;
}

const getSimpleBucketSortProperty = ({
	bucket,
	bucketName,
	name,
	isContinuous,
}: GetSimpleBucketSortPropertyParams): number => {
	if (isContinuous) {
		const {LowerInclusive, UpperExclusive} = bucket;

		return LowerInclusive !== null ? LowerInclusive : UpperExclusive || 0;
	}

	if (RANGE_BUCKET_NAMES.includes(name)) {
		const isMillion = bucketName.includes('M');
		const isBillion = bucketName.includes('B');

		if (bucketName.includes('>')) {
			const removedLessThanCharacter = bucketName.substr(1);

			const number = parseFloat(removedLessThanCharacter.replace(/,/g, ''));

			if (isMillion) {
				return number * 1000000;
			}

			if (isBillion) {
				return number * 1000000000;
			}

			return number;
		}

		if (bucketName.includes('-')) {
			const [value = ''] = bucketName.split('-');
			const minValue = parseInt(value, 10);

			if (isMillion) {
				return minValue * 1000000;
			}

			if (isBillion) {
				return minValue * 1000000000;
			}

			return minValue;
		}
	}

	return bucket.Lift;
};

interface ReducePredictorElementsParams {
	predictor: Predictor;
	modelSummary: ModelSummary;
	isContinuous: boolean;
}

interface ReducePredictorElementsReturn {
	nullBucket: SimpleBucketElement | null;
	miscBucket: SimpleBucketElement | null;
	elementList: SimpleBucketElementList;
}

const reducePredictorElements = ({
	predictor,
	modelSummary,
	isContinuous,
}: ReducePredictorElementsParams): ReducePredictorElementsReturn => {
	const name = predictor.DisplayName;

	return predictor.Elements.reduce(
		(accumulator, bucket) => {
			const bucketName = GetAttributeBucketName(bucket, predictor);

			const percentTotal = (
				(bucket.Count / modelSummary.ModelDetails.TotalLeads) *
				100
			).toFixed(0);

			const bucketToDisplay: SimpleBucketElement = {
				name: bucketName,
				lift: bucket.Lift,
				percentTotal,
				SortProperty: getSimpleBucketSortProperty({
					bucket,
					bucketName,
					name,
					isContinuous,
				}),
			};

			if (bucket?.IsVisible) {
				if (NULL_BUCKET_NAMES.includes(bucketToDisplay.name.toUpperCase())) {
					return {
						...accumulator,
						nullBucket: {
							...bucketToDisplay,
							name: NULL_BUCKET_NAME,
						},
					};
				}

				if (bucketToDisplay.name === MISC_BUCKET_NAME) {
					return {
						...accumulator,
						miscBucket: bucketToDisplay,
					};
				}

				return {
					...accumulator,
					elementList: [...accumulator.elementList, bucketToDisplay],
				};
			}

			return accumulator;
		},
		{
			nullBucket: null,
			miscBucket: null,
			elementList: [],
		} as ReducePredictorElementsReturn
	);
};

interface SortBySortPropertyParams {
	a: SimpleBucketElement;
	b: SimpleBucketElement;
	name?: string;
	isContinuous: boolean;
}
const sortBySortProperty = ({
	a,
	b,
	name,
	isContinuous,
}: SortBySortPropertyParams): number => {
	if (name && RANGE_BUCKET_NAMES.includes(name)) {
		if (a.SortProperty < b.SortProperty) {
			return -1;
		}
		if (a.SortProperty === b.SortProperty) {
			return 0;
		}
		if (a.SortProperty > b.SortProperty) {
			return 1;
		}
	}

	if (a.SortProperty < b.SortProperty) {
		return isContinuous ? -1 : 1;
	}

	if (a.SortProperty === b.SortProperty) {
		return 0;
	}

	if (a.SortProperty > b.SortProperty) {
		return isContinuous ? 1 : -1;
	}

	return 0;
};

const mapElementList = (
	predictor: Predictor,
	modelSummary: ModelSummary
): SimpleBucketElementList => {
	const name = predictor.DisplayName;

	const isContinuous = IsPredictorBoolean(predictor)
		? false
		: predictor.Elements.some(
				({LowerInclusive, UpperExclusive}) =>
					LowerInclusive !== null || UpperExclusive !== null
		  );

	const {elementList, nullBucket, miscBucket} = reducePredictorElements({
		predictor,
		modelSummary,
		isContinuous,
	});

	elementList.sort((a, b) => sortBySortProperty({a, b, name, isContinuous}));

	// Always sort NULL bucket to the bottom
	if (nullBucket !== null) {
		elementList.push(nullBucket);
	}

	if (miscBucket !== null) {
		elementList.push(miscBucket);
	}

	// DP-932
	if (
		isContinuous &&
		nullBucket !== null &&
		elementList.length === 2 &&
		elementList[0]
	) {
		elementList[0].name = 'Available';
	}

	const hasOther = elementList.some(({name}) => name === 'Other, Less Popular');
	const hasZeroPercent = elementList.some(
		({percentTotal}) => percentTotal === '0'
	);

	if (hasOther && hasZeroPercent) {
		// Combine "Other, Less Popular" bucket and buckets with 0 percent total
		const {otherLessPopular, zeroPercentTotal} = elementList.reduce(
			(accumulator, element, currentIndex) => {
				if (element.name === 'Other, Less Popular') {
					elementList.splice(currentIndex, 1);

					return {
						...accumulator,
						otherLessPopular: element,
					};
				}

				if (element.percentTotal === '0') {
					elementList.splice(currentIndex, 1);

					return {
						...accumulator,
						zeroPercentTotal: element,
					};
				}

				return accumulator;
			},
			{
				otherLessPopular: null,
				zeroPercentTotal: null,
			} as Record<string, SimpleBucketElement | null>
		);

		if (otherLessPopular && zeroPercentTotal) {
			const percentTotal =
				parseInt(otherLessPopular.percentTotal, 10) +
				parseInt(zeroPercentTotal.percentTotal, 10);

			const newCombinedBucket = {
				name: otherLessPopular.name,
				lift: otherLessPopular.lift + zeroPercentTotal.lift,
				percentTotal: percentTotal.toLocaleString(),
				SortProperty: otherLessPopular.lift + zeroPercentTotal.lift,
			};

			elementList.push(newCombinedBucket);
		}
	}

	return elementList;
};

const getSimpleBuckets = (
	attributeName: string,
	attributeColor: string,
	modelSummary: ModelSummary
): SimpleBucket | null => {
	if (attributeName === null || modelSummary === null) {
		return null;
	}

	const predictor = getAttributeByName(attributeName, modelSummary.Predictors);

	if (predictor === null) {
		return null;
	}

	return {
		name: predictor.DisplayName,
		color: attributeColor,
		description: predictor.Description || '',
		elementList: mapElementList(predictor, modelSummary),
	};
};
// #endregion getSimpleBuckets

const getPercent = (percent: string): string => {
	const formattedPercent = +percent;

	if (
		formattedPercent >= 0.95 ||
		(formattedPercent <= 0.95 && formattedPercent >= 0.1)
	) {
		return percent;
	}

	return '<0.1';
};

// #region getDataForAttributeValueChart
interface GetOtherBucketFlagsReturn {
	doOtherBucket: boolean;
	isContinuous: boolean;
}
const getOtherBucketFlags = (
	predictor: Predictor
): GetOtherBucketFlagsReturn => {
	if (IsPredictorBoolean(predictor)) {
		return {
			doOtherBucket: false,
			isContinuous: false,
		};
	}

	for (const bucket of predictor.Elements) {
		if (isPredictorElementCategorical(bucket)) {
			return {
				doOtherBucket: true,
				isContinuous: false,
			};
		}

		if (bucket.LowerInclusive !== null || bucket.UpperExclusive !== null) {
			return {
				doOtherBucket: false,
				isContinuous: true,
			};
		}
	}

	return {
		doOtherBucket: false,
		isContinuous: false,
	};
};

interface ReduceOtherBucketsReturn {
	otherBucketElements: Element[];
	topBucketCandidates: Element[];
}
const reduceOtherBuckets = (
	predictor: Predictor,
	modelSummary: ModelSummary,
	doOtherBucket: boolean
): ReduceOtherBucketsReturn => {
	if (!doOtherBucket) {
		return {
			otherBucketElements: [],
			topBucketCandidates: [],
		};
	}

	return predictor.Elements.reduce(
		(accumulator, bucket) => {
			const bucketName = GetAttributeBucketName(bucket, predictor);

			const percentTotal = (
				(bucket.Count / modelSummary.ModelDetails.TotalLeads) *
				100
			).toFixed(0);

			if (percentTotal < '1' || bucketName.toLowerCase() === 'other') {
				return {
					...accumulator,
					otherBuckets: [...accumulator.otherBucketElements, bucket],
				};
			}

			return {
				...accumulator,
				topCandidates: [...accumulator.topBucketCandidates, bucket],
			};
		},
		{
			otherBucketElements: [],
			topBucketCandidates: [],
		} as ReduceOtherBucketsReturn
	);
};

const getSortProperty = ({
	bucket,
	bucketName,
	name,
	isContinuous,
}: GetSimpleBucketSortPropertyParams): number => {
	if (isContinuous) {
		const {LowerInclusive, UpperExclusive} = bucket;

		return LowerInclusive !== null ? LowerInclusive : UpperExclusive || 0;
	}

	// Only when the attribute is continuous, sorting is increasing order
	if (name === 'Employee Range') {
		if (bucketName.includes('>')) {
			const removedLessThanCharacter = bucketName.substr(1);

			return parseFloat(removedLessThanCharacter.replace(/,/g, ''));
		}

		if (bucketName.includes('-')) {
			const [value = ''] = bucketName.split('-');

			return parseInt(value, 10);
		}
	}

	return bucket.Lift;
};

interface ReduceTopPredictorsParams {
	predictor: Predictor;
	topPredictorElements: Element[];
	modelSummary: ModelSummary;
	isContinuous: boolean;
}

interface ReduceTopPredictorsReturn {
	nullBucket: SimpleBucketElement | null;
	miscBucket: SimpleBucketElement | null;
	elementList: SimpleBucketElementList;
}

const reduceTopPredictors = ({
	predictor,
	topPredictorElements,
	modelSummary,
	isContinuous,
}: ReduceTopPredictorsParams): ReduceTopPredictorsReturn => {
	const name = predictor.DisplayName;

	return topPredictorElements.reduce(
		(accumulator, bucket) => {
			const bucketName = GetAttributeBucketName(bucket, predictor);

			const simpleBucket = {
				name: bucketName,
				lift: bucket.Lift,
				percentTotal: (
					(bucket.Count / modelSummary.ModelDetails.TotalLeads) *
					100
				).toFixed(0),
				SortProperty: getSortProperty({
					bucket,
					bucketName,
					name,
					isContinuous,
				}),
			};

			if (bucket.IsVisible) {
				// Always sort NA bucket to the bottom
				if (NULL_BUCKET_NAMES.includes(bucketName)) {
					return {
						...accumulator,
						nullBucket: {
							...simpleBucket,
							name: NULL_BUCKET_NAME,
						},
					};
				}
				if (simpleBucket.name === MISC_BUCKET_NAME) {
					return {
						...accumulator,
						miscBucket: simpleBucket,
					};
				}

				return {
					...accumulator,
					elementList: [...accumulator.elementList, simpleBucket],
				};
			}

			return accumulator;
		},
		{
			nullBucket: null,
			miscBucket: null,
			elementList: [],
		} as ReduceTopPredictorsReturn
	);
};

const getOtherBucket = (
	otherBucketElements: Element[],
	modelSummary: ModelSummary
): SimpleBucketElement | null => {
	if (isEmpty(otherBucketElements)) {
		return null;
	}

	// Merge "Other" bucket averaged out lift and percentage
	const {otherBucketTotalPercentage, averagedLift} = otherBucketElements.reduce(
		(accumulator, otherBucketElement) => {
			const otherBucketPercentage =
				otherBucketElement.Count / modelSummary.ModelDetails.TotalLeads;

			const otherBucketLift = otherBucketElement.Lift;

			return {
				otherBucketTotalPercentage:
					accumulator.otherBucketTotalPercentage + otherBucketPercentage,
				averagedLift:
					accumulator.averagedLift + otherBucketLift * otherBucketPercentage,
			};
		},
		{
			otherBucketTotalPercentage: 0,
			averagedLift: 0,
		}
	);

	return {
		name: 'Other, Less Popular',
		lift: averagedLift / otherBucketTotalPercentage,
		percentTotal: (otherBucketTotalPercentage * 100).toFixed(0),
		SortProperty: averagedLift / otherBucketTotalPercentage,
	};
};

const getElementListForAttributeValueChart = (
	modelSummary: ModelSummary,
	predictor: Predictor
): SimpleBucketElementList => {
	// Do "Other" bucketing if discrete and not boolean
	const {doOtherBucket, isContinuous} = getOtherBucketFlags(predictor);

	const {otherBucketElements, topBucketCandidates} = reduceOtherBuckets(
		predictor,
		modelSummary,
		doOtherBucket
	);

	const topPredictorElements = doOtherBucket
		? topBucketCandidates
		: predictor.Elements;

	const {elementList, nullBucket, miscBucket} = reduceTopPredictors({
		predictor,
		topPredictorElements,
		modelSummary,
		isContinuous,
	});

	elementList.sort((a, b) => sortBySortProperty({a, b, isContinuous}));

	const nullBucketLength = nullBucket === null ? 0 : 1;
	const otherBucketLength = otherBucketElements.length > 0 ? 1 : 0;
	const miscBucketLength = miscBucket === null ? 0 : 1;

	const currentTotalNumBuckets =
		elementList.length +
		nullBucketLength +
		otherBucketLength +
		miscBucketLength;

	// number that comfortably fit on screen without resizing
	const maxElementsToDisplay = 8;

	if (currentTotalNumBuckets > maxElementsToDisplay) {
		const numToRemove = currentTotalNumBuckets - maxElementsToDisplay;

		const removed = elementList.splice(
			elementList.length - numToRemove,
			numToRemove
		);

		Array.prototype.push.apply(otherBucketElements, removed);
	}

	const otherBucket = getOtherBucket(otherBucketElements, modelSummary);

	// Always sort Other bucket second from bottom
	if (otherBucket !== null) {
		elementList.push(otherBucket);
	}

	// Always sort NULL bucket to the bottom
	if (nullBucket !== null) {
		elementList.push(nullBucket);
	}

	if (miscBucket !== null) {
		elementList.push(miscBucket);
	}

	// DP-932
	if (
		isContinuous &&
		nullBucket !== null &&
		elementList.length === 2 &&
		elementList[0]
	) {
		elementList[0].name = 'Available';
	}

	return elementList;
};

const getDataForAttributeValueChart = (
	attributeName: string,
	attributeColor: string,
	modelSummary: ModelSummary
): SimpleBucket | null => {
	if (attributeName === null || modelSummary === null) {
		return null;
	}

	const predictor = getAttributeByName(attributeName, modelSummary.Predictors);

	if (predictor === null) {
		return null;
	}

	return {
		name: predictor.DisplayName,
		color: attributeColor,
		description: predictor.Description || '',
		elementList: getElementListForAttributeValueChart(modelSummary, predictor),
	};
};
// #endregion getDataForAttributeValueChart

// #region getTopPredictorExport

/*
 * Apparently, excel does not like UTF-8 characters. Handle the current offenders.
 *
 * See: http://i18nqa.com/debug/utf8-debug.html
 */
const cleanupForExcel = (text: string): string =>
	text
		.replace('\u2019', "'")
		.replace('\u201c', '"')
		.replace('\u201d', '"')
		.replace(/ *,/g, '');

interface GetAttributeRowsParams {
	predictor: Predictor;
	modelSummary: ModelSummary;
	isNumericRange: boolean;
	averageConversionRate: number;
}

interface MaxBucket extends Element {
	indexOfMax: number;
}

interface GetAttributeRowsReturn {
	attributeRows: string[][];
	maxBucket: MaxBucket | null;
}

const getAttributeRows = ({
	predictor,
	modelSummary,
	isNumericRange,
	averageConversionRate,
}: GetAttributeRowsParams): GetAttributeRowsReturn => {
	return predictor.Elements.reduce(
		(accumulator, element) => {
			const percentTotal = (
				(element.Count / modelSummary.ModelDetails.TotalLeads) *
				100
			).toFixed(0);

			const isCategorical = isPredictorElementCategorical(element);

			if (isCategorical && percentTotal < '1') {
				return accumulator;
			}

			const lift = element.Lift.toPrecision(2);
			const conversionRate = (element.Lift * averageConversionRate).toFixed(2);

			const description = cleanupForExcel(
				predictor.Description ? predictor.Description : ''
			);

			const attributeValueName = GetAttributeBucketName(element, predictor);

			let attributeValue = cleanupForExcel(attributeValueName);

			if (NOT_AVAILABLE_NAMES.includes(attributeValue.toUpperCase())) {
				attributeValue = NULL_BUCKET_NAME;
			}

			// PLS-352
			attributeValue = `'${attributeValue}'`;

			const predictivePower = (predictor.UncertaintyCoefficient * 100).toFixed(
				2
			);
			const displayName = cleanupForExcel(predictor.DisplayName);

			const attributeRow = [
				predictor.Category,
				displayName,
				attributeValue,
				description,
				percentTotal,
				lift,
				conversionRate,
				predictivePower,
			];

			const newAttributeRows = [...accumulator.attributeRows, attributeRow];
			let newMaxBucket = accumulator.maxBucket;

			if (
				isNumericRange &&
				element.LowerInclusive !== null &&
				newMaxBucket !== null &&
				newMaxBucket.UpperExclusive !== null &&
				element.LowerInclusive >= newMaxBucket.UpperExclusive
			) {
				newMaxBucket = {
					...element,
					indexOfMax: newAttributeRows.length - 1,
				};
			}

			return {
				attributeRows: newAttributeRows,
				maxBucket: newMaxBucket,
			};
		},
		{
			attributeRows: [],
			maxBucket: null,
		} as GetAttributeRowsReturn
	);
};

const getTopPredictorExport = (
	modelSummary: ModelSummary | null
): string[][] | null => {
	if (isNil(modelSummary) || isEmpty(modelSummary.Predictors)) {
		return null;
	}

	const columns: string[] = [
		getString('TOP_PREDICTOR_EXPORT_CATEGORY_LABEL'),
		getString('TOP_PREDICTOR_EXPORT_ATTRIBUTE_NAME_LABEL'),
		getString('TOP_PREDICTOR_EXPORT_ATTRIBUTE_VALUE_LABEL'),
		getString('TOP_PREDICTOR_EXPORT_ATTRIBUTE_DESCRIPTION_LABEL'),
		getString('TOP_PREDICTOR_EXPORT_PERCENT_LEADS_LABEL'),
		getString('TOP_PREDICTOR_EXPORT_LIFT_LABEL'),
		getString('TOP_PREDICTOR_EXPORT_CONVERSION_RATE_LABEL'),
		getString('TOP_PREDICTOR_EXPORT_PREDICTIVE_POWER_LABEL'),
	];

	const indexOfBucketName = 2;

	const totalPredictors = modelSummary.Predictors!.sort(
		sortByUncertaintyCoefficient
	);

	const averageConversionRate =
		modelSummary.ModelDetails.TestingConversions /
		modelSummary.ModelDetails.TestingLeads;

	const numericalFundamental = [
		FUNDAMENTAL_TYPE.NUMERIC,
		FUNDAMENTAL_TYPE.CURRENCY,
	];

	return totalPredictors.reduce(
		(accumulator, predictor) => {
			const {FundamentalType} = predictor;

			const isNumericRange =
				FundamentalType === null
					? false
					: numericalFundamental.includes(
							FundamentalType.toUpperCase() as FUNDAMENTAL_TYPE
					  );

			const hasMatchingExternalTag = hasMatchingAttributeTag(predictor, true);
			const hasMatchingInternalTag = hasMatchingAttributeTag(predictor, false);

			const hasMatchingTag = hasMatchingExternalTag || hasMatchingInternalTag;

			const hasValidBuckets = hasAttributeValidBuckets(
				predictor,
				modelSummary.ModelDetails.TotalLeads
			);

			if (
				predictor.Category &&
				hasMatchingTag &&
				IsAllowedForInsights(predictor) &&
				hasValidBuckets
			) {
				const {attributeRows, maxBucket} = getAttributeRows({
					predictor,
					modelSummary,
					isNumericRange,
					averageConversionRate,
				});

				const newRows = [...accumulator];

				if (maxBucket !== null) {
					const row = newRows[maxBucket.indexOfMax];

					if (row) {
						row[indexOfBucketName] = `'${GetAttributeBucketName(
							maxBucket,
							predictor
						)}'`;
					}
				}

				return [...newRows, ...attributeRows];
			}

			return accumulator;
		},
		[columns]
	);
};
// #endregion getTopPredictorExport

// #endregion getters

export {
	mapChildrenSize,
	mapCategoryChildren,
	mapTopPercentage,
	resetCategoriesActiveClass,
	mapTicks,
	getSuppressedCategories,
	getNumberOfAttributesByCategory,
	getModelChartData,
	getSimpleBuckets,
	getPercent,
	getDataForAttributeValueChart,
	getTopPredictorExport,
};
