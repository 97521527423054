import React from 'common/react-vendor';
import classNames from 'classnames';
import {DNBTypography} from '@dnb-uux-design-system/react';
import styles from './PersonalizationComponent.module.scss';
import {CommonHeaderSize, HeaderType} from '../configs/PersonalizationTypes';

interface ICommonHeader {
	headerType: string;
	size: string;
	title: string;
	description?: string;
}

const CommonHeader = ({
	headerType,
	size,
	title,
	description,
}: ICommonHeader): React.ReactElement => {
	return (
		<div
			className={classNames(styles.commonHeader, {
				[styles.headerType!]: headerType === HeaderType.Steps,
			})}>
			<DNBTypography
				variant='h6'
				sx={{color: (theme) => theme.colors.ColorGrayPrimary}}>
				{title}
			</DNBTypography>
			<DNBTypography
				sx={{color: (theme) => theme.colors.ColorGraySecondary}}
				variant={
					size === CommonHeaderSize.CompactBody ? 'compact-body' : 'subtitle2'
				}>
				{description}
			</DNBTypography>
		</div>
	);
};

export default CommonHeader;
