/* eslint-disable no-param-reassign */
import {createSlice} from 'common/node_modules/@reduxjs/toolkit';
import {
	AttributeFormScene,
	IAttributeData,
	IAtttributeState,
} from '../schemaTypes';
import {attributeNamespace} from '../consts';

export const attributeSlice = createSlice({
	name: attributeNamespace,
	initialState: {
		submitting: false,
		uploadedData: {},
		scene: AttributeFormScene.Add,
		timezones: [],
	} as IAtttributeState,
	reducers: {
		initData(state) {
			state.scene = AttributeFormScene.Add;
			state.uploadedData = {};
		},
		uploadFileData(
			state,
			{payload}: {payload: {[filename: string]: Array<IAttributeData>}}
		) {
			Object.assign(state.uploadedData, payload);
		},
		deleteFileData(state, {payload: filename}: {payload: string}) {
			delete state.uploadedData[filename];
		},
		changeScene(state, {payload}: {payload: IAtttributeState['scene']}) {
			state.scene = payload;
		},
		setAttributesBegin(state) {
			state.submitting = true;
		},
		setAttributesEnd(state) {
			state.submitting = false;
		},
	},
});

export const {
	initData,
	uploadFileData,
	deleteFileData,
	changeScene,
	setAttributesBegin,
	setAttributesEnd,
} = attributeSlice.actions;

export default attributeSlice.reducer;
/* eslint-enable no-param-reassign */
