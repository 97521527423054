import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import {getJobs, getJob, getYarnAppsApplication} from './jobs.queries';
import type {GetJobsParams} from './jobs.queries';
import type {StepsCounts, JobStep, Job} from './jobs.types';
import {JobStepStatus} from './jobs.enums';
import type {JobsStore, JobStore} from '../stores/jobs';

const getInitialStepsCounts = (): StepsCounts => ({
	load_data: 0,
	match_data: 0,
	generate_insights: 0,
	create_global_model: 0,
	create_global_target_market: 0,
	score_training_set: 0,
});

const reduceQueryParams = (key: string, params?: string[] | null): string => {
	if (isEmpty(params)) {
		return '';
	}

	return params!.reduce(
		(queryParams, param) => `${queryParams}&${key}=${param}`,
		''
	);
};

const getStepByStatuses = (
	steps: JobStore['steps'],
	statuses: JobStepStatus[]
): JobStore['name'] | undefined => {
	const step = steps?.find(({stepStatus}) => statuses.includes(stepStatus));

	return step?.name;
};

const isScoreStep = (name = ''): boolean => {
	const scoreNames = ['generate_insights', 'create_global_target_market'];
	return scoreNames.includes(name) && name.includes('score_training_set');
};

const reduceCompletedSteps = (steps: JobStep[]): GetStepsCompletedReturn =>
	steps.reduce(
		({stepsCompleted, stepsCounts}, {stepStatus, name}) => {
			if (stepStatus === JobStepStatus.Completed) {
				if (isScoreStep(name)) {
					return {
						stepsCompleted,
						stepsCounts: {
							...stepsCounts,
							score_training_set: stepsCounts.score_training_set + 1,
						},
					};
				}

				return {
					stepsCompleted: [...stepsCompleted, name],
					stepsCounts: {
						...stepsCounts,
						[name]: stepsCounts[name] + 1,
					},
				};
			}

			return {
				stepsCompleted,
				stepsCounts,
			};
		},
		{
			stepsCompleted: [] as string[],
			stepsCounts: getInitialStepsCounts(),
		}
	);

interface GetStepsCompletedReturn {
	stepsCompleted: string[];
	stepsCounts: StepsCounts;
}

const getStepsCompleted = (
	steps: JobStore['steps']
): GetStepsCompletedReturn => {
	if (isEmpty(steps)) {
		return {
			stepsCompleted: [],
			stepsCounts: getInitialStepsCounts(),
		};
	}

	return reduceCompletedSteps(steps as JobStep[]);
};

interface GetCompletedStepTimesParams {
	steps: JobStore['steps'];
	stepRunning?: string;
	stepsCompleted: string[];
	stepsCounts: StepsCounts;
}
const getCompletedStepTimes = ({
	steps = [],
	stepRunning,
	stepsCompleted,
	stepsCounts,
}: GetCompletedStepTimesParams): JobStore['completedTimes'] => {
	const completedTimes = {
		load_data: null,
		match_data: null,
		generate_insights: null,
		create_global_model: null,
		create_global_target_market: null,
		score_training_set: null,
	};

	if (isEmpty(steps)) {
		return completedTimes;
	}

	let currStepIndex = 0;

	const stepsKeys = Object.keys(completedTimes) as (keyof StepsCounts)[];

	return stepsKeys.reduce(
		(accumulator, key) => {
			if (stepRunning !== key && stepsCompleted.includes(key)) {
				currStepIndex += stepsCounts[key];
				const step = steps[currStepIndex - 1];

				return {
					...accumulator,
					[key]: step ? step.endTimestamp : null,
				};
			}

			return {
				...accumulator,
			};
		},
		{...completedTimes}
	);
};

const mapJobStore = (job: Job): JobStore => {
	const {inputs, outputs, steps} = job;

	const stepRunning = getStepByStatuses(steps, [JobStepStatus.Running]);
	const {stepsCompleted, stepsCounts} = getStepsCompleted(steps);

	return {
		...job,
		timestamp: job.startTimestamp,
		note: job.note || '',
		status: job.jobStatus,
		source: inputs?.SOURCE_DISPLAY_NAME || null,
		subJobs: job.subJobs || [],
		steps: steps || [],
		modelName: inputs?.MODEL_DISPLAY_NAME || null,
		modelId: inputs?.MODEL_ID || outputs?.MODEL_ID || null,
		modelType: inputs?.MODEL_TYPE || null,
		sourceFileExists: inputs ? inputs.SOURCE_FILE_EXISTS === 'true' : null,
		isDeleted: inputs ? inputs.MODEL_DELETED === 'true' : null,
		applicationLogUrl: outputs?.YARN_LOG_LINK_PATH || null,
		stepRunning,
		stepsCompleted,
		stepFailed:
			getStepByStatuses(steps, [
				JobStepStatus.Failed,
				JobStepStatus.Cancelled,
			]) || [],
		completedTimes: getCompletedStepTimes({
			steps,
			stepRunning,
			stepsCompleted,
			stepsCounts,
		}),
	};
};

const getStepRunning = (
	stepRunning: string | undefined | null,
	stepsCompleted: string[]
): string | undefined | null => {
	if (stepRunning && isScoreStep(stepRunning)) {
		return 'score_training_set';
	}

	if (
		stepRunning === 'load_data' &&
		stepsCompleted.includes('generate_insights')
	) {
		return 'generate_insights';
	}

	return stepRunning;
};

const getAllJobs = async (params: GetJobsParams): Promise<JobsStore> =>
	getJobs(params).then((jobsResponse) => {
		if (isEmpty(jobsResponse)) {
			return [];
		}

		return sortBy(jobsResponse, 'startTimestamp').map(mapJobStore);
	});

const getJobStatus = async (
	jobId?: string,
	jobIdType = ''
): Promise<JobStore | undefined> =>
	getJob(jobId, jobIdType).then((jobResponse) => {
		if (isNil(jobResponse)) {
			return;
		}

		const jobStore = mapJobStore(jobResponse);

		let {stepRunning, stepsCompleted} = jobStore;

		stepRunning = getStepRunning(stepRunning, stepsCompleted);

		return {
			...jobStore,
			stepRunning,
		};
	});

const getJobStatusFromApplicationId = async (
	applicationId: string
): Promise<JobStore | undefined> =>
	getYarnAppsApplication(applicationId).then((applicationResponse) => {
		if (isNil(applicationResponse)) {
			return;
		}

		const jobStore = mapJobStore(applicationResponse);

		let {stepRunning, stepsCompleted} = jobStore;

		stepRunning = getStepRunning(stepRunning, stepsCompleted);

		return {
			...jobStore,
			stepRunning,
		};
	});

export {
	reduceQueryParams,
	getAllJobs,
	getJobStatus,
	getJobStatusFromApplicationId,
};
