import {getAptrinsicIdentifyData} from 'common/lib/aptrinsic/aptrinsic.helpers';

angular
	.module('common.datacloud.query.ac360', [])
	.controller(
		'AC360Ctrl',
		function ($location) {
			const vm = this;
			vm.$onInit = function () {
				// Construct iframe src
				vm.src = `/account360/?${new URLSearchParams(
					$location.search()
				).toString()}`;
				const a360Iframe = document.getElementById('account360-iframe');

				// Define closure for onLoad event listener
				vm.postToken = async function () {
					const a360ContentWindow = a360Iframe.contentWindow;

					const message = await getAptrinsicIdentifyData();

					vm.enableNewAC360 =
						message.feature_flags.includes('EnableAc360Redesign') &&
						message.feature_flags.includes('EnterpriseIntegration');

					a360Iframe.setAttribute(
						'class',
						vm.enableNewAC360 ? 'enableNewAC360' : ''
					);

					a360ContentWindow.postMessage(message, a360Iframe.src);
				};

				// Pass auth token to iframe via postMessage
				a360Iframe.addEventListener('load', vm.postToken);
			};

			// Tear down the onLoad event listener
			vm.$onDestroy = function () {
				vm.removeEventListener('load', vm.postToken);
			};
		});
