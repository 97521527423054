/**
 * TODO: Delete this angular file once all components that uses dateAttributeEdit gets refactor.
 * TODO: DELETE this file once there are no more instances of <date-attribute-edit />.
 */

import {react2angular} from 'common/react-vendor';
import {DateAttributeEditWithAngularProps} from './DateAttributeEdit/DateAttributeEdit';
import './date-attribute-edit.scss';

angular
	.module('common.datacloud.query.builder.tree.edit.date.attribute', [
		'common.datacloud.query.builder.tree.edit.transaction.edit.numerical.range',
		'common.datacloud.query.builder.tree.edit.transaction.edit.date.range',
		'angularMoment',
	])
	.component(
		'dateAttributeEdit',
		react2angular(DateAttributeEditWithAngularProps, [
			'bucketrestriction',
			'type',
			'onnumericalrangevalidation',
			'onbucketrestrictionchange',
		])
	);
