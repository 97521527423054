import LocalStorageUtility from 'common/widgets/utilities/local-storage.utility';

export const clearSession = () => {
	LocalStorageUtility.clear();
	window.location.reload();
};

export const handleResponseErrors = (status) => {
	if (status === 401) {
		this.ClearSession();
	}
};
