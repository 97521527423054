/**
 * TODO: Delete this angular file once all components that uses dateRangeDirective gets refactor.
 * TODO: DELETE this file once there are no more instances of <date-range-directive />.
 */

import {react2angular} from 'common/react-vendor';
import {DateRangeWithAngularProps} from '../DateRange/DateRange';

angular
	.module(
		'common.datacloud.query.builder.tree.edit.transaction.edit.date.range',
		[]
	)
	.component(
		'dateRangeDirective',
		react2angular(DateRangeWithAngularProps, [
			'bucketrestriction',
			'config',
			'showfrom',
			'showto',
			'changed',
			'fromlabel',
			'tolabel',
			'showinline',
			'startplaceholder',
			'endplaceholder',
			'hidepristineerror',
			'hideicon',
			'inputclass',
		])
	);
