import {BucketCmp, TransactionKey} from '../../../query.enums';
import {CompareData, InputRangeConfig} from '../../../query.types';

const choicesList: CompareData[] = [
	{name: BucketCmp.ANY_CAPITALIZE, displayName: 'Any'},
	{name: BucketCmp.GREATER_THAN, displayName: 'Greater than'},
	{
		name: BucketCmp.GREATER_OR_EQUAL,
		displayName: 'Greater than or Equal to',
	},
	{name: BucketCmp.LESS_THAN, displayName: 'Less than'},
	{name: BucketCmp.LESS_OR_EQUAL, displayName: 'Less than or Equal to'},
	{name: BucketCmp.GT_AND_LT, displayName: 'Between (Value1, Value2)'},
	{name: BucketCmp.GT_AND_LTE, displayName: 'Between (Value1, Value2]'},
	{name: BucketCmp.GTE_AND_LT, displayName: 'Between [Value1, Value2)'},
	{name: BucketCmp.GTE_AND_LTE, displayName: 'Between [Value1, Value2]'},
];

const compareList: CompareData[] = [
	{name: BucketCmp.EVER, displayName: 'Ever'},
	{name: BucketCmp.IN_CURRENT_PERIOD, displayName: 'Current'},
	{name: BucketCmp.WITHIN, displayName: 'Previous'},
	{name: BucketCmp.PRIOR_ONLY, displayName: 'Only Prior to Last'},
	{name: BucketCmp.BETWEEN, displayName: 'Between Last'},
	{name: BucketCmp.BETWEEN_DATE, displayName: 'Between'},
	{name: BucketCmp.BEFORE, displayName: 'Before'},
	{name: BucketCmp.AFTER, displayName: 'After'},
];

const amtConfig: InputRangeConfig = {
	from: {
		name: 'from-amt',
		index: 0,
		type: TransactionKey.Amt,
		min: '0',
		max: '',
	},
	to: {
		name: 'to-amt',
		index: 1,
		type: TransactionKey.Amt,
		min: '0',
		max: '',
	},
};

const qtyConfig: InputRangeConfig = {
	from: {
		name: 'from-qty',
		index: 0,
		type: TransactionKey.Qty,
		min: '0',
	},
	to: {
		name: 'to-qty',
		index: 1,
		type: TransactionKey.Qty,
		min: '0',
	},
};

const numericTimeConfig: InputRangeConfig = {
	from: {
		name: 'from-period',
		index: 0,
		type: TransactionKey.Time,
		min: '0',
		max: '',
		step: 1,
		pattern: '\\d*',
	},
	to: {
		name: 'to-period',
		index: 1,
		type: TransactionKey.Time,
		min: '0',
		max: '',
		step: 1,
		pattern: '\\d*',
	},
};

const timeConfig: InputRangeConfig = {
	from: {
		name: 'from-time',
		index: 0,
		type: TransactionKey.Time,
		visible: true,
		step: 1,
	},
	to: {
		name: 'to-time',
		index: 1,
		type: TransactionKey.Time,
		visible: true,
		step: 1,
	},
};

export {
	choicesList,
	compareList,
	qtyConfig,
	amtConfig,
	numericTimeConfig,
	timeConfig,
};
