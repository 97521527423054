import httpService from 'common/app/http/http-service';
import Observer from 'common/app/http/observer';
import {isEmpty} from 'common/app/utilities/ObjectUtilities';
import messageService from 'common/app/utilities/message-service';
import Message, {NOTIFICATION} from 'common/app/utilities/message';
import ConnectorService, {
	FACEBOOK,
	GOOGLE_ADS,
	LINKEDIN,
	MARKETO,
	PARDOT,
} from './connectors.service';

export const FIELD_MAPPING_MARKETO = 'external_field_mapping';
export const FIELD_MAPPING_OUTREACH = 'external_lattice_>_outreach_fields';
export const FIELD_MAPPING_PARDOT = 'external_fieldmappings';

class ConfWindowService {
	constructor() {
		this.solutionInstanceConfig = {
			orgType: null,
			id: null,
			accessToken: null,
			registerLookupIdMap: null,
			fieldMapping: null,
			system: null,
			connectorName: null,
		};
	}

	static guidGenerator() {
		const S4 = function () {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		};
		return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
	}

	static parseLatticeField(field) {
		return field.replace('CONTACT:', '');
	}

	static isSelectionEmpty(fieldMappingObj) {
		return !!(!fieldMappingObj || isEmpty(fieldMappingObj));
	}

	getSolutionInstanceConfig() {
		return this.solutionInstanceConfig;
	}

	getTrayAuthValues(solutionInstance, opts = {}) {
		const {
			system = null,
			register = true,
			callback = null,
			registerCallback = null,
		} = opts;
		const solutionInstanceId = solutionInstance.id;
		const externalId = 'external_http_client_authentication';
		const observer = new Observer(
			(response) => {
				if (response.data) {
					const {authValues, configValues} = response.data.solutionInstance;
					const orgTypeTmp =
						this.solutionInstanceConfig.orgType === GOOGLE_ADS
							? 'google_ads'
							: this.solutionInstanceConfig.orgType;
					const authenticationExternalId = `external_${orgTypeTmp.toLowerCase()}_authentication`;

					const externalAuthentication = authValues.filter(function (
						authValue
					) {
						return authValue.externalId === authenticationExternalId;
					});
					const trayAuthenticationId =
						externalAuthentication &&
						externalAuthentication[0] &&
						externalAuthentication[0].authId
							? externalAuthentication[0].authId
							: null;
					const authentication = response.data.authentications.filter(function (
						auth
					) {
						return auth.node.id === trayAuthenticationId;
					});

					const nodeName =
						authentication &&
						authentication[0] &&
						authentication[0].node &&
						authentication[0].node.name
							? authentication[0].node.name
							: null;
					if (register) {
						this.registerLookupIdMap(
							trayAuthenticationId,
							nodeName,
							this.getLookupIdMapConfiguration(authentication[0], configValues),
							solutionInstance,
							system,
							registerCallback
						);
					}
					if (
						this.solutionInstanceConfig.orgType === LINKEDIN ||
						this.solutionInstanceConfig.orgType === FACEBOOK
					) {
						this.updatePlsSolutionInstance(
							response.data.solutionInstance.id,
							response.data.solutionInstance.name,
							{
								externalId,
								authId: trayAuthenticationId,
							},
							solutionInstance.orgType
						);
					} else {
						this.updatePlsSolutionInstance(
							response.data.solutionInstance.id,
							response.data.solutionInstance.name,
							{},
							solutionInstance.connectorName
						);
					}
					if (callback && typeof callback === 'function') {
						callback(
							{
								orgName: nodeName,
								solutionInstanceId: solutionInstance.id,
								trayAuthenticationId,
								configValues: this.getLookupIdMapConfigValues(configValues),
							},
							response.data
						);
					}
					httpService.unsubscribeObservable(observer);
				}
			},
			(error) => {
				console.error(`Error retrieving authValues: ${JSON.stringify(error)}`);
			}
		);
		httpService.get(`/tray/solutionInstances/${solutionInstanceId}`, observer, {
			useraccesstoken: this.solutionInstanceConfig.accessToken,
		});
	}

	deleteSolutionInstance() {
		const params = {
			solutionInstanceId: this.solutionInstanceConfig.id,

			authenticationId: this.solutionInstanceConfig.accessToken,
		};

		httpService.delete(
			`/pls/tray/solutionInstance/${this.solutionInstanceConfig.connectorName}/delete`,
			{},
			{},
			params
		);
	}

	registerLookupIdMap(
		trayAuthenticationId,
		trayAuthenticationName,
		lookupIdMapConfiguration,
		solutionInstance,
		system,
		callback
	) {
		const observer = new Observer(
			(response) => {
				if (response.data && response.data.configId) {
					httpService.unsubscribeObservable(observer);
					console.log('Registered lookupIdMap', response.data);
					callback(response.data);
				} else {
					console.log('response', response);
				}
			},
			(error) => {
				callback?.(error);

				this.deleteSolutionInstance();
			}
		);

		const connector =
			ConnectorService.getConnector(solutionInstance.connectorName) || {};
		const orgTypeTmp =
			connector.systemName || this.solutionInstanceConfig.orgType;

		const lookupIdMap = {
			attributeSetName: system?.attributeSetName || null,
			orgId: lookupIdMapConfiguration.orgId,
			orgName: lookupIdMapConfiguration.orgName,
			accountId: lookupIdMapConfiguration.accountId || null,
			externalSystemType: lookupIdMapConfiguration.externalSystemType || 'MAP',
			configValues: lookupIdMapConfiguration.configValues || null,
			externalSystemName: orgTypeTmp,
			externalAuthentication: {
				solutionInstanceId: this.solutionInstanceConfig.id,
				trayWorkflowEnabled: true,
				trayAuthenticationId,
			},
			exportFieldMappings: this.constructExportFieldMappings(),
			...system,
		};

		httpService.post('/pls/lookup-id-mapping/register', lookupIdMap, observer);
	}

	constructExportFieldMappings() {
		if (ConnectorService.canEditMapping(this.solutionInstanceConfig.orgType)) {
			return Object.keys(this.solutionInstanceConfig.fieldMapping).map(
				(leftMapping) => ({
					sourceField: ConfWindowService.parseLatticeField(leftMapping),
					destinationField:
						this.solutionInstanceConfig.fieldMapping[leftMapping],
					overwriteValue: false,
				})
			);
		}
		return [];
	}

	updateTraySolutionInstance(
		solutionInstanceId,
		solutionInstanceName,
		authValues
	) {
		const observer = new Observer((response) => {
			if (response.data) {
				httpService.unsubscribeObservable(observer);
			}
		});
		httpService.put(
			`/tray/solutionInstances/${solutionInstanceId}`,
			{
				solutionInstanceName,
				authValues,
			},
			observer,
			{useraccesstoken: this.solutionInstanceConfig.accessToken}
		);
	}

	updatePlsSolutionInstance(
		solutionInstanceId,
		solutionInstanceName,
		authValues,
		systemName
	) {
		const observer = new Observer((response) => {
			if (response.data) {
				httpService.unsubscribeObservable(observer);
			}
		});
		httpService.post(
			`/pls/tray/solutionInstance/${systemName}/update`,
			{
				solutionInstanceId,
				instanceName: solutionInstanceName,
			},
			observer,
			{useraccesstoken: this.solutionInstanceConfig.accessToken}
		);
	}

	updateSystem = () => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					resolve(response.data || null);
					httpService.unsubscribeObservable(observer);
				},
				(error) => {
					console.error('Error registering lookupIdMap ', error);
					reject(error);
				}
			);

			const lookupIdMap = this.solutionInstanceConfig.system;
			lookupIdMap.exportFieldMappings = this.constructExportFieldMappings();
			console.log(`Update LookupIdMap:${lookupIdMap}`);

			httpService.put(
				`/pls/lookup-id-mapping/config/${lookupIdMap.configId}`,
				lookupIdMap,
				observer
			);
		});
	};

	verifyFieldMapping(fieldMappingObj, externalId) {
		const systemName = this.solutionInstanceConfig.orgType;
		const errors = {};
		if (ConnectorService.canEditMapping(systemName)) {
			const mappedFields = new Set();
			if (ConfWindowService.isSelectionEmpty(fieldMappingObj)) {
				const msg = `${'CDP field and'} ${systemName} ${'field have both to be mapped.'}`;
				errors[externalId] = msg;
				messageService.sendMessage(
					new Message(null, NOTIFICATION, 'error', '', msg)
				);
				return errors;
			}
			const keys = Object.keys(fieldMappingObj);
			keys.forEach((leftMapping) => {
				if (leftMapping === '[object Object]') {
					const msgBoth = `${'The CDP field and'} ${systemName} ${'field must both be mapped'}`;
					errors[externalId] = msgBoth;
					messageService.sendMessage(
						new Message(null, NOTIFICATION, 'error', '', msgBoth)
					);
				}
				if (mappedFields.has(fieldMappingObj[leftMapping])) {
					const msgMultiple = `${'The'} ${systemName} ${'field'} ${
						fieldMappingObj[leftMapping]
					} ${'has been mapped multiple times.'}`;
					errors[externalId] = msgMultiple;
					messageService.sendMessage(
						new Message(null, NOTIFICATION, 'error', '', msgMultiple)
					);
				}
				mappedFields.add(fieldMappingObj[leftMapping]);
				return errors;
			});
			const mandatory = ConnectorService.areMandatoryMapped(
				systemName,
				mappedFields
			);
			if (mandatory !== '') {
				const msgMandatory = `Required attributes for ${systemName}: ${mandatory}`;
				errors[externalId] = msgMandatory;
				messageService.sendMessage(
					new Message(null, NOTIFICATION, 'error', '', msgMandatory)
				);
				return errors;
			}
			this.solutionInstanceConfig.fieldMapping = fieldMappingObj;
		}
		return errors;
	}

	getConfigValues() {
		if (this.solutionInstanceConfig.orgType === 'liveramp') {
			const connectorName =
				this.solutionInstanceConfig.connectorName.toLowerCase();

			return {
				external_dsp: `"${connectorName}"`,
			};
		}

		return {};
	}

	getLookupIdMapConfiguration(authentication, configValues) {
		const trayAuthenticationName =
			authentication && authentication.node
				? authentication.node.name
				: `${this.solutionInstanceConfig.orgType}_${new Date().getTime()}`;
		const remappedConfigValues = this.getLookupIdMapConfigValues(configValues);
		switch (this.solutionInstanceConfig.orgType) {
			case MARKETO: {
				const customFields = JSON.parse(authentication.node.customFields);
				return {
					orgId:
						customFields && customFields.identification
							? customFields.identification.marketo_org_id
							: ConfWindowService.guidGenerator(),
					orgName: trayAuthenticationName,
					externalSystemType: 'MAP',
				};
			}
			case GOOGLE_ADS:
			case LINKEDIN:
			case FACEBOOK: {
				const {value: externalAdAccount} =
					configValues.find(function (configValue) {
						return configValue.externalId === 'external_ad_account';
					}) || {};
				const adAccount = externalAdAccount
					? JSON.parse(externalAdAccount)
					: ConfWindowService.guidGenerator();
				return {
					orgId: adAccount,
					orgName: trayAuthenticationName,
					externalSystemType: 'ADS',
				};
			}
			case 'liveramp': {
				const externalDspInputs = configValues?.find(
					(item) => item.externalId === 'external_dsp_inputs'
				);
				const externalDspInputsValues =
					typeof externalDspInputs.value === 'string'
						? JSON.parse(externalDspInputs.value)
						: externalDspInputs.value;
				const orgName =
					externalDspInputsValues?.connection_name || trayAuthenticationName;
				return {
					orgId: ConfWindowService.guidGenerator(),
					orgName,
					externalSystemType: 'DSP',
					configValues: remappedConfigValues,
				};
			}
			default: {
				return {
					orgId: ConfWindowService.guidGenerator(),
					orgName: trayAuthenticationName,
					externalSystemType: 'MAP',
					configValues: remappedConfigValues,
				};
			}
		}
	}

	getLookupIdMapConfigValues(configValues) {
		switch (this.solutionInstanceConfig.orgType) {
			case PARDOT: {
				const configValuesToFilterFor =
					ConnectorService.getConnectorTrayConfigValuesToSave(
						this.solutionInstanceConfig.connectorName,
						false
					) || [];
				const filteredConfigValues = configValues.filter((a) =>
					configValuesToFilterFor.some(
						(b) => a.externalId === `external_${b.id}`
					)
				);
				const remappedConfigValues =
					this.convertTrayConfigValuesToLookupIdMapConfigValues(
						filteredConfigValues,
						true
					);
				return remappedConfigValues;
			}
			case 'liveramp': {
				// TODO: Need to redesign all the filtering because
				// of its complexity.
				// Find the config values
				const externalDspInputs = configValues?.find(
					(item) => item.externalId === 'external_dsp_inputs'
				);
				// Parse the config values
				const externalDspInputsValues =
					typeof externalDspInputs.value === 'string'
						? JSON.parse(externalDspInputs.value)
						: externalDspInputs.value;
				// Convert the config values into a more complicated datastructure
				const mappedConfigValues = Object.keys(
					externalDspInputsValues || {}
				).map((key) => {
					return {externalId: key, value: externalDspInputsValues[key]};
				});

				const isDatastore = externalDspInputsValues?.is_data_store;
				// Filter for config values that need to be persisted
				const configValuesToFilterFor =
					ConnectorService.getConnectorTrayConfigValuesToSave(
						this.solutionInstanceConfig.connectorName,
						isDatastore
					) || [];
				const filteredConfigValues = mappedConfigValues.filter((a) =>
					configValuesToFilterFor.some((b) => a.externalId === b.id)
				);

				// Collapse the datastucture into a single object of config values
				const remappedConfigValues =
					this.convertTrayConfigValuesToLookupIdMapConfigValues(
						filteredConfigValues,
						false
					);
				return remappedConfigValues;
			}
			default: {
				return {};
			}
		}
	}

	convertTrayConfigValuesToLookupIdMapConfigValues(
		trayConfigValues,
		stripExternalTextFromKey
	) {
		return trayConfigValues.reduce(function (accumulator, configValue) {
			const key = stripExternalTextFromKey
				? configValue.externalId.replace('external_', '')
				: configValue.externalId;
			accumulator[key] =
				typeof configValue.value === 'string'
					? configValue.value.trim()
					: configValue.value;
			return accumulator;
		}, {});
	}
}

const instance = new ConfWindowService();

export default instance;
