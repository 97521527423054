import {
	AdminRouteMap,
	NoAdminRouteMap,
	ROUTE_PAGE,
	setMultipleStates,
} from 'common/app/utilities/AdminPageUtility';
import {loadCategoryMetadata} from '../../datacloud.service.vanilla';
import template from './enable.component.html';
import {Banner} from '../../../../app/services/BannerService';
import AttrConfigService from '../AttrConfigService';
import AttrConfigStore from '../AttrConfigStore';

angular
	.module('common.attributes.enable', [])
	.config(function ($stateProvider) {
		$stateProvider.states = setMultipleStates;

		const enableAttributesNames = [
			AdminRouteMap.get(ROUTE_PAGE.ENABLE_ATTRIBUTES),
			NoAdminRouteMap.get(ROUTE_PAGE.ENABLE_ATTRIBUTES),
		];

		$stateProvider.states(enableAttributesNames, {
			url: '/enable/:section/:category/:subcategory',
			params: {
				section: {
					dynamic: false,
					value: 'Segmentation',
				},
				category: {
					dynamic: false,
					value: '',
				},
				subcategory: {
					dynamic: true,
					value: '',
				},
			},
			onExit: function () {
				AttrConfigStore.reset();
			},
			resolve: {
				categoryMetadata: loadCategoryMetadata,
				overview: () =>
					AttrConfigService.getOverview('usage').then((r) => r.data || []),
				config: [
					'$state',
					'$stateParams',
					'overview',
					'categoryMetadata',
					async function ($state, $stateParams, overview, categoryMetadata) {
						const {section} = $stateParams;
						let {category} = $stateParams;

						const {Categories} = overview.Selections.find(
							(item) => item.DisplayName === section
						);

						if (!category) {
							category = categoryMetadata.reduce((fin, cur) =>
								cur.uIOrderMyData < fin.uIOrderMyData ? cur : fin
							).legacyDisplayName;
						}

						AttrConfigStore.set('category', category);
						AttrConfigStore.set('categories', Categories);

						const res = await AttrConfigService.getConfig('usage', category, {
							usage: section,
						});
						const config = res.data || [];
						AttrConfigStore.setData('config', config);
						return config;
					},
				],
				reportstatus: () =>
					AttrConfigService.getProfileReportStatus().then((r) => r.data || []),
			},
			views: {
				'subsummary@': 'attrSubheader',
				'main@': {component: 'attrEnable'},
				'adminSidebar@': {component: 'adminSidebar'},
			},
		});
	})
	.component('attrEnable', {
		template,
		bindings: {
			overview: '<',
			config: '<',
			reportstatus: '<',
			categoryMetadata: '<',
		},
		controller($q, $scope, $state, $stateParams) {
			const vm = this;

			vm.store = AttrConfigStore;
			vm.filters = vm.store.get('filters');
			vm.params = $stateParams;
			vm.filtersChange = (f) => {
				AttrConfigStore.set('filters', f);
				vm.filters = f;
				$scope.$apply();
			};
			vm.showModal = false;

			vm.save = function () {
				const payload = vm.store.generatePayload();

				vm.store.saveConfig($state.current.name, $stateParams).then(() => {
					$state.reload();
				});
			};

			vm.uiCanExit = () => {
				if (!vm.store.isChanged()) {
					return true;
				}

				const deferred = $q.defer();

				vm.onConfirm = () => {
					deferred.resolve(true);
					vm.showModal = false;
				};
				vm.closeModal = () => {
					deferred.reject('user cancelled action');
					vm.showModal = false;
					HideSpinner();
				};

				vm.showModal = true;

				return deferred.promise;
			};

			Banner.clear('info');

			vm.$onInit = () => {
				vm.categories = vm.store.get('categories');
			};
		},
	});
