import isNil from 'lodash/isNil'; // TODO: Use lodash_es to improve bundle size https://www.blazemeter.com/blog/import-lodash-libraries
import {CacheStorage} from '../../hooks/useCache';

/**
 * Function to cache the promises response in
 * a singleton pattern to prevent executing
 * promises multiple times.
 *
 * Some axios request are being made in
 * angular services, since angular executes code
 * multiple times and doesn't have a right state
 * manager as React we need this utility.
 *
 * Once we fully migrate to React we might remove
 * this utility since React makes easier the fetches.
 */
let singletonCache = {};

const getSingletonCache = (): Record<string, unknown> => {
	return {...singletonCache};
};

const setSingletonCache = (newCache: Record<string, unknown>): void => {
	singletonCache = {
		...singletonCache,
		...newCache,
	};
};

const CACHE_STORAGE_KEY = 'cacheUtility';

type WindowStorage = 'localStorage' | 'sessionStorage';

const getStorageCache = (storage: WindowStorage): Record<string, unknown> => {
	const data = window[storage].getItem(CACHE_STORAGE_KEY) || '{}';

	return JSON.parse(data);
};

const setStorageCache = (
	newCache: Record<string, unknown>,
	storage: WindowStorage
): void => {
	const stringifyCache = JSON.stringify(newCache);

	window[storage].setItem(CACHE_STORAGE_KEY, stringifyCache);
};

const clearCacheStorage = (): void => {
	singletonCache = {};
	window.localStorage.removeItem(CACHE_STORAGE_KEY);
	window.sessionStorage.removeItem(CACHE_STORAGE_KEY);
};

const getCache = (storage: CacheStorage): Record<string, unknown> =>
	storage === 'singleton' ? getSingletonCache() : getStorageCache(storage);

const getKeyCacheData = <T = undefined>(
	key: string,
	storage: CacheStorage
): T => getCache(storage)[key] as T;

const setKeyCacheData = <Data>(
	key: string,
	storage: CacheStorage,
	data: Data
): void => {
	const storageCacheObject = getCache(storage);

	const newCache = {
		...storageCacheObject,
		[key]: data,
	};

	if (storage === 'singleton') {
		setSingletonCache(newCache);
	} else {
		setStorageCache(newCache, storage);
	}
};

const clearKeyCacheData = (key: string, storage: CacheStorage): void => {
	const storageCacheObject = getCache(storage);
	const newCache = {
		...storageCacheObject,
	};

	delete newCache[key];

	if (storage === 'singleton') {
		setSingletonCache(newCache);
	} else {
		setStorageCache(newCache, storage);
	}
};

interface CacheQueryConfig {
	refetch?: boolean;
	storage?: CacheStorage;
}

const cacheQueryResponse = async <Response>(
	queryKey: string,
	queryFn: () => Promise<Response>,
	config?: CacheQueryConfig
): Promise<Response> => {
	const {refetch = false, storage = 'singleton'} = config || {};

	const cacheObject = getCache(storage);

	if (!isNil(cacheObject[queryKey]) && !refetch) {
		return cacheObject[queryKey] as Response;
	}

	return queryFn().then((response) => {
		setKeyCacheData(queryKey, storage, response);

		return response;
	});
};

export {
	cacheQueryResponse,
	getCache,
	clearCacheStorage,
	getKeyCacheData,
	setKeyCacheData,
	clearKeyCacheData,
};
