import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {
	getQueryProperty,
	dispatchQueryPropertyValue,
} from 'common/stores/query';
import {DataCollectionStatusResponse} from 'common/components/datacloud/query/queryService.queries';
import {
	cacheQueryResponse,
	getKeyCacheData,
} from 'common/app/utilities/cacheUtility/cacheUtility';
import {getSegments} from 'common/components/datacloud/segment/segment.queries';
import NgState from 'atlas/ng-state';
import {AngularStateList} from 'atlas/webInsights/configs/WebInsightsConst';
import {
	SEGMENTS_MARKETPLACE,
	SEGMENTS_NEW,
	NavItems,
	SEGMENTS_CHILDROUTES,
} from '../header/components/page-navigation.utils';
import StateHistory from '../../StateHistory';
import {
	MyDataStates,
	myDataItem,
	segmentsItem,
	modelsItem,
	playbookItem,
	connectionsItem,
	reportItem,
	webInsightsItem,
	attributesItem,
	adminItem,
	SIDEBAR_STORE_KEY,
} from './sidebar.constants';

export const checkWebInsightsActive = (): boolean =>
	Object.values(AngularStateList).includes(
		NgState.getAngularState().current.name
	);

export const checkNewSegmentationActiveState = (): boolean =>
	[SEGMENTS_NEW, SEGMENTS_MARKETPLACE, ...SEGMENTS_CHILDROUTES].includes(
		NgState.getAngularState().current.name
	);

export const checkMyDataActiveState = (): boolean => {
	const {segment} = StateHistory.lastToParams() || {};

	const isStateName = MyDataStates.includes(
		NgState.getAngularState().current.name
	);

	return isStateName && (!segment || segment === 'Create');
};

export const isDataAvailable = (): boolean => {
	const collectionStatus =
		getQueryProperty<DataCollectionStatusResponse | null>('collectionStatus');

	if (collectionStatus === null) {
		return true;
	}

	return collectionStatus.AccountCount > 0 || collectionStatus.ContactCount > 0;
};

export const getMyDataState = (): string => {
	const accountsDestination = 'home.segment.accounts';

	const hasAttributesAccess = RBAC.hasAccess(
		RBACInterface.ATTRIBUTES_TAB,
		RBACActions.VIEW
	);

	const withDataDestination = hasAttributesAccess
		? 'home.segment.explorer.attributes'
		: accountsDestination;

	return isDataAvailable() ? withDataDestination : 'home.nodata';
};

export const mapNavigationItems = async (): Promise<NavItems | void> =>
	cacheQueryResponse('getSegments', () => getSegments(), {
		storage: 'sessionStorage',
	})
		.then((result) => {
			const hasSegments = result.length > 0;

			if (hasSegments) {
				dispatchQueryPropertyValue('segments', result);
			}

			return [
				{...myDataItem()},
				{...segmentsItem()},
				{...modelsItem()},
				{...playbookItem(hasSegments)},
				{...connectionsItem()},
				{...reportItem()},
				{...webInsightsItem()},
				{...attributesItem()},
				{...adminItem()},
			];
		})
		.catch((error) => console.error(error));

export const cacheNavigationItems = async (): Promise<NavItems | void> =>
	cacheQueryResponse<NavItems | void>(
		SIDEBAR_STORE_KEY,
		() => mapNavigationItems().then((navigationItems) => navigationItems),
		{storage: 'sessionStorage'}
	);

export const getSidebarItems = (): NavItems =>
	getKeyCacheData<NavItems>(SIDEBAR_STORE_KEY, 'sessionStorage') ?? [];
