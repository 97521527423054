import {ISegment} from 'atlas/data/SegmentConst';
import {API} from 'atlas/segmentation/SegmentDashboard/ApiCalls';
import {
	DuplicateDialog,
	DuplicateDialogTitle,
	DuplicateSegmentMessage,
} from 'atlas/JourneyStage/Component/Dialogs/DuplicateDialog';
import {
	ClearSegmentButton,
	SaveSegmentButton,
	ExportButton,
	RefreshCountButton,
	DuplicateSegmentButton,
} from 'atlas/segmentation/Components/SegmentTitleButtons';
import {
	clearSegment,
	saveSegment,
} from 'atlas/segmentation/Helper/SegmentHelper';
import {updateSegmentCounts} from 'common/components/datacloud/segment/segment.queries';
import {SegmentCreate} from 'common/components/datacloud/segment/SegmentQueryHelper';
import {SaveSegmentDialog} from 'common/components/datacloud/tabs/subheader/SaveSegmentDialog/SaveSegmentDialog';
import {ExportDialog} from 'common/components/Dialog/ExportDialog';
import React from 'common/react-vendor';
import {getQueryProperty} from 'common/stores/query';
import {QueryState} from 'common/stores/query/types';
import {useSelector} from 'react-redux';
import {Box, DNBSnackbar} from 'common/dnb-uux-vendor';
import {refreshCounts} from '../../query.helpers';
import {SegmentButtonGroup} from './SegmentButtonGroup';
import {IQueryButtonGroup} from './query.button.group.ng';

const QueryButtonGroup = ({$state}: IQueryButtonGroup): React.ReactElement => {
	const segment = getQueryProperty<ISegment>('segment') || {
		name: SegmentCreate,
	};
	const queryStore = useSelector((state: {query: QueryState}) => state?.query);
	const publicQueryStore = queryStore?.public || {};
	const isCreate = !segment?.name || segment.name === SegmentCreate;
	const [isExportDialogOpen, setExportDialogOpen] = React.useState(false);
	const [isSaveSegmentOpen, setIsSaveSegmentOpen] = React.useState(false);
	const [isDuplicateDialogOpen, setDuplicateDialogOpen] = React.useState(false);
	const [isProcessing, setProcessing] = React.useState(false);
	const [isSnapbarOpen, setSnapbarOpen] = React.useState(false);
	const buttonList = React.useMemo(
		() =>
			isCreate
				? [
						{...ExportButton(), onClick: () => setExportDialogOpen?.(true)},
						{...ClearSegmentButton(), onClick: () => clearSegment()},
						{
							...SaveSegmentButton(),
							disabled: !publicQueryStore.enableSaveSegmentButton,
							onClick: () => setIsSaveSegmentOpen?.(true),
						},
				  ]
				: [
						{...ExportButton(), onClick: () => setExportDialogOpen?.(true)},
						{
							...RefreshCountButton(),
							disabled: publicQueryStore.enableSaveSegmentButton,
							onClick: () => {
								refreshCounts()
									.then(() => {
										updateSegmentCounts(segment.name);
									})
									.catch(() => undefined);
							},
						},
						{
							...DuplicateSegmentButton(),
							onClick: () => setDuplicateDialogOpen?.(true),
						},
						{
							...SaveSegmentButton(),
							disabled: !publicQueryStore.enableSaveSegmentButton,
							onClick: () => saveSegment(segment.name),
						},
				  ],
		[isCreate, publicQueryStore.enableSaveSegmentButton, segment.name]
	);
	return (
		<Box sx={{padding: '0 24px 24px'}}>
			<DNBSnackbar
				variant='success'
				snackbarText={`The "${segment.display_name}" segment has been successfully duplicated.`}
				isOpen={isSnapbarOpen}
				duration={3000}
				onClickCTA={() => {
					setSnapbarOpen(false);
				}}
				handleClose={() => setSnapbarOpen(false)}
			/>
			<SaveSegmentDialog
				open={isSaveSegmentOpen}
				ngState={$state}
				handleClose={() => setIsSaveSegmentOpen(false)}
			/>
			<DuplicateDialog
				title={DuplicateDialogTitle.Segment}
				description={DuplicateSegmentMessage(segment.display_name)}
				isProcessing={isProcessing}
				data={isDuplicateDialogOpen}
				setData={setDuplicateDialogOpen}
				onOkay={async () => {
					setProcessing(true);
					const success = await API.duplicateSegment(segment);
					setProcessing(false);
					if (success) {
						setSnapbarOpen(true);
					}
				}}
			/>
			{isExportDialogOpen && (
				<ExportDialog data={isExportDialogOpen} setData={setExportDialogOpen} />
			)}
			<SegmentButtonGroup buttonList={buttonList} />
		</Box>
	);
};

export {QueryButtonGroup};
