import {ISegment} from 'atlas/data/SegmentConst';
import {IGenericAction} from 'atlas/JourneyStage/Component/Dialogs/DialogType';
import {DNBButtonProps} from 'common/dnb-uux-vendor';
import React from 'common/react-vendor';
import {getQueryProperty} from 'common/stores/query';
import {EditSegmentDialog} from '../segmentation.edit';
import {
	IEditDialog,
	INameDescriptionData,
	NameDescriptionHeader,
} from './NameDescriptionHeader';

/**
 * ISegmentTitleHeader
 * @param data Segment data @ISegment
 * @param buttons Buttons configuration.
 * @param action: Action callback from Dialog.
 */
interface ISegmentTitleHeader {
	buttons: DNBButtonProps[];
	action?: IGenericAction<INameDescriptionData>;
}

const SegmentTitleHeader = ({
	buttons,
	action,
}: ISegmentTitleHeader): React.ReactElement => {
	const data = getQueryProperty<ISegment>('segment');
	const {name, display_name, description} = data;
	return (
		<NameDescriptionHeader
			data={{id: name, name: display_name, description}}
			buttons={buttons}
			Dialog={(prop: IEditDialog) => (
				<EditSegmentDialog
					{...prop}
					{...data}
					onOkay={(newData) => {
						prop?.onOkay?.(newData);
						action?.onOkay?.(newData);
					}}
					onCancel={() => {
						prop?.onCancel?.();
						action?.onCancel?.();
					}}
				/>
			)}
		/>
	);
};

export type {ISegmentTitleHeader};
export {SegmentTitleHeader};
