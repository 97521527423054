import {DNBDialogWithAngularProps} from './DNBDialogWithAngularProps';
import {react2angular} from 'common/react-vendor';

angular
	.module('common.modal', [])
	.component(
		'dnbDialog',
		react2angular(DNBDialogWithAngularProps, [
			'open',
			'primarycta',
			'primaryctalabel',
			'disabledprimarycta',
			'secondarycta',
			'secondaryctalabel',
			'disabledsecondarycta',
			'onclose',
			'size',
			'variant',
			'title',
			'hasstickyfooter',
			'hasclosebutton',
			'tertiarycta',
			'tertiaryctalabel',
			'disabledtertiarycta',
			'scrim',
			'scroll',
			'body',
		])
	);
