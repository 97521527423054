import {
	axiosInstance,
	UIActionResponse,
} from 'common/app/utilities/axiosUtility/axiosInstance';
import {segmentFormat} from 'atlas/segmentation/SegmentationUtility';
import {Query} from 'common/components/datacloud/datacloud.types';
import {Team} from 'common/components/datacloud/query/query.types';
// TODO: remove 'deleteAngularHashKey' when migration to React is complete
import {deleteAngularHashKey} from './segment.helpers';
import {getSessionSegmentState} from '../query/results/rebuild/segment.helpers';

export interface CreateOrUpdateSegmentResponse {
	data?: Query | string;
	success: boolean;
}

interface DeleteResponse {
	success: boolean;
	errorMessage?: string;
}

const getSegmentExportByExportId = async (exportId: string): Promise<Query> =>
	axiosInstance
		.get(`/pls/datacollection/segments/export/${exportId}`)
		.then((response) => response.data);

const getSegments = async (hasContext = false): Promise<Array<Query>> =>
	axiosInstance
		.get<Array<Query>>(
			`/pls/datacollection/segments${hasContext ? '/context' : ''}`
		)
		.then(({data}) => {
			const segments: Query[] = [];
			data.forEach((segment) => {
				if (segment?.listSegment?.systemType !== 'SSVI') {
					segments.push(segmentFormat(segment));
				}
			});
			return segments;
		});

const getSegmentByName = (name: string): Promise<Query> =>
	axiosInstance
		.get(`/pls/datacollection/segments/${name}`)
		.then((response) => response.data);

const createOrUpdateSegment = (
	segment: Query,
	isClone = false
): Promise<CreateOrUpdateSegmentResponse> =>
	axiosInstance
		.post(
			`/pls/datacollection/segments${isClone ? '/clone' : ''}`,
			deleteAngularHashKey<Query>(segment)
		)
		.then((response) => ({data: response.data, success: true}))
		.catch((error) => ({data: error.message, success: false}));

const getSegmentDependenciesModelView = async (
	segmentId: string
): Promise<UIActionResponse> =>
	axiosInstance
		.get(`/pls/datacollection/segments/${segmentId}/dependencies/modelAndView`)
		.then((response) => response.data);

const updateSegmentCounts = async (segmentName: string): Promise<void> =>
	axiosInstance.put(`/pls/datacollection/segments/${segmentName}/counts`);

const createOrUpdateSegmentExport = async (
	segmentExport: Query,
	attributeSetName?: string
): Promise<boolean> => {
	const segement = {...segmentExport};

	const sessionSegmentState = getSessionSegmentState();

	if (attributeSetName) {
		segement.attributeSetName = attributeSetName;
	}

	if (
		sessionSegmentState?.isCompanyList ||
		sessionSegmentState?.isContactList
	) {
		segement.type = sessionSegmentState?.isCompanyList ? 'ACCOUNT' : 'CONTACT';
	}

	return axiosInstance
		.post(
			'/pls/datacollection/segments/export',
			deleteAngularHashKey<Query>(segement)
		)
		.then(() => true)
		.catch(() => false);
};

const getTeams = (): Promise<Team> =>
	axiosInstance.get('/pls/teams/session').then((response) => response.data);

const deleteSegment = async (segmentName: string): Promise<DeleteResponse> =>
	axiosInstance
		.delete(`/pls/datacollection/segments/${segmentName}/modelAndView`)
		.then(() => ({
			success: true,
		}))
		.catch((error) => ({
			success: false,
			errorMessage: error.message,
		}));

export {
	getSegmentExportByExportId,
	getSegments,
	getSegmentByName,
	createOrUpdateSegment,
	getSegmentDependenciesModelView,
	updateSegmentCounts,
	createOrUpdateSegmentExport,
	getTeams,
	deleteSegment,
};
