import React from 'common/react-vendor';
import {
	IPersonalizationContext,
	IPersonalizationContextUpdate,
	PersonalizationContext,
	PersonalizationContextUpdate,
} from '../context/PersonalizationContext';

const {useContext} = React;

interface IUsePersonalization {
	context: IPersonalizationContext;
	setContext: IPersonalizationContextUpdate;
}

const usePersonalization = (): IUsePersonalization => {
	const context = useContext<IPersonalizationContext>(PersonalizationContext);
	const setContext = useContext<IPersonalizationContextUpdate>(
		PersonalizationContextUpdate
	);
	return {context, setContext};
};

export {usePersonalization};
export type {IUsePersonalization};
