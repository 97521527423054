import {INameDescriptionData} from 'atlas/segmentation/Components/NameDescriptionHeader';
import {Restriction} from 'common/components/datacloud/query/query.types';
import {IEditable} from '../JourneyStageConfiguration/Component/EditableCell';

const NewlyAddedId = -1;

/**
 * Ignored fields for create api.
 */
enum IgnoreField {
	created = 'created',
	createdBy = 'createdBy',
	id = 'id',
	updated = 'updated',
	updatedBy = 'updatedBy',
}

/**
 * Journey Stage summary card interface.
 * @param default True on default journey map, can not delete.
 * @param created Created time stamp.
 * @param createdBy Created by whom.
 * @param description Journey map description. @INameDescriptionData
 * @param id Journey id. @INameDescriptionData
 * @param name Journey name. @INameDescriptionData
 * @param status Journey status.
 * @param updated Updated time stamp.
 * @param updatedBy Updated by whom.
 * @param noOfJourneys Number of journeys.
 * @param noOfSegments Number of segments that using this journey map.
 * @param isEdit FE only flag indicates if edit mode is on. Refers to @IEditable
 */
type IJourneyStageMapData = IEditable &
	INameDescriptionData & {
		id: number;
		default: boolean;
		created: number;
		createdBy: string;
		status: string;
		updated: number;
		updatedBy: string;
		noOfJourneys: number;
		noOfSegments: number;
	};

/**
 * JourneyStageMapData interface with id field picked.
 */
type IJourneyStageMapDataWithId = Partial<IJourneyStageMapData> &
	Pick<IJourneyStageMapData, 'id'>;

/**
 * Default journey stage map
 * Used in edit mode.
 */
const DefaultJourneyStageMap: IJourneyStageMapData = {} as IJourneyStageMapData;

/**
 * Journey stage data interface.
 * @param accountRestriction Account restriction as string.
 * @param contactRestriction Contact restriction as string.
 * @param id Unique id, 0 means newly created on FE. @INameDescriptionData
 * @param name Name @INameDescriptionData
 * @param stageNumber Stage number as priority 1 based. @INameDescriptionData
 * @param accountRestrictionObj Account restriction object @accountRestriction compute by FE
 * @param contactRestrictionObj Contact restriction object @contactRestriction compute by FE
 * @param noOfQueries Number of queries computed FE.
 */
type IJourneyStageData = IEditable &
	INameDescriptionData & {
		id: number;
		accountRestriction?: string;
		contactRestriction?: string;
		accounts: number;
		contacts: number;
		created?: string;
		createdBy?: string;
		buyerJourneyMapId: number;
		stageNumber: number;
		updated?: string;
		updatedBy?: string;
		accountRestrictionInstance?: Restriction;
		contactRestrictionInstance?: Restriction;
		accountRestrictionObj?: Restriction;
		contactRestrictionObj?: Restriction;
		noOfQueries?: number;
	} & {
		buyerJourneyStage?: IJourneyStageData;
	};

/**
 * New journey stage data FE side.
 */
const NewJourneyStageData: IJourneyStageData = {
	description: '',
	buyerJourneyMapId: NewlyAddedId,
	id: NewlyAddedId,
	name: '',
	stageNumber: 0,
	noOfQueries: 0,
	accounts: 0,
	contacts: 0,
	isEdit: true,
};

export type {
	IJourneyStageMapData,
	IJourneyStageMapDataWithId,
	IJourneyStageData,
};
export {DefaultJourneyStageMap, NewJourneyStageData, IgnoreField, NewlyAddedId};
