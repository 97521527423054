import React from 'common/react-vendor';

const DNBSegmentIcon = (): React.ReactElement => (
	<svg width="62" height="77" viewBox="0 0 62 77" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M48.3007 31.19H28.3906V11.27L48.3007 31.19Z" stroke="#4196B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M28.39 11.27H2C1.73478 11.27 1.48038 11.3753 1.29285 11.5629C1.10531 11.7504 1 12.0048 1 12.27V75C1 75.2652 1.10531 75.5196 1.29285 75.7071C1.48038 75.8946 1.73478 76 2 76H47.3C47.5653 76 47.8195 75.8946 48.0071 75.7071C48.1946 75.5196 48.3 75.2652 48.3 75V31.19" stroke="#4196B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M7.51953 6.93018H55.0695L54.8295 71.6501" stroke="#4196B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M13.4492 1H60.9993L60.7593 65.73" stroke="#4196B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M29.5 44.2104C30.5796 45.3515 31.1685 46.8704 31.1405 48.441C31.1125 50.0116 30.4696 51.5086 29.35 52.6104L29.14 52.8004L27.54 51.1604L27.75 50.9604C28.4474 50.2893 28.8497 49.3687 28.8684 48.4011C28.8872 47.4335 28.5208 46.498 27.85 45.8004C27.483 45.4076 27.0317 45.1033 26.53 44.9104V42.5404C27.6743 42.785 28.7192 43.3667 29.53 44.2104M16.7 37.6404L15.5 38.7304L17.09 40.3804L18.23 39.2904C18.4887 39.0402 18.7942 38.8435 19.1289 38.7113C19.4637 38.5792 19.8211 38.5143 20.1809 38.5204C20.5407 38.5264 20.8959 38.6032 21.2261 38.7465C21.5562 38.8898 21.8548 39.0967 22.105 39.3554C22.3551 39.6141 22.552 39.9196 22.6841 40.2543C22.8162 40.5891 22.881 40.9466 22.875 41.3064C22.869 41.6662 22.7922 42.0213 22.6489 42.3514C22.5056 42.6816 22.2987 42.9802 22.04 43.2304L20.91 44.3104L22.5 45.9604L23.63 44.8804C24.1054 44.4208 24.4856 43.872 24.7489 43.2654C25.0122 42.6589 25.1534 42.0064 25.1646 41.3453C25.1757 40.6841 25.0566 40.0272 24.8138 39.4122C24.5711 38.7971 24.2096 38.2358 23.75 37.7604C23.2904 37.285 22.7416 36.9048 22.135 36.6415C21.5284 36.3782 20.876 36.237 20.2148 36.2258C19.5537 36.2147 18.8968 36.3339 18.2817 36.5766C17.6666 36.8193 17.1054 37.1808 16.63 37.6404" fill="#3094B2"/>
		<path d="M15.1494 39.9399L30.8394 56.2199L29.1893 57.8099L26.7094 55.2499L24.2694 57.6099C23.7102 58.1547 23.0482 58.5829 22.322 58.8696C21.5958 59.1563 20.82 59.2958 20.0394 59.2799C18.8649 59.2594 17.7229 58.8906 16.7583 58.2201C15.7937 57.5496 15.05 56.6077 14.6215 55.514C14.193 54.4202 14.099 53.2238 14.3514 52.0765C14.6039 50.9293 15.1914 49.8828 16.0394 49.0699L17.6794 47.4999L19.2694 49.14L17.6294 50.7199C17.1076 51.219 16.7461 51.862 16.5907 52.5671C16.4353 53.2723 16.4932 54.0077 16.757 54.6798C17.0207 55.352 17.4783 55.9305 18.0717 56.3419C18.6651 56.7532 19.3674 56.9788 20.0894 56.9899C20.5674 57.0043 21.0436 56.9233 21.49 56.7516C21.9364 56.5799 22.3441 56.321 22.6893 55.9899L25.1394 53.6299L15.1394 43.2899V39.9699L15.1494 39.9399Z" fill="#3094B4"/>
	</svg>
);

export default DNBSegmentIcon;
