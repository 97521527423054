import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {cacheQueryResponse} from 'common/app/utilities/cacheUtility/cacheUtility';
import type {
	AttributesTimeSeriesResponse,
	DownloadCsvTokenResponse,
	EntityMappingResponse,
	InsightsData,
	PremiumAttributesLimitationMapResponse,
	PremiumAttributesLimitationResponse,
	StatsCubesResponse,
	StatsTopNResponse,
	Report,
	RatingModel,
} from './datacloud.types';
import type {Attribute} from './query/advanced/tree/types';
import {getQueryUrl} from './datacloud.helpers';
import {QueryType} from './datacloud.enums';

// #region GET
const getPremiumAttributesLimitation =
	async (): Promise<PremiumAttributesLimitationResponse> =>
		cacheQueryResponse('getPremiumAttributesLimitation', () =>
			axiosInstance
				.get<PremiumAttributesLimitationResponse>(
					'/pls/latticeinsights/insights/premiumattributeslimitation'
				)
				.then(({data}) => data)
		);

const getPremiumAttributesLimitationMap =
	async (): Promise<PremiumAttributesLimitationMapResponse> =>
		cacheQueryResponse('getPremiumAttributesLimitationMap', () =>
			axiosInstance
				.get<PremiumAttributesLimitationMapResponse>(
					'/pls/latticeinsights/insights/premiumattributeslimitationmap'
				)
				.then(({data}) => data)
		);

const getDownloadCsvToken = async (mode: 'all' | 'selected'): Promise<string> =>
	axiosInstance
		.get<DownloadCsvTokenResponse>(
			`/pls/latticeinsights/insights/downloadcsv-token?onlySelectedAttributes=${String(
				mode === 'selected'
			)}`
		)
		.then((response) => response.data.Result);

const getSelectedAttributesCount = async (): Promise<number> =>
	axiosInstance
		.get<number>('/pls/datacollection/attributes/selectedattributes/count')
		.then(({data}) => data);

interface GetAttributesOpts {
	url?: string;
	onlySelectedAttributes?: string;
}

const getAttributes = async (
	opts?: GetAttributesOpts
): Promise<Attribute[]> => {
	const url = getQueryUrl(QueryType.Attributes);

	return axiosInstance
		.get<Attribute[]>(opts?.url || url, {
			params: {
				onlySelectedAttributes: opts?.onlySelectedAttributes || null,
			},
		})
		.then(({data}) => data);
};

const getAttributesTimeSeries =
	async (): Promise<AttributesTimeSeriesResponse> =>
		cacheQueryResponse('getAttributesTimeSeries', () =>
			axiosInstance
				.get<AttributesTimeSeriesResponse>(
					'/pls/datacollection/attributes/timeseries'
				)
				.then(({data}) => data)
		);

const getEntityMapping = async (): Promise<EntityMappingResponse> =>
	axiosInstance
		.get<EntityMappingResponse>(
			'/pls/datacollection/attrconfig/getEntityMapping'
		)
		.then(({data}) => data);

interface GetStatsTopNOpts {
	url?: string;
}

const getStatsTopN = async (
	opts?: GetStatsTopNOpts
): Promise<StatsTopNResponse> => {
	const url = getQueryUrl(QueryType.StatsTopN);

	return cacheQueryResponse('getStatsTopN', () =>
		axiosInstance
			.get<StatsTopNResponse>(opts?.url || url)
			.then(({data}) => data)
	);
};

interface GetStatsCubesOpts {
	url?: string;
}

const getStatsCubes = async (
	opts?: GetStatsCubesOpts
): Promise<StatsCubesResponse> => {
	const url = getQueryUrl(QueryType.StatsCubes);

	return axiosInstance
		.get<StatsCubesResponse>(opts?.url || url)
		.then(({data}) => data);
};

const getRatingModels = async (
	ratingsEngineId: string,
	ratingModelId?: string
): Promise<[RatingModel]> =>
	axiosInstance
		.get<[RatingModel]>(
			`/pls/ratingengines/${ratingsEngineId}/ratingmodels${
				ratingModelId ? `/${ratingModelId}` : ''
			}`
		)
		.then(({data}) => data);
// #endregion GET

// #region PUT
/**
 * TODO: Remove this code?
 * This function is being used only in the datacloud service.
 * I have checked the code and it appears that datacloud
 * is not available in the newer version of RevUp (Atlas 4.0).
 *
 * Maybe we can delete all datacloud code in common?
 *
 * This appears to be a copy/paste of the legacy-common code,
 * that might be the reason this exists in common.
 *
 * Lpi(the old version of atlas) is render for old tenants
 * (LPI 3.0). In there I can see the datacloud page, but in atlas
 * I get an error and the page doesn't render correctly.
 *
 * I also checked the FFs to check if datacloud is behind one
 * but I couldn't find any.
 *
 * Double check with QA and PM to see if we can safely delete
 * the datacloud code in atlas/common in a diff task.
 * END TODO: DELETE ME??
 */
const putInsights = async (
	data: InsightsData
): Promise<void | Record<'errorCode', number>> =>
	axiosInstance
		.put('/pls/latticeinsights/insights', data)
		.then(() => {
			return;
		})
		.catch((error) => ({
			errorCode: error.response.status,
		}));
// #endregion PUT

// #region POST
interface PostFlagsOpts {
	fieldName?: string;
	useCase?: string;
}

interface Flags {
	hidden?: boolean;
	highlighted?: boolean;
}

const postFlags = async (
	opts: PostFlagsOpts = {},
	flags: Flags = {}
): Promise<''> => {
	const {fieldName = '', useCase = 'CompanyProfile'} = opts;

	return axiosInstance
		.post<''>(`/pls/attributes/flags/${fieldName}/${useCase}`, flags)
		.then(({data}) => data);
};

interface PostCategoriesFlagsOpts {
	categoryName?: string;
	useCase?: string;
}

const postCategoriesFlags = async (
	opts: PostCategoriesFlagsOpts = {},
	flags: Flags = {}
): Promise<''> => {
	const {categoryName = '', useCase = 'CompanyProfile'} = opts;

	return axiosInstance
		.post<''>(`/pls/attributes/categories/flags/${useCase}`, flags, {
			params: {
				category: categoryName,
			},
		})
		.then(({data}) => data);
};

interface PostSubcategoriesFlagsOpts extends PostCategoriesFlagsOpts {
	subcategoryName?: string;
}

const postSubcategoriesFlags = async (
	opts: PostSubcategoriesFlagsOpts = {},
	flags: Flags = {}
): Promise<''> => {
	const {
		categoryName = '',
		subcategoryName = '',
		useCase = 'CompanyProfile',
	} = opts;

	return axiosInstance
		.post<''>(
			`/pls/attributes/categories/subcategories/flags/${useCase}`,
			flags,
			{
				params: {
					category: categoryName,
					subcategory: subcategoryName,
				},
			}
		)
		.then(({data}) => data);
};

const postCustomerReports = async (
	report: Partial<Report> = {},
	type = 'incorrectmatchedattrs'
): Promise<''> =>
	axiosInstance
		.post<''>(`/pls/datacloud/customerreports/${type}`, report, {
			headers: {
				ErrorDisplayMethod: 'none',
			},
		})
		.then(({data}) => data);

const postRatingModels = async (
	ratingsEngineId: string,
	ratingModelId?: string,
	attributes?: string[]
): Promise<string[]> => {
	const data = {
		rule: {
			id: ratingsEngineId,
			selectedAttributes: attributes,
		},
	};

	return axiosInstance
		.post<RatingModel>(
			`/pls/ratingengines/${ratingsEngineId}/ratingmodels${
				ratingModelId ? `/${ratingModelId}` : ''
			}`,
			data
		)
		.then(({data}) => data?.rule?.selectedAttributes || []);
};
// #endregion POST

export {
	getPremiumAttributesLimitation,
	getPremiumAttributesLimitationMap,
	getDownloadCsvToken,
	getSelectedAttributesCount,
	getAttributes,
	getAttributesTimeSeries,
	getEntityMapping,
	getStatsTopN,
	getStatsCubes,
	getRatingModels,
	putInsights,
	postFlags,
	postCategoriesFlags,
	postSubcategoriesFlags,
	postCustomerReports,
	postRatingModels,
};
export type {GetAttributesOpts, GetStatsCubesOpts};
