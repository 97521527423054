import {BucketCmp} from 'common/components/datacloud/query/query.enums';

export const collectionCmp = [
	BucketCmp.IN_COLLECTION,
	BucketCmp.NOT_IN_COLLECTION,
];

export const defaultRangeConfig = {
	from: {
		min: '0',
	},
	to: {
		min: '0',
	},
};
