import React from 'react';
import {DNBTabs, DNBTab, DNBTooltip} from '@dnb-uux-design-system/react';
import {StateService} from '@uirouter/angularjs';
import styles from './AttrHeader.module.scss';

export default function AttrHeader({
	tabs,
	$state,
}: {
	$state: StateService;
	tabs: Array<{
		sref: string;
		label: string;
		info?: string;
		disabled?: boolean;
	}>;
}): React.ReactElement {
	const [currentSref, setCurrentSref] = React.useState($state.current.name);
	// TODO: remove the container div once we remove the background color of #mainSummaryView
	return (
		<div className={styles.container}>
			<DNBTabs
				value={currentSref}
				classes={{
					scroller: styles.scroller,
					flexContainer: styles.flexContainer,
				}}
				sx={{margin: '0 32px'}}>
				{tabs.map(({sref, label, info, disabled}) => (
					<DNBTab
						key={sref}
						value={sref}
						disabled={disabled}
						label={
							<DNBTooltip content={info} arrow>
								<span>{label}</span>
							</DNBTooltip>
						}
						onClick={() => {
							setCurrentSref(sref);
							$state.go(sref);
						}}
					/>
				))}
			</DNBTabs>
		</div>
	);
}
