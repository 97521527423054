import {AxiosPromise} from 'common/node_modules/axios';
import {ObjectType} from 'atlas/connectors/EIF/Data/ConnectionType';
import {genericFetch} from 'atlas/connectors/EIF/Util/FetchWrapper';
import {ApiMethod} from 'atlas/connectors/EIF/Data/API/ApiType';
import {EntityType} from 'atlas/data/RestrictionConst';
import {
	IJourneyStageData,
	IJourneyStageMapData,
	IJourneyStageMapDataWithId,
} from './Data/JourneyStageData';
import {JourneyStageDataHandler} from './Data/JourneyStageDataHandler';

interface IGetJourneyStageUrlParam extends ObjectType {
	id: number;
}

const getJourneyStageListUrl = (buyerJourneyStageId: number): string =>
	`/pls/buyerJourney/maps/${buyerJourneyStageId}/stages`;

/**
 * API to create a journey stage.
 * @param mapId The map id.
 * @param data The journey stage data.
 * @return The created journey stage data.
 */
const createJourneyStage = (
	mapId: number,
	data: IJourneyStageData
): AxiosPromise<IJourneyStageData> => {
	return genericFetch<IJourneyStageData>({
		apiConfig: {
			url: () => `/pls/buyerJourney/maps/${mapId}/stages`,
			method: ApiMethod.post,
		},
		data,
	}).then(({status, data}) => ({
		status,
		data: new JourneyStageDataHandler(data).refill(),
	})) as AxiosPromise<IJourneyStageData>;
};

const getJourneyStageUrl = (id: number): string =>
	`/pls/buyerJourney/map/stages/${id}`;

/**
 * API to get a journey stage.
 * @param id The journey stage id.
 */
const getJourneyStage = (id: number): Promise<IJourneyStageData> => {
	return genericFetch<IJourneyStageData>({
		apiConfig: {
			url: () => getJourneyStageUrl(id),
		},
	}).then(({data}) => new JourneyStageDataHandler(data).refill());
};

/**
 * API to update a journey stage
 * @param data The updated journey stage data.
 */
const updateJourneyStage = (
	data: IJourneyStageData
): AxiosPromise<IJourneyStageData> => {
	return genericFetch({
		apiConfig: {
			url: () => getJourneyStageUrl(data.id),
			method: ApiMethod.put,
		},
		data,
	}) as AxiosPromise<IJourneyStageData>;
};

/**
 * Pick 'id' and 'stageNumber' of @IJourneyStageData
 */
type IJourneyStageOrderData = Pick<IJourneyStageData, 'id' | 'stageNumber'>;

/**
 * API to update batch of journey stages order.
 * @param orderList: List of @IJourneyStageData
 */
const updateJourneyStageOrder = async (
	orderList: IJourneyStageData[]
): Promise<boolean> => {
	const {status} = await genericFetch<IJourneyStageData[]>({
		apiConfig: {
			url: () => '/pls/buyerJourney/map/stages/bulkUpdate',
			method: ApiMethod.post,
		},
		data: orderList,
	});
	return status === 200;
};

/**
 * API to delete a journey stage.
 * @param id The journey stage id
 */
const deleteJourneyStage = async (id: number): Promise<boolean> => {
	const {status} = await genericFetch({
		apiConfig: {
			url: () => `/pls/buyerJourney/map/stages/${id}`,
			method: ApiMethod.delete,
		},
	});
	return status === 200;
};

const getJourneyStageMapPerSegment = (segmentName: string): string =>
	`/pls/buyerJourney/buyerJourneyMapBySegment/${segmentName}`;
const getJourneyStageMapListUrl = (): string => '/pls/buyerJourney/maps';

const getJourneyStageMapUrl = (mapId: number): string =>
	`/pls/buyerJourney/maps/${mapId}`;

/**
 * API to create a journey stage map
 * @param data The journey stage map data to be created.
 */
const createJourneyStageMap = (
	data: IJourneyStageMapData
): AxiosPromise<IJourneyStageMapData> => {
	return genericFetch<IJourneyStageMapData>({
		apiConfig: {url: () => '/pls/buyerJourney/maps', method: ApiMethod.post},
		data,
	}) as AxiosPromise<IJourneyStageMapData>;
};

/**
 * API to duplicate a journey stage map
 * @param id The journey stage map id
 * @param includingStages Flag indicating if includes stages data.
 */
const duplicateJourneyStageMap = (
	id: number,
	includingStages = true
): AxiosPromise<IJourneyStageMapData> => {
	return genericFetch<IJourneyStageMapData>({
		apiConfig: {
			url: () => `/pls/buyerJourney/maps/${id}/duplicate`,
			method: ApiMethod.post,
		},
		query: {includingStages},
	}) as AxiosPromise<IJourneyStageMapData>;
};

/**
 * API to update a journey stage map
 * @param data The journey stage map data to be updated.
 */
const updateJourneyStageMap = async (
	data: IJourneyStageMapDataWithId
): Promise<boolean> => {
	const {status} = await genericFetch({
		apiConfig: {
			url: () => `/pls/buyerJourney/maps/${data.id}`,
			method: ApiMethod.put,
		},
		data,
	});
	return status === 200;
};

/**
 * API to delete a journey stage map
 * @param id The journey stage map id
 */
const deleteJourneyStageMap = async (id: number): Promise<boolean> => {
	const {status} = await genericFetch({
		apiConfig: {
			url: () => `/pls/buyerJourney/maps/${id}`,
			method: ApiMethod.delete,
		},
	});
	return status === 200;
};

/**
 * GetEntityData type.
 * @stageIds Stage id list.
 * @stageIdForSubStages Parent stage id for any substages query.
 */
type IGetEntityData = ObjectType & {
	segmentName: string;
	businessEntity?: EntityType;
	stageIds?: number[];
	stageIdForSubStages?: number;
	lookups?: common.QueryAttribute[];
	pageFilter?: common.PageFilter;
	sort?: common.Sort;
};

type IGetEntityResponse = Record<
	EntityType,
	{
		data: common.Entity[];
	}
>;

const getEntityData = (
	data: IGetEntityData
): AxiosPromise<IGetEntityResponse> => {
	return genericFetch<IGetEntityResponse>({
		apiConfig: {
			url: () => '/pls/buyerJourney/data/stages',
			method: ApiMethod.post,
		},
		data,
	}) as AxiosPromise<IGetEntityResponse>;
};

/**
 * Get entity count data interface.
 * Stage id list.
 */
type IGetEntityCount = string[];

/**
 * Get entity count response interface
 */
type IGetEntityCountResponse = {
	totalAccount: number;
	totalContact: number;
};

const getEntityCount = (
	segmentName: string,
	data: IGetEntityCount
): AxiosPromise<IGetEntityCountResponse[]> => {
	return genericFetch<IGetEntityCountResponse[]>({
		apiConfig: {
			url: () => '/pls/buyerJourney/query/stages',
			method: ApiMethod.post,
		},
		query: {segmentName},
		data,
	}) as AxiosPromise<IGetEntityCountResponse[]>;
};

/**
 * Api for CRUDs of journey stage map and journey stage.
 */
const API = {
	getJourneyStage,
	updateJourneyStage,
	updateJourneyStageOrder,
	createJourneyStage,
	deleteJourneyStage,
	updateJourneyStageMap,
	createJourneyStageMap,
	deleteJourneyStageMap,
	duplicateJourneyStageMap,
	getEntityData,
	getEntityCount,
};

export type {
	IGetEntityData,
	IGetJourneyStageUrlParam,
	IJourneyStageOrderData,
	IGetEntityCountResponse,
};
export {
	API,
	getJourneyStageUrl,
	getJourneyStageListUrl,
	getJourneyStageMapUrl,
	getJourneyStageMapPerSegment,
	getJourneyStageMapListUrl,
};
