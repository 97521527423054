// Containes the utilities to work with js objects

export const isEmpty = (obj) => {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
};

export const deepCopy = (obj, safe, debug) => {
	if (debug) {
		console.time('deepCopy');
	}

	const getCircularReplacer = () => {
		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value#Examples
		const seen = new WeakSet();

		return (key, value) => {
			if (typeof value === 'object' && value !== null) {
				if (seen.has(value)) {
					return;
				}
				seen.add(value);
			}
			return value;
		};
	};

	if (obj && Object.keys(obj).length) {
		if (safe) {
			if (debug) {
				console.timeEnd('deepCopy');
			}

			return JSON.parse(JSON.stringify(obj, getCircularReplacer()));
		}

		if (debug) {
			console.timeEnd('deepCopy');
		}

		return JSON.parse(JSON.stringify(obj));
	}

	if (debug) {
		console.timeEnd('deepCopy');
	}

	return obj;
};

export const isEquivalent = (object1, object2) => {
	const object1Props = Object.getOwnPropertyNames(object1);
	const object2Props = Object.getOwnPropertyNames(object2);

	// If number of properties is different
	if (object1Props.length != object2Props.length) {
		return false;
	}

	for (let i = 0; i < object1Props.length; i++) {
		const propName = object1Props[i];

		// If values of same property are not equal
		if (object1[propName] !== object2[propName]) {
			return false;
		}
	}
	return true;
};

export const dotObject = (path, obj = {}, delimiter = '.') => {
	return path.split(delimiter).reduce((o, i) => {
		return o ? o[i] : false;
	}, obj);
};

export const getArrayFromObj = (obj, key) => {
	const currentObj = obj || [],
		array = [];

	currentObj.forEach((item) => {
		array.push(item[key]);
	});

	return array;
};

export const symmetricDifferenceArrays = (a1, a2) => {
	const result = [];

	for (var i = 0; i < a1.length; i++) {
		if (a2.indexOf(a1[i]) === -1) {
			result.push(a1[i]);
		}
	}

	for (i = 0; i < a2.length; i++) {
		if (a1.indexOf(a2[i]) === -1) {
			result.push(a2[i]);
		}
	}

	return result;
};

export const removeByKeyValue = (ar, key, value) => {
	const _ar = [];

	if (key && (value || value === false) && ar && Array.isArray(ar)) {
		ar.forEach((obj) => {
			if (obj[key] !== value) {
				_ar.push(obj);
			}
		});

		return _ar;
	}

	return ar;
};
