export enum EntityType {
	ACCOUNTS = 'accounts',
	CONTACTS = 'contacts',
	LEADS = 'leads',
}

export enum CoverageType {
	RATING_MODELS_COVERAGE_MAP = 'ratingModelsCoverageMap',
	RATING_ENGINE_ID_COVERAGE_MAP = 'ratingEngineIdCoverageMap',
	ACCOUNTS_COUNT = 'accountsCount',
	CONTACTS_COUNT = 'contactsCount',
}

export enum OperationKeys {
	ACCOUNTS_CREATE = 'ACCOUNTS_CREATE',
	ACCOUNTS_UPDATE = 'ACCOUNTS_UPDATE',
	CONTACTS_CREATE = 'CONTACTS_CREATE',
	CONTACTS_UPDATE = 'CONTACTS_UPDATE',
	LEADS_CREATE = 'LEADS_CREATE',
	LEADS_UPDATE = 'LEADS_UPDATE',
}

export interface AccountsCoverage {
	accounts: number;
	contacts: number;
	unscoredAccounts: number;
	accountsInBuckets: Record<string, number>;
}

export interface ContactsCoverage {
	accounts: number;
	contacts: number;
	contactsInUnscoredAccount: number;
	contactsInBuckets: Record<string, number>;
}

export interface LaunchAccountsCoverage {
	engineId?: string;
	ratingModelsCoverageMap?: Coverage;
	ratingEngineIdCoverageMap?: Record<string, Coverage>;
	accountsCount?: number;
	contactsCount?: number;
	[OperationKeys.ACCOUNTS_CREATE]?: AccountsCoverage;
	[OperationKeys.ACCOUNTS_UPDATE]?: AccountsCoverage;
	[OperationKeys.CONTACTS_CREATE]?: ContactsCoverage;
	[OperationKeys.CONTACTS_UPDATE]?: ContactsCoverage;
	[OperationKeys.LEADS_CREATE]?: ContactsCoverage;
	[OperationKeys.LEADS_UPDATE]?: ContactsCoverage;
}

export interface BucketCoverage {
	bucket?: string;
	count: number;
	contactCount?: number;
	percentage?: number;
}

export interface Coverage {
	unscoredAccountCount?: number;
	unscoredContactCount?: number;
	accountCount?: number;
	contactCount?: number;
	bucketCoverageCounts?: BucketCoverage[];
}

export interface CoverageWithEngineId {
	engineId?: string | null;
	coverage?: Coverage;
}

export interface RecommendationCounts {
	total: number;
	selected: number;
	suppressed: number;
	launched: number;
	contacts: number;
}

export type TargetSegment = {
	[key in EntityType]?: number;
};

export enum SendType {
	ACCOUNTS = 'ACCOUNTS',
	CONTACTS = 'CONTACTS',
	ACCOUNTS_AND_CONTACTS = 'ACCOUNT_AND_CONTACT',
	LEADS = 'LEADS',
}

export enum SendAction {
	UPSERT = 'UPSERT',
	CREATE = 'CREATE',
	UPDATE = 'UPDATE',
}

export interface ICurrencyListAPI {
	currencies: {currency: string; name: string; symbol: string}[];
}

export interface ICountryListAPI {
	countries: {country: string; name: string; region: string}[];
}
