import {FeatureFlags, isFeatureFlagEnabled} from 'common/stores/tenantConfig';
import {getDataCloudStore} from 'common/stores/datacloud';

angular
	.module('common.datacloud.tabs.datacloud', [])
	.controller(
		'DataCloudTabsController',
		function ($state, $stateParams, $scope) {
			const vm = this;

			angular.extend(vm, {
				DataCloudStore: getDataCloudStore(),
				stateParams: $stateParams,
				section: $stateParams.section,
				show_lattice_insights: isFeatureFlagEnabled(
					FeatureFlags.LATTICE_INSIGHTS
				),
			});

			vm.setStateParams = function (section) {
				let goHome = false;
				if (section && section == vm.section && section) {
					goHome = true;
				}
				vm.section = section;
				const params = {
					section: vm.section,
				};
				if (goHome) {
					params.category = '';
					params.subcategory = '';
				}
				$state.go('home.datacloud.explorer', params, {notify: true});
			};

			vm.init = function () {};

			vm.init();
		}
	);
