import React, {Component} from 'common/react-vendor';
import {redux} from 'store';
import './le-buttons.scss';

export default class LeButtons extends Component {
	constructor(props) {
		super(props);

		const state = {
			active: '',
			items: [],
			containerClass: props.containerClass,
		};

		this.redux = redux({
			path: props.namespace || '@filters.buttongroup',
			state: Object.assign(state, props.config),
			component: this,
			appCache: props.noAppCache ? null : ['active'],
		});
	}

	clickButton = (item) => {
		const state = this.redux.get();
		state.active = item.name;
		this.redux.set(state);
		if (this.props.callback) {
			this.props.callback(item.name);
		}
	};

	render() {
		const state = this.redux.get(),
			isDisabled = !state.activeColor,
			activeColor = isDisabled ? 'white' : state.activeColor || 'green';

		return (
			<div
				className={`le-buttons button-group${
					state.containerClass ? ' ' + state.containerClass : ''
				}`}>
				{state.items.map((item) => {
					const color = state.active == item.name ? activeColor : 'white';
					return (
						<button
							key={item.name}
							type='button'
							title={item.title}
							className={`button icon-button ${color}-button`}
							onClick={() => this.clickButton(item)}
							disabled={isDisabled && state.active == item.name}>
							{item.icon ? <span className={`fa ${item.icon}`} /> : ''}
							{item.label ? <span>{item.label}</span> : ''}
						</button>
					);
				})}
			</div>
		);
	}
}
