import React from 'common/react-vendor';
import classNames from 'classnames';
import styles from './CopyComponent.module.scss';

enum IconPlacement {
	Left = 'left',
	Right = 'right',
}

interface ICopyComponent {
	text: string;
	label?: string;
	showData?: boolean;
	callbackHandle?: () => void | Promise<void>;
	title?: string;
	iconPlacement?: IconPlacement;
	iconSet?: string;
	iconHide?: boolean;
	className?: string;
}

interface IIconComponent {
	title?: string;
	iconPlacement: IconPlacement;
	iconSet?: string;
	callbackHandle: () => void | Promise<void>;
}

const IconComponent = ({
	title,
	iconPlacement,
	iconSet,
	callbackHandle,
}: IIconComponent): React.ReactElement => {
	return (
		<i
			className={classNames(
				`fa ${iconSet} ${styles.leCopyControl} ${iconPlacement}`
			)}
			title={`${title}`}
			onClick={callbackHandle}
		/>
	);
};

const CopyComponent = ({
	text,
	label,
	showData = false,
	callbackHandle,
	title = 'Copy Link',
	iconPlacement = IconPlacement.Right,
	iconSet = 'fa-files-o',
	iconHide = false,
	className,
}: ICopyComponent): React.ReactElement => {
	const clickHandler = (): Promise<void> => {
		return window.navigator.clipboard.writeText(text).then(
			() => {
				if (callbackHandle) {
					callbackHandle();
				}
			},
			(err) => {
				console.error('Async: Could not copy text: ', err);
			}
		);
	};

	return (
		<div
			className={classNames('text-ellipsis', styles.pathValue, className)}
			title={text}>
			{text}
			<div className={classNames(styles.leCopyControlls, styles.pathCopy)}>
				{IconPlacement.Left === iconPlacement && !iconHide && (
					<IconComponent
						title={title}
						iconPlacement={iconPlacement}
						iconSet={iconSet}
						callbackHandle={clickHandler}
					/>
				)}
				{label && <span title={`${title}`}>{label}&nbsp;</span>}
				{text && showData && <span title={text}>{text}</span>}
				{IconPlacement.Right === iconPlacement && !iconHide && (
					<IconComponent
						title={title}
						iconPlacement={iconPlacement}
						iconSet={iconSet}
						callbackHandle={clickHandler}
					/>
				)}
			</div>
		</div>
	);
};

export {CopyComponent};
export type {ICopyComponent};
