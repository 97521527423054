import React from 'common/react-vendor';
import {SortButton} from 'widgets/dataviews/column-sort';
import {AttributeGroupAttribute} from '../../query';
import {getAttributeId} from './column-customization';
import {TableAction, TableQuery, isListSegment} from '.';
import styles from './table-content.module.css';

export function getHeader(
	attr: AttributeGroupAttribute,
	query: TableQuery,
	dispatch: React.Dispatch<TableAction>
): React.ReactNode {
	const id = getAttributeId(attr);
	return (
		<SortButton
			sort={query.sort}
			itemKey={id}
			onClick={() =>
				dispatch({
					type: 'setSort',
					sortBy: id,
				})
			}
			style={{fontWeight: 600}}
			title={attr.DisplayName}>
			<div className={styles.tableCell}>{attr.DisplayName}</div>
		</SortButton>
	);
}

export function getCell(
	attr: AttributeGroupAttribute,
	onOpenAccount?: React.Dispatch<Record<string, string>>
) {
	// eslint-disable-next-line react/display-name
	return ({row}: {row: common.Entity}): JSX.Element => {
		const newRow: common.Entity = {...row};
		const Surges = ['—', 'Normal', 'Moderate', 'High'];

		Object.entries(newRow).forEach(([key, value]) => {
			if (key.startsWith('BmbrSurge') && typeof value === 'number') {
				newRow[key] = Surges[value] || '—';
			}
		});
		// TODO: common.d.ts has value typed as `string | number | null`, but formatted cells can also be `boolean | ReactElement`
		const value = newRow[attr.AttrName] as
			| string
			| number
			| null
			| boolean
			| React.ReactElement;

		const {AccountId, ContactId} = newRow;
		if (
			onOpenAccount &&
			['CompanyName', 'ContactName'].includes(attr.AttrName) &&
			typeof AccountId === 'string' &&
			typeof value === 'string' &&
			!isListSegment()
		)
			return (
				<div className={styles.tableCell} title={value}>
					<button
						type='button'
						className='button link-button'
						style={{
							textDecoration: 'underline',
							maxWidth: '100%',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							font: 'inherit',
							position: 'static',
						}}
						onClick={(e) => {
							onOpenAccount({
								a360_accountId: AccountId,
								...(attr.AttrName !== 'ContactName'
									? {}
									: {a360_contactId: ContactId as string}),
							});
							e.stopPropagation();
						}}>
						{value}
					</button>
				</div>
			);

		if (typeof value === 'number')
			return (
				<div className={styles.tableCell} title={value.toLocaleString()}>
					{value.toLocaleString()}
				</div>
			);
		if (typeof value === 'boolean')
			return (
				<div className={styles.tableCell} title={value ? 'True' : 'False'}>
					{value ? 'True' : 'False'}
				</div>
			);
		if (value === null || value === undefined)
			return (
				<div className={styles.tableCell} title='This cell has no data'>
					<span style={{color: '#777'}}>-</span>
				</div>
			);

		return (
			<div
				className={styles.tableCell}
				title={typeof value === 'string' ? value : undefined}>
				{value}
			</div>
		);
	};
}
