import {store} from 'store';
import {ISegment, ITeam} from 'atlas/data/SegmentConst';
import type {
	CurrentRating,
	RatingsEngineState,
	ModelTrainingOptions,
	TrainingCounts,
	Current,
	RatingsEngineValidation,
} from './types';
import {
	clearRatingsEngineStore,
	setRatingsEnginePropertyValue,
	setModelTrainingOption,
	setTrainingCountsPropertyValue,
	setCurrentPropertyValue,
	setValidationPropertyValue,
} from './actions';

// #region get from store
export const getRatingsEngineStore = (): RatingsEngineState | undefined =>
	store.getState().ratingsEngine;

export const getRatingsEngineProperty = <RatingsEngineProperty>(
	key: keyof RatingsEngineState
): RatingsEngineProperty | undefined =>
	getRatingsEngineStore()?.[key] as RatingsEngineProperty | undefined;
// #endregion get from store

// #region setters
export const dispatchClearRatingsEngineStore = (): void => {
	store.dispatch(clearRatingsEngineStore());
};

export const dispatchRatingsEnginePropertyValue = <RatingsEngineValue>(
	key: keyof RatingsEngineState,
	value: RatingsEngineValue
): void => {
	store.dispatch(setRatingsEnginePropertyValue<RatingsEngineValue>(key, value));
};

export const setCurrentRatingPropertyValue = <RatingsEngineValue>(
	key: keyof RatingsEngineState,
	value: RatingsEngineValue
): void => {
	const currentRating = {
		...getRatingsEngineProperty<CurrentRating>('currentRating'),
	};

	if (currentRating && key && value) {
		dispatchRatingsEnginePropertyValue<CurrentRating>('currentRating', {
			...currentRating,
			[key]: value,
		});
	}
};

export const setSegment = (segment: ISegment | null): void => {
	dispatchRatingsEnginePropertyValue('savedSegment', segment);

	const currentRating = {
		...getRatingsEngineProperty<CurrentRating>('currentRating'),
	};

	if (currentRating && segment && segment !== null) {
		dispatchRatingsEnginePropertyValue<CurrentRating>('currentRating', {
			...currentRating,
			segment,
		});
	}
};

export const setTeam = (team: ITeam): void => {
	const currentRating = {
		...getRatingsEngineProperty<CurrentRating>('currentRating'),
	};

	if (currentRating && team) {
		dispatchRatingsEnginePropertyValue<CurrentRating>('currentRating', {
			...currentRating,
			teamId: team.TeamId || 'Global_Team',
		});
	}
};

export const dispatchModelTrainingOption = (
	key: keyof ModelTrainingOptions,
	value: unknown
): void => {
	store.dispatch(setModelTrainingOption(key, value));
};

export const dispatchTrainingCountsPropertyValue = (
	key: keyof TrainingCounts,
	value: unknown
): void => {
	store.dispatch(setTrainingCountsPropertyValue(key, value));
};

export const dispatchCurrentPropertyValue = (
	key: keyof Current,
	value: unknown
): void => {
	store.dispatch(setCurrentPropertyValue(key, value));
};

export const dispatchValidationPropertyValue = (
	key: keyof RatingsEngineValidation,
	value: boolean
): void => {
	store.dispatch(setValidationPropertyValue(key, value));
};
// #endregion setters
