import React, {react2angular} from 'common/react-vendor';
import {Provider} from 'common/redux-vendor';
import {store} from 'store';
import {ISubstageEditHeader, SubstageEditHeader} from './SubstageEditHeader';

const SubstageEditHeaderWithRedux = ({
	...props
}: ISubstageEditHeader): JSX.Element => {
	return (
		<Provider store={store}>
			<SubstageEditHeader {...props} />
		</Provider>
	);
};

angular
	.module('common.datacloud.query.substageditheader', [])
	.component(
		'subStageEditHeader',
		react2angular(
			SubstageEditHeaderWithRedux,
			['subStage'],
			['$state', '$stateParams']
		)
	);
