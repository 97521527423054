import {store} from 'common/app/store';
import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	Right,
	Service,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {
	getKeyCacheData,
	cacheQueryResponse,
} from 'common/app/utilities/cacheUtility/cacheUtility';
import {getTenantConfig} from './queries';
import type {
	TenantConfigData,
	TenantConfigState,
	TenantFeatureFlags,
	TenantModules,
	TenantProducts,
} from './types';
import {FeatureFlags, Modules} from './enums';
import {setTenantConfig} from './actions';

const getRBACFeatureFlags = (): TenantFeatureFlags => ({
	[FeatureFlags.VIEW_SAMPLE_LEADS]: RBAC.currentUserMay(
		Right.View,
		Service.Sample_Leads
	),
	[FeatureFlags.VIEW_REFINE_CLONE]: RBAC.currentUserMay(
		Right.View,
		Service.Refine_Clone
	),
	[FeatureFlags.EDIT_REFINE_CLONE]: RBAC.currentUserMay(
		Right.Edit,
		Service.Refine_Clone
	),
	[FeatureFlags.VIEW_REMODEL]: RBAC.currentUserMay(Right.View, Service.Remodel),
	[FeatureFlags.CHANGE_MODEL_NAME]: RBAC.currentUserMay(
		Right.Edit,
		Service.Models
	),
	[FeatureFlags.DELETE_MODEL]: RBAC.currentUserMay(Right.Edit, Service.Models),
	[FeatureFlags.REVIEW_MODEL]: false,
	[FeatureFlags.UPLOAD_JSON]: RBAC.currentUserMay(Right.Create, Service.Models),
	[FeatureFlags.USER_MGMT_PAGE]: RBAC.currentUserMay(Right.View, Service.Users),
	[FeatureFlags.ADD_USER]: RBAC.currentUserMay(Right.Edit, Service.Users),
	[FeatureFlags.CHANGE_USER_ACCESS]: RBAC.currentUserMay(
		Right.Edit,
		Service.Users
	),
	[FeatureFlags.DELETE_USER]: RBAC.currentUserMay(Right.Edit, Service.Users),
	[FeatureFlags.ADMIN_PAGE]: RBAC.currentUserMay(Right.View, Service.Reporting),
	[FeatureFlags.ADMIN_ALERTS_TAB]: RBAC.currentUserMay(
		Right.Edit,
		Service.Configurations
	),
	[FeatureFlags.MODEL_HISTORY_PAGE]: RBAC.currentUserMay(
		Right.View,
		Service.Reporting
	),
	[FeatureFlags.SYSTEM_SETUP_PAGE]: RBAC.currentUserMay(
		Right.Edit,
		Service.Configurations
	),
	[FeatureFlags.ACTIVATE_MODEL_PAGE]: RBAC.currentUserMay(
		Right.Edit,
		Service.Configurations
	),
	[FeatureFlags.SETUP_PAGE]: RBAC.currentUserMay(
		Right.Edit,
		Service.Configurations
	),
	[FeatureFlags.DEPLOYMENT_WIZARD_PAGE]: RBAC.currentUserMay(
		Right.Edit,
		Service.Configurations
	),
	[FeatureFlags.REDIRECT_TO_DEPLOYMENT_WIZARD_PAGE]: RBAC.currentUserMay(
		Right.Edit,
		Service.Configurations
	),
	[FeatureFlags.LEAD_ENRICHMENT_PAGE]: RBAC.currentUserMay(
		Right.Edit,
		Service.Configurations
	),
	[FeatureFlags.JOBS_PAGE]: RBAC.currentUserMay(Right.View, Service.Jobs),
	[FeatureFlags.MARKETO_SETTINGS_PAGE]: RBAC.currentUserMay(
		Right.Edit,
		Service.Configurations
	),
});

const getTenantConfigStore = (): TenantConfigState => {
	const tenantConfigCache = getKeyCacheData(
		'getTenantConfigStore',
		'sessionStorage'
	);

	const tenantConfigState = store.getState().tenantConfig;

	if (tenantConfigState?.isFetching && tenantConfigCache) {
		return tenantConfigCache;
	}

	return tenantConfigState;
};

const getFeatureFlags = (): TenantFeatureFlags =>
	getTenantConfigStore()?.featureFlags;

const getModules = (): TenantModules => getTenantConfigStore()?.modules;

const getProducts = (): TenantProducts => {
	return getTenantConfigStore()?.products;
};

const getTenantConfigData = (refetch = false): Promise<TenantConfigData> =>
	getTenantConfig(refetch)
		.then(
			({FeatureFlags: tenantFlags, Modules: modules, Products: products}) => ({
				featureFlags: {
					...tenantFlags,
					...getRBACFeatureFlags(),
				},
				modules,
				products,
			})
		)
		.catch(() => ({
			featureFlags: {},
			modules: [],
			products: [],
		}));

const setTenantConfigStore = async (): Promise<void> => {
	const {featureFlags, modules, products} = await getTenantConfigData();
	store.dispatch(
		setTenantConfig({
			featureFlags,
			modules,
			products,
			isFetching: false,
		})
	);
};

const isFeatureFlagEnabled = (featureFlag: FeatureFlags): boolean => {
	const featureFlags = getFeatureFlags();

	if (!featureFlags) {
		return false;
	}

	return featureFlags[featureFlag] || false;
};

const hasModule = (module: Modules): boolean => getModules()?.includes(module);

const hasProduct = (product: string): boolean =>
	getProducts()?.includes(product);

const refetchTenantConfigCache = async (): Promise<TenantConfigData> =>
	cacheQueryResponse<TenantConfigData>(
		'getTenantConfigStore',
		() => getTenantConfigData(true),
		{
			refetch: true,
			storage: 'sessionStorage',
		}
	);

export {
	isFeatureFlagEnabled,
	getTenantConfigStore,
	getFeatureFlags,
	getModules,
	getProducts,
	getRBACFeatureFlags,
	hasModule,
	hasProduct,
	setTenantConfigStore,
	refetchTenantConfigCache,
};
