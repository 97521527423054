import isNil from 'lodash/isNil';
import React from 'common/react-vendor';
import {
	cacheQueryResponse,
	getKeyCacheData,
} from '../utilities/cacheUtility/cacheUtility';
import {useApi} from './useApi';
import type {UseApiReturn} from './useApi';

const {useMemo} = React;

type CacheStorage = 'singleton' | 'localStorage' | 'sessionStorage';

interface UseCacheConfig {
	refetch?: boolean;
	storage?: CacheStorage;
}

const useCache = <Response>(
	queryKey: string | string[],
	queryFn: () => Promise<Response>,
	config?: UseCacheConfig
): UseApiReturn<Response> => {
	const {refetch = false, storage = 'singleton'} = config || {};

	const {cacheKey, cacheData} = useMemo(() => {
		const cacheKey = Array.isArray(queryKey) ? queryKey.join(':') : queryKey;

		return {
			cacheKey,
			cacheData: getKeyCacheData(cacheKey, storage),
		};
	}, [queryKey, storage]);

	const {data, ...useApiProps} = useApi<Response>(
		queryKey,
		() =>
			cacheQueryResponse(cacheKey, queryFn, {
				refetch,
				storage,
			}),
		{
			enabled: isNil(cacheData) || refetch,
		}
	);

	return {
		...useApiProps,
		data: data || cacheData,
	};
};

export {useCache};
export type {CacheStorage};
