import React from 'common/react-vendor';
import MuiTablePagination, {
	TablePaginationProps,
} from '@mui/material/TablePagination';
import {styled} from '@mui/material/styles';

type OmittedMuiTablePaginationProps = '';
export type DNBTablePaginationProps = {testProp?: string} & Omit<
	TablePaginationProps,
	OmittedMuiTablePaginationProps
>;

const StyledDNBTablePagination = styled(MuiTablePagination)(({theme}) => ({
	'& .MuiIconButton-colorInherit': {
		'&:active': {
			borderStyle: 'solid',
			borderWidth: '2px',
			borderColor: theme.palette.primary.main,
		},
	},
	'& .MuiSelect-select': {
		'&:active': {
			borderStyle: 'solid',
			borderWidth: '2px',
			borderColor: theme.palette.primary.main,
		},
	},
}));

export const DNBTablePagination: React.FC<
	React.PropsWithChildren<DNBTablePaginationProps>
> = (props) => {
	return (
		<StyledDNBTablePagination {...props} labelRowsPerPage='Items per page: ' />
	);
};
