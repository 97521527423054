import React from 'common/react-vendor';
import {DNBDialog} from '@dnb-uux-design-system/react';

/*
TODO: Remove this intermediate component once angular is gone.
It exists only to convert camelCase React props to lowercase angular props and pass the 'body' prop in for the children.
*/
export const DNBDialogWithAngularProps = ({
	open,
	primarycta,
	primaryctalabel,
	disabledprimarycta,
	secondarycta,
	secondaryctalabel,
	disabledsecondarycta,
	onclose,
	size,
	variant,
	title,
	hasstickyfooter,
	hasclosebutton,
	tertiarycta,
	tertiaryctalabel,
	disabledtertiarycta,
	scrim,
	scroll,
	body,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Record<string, any>): React.ReactElement => (
	<DNBDialog
		open={open}
		primaryCTA={primarycta}
		primaryCTALabel={primaryctalabel}
		disabledPrimaryCTA={disabledprimarycta}
		secondaryCTA={secondarycta}
		secondaryCTALabel={secondaryctalabel}
		disabledSecondaryCTA={disabledsecondarycta}
		onClose={onclose}
		size={size}
		variant={variant}
		title={title}
		hasStickyFooter={hasstickyfooter}
		hasCloseButton={hasclosebutton}
		tertiaryCTA={tertiarycta}
		tertiaryCTALabel={tertiaryctalabel}
		disabledTertiaryCTA={disabledtertiarycta}
		scrim={scrim}
		scroll={scroll}>
		<div dangerouslySetInnerHTML={{__html: body}} />
	</DNBDialog>
);
