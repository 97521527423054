import React from 'common/react-vendor';
import {FilterDrawer} from 'common/composite-uux-components/filter-drawer';
import cloneDeep from 'lodash/cloneDeep';

interface IFilterCount {
	[key: string]: number;
}

const {useState, useEffect, useRef, useMemo} = React;

interface SchemaFilterProps<T> {
	dataSource: T[];
	filterConfig: Partial<
		Record<
			keyof T,
			{
				label: string;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				valueFormatter?: (value: any) => string;
			}
		>
	>;
	onFilter: (
		filters: Partial<Record<keyof T, Array<string>>>,
		allSelected: boolean
	) => void;
	isFilterOpen: boolean;
}

export function SchemaFilter<T>({
	dataSource,
	filterConfig,
	onFilter,
	isFilterOpen,
}: SchemaFilterProps<T>): React.ReactElement {
	const [filterCounts, setFilterCounts] = useState<
		Partial<Record<keyof T, IFilterCount>>
	>({});
	const [targetFilters, setTargetFilters] = useState<
		Partial<Record<keyof T, Array<string>>>
	>({});
	const allFiltersRef = useRef<typeof targetFilters>();

	const filters = useMemo(() => {
		return Object.entries(filterCounts).map(([key, params]) => {
			const filterKey = key as keyof T;
			const {label} = filterConfig[filterKey]!;
			const targetFilterValue = targetFilters[filterKey];

			return {
				id: key,
				name: label,
				options: Object.entries(params as IFilterCount).map(
					([option, count]) => {
						return {
							label: option,
							value: option,
							count,
						};
					}
				),
				selectedFilters: targetFilterValue,
				onFilterChange: (checked: boolean, value: string) => {
					if (checked) {
						if (targetFilterValue) {
							targetFilterValue.push(value);
						} else {
							targetFilters[filterKey] = [value];
						}
					} else if (targetFilterValue) {
						targetFilters[filterKey] = targetFilterValue.filter(
							(targetFilter) => targetFilter !== value
						);
					}
					setTargetFilters({...targetFilters});
				},
				onSelectAllChange: (checked: boolean) => {
					if (checked) {
						setTargetFilters((prevSelections) => {
							const newTargetFilters = {...prevSelections};
							newTargetFilters[filterKey] = [
								...(allFiltersRef.current?.[filterKey] || []),
							];
							return newTargetFilters;
						});
					} else {
						setTargetFilters((prevSelections) => {
							const newTargetFilters = {...prevSelections};
							newTargetFilters[filterKey] = [];
							return newTargetFilters;
						});
					}
				},
			};
		});
	}, [filterConfig, filterCounts, targetFilters]);

	useEffect(() => {
		const newFilterCounts: typeof filterCounts = {};
		const newTargetFilters: typeof targetFilters = {};

		dataSource.forEach((dataItem) => {
			Object.keys(filterConfig).forEach((_key) => {
				const key = _key as keyof T;
				const stringifiedIndex =
					filterConfig[key]?.valueFormatter?.(dataItem[key]) ||
					(dataItem[key] as unknown as string);
				if (newFilterCounts[key]) {
					const targetParam: IFilterCount = newFilterCounts[key]!;
					if (targetParam[stringifiedIndex]) {
						targetParam[stringifiedIndex]++;
					} else {
						targetParam[stringifiedIndex] = 1;
					}
				} else {
					newFilterCounts[key] = {
						[stringifiedIndex]: 1,
					};
				}

				if (!newTargetFilters[key]) {
					newTargetFilters[key] = [stringifiedIndex];
				} else if (!newTargetFilters[key]?.includes(stringifiedIndex)) {
					newTargetFilters[key]!.push(stringifiedIndex);
				}
			});
		});

		setFilterCounts(newFilterCounts);
		setTargetFilters(newTargetFilters);

		allFiltersRef.current = cloneDeep(newTargetFilters);
	}, [dataSource, filterConfig]);

	useEffect(() => {
		onFilter(
			targetFilters,
			!!allFiltersRef.current &&
				Object.values(allFiltersRef.current).flat().length ===
					Object.values(targetFilters).flat().length
		);
	}, [onFilter, targetFilters]);

	const handleSelectAllChange = (selectAll: boolean): void => {
		setTargetFilters(() => {
			const allFilters = {...allFiltersRef.current!};

			if (selectAll) {
				return allFilters;
			}

			return Object.keys(allFilters).reduce(
				(filters, filterKey) => ({
					...filters,
					[filterKey]: [],
				}),
				{}
			);
		});
	};

	return (
		<FilterDrawer
			isOpen={isFilterOpen}
			filters={filters}
			onSelectAllChange={handleSelectAllChange}
			hasActiveFilters={Object.values(targetFilters).flat().length > 0}
		/>
	);
}
