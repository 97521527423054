import React from 'common/react-vendor';
import {ConnectionType} from '../Data/ConnectionType';
import {FieldType} from '../Data/FieldType';
import {ImportSubsystemType} from '../Data/ImportSubsystemType';
import {useComponentVisible} from '../Hook/useComponentVisible';

enum CompareType {
	Alphabet,
	Numeric,
}

enum SortDirection {
	Ascend = 'asc',
	Descend = 'desc',
}

interface ICompareOption {
	key: FieldType;
	title: string;
	compareType: CompareType;
}

type ICompareOptionConfig = {
	[key in ConnectionType]: {
		[key in ImportSubsystemType]: ICompareOption[];
	};
};

interface IConnectionSortBy {
	option: ICompareOption;
	optionList: ICompareOption[];
	onSortOptionChanged: (option: ICompareOption) => void;
	sortDirection: SortDirection;
	onSortDirectionChanged: (direction: SortDirection) => void;
}

const ConnectionSortBy = ({
	option,
	optionList,
	onSortOptionChanged,
	sortDirection,
	onSortDirectionChanged,
}: IConnectionSortBy): React.ReactElement => {
	const {title, compareType} = option;
	const iconConfig = {
		[CompareType.Alphabet]: `fa fa-sort-alpha-${sortDirection}`,
		[CompareType.Numeric]: `fa fa-sort-numeric-${sortDirection}`,
	};
	const {ref, isComponentVisible, setIsComponentVisible} =
		useComponentVisible(false);
	const onShowMenu = (): void => {
		setIsComponentVisible(!isComponentVisible);
	};
	const onMenuItemClick = (option: ICompareOption): void => {
		setIsComponentVisible(false);
		onSortOptionChanged(option);
	};
	return (
		<li>
			<div className='select-menu white-select-menu sort-select'>
				<button
					type='button'
					title={title}
					className={`button white-button icon-button select-label ${
						isComponentVisible ? 'open' : ''
					}`}
					onClick={() => onShowMenu()}>
					{title}
				</button>
				<button
					type='button'
					title='Sort Direction'
					className={`button white-button icon-button select-more ${
						isComponentVisible ? 'open' : ''
					}`}
					onClick={() =>
						onSortDirectionChanged(
							sortDirection === SortDirection.Ascend
								? SortDirection.Descend
								: SortDirection.Ascend
						)
					}>
					<span className={iconConfig[compareType]} />
				</button>
				{isComponentVisible && (
					<ul
						className='model-menu'
						ref={ref as React.RefObject<HTMLUListElement>}>
						{optionList.map(({key, title, compareType}) => {
							return (
								<li
									key={title}
									onClick={() => onMenuItemClick({key, title, compareType})}>
									<span>{title}</span>
									<i className={iconConfig[compareType]} />
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</li>
	);
};

export {ConnectionSortBy, CompareType, SortDirection};
export type {IConnectionSortBy, ICompareOption, ICompareOptionConfig};
