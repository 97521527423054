import {getDataCloudProperty} from 'common/stores/datacloud';
import {
	setPublicProperty,
	getQueryProperty,
	setEntitiesProperty,
	setResourceTypeCount,
} from 'common/stores/query';
import {
	getEntitiesCounts,
	toggleRestrictionEntity,
} from '../query/query.helpers';
import {sanitizeSegment} from '../segment/segment.helpers';

angular
	.module('common.datacloud.valuepicker', [])
	.controller(
		'ValuePickerController',
		function ValuePickerController(
			$rootScope,
			$state,
			$stateParams,
			PickerBuckets
		) {
			const vm = this;

			angular.extend(vm, {
				stateParams: $stateParams,
				buckets: PickerBuckets.Bkts.List,
				picker_object: null,
				sortPrefix: '-',
				page: 1,
				row_limit: 15,
				page_size: Math.ceil(15 * 3),
				mode: $stateParams.mode,
			});

			vm.init = () => {
				vm.picker_object = getDataCloudProperty('pickerObject') || {
					item: null,
					restriction: null,
				};

				if (vm.picker_object.item) {
					vm.item = vm.picker_object.item;
					vm.fieldname = vm.item.ColumnId;
					vm.entity = vm.item.Entity;

					if (!vm.item.cube) {
						vm.item.cube = PickerBuckets;
					}
				}

				if (vm.picker_object.restriction) {
					vm.bucketRestriction = vm.picker_object.restriction.bucketRestriction;

					const values = vm.bucketRestriction.bkt.Vals;

					vm.buckets.forEach((bucket) => {
						if (values.indexOf(bucket.Vals[0]) >= 0) {
							bucket.checked = true;
						}
					});
				}
			};

			// TODO: This code is duplicated all over the place where the QueryStore is
			// used. Ideally, it should just be written there once, so all the call
			// sites don't need to define it themselves.
			// FIXME: Magic values
			vm.getRestrictionEntity = (entity) => {
				const isContact = [
					'Contact',
					'ContactMarketingActivity',
					'CuratedContact',
				].includes(entity);
				return isContact ? 'Contact' : 'Account';
			};

			vm.changeBucketState = (bucket) => {
				let restriction = vm.bucketRestriction;
				const treeroot = getQueryProperty('addBucketTreeRoot');
				let vals;
				let bkt;

				if (restriction) {
					bkt = restriction.bkt;
					vals = bkt.Vals;
				}

				if (bucket.checked) {
					if (!bkt && !vals) {
						const entity = vm.getRestrictionEntity(vm.item.Entity);
						toggleRestrictionEntity('add', entity, {
							columnName: vm.fieldname,
							resourceType: vm.entity,
							bkt: angular.copy(bucket),
						});

						// this code shows the fake item in picker
						restriction = getQueryProperty(
							`${entity.toLowerCase()}Restriction`
						).restriction;
						const {restrictions} = (treeroot || restriction).logicalRestriction;

						vm.bucketRestriction =
							restrictions[restrictions.length - 1].bucketRestriction;
						vm.picker_object.restriction = {
							// Possible FIXME: Isn't this an angular internal? Why is it being manually set?
							$$hashKey: 'object:9999',
							bucketRestriction: vm.bucketRestriction,
							collapsed: false,
							labelGlyph: restrictions.length,
						};
						bkt = vm.bucketRestriction.bkt;
						vals = bkt.Vals;
					} else {
						vals.push(bucket.Vals[0]);
					}
				} else {
					// Can this be replaced with vals.shift()? There are too many different
					// variables in this file for what it does
					vals.splice(vals.indexOf(bucket.Vals[0]), 1);
				}

				if (vals.length === 0) {
					bkt.Cmp = 'IS_NULL';
				} else if (bkt.Cmp === 'NOT_EQUAL' || bkt.Cmp === 'NOT_IN_COLLECTION') {
					bkt.Cmp = vals.length === 1 ? 'NOT_EQUAL' : 'NOT_IN_COLLECTION';
				} else {
					bkt.Cmp = vals.length === 1 ? 'EQUAL' : 'IN_COLLECTION';
				}

				if (vm.controller) {
					vm.updateCounts(vals);
				}

				if (
					$state.current.name.includes(
						'home.ratingsengine.createAccountFitModeling.successCriteria'
					)
				) {
					const segment = sanitizeSegment(getQueryProperty('segment'));

					// Dark magic to be able to persist the modelLabelQuery in the react component AccountFitModelingWizard
					window.postMessage({
						query: {
							account_restriction: segment.account_restriction,
							contact_restriction: segment.contact_restriction,
							free_form_text_search: '',
							page_filter: {num_rows: 10, row_offset: 0},
						},
					});
				}

				setPublicProperty('enableSaveSegmentButton', true);
			};

			vm.getBucketLabel = (bucket) => bucket.labelGlyph;

			vm.updateCounts = () => {
				if (
					$state.current.name !==
					'home.ratingsengine.rulesprospects.segment.attributes.rules.picker'
				) {
					setEntitiesProperty('loading', true);

					const segment = {
						free_form_text_search: '',
						page_filter: {
							num_rows: 10,
							row_offset: 0,
						},
					};

					segment.account_restriction = angular.copy(
						getQueryProperty('accountRestriction')
					);
					segment.contact_restriction = angular.copy(
						getQueryProperty('contactRestriction')
					);

					getEntitiesCounts(segment)
						.then((result) => {
							setResourceTypeCount('accounts', false, result.Account);
							setResourceTypeCount('contacts', false, result.Contact);
						})
						.finally(() => {
							$rootScope.$apply();
						});
				}

				/**
				 * The updateBucketCount function that
				 * is inheriting the controller its the
				 * function from tree.component.js
				 *
				 * https://github.com/dnb-main/lat-leui/blob/develop/projects/common/components/datacloud/query/advanced/tree/tree.component.js#L408
				 *
				 * If the tree.component.js gets refactor
				 * we need to consider updating this too.
				 *
				 * Module name: 'common.datacloud.query.builder.tree'
				 * Directive name: 'queryTreeDirective'
				 *
				 * Leaving these names in case someone makes the refactor
				 * and makes a global search of these names.
				 */
				vm.controller.updateBucketCount();
			};

			vm.pushItem = (item, bucketRestriction, controller) => {
				vm.controller = controller;
				vm.updateCounts();
			};

			vm.go = $state.go;

			vm.getRecordLabel = () => `${vm.item?.Entity || 'Record'}s`;

			vm.init();
		}
	);
