import React, {PropTypes} from 'common/react-vendor';
import './styles.scss';

const unhandledChange = () => {
	throw new Error(
		'LeSwitch requires an onChange callback but none was provided'
	);
};

export default function Switch({
	checked,
	onChange = unhandledChange,
	label,
	testId,
	disabled = false,
	className = '',
}) {
	return (
		<label
			className={`le-switch ${className}`}
			aria-label={label}
			disabled={disabled}>
			<input
				className='le-switch__checkbox'
				type='checkbox'
				checked={checked}
				onChange={(e) => onChange(e.target.checked)}
				data-test={testId}
				disabled={disabled}
			/>
			<span className='le-switch__indicator'>{checked ? 'ON' : 'OFF'}</span>
		</label>
	);
}

Switch.propTypes = {
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	testId: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
};
