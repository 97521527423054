import React from 'common/react-vendor';
import {SvgIcon, SvgIconProps} from '@mui/material';

const EventIcon = (props: SvgIconProps): React.ReactElement => (
	<SvgIcon {...props}>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='currentColor'>
			<path d='M12.0833 15.0013C11.5 15.0013 11.0069 14.7999 10.6042 14.3971C10.2014 13.9944 10 13.5013 10 12.918C10 12.3346 10.2014 11.8416 10.6042 11.4388C11.0069 11.036 11.5 10.8346 12.0833 10.8346C12.6667 10.8346 13.1597 11.036 13.5625 11.4388C13.9653 11.8416 14.1667 12.3346 14.1667 12.918C14.1667 13.5013 13.9653 13.9944 13.5625 14.3971C13.1597 14.7999 12.6667 15.0013 12.0833 15.0013ZM4.16667 18.3346C3.70833 18.3346 3.31583 18.1716 2.98917 17.8455C2.66306 17.5188 2.5 17.1263 2.5 16.668V5.0013C2.5 4.54297 2.66306 4.15075 2.98917 3.82464C3.31583 3.49797 3.70833 3.33464 4.16667 3.33464H5V1.66797H6.66667V3.33464H13.3333V1.66797H15V3.33464H15.8333C16.2917 3.33464 16.6842 3.49797 17.0108 3.82464C17.3369 4.15075 17.5 4.54297 17.5 5.0013V16.668C17.5 17.1263 17.3369 17.5188 17.0108 17.8455C16.6842 18.1716 16.2917 18.3346 15.8333 18.3346H4.16667ZM4.16667 16.668H15.8333V8.33464H4.16667V16.668ZM4.16667 6.66797H15.8333V5.0013H4.16667V6.66797ZM4.16667 6.66797V5.0013V6.66797Z' />
		</svg>
	</SvgIcon>
);

export default EventIcon;
