import React from 'common/react-vendor';
import {DescriptionOutlinedIcon, PublicIcon} from 'common/dnb-uux-vendor';
import {QuickType} from 'common/widgets/dataviews/numeric-range/quickTimeRange.helpers';
import RBAC, {
	AccessGroups,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	CommonFiltersConfig,
	CommonSelectLabelConfig,
	CommonSelectLabelPlaceholderConfig,
	CompanyDetailFields,
	ICommonWebInsightsFiltersMap,
	IOverviewFiltersMap,
	IWebInsightsTab,
	IWebInsightsTabsType,
	MenuType,
	SelectType,
	WebInsightsTabsType,
} from './WebInsightsTypes';

const webInsightsIframeID = 'webInsights-iframe';

const rowHeight = 28;

const indexColumnWidth = 50;

const indexColumnField = 'index';

const SSVIStorageNamespace = 'SSVICustomFiltersDraft';

const defaultFilterGroupId = 0;

const SSVIInfoStorageNamespace = 'GriotWebInsightsInfo';

const APIErrorMessage =
	'Cannot retrieve data at this time, please try again later.';

const hasAdminAccess = RBAC.userHasAccessLevel(AccessGroups.ADMINS);

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const AngularStateList = {
	Overview: 'home.webInsightsOverview',
	CompanyVisitAnalysis: 'home.webInsightsCompanyVisitAnalysis',
	CompanyDetail: 'home.webInsightsCompanyDetail',
	CompanyDetailPageGroup: 'home.webInsightsCompanyDetailPageGroup',
	PageGroupAnalysis: 'home.webInsightsPageGroupAnalysis',
	PageGroupDetail: 'home.webInsightsPageGroupDetail',
	PageGroupDetailCompany: 'home.webInsightsPageGroupDetailCompany',
	DashboardConfiguration: 'home.webInsightsDashboardConfiguration',
	SSVIOverview: 'home.SSVIOverview',
};

const StateList = {
	Overview: 'webInsightsOverview',
	CompanyVisitAnalysis: 'webInsightsCompanyVisitAnalysis',
	CompanyDetail: 'webInsightsCompanyDetail',
	CompanyDetailPageGroup: 'webInsightsCompanyDetailPageGroup',
	PageGroupAnalysis: 'webInsightsPageGroupAnalysis',
	PageGroupDetail: 'webInsightsPageGroupDetail',
	PageGroupDetailCompany: 'webInsightsPageGroupDetailCompany',
	DashboardConfiguration: 'webInsightsDashboardConfiguration',
	SSVIOverview: 'SSVIOverview',
};

const StateLisToSelectedTab = {
	[AngularStateList.Overview]: StateList.Overview,
	[AngularStateList.CompanyVisitAnalysis]: StateList.CompanyVisitAnalysis,
	[AngularStateList.CompanyDetail]: StateList.CompanyVisitAnalysis,
	[AngularStateList.CompanyDetailPageGroup]: StateList.CompanyVisitAnalysis,
	[AngularStateList.PageGroupAnalysis]: StateList.PageGroupAnalysis,
	[AngularStateList.PageGroupDetail]: StateList.PageGroupAnalysis,
	[AngularStateList.PageGroupDetailCompany]: StateList.PageGroupAnalysis,
	[AngularStateList.DashboardConfiguration]: StateList.DashboardConfiguration,
	[AngularStateList.SSVIOverview]: StateList.SSVIOverview,
};

const WebInsightsTabsTypeConfig: IWebInsightsTabsType = {
	[WebInsightsTabsType.Overview]: 'Overview',
	[WebInsightsTabsType.CompanyVisitAnalysis]: 'Company Visit Analysis',
	[WebInsightsTabsType.CompanyDetail]: 'Company Detail',
	[WebInsightsTabsType.CompanyDetailPageGroup]: 'Company Detail Page Group',
	[WebInsightsTabsType.PageGroupAnalysis]: 'Page Group Analysis',
	[WebInsightsTabsType.PageGroupDetail]: 'Page Group Detail',
	[WebInsightsTabsType.PageGroupDetailCompany]: 'Page Group Detail Company',
	[WebInsightsTabsType.DashboardConfiguration]: 'Dashboard Configuration',
	[WebInsightsTabsType.SSVIOverview]: 'Overview',
};

const WebInsightsRoutes: IWebInsightsTabsType = {
	[WebInsightsTabsType.Overview]: AngularStateList.Overview,
	[WebInsightsTabsType.CompanyVisitAnalysis]:
		AngularStateList.CompanyVisitAnalysis,
	[WebInsightsTabsType.CompanyDetail]: AngularStateList.CompanyDetail,
	[WebInsightsTabsType.CompanyDetailPageGroup]:
		AngularStateList.CompanyDetailPageGroup,
	[WebInsightsTabsType.PageGroupAnalysis]: AngularStateList.PageGroupAnalysis,
	[WebInsightsTabsType.PageGroupDetail]: AngularStateList.PageGroupDetail,
	[WebInsightsTabsType.PageGroupDetailCompany]:
		AngularStateList.PageGroupDetailCompany,
	[WebInsightsTabsType.SSVIOverview]: AngularStateList.SSVIOverview,
	[WebInsightsTabsType.DashboardConfiguration]:
		AngularStateList.DashboardConfiguration,
};

const WebInsightsTabsOptions: IWebInsightsTab[] = [
	{
		value: WebInsightsTabsType.Overview,
		label: WebInsightsTabsTypeConfig[WebInsightsTabsType.Overview],
		url: '/web-insights/overview',
		show: true,
	},
	{
		value: WebInsightsTabsType.CompanyVisitAnalysis,
		label: WebInsightsTabsTypeConfig[WebInsightsTabsType.CompanyVisitAnalysis],
		url: '/web-insights/company-visits',
		show: true,
	},
	{
		value: WebInsightsTabsType.CompanyDetail,
		label: '',
		url: '',
		show: false,
	},
	{
		value: WebInsightsTabsType.CompanyDetailPageGroup,
		label: '',
		url: '',
		show: false,
	},
	{
		value: WebInsightsTabsType.PageGroupAnalysis,
		label: WebInsightsTabsTypeConfig[WebInsightsTabsType.PageGroupAnalysis],
		url: '/web-insights/page-groups',
		show: true,
	},
	{
		value: WebInsightsTabsType.PageGroupDetail,
		label: '',
		url: '',
		show: false,
	},
	{
		value: WebInsightsTabsType.PageGroupDetailCompany,
		label: '',
		url: '',
		show: false,
	},
	{
		value: WebInsightsTabsType.SSVIOverview,
		url: '/web-insights/ssvi',
		label: WebInsightsTabsTypeConfig[WebInsightsTabsType.SSVIOverview],
		show: true,
	},
	{
		value: WebInsightsTabsType.DashboardConfiguration,
		url: '/web-insights/dash-config',
		label:
			WebInsightsTabsTypeConfig[WebInsightsTabsType.DashboardConfiguration],
		show: hasAdminAccess,
	},
];

const WebInsightsHeaderMessageConfig = {
	[WebInsightsTabsType.Overview]: {
		title: 'Website Visitor Data Overview',
		description:
			'Analyze your website visitor traffic to identify new leads, build targeting segments for campaigns, personalize your web content and optimize your campaigns to boost outcomes.',
	},
	[WebInsightsTabsType.CompanyVisitAnalysis]: {
		title: 'Company Visit Analysis',
		description:
			'Analyze your website visitor traffic to identify new leads, build targeting segments for campaigns, personalize your web content and optimize your campaigns to boost outcomes.',
	},
	[WebInsightsTabsType.CompanyDetail]: {
		title: 'CompanyDetail',
		description: '',
	},
	[WebInsightsTabsType.CompanyDetailPageGroup]: {
		title: 'CompanyDetailPageGroup',
		description: '',
	},
	[WebInsightsTabsType.PageGroupAnalysis]: {
		title: 'Page Group Analysis',
		description:
			'Analyze your website visitor traffic to identify new leads, build targeting segments for campaigns, personalize your web content and optimize your campaigns to boost outcomes.',
	},
	[WebInsightsTabsType.PageGroupDetail]: {
		title: 'PageGroupDetail',
		description: '',
	},
	[WebInsightsTabsType.PageGroupDetailCompany]: {
		title: 'PageGroupDetail',
		description: '',
	},
	[WebInsightsTabsType.DashboardConfiguration]: {
		title: 'Website Visitor Data DashboardConfiguration',
		description:
			'Analyze your website visitor traffic to identify new leads, build targeting segments for campaigns, personalize your web content and optimize your campaigns to boost outcomes.',
	},
	[WebInsightsTabsType.SSVIOverview]: {
		title: 'Overview',
		description: '',
	},
};

const getWebInsightsTabConfig = (
	curTab: WebInsightsTabsType
): IWebInsightsTab => {
	return WebInsightsTabsOptions.find((tab) => tab.value === curTab)!;
};

const getStaticFilters = (
	tabType: WebInsightsTabsType
): {[key in CommonFiltersConfig]?: string} => {
	return tabType === WebInsightsTabsType.Overview
		? {}
		: {
				[CommonFiltersConfig.isp_status]: 'Non-ISP',
				[CommonFiltersConfig.match_status]: 'Matched Visits',
		  };
};

const WebInsightsOptionConfig: Array<QuickType> = [
	QuickType.Today,
	QuickType.Yesterday,
	QuickType.Last7Days,
	QuickType.Last14Days,
	QuickType.Last28Days,
	QuickType.Last30Days,
	QuickType.Last90Days,
	QuickType.Last180Days,
	QuickType.Last365Days,
	QuickType.ThisWeek,
	QuickType.ThisMonth,
	QuickType.ThisQuarter,
	QuickType.ThisYear,
	QuickType.PreviousWeek,
	QuickType.PreviousMonth,
	QuickType.PreviousQuarter,
	QuickType.PreviousYear,
	QuickType.Custom,
];

const CommonWebInsightsFiltersMap: ICommonWebInsightsFiltersMap = {
	[CommonFiltersConfig.domain]: {
		key: CommonSelectLabelConfig.DomainName,
		filterType: CommonFiltersConfig.domain,
		placeholder: CommonSelectLabelPlaceholderConfig.AllDomains,
		selectType: SelectType.Single,
		icon: (
			<PublicIcon
				fontSize='small'
				sx={{
					color: (theme) => theme.colors.ColorGraySecondary,
				}}
			/>
		),
		minWidth: '220px',
	},
	[CommonFiltersConfig.account_list_mapping_list_name]: {
		key: CommonSelectLabelConfig.Company,
		filterType: CommonFiltersConfig.account_list_mapping_list_name,
		placeholder: CommonSelectLabelPlaceholderConfig.SelectTargetCompanyList,
		selectType: SelectType.Single,
		icon: (
			<DescriptionOutlinedIcon
				fontSize='small'
				sx={{
					color: (theme) => theme.colors.ColorGraySecondary,
				}}
			/>
		),
		minWidth: '220px',
	},
	[CommonFiltersConfig.time_range]: {
		key: CommonSelectLabelConfig.DateRange,
		filterType: CommonFiltersConfig.time_range,
		selectType: SelectType.TimeRange,
	},
	[CommonFiltersConfig.time_zone]: {
		key: CommonSelectLabelConfig.TimeZone,
		filterType: CommonFiltersConfig.time_zone,
		selectType: SelectType.Single,
	},
	[CommonFiltersConfig.isp_status]: {
		key: CommonSelectLabelConfig.ISP,
		title: CommonSelectLabelConfig.ISP,
		filterType: CommonFiltersConfig.isp_status,
		placeholder: CommonSelectLabelPlaceholderConfig.AllVisitorTypes,
		selectType: SelectType.Single,
	},
	[CommonFiltersConfig.utm_campaign]: {
		key: CommonSelectLabelConfig.Campaign,
		title: CommonSelectLabelConfig.Campaign,
		filterType: CommonFiltersConfig.utm_campaign,
		placeholder: CommonSelectLabelPlaceholderConfig.AllCampaigns,
		selectType: SelectType.Multiple,
		menuType: MenuType.Campaigns,
	},
	[CommonFiltersConfig.utm_source]: {
		key: CommonSelectLabelConfig.Channel,
		title: CommonSelectLabelConfig.Channel,
		filterType: CommonFiltersConfig.utm_source,
		placeholder: CommonSelectLabelPlaceholderConfig.AllChannels,
		selectType: SelectType.Multiple,
		menuType: MenuType.Channels,
	},
	[CommonFiltersConfig.match_status]: {
		key: CommonSelectLabelConfig.MatchType,
		title: CommonSelectLabelConfig.MatchType,
		filterType: CommonFiltersConfig.match_status,
		placeholder: CommonSelectLabelPlaceholderConfig.AllMatchTypes,
		selectType: SelectType.Single,
	},
	[CommonFiltersConfig.utm_content]: {
		key: CommonSelectLabelConfig.Content,
		title: CommonSelectLabelConfig.Content,
		filterType: CommonFiltersConfig.utm_content,
		placeholder: CommonSelectLabelPlaceholderConfig.AllContents,
		selectType: SelectType.Multiple,
		menuType: MenuType.Contents,
	},
	[CommonFiltersConfig.industry]: {
		key: CommonSelectLabelConfig.Industry,
		title: CommonSelectLabelConfig.Industry,
		filterType: CommonFiltersConfig.industry,
		placeholder: CommonSelectLabelPlaceholderConfig.AllIndustries,
		selectType: SelectType.Multiple,
		menuType: MenuType.Industries,
	},
	[CommonFiltersConfig.revenue_range]: {
		key: CommonSelectLabelConfig.Revenue,
		title: CommonSelectLabelConfig.Revenue,
		filterType: CommonFiltersConfig.revenue_range,
		placeholder: CommonSelectLabelPlaceholderConfig.AllRevenueBands,
		selectType: SelectType.Multiple,
		menuType: MenuType.Revenues,
	},
	[CommonFiltersConfig.employee_range]: {
		key: CommonSelectLabelConfig.EmployeeCount,
		title: CommonSelectLabelConfig.EmployeeCount,
		filterType: CommonFiltersConfig.employee_range,
		placeholder: CommonSelectLabelPlaceholderConfig.AllEmployeeCountBands,
		selectType: SelectType.Multiple,
		menuType: MenuType.EmployeesCount,
	},
	[CommonFiltersConfig.country]: {
		key: CommonSelectLabelConfig.Country,
		title: CommonSelectLabelConfig.Country,
		filterType: CommonFiltersConfig.country,
		placeholder: CommonSelectLabelPlaceholderConfig.AllCountries,
		selectType: SelectType.Multiple,
		menuType: MenuType.Countries,
	},
	[CommonFiltersConfig.job_function]: {
		key: CommonSelectLabelConfig.JobFunction,
		title: CommonSelectLabelConfig.JobFunction,
		filterType: CommonFiltersConfig.job_function,
		placeholder: CommonSelectLabelPlaceholderConfig.AllJobFunctions,
		selectType: SelectType.Multiple,
		menuType: MenuType.JobFunctions,
	},
	[CommonFiltersConfig.job_seniority]: {
		key: CommonSelectLabelConfig.JobSeniority,
		title: CommonSelectLabelConfig.JobSeniority,
		filterType: CommonFiltersConfig.job_seniority,
		placeholder: CommonSelectLabelPlaceholderConfig.AllJobSeniorityLevels,
		selectType: SelectType.Multiple,
		menuType: MenuType.JobSeniorities,
	},
	[CommonFiltersConfig.page_groups_page_group]: {
		key: CommonSelectLabelConfig.PageGroup,
		title: CommonSelectLabelConfig.PageGroup,
		filterType: CommonFiltersConfig.page_groups_page_group,
		placeholder: CommonSelectLabelPlaceholderConfig.AllPageGroups,
		selectType: SelectType.Multiple,
		menuType: MenuType.PageGroups,
	},
};

const CommonTopDetailFiltersArray = [
	CommonFiltersConfig.time_range,
	CommonFiltersConfig.time_zone,
	CommonFiltersConfig.job_function,
];

const CommonTopDetailFilterListConfig = [
	{
		...CommonWebInsightsFiltersMap[CommonFiltersConfig.time_range],
		title: CommonSelectLabelConfig.DateRange,
	},
	CommonWebInsightsFiltersMap[CommonFiltersConfig.job_function],
];

const CommonTopIndexFilterListConfig = [
	CommonWebInsightsFiltersMap[CommonFiltersConfig.domain],
	CommonWebInsightsFiltersMap[
		CommonFiltersConfig.account_list_mapping_list_name
	],
	CommonWebInsightsFiltersMap[CommonFiltersConfig.time_range],
];

const CompanyDetailColumns = [
	{
		title: 'DUNS Number',
		field: CompanyDetailFields.DunsNumber,
	},
	{
		title: 'Industry',
		field: CompanyDetailFields.Industry,
	},
	{
		title: 'Location',
		field: CompanyDetailFields.State,
	},
	{
		title: 'Revenue',
		field: CompanyDetailFields.RevenueRange,
	},
	{
		title: 'Employee Count',
		field: CompanyDetailFields.EmployeeRange,
	},
];

const OverviewFiltersMap: IOverviewFiltersMap = {
	[CommonFiltersConfig.domain]: {
		display_name: CommonFiltersConfig.domain,
		value: '',
	},
	[CommonFiltersConfig.account_list_mapping_list_name]: {
		display_name: CommonFiltersConfig.account_list_mapping_list_name,
		value: '',
	},
	[CommonFiltersConfig.time_range]: {
		display_name: CommonFiltersConfig.time_range,
		value: QuickType.Last7Days,
	},
	[CommonFiltersConfig.isp_status]: {
		display_name: CommonFiltersConfig.isp_status,
		value: 'Non-ISP',
	},
	[CommonFiltersConfig.utm_campaign]: {
		display_name: CommonFiltersConfig.utm_campaign,
		value: '',
	},
	[CommonFiltersConfig.utm_source]: {
		display_name: CommonFiltersConfig.utm_source,
		value: '',
	},
	[CommonFiltersConfig.match_status]: {
		display_name: CommonFiltersConfig.match_status,
		value: '',
	},
	[CommonFiltersConfig.utm_content]: {
		display_name: CommonFiltersConfig.utm_content,
		value: '',
	},
	[CommonFiltersConfig.industry]: {
		display_name: CommonFiltersConfig.industry,
		value: '',
	},
	[CommonFiltersConfig.revenue_range]: {
		display_name: CommonFiltersConfig.revenue_range,
		value: '',
	},
	[CommonFiltersConfig.employee_range]: {
		display_name: CommonFiltersConfig.employee_range,
		value: '',
	},
	[CommonFiltersConfig.country]: {
		display_name: CommonFiltersConfig.country,
		value: '',
	},
	[CommonFiltersConfig.job_function]: {
		display_name: CommonFiltersConfig.job_function,
		value: '',
	},
	[CommonFiltersConfig.job_seniority]: {
		display_name: CommonFiltersConfig.job_seniority,
		value: '',
	},
	[CommonFiltersConfig.time_zone]: {
		display_name: CommonFiltersConfig.time_zone,
		value: localTimeZone,
	},
	[CommonFiltersConfig.page_groups_page_group]: {
		display_name: CommonFiltersConfig.page_groups_page_group,
		value: '',
	},
};

export {
	localTimeZone,
	rowHeight,
	indexColumnWidth,
	indexColumnField,
	WebInsightsTabsOptions,
	WebInsightsHeaderMessageConfig,
	WebInsightsRoutes,
	WebInsightsTabsTypeConfig,
	webInsightsIframeID,
	getWebInsightsTabConfig,
	getStaticFilters,
	WebInsightsOptionConfig,
	StateList,
	AngularStateList,
	StateLisToSelectedTab,
	SSVIStorageNamespace,
	defaultFilterGroupId,
	CommonTopDetailFiltersArray,
	CommonTopDetailFilterListConfig,
	CompanyDetailColumns,
	CommonWebInsightsFiltersMap,
	CommonTopIndexFilterListConfig,
	SSVIInfoStorageNamespace,
	OverviewFiltersMap,
	APIErrorMessage,
};
