import {ISegment} from 'atlas/data/SegmentConst';
import {getQueryProperty} from 'common/stores/query';
import {
	isBuyerJourneyEnabled,
	isBuyerJourneyAnalyticsEnabled,
} from 'common/stores/tenantConfig';
import {ITabType} from './SegmentTabsHelper';

type ITabCount = {value: number; loading?: boolean};
type ITabCountMap = Partial<Record<ITabType, ITabCount>>;

type ISegmentHelper = {
	segment: ISegment;
	isCreateMode: () => boolean;
	isListSegment: () => boolean;
	showDashboard: () => boolean;
	counts: () => ITabCountMap;
	getSegmentName: () => string;
};

class SegmentHelper implements ISegmentHelper {
	public segment: ISegment;

	constructor(segment: ISegment) {
		this.segment = segment;
	}

	isCreateMode = (): boolean =>
		!this.segment.name || this.segment.name === 'Create';

	isListSegment = (): boolean => {
		const {segment} = this;
		return segment.type === 'List';
	};

	showDashboard = (): boolean => {
		const {
			isCreateMode,
			isListSegment,
			segment: {isSubSegment},
		} = this;
		return (
			(isBuyerJourneyEnabled() || isBuyerJourneyAnalyticsEnabled()) &&
			!isCreateMode() &&
			!isListSegment() &&
			!isSubSegment
		);
	};

	counts = (): ITabCountMap => {
		const {
			segment: {accounts, contacts},
			isListSegment,
		} = this;
		return isListSegment()
			? {
					accounts: {value: accounts},
					contacts: {value: contacts},
			  }
			: getQueryProperty('counts');
	};

	getSegmentName = (): string => {
		const {
			segment: {display_name},
		} = this;
		return display_name || 'Segments';
	};
}

export type {ITabCount, ITabCountMap, ISegmentHelper};
export {SegmentHelper};
