import React from 'common/react-vendor';
import {isDescending, sortKey} from 'common/widgets/dataviews/column-sort';
import classnames from 'classnames';
import {sanitizeSegment} from 'common/components/datacloud/segment/segment.helpers';
import {Query} from 'common/components/datacloud/datacloud.types';
import {getEntities} from 'common/components/datacloud/query/query';
import {
	EntitiesCountsResponse,
	getEntitiesCounts,
	getSanitizedQuery,
} from 'common/components/datacloud/query/queryService.queries';
import {
	TableQuery,
	QueryResult,
	AccountsPage,
	ContactsPage,
	ResultsPageProps,
} from 'common/components/datacloud/query/results/rebuild';
import {IEntityType} from 'common/components/datacloud/journeystage/journey.helpers';
import {SegmentNgState, navigate} from 'atlas/helpers/NavigateHelper';
import {EntityType} from 'atlas/data/RestrictionConst';
import {
	API,
	IGetEntityCountResponse,
	IGetEntityData,
} from 'atlas/JourneyStage/ApiCalls';
import {setJourneyEntitiesProperty} from 'common/stores/query';
import {setMetadataPropertyValue} from 'common/stores/datacloud';
import {store} from 'store';
import {getJourneyStageOverview} from '../SegmentDashboard/ApiCalls';

/**
 * EntityComponentMap interface
 * @param key @IEntityType
 * @param value Rendered component according to key of @EntitiesCountsResponse
 */
type IEntityComponentMap = Record<
	IEntityType,
	{
		fieldKey: keyof EntitiesCountsResponse;
		component: (prop: ResultsPageProps) => JSX.Element;
	}
>;

/**
 * Entity Component Map
 */
const EntityComponentMap: IEntityComponentMap = {
	accounts: {
		fieldKey: 'Account',
		component: AccountsPage,
	},
	contacts: {
		fieldKey: 'Contact',
		component: ContactsPage,
	},
};

/**
 * EntityPage interface.
 * @param stageIds Stage id list.
 * @param stageIdForSubStages Parent stage if for sub stages.
 * @param entityType @IEntityType
 * @param filter Filter state.
 * @param segment Segment name.
 * @param restriction Query restriction.
 */
export interface IEntityPage {
	stageIds?: number[];
	stageId?: number;
	entityType: IEntityType;
	filter?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	segment: string;
	restriction: Query;
}

/**
 * Get total entity count.
 * @param input Entity count list.
 * @returns The total entity count.
 */
const totalEntityCount = (
	input: IGetEntityCountResponse[]
): IGetEntityCountResponse => {
	return input.reduce(
		(pre, {totalAccount, totalContact}) => ({
			totalAccount: pre.totalAccount + totalAccount,
			totalContact: pre.totalContact + totalContact,
		}),
		{totalAccount: 0, totalContact: 0} as IGetEntityCountResponse
	);
};

const getEntityType2 = (entity: IEntityType): EntityType => {
	return entity === 'accounts' ? EntityType.Account : EntityType.Contact;
};

type IRunQuery = {
	restriction: Query;
	q: TableQuery;
	lookups: common.QueryAttribute[];
};

const sanitizeQuery = ({
	restriction,
	q: {textSearch, pagination, sort},
	lookups,
}: IRunQuery): Query => {
	// sanitizeSegment mutates its arg, so we'll isolate any possibility of
	// mutation to the externals
	const restrictions = getSanitizedQuery(sanitizeSegment(restriction));
	const query: Query = {
		...restrictions,
		free_form_text_search: textSearch,
		restrict_without_sfdcid: false,
		page_filter: {
			row_offset: pagination.page * pagination.pageSize,
			num_rows: pagination.pageSize,
		},
		lookups,
	};
	const [entity, attribute] = sortKey(sort)?.split('.') || [];
	if (
		entity &&
		attribute &&
		// TODO: document implicit behavior (sort only takes effect if we also lookup the attribute value)
		lookups.some(
			({attribute: attr}) =>
				attr.entity === entity && attr.attribute === attribute
		)
	) {
		query.sort = {
			attributes: [
				{
					attribute: {
						attribute,
						entity,
					},
				},
			],
			descending: isDescending(sort),
		};
	}
	return query;
};

const runQuery =
	(entityType: IEntityType, restriction: Query) =>
	async (
		q: TableQuery,
		lookups: common.QueryAttribute[]
	): Promise<QueryResult> => {
		const query = sanitizeQuery({
			restriction,
			q,
			lookups,
		});
		// Start both requests in parallel
		const entitiesPromise = getEntities(entityType, query);
		const entityCountPromise = getEntitiesCounts(query).then((data) => {
			const key = EntityComponentMap[entityType].fieldKey;
			return data[key];
		});
		return {
			entities: await entitiesPromise,
			entityCount: await entityCountPromise,
		};
	};

const runQueryWithStageIds =
	(
		{
			segmentName,
			businessEntity,
			stageIds = [],
			stageIdForSubStages,
		}: IGetEntityData,
		restriction: Query
	) =>
	async (
		q: TableQuery,
		lookups: common.QueryAttribute[]
	): Promise<QueryResult> => {
		const query = sanitizeQuery({
			restriction,
			q,
			lookups,
		});
		setJourneyEntitiesProperty({
			accounts: {value: 0, loading: true},
			contacts: {value: 0, loading: true},
		});
		if (!businessEntity)
			return {
				entities: [],
				entityCount: 0,
			};
		const [{data}, stagesData] = await Promise.all([
			API.getEntityData({
				segmentName,
				businessEntity,
				stageIds,
				stageIdForSubStages,
				lookups: query.lookups,
				pageFilter: query.page_filter,
				sort: query.sort,
			}),
			await getJourneyStageOverview(segmentName, stageIdForSubStages),
			// await API.getEntityCount(segmentName, stageIds),
		]);
		const {totalAccount, totalContact} = totalEntityCount(
			stagesData.filter(({stageId}) => stageIds.includes(stageId))
		);
		setJourneyEntitiesProperty({
			accounts: {value: totalAccount, loading: false},
			contacts: {value: totalContact, loading: false},
		});
		return {
			entities: data[businessEntity]?.data || [],
			entityCount:
				businessEntity === EntityType.Account ? totalAccount : totalContact,
		};
	};

/**
 * Accounts and Contacts page component.
 * @param prop @IEntityPage
 */
function EntityPage({
	stageIds,
	stageId,
	entityType = 'accounts',
	filter,
	segment,
	restriction,
}: IEntityPage): JSX.Element {
	const openAccount = (queryParams: Record<string, string>): void => {
		store.dispatch(
			setMetadataPropertyValue('accountName', queryParams.CompanyName)
		);
		navigate(SegmentNgState.Contact, queryParams);
	};
	const onChangeSegment = (segmentName: string): void =>
		console.log(segmentName);
	const [isFilterEnabled] = filter || [false];
	const {component: EntityComponent} = EntityComponentMap[entityType];
	const hasStageIds = !!stageIds?.length;
	return (
		<div className={classnames('results-page', {ShowFilter: isFilterEnabled})}>
			<EntityComponent
				entityType={entityType}
				filter={filter!}
				segment={segment}
				runQuery={
					hasStageIds
						? runQueryWithStageIds(
								{
									segmentName: segment,
									businessEntity: getEntityType2(entityType),
									stageIds,
									stageIdForSubStages: stageId,
								},
								restriction
						  )
						: runQuery(entityType, restriction)
				}
				onOpenAccount={openAccount}
				onChangeSegment={onChangeSegment}
			/>
		</div>
	);
}

export {EntityPage};
