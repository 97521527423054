export const truncate = (
	string: string,
	limit: number,
	dots = '...'
): string => {
	if (string.length > limit) {
		return string.substring(0, limit) + dots;
	}
	return string;
};

export const generateRandomString = (
	length = 16,
	charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
): string =>
	new Array(length)
		.fill(0)
		.map(() => charset.charAt(Math.floor(Math.random() * charset.length)))
		.join('');

export const isEmptyStringOrNull = (string: string | null = null): boolean =>
	string === null || string.trim() === '';

export const addCommas = (number: number | null): string | null => {
	if (number === null) {
		return null;
	}
	return new Intl.NumberFormat().format(number);
};

export const substituteAllSpecialCharsWithDashes = (string: string): string =>
	string.trim().replace(/\W/g, '-');

export const substituteAllSpecialCharsWithSpaces = (string: string): string =>
	string.trim().replace(/\W/g, ' ');

export const capitalizeFirstLetter = (string: string): string =>
	string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const removeAllWhiteSpace = (string: string): string =>
	string.replace(/ /g, '');
