import {axios} from 'common/network.vendor';
import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {
	getMetadataForCategory,
	loadCategoryMetadata,
} from '../datacloud.service.vanilla';
import {
	AnyResponse,
	AttrOverview,
	AttrOverviewResponse,
	AttrSelection,
	AttributeSet,
	Section,
} from './AttrConfig.types';

const getOverview = async (section: Section): Promise<AttrOverview> => {
	const categoryMetadata = await loadCategoryMetadata();

	return axiosInstance
		.get<AttrOverviewResponse>(`/pls/attrconfig/${section}/overview`)
		.then((res) => {
			const data = {
				Selections: res.data.Selections.map((s: AttrSelection) => ({
					...s,
					metadata: getMetadataForCategory(categoryMetadata, s.DisplayName),
				})),
			};
			return {...res, data};
		});
};

const getConfig = (
	section: Section,
	category: string,
	params: unknown
): Promise<AnyResponse> => {
	return axiosInstance.get(
		`/pls/attrconfig/${encodeURIComponent(
			section
		)}/config/category/${encodeURIComponent(category)}`,
		{params}
	);
};

const getBucketData = (
	category: string,
	subcategory: string
): Promise<AnyResponse> =>
	axiosInstance.get(`/pls/attrconfig/stats/category/${category}`, {
		params: {
			subcategory,
		},
	});

const putConfig = (
	section: Section,
	category: string,
	params: Record<string, unknown>,
	data: unknown
): Promise<AnyResponse> =>
	axiosInstance
		.put<AnyResponse>(
			`/pls/attrconfig/${section}/config/category/${category}`,
			data,
			{
				params,
			}
		)
		.then(
			(r) => r,
			(r) => r
		);

const refreshAttributes = (): Promise<unknown> => {
	return Promise.resolve({});
}; // unimplemented stub?

const getUsageOverviewByAttributeSet = (
	attributeSetName: string | null = null
): Promise<AnyResponse> =>
	axiosInstance.get(`/pls/attrconfig/usage/overview/attributeset`, {
		params: {attributeSetName},
	});

const getAttributeSet = (attributeSetName: string): Promise<AnyResponse> =>
	axiosInstance
		.get(`/pls/attrconfig/attributeset/name/${attributeSetName}`)
		.then(
			(r) => r,
			(r) => r
		);

const getAttributeSets = (): Promise<AnyResponse> =>
	axiosInstance.get('/pls/attrconfig/attributeset').then(
		(r) => r,
		(r) => r
	);

const createNewAttributeSet = (
	attributeSetName: string
): Promise<AnyResponse> =>
	axiosInstance.post('/pls/attrconfig/attributeset', {
		description: 'No description has been provided yet.',
		displayName: attributeSetName,
	});

const cloneAttributeSet = (
	attributeSet: AttributeSet
): Promise<AnyResponse> => {
	const {description, displayName, name} = attributeSet;
	return axiosInstance
		.post(`/pls/attrconfig/attributeset/clone?attributeSetName=${name}`, {
			description,
			displayName: `${displayName} (Copy)`,
		})
		.then(
			(r) => r,
			(r) => r
		);
};

const updateAttributeSetDisplayName = (
	name: string,
	displayName: string
): Promise<AnyResponse> =>
	axios
		.put('/pls/attrconfig/attributeset', {
			displayName,
			name,
		})
		.then(
			(r) => r,
			(r) => r
		);

const updateAttributeSetDescription = (
	name: string,
	description: string
): Promise<AnyResponse> =>
	axiosInstance
		.put('/pls/attrconfig/attributeset', {
			description,
			name,
		})
		.then(
			(r) => r,
			(r) => r
		);

const updateAttributeSetAttributes = (
	name: string,
	attributesMap: unknown
): Promise<AnyResponse> =>
	axiosInstance.put('/pls/attrconfig/attributeset', {
		attributesMap,
		name,
	});

const deleteAttributeSet = (attributeSetName: string): Promise<AnyResponse> =>
	axiosInstance.delete(`/pls/attrconfig/attributeset/name/${attributeSetName}`);

const getProfileReportStatus = (): Promise<AnyResponse> =>
	axiosInstance.get('/pls/profile-reports/status');

const refreshProfileReport = (): Promise<AnyResponse> =>
	axiosInstance.post('/pls/profile-reports/refresh').then(({data}) => data);

export default {
	getOverview,
	getConfig,
	getBucketData,
	putConfig,
	refreshAttributes,
	getUsageOverviewByAttributeSet,
	getAttributeSet,
	getAttributeSets,
	createNewAttributeSet,
	cloneAttributeSet,
	updateAttributeSetDisplayName,
	updateAttributeSetDescription,
	updateAttributeSetAttributes,
	deleteAttributeSet,
	getProfileReportStatus,
	refreshProfileReport,
};
