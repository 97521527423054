import React from 'common/react-vendor';
import cx from 'classnames';
import {DNBTypography, designTokens} from 'common/dnb-uux-vendor';
import {Checkbox} from 'widgets/checkbox';
import {Selections, Column, Category, addColumns, removeColumns} from './index';
import styles from './index.module.css';
import {
	DisabledCategoryTooltip,
	DisabledColumnTooltip,
} from './tooltip/DisabledCategoryTooltip';

const {useState, useEffect, useMemo, useCallback, useRef} = React;

interface ColumnSelectionProps {
	selections: Selections;
	setSelections: React.Dispatch<React.SetStateAction<Selections>>;
	defaultGroupId: string;
	columns: Record<string, Column>;
	categories: Category[];
}

export function ColumnSelection({
	selections,
	setSelections,
	defaultGroupId,
	columns,
	categories,
}: ColumnSelectionProps): JSX.Element {
	const [search, setSearch] = useState('');
	const filteredCategories = useMemo(
		() => filterToggles(categories, columns, search),
		[categories, columns, search]
	);

	const [activeCategory, setActiveCategory] = useState<string | null>(null);
	const wrapper = useRef<HTMLUListElement>(null);

	const scrollTo = useCallback((category: string) => {
		const section = wrapper.current?.querySelector(
			`[data-categoryId="${category}"]`
		);
		section?.scrollIntoView({behavior: 'smooth'});
	}, []);

	const handleScroll = useCallback(() => {
		if (!wrapper.current) return;
		const {scrollTop, scrollHeight, clientHeight} = wrapper.current;
		const categories: NodeListOf<HTMLElement> =
			wrapper.current.querySelectorAll('[data-categoryId]');
		const terminator = scrollTop / (scrollHeight - clientHeight);
		let i = 0;
		while (
			categories[i + 1] &&
			// TODO: explain the math & method here
			(categories[i + 1]!.offsetTop - scrollTop) / clientHeight - terminator < 0
		)
			i++;
		const category = categories[i]?.dataset.category;
		if (category && category !== activeCategory) setActiveCategory(category);
	}, [activeCategory]);

	// sync the current category whenever the output list changes
	useEffect(() => handleScroll(), [handleScroll, filteredCategories]);

	// sync the current category when the wrapper size changes
	useEffect(() => {
		if (!wrapper.current) return;
		const observer = new ResizeObserver(handleScroll);
		observer.observe(wrapper.current);
		return () => observer.disconnect();
	}, [handleScroll]);

	const selectedCols = React.useMemo(
		() =>
			Object.values(selections)
				.flat()
				.filter((column) => !columns[column]?.disabled),
		[columns, selections]
	);

	const categoryDisabledMap = React.useMemo(() => {
		return filteredCategories.reduce((pre, {id, colIds}) => {
			return {...pre, [id]: colIds.every((id) => columns[id]?.disabled)};
		}, {} as Record<string, boolean>);
	}, [columns, filteredCategories]);

	return (
		<>
			<ul className={styles.categories}>
				{filteredCategories.map(({id, name}) => {
					const disabled = categoryDisabledMap[id];
					return (
						<div className={styles.categoryRow}>
							<li
								key={id}
								className={cx(
									styles.category,
									activeCategory === id && styles.active
								)}>
								<button
									className={cx({
										[styles.columnDisabled!]: disabled,
									})}
									type='button'
									onClick={() => scrollTo(id)}
									disabled={disabled}>
									{name}
								</button>
							</li>
							{disabled && <DisabledCategoryTooltip categoryName={name} />}
						</div>
					);
				})}
			</ul>
			<div className={styles.toggles}>
				<div className={styles.searchBar}>
					<input
						className={styles.searchInput}
						placeholder='Search columns'
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</div>
				<ul
					className={styles.togglesTree}
					ref={wrapper}
					onScroll={handleScroll}>
					{filteredCategories.length ? (
						filteredCategories.map(({id, name, colIds}) => {
							const enabledColIds = colIds.filter(
								(id) => !columns[id]?.disabled
							);
							const selectedCount = colIds.filter((c) =>
								selectedCols.includes(c)
							).length;
							const disabled = categoryDisabledMap[id];
							return (
								<li key={id} data-categoryId={id} data-categoryName={name}>
									<div className={styles.togglesTreeLabel}>
										<span
											className={cx(styles.columnCheckBox, {
												[styles.columnDisabled!]: disabled,
											})}>
											<Checkbox
												checked={
													!!enabledColIds.length &&
													enabledColIds.every((c) => selectedCols.includes(c))
												}
												disabled={disabled}
												indeterminate={
													enabledColIds.some((c) => selectedCols.includes(c)) &&
													enabledColIds.some((c) => !selectedCols.includes(c))
												}
												onChange={(isAdding) => {
													const toggle = isAdding ? addColumns : removeColumns;
													setSelections(
														toggle(enabledColIds, defaultGroupId, columns)
													);
												}}
											/>
											{` ${name} `}
											{selectedCount ? (
												<DNBTypography
													variant='compact-body'
													color={designTokens.TokenColors.ColorPrimaryBlue}
													component='span'>
													({selectedCount})
												</DNBTypography>
											) : null}
										</span>
										{disabled && (
											<DisabledCategoryTooltip categoryName={name} />
										)}
									</div>
									<ul>
										{colIds.map((colId) => {
											const columnDisabled = columns[colId]?.disabled;
											const showTooltip = !disabled && columnDisabled;
											return (
												<li key={colId}>
													<div className={styles.toggleItemLabel}>
														<span
															className={cx(styles.columnCheckBox, {
																[styles.columnDisabled!]: columnDisabled,
															})}>
															<Checkbox
																checked={selectedCols.includes(colId)}
																disabled={columnDisabled}
																onChange={(isAdding) => {
																	const toggle = isAdding
																		? addColumns
																		: removeColumns;
																	setSelections(
																		toggle([colId], defaultGroupId, columns)
																	);
																}}
															/>{' '}
															{columns[colId]!.name}
														</span>
														{showTooltip && <DisabledColumnTooltip />}
													</div>
												</li>
											);
										})}
									</ul>
								</li>
							);
						})
					) : (
						<>No columns</>
					)}
				</ul>
			</div>
		</>
	);
}

function filterToggles(
	categories: Category[],
	columns: Record<string, Column>,
	text: string
): Category[] {
	const searchText = text.toLowerCase();
	return categories
		.map((category) => ({
			...category,
			colIds: category.colIds.filter((colId) =>
				columns[colId]?.name.toLowerCase().includes(searchText)
			),
		}))
		.filter((category) => category.colIds.length > 0);
}
