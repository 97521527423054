import React from 'common/react-vendor';
import {SegmentNgState} from 'atlas/helpers/NavigateHelper';
import {Box} from 'common/dnb-uux-vendor';
import {
	API,
	ISubJourneyStage,
} from 'atlas/segmentation/SegmentDashboard/ApiCalls';
import {SubstageTitleHeader} from 'atlas/segmentation/Components/SubstageTitleHeader';
import {
	dispatchQueryPropertyValue,
	getQueryProperty,
} from 'common/stores/query';
import {ISegment} from 'atlas/data/SegmentConst';
import {
	CommonBreadcrumbs,
	ICommonBreadcrumbs,
} from '../../../../../atlas/app/connectors/EIF/Component/CommonBreadcrumbs';
import {ISegmentEditHeaderX} from '../segmentheader/SegmentEditHeaderX';

/**
 * ISubstageEditHeader
 * @param $state Angular state
 * @param subStage @ISubJourneyStage
 */
type ISubstageEditHeader = ISegmentEditHeaderX & {
	subStage?: ISubJourneyStage;
};

type ISegmentSubstage = {
	stageId?: number;
	subStageId?: number;
	subStageName?: string;
};

/**
 * SubstageEditHeader
 * @param prop @SubstageEditHeader
 * @returns
 */
function SubstageEditHeader({
	$state,
	subStage,
}: ISubstageEditHeader): JSX.Element {
	const {name: segment, display_name: segmentDispayName} =
		getQueryProperty<ISegment>('segment');
	const [name, setName] = React.useState(subStage?.name || '');
	const [description, setDescription] = React.useState(
		subStage?.description || ''
	);
	const breadcrumbsList: ICommonBreadcrumbs[] = React.useMemo(() => {
		return [
			{
				text: 'Segments',
				onClick: () => {
					$state.go('home.segments_new', {}, {reload: true});
				},
				isLink: true,
			},
			{
				text: segmentDispayName,
				isLink: true,
				onClick: () => {
					$state.go(
						SegmentNgState.Dashboard,
						{
							segment,
						},
						{reload: true}
					);
				},
				hide: !segment,
			},
			{
				text: name || 'Create a Substage',
				isLink: false,
			},
		];
	}, [$state, segmentDispayName, name, segment]);
	const showInput = !!name && !!subStage;
	return (
		<Box
			sx={{
				backgroundColor: 'white',
			}}>
			<Box sx={{margin: 6, display: 'flex', flexDirection: 'column', gap: 6}}>
				<CommonBreadcrumbs
					breadcrumbsList={breadcrumbsList.filter(({hide}) => !hide)}
				/>
				{showInput && (
					<SubstageTitleHeader
						buttons={[]}
						action={{
							onOkay: async (newData) => {
								if (!newData) return;
								const {status} = await API.createOrUpdateSubJourneyStage({
									...subStage,
									name: newData.name,
									description: newData.description,
								});
								if (status !== 200) return;
								const {name, description} = newData;
								setName(name);
								setDescription(description);
								dispatchQueryPropertyValue<ISubJourneyStage>(
									'segmentSubstage',
									newData as ISubJourneyStage
								);
							},
						}}
						data={{name, description, id: subStage.pid}}
					/>
				)}
			</Box>
		</Box>
	);
}

export type {ISegmentSubstage, ISubstageEditHeader};
export {SubstageEditHeader};
