import {axios, Observable} from '../../network.vendor';
import messageService from '../utilities/message-service';
import Response from './response';
import Error from './error';
import Observables from './observables';
import Message, {BANNER, ERROR} from '../utilities/message';

/**
 *
 * @param {*} axiosObj
 * @param {*} obj
 */
const setParams = (axiosObj, obj) => {
	if (obj) {
		Object.keys(obj).forEach((key) => {
			axiosObj[key] = obj[key];
		});
	}
};

const init = () => {
	if (!window.http) {
		window.http = axios;
		window.observables = new Observables();
	}
};

/**
 * http service
 */
const httpService = {
	constructor: () => {
		const {http} = window;
	},
	/**
	 * Set up headeer for all the requests
	 */
	setUpHeader: (headerObj) => {
		setParams(http.defaults.headers.common, headerObj);
	},
	unsubscribeObservable: (observer) => {
		window.observables.removeObservable(observer.getName());
	},
	get: (url, observer, headers, params, signal) => {
		const observable = Observable.create((obs) => {
			http
				.get(url, {
					headers: headers || {},
					params: params || {},
					signal
				})
				.then((response) => {
					const resp = new Response(
						response,
						response.status,
						response.statusText,
						response.data
					);

					if (
						response?.data?.UIAction?.title ||
						response?.data?.UIAction?.message
					) {
						messageService.sendMessage(new Message(response, '', '', '', ''));
					}

					obs.next(resp);
					obs.complete();
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						console.error('Request canceled: ', error.message);
						return;
					}

					const respoError = new Error(
						error.response.status,
						error.response.statusText,
						error.message
					);

					messageService.sendMessage(
						new Message(
							error.response,
							BANNER,
							ERROR,
							respoError.getMsg(),
							`${url} ${respoError.getFullMessage()}`
						)
					);

					if (obs.error) {
						obs.error(respoError);
					}

					obs.complete();
				});
		}).subscribe(observer);
		window.observables.addObservable(observer.getName(), observable);
	},

	post: (url, body, observer, headers, config = {}) => {
		const observable = Observable.create((obs) => {
			http
				.post(url, body, {headers: headers || {}, ...config})
				.then((response) => {
					const resp = new Response(
						response,
						response.status,
						response.statusText,
						response.data
					);

					if (
						response?.data?.UIAction?.title ||
						response?.data?.UIAction?.message
					) {
						messageService.sendMessage(new Message(response, '', '', '', ''));
					}

					obs.next(resp);
					obs.complete();
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						console.error('Request canceled: ', error.message);
						return;
					}

					const respoError = new Error(
						error.response.status,
						error.response.statusText,
						error.message,
						error?.response?.data
					);

					messageService.sendMessage(
						new Message(
							error.response,
							BANNER,
							ERROR,
							respoError.getMsg(),
							`${url} ${respoError.getFullMessage()}`
						)
					);

					if (obs.error) {
						obs.error(respoError);
					}

					obs.complete();
				});
		}).subscribe(observer);
		window.observables.addObservable(observer.getName(), observable);
	},
	put: (url, body, observer, headers, signal) => {
		const observable = Observable.create((obs) => {
			http
				.put(url, body, {headers: headers || {}, signal})
				.then((response) => {
					const resp = new Response(
						response,
						response.status,
						response.statusText,
						response.data
					);

					if (
						response?.data?.UIAction?.title ||
						response?.data?.UIAction?.message
					) {
						messageService.sendMessage(new Message(response, '', '', '', ''));
					}

					obs.next(resp);
					obs.complete();
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						console.error('Request canceled: ', error.message);
						return;
					}

					const respoError = new Error(
						error.response.status,
						error.response.statusText,
						error.message
					);

					messageService.sendMessage(
						new Message(
							error.response,
							BANNER,
							ERROR,
							respoError.getMsg(),
							`${url} ${respoError.getFullMessage()}`
						)
					);

					if (obs.error) {
						obs.error(respoError);
					}

					obs.complete();
				});
		}).subscribe(observer);
		window.observables.addObservable(observer.getName(), observable);
	},
	delete: (url, observer, headers, params, callback) => {
		const observable = Observable.create((obs) => {
			http
				.delete(url, {
					headers: headers || {},
					data: params || {},
				})
				.then((response) => {
					const resp = new Response(
						response,
						response.status,
						response.statusText,
						response.data
					);

					if (
						response?.data?.UIAction?.title ||
						response?.data?.UIAction?.message
					) {
						messageService.sendMessage(new Message(response, '', '', '', ''));
					}

					obs.next(resp);
					obs.complete();

					if (callback && typeof callback === 'function') {
						callback(resp);
					}
				})
				.catch((error) => {
					const respoError = new Error(
						error.response.status,
						error.response.statusText,
						error.message
					);

					messageService.sendMessage(
						new Message(
							error.response,
							BANNER,
							ERROR,
							respoError.getMsg(),
							`${url} ${respoError.getFullMessage()}`
						)
					);

					if (obs.error) {
						obs.error(respoError);
					}

					obs.complete();

					if (callback && typeof callback === 'function') {
						callback(respoError);
					}
				});
		}).subscribe(observer);
		window.observables.addObservable(observer.getName(), observable);
	},
};
init();

export const HTTPFactory = () => {
	return {
		initHeader: (headerObj) => {
			httpService.setUpHeader(headerObj);
		},
		unsubscribeObservable: (observer) => {
			httpService.unsubscribeObservable(observer);
		},
		get: (url, observer) => {
			return httpService.get(url, observer);
		},
	};
};

export default httpService;
