import React, {react2angular} from 'common/react-vendor';
import {redirectToOktaSSO} from 'common/app/services/LoginService';
import {IdaasResetPasswordMessage} from './IdaasResetPasswordMessage';

const IdaasResetPasswordMessageWrapper = (): React.ReactElement => {
	return (
		<IdaasResetPasswordMessage redirectToPasswordUpdate={redirectToOktaSSO} />
	);
};

angular
	.module('login.idaasResetPasswordMessage', [])
	.component(
		'idaasResetPasswordMessage',
		react2angular(IdaasResetPasswordMessageWrapper, [], ['$state'])
	);
