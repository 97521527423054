import {ImportSubsystemType} from './ImportSubsystemType';
import {SystemType} from './SystemType';

enum ConnectionType {
	Import = 'Import',
	Activation = 'Enterprise Activation',
}

interface ConnectionProps {
	connectionType: ConnectionType;
	filterEnabled: boolean;
}

interface IConnectionCommonParams extends ObjectType {
	connectionType: ConnectionType;
	subSystemType: ImportSubsystemType;
	SystemType: SystemType;
	SystemId: string;
	AuthenticationUpdatedAt: number;
	AuthenticationName: string;
	originFrom: ImportSubsystemType;
	IsSandbox: boolean;
	IsReAuth: boolean;
}

type ObjectType = Record<string, unknown>;
type ObjectArray = ObjectType[];

const isObjectTypeEmpty = (obj: ObjectType): boolean => {
	return Object.keys(obj).length === 0;
};

const isObjectFieldInvalid = (val: unknown): boolean => {
	return (
		val === null || val === undefined || (typeof val === 'string' && val === '')
	);
};

const doesObjectTypeContainEmptyField = (obj: ObjectType): boolean => {
	return Object.values(obj).some((val) => isObjectFieldInvalid(val));
};

export {ConnectionType, isObjectTypeEmpty, doesObjectTypeContainEmptyField};
export type {ConnectionProps, ObjectType, ObjectArray, IConnectionCommonParams};
