import React from 'common/react-vendor';
import './le-range-slider.scss';

interface INumericShortenedInput {
	value: number;
	name?: string;
	className?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const shortenValue = (v: number): string => {
	const million = 1000000;
	const thousand = 1000;
	if (v / million > 1) {
		return `${(v / million).toFixed(1)}M`;
	}
	if (v / thousand > 1) {
		return `${(v / thousand).toFixed(1)}K`;
	}
	return v?.toString();
};

const NumericShortenedInput = ({
	value = 0,
	name,
	className,
	onChange,
	onBlur,
}: INumericShortenedInput): JSX.Element => {
	const [focused, setFocused] = React.useState(false);
	const [inputVal, setInputVal] = React.useState<string | number>(value);
	React.useEffect(() => {
		const shortValue = shortenValue(value);
		setInputVal(focused ? value : shortValue);
	}, [focused, value]);

	const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setFocused(false);
		if (typeof onBlur === 'function') {
			onBlur(event);
		}
	};
	const handleOnFocus = (): void => {
		setFocused(true);
	};

	return (
		<input
			type={focused ? 'number' : `string`}
			name={name}
			value={inputVal}
			className={className}
			onFocus={handleOnFocus}
			onChange={onChange}
			onBlur={handleOnBlur}
		/>
	);
};

export default NumericShortenedInput;
