import {JourneyStageEditDialog} from 'atlas/JourneyStage/Component/Dialogs/JourneyStageEditDialog';
import React from 'common/react-vendor';
import {
	IEditDialog,
	INameDescriptionData,
	NameDescriptionHeader,
} from './NameDescriptionHeader';
import {ISegmentTitleHeader} from './SegmentTitleHeader';

type ISubstageTitleHeader = ISegmentTitleHeader & {
	data: INameDescriptionData;
};

const SubstageTitleHeader = ({
	buttons,
	action,
	data,
}: ISubstageTitleHeader): React.ReactElement => {
	return (
		<NameDescriptionHeader
			data={data}
			buttons={buttons}
			Dialog={(prop: IEditDialog) => (
				<JourneyStageEditDialog
					data={prop.open}
					setData={() => prop.onOkay?.()}
					journeyStageData={data}
					{...prop}
					onOkay={(newData) => {
						prop?.onOkay?.(newData);
						action?.onOkay?.(newData);
					}}
					onCancel={() => {
						prop?.onCancel?.();
						action?.onCancel?.();
					}}
				/>
			)}
		/>
	);
};

export {SubstageTitleHeader};
