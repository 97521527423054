import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {cacheQueryResponse} from 'common/app/utilities/cacheUtility/cacheUtility';
import {isNil} from 'lodash';

export const getSubscription = (callback: {
	(hasRevupProduct: boolean): void;
}): void => {
	cacheQueryResponse('subscription', () =>
		axiosInstance.get(`/pls/subscription`)
	)
		.then(({data}: {data: SubscriptionData}) => {
			const {contractProducts} = data.currentSubscription;

			const hasRevupProduct = contractProducts.includes('Rev.Up ABX for Ads');

			const hasMoreProducts = contractProducts.length > 1;

			const showHoovers = hasMoreProducts || !hasRevupProduct;

			if (!isNil(callback)) {
				// eslint-disable-next-line promise/no-callback-in-promise
				callback(showHoovers);
			}
		})
		.catch(() => undefined);
};

export const getAlertEmail = async (
	refetch: boolean
): Promise<IAlertEmailConfig> =>
	cacheQueryResponse(
		'getAlertEmail',
		() => axiosInstance.get('/pls/alertemail').then(({data}) => data),
		{
			refetch,
		}
	);

export interface SubscriptionData {
	currentSubscription: CurrentSubscription;
	subscriptionUsage: SubscriptionUsage;
	alertEmail: IAlertEmailConfig;
}

export interface CurrentSubscription {
	contractEndDate: string;
	contractPackage: string;
	contractProducts: string[];
	contractStartDate: string;
	dayLeft: string;
}

interface SubscriptionUsage {
	accountAvailable: number;
	accountLimit: number;
	accountUsed: number;
	contactAvailable: number;
	contactLimit: number;
	contactUsed: number;
	pageViewAvailable: number;
	pageViewLimit: number;
	pageViewUsed: number;
}

export interface IAlertEmailConfig {
	[s: string]: {
		[x: string]: boolean | string;
	};
}

export const defaultAlertEmail: IAlertEmailConfig = {
	dataImport: {
		PAJobFailDue2Data: true,
		PAJobFailure: true,
		// PAJobSuccess: true,
		// PAJobStateChanged: true,
		// PAJobRestarted: true,
		// PAJobDelayed: true,
		exportOrphanRecordsRunning: true,
		exportOrphanRecordsCompleted: true,
		importActionCancel: true,
	},
	segmentation: {
		exportsRunning: true,
		exportsSuccess: true,
		exportsFailed: true,
		modelCDLSuccess: true,
		modelCDLFailure: true,
	},
	activation: {
		campaignLaunchFail: true,
		campaignLaunchSchedulesExpiring: true,
		campaignGroupSchedulesExpiring: true,
		campaignGroupExpiring: true,
		AdConnectionsExpired: true,
		AdConnectionsExpiring: true,
	},
	tenantManagement: {
		tenantActivityOverages: true,
		subscriptionExpiring: true,
		subscriptionExpired: true,
	},
};

export const alertEmailConfig: IAlertEmailConfig = {
	dataImport: {
		isCardExpend: false,
		label: 'Data Import',
		description: 'I want to receive email alerts for',
		PAJobFailDue2Data: 'PA jobs that fail due to data issues',
		PAJobFailure: 'General PA job failures',
		// PAJobSuccess: 'PA job run successfully',
		// PAJobStateChanged: 'PA job workflow state change',
		// PAJobRestarted: 'PA job that have restarted',
		// PAJobDelayed: 'PA jobs that are delayed, but pending support',
		exportOrphanRecordsRunning: 'Export orphan records job is running',
		exportOrphanRecordsCompleted: 'Export orphan records job is complete',
		importActionCancel: 'Successful import cancellation',
	},
	segmentation: {
		isCardExpend: true,
		label: 'Segmentation',
		description: 'I want to receive email alerts for',
		exportsRunning: 'Segment exports that are running',
		exportsSuccess: 'Successful segment exports',
		exportsFailed: 'Failed segment exports',
		modelCDLSuccess: 'Model job success',
		modelCDLFailure: 'Model job failure',
	},
	activation: {
		isCardExpend: true,
		label: 'Activation',
		description: 'I want to receive email alerts for',
		campaignLaunchFail: 'Campaign launch failure',
		campaignLaunchSchedulesExpiring: 'Campaign launch schedules are expiring',
		campaignGroupSchedulesExpiring: 'Campaign group schedules are expiring',
		campaignGroupExpiring: 'Campaign group is expiring',
		AdConnectionsExpired: 'Ad connections have expired',
		AdConnectionsExpiring: 'Ad connections are expiring',
	},
	tenantManagement: {
		isCardExpend: true,
		label: 'Tenant Management',
		description: 'I want to receive email alerts for',
		tenantActivityOverages: 'Tenant activity overages',
		subscriptionExpiring: 'Product subscription is expiring',
		subscriptionExpired: 'Product subscription has expired',
	},
};
