import {Bkt, InputRangeConfig} from 'components/datacloud/query/query.types';
import {getValue} from 'components/datacloud/query/advanced/tree/date-attribute/dateAttribute.helpers';
import {BucketCmp, BucketType} from 'components/datacloud/query/query.enums';

const getInitialPeriodTimeConfig = (
	cmp: BucketCmp | '',
	bkt?: Bkt
): InputRangeConfig => ({
	from: {
		name: 'from-time',
		initial: getValue({
			type: BucketType.Date,
			cmp,
			bkt,
			index: 0,
		}),
		index: 0,
		type: BucketType.Date,
		pattern: '\\d+',
		step: 1,
	},
	to: {
		name: 'to-time',
		initial: getValue({
			type: BucketType.Date,
			cmp,
			bkt,
			index: 1,
		}),
		index: 1,
		type: BucketType.Date,
		pattern: '\\d+',
		step: 1,
	},
});

const getInitialPeriodNumberConfig = (
	cmp: BucketCmp | '',
	bkt?: Bkt
): InputRangeConfig => ({
	from: {
		name: 'from-period',
		value: getValue({
			type: BucketType.Numerical,
			cmp,
			bkt,
			index: 0,
		}),
		index: 0,
		type: BucketType.Numerical,
		min: cmp === BucketCmp.LAST ? '1' : '0',
		max: '36500',
		pattern: '\\d*',
		step: 1,
	},
	to: {
		name: 'to-period',
		value: getValue({
			type: BucketType.Numerical,
			cmp,
			bkt,
			index: 1,
		}),
		index: 1,
		type: BucketType.Numerical,
		min: '0',
		max: '36500',
		pattern: '\\d*',
		step: 1,
	},
});

export {getInitialPeriodNumberConfig, getInitialPeriodTimeConfig};
