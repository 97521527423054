import {Query, RatingModelRule} from '../datacloud.types';
import {Bkt, BucketRestriction, Restriction} from '../query/query.types';
import {AttributeEntity} from '../query/advanced/tree/types';

interface EmptyBucketRestriction {
	bucketRestriction: BucketRestriction;
}

/**
 * Check if an entity should be considered as part of Contact data
 * @param {*} entity
 */
const isContactEntity = (entity: AttributeEntity): boolean => {
	const contactEntities = [
		'Contact',
		'ContactMarketingActivity',
		'CuratedContact',
		'ContactSalesActivity',
		'ContactCampaignLaunchHistory',
		'ContactWebVisitProfile',
	];
	return contactEntities.includes(entity);
};

/**
 * Create an empty bucketRestriction with ignored=true
 * @param {*} entity
 * @param {*} attributeName
 */
const getEmptyBucketRestriction = (
	entity: AttributeEntity,
	attributeName: string
): EmptyBucketRestriction => ({
	bucketRestriction: {
		attr: `${entity}.${attributeName}`,
		bkt: {} as Bkt,
		ignored: true,
	},
});

/**
 *
 * @param {*} singleBucket object containing account_restriction and contact_restriction
 * @param {*} entity
 * @param {*} attr
 */
const addRestrictionToBucket = (
	singleBucket: Query,
	entity: AttributeEntity,
	attr: string
): Query => {
	const newSingleBucket = {...singleBucket};
	if (isContactEntity(entity)) {
		newSingleBucket.contact_restriction?.logicalRestriction?.restrictions.push(
			getEmptyBucketRestriction(entity, attr) as Restriction
		);
	} else if (newSingleBucket.account_restriction) {
		newSingleBucket.account_restriction.logicalRestriction?.restrictions.push(
			getEmptyBucketRestriction(entity, attr) as Restriction
		);
	}

	return newSingleBucket;
};

/**
 *
 * @param {*} restrictions restrictions contained in the in either account_restriction or contact_restriction
 * @param {*} entityAttr string containing the Entity.AttributeName combined
 */
const removeFromRestrictions = (
	restrictions: Restriction[] = [],
	entityAttr: string
): Restriction[] =>
	restrictions.filter(
		(restriction) => restriction.bucketRestriction?.attr !== entityAttr
	);

/**
 * Provied the Entity it takes care of calling the remove method on the appropriate bucket
 * @param {*} bucket
 * @param {*} entity
 * @param {*} attr
 */
const removeRestrictionFromBucket = (
	bucket: Query,
	entity: AttributeEntity,
	attr: string
): Query => {
	const newBucket = {...bucket};
	const entityAttr = `${entity}.${attr}`;
	if (isContactEntity(entity)) {
		removeFromRestrictions(
			newBucket.contact_restriction!.logicalRestriction?.restrictions,
			entityAttr
		);
	} else if (newBucket.account_restriction) {
		removeFromRestrictions(
			newBucket.account_restriction.logicalRestriction?.restrictions,
			entityAttr
		);
	}

	return newBucket;
};

export default {
	/**
	 * For each bucket (A,B, .....) it adds/removes the attribute
	 * @param {*} add
	 * @param {*} rule Object containing ratingRule.bucketToRuleMap
	 * @param {*} entity
	 * @param {*} attr
	 */
	removeAddAttrFromRule: (
		add: boolean,
		rule: RatingModelRule,
		entity: AttributeEntity,
		attr: string
	): RatingModelRule => {
		const newRule = {...rule};
		const buckets = newRule.ratingRule.bucketToRuleMap;
		const bucketsName = Object.keys(buckets);

		bucketsName.forEach(function (bucket) {
			switch (add) {
				case true: {
					buckets[bucket] = addRestrictionToBucket(
						buckets[bucket]!,
						entity,
						attr
					);
					break;
				}
				case false: {
					buckets[bucket] = removeRestrictionFromBucket(
						buckets[bucket]!,
						entity,
						attr
					);
					break;
				}
				default: {
					console.warn(
						'Add/Remove to the bucket not performed (',
						bucket,
						attr,
						')'
					);
				}
			}
		});

		return newRule;
	},
};
