import React, {react2angular} from 'common/react-vendor';
import LocalStorageUtility from 'common/widgets/utilities/local-storage.utility';
import {getString} from 'common/app/utilities/ResourceUtility';
import {GetSessionDocument, entitledForDnB} from 'common/app/services/LoginService';
import SessionTimeout from 'common/components/timeout/SessionTimeout';
import moment from 'moment';
import {DNBTablePagination} from 'common/local-uux-components/pagination/table-pagination';
import {LeBannerSimple} from 'common/widgets/le-banner-simple/le-banner-simple';
import {login, set, redirectToApp} from '../login.service';

const {useState, useEffect, useRef} = React;

export const Tenants = () => {
	const logindocument = LocalStorageUtility.getLoginDocument() || {};
	const ClientSession = LocalStorageUtility.getClientSession();

	const [page, setPage] = useState(0);
	const [version, setVersion] = useState('');
	const [deactivated, setDeactivated] = useState(false);
	const [sortProperty, setSortProperty] = useState('RegisteredTime');
	const [sortDirection, setSortDirection] = useState('Desc');
	const [isSearchVisible, setIsSearchVisible] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const [tenantList, setTenantList] = useState(logindocument.Tenants || []);
	const [filteredTenantList, setFilteredTenantList] = useState(tenantList);
	const [pageSize, setPageSize] = useState(100);
	const [bannerConfig, setBannerConfig] = useState({
		content: '',
		type: '',
		hidden: true,
	});

	const tenantMap = {};

	const searchInput = useRef(null);

	useEffect(() => {
		if (tenantList !== null) {
			const validatedTenants = tenantList.filter((tenant) => {
				return entitledForDnB(tenant);
			});
			setTenantList(validatedTenants);
		}

		set(logindocument, ClientSession);

		const referringUrl = sessionStorage.getItem('referringUrl');
		if (referringUrl) {
			const url = new URL(referringUrl);
			const str = url.pathname.split('/')[3];

			const tenants = tenantList;
			const found = tenants.find((element) => {
				return element.DisplayName.indexOf(str) !== -1;
			});

			select(found);
			return;
		}
		if (tenantList.length === 1) {
			select(tenantList[0]);
			return;
		}

		if (tenantList === null || tenantList.length === 0) {
			if (logindocument && !login.username) {
				setBannerConfig({
					content: getString('LOGIN_EXPIRED_AUTHENTICATION_CREDENTIALS'),
					type: 'error',
					hidden: false,
				});
			} else {
				LocalStorageUtility.clear();
				window.location.href = 'https://help.lattice-engines.com';
			}
			return;
		}

		tenantList.forEach((tenant) => {
			tenantMap[tenant.Identifier] = tenant;
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		filterTenantsList(version, searchValue, tenantList);
	}, [version, searchValue, tenantList]);

	useEffect(() => {
		setPage(0);
	}, [version]);

	const select = (tenant) => {
		if (entitledForDnB(tenant)) {
			setBannerConfig({hidden: true});
			setDeactivated(true);
	
			GetSessionDocument(tenant, login.username)
				.then(function (data) {
					if (data != null && data.Success === true) {
						redirectToApp(tenant);
					} else {
						setDeactivated(false);
						setBannerConfig({
							content: getString('TENANT_SELECTION_FORM_ERROR'),
							type: 'error',
							hidden: false,
						});
					}
				})
				.catch((e) => console.error(e));
		} else {
			setDeactivated(false);
			setBannerConfig({
				content: getString('TENANT_SELECTION_FORM_ERROR'),
				type: 'error',
				hidden: false,
			});
		}
	};

	const sort = (value) => {
		if (sortProperty === value) {
			setSortDirection(sortDirection === 'Asc' ? 'Desc' : 'Asc');
		} else {
			setSortDirection('Asc');
		}

		setSortProperty(value);
	};

	const toggle = () => {
		setIsSearchVisible(!isSearchVisible);
		setSearchValue('');
		filterTenantsList(version, '', tenantList);
		searchInput.current.focus();
	};

	const searchChangeHandler = (e) => {
		setSearchValue(e.target.value);
	};

	const filterTenantsList = (versionPara, searchValuePara, tenantListPara) => {
		const filteredList = tenantListPara.filter((tenant) => {
			return (
				(versionPara ? tenant.UIVersion === versionPara : true) &&
				(searchValuePara
					? tenant.DisplayName.toLowerCase().includes(
							searchValuePara.toLowerCase()
					  )
					: true)
			);
		});
		setFilteredTenantList(filteredList);
		const maxPage = Math.round(filteredList.length / pageSize);
		if (page > maxPage) {
			setPage(maxPage);
		}
	};

	const getBadgeColor = (tenant) => {
		switch (tenant.UIVersion) {
			case '1.0':
				return 'orange-badge';
			case '2.0':
				return 'gray-badge';
			case '3.0':
				return 'blue-badge';
			case '4.0':
				return 'olive-badge';
			case '5.0':
				return 'purple-badge';
			default:
				return 'gray-badge';
		}
	};

	const handlePageChange = (event, value) => {
		setPage(value);
	};

	const handlePageSizeChange = (event) => {
		setPageSize(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<>
			<SessionTimeout />
			{!bannerConfig.hidden && (
				<LeBannerSimple
					content={bannerConfig.content}
					type={bannerConfig.type}
					showCloseIcon
					onClick={() => setBannerConfig({content: '', type: '', hidden: true})}
				/>
			)}
			<form className='form form-tenant-select' name='tenant-select-form'>
				<div className='tenant-select-header row'>
					<h4 className='tenant-header'>
						{getString('TENANT_SELECTION_FORM_TITLE')}
					</h4>
				</div>
				<div className='tenant-select-body'>
					<div className='tenant-select-controls'>
						<div
							className='button-group'
							role='group'
							aria-label='Basic example'>
							<button
								onClick={() => setVersion('4.0')}
								type='button'
								className={`button ${
									version === '4.0' ? 'blue' : 'white'
								}-button icon-button`}>
								Atlas 4.0
							</button>
							<button
								onClick={() => setVersion('3.0')}
								type='button'
								className={`button ${
									version === '3.0' ? 'blue' : 'white'
								}-button icon-button`}>
								LPI 3.0
							</button>
							<button
								onClick={() => setVersion('')}
								type='button'
								className={`button ${
									!version ? 'blue' : 'white'
								}-button icon-button`}>
								All
							</button>
						</div>
						<div className='tenant-select-controls-right pull-right'>
							<div className='select-menu ng-search open-absolute'>
								<input
									ref={searchInput}
									type='text'
									className='search-form-control'
									placeholder='Search'
									onChange={searchChangeHandler}
									value={searchValue}
									hidden={!isSearchVisible}
									autoFocus
								/>
								<button
									type='button'
									className='button icon-button white-button'
									onClick={toggle}>
									<span
										className={`fa fa-${isSearchVisible ? 'times' : 'search'}`}
									/>
								</button>
							</div>
						</div>
					</div>
					<div
						className={`columns twelve panel panel-default tenant-select-list ${
							deactivated ? 'deactivated' : null
						}`}>
						<div className='row panel-heading custom-heading'>
							<div className='columns two' onClick={() => sort('UIVersion')}>
								{`Ver. `}
								<span hidden={sortProperty !== 'UIVersion'}>
									<i
										className={`fa fa-caret-${
											sortDirection === 'Asc' ? 'up' : 'down'
										}`}
									/>
								</span>
							</div>
							<div className='columns six' onClick={() => sort('DisplayName')}>
								{`Name `}
								<span hidden={sortProperty !== 'DisplayName'}>
									<i
										className={`fa fa-caret-${
											sortDirection === 'Asc' ? 'up' : 'down'
										}`}
									/>
								</span>
							</div>
							<div
								className='columns four'
								onClick={() => sort('RegisteredTime')}>
								{`Created `}
								<span hidden={sortProperty !== 'RegisteredTime'}>
									<i
										className={`fa fa-caret-${
											sortDirection === 'Asc' ? 'up' : 'down'
										}`}
									/>
								</span>
							</div>
						</div>
						<div className='row panel-body tenant-selection-list'>
							{filteredTenantList
								.sort((a, b) => {
									if (sortProperty === 'DisplayName') {
										return sortDirection === 'Asc'
											? a[sortProperty].localeCompare(b[sortProperty])
											: b[sortProperty].localeCompare(a[sortProperty]);
									}
									return sortDirection === 'Asc'
										? a[sortProperty] - b[sortProperty]
										: b[sortProperty] - a[sortProperty];
								})
								.slice(page * pageSize, page * pageSize + pageSize)
								.map((tenant, index) => {
									const badgeColor = getBadgeColor(tenant);
									return (
										<div
											className={`job-td-row tenant-list-item ${index}`}
											id={tenant.Identifier}
											key={tenant.Identifier}
											hidden={deactivated}
											onClick={() => select(tenant)}>
											<div className='columns two tenant-version'>
												<span
													className={`badge ${
														tenant.UIVersion === '3.0' ? 'blue' : 'gray'
													}-badge ${badgeColor}`}>
													{tenant.UIVersion}
												</span>
											</div>
											<div
												className='columns seven tenant-name'
												title={tenant.DisplayName}>
												{tenant.DisplayName}
											</div>
											<div className='columns three tenant-date'>
												{moment(tenant.RegisteredTime).format('MM/DD/yyyy')}
											</div>
										</div>
									);
								})}
							<div
								className='text-center tenant-list-spinner'
								hidden={!deactivated}>
								<section className='loading-spinner lattice show-spinner'>
									<h2 className='text-center'>Loading CDP...</h2>
									<div className='meter'>
										<span className='indeterminate' />
									</div>
								</section>
							</div>
						</div>
						<div className='row'>
							<DNBTablePagination
								component='div'
								count={filteredTenantList.length}
								page={page}
								onPageChange={handlePageChange}
								rowsPerPage={pageSize}
								onRowsPerPageChange={handlePageSizeChange}
							/>
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

angular
	.module('login.tenants', [])
	.component('loginTenants', react2angular(Tenants));
