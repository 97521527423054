import React, {react2angular} from 'common/react-vendor';
import {
	OVERRIDE_SSO_REDIRECT_QUERY_PARAM_KEY,
	redirectToOktaSSO,
	Login,
	PostToJwt,
} from 'app/services/LoginService';
import {isEmpty} from 'lodash';
import {getString} from 'common/app/utilities/ResourceUtility';
import {
	LeBannerSimple,
	BannerConfig,
} from 'common/widgets/le-banner-simple/le-banner-simple';
import {SessionStorageKeys} from '../IdaasMigration/idaas-constants';
import styles from './LoginForm.component.module.scss';
import NgState from '../../../ng-state';

const {useState, useEffect} = React;

type Params = Record<string, unknown>;

export const LoginForm = (): JSX.Element => {
	const [loginInProgress, setLoginInProgress] = useState(false);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [isUsernameInvalid, setIsUsernameInvalid] = useState(false);
	const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
	const [params, setParams] = useState<Params>({});
	const [bannerConfig, setBannerConfig] = useState<BannerConfig>({
		content: '',
		type: '',
		hidden: true,
	});

	const isEmptyObject = (val: Record<string, unknown>): boolean => {
		return isEmpty(val) && val.constructor === Object;
	};

	const urlParams = Object.fromEntries(
		new URLSearchParams(window.location.search)
	);

	useEffect(() => {
		if (!urlParams[OVERRIDE_SSO_REDIRECT_QUERY_PARAM_KEY]) {
			redirectToOktaSSO();
			return;
		}

		setParams(
			isEmptyObject(urlParams)
				? urlParams
				: NgState.getAngularState().params.obj
		);

		if (params.logout) {
			setBannerConfig({
				content: 'You have been logged out.',
				type: 'info',
				hidden: false,
			});
		}

		if (
			sessionStorage.getItem(SessionStorageKeys.showLoginWithDnbCredsBanner)
		) {
			sessionStorage.removeItem(SessionStorageKeys.showLoginWithDnbCredsBanner);
			setBannerConfig({
				content: 'Please use your D&B password to login.',
				type: 'info',
				hidden: false,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const showLoginHeaderMessage = (message: string): void => {
		if (message == null) {
			return;
		}

		setBannerConfig({
			content: message.includes('Global Auth')
				? getString('LOGIN_GLOBAL_AUTH_ERROR')
				: message,
			type: 'error',
			hidden: false,
		});
	};

	const loginClick = (): void => {
		setIsUsernameInvalid(isEmpty(username));
		setIsPasswordInvalid(isEmpty(password));

		if (isEmpty(username) || isEmpty(password)) {
			return;
		}

		setLoginInProgress(true);

		Login(username, password)
			.then(function (result: {Success: boolean}) {
				setLoginInProgress(false);

				if (result !== null && result.Success === true) {
					if (isEmptyObject(params) && params.type === 'jwt') {
						// eslint-disable-next-line promise/no-nesting
						PostToJwt(params)
							// eslint-disable-next-line promise/no-nesting
							.then(function (result: {url: string}) {
								window.location.href = result.url;
							})
							// eslint-disable-next-line promise/no-nesting
							.catch((e: Error) => console.error(e));
					} else {
						NgState.getAngularState().go('login.tenants');
					}
				} else {
					showLoginHeaderMessage(result as unknown as string);
				}
			})
			.catch((e: Error) => console.error(e));
	};

	if (urlParams[OVERRIDE_SSO_REDIRECT_QUERY_PARAM_KEY]) {
		return (
			<>
				{!bannerConfig.hidden && (
					<LeBannerSimple
						content={bannerConfig.content}
						type={bannerConfig.type}
						showCloseIcon
						onClick={() =>
							setBannerConfig({content: '', type: '', hidden: true})
						}
					/>
				)}
				<form
					className={`form form-signin ${styles.loginForm}`}
					name='login-form'
					onSubmit={(e) => {
						e.preventDefault();
						loginClick();
					}}>
					<div className='form-group'>
						<label>{getString('LOGIN_USERNAME')}</label>
						<input
							type='text'
							id='username'
							autoComplete='on'
							name='le-login_username'
							title={getString('LOGIN_USERNAME')}
							className={`form-control ${isUsernameInvalid ? 'error' : ''}`}
							autoFocus
							onChange={(e) => setUsername(e.target.value)}
						/>
					</div>
					<div className='form-group'>
						<label>{getString('LOGIN_PASSWORD')}</label>
						<input
							type='password'
							id='password'
							name='le-login_password'
							autoComplete='on'
							title={getString('LOGIN_PASSWORD')}
							className={`form-control ${isPasswordInvalid ? 'error' : ''} `}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<div className='form-actions'>
						<button
							id='loginButton'
							type='submit'
							onClick={loginClick}
							className='button blue-button'
							disabled={loginInProgress}>
							{getString('LOGIN_LABEL_REGMARK')}
						</button>

						{loginInProgress && <i className='fa fa-spinner fa-pulse fa-fw' />}
						<div className='secondary-actions_'>
							<a href='/login/forgot'>
								{getString('LOGIN_FORGOT_PASSWORD_LABEL')}
							</a>
						</div>
					</div>
					<p className='footer login-footer'>
						&copy;
						{`${getString('LOGIN_COPYRIGHT', [
							`2010 - ${new Date().getFullYear()}`,
						])} `}
						<a href='https:www.dnb.com/utility-pages/privacy-policy.html'>
							{getString('HEADER_PRIVACY_POLICY')}
						</a>
					</p>
				</form>
			</>
		);
	}
	return <></>;
};

angular
	.module('login.form', [])
	.component('loginForm', react2angular(LoginForm, [], []));
