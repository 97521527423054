import {
	PredefinedRoles,
	RBACActions,
	RBACInterface,
	RBACCampaignTags,
	RBACModelTags,
	RBACJobTags,
	Right,
	Service,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import Cookies from 'js-cookie';
import {
	encryptJSONData,
	decryptJSONData,
} from 'common/app/utilities/EncryptionUtility';
import {clearCacheStorage} from 'common/app/utilities/cacheUtility/cacheUtility';

/* CORE GET/SET */
/*
	Local Storage is used by the application to store data
	for later use by the application, even across sessions
*/
const get = (path: string): string | null => {
	return window.localStorage.getItem(path);
};

// localstorage can only store string values
const set = (path: string, value: string): void => {
	window.localStorage.setItem(path, value);
};

const getObject = (path: string): unknown => {
	return JSON.parse(`${get(path)}`);
};

// stringifies objects to store in local storage
const setObject = (path: string, value: unknown): void => {
	const str = JSON.stringify(value);
	set(path, str);
};

/* LOCAL STORAGE USES */

enum Stores {
	Login = 'GriotLoginDocument',
	ClientSession = 'GriotClientSession',
	Session = 'GriotSessionDocument',
	Token = 'GriotTokenDocument',
	OAuthToken = 'GriotOAuthAccessToken',
	WidgetConfig = 'GriotWidgetConfigDocument',
	SessionTimestamp = 'GriotSessionLastActiveTimestamp',
	TenantCache = 'GriotTenantCache',
	CookieSetTime = 'GriotCookieSetTime',
}

interface ILoginDocument {
	FirstName: string;
	LastName: string;
	MustChangePassword: boolean;
	PasswordLastModified: number;
	Tenants: ISessionTenant[];
	Timestamp: number;
	UserName: string;
}

const getLoginDocument = (): ILoginDocument => {
	return getObject(Stores.Login) as ILoginDocument;
};
const setLoginDocument = (data: ILoginDocument): void => {
	setObject(Stores.Login, data);
};

export interface ISessionBackend {
	rbacInterface: RBACInterface;
	effect: 'ACCEPT' | 'DENY';
	actions: RBACActions[];
	tags: (RBACCampaignTags | RBACModelTags | RBACJobTags)[];
}

export type ISessionRights = Record<Service, Record<Right, boolean>>;

export interface ISessionTenant {
	Identifier: string;
	DisplayName: string;
	Pid: number;
	RegisteredTime: number;
	UIVersion: string;
	Status: string;
	TenantType: string;
	Contract: string;
	EntitledApps: string;
	Product?: string;
	NotificationLevel: string;
	NotificationType: string;
	JobNotificationLevel: unknown;
}

export interface IClientSessionSession {
	Statements?: ISessionBackend[];
	AvailableRights?: ISessionRights;
	AccessLevel?: PredefinedRoles;
	RoleLabel?: string;
	Identifier?: string;
	Tenant?: ISessionTenant;
	DisplayName?: string;
	EmailAddress?: string;
	Locale?: string;
	TeamIds?: string[];
}

const getClientSession = (): IClientSessionSession | null => {
	const encryptedClientSession =
		(getObject(Stores.ClientSession) as string) || '';

	const isPlaywrightTest = window.sessionStorage.getItem('isPlaywrightTest');

	if (process.env.NODE_ENV === 'test' || isPlaywrightTest) {
		return encryptedClientSession as unknown as IClientSessionSession;
	}

	return decryptJSONData<IClientSessionSession>(encryptedClientSession);
};
const setClientSession = (data: IClientSessionSession): void => {
	const encryptedClientSession = encryptJSONData<IClientSessionSession>(data);

	setObject(Stores.ClientSession, encryptedClientSession);
};

const getSessionDocument = (): unknown => {
	return getObject(Stores.Session) as unknown;
};
const setSessionDocument = (data: unknown): void => {
	setObject(Stores.Session, data);
};
const getTokenDocument = (): unknown => {
	return getObject(Stores.Token);
};
const setTokenDocument = (data: unknown): void => {
	Cookies.set('Authorization', data as string, {
		secure: true, // https required
		sameSite: 'strict', // dont send cookie on 3rd party requests

		// backend defines that tokens are good for 8 hours
		// unless timed out by UI
		expires: 8 / 24,
	});
	setObject(Stores.Token, data);
};
const getOAuthAccessToken = (): unknown => {
	return getObject(Stores.OAuthToken) as unknown;
};
const setOAuthAccessToken = (data: unknown): void => {
	setObject(Stores.OAuthToken, data);
};
const getWidgetConfigDocument = (): unknown => {
	return getObject(Stores.WidgetConfig) as unknown;
};
const setWidgetConfigDocument = (data: unknown): void => {
	setObject(Stores.WidgetConfig, data);
};
const getSessionLastActiveTimestamp = (): string => {
	return getObject(Stores.SessionTimestamp) as string;
};
const setSessionLastActiveTimestamp = (timeStamp: string): void => {
	setObject(Stores.SessionTimestamp, timeStamp);
};
const tenantCachePath = `${Stores.TenantCache}.${
	getClientSession()?.Tenant?.Identifier
}`;
const getTenantCache = (path: string): unknown => {
	const cache = (getObject(tenantCachePath) || {}) as Record<string, unknown>;
	return cache[path];
};
const setTenantCache = (path: string, value: unknown): void => {
	const cache = (getObject(tenantCachePath) || {}) as Record<string, unknown>;
	setObject(tenantCachePath, {
		...cache,
		[path]: value,
	});
};

const clear = (): void => {
	Object.values(Stores)
		.map((store) => (store === Stores.TenantCache ? tenantCachePath : store))
		.forEach((store) => {
			setObject(store, null);
		});

	clearCacheStorage();

	Cookies.remove('Authorization');
};

const getCookieSetTime = (): number => {
	return getObject(Stores.CookieSetTime) as number;
};
const setCookieSetTime = (data: number): void => {
	setObject(Stores.CookieSetTime, data);
};

export default {
	get,
	set,
	getObject,
	setObject,
	getTenantCache,
	setTenantCache,
	getLoginDocument,
	setLoginDocument,
	getClientSession,
	setClientSession,
	getSessionDocument,
	setSessionDocument,
	getTokenDocument,
	setTokenDocument,
	getOAuthAccessToken,
	setOAuthAccessToken,
	getWidgetConfigDocument,
	setWidgetConfigDocument,
	getSessionLastActiveTimestamp,
	setSessionLastActiveTimestamp,
	clear,
	getCookieSetTime,
	setCookieSetTime,
};
