import {ObjectType} from '../ConnectionType';

enum ApiMethod {
	get = 'get',
	post = 'post',
	put = 'put',
	delete = 'delete',
}

enum ApiResponseType {
	Object,
	Array,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IApiDataType = any;

interface IGenericApiResponse<T extends IApiDataType> {
	status?: number;
	data: T;
}

type IApiResponse = IGenericApiResponse<IApiDataType>;

interface IApiErrorResponse extends IApiResponse {
	statusText?: string;
}

enum ApiType {
	// inbound api types..
	GetImportConnectionList = 'GetImportConnectionList',
	CreateConnection = 'CreateConnection',
	UpdateConnection = 'UpdateConnection',
	GetImportConnectionById = 'GetImportConnectionById',
	DeleteConnectionById = 'DeleteConnectionById',
	BrokerAuthInfo = 'BrokerAuthInfo',
	BrokerAuth = 'BrokerAuth',
	BrokerWithTray = 'BrokerWithTray',
	UpdateAuth = 'UpdateAuth',
	GetSourceListPerConnection = 'GetSourceListPerConnection',
	GetSourceList = 'GetSourceList',
	GetSourceById = 'GetSourceById',
	DeleteSource = 'DeleteSource',
	PauseResumeSource = 'PauseResumeSource',
	CreateSource = 'CreateSource',
	GetDocumentTypeList = 'GetDocumentTypeList',
	GetUniqueIDList = 'GetUniqueIDList',
	OneOffImport = 'OneOffImport',
	// relationship apis.
	GetSourceIdList = 'GetSourceIdList',
	GetTargetSourceIdList = 'GetTargetSourceIdList',
	GetTargetSourceList = 'GetTargetSourceList',
	UpdateRelationShipList = 'UpdateRelationShipList',
	// activation api types
	GetActivationConnectionList = 'GetActivationConnectionList',
	CreateActivationConnection = 'CreateActivationConnection',
	UpdateActivationConnection = 'UpdateActivationConnection',
	GetActivationConnectionById = 'GetActivationConnectionById',
	DeleteActivationConnectionByName = 'DeleteActivationConnectionByName',
	BrokerAuthActivation = 'BrokerAuthActivation',
	GetAvailableLookupIds = 'GetAvailableLookupIds',
	GetAttributeGroupsData = 'GetAttributeGroupsData',
	GetSystemFieldsData = 'GetSystemFieldsData',
	GetGroupAttributes = 'GetGroupAttributes',
	GetTrayUserdocument = 'GetTrayUserdocument',
	UpdateConnectionPriorities = 'UpdateConnectionPriorities',
	GetCustomObjects = 'GetCustomObjects',
	GetSourceInfoById = 'GetSourceInfoById',
	GetSourceInfo = 'GetSourceInfo',
	UpdateSourceInfo = 'UpdateSourceInfo',
	GetAccountList = 'GetAccountList',
	GetTenantTeams = 'GetTenantTeams',
	GetCurrentMap = 'GetCurrentMap',
}

type IAPIFetcher<T extends IApiDataType> = {
	convertHook?: (data: IApiDataType) => T;
};

interface IAPIConfig {
	url: (params: ObjectType) => string;
	method?: ApiMethod;
	responseType?: ApiResponseType;
}

type IAPITypeConfig = Record<ApiType, IAPIConfig>;

type IDefaultResponse = {
	[key in ApiResponseType]: IApiResponse;
};

export {ApiType, ApiMethod, ApiResponseType};
export type {
	IAPIConfig,
	IApiDataType,
	IAPIFetcher,
	IApiResponse,
	IGenericApiResponse,
	IAPITypeConfig,
	IDefaultResponse,
	IApiErrorResponse,
};
