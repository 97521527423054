angular
	.module('common.wizard', [
		'common.wizard.progress',
		'common.wizard.controls',
		'common.wizard.header',
	])
	.controller(
		'ImportWizard',
		function (
			$state,
			$stateParams,
			$scope,
			WizardHeaderTitle,
			WizardContainerId
		) {
			const vm = this

			angular.extend(vm, {
				title: WizardHeaderTitle || '',
				container_id: WizardContainerId || '',
			});

			vm.init = function () {
				vm.title = WizardHeaderTitle || name; //set WizardHeaderTitle to false to use the header component
			};

			vm.init();
		}
	);
