import React from 'common/react-vendor';
import {GridRenderCellParams} from 'common/dnb-uux-vendor';
import {DisplayMode} from './DisplayModeType';
import {FieldType, FieldTypeConfig, SystemNameDisplayName} from './FieldType';
import {ConnectionType, ObjectType} from './ConnectionType';
import {ApiType} from './API/ApiType';
import {Toggle} from '../Component/Toggle';
import {NameColumnItem} from '../Component/Table/Component/NameColumnItem';
import {TextColumnItem} from '../Component/Table/Component/TextColumnItem';
import {DisableIcon, EnableIcon} from './AssetConst';
import {SystemLabelType, SystemType} from './SystemType';
import {ItemType} from '../Component/Tile/TileBody';
import {formatPriorityStr} from '../Util/CommonUtil';
import {IActivationConnectionResponse} from './API/FE/ActivationResponse';
import {IImportConnectionResponse} from './API/FE/ImportResponse';
import {TextColumnListItem} from '../Component/Table/Component/TextColumnListItem';
import {ImportSubsystemType} from './ImportSubsystemType';
import {NameTableHeaderComponent} from '../Component/Table/ConnectionTableItem';
import {IConnectionSortBy} from '../Component/ConnectionSortBy';
import {ICommonConnectionResponse} from './API/FE/CommonResponse';
import {CronSettingTypeConfig} from './CronSettingType';
import {IconTipHeaderComponent} from '../Component/Table/Header/IconTipHeaderComponent';
import {CustomizeColumnContextMenuId} from '../Component/ContextMenu/CustomizeColumnContextMenu';
import {
	ActivationSystemList,
	ImportSystemList,
	showConnectionSchedule,
	ISystemType,
	SystemConfigMap,
} from './SystemConst';

const Home = 'home';
const ConnectionLabel = 'Connections';

const AngularStateList = {
	ConnectionList: 'home.connectors',
	ConnectionConfiguration: 'home.configureconnector',
	AddConnection: 'home.addconnection',
	PickConnection: 'home.pickconnection',
	AddSource: 'home.addsource',
	RelationShipSetting: 'home.relationshipsetting',
	ManagePriorities: 'home.managepriorities',
	EditMapping: 'home.editMapping',
	OneOffImport: 'home.oneoffimport',
	AddNewMarketingActivities: 'home.addnewmarketingactivities',
	AutoDelete: 'home.autodelete',
	ScheduleDeleteConfiguration: 'home.deleteconfiguration',
};

const StateList = {
	LegacyConnectionList: 'connectorslist',
	ConnectionList: 'ConnectionList',
	ConnectionConfiguration: 'ConnectionConfiguration',
	AddConnection: 'AddConnection',
	PickConnection: 'PickConnection',
	SourceWizard: 'SourceWizard',
	RelationShipSetting: 'RelationShipSetting',
	EditMapping: 'EditMapping',
	ManagePriorities: 'ManagePriorities',
	OneOffImport: 'OneOffImport',
	AddS3Sources: 'AddS3Sources',
	AddNewMarketingActivities: 'AddNewMarketingActivities',
	AutoDelete: 'AutoDelete',
	ScheduleDeleteConfiguration: 'ScheduleDeleteConfiguration',
};

const getDisplayName = (type: ConnectionType): string => {
	return `${type} ${ConnectionLabel}`;
};

const ConnectionConfigurationApiConfig = {
	[ConnectionType.Import]: ApiType.GetImportConnectionById,
	[ConnectionType.Activation]: ApiType.GetActivationConnectionById,
};

type IApiSubSystemType = {
	[key in ImportSubsystemType]?: ApiType;
};

type IConnectionConfig = {
	[key in ConnectionType]: {
		displayName: string;
		apiType: IApiSubSystemType;
	};
};

type IAddConnectionConfig = {
	[key in ConnectionType]: {
		title: string;
		description: string;
	};
};

const LegacyActivation = 'Standard Activation Connections';

const AddConnectionConfig: IAddConnectionConfig = {
	[ConnectionType.Import]: {
		title: 'Where do you want to import your data from?',
		description: '',
	},
	[ConnectionType.Activation]: {
		title: 'Pick a Connector',
		description: 'Create a connection to activate your data.',
	},
};

const ConnectionConfig: IConnectionConfig = {
	[ConnectionType.Import]: {
		displayName: `${getDisplayName(ConnectionType.Import)}`,
		apiType: {
			[ImportSubsystemType.Connection]: ApiType.GetImportConnectionList,
			[ImportSubsystemType.Source]: ApiType.GetSourceList,
		},
	},
	[ConnectionType.Activation]: {
		displayName: `${getDisplayName(ConnectionType.Activation)}`,
		apiType: {
			[ImportSubsystemType.Connection]: ApiType.GetActivationConnectionList,
			[ImportSubsystemType.Source]: ApiType.GetActivationConnectionList,
		},
	},
};

interface ITableColumnComponent {
	data?: ObjectType;
}

interface IConnectionHeaderComponent {
	sortBy?: IConnectionSortBy;
}

interface IRowConfig {
	key: FieldType;
	valueType: ItemType;
	stylePrefix?: string;
	formatHook?: (data: ObjectType) => string;
	filterHook?: (data: ObjectType) => boolean;
	hide?: boolean;
}

interface IColumnConfig {
	size: number;
	key: FieldType;
	HeaderComponent?: ({
		sortBy,
	}: IConnectionHeaderComponent) => React.ReactElement;
	Component?: ({data}: ITableColumnComponent) => React.ReactElement;
	hide?: boolean;
	fixed?: boolean;
	sortable?: boolean;
	headerName?: string;
	renderCell?: (props: GridRenderCellParams) => React.ReactElement;
}

interface IConnectionComponent {
	Tile: {
		RowConfigList: IRowConfig[];
	};
	Table: {
		ColumnConfigList: IColumnConfig[];
	};
}

interface IConnectionComponentConfig {
	Component: IConnectionComponent;
	EditButton: {
		Text: string;
	};
}

type IConnectionUIConfig = {
	[key in ConnectionType]: IConnectionComponentConfig;
};

const ConnectionUIConfig: IConnectionUIConfig = {
	[ConnectionType.Import]: {
		Component: {
			[DisplayMode.Tile]: {
				RowConfigList: [
					{
						key: FieldType.Priority,
						valueType: ItemType.Enum,
						stylePrefix: 'P',
						formatHook: (data: ObjectType): string =>
							formatPriorityStr(data.Priority as number),
					},
					{
						key: FieldType.Schedule,
						valueType: ItemType.Text,
						filterHook: (data: ObjectType): boolean => {
							const {SystemType} = data as ICommonConnectionResponse;
							const {label} = SystemConfigMap[SystemType];
							const isAutoSystem = label.includes(SystemLabelType.Auto);
							return isAutoSystem && showConnectionSchedule(SystemType);
						},
					},
					{
						key: FieldType.CreatedBy,
						valueType: ItemType.Text,
						filterHook: (data: ObjectType): boolean => !!data.CreatedBy,
					},
					{key: FieldType.Created, valueType: ItemType.Date},
					{
						key: FieldType.LastUpdatedDate,
						valueType: ItemType.Date,
					},
				],
			},
			[DisplayMode.Table]: {
				ColumnConfigList: [
					{
						size: 8,
						key: FieldType.SystemName,
						HeaderComponent: ({
							sortBy,
						}: IConnectionHeaderComponent): React.ReactElement => {
							return NameTableHeaderComponent({
								name: SystemNameDisplayName,
								sortBy,
							});
						},
						Component: ({data}: ITableColumnComponent): React.ReactElement => {
							const {SystemName, SystemType} =
								data as IImportConnectionResponse;
							return NameColumnItem({
								systemName: SystemName,
								systemType: SystemType as SystemType,
								isPrimary: true,
							});
						},
					},
					{
						size: 6,
						key: FieldType.Priority,
						HeaderComponent: ({
							sortBy,
						}: IConnectionHeaderComponent): React.ReactElement => {
							return NameTableHeaderComponent({
								name: FieldTypeConfig.Priority,
								sortBy,
							});
						},
						Component: ({data}: ITableColumnComponent): React.ReactElement => {
							const {Priority} = data as IImportConnectionResponse;
							return TextColumnItem({
								value: `${Priority}`,
								valueType: ItemType.Text,
							});
						},
					},
					{
						size: 6,
						key: FieldType.SystemType,
						HeaderComponent: ({
							sortBy,
						}: IConnectionHeaderComponent): React.ReactElement => {
							return NameTableHeaderComponent({
								name: FieldTypeConfig.SystemType,
								sortBy,
							});
						},
						Component: ({data}: ITableColumnComponent): React.ReactElement => {
							const {SystemType} = data as IImportConnectionResponse;
							const {displayName = SystemType} = SystemConfigMap[SystemType];
							return TextColumnItem({
								value: displayName,
								valueType: ItemType.Text,
							});
						},
					},
					{
						size: 4,
						key: FieldType.Schedule,
						HeaderComponent: (): React.ReactElement => {
							return NameTableHeaderComponent({name: FieldTypeConfig.Schedule});
						},
						Component: function ScheduleTableColumn({
							data,
						}: ITableColumnComponent): React.ReactElement {
							const {Schedule, SystemType: systemType} =
								data as IImportConnectionResponse;
							const isAutoSystem = SystemConfigMap[systemType].label.includes(
								SystemLabelType.Auto
							);
							return (
								(isAutoSystem &&
									showConnectionSchedule(systemType) &&
									TextColumnItem({
										value: CronSettingTypeConfig[Schedule],
										valueType: ItemType.Text,
									})) || <></>
							);
						},
					},
					{
						size: 6,
						key: FieldType.LastImportTime,
						HeaderComponent: (): React.ReactElement => {
							return NameTableHeaderComponent({
								name: FieldTypeConfig.LastImportTime,
							});
						},
						Component: ({data}: ITableColumnComponent): React.ReactElement => {
							const {LastImportTime} = data as IImportConnectionResponse;
							return TextColumnItem({
								value: `${LastImportTime}`,
								valueType: ItemType.DateTime,
							});
						},
						hide: true,
					},
					{
						size: 8,
						key: FieldType.CreatedBy,
						HeaderComponent: ({
							sortBy,
						}: IConnectionHeaderComponent): React.ReactElement => {
							return NameTableHeaderComponent({
								name: FieldTypeConfig.CreatedBy,
								sortBy,
							});
						},
						Component: ({data}: ITableColumnComponent): React.ReactElement => {
							const {CreatedBy} = data as IImportConnectionResponse;
							return TextColumnItem({
								value: `${CreatedBy}`,
								valueType: ItemType.Text,
							});
						},
					},
					{
						size: 8,
						key: FieldType.TypeOfConnection,
						HeaderComponent: (): React.ReactElement => {
							return NameTableHeaderComponent({
								name: FieldTypeConfig.TypeOfConnection,
							});
						},
						Component: ({data}: ITableColumnComponent): React.ReactElement => {
							const {SystemType, Beta} = data as IImportConnectionResponse;
							const {label} = SystemConfigMap[SystemType];
							const textColumnList = label.map((systemLabel) => ({
								value: `${systemLabel}`,
								valueType: ItemType.Enum,
							}));
							if (Beta) {
								textColumnList.push({
									value: 'Beta',
									valueType: ItemType.Enum,
								});
							}
							return TextColumnListItem(textColumnList);
						},
					},
					{
						size: 2,
						key: FieldType.UserAction,
						HeaderComponent:
							function ConnectionContextMenu(): React.ReactElement {
								return (
									<IconTipHeaderComponent id={CustomizeColumnContextMenuId} />
								);
							},
						fixed: true,
					},
				],
			},
		},
		EditButton: {
			Text: 'See Connection',
		},
	},
	[ConnectionType.Activation]: {
		Component: {
			[DisplayMode.Tile]: {
				RowConfigList: [
					{
						key: FieldType.SystemId,
						valueType: ItemType.Text,
						formatHook: (data: ObjectType): string => data.OrgId as string,
					},
					{
						key: FieldType.Category,
						valueType: ItemType.Text,
						formatHook: (data: ObjectType): string =>
							SystemConfigMap[data.SystemType as SystemType]?.category,
					},
					{key: FieldType.CreatedBy, valueType: ItemType.Text},
					{key: FieldType.Created, valueType: ItemType.Date},
					{key: FieldType.LastUpdated, valueType: ItemType.Date},
					{key: FieldType.Status, valueType: ItemType.Enum},
				],
			},
			[DisplayMode.Table]: {
				ColumnConfigList: [
					{
						size: 2,
						key: FieldType.Enabled,
						HeaderComponent: (): React.ReactElement => {
							return NameTableHeaderComponent({
								name: '',
							});
						},
						Component: ({data}: ITableColumnComponent): React.ReactElement => {
							const {Enabled} = data as IActivationConnectionResponse;
							return Toggle({
								Enabled,
								EnableIcon,
								DisableIcon,
							});
						},
					},
					{
						size: 8,
						key: FieldType.SystemName,
						HeaderComponent: ({
							sortBy,
						}: IConnectionHeaderComponent): React.ReactElement => {
							return NameTableHeaderComponent({
								name: SystemNameDisplayName,
								sortBy,
							});
						},
						Component: ({data}: ITableColumnComponent): React.ReactElement => {
							const {SystemName, SystemType, GDSPId} =
								data as IActivationConnectionResponse;
							return NameColumnItem({
								systemType: SystemType as SystemType,
								systemName: SystemName,
								GDSPId,
							});
						},
					},
					{
						size: 6,
						key: FieldType.SystemType,
						HeaderComponent: (): React.ReactElement => {
							return NameTableHeaderComponent({
								name: FieldTypeConfig.SystemType,
							});
						},
						Component: ({data}: ITableColumnComponent): React.ReactElement => {
							const {SystemType} = data as IActivationConnectionResponse;
							const {displayName = SystemType} = SystemConfigMap[SystemType];
							return TextColumnItem({
								value: displayName,
								valueType: ItemType.Text,
							});
						},
					},
					{
						size: 6,
						key: FieldType.Created,
						HeaderComponent: ({
							sortBy,
						}: IConnectionHeaderComponent): React.ReactElement => {
							return NameTableHeaderComponent({
								name: FieldTypeConfig.Created,
								sortBy,
							});
						},
						Component: ({data}: ITableColumnComponent): React.ReactElement => {
							const {Created} = data as IActivationConnectionResponse;
							return TextColumnItem({
								value: `${Created}`,
								valueType: ItemType.DateTime,
							});
						},
					},
					{
						size: 8,
						key: FieldType.CreatedBy,
						HeaderComponent: ({
							sortBy,
						}: IConnectionHeaderComponent): React.ReactElement => {
							return NameTableHeaderComponent({
								name: FieldTypeConfig.CreatedBy,
								sortBy,
							});
						},
						Component: ({data}: ITableColumnComponent): React.ReactElement => {
							const {CreatedBy} = data as IActivationConnectionResponse;
							return TextColumnItem({
								value: `${CreatedBy}`,
								valueType: ItemType.Text,
							});
						},
					},
					{
						size: 8,
						key: FieldType.TypeOfConnection,
						HeaderComponent: (): React.ReactElement => {
							return NameTableHeaderComponent({
								name: FieldTypeConfig.TypeOfConnection,
							});
						},
						Component: ({data}: ITableColumnComponent): React.ReactElement => {
							const {SystemType, Beta} = data as IImportConnectionResponse;
							const {label} = SystemConfigMap[SystemType];
							const textColumnList = label.map((systemLabel) => ({
								value: `${systemLabel}`,
								valueType: ItemType.Enum,
							}));
							if (Beta) {
								textColumnList.push({
									value: 'Beta',
									valueType: ItemType.Enum,
								});
							}
							return TextColumnListItem(textColumnList);
						},
					},
					{
						size: 2,
						key: FieldType.UserAction,
						HeaderComponent:
							function ConnectionContextMenu(): React.ReactElement {
								return (
									<IconTipHeaderComponent id={CustomizeColumnContextMenuId} />
								);
							},
						fixed: true,
					},
				],
			},
		},
		EditButton: {
			Text: 'Edit Connection',
		},
	},
};

type IConnectionSystemListMap = {
	[key in ConnectionType]: ISystemType[];
};

const ConnectionSystemListMap: IConnectionSystemListMap = {
	[ConnectionType.Import]: ImportSystemList,
	[ConnectionType.Activation]: ActivationSystemList,
};

export {
	LegacyActivation,
	ConnectionConfig,
	ConnectionLabel,
	AngularStateList,
	StateList,
	Home,
	ConnectionUIConfig,
	ConnectionConfigurationApiConfig,
	AddConnectionConfig,
	ConnectionSystemListMap,
};
export type {
	ITableColumnComponent,
	IRowConfig,
	IColumnConfig,
	IConnectionUIConfig,
	IConnectionComponentConfig,
	IConnectionComponent,
	IConnectionHeaderComponent,
	IConnectionSystemListMap,
};
