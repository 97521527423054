import NgState from 'atlas/ng-state';
import {ConnectionType} from 'atlas/connectors/EIF/Data/ConnectionType';
import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {isEIFInboundEnabled} from 'common/stores/tenantConfig';
import {navigateToImportPage} from '../Configuration/Component/ConnectionConfigurationNavigation';
import {AngularStateList} from '../Data/ConnectionConst';
import {ImportSubsystemType} from '../Data/ImportSubsystemType';

const delay = (ms: number): Promise<unknown> => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

const formatPriorityStr = (num: number): string => {
	const j = num % 10;
	const k = num % 100;
	if (j === 1 && k !== 11) {
		return `${num}st`;
	}
	if (j === 2 && k !== 12) {
		return `${num}nd`;
	}
	if (j === 3 && k !== 13) {
		return `${num}rd`;
	}
	return `${num}th`;
};

const toPriorityNum = (priority: string): number => {
	return parseInt(priority.slice(0, priority.length - 2), 10);
};

const adjustConnectionType = (
	connectionType: ConnectionType
): ConnectionType => {
	const hasInboundAccess = RBAC.hasAccess(
		RBACInterface.EIF_INBOUND_CONNECTIONS,
		RBACActions.VIEW
	);
	switch (connectionType) {
		case ConnectionType.Import:
			return isEIFInboundEnabled() && hasInboundAccess
				? connectionType
				: ConnectionType.Activation;
		case ConnectionType.Activation:
			return connectionType;
	}
	return connectionType;
};

// abandoned. Refresh browser will change history, so history is not reliable here.
const historyBack = (): void => {
	/**
	 *  Wried route in EIF, we used both angular and react route,
	 * 	to remove react route, we need -1,
	 *  to avoid route fallback to default react route, we need -1 again,
	 *  to go back to previous angular route, we need -1,
	 *  then the route will fall in it's default react route.
	 *  So, it's -3 here.
	 */
	window.history.go(-3);
};

// Need to go back to previous page after create/edit/cancel/delete a source
const sourcePageRouteBack = (): void => {
	let prevHistoryIndex = window.StateHistory.from.length - 2;
	let prevHistory = window.StateHistory.from[prevHistoryIndex];
	while (
		prevHistory?.name === AngularStateList.PickConnection ||
		prevHistory?.name === AngularStateList.AddConnection
	) {
		prevHistory = window.StateHistory.from[--prevHistoryIndex];
	}
	/**
	 * When connection created, users can click 'load data' to create source,
	 *  if user clicks cancel in source wizard, we can nav back to connection list page for then newly created connection.
	 */
	if (
		prevHistory?.name === AngularStateList.ConnectionConfiguration &&
		// @ts-ignore
		!prevHistory.goParams?.SystemId
	) {
		const {SystemId, SystemType} = NgState.getAngularState().params;
		if (SystemId && SystemType) {
			NgState.getAngularState().go(AngularStateList.ConnectionConfiguration, {
				connectionType: ConnectionType.Import,
				SystemType,
				SystemId,
			});
		} else {
			navigateToImportPage({
				connectionType: ConnectionType.Import,
				subSystemType: ImportSubsystemType.Connection,
			});
		}
	} else if (prevHistory?.name) {
		NgState.getAngularState().go(prevHistory.name, prevHistory.goParams);
	} else {
		// If user refresh their browser, we'll lost track, roll back to import source list page
		navigateToImportPage({
			connectionType: ConnectionType.Import,
			subSystemType: ImportSubsystemType.Source,
		});
	}
};

const downloadByAnchor = (file: File, fileName?: string): void => {
	const tmpLink = document.createElement('a');
	const objectUrl = URL.createObjectURL(file);

	tmpLink.href = objectUrl;
	tmpLink.download = fileName || file.name;
	document.body.appendChild(tmpLink);
	tmpLink.click();

	document.body.removeChild(tmpLink);
	URL.revokeObjectURL(objectUrl);
};

export {
	delay,
	formatPriorityStr,
	toPriorityNum,
	adjustConnectionType,
	historyBack,
	sourcePageRouteBack,
	downloadByAnchor,
};
