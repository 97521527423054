import React, {react2angular} from 'common/react-vendor';
import {inject, store} from 'store';
import {queryReducer} from 'common/stores/query';
import {Provider} from 'common/redux-vendor';
import {SegmentTabs} from './SegmentTabs';
import {JourneyTabs} from './JourneyTabs';

inject(store, 'query', queryReducer);

const SegmentTabsWithRedux = ({...props}) => {
	return (
		<Provider store={store}>
			<SegmentTabs {...props} />
		</Provider>
	);
};

const JourneyTabsWithRedux = ({ ...props }) => {
	return (
		<Provider store={store}>
			<JourneyTabs {...props} />
		</Provider>
	);
};

angular
	.module('common.datacloud.tabs.tabs', [])
	.component(
		'segmentTabs',
		react2angular(SegmentTabsWithRedux, [], ['$state', '$stateParams'])
	)
	.component(
		'journeyTabs',
		react2angular(JourneyTabsWithRedux, [], ['$state', '$stateParams'])
	);
