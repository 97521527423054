import React from 'common/react-vendor';
import {DNBTypography} from 'common/dnb-uux-vendor';
import {StateService} from '@uirouter/angularjs';
import {SidebarLink} from '../AttrConfig.types';
import styles from './AttributesBreadCrumb.module.scss';

function findBreadcrumbs(menus: SidebarLink[], sref?: string): SidebarLink[] {
	let breadCrumbs: SidebarLink[] = [];
	for (let i = 0; menus[i]; i++) {
		const currentMenu = menus[i]!;
		if (currentMenu.sref === sref) {
			breadCrumbs.push(currentMenu);
			break;
		}
		if (currentMenu.subMenu) {
			const result = findBreadcrumbs(currentMenu.subMenu, sref);
			if (result.length) {
				breadCrumbs = [...breadCrumbs, currentMenu, ...result];
				break;
			}
		}
	}

	return breadCrumbs;
}

const slash = (
	<li aria-hidden='true' className={styles.separator} key='slash'>
		/
	</li>
);

export default function AttributesBreadCrumb({
	menus,
	$state,
}: {
	menus: SidebarLink[];
	$state: StateService;
}): React.ReactElement {
	const breadcrumbs = findBreadcrumbs(menus, $state.current.name);

	return (
		<nav className={styles.breadcrumbs}>
			<ol>
				<li>
					<DNBTypography variant='caption'>Attributes</DNBTypography>
				</li>
				{breadcrumbs.map(({label, sref}) => [
					slash,
					<li key={sref}>
						<DNBTypography variant='caption'>{label}</DNBTypography>
					</li>,
				])}
			</ol>
		</nav>
	);
}
