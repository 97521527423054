import React from 'common/react-vendor';

const {useRef, useEffect} = React;

export function useMountPoint(): HTMLDivElement | null {
	const mountPointRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!mountPointRef.current) {
			mountPointRef.current = document.createElement('div');
			document.body.append(mountPointRef.current);
			return () => {
				mountPointRef.current!.remove();
			};
		}
	}, []);

	return mountPointRef.current;
}
