import DeprecatedModalService from '../modal/DeprecatedModalService';
import NoticeService from '../notice/NoticeService';
import {Banner} from '../../app/services/BannerService';
import ReactMessagingService from './react.messaging.utils';

interface UIActionResponse {
	data: {
		error?: unknown;
		error_description?: unknown;
		errorMsg?: unknown;
		Errors?: string[];
		UIAction?: {
			title?: string;
			status?: string;
			message?: string;
			view?: string;
		};
	};
	statusText: string;
	status: string | number;
	config?: {
		headers: {
			ErrorDisplayMethod?: string;
			ErrorDisplayOptions?: string;
		};
		url: string;
	};
}

const check = (response: UIActionResponse): boolean => {
	if (!response || !response.data) {
		return false;
	}

	const {data} = response;
	const {error, error_description, errorMsg, Errors, UIAction} = data;
	return !!(error || error_description || errorMsg || Errors || UIAction);
};

const process = (response: UIActionResponse): void => {
	if (check(response)) {
		const {config, statusText, status, data} = response;
		const display = config?.headers?.ErrorDisplayMethod || 'banner';
		const options = config?.headers?.ErrorDisplayOptions
			? JSON.parse(config.headers?.ErrorDisplayOptions)
			: {};
		const uiAction = data.UIAction || {};
		const view = (
			uiAction?.view && display !== 'nodisplay' ? uiAction.view : display
		).toLowerCase();

		const method = uiAction.status?.toLowerCase() || 'error';
		const url = config?.url || '';
		const title =
			uiAction.title === undefined
				? `${status} "${statusText}" ${url}`
				: uiAction.title;
		const message =
			uiAction.message ||
			data.errorMsg ||
			data.error_description ||
			data.Errors?.join();
		const name = 'API_Exception';
		const opts = {...options, title, message, name};

		switch (view) {
			case 'none':
			case 'nodisplay':
				break;

			case 'banner':
				if (document.getElementById('react-banner-container')) {
					ReactMessagingService.showBanner(response, options);
				} else {
					(Banner[method as keyof typeof Banner] as typeof Banner.error)(opts);
				}
				break;

			case 'notice':
				NoticeService[method as keyof typeof NoticeService](opts);
				break;

			case 'suppress':
				console.log('-!- API error suppressed:', response, options);
				break;

			case 'popup':
			case 'modal':
			default:
				DeprecatedModalService[method as keyof typeof DeprecatedModalService](
					opts
				);
		}
	}
};

export default {
	process,
};
