import React from 'common/react-vendor';
import cx from 'classnames';
import styles from './Table.module.scss';

const {useRef, useEffect, useState, useMemo} = React;

// The default column width in css pixels
const DEFAULT_WIDTH = 200;
const DEFAULT_MIN_WIDTH = 30;
const DEFAULT_MAX_WIDTH = Infinity;

export enum Side {
	Left = 'left',
	Right = 'right',
}

export interface ColumnProps<Row> {
	key: React.Key;
	// the header prop overrides children as the column's header cell
	header?: React.ReactNode;
	children?: React.ReactNode;
	Cell: React.FC<React.PropsWithChildren<{row: Row}>>;
	freeze?: Side;
	width?: number;
	minWidth?: number;
	maxWidth?: number;
	onResize?: (newWidth: number) => void;
	resizeDisabled?: boolean;
}

export function Column<Row>(_props: ColumnProps<Row>): JSX.Element {
	throw new Error(
		'<Column /> components must be direct children of a <Table />'
	);
}

type HeaderCellProps<Row> = {
	column: ProcessedColumn<Row>;
	setResize: React.Dispatch<Resize | null>;
	isResizing: boolean;
} & (
	| {side?: never; offset?: never; hasBorder?: never}
	| {
			side: Side;
			hasBorder: boolean;
	  }
);

const MemoHeaderCell = React.memo(HeaderCell) as typeof HeaderCell;

function HeaderCell<Row>({
	column,
	setResize,
	isResizing,
	side,
	hasBorder,
}: HeaderCellProps<Row>): JSX.Element {
	const width = column.width ?? DEFAULT_WIDTH;
	const style: React.CSSProperties = {width};
	if (side) style[side] = column.offset;

	return (
		<th
			className={cx(
				styles.cell,
				stickyClass(side),
				hasBorder && borderClass(side),
				side === Side.Right && hasBorder && styles.pushStickyCellRight
			)}
			style={style}>
			{column.header ?? column.children}
			{column.onResize && !column.resizeDisabled ? (
				<div
					className={cx(styles.resizeHandle, isResizing && styles.resizing)}
					onMouseDown={(e) => {
						setResize({
							column: column.key,
							initialClientX: e.clientX,
							delta: 0,
						});
						e.preventDefault();
						e.stopPropagation();
					}}
				/>
			) : null}
		</th>
	);
}

interface Resize {
	column: React.Key;
	initialClientX: number;
	delta: number;
}

type ColumnDef<Row> =
	| {columns: ColumnProps<Row>[]; children?: undefined}
	| {children: React.ReactNode; columns?: undefined};

export interface TableProps<Row> {
	rows: Row[];
	getRowKey?: (row: Row) => React.Key;
	getRowClass?: (row: Row) => string;
	onClickRow?: (row: Row, event: React.SyntheticEvent) => void;
}

export {MemoTable as Table};
const MemoTable = React.memo(Table) as typeof Table;

function Table<Row>({
	rows,
	getRowKey,
	getRowClass,
	onClickRow,
	columns,
	children,
}: TableProps<Row> & ColumnDef<Row>): JSX.Element {
	const processedColumns = useProcessedColumns(columns, children);

	const [resize, setResize] = useState<Resize | null>(null);
	const isResizing = resize !== null;

	useEffect(() => {
		if (isResizing) {
			const handleMove = ({clientX}: MouseEvent): void => {
				setResize((r) =>
					r
						? {
								...r,
								delta: clientX - r.initialClientX,
						  }
						: r
				);
			};

			document.body.addEventListener('mousemove', handleMove);
			return () => document.body.removeEventListener('mousemove', handleMove);
		}
	}, [isResizing]);

	useEffect(() => {
		if (resize) {
			const endResize = (): void => {
				for (const cols of processedColumns)
					for (const [idx, newWidth] of getNewWidths(cols, resize)) {
						cols[idx]!.onResize!(newWidth);
					}
				setResize(null);
			};

			document.body.addEventListener('mouseup', endResize);
			document.body.addEventListener('mouseleave', endResize);
			return () => {
				document.body.removeEventListener('mouseup', endResize);
				document.body.removeEventListener('mouseleave', endResize);
			};
		}
	}, [processedColumns, resize]);

	const [leftColumns, middleColumns, rightColumns] = useResizedColumns(
		processedColumns,
		resize
	);

	const wrapper = useRef<HTMLDivElement>(null);
	const [isHScrollable, setIsHScrollable] = useState(false);

	useEffect(() => {
		const o = new ResizeObserver(() => {
			if (!wrapper.current) return;
			const {scrollWidth, clientWidth} = wrapper.current;
			setIsHScrollable(scrollWidth > clientWidth);
		});
		o.observe(wrapper.current!);
		return () => o.disconnect();
	}, []);

	const hasCols =
		leftColumns.length + rightColumns.length + middleColumns.length > 0;

	return (
		<div className={styles.wrapper} ref={wrapper}>
			{hasCols ? (
				<table className={cx(styles.table, styles.blockLayout)}>
					<thead className={styles.thead}>
						<tr className={styles.row}>
							{leftColumns.map((col, idx) => (
								<MemoHeaderCell<Row>
									key={col.key}
									column={col}
									setResize={setResize}
									isResizing={resize ? resize.column === col.key : false}
									side={Side.Left}
									hasBorder={idx === leftColumns.length - 1 && isHScrollable}
								/>
							))}
							{middleColumns.map((col) => (
								<MemoHeaderCell<Row>
									key={col.key}
									column={col}
									setResize={setResize}
									isResizing={resize ? resize.column === col.key : false}
								/>
							))}
							{rightColumns.map((col, idx) => (
								<MemoHeaderCell<Row>
									key={col.key}
									column={col}
									setResize={setResize}
									isResizing={resize ? resize.column === col.key : false}
									side={Side.Right}
									hasBorder={idx === 0 && isHScrollable}
								/>
							))}
						</tr>
					</thead>
					<tbody>
						{rows.map((row, idx) => (
							<tr
								key={getRowKey?.(row) ?? idx}
								className={cx(styles.row, getRowClass?.(row))}
								tabIndex={onClickRow ? 0 : undefined}
								role={onClickRow ? 'button' : undefined}
								onKeyDown={
									onClickRow
										? (e) => {
												if (e.key === 'Enter' || e.key === ' ')
													onClickRow(row, e);
										  }
										: undefined
								}
								onClick={onClickRow ? (e) => onClickRow(row, e) : undefined}>
								{leftColumns.map(({key, Cell, width, offset}, idx) => (
									<td
										key={key}
										className={cx(
											styles.cell,
											styles.stickyLeftCell,
											idx === leftColumns.length - 1 &&
												isHScrollable &&
												styles.rightBorder
										)}
										style={{
											left: offset,
											width: width ?? DEFAULT_WIDTH,
										}}>
										<Cell row={row} />
									</td>
								))}
								{middleColumns.map(({key, Cell, width}) => (
									<td
										key={key}
										className={styles.cell}
										style={{
											width: width ?? DEFAULT_WIDTH,
										}}>
										<Cell row={row} />
									</td>
								))}
								{rightColumns.map(({key, Cell, width, offset}, idx) => (
									<td
										key={key}
										className={cx(
											styles.cell,
											styles.stickyRightCell,
											idx === 0 && isHScrollable && styles.leftBorder,
											idx === 0 && styles.pushStickyCellRight
										)}
										style={{
											right: offset,
											width: width ?? DEFAULT_WIDTH,
										}}>
										<Cell row={row} />
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			) : (
				<>No Columns</>
			)}
		</div>
	);
}

type ColEl<Row> = React.ReactElement<ColumnProps<Row>, typeof Column>;

function isColumnNode<Row>(node: React.ReactNode): node is ColEl<Row> {
	if (!node) return false;
	if (typeof node !== 'object' || Array.isArray(node)) return false;
	// @ts-ignore: Type {} doesn't have property 'type' will work with this logic
	return node.type === Column;
}

type ProcessedColumn<Row> = ColumnProps<Row> & {offset: number};
type ProcessedColumns<Row> = [
	left: ProcessedColumn<Row>[],
	middle: ProcessedColumn<Row>[],
	right: ProcessedColumn<Row>[]
];

function useProcessedColumns<Row>(
	columns?: ColumnProps<Row>[],
	children?: React.ReactNode
): ProcessedColumns<Row> {
	const colEntries = useMemo(
		() => columns ?? childrenToColumns<Row>(children),
		[columns, children]
	);

	return useMemo<ProcessedColumns<Row>>(() => {
		const leftColumns: ProcessedColumn<Row>[] = [];
		const middleColumns: ProcessedColumn<Row>[] = [];
		const rightColumns: ProcessedColumn<Row>[] = [];
		let leftOffset = 0;
		let rightOffset = 0;

		// TODO: just use this to partition the columns. Calculate the offsets in the `useResizedColumns` hook to fix the bug where the offsets are wrong during a resize
		for (const col of colEntries) {
			const colWidth = col.width ?? DEFAULT_WIDTH;
			switch (col.freeze) {
				case Side.Left:
					leftColumns.push({...col, offset: leftOffset});
					leftOffset += colWidth;
					break;
				case Side.Right:
					rightColumns.push({...col, offset: rightOffset});
					rightOffset += colWidth;
					break;
				default:
					middleColumns.push({...col, offset: 0});
			}
		}

		// The offsets we've collected are for the left edges, which is fine for the
		// columns on the left, but we need to calculate the right edge offset for
		// the right frozen columns
		for (const col of rightColumns) {
			col.offset = rightOffset - (col.width ?? DEFAULT_WIDTH) - col.offset;
		}

		return [leftColumns, middleColumns, rightColumns];
	}, [colEntries]);
}

function childrenToColumns<Row>(children: React.ReactNode): ColumnProps<Row>[] {
	const cols: ColumnProps<Row>[] = [];
	React.Children.forEach(children, (col) => {
		if (!col) return;
		if (!isColumnNode<Row>(col))
			throw new Error(
				'Only <Column /> components may be rendered as children of a <Table />'
			);
		if (!col.key) throw new Error('<Column /> components must have a key');
		cols.push({...col.props, key: col.key});
	});
	return cols;
}

function stickyClass(side?: Side): string | undefined {
	switch (side) {
		case Side.Left:
			return styles.stickyLeftCell;
		case Side.Right:
			return styles.stickyRightCell;
	}
}

// Inverted b/c side is the side of the column, not the side of the border
function borderClass(side?: Side): string | undefined {
	switch (side) {
		case Side.Left:
			return styles.rightBorder;
		case Side.Right:
			return styles.leftBorder;
	}
}

function useResizedColumns<Row>(
	processedColumns: ProcessedColumns<Row>,
	resize: Resize | null
): ProcessedColumns<Row> {
	if (!resize) return processedColumns;
	let [left, middle, right] = processedColumns;
	left = resizeColumns(left, resize);
	middle = resizeColumns(middle, resize);
	right = resizeColumns(right, resize);
	return [left, middle, right];
}

function resizeColumns<Row>(
	cols: ProcessedColumn<Row>[],
	resize: Resize
): ProcessedColumn<Row>[] {
	const resizedCols = [...cols];
	for (const [idx, width] of getNewWidths(cols, resize))
		resizedCols[idx] = {...resizedCols[idx]!, width};
	return resizedCols;
}

function getNewWidths<Row>(
	cols: ProcessedColumn<Row>[],
	resize: Resize
): [colIdx: number, newWidth: number][] {
	// This function should only operate over columns that can be resized
	const resizableColumns = cols.filter((c) => c.onResize);
	const colIdx = resizableColumns.findIndex((c) => c.key === resize.column);
	if (colIdx === -1) return [];
	const currentWidth = resizableColumns[colIdx]!.width ?? DEFAULT_WIDTH;
	const newWidth = Math.max(
		DEFAULT_MIN_WIDTH,
		Math.min(currentWidth + resize.delta, DEFAULT_MAX_WIDTH)
	);
	return [[colIdx, newWidth]];
}
