import StateHistory from '../../../../../atlas/app/StateHistory';
import AttrConfigStore from '../AttrConfigStore';
import template from './subheader.component.html';

angular.module('common.attributes.subheader', []).component('attrSubheader', {
	template,
	bindings: {
		overview: '<',
		showPremiumBadge: '<',
	},
	controller($state, $stateParams) {
		const vm = this;

		vm.store = AttrConfigStore;

		vm.$onInit = function () {
			vm.section = vm.store.getSection($state.current.name);
			vm.params = $stateParams;
			vm.tabs = vm.overview?.Selections.filter(
				({TotalAttrs}) => TotalAttrs === undefined || TotalAttrs > 0
			);
			vm.pageSize = 5;
			// The array index of the current tab
			const startIndex = vm.tabs.reduce(
				(acc, {id, DisplayName}, idx) =>
					[id, DisplayName].includes($stateParams.category) ? idx : acc,
				0
			);
			vm.page = Math.ceil((1 + startIndex) / vm.pageSize);

			switch (vm.section) {
				case 'enable':
					vm.supplemental = 'ENABLED';
					break;
				case 'activate':
					vm.supplemental = 'ACTIVE';
					break;
				default:
					vm.supplemental = '';
					break;
			}

			vm.store.setData('overview', vm.overview);
		};

		vm.click = function (tab) {
			ShowSpinner('Loading Attributes');

			const params = {
				activate: {
					category: tab.DisplayName,
					subcategory: '',
				},
				enable: {
					section: tab.DisplayName,
					category: '',
					subcategory: '',
				},
				edit: {
					category: tab.DisplayName,
					subcategory: '',
				},
			};

			$state.go('.', params[vm.section]);
		};

		vm.getTo = function () {
			return StateHistory.lastToParams();
		};

		vm.getFrom = function () {
			return StateHistory.lastFromParams();
		};

		vm.isActive = function (tab) {
			const t_c = tab.id || tab.DisplayName;
			const t_l = tab.id || tab.DisplayName;
			const p_c = vm.params.category;
			const p_s = vm.params.section;
			const act = vm.section == 'activate' || vm.section == 'edit';
			const key = act ? 'category' : 'section';
			const val = act ? t_c : t_l;

			const x = act ? t_c == p_c : t_l == p_s;
			const y = vm.getTo()[key] == val;
			const z = vm.getFrom()[key] != val || vm.getTo()[key] == val;

			return (x || y) && z;
		};

		vm.indicatorWidth = () => 1 / Math.ceil(vm.tabs.length / vm.pageSize);
		vm.indicatorStart = () => (vm.page - 1) * vm.indicatorWidth();
	},
});
