import {
	DNBIconButton,
	DNBMultiSelectMenu,
	DNBTextField,
	DNBTooltip,
} from '@dnb-uux-design-system/react';
import {FilterAlt, SearchRounded, Sort} from '@mui/icons-material';
import {InputAdornment} from '@mui/material';
import React from 'common/react-vendor';
import {AttrConfigFilters} from '../AttrConfig.types';

interface AttrFiltersProps {
	filters: AttrConfigFilters;
	section: 'activate' | 'enable';
	onchange: (filters: AttrConfigFilters) => void;
}

export const AttrFilters: React.FC<
	React.PropsWithChildren<AttrFiltersProps>
> = ({filters, section, onchange}: AttrFiltersProps) => {
	const sortAsc = filters.sortPrefix === '+';
	const iconRef = React.useRef<HTMLDivElement>(null);
	const [openFilters, setOpenFilters] = React.useState(false);

	const hasFilter = (): boolean =>
		filters.show.Selected ||
		filters.hide.Selected ||
		filters.show.IsPremium ||
		filters.hide.IsPremium ||
		false;

	return (
		<div style={{display: 'flex', gap: '4px'}}>
			<DNBTextField
				size='compact'
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<SearchRounded />
						</InputAdornment>
					),
				}}
				placeholder='Search'
				value={filters.queryText}
				onChange={(e) => {
					const f = {...filters, queryText: e.target.value, page: 1};
					onchange(f);
				}}
			/>
			<DNBTooltip
				content={`Sort: ${sortAsc ? 'Ascending' : 'Descending'}`}
				arrow>
				<div>
					<DNBIconButton
						size='compact'
						onClick={() => {
							const sortPrefix = (sortAsc ? '-' : '+') as '+' | '-';
							const f = {...filters, sortPrefix};
							onchange(f);
						}}>
						<Sort style={{transform: sortAsc ? 'scaleY(-1)' : 'none'}} />
					</DNBIconButton>
				</div>
			</DNBTooltip>
			<DNBTooltip content='Filters' arrow>
				<div ref={iconRef}>
					<DNBIconButton
						size='compact'
						variant={hasFilter() ? 'primary' : 'default'}
						onClick={() => {
							setOpenFilters(true);
						}}>
						<FilterAlt />
					</DNBIconButton>
				</div>
			</DNBTooltip>
			<DNBMultiSelectMenu
				anchorEl={iconRef.current}
				onClose={() => {
					setOpenFilters(false);
				}}
				open={openFilters}
				menuItems={[
					{
						checked: filters.show.Selected || false,
						label: `${section === 'activate' ? 'Activated' : 'Enabled'} only`,
						isDisabled: filters.disabled.Selected,
						onChange: () => {
							const f = {...filters, page: 1};
							f.show.Selected = !f.show.Selected;
							f.hide.Selected = false;
							onchange(f);
						},
						value: 'showSelected',
					},
					{
						checked: filters.hide.Selected || false,
						label: `${
							section === 'activate' ? 'Non-Activated' : 'Disabled'
						} only`,
						isDisabled: filters.disabled.Selected,
						onChange: () => {
							const f = {...filters, page: 1};
							f.hide.Selected = !f.hide.Selected;
							f.show.Selected = false;
							onchange(f);
						},
						value: 'hideSelected',
					},
					...(section === 'enable'
						? [
								{
									checked: filters.show.IsPremium || false,
									label: 'Premium only',
									isDisabled: filters.disabled.IsPremium,
									onChange: () => {
										const f = {...filters, page: 1};
										f.show.IsPremium = !f.show.IsPremium;
										f.hide.IsPremium = false;
										onchange(f);
									},
									value: 'showIsPremium',
								},
								{
									checked: filters.hide.IsPremium || false,
									label: 'Non-premium only',
									isDisabled: filters.disabled.IsPremium,
									onChange: () => {
										const f = {...filters, page: 1};
										f.hide.IsPremium = !f.hide.IsPremium;
										f.show.IsPremium = false;
										onchange(f);
									},
									value: 'hideIsPremium',
								},
						  ]
						: []),
				]}
			/>
		</div>
	);
};
