import template from './tree-activity.component.html';
import {cmpMap} from '../tree.constants';

angular
	.module('common.datacloud.query.builder.tree.activity', [])
	.directive('queryActivityDirective', function () {
		return {
			restrict: 'E',
			scope: {
				vm: '=',
			},
			template,
			controllerAs: 'vm',
			controller: function ($scope) {
				const vm = $scope.vm;

				vm.getOperationLabel = function () {
					const {cmp = 'GREATER_THAN'} =
						vm.activityRestriction.activityRestriction;
					return cmpMap[cmp];
				};

				vm.getOperationValue = function (position = 0) {
					const {cnt = [,]} = vm.activityRestriction.activityRestriction;
					return cnt[position]?.toLocaleString();
				};

				vm.getSuffixLabel = function (index) {
					const {cnt = []} = vm.activityRestriction.activityRestriction;
					const suffix = vm.showTo() ? ' -' : '';
					return index != cnt.length - 1 ? suffix : '';
				};

				vm.showTo = function () {
					const {cmp} = vm.activityRestriction.activityRestriction;
					return Object.values(vm.two_inputs).includes(cmp);
				};
			},
		};
	});
