import {
	MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT_ATTRIBUTES,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT_ATTRIBUTES_PICKER,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT_ATTRIBUTES,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT_ATTRIBUTES_PICKER,
} from 'atlas/navigation/header/components/page-navigation.utils';
import {Query} from 'common/components/datacloud/datacloud.types';
import {
	deleteAngularHashKey,
	sanitizeSegment,
} from 'common/components/datacloud/segment/segment.helpers';
import type {QueryState} from 'common/stores/query/types';
import LocalStorageUtility from 'common/widgets/utilities/local-storage.utility';
import {getString} from 'common/app/utilities/ResourceUtility';
import {isSegmentationV2Enabled} from 'common/stores/tenantConfig';
import type {FormData} from './SaveSegmentDialog';

export const getDefaultSegmentName = (): string => {
	const uid = crypto.randomUUID().slice(0, 5).toUpperCase();

	const LoginDocument = LocalStorageUtility.getLoginDocument();

	// Why? Well idk...
	const firstName = (LoginDocument.FirstName || 'Unknown')[0]?.replace(
		/\W/g,
		''
	);
	const lastName = (LoginDocument.LastName || 'User').replace(/\W/g, '');

	const datetime = new Date().toLocaleString(undefined, {
		month: '2-digit',
		day: '2-digit',
		year: '2-digit',
		hour: 'numeric',
		minute: 'numeric',
		hour12: false,
	});

	return `${firstName}. ${lastName} ${datetime} - ${uid}`;
};

// I wish we could use a prop for this, but having to combine react and angular is a pain and makes things hacky...
export const getTitle = (routeName?: string): string => {
	switch (routeName) {
		case MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT:
		case MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT_ATTRIBUTES:
		case MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT_ATTRIBUTES_PICKER:
			return 'Create a Segment to Train your Model';
		case MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT:
		case MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT_ATTRIBUTES:
		case MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT_ATTRIBUTES_PICKER:
			return 'Create a Scoring Segment';
		default:
			return getString('SEGMENT_CREATE_TITLE');
	}
};

// I wish we could use a prop for this, but having to combine react and angular is a pain and makes things hacky...
export const getSubmitLabel = (routeName?: string): string => {
	switch (routeName) {
		case MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT:
		case MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT_ATTRIBUTES:
		case MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT_ATTRIBUTES_PICKER:
			return 'Save & Select';
		case MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT:
		case MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT_ATTRIBUTES:
		case MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT_ATTRIBUTES_PICKER:
			return 'Save & Select';
		default:
			return 'Save';
	}
};

// #region mapSegment
type MapSegmentParams = {
	defaultSegmentName: string;
	formData: FormData;
	queryStore?: QueryState;
	customParentSegmentName?: string;
};

export const mapSegment = ({
	defaultSegmentName,
	formData,
	queryStore,
	customParentSegmentName,
}: MapSegmentParams): Query =>
	sanitizeSegment({
		name: defaultSegmentName.replace(/(\s|\.|,|-|\/|:)/g, '').toUpperCase(),
		display_name: formData.segmentName,
		description: formData.description,
		teamId: formData.team?.TeamId || null,
		account_restriction: deleteAngularHashKey(queryStore?.accountRestriction),
		contact_restriction: deleteAngularHashKey(queryStore?.contactRestriction),
		page_filter: {
			row_offset: 0,
			num_rows: 10,
		},
		...(isSegmentationV2Enabled()
			? {
					member_restriction: deleteAngularHashKey(
						queryStore?.memberRestriction
					),
					materialized: false,
			  }
			: {}),
		...(queryStore?.segment?.ssviCardName
			? {ssviCardName: queryStore?.segment?.ssviCardName}
			: {}),
		...(customParentSegmentName
			? {
					customParentSegmentName,
			  }
			: {}),
	});
// #endregion mapSegment
