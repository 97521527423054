import RBAC, {
	AccessGroups,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {PredefinedRoles} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import template from './list.component.html';
import {Banner} from '../../../../app/services/BannerService';
import AttrConfigStore from '../AttrConfigStore';

angular.module('common.attributes.list', []).component('attrResultsList', {
	template,
	bindings: {
		filters: '<',
	},
	controller: function ($state, $stateParams) {
		const vm = this;

		vm.store = AttrConfigStore;
		vm.buckets = [];
		vm.attributes = {};
		vm.indeterminate = {};
		vm.startChecked = {};
		vm.accesslevel = '';
		vm.allChecked = false;
		vm.refreshDisabled = false;
		vm.lastRefresh = vm.store.getData('lastRefresh');
		vm.showRefresh = false;
		vm.searchFilter = vm.store.searchFilter;
		vm.$onInit = function () {
			vm.accesslevel = RBAC.getUserAccessLevel().name;
			vm.section = vm.store.getSection($state.current.name);
			vm.category = vm.store.get('category');
			vm.data = vm.store.get('data');
			vm.filters.page = 1;
			vm.buckets = vm.data.buckets;

			vm.autoDrillDown();
			vm.parseData();
			vm.countSelected();

			vm.store.setData('original', JSON.parse(JSON.stringify(vm.data.config)));

			if ((vm.isUser() || vm.isExternalAdmin()) && vm.section == 'activate') {
				vm.showImmutable();
			}
		};

		vm.showImmutable = function () {
			Banner.info({
				title: 'Immutable Data Notification',
				message:
					'Your access level has placed certain restrictions on modifications in this section.',
			});
		};

		vm.autoDrillDown = function () {
			if (
				vm.data.config &&
				vm.data.config.Subcategories &&
				vm.data.config.Subcategories.length == 1
			) {
				vm.go(vm.data.config.Subcategories[0].DisplayName);
			}
		};

		vm.parseData = function () {
			let total = [];

			vm.data.config.Subcategories.forEach(function (item) {
				const selected = item.Attributes.filter(function (attr) {
					if (attr.IsPremium) {
						vm.filters.disabled = false;
					}

					return attr.Selected;
				});

				selected.forEach(function (attr) {
					vm.startChecked[attr.Attribute] = true;
				});

				item.checked = selected.length;
				item.Selected = vm.isChecked(item);

				vm.attributes[item.DisplayName] = item.Attributes;

				total = total.concat(selected);
			});

			vm.store.set('selected', total);
			vm.store.set('start_selected', total);

			vm.setAllChecked();
		};

		vm.countSelected = function () {
			const total = [];

			Object.keys(vm.attributes).forEach(function (key) {
				const subcategory = vm.getSubcategory(key);
				const attributes = vm.attributes[key];
				const selected = [];

				attributes.forEach(function (attr) {
					attr.SubCategory = key;

					if (attr.Selected === true) {
						selected.push(attr);
						total.push(attr);
					}
				});

				if (selected.length > 0 && selected.length != attributes.length) {
					vm.indeterminate[key] = true;
				} else {
					delete vm.indeterminate[key];
				}

				subcategory.checked = selected.length;
			});

			vm.store.set('selected', total);
			vm.data.config.Selected = vm.store.get('selected').length;

			vm.setAllChecked();
		};

		vm.setAllChecked = function () {
			const listLimit = vm.store.get('limit');
			vm.allChecked =
				listLimit === vm.store.get('selected').length ||
				(vm.isAllChecked() &&
					!vm.indeterminate[vm.subcategory || 'all_attributes']);
		};

		vm.getResults = function () {
			if (vm.subcategory && !vm.buckets[vm.subcategory]) {
				vm.buckets[vm.subcategory] = {Bkts: {List: []}};
				vm.store.getBucketData(vm.category, vm.subcategory);
			}

			const ret = vm.subcategory
				? vm.attributes[vm.subcategory]
				: vm.data.config.Subcategories;

			return ret;
		};

		vm.getCount = function () {
			return vm.subcategory
				? vm.getSubcategory(vm.subcategory).TotalAttrs
				: vm.data.config.TotalAttrs;
		};

		vm.getBuckets = function (attribute) {
			if (!vm.buckets[vm.subcategory]) {
				return [];
			}

			const bucket = vm.buckets[vm.subcategory][attribute];

			if (bucket && !bucket.Bkts) {
				bucket.Bkts = {
					List: [
						{
							Lbl: '<string>',
							Cnt: bucket.Cnt,
						},
					],
				};
			}

			return bucket ? bucket.Bkts.List || [] : [];
		};

		vm.getSubcategory = function (name) {
			return vm.data.config.Subcategories.filter(function (item) {
				return item.DisplayName == name;
			})[0];
		};

		vm.back = function () {
			vm.go('');
		};

		vm.click = function (item) {
			if (item.Attributes) {
				vm.go(item.DisplayName);
			} else {
				vm.toggleSelected(item);
			}
		};

		vm.isChecked = function (item) {
			if (item.Attributes) {
				if (vm.indeterminate[item.DisplayName] === true) {
					vm.setIndeterminate(item.DisplayName, true);

					item.Selected = true;

					return true;
				} else {
					item.Selected = item.checked == item.TotalAttrs;

					return item.checked == item.TotalAttrs;
				}
			} else {
				return item.Selected;
			}
		};

		vm.isAllChecked = function () {
			let subcategory, selected, total;

			if (!vm.subcategory) {
				selected = vm.store.get('selected').length;
				total = vm.data.config.TotalAttrs;
			} else {
				subcategory = vm.getSubcategory(vm.subcategory);
				selected = subcategory.checked;
				total = subcategory.TotalAttrs;
			}

			const indeterminate = selected !== 0 && selected != total;
			vm.setIndeterminate(vm.checkboxName(), indeterminate);

			return selected !== 0;
		};

		vm.isDisabled = function (item) {
			const isFrozen = item.IsFrozen;
			let overLimit = false;

			const listLimit = vm.store.get('limit');

			if (listLimit >= 0) {
				/**
				 * TODO: Maybe once we refactor to React
				 * we can check why this check takes
				 * to much time.
				 */
				overLimit =
					vm.store.getSelectedTotal($state.current.name, $stateParams, true) >=
						listLimit && !vm.isChecked(item);
			}

			return item.Attributes ? overLimit : isFrozen || overLimit;
		};

		vm.isUser = function () {
			return RBAC.userHasAccessLevel([
				PredefinedRoles.INTERNAL_USER,
				PredefinedRoles.EXTERNAL_USER,
			]);
		};

		vm.isInternalAdmin = function () {
			return RBAC.userHasAccessLevel(AccessGroups.INTERNAL_ADMINS);
		};

		vm.isExternalAdmin = function () {
			return RBAC.userHasAccessLevel([PredefinedRoles.EXTERNAL_ADMIN]);
		};

		vm.isStartsDisabled = function (item) {
			if (item.Attributes || vm.section == 'enable' || vm.section == 'groups') {
				return false;
			}

			if (!item.Selected && (vm.isInternalAdmin() || vm.isExternalAdmin())) {
				return false;
			}

			if (item.Selected && vm.isInternalAdmin()) {
				return false;
			}

			if (vm.isUser()) {
				return true;
			}

			return vm.startChecked[item.Attribute];
		};

		vm.toggleSelected = function (item) {
			if (vm.isDisabled(item) || vm.isStartsDisabled(item)) {
				return false;
			}

			if (item.Attributes) {
				vm.setIndeterminate(item.DisplayName, false);

				item.Attributes.sort(vm.sortAttributes).forEach(function (attr) {
					if (vm.isDisabled(attr) || vm.isStartsDisabled(attr)) {
						return;
					}

					attr.Selected = (item.checked === 0);

					if (attr.Selected) {
						vm.store.get('selected').push(attr);
					}
				});
			} else {
				item.Selected = !item.Selected;
			}

			vm.countSelected();
		};

		vm.toggleAll = function () {
			const listLimit = vm.store.get('limit');
			const len = vm.store.getSelectedTotal($state.current.name, $stateParams, true);
			const newChecked = (listLimit === -1 || listLimit > len) ? !vm.allChecked : false;
			if (vm.subcategory) {
				vm.allChecked = newChecked;

				vm.attributes[vm.subcategory]
					.sort(vm.sortAttributes)
					.forEach(function (attr) {
						if (
							vm.isDisabled(attr) ||
							vm.isStartsDisabled(attr) ||
							!vm.searchFilter(attr)
						) {
							return;
						}

						if (!attr.Selected && newChecked) {
							vm.store.get('selected').push(attr);
						}
						attr.Selected = newChecked;
					});
			} else {
				vm.allChecked = newChecked;

				Object.keys(vm.attributes)
					.sort(vm.sortSubcategories)
					.forEach(function (key) {
						vm.setIndeterminate(key, false);
						vm.attributes[key].checked = vm.attributes[key].TotalAttrs;

						vm.attributes[key].sort(vm.sortAttributes).forEach(function (attr) {
							if (
								vm.isDisabled(attr) ||
								vm.isStartsDisabled(attr) ||
								!vm.searchFilter(attr)
							) {
								return;
							}

							if (!attr.Selected && newChecked) {
								vm.store.get('selected').push(attr);
							}
							attr.Selected = newChecked;
						});
					});
			}

			vm.countSelected();
		};

		vm.checkboxName = function () {
			return vm.subcategory ? 'check_all_' + vm.subcategory : 'all_attributes';
		};

		vm.setIndeterminate = function (checkbox, value) {
			vm.indeterminate[checkbox] = value;
		};

		vm.sortAttributes = function (a, b) {
			return a.Attribute.toLowerCase().localeCompare(b.Attribute.toLowerCase());
		};

		vm.sortSubcategories = function (a, b) {
			return a.toLowerCase().localeCompare(b.toLowerCase());
		};

		vm.getAttributes = function (filtered) {
			let treeroot = [];

			vm.data.config.Subcategories.forEach(function (item) {
				treeroot = item.Attributes.concat(treeroot);
			});

			vm.store.set('TotalFilteredAttrs', treeroot);

			return vm.subcategory ? filtered : treeroot;
		};

		vm.getPageSize = function () {
			return vm.filters.pagesize;
		};

		vm.getFiltering = function (root) {
			const obj = vm.store.getFiltering();

			return root || vm.subcategory ? obj : {Attributes: obj};
		};

		vm.refreshAttributes = () => {
			vm.refreshDisabled = true;
			vm.store.refreshAttributes().then(() => {
				vm.refreshDisabled = false;
			});
		};

		vm.go = function (subcategory) {
			vm.subcategory = subcategory;
			vm.filters.page = 1;

			$state.go('.', {
				subcategory: subcategory,
			});
			vm.setAllChecked();
		};
	},
});
