import React from 'react';
import {DNBTypography} from 'common/dnb-uux-vendor';
import {StyledDNBAccordion} from './FilterAccordion.styled';
import {FilterAccordionProps} from './FilterAccordion.types';

interface FilterAccordionCustomItem extends React.PropsWithChildren {
	id: string;
	name: string;
}

type FilterCustomAccordionProps = FilterAccordionProps &
	FilterAccordionCustomItem;

const FilterCustomAccordion = ({
	id,
	name,
	expanded,
	onExpandedChange,
	children,
}: FilterCustomAccordionProps): React.ReactElement => {
	const accordionId = `${id}-${name}-filter-accordion`;

	return (
		<StyledDNBAccordion
			id={accordionId}
			data-test-id={accordionId}
			primaryTitle={
				<DNBTypography variant='compact-body'>{name}</DNBTypography>
			}
			expanded={expanded}
			onChange={(_, newState) => onExpandedChange(newState)}>
			{children!}
		</StyledDNBAccordion>
	);
};

export {FilterCustomAccordion};
export type {FilterAccordionCustomItem};
