import {react2angular} from 'common/react-vendor';
import {AttrControls} from './AttrControls';

angular
	.module('common.attributes.controls', [])
	.component(
		'reactAttrControls',
		react2angular(AttrControls, [
			'total',
			'label',
			'page',
			'save',
			'overview',
			'section',
		])
	);
