import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import thunk from 'redux-thunk';
import {v4 as uuid} from 'uuid';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {axios} from '../../network.vendor';
import {axiosInstance} from '../utilities/axiosUtility/axiosInstance';
import {cacheQueryResponse} from '../utilities/cacheUtility/cacheUtility';
import AppStorageUtility from '../../widgets/utilities/app-storage.utility';
import LocalStorageUtility from '../../widgets/utilities/local-storage.utility';
import {setTenantConfigStore, tenantConfigReducer} from '../../stores/tenantConfig';

const createReducer = (asyncReducers) => {
	return combineReducers({
		default: () => {
			return {};
		}, // default reducer gets rid of error on app instantiation
		...asyncReducers,
	});
};

const middleware = [thunk];

// BEGIN Configuring Redux Dev Tools Browser Extension
const replacementStr = 'sanitized';

const actionSanitizer = (action) => {
	if (action.type === 'GET_CUBE') {
		return {...action, payload: replacementStr};
	}

	if (action.type === 'GET_ENRICHMENTS') {
		return {...action, payload: replacementStr};
	}

	if (action.type === 'GET_SEGMENTS') {
		return {...action, payload: replacementStr};
	}

	return action;
};

const stateSanitizer = (unsanitizedState) => {
	// sanitizers must not modify unsanitizedState, which will still be used by redux outside of the devTools context
	const sanitizationMsg =
		'Sanitized to prevent Redux Dev Tools out-of-memory. See redux-store.js to add new fields.';

	const sanitizeSection = (state, fields, sectionName = null) => {
		const sanitizeObjectFields = (obj, fields) => {
			const sanitizedFields = Object.keys(obj)
				.filter((key) => fields.some((field) => key.includes(field)))
				.reduce(
					(sanitized, key) => ({
						...sanitized,
						[key]: sanitizationMsg,
					}),
					{}
				);

			return {
				...obj,
				...sanitizedFields,
			};
		};

		if (!sectionName) {
			return sanitizeObjectFields(state, fields);
		}

		if (!state[sectionName]) {
			return state;
		}

		return {
			...state,
			[sectionName]: {
				...state[sectionName],
				...sanitizeObjectFields(state[sectionName], fields),
			},
		};
	};

	return [
		(state) => sanitizeSection(state, ['LeItemView']),
		(state) =>
			sanitizeSection(
				state,
				['context', 'cube', 'enrichments', 'segments'],
				'segmentation'
			),
		(state) => sanitizeSection(state, ['context', 'models'], 'models'),
		(state) =>
			sanitizeSection(
				state,
				['connections', 'plays', 'ratings'],
				'playbook'
			),
	].reduce(
		(sanitizedState, sanitization) => sanitization(sanitizedState),
		unsanitizedState
	);
};

const reduxDevToolsExtensionConfiguration = {
	trace: true,
	traceLimit: 25,
	actionSanitizer,
	stateSanitizer,
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
			reduxDevToolsExtensionConfiguration
	  )
	: compose;
// END Configuring Redux Dev Tools Browser Extension

export function configureStore(initialState = {}) {
	const store = createStore(
		createReducer(),
		initialState,
		composeEnhancers(applyMiddleware(...middleware))
	);

	store.asyncReducers = {};

	return store;
}

if (!window.store) {
	window.store = configureStore();
}

export const store = window.store;

if (
	isNil(store?.getState().tenantConfig) &&
	!isNil(axios) &&
	!isNil(store) &&
	!isNil(LocalStorageUtility.getTokenDocument())
) {
	injectAsyncReducer(store, 'tenantConfig', tenantConfigReducer);

	(async () => {
		await setTenantConfigStore()
	})();
}

export function injectAsyncReducer(store_, name, asyncReducer) {
	store_.asyncReducers[name] = asyncReducer;
	store_.replaceReducer(createReducer(store_.asyncReducers));
}

export function mount(path) {
	return (state) => {
		if (path) {
			return {store: {...state[path]}};
		} else {
			return {store: {...state}};
		}
	};
}

// helper utility for tying redux to component state
export const redux = (options = {}, namespace = uuid()) => {
	let {path, component, actions, reducer, appCache} = options;

	const initialState = Object.assign({}, options.state);

	path = (path || '')[0] == '@' ? namespace + path : path;

	const get = () => {
		return store.getState()[path];
	};

	const set = (payload) => {
		return store.dispatch({
			type: '_SET',
			namespace: path,
			payload,
		});
	};

	const clear = () => {
		return store.dispatch({
			type: '_CLEAR',
			namespace: path,
		});
	};

	const currentstate = get() || null;

	reducer = reducer
		? reducer
		: (current = {}, action) => {
				if (action.namespace !== path) {
					return current;
				}

				switch (action.type) {
					case '_SET':
						if (appCache) {
							const savedState = Object.assign(current, action.payload);
							const appCacheObject = {};

							appCache.forEach((property) => {
								if (savedState.hasOwnProperty(property)) {
									appCacheObject[property] = savedState[property];
								}
							});

							AppStorageUtility.set(action.namespace, appCacheObject);
						}
						return {
							...current,
							...action.payload,
						};
					case '_CLEAR':
						return {};
					default:
						return current;
				}
		  };

	if (path && (!options.noclobber || (options.noclobber && !currentstate))) {
		injectAsyncReducer(store, path, reducer);
	}

	if (initialState) {
		if (Object.keys(currentstate || {}).length == 0 || !appCache) {
			clear();

			if (appCache) {
				const cache = AppStorageUtility.get(path);

				set(Object.assign(initialState, cache));
			} else {
				set(initialState);
			}
		}
	}

	if (component) {
		const currentstate = get();

		component.state = Object.assign({}, currentstate);

		component.handleChange = () => {
			component.setState(currentstate);
		};

		component.unsubscribe = store.subscribe(component.handleChange);

		component.componentWillUnmount = () => {
			component.unsubscribe();
		};
	}

	return {namespace, path, get, set, clear, actions, reducer, initialState};
};
