import {getRatingsEngineProperty} from 'atlas/stores/ratingsEngine';
import {getRating} from 'atlas/ratingsengine/ratingsengine.helpers';

angular
	.module('common.wizard.header', [])
	.controller(
		'WizardHeader',
		function (
			$rootScope,
			$state,
			$stateParams,
			WizardProgressContext,
			WizardHeaderTitle,
			WizardCustomHeaderSteps
		) {
			var vm = this;

			angular.extend(vm, {
				title: WizardHeaderTitle || '',
				showEntityCounts: false,
			});

			vm.init = function () {
				if (vm.showCustomHeader()) {
					vm.setEntityCounts();
				}
			};

			vm.showCustomHeader = function () {
				var check = false;
				var wizardState = 'home.' + WizardProgressContext;

				WizardCustomHeaderSteps.forEach(function (step) {
					if ($state.current.name == wizardState + step) {
						check = true;
					}
				});
				return check;
			};

			vm.setEntityCounts = function () {
				var currentSegment = getRatingsEngineProperty('savedSegment');
				if (
					currentSegment.accounts != undefined &&
					currentSegment.contacts != undefined
				) {
					vm.accounts = currentSegment.accounts.toLocaleString('en');
					vm.contacts = currentSegment.contacts.toLocaleString('en');
				} else if ($stateParams.rating_id) {
					getRating($stateParams.rating_id)
						.then(function (rating) {
							vm.accounts = rating.segment.accounts.toLocaleString('en');
							vm.contacts = rating.segment.contacts.toLocaleString('en');
						})
						.finally(() => {
							$rootScope.$apply();
						});
				}
			};

			vm.init();
		}
	);
