import React from 'common/react-vendor';
import styles from './PersonalizationComponent.module.scss';
import {usePersonalization} from '../hook/usePersonalization';
import PersonalizationProcessing from './PersonalizationProcessing';
import PersonalizationSteps from './PersonalizationSteps';
import {PersonalizationPageType} from '../configs/PersonalizationTypes';

export default function PersonalizationContainer(): React.ReactElement {
	const {context: personalizationContext} = usePersonalization();
	const {pageType} = personalizationContext;

	return (
		<div className={styles.personalizationComponent}>
			{pageType === PersonalizationPageType.Processing ? (
				<PersonalizationProcessing />
			) : (
				<PersonalizationSteps />
			)}
		</div>
	);
}
