import {store} from 'store';
import messageService from 'common/app/utilities/message-service';
import Message, {
	CLOSE_MODAL,
	ERROR,
	INFO,
	SUCCESS,
	WARNING,
} from 'common/app/utilities/message';
import {actions as bannerActions} from 'common/widgets/banner/le-banner.redux';
import {actions as modalActions} from 'common/widgets/modal/le-modal.redux';
import {ObjectType} from '../Data/ConnectionType';

enum LoggerType {
	Log = 'Log',
	Info = 'Info',
	Warn = 'Warn',
	Error = 'Error',
	Close = 'Close',
}

interface ILoggerOption {
	title?: React.ReactNode;
	message: string;
	loggerType: LoggerType;
	extra?: ObjectType;
	position?: string;
}

interface ILogger {
	log: (option: ILoggerOption) => void;
}

const DefaultLoggerConfig = {
	[LoggerType.Log]: console.log,
	[LoggerType.Info]: console.info,
	[LoggerType.Warn]: console.warn,
	[LoggerType.Error]: console.error,
	[LoggerType.Close]: console.clear,
};

const DefaultLogger: ILogger = {
	log: ({
		title,
		message,
		loggerType = LoggerType.Info,
	}: ILoggerOption): void => {
		DefaultLoggerConfig[loggerType](`title: ${title}, message: ${message}`);
	},
};

const BannerLoggerConfig = {
	[LoggerType.Log]: bannerActions.success,
	[LoggerType.Info]: bannerActions.info,
	[LoggerType.Warn]: bannerActions.warning,
	[LoggerType.Error]: bannerActions.error,
	[LoggerType.Close]: bannerActions.clearBanners,
};

const BannerLogger: ILogger = {
	log: ({
		title,
		message,
		loggerType = LoggerType.Info,
		position = 'top',
	}: ILoggerOption): void => {
		BannerLoggerConfig[loggerType](store, {
			title,
			message,
			position,
		});
	},
};

const ModalLoggerConfig = {
	[LoggerType.Log]: modalActions.success,
	[LoggerType.Info]: modalActions.info,
	[LoggerType.Warn]: modalActions.warning,
	[LoggerType.Error]: modalActions.error,
	[LoggerType.Close]: modalActions.closeModal,
};

interface IModalExtra {
	confirmLabel?: string;
	cancelLabel?: string;
	onConfirmAction?: () => void;
	onCancelAction?: () => void;
}

const ModalLogger: ILogger = {
	log: ({
		title,
		message,
		loggerType = LoggerType.Info,
		extra,
	}: ILoggerOption): void => {
		const {confirmLabel, onConfirmAction, onCancelAction}: IModalExtra =
			extra || {};
		ModalLoggerConfig[loggerType](store, {
			title: () => title,
			template: () => message,
			confirmLabel,
			callback: (action: string) => {
				if (action === 'ok') {
					onConfirmAction?.();
				} else if (action === 'close') {
					onCancelAction?.();
				}
				modalActions.closeModal(store);
			},
		});
	},
};

const GenericLoggerConfig = {
	[LoggerType.Log]: SUCCESS,
	[LoggerType.Info]: INFO,
	[LoggerType.Warn]: WARNING,
	[LoggerType.Error]: ERROR,
	[LoggerType.Close]: CLOSE_MODAL,
};

enum LegacyLoggerType {
	Banner = 'banner',
	Notice = 'notification',
}

const LegacyGenericLogger: (legacyLoggerType: LegacyLoggerType) => ILogger = (
	legacyLoggerType: LegacyLoggerType
) => {
	return {
		log: ({
			title,
			message,
			loggerType = LoggerType.Info,
		}: ILoggerOption): void => {
			messageService.sendMessage(
				new Message(
					null,
					legacyLoggerType,
					GenericLoggerConfig[loggerType],
					title,
					message
				)
			);
		},
	};
};

const LegacyBannerLogger: ILogger = LegacyGenericLogger(
	LegacyLoggerType.Banner
);

const LegacyNoticeLogger: ILogger = LegacyGenericLogger(
	LegacyLoggerType.Notice
);

export {
	LoggerType,
	GenericLoggerConfig,
	DefaultLogger,
	BannerLogger,
	ModalLogger,
	LegacyBannerLogger,
	LegacyNoticeLogger,
};
export type {ILogger, ILoggerOption};
