import template from './categories.component.html';
import {getMetadataForCategory} from '../../datacloud.service.vanilla';
import StateHistory from '../../../../../atlas/app/StateHistory';

const pruneEmptyCategories = (categoryMap) =>
	Object.fromEntries(
		Object.entries(categoryMap).filter(([_, count]) => count !== 0)
	);

angular
	.module('common.attributes.categories', [])
	.filter('getMeta', function GetMetaConstructor() {
		return (ids, metadata) =>
			ids.map((id) => getMetadataForCategory(metadata, id));
	})
	.component('attrCategories', {
		template,
		bindings: {
			categories: '<',
			metadata: '<',
			store: '<',
			callback: '&',
			displayType: '@',
		},
		controller($state, $stateParams) {
			const vm = this;

			vm.current = 1;
			vm.pagesize = 6;
			vm.catlength = 1;

			vm.$onInit = function () {
				vm.category = vm.store.get('category');
				vm.catmap = pruneEmptyCategories(vm.categories);
				vm.categories = Object.keys(vm.catmap);
				vm.catlength = vm.categories.length;

				const sortedCategories = vm.categories
					.map((id) => [id, getMetadataForCategory(vm.metadata, id)])
					.sort(
						([, a], [, b]) =>
							(a?.uIOrderMyData ?? Infinity) - (b?.uIOrderMyData ?? Infinity)
					)
					.map(([id]) => id);

				const categoryIndex = sortedCategories.indexOf(vm.category) + 1;
				vm.current = Math.ceil(categoryIndex / vm.pagesize);
			};

			vm.selectCategory = function (category) {
				const {legacyDisplayName, displayName} = category;
				if (!$stateParams.section) {
					vm.callback({category: legacyDisplayName});
				} else {
					ShowSpinner(
						`Loading ${displayName} Data`,
						'div.attr-results-container'
					);

					$state.go('.', {
						section: $stateParams.section,
						category: legacyDisplayName,
						subcategory: $stateParams.subcategory,
					});
				}
			};

			vm.isActive = function (category) {
				if (!$stateParams.section) {
					return vm.store.get('category') === category;
				}

				// FIXME: This is here because whoever originally wrote this code created
				// like 3 different sources of truth for which category is selected.
				// Different sources of truth should be consolodated, and the end result
				// should just be `return category === theActiveCategory`
				const lastToParams = StateHistory.lastToParams();
				const lastFromParams = StateHistory.lastFromParams();

				const x = vm.category === category;
				const y = lastToParams.category === category;
				const z =
					lastFromParams.category !== category ||
					lastToParams.category === category;

				return (x || y) && z;
			};
		},
	});
