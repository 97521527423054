// #region AbbreviateLargeNumber
const getDecimalPlaces = (decimal_places: number | null): number => {
	const decimalPlaces = decimal_places || 2;

	return Math.pow(10, decimalPlaces);
};

/**
 * Should we migrate to Intl.NumberFormat() constructor
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
const AbbreviateLargeNumber = (
	number: number | null,
	decimal_places: number | null,
	abbreviations?: string[]
): string | number | null => {
	if (number === null) {
		return null;
	}

	const decimalPlaces = getDecimalPlaces(decimal_places);

	const abbreviation = abbreviations || ['K', 'M', 'B', 'T'];
	const abbreviationSize = abbreviation.length - 1;

	let abbreviatedNumber: string | number = number;

	for (let i = abbreviationSize; i >= 0; i--) {
		const size = Math.pow(10, (i + 1) * 3);

		if (size <= number) {
			abbreviatedNumber =
				Math.round((number * decimalPlaces) / size) / decimalPlaces;

			if (abbreviatedNumber === 1000 && i < abbreviationSize) {
				abbreviatedNumber = 1;
			}

			abbreviatedNumber = `${abbreviatedNumber}${abbreviation[i]}`;

			break;
		}
	}

	return abbreviatedNumber;
};
// #endregion AbbreviateLargeNumber

// #region MakePercentage
const getPercentage = (
	number: number,
	total: number,
	limit?: number
): string | number => {
	const percentage = (number / total) * 100;

	if (typeof limit !== 'undefined') {
		return percentage.toFixed(limit);
	}

	return percentage;
};

const MakePercentage = (
	number?: number,
	total?: number,
	suffix = '',
	limit?: number
): number | string => {
	if (number && total) {
		const percentage = getPercentage(number, total, limit);

		return `${percentage}${suffix}`;
	}

	return 0;
};
// #endregion MakePercentage

// #region PadNumber
const PadNumber = (number: number, width: number, character = '0'): string => {
	const stringNumber = number.toString();
	const numberSize = stringNumber.length;

	if (numberSize >= width) {
		return stringNumber;
	}

	const arrayLength = width - numberSize + 1;
	const prefix = new Array(arrayLength).join(character);

	return `${prefix}${stringNumber}`;
};
// #endregion PadNumber

export {AbbreviateLargeNumber, MakePercentage, PadNumber};
