import React from 'common/react-vendor';
import {
	designTokens,
	DNBRadio,
	DNBSelect,
	DNBTypography,
} from 'common/dnb-uux-vendor';
import {CommonNumberInput} from './CommonNumberInput';
import styles from './ScheduleMonthlyComponent.module.scss';
import {WeeklyType} from '../../../Data/CronSettingType';

enum MonthlyDetailType {
	PerDay,
	PerWeek,
}

enum WhichWeekType {
	First = 'First',
	Second = 'Second',
	Third = 'Third',
	Fourth = 'Fourth',
	Fifth = 'Fifth',
}

interface IScheduleMonthlyComponent {
	monthlyDetailType: MonthlyDetailType;
	detailMonthDay: number;
	detailWhichWeek: WhichWeekType;
	detailWhichDay: WeeklyType;
	onMonthlyDetailTypeChanged: (
		monthlyType: MonthlyDetailType,
		detailMonth: number,
		whichWeek: WhichWeekType,
		whichDay: WeeklyType
	) => void;
}

const ScheduleMonthlyComponent = ({
	monthlyDetailType,
	detailMonthDay,
	detailWhichWeek,
	detailWhichDay,
	onMonthlyDetailTypeChanged: onMonlyDetailTypeChanged,
}: IScheduleMonthlyComponent): React.ReactElement => {
	const [monthlyDetail, setMonthlyDetail] = React.useState(monthlyDetailType);
	const [monthDay, setMonthDay] = React.useState(detailMonthDay);
	const [whichWeek, setWhichWeek] = React.useState(detailWhichWeek);
	const [whichDay, setWhichDay] = React.useState(detailWhichDay);

	return (
		<div className={styles.ScheduleMonthlyComponent}>
			<div className={styles.OptionContainer}>
				<DNBRadio
					value=''
					size='small'
					checked={monthlyDetail === MonthlyDetailType.PerDay}
					onChange={(e) => {
						if (
							monthlyDetail === MonthlyDetailType.PerDay &&
							e.target.checked
						) {
							return;
						}
						setMonthlyDetail(MonthlyDetailType.PerDay);
						onMonlyDetailTypeChanged(
							MonthlyDetailType.PerDay,
							monthDay,
							whichWeek,
							whichDay
						);
					}}
				/>
				<DNBTypography
					variant='compact-body'
					component='span'
					sx={{ml: 3, mr: 4}}
					color={designTokens.TokenColors.ColorGrayPrimary}>
					Day
				</DNBTypography>
				<CommonNumberInput
					min={1}
					max={31}
					num={monthDay}
					disabled={monthlyDetail !== MonthlyDetailType.PerDay}
					onChange={(val) => {
						const newMonthDay = val;
						setMonthDay(newMonthDay);
						onMonlyDetailTypeChanged(
							MonthlyDetailType.PerDay,
							newMonthDay,
							whichWeek,
							whichDay
						);
					}}
				/>
				<DNBTypography
					variant='compact-body'
					component='span'
					sx={{ml: 4}}
					color={designTokens.TokenColors.ColorGrayPrimary}>
					of every month
				</DNBTypography>
			</div>
			<div className={styles.OptionContainer}>
				<DNBRadio
					value=''
					size='small'
					checked={monthlyDetail === MonthlyDetailType.PerWeek}
					onChange={(e) => {
						if (
							monthlyDetail === MonthlyDetailType.PerWeek &&
							e.target.checked
						) {
							return;
						}
						setMonthlyDetail(MonthlyDetailType.PerWeek);
						onMonlyDetailTypeChanged(
							MonthlyDetailType.PerWeek,
							monthDay,
							whichWeek,
							whichDay
						);
					}}
				/>
				<DNBTypography
					variant='compact-body'
					component='span'
					sx={{ml: 3, mr: 4}}
					color={designTokens.TokenColors.ColorGrayPrimary}>
					The
				</DNBTypography>
				<span className={styles.mr12}>
					<DNBSelect<WhichWeekType>
						size='compact'
						minWidth='250px'
						value={whichWeek}
						selectOptions={Object.values(WhichWeekType).map((val) => {
							return {
								label: val,
								value: val,
							};
						})}
						onChange={(_, val) => {
							const newWhichWeek = val!;
							setWhichWeek(newWhichWeek);
							onMonlyDetailTypeChanged(
								MonthlyDetailType.PerWeek,
								monthDay,
								newWhichWeek,
								whichDay
							);
						}}
					/>
				</span>
				<span className={styles.mr12}>
					<DNBSelect<WeeklyType>
						size='compact'
						minWidth='250px'
						value={whichDay}
						selectOptions={Object.values(WeeklyType).map((val) => {
							return {
								label: val,
								value: val,
							};
						})}
						onChange={(_, val) => {
							console.log(whichDay, 'WeeklyType selected', val);
							const newWhichDay = val!;
							setWhichDay(newWhichDay);
							onMonlyDetailTypeChanged(
								MonthlyDetailType.PerWeek,
								monthDay,
								whichWeek,
								newWhichDay
							);
						}}
					/>
				</span>
				<DNBTypography
					variant='compact-body'
					component='span'
					color={designTokens.TokenColors.ColorGrayPrimary}>
					of every month
				</DNBTypography>
			</div>
		</div>
	);
};

export {ScheduleMonthlyComponent, WeeklyType, WhichWeekType, MonthlyDetailType};
export type {IScheduleMonthlyComponent};
