import {isEmpty, isNil} from 'lodash';
import {AbbreviateLargeNumber} from 'common/app/utilities/NumberUtility';
import {
	FormatEpochDate,
	FormatStringDate,
} from 'common/app/utilities/DateTimeFormatUtility';
import {getString} from 'common/app/utilities/ResourceUtility';
import type {
	AttributeMetadata,
	Bucket,
	Value,
} from './AnalyticAttributeUtility.types';
import {
	ApprovedUsage,
	DataType,
	FundamentalType,
} from './AnalyticAttributeUtility.types';
import {Element, Predictor} from '../topPredictor/topPredictor.types';

const MISC_BUCKET_NAME = 'Other, Less Popular';

// #region FormatBooleanBucketName
const NotAvailableValues = ['NA', 'N/A', 'NULL', 'NOT AVAILABLE'];
const NoValues = ['N', 'NO', 'FALSE', 'F'];
const YesValues = ['Y', 'YES', 'TRUE', 'T'];

const FormatBooleanBucketName = (value: string): string => {
	const intValue = parseInt(value, 10);

	if (NotAvailableValues.includes(value) || intValue === -1) {
		return 'Not Available';
	}

	if (NoValues.includes(value) || intValue === 0) {
		return 'No';
	}

	if (YesValues.includes(value) || intValue === 1) {
		return 'Yes';
	}

	return value;
};
// #endregion FormatBooleanBucketName

// #region AbbreviateNumber
const AbbreviateNumber = (
	realValue: string | number,
	fundamentalType: FundamentalType
): Value => {
	const parsedValue =
		typeof realValue === 'string' ? parseFloat(realValue) : realValue;

	// If the parsedValue is NaN then we have a mismatch of types so just return the value
	if (isNaN(parsedValue)) {
		return realValue;
	}

	// If the value's fundamental type is 'year', do not round it.
	if (fundamentalType === FundamentalType.Year) {
		return parsedValue;
	}

	/**
	 * If the value is less than 1 it should get 2 decimal places
	 * If the value is less than 1,000 it should get 1 decimal place, but only if it had a decimal place to begin with
	 * Anything greater than 1,000 will be handled by NumberUtil.AbbreviateLargeNumber
	 */

	if (parsedValue === 0) {
		return parsedValue;
	}

	if (parsedValue < 1) {
		return parsedValue.toFixed(2);
	}

	if (parsedValue < 1000 && parsedValue % 1 !== 0) {
		return parsedValue.toFixed(1);
	}

	return AbbreviateLargeNumber(parsedValue, 1);
};
// #endregion AbbreviateNumber

// #region FormatBooleanValueForDisplay
const FormatBooleanValueForDisplay = (
	booleanValue?: string | number | boolean | null
): string => {
	if (isEmpty(booleanValue)) {
		return '';
	}

	const value = booleanValue!.toString();
	const upperCaseValue = value.toUpperCase();

	if (upperCaseValue === '1' || upperCaseValue === 'TRUE') {
		return getString('BOOLEAN_TRUE_DISPLAY_LABEL');
	}

	if (booleanValue === '0' || booleanValue === 'FALSE') {
		return getString('BOOLEAN_FALSE_DISPLAY_LABEL');
	}

	return '';
};
// #endregion FormatBooleanValueForDisplay

// #region FormatBucketValue
const FormatBucketValue = (
	value: Value,
	attributeMetadata: AttributeMetadata | Predictor
): Value => {
	if (value === null || attributeMetadata === null) {
		return value;
	}

	const fundamentalType =
		(attributeMetadata.FundamentalType?.toUpperCase() as FundamentalType) ||
		null;

	// If the coming data has fundamental type specified, manipulate the value as required according to fundamental type.
	const numericFundamentalTypes = [
		FundamentalType.Year,
		FundamentalType.Currency,
		FundamentalType.Numeric,
		FundamentalType.Percent,
	];

	if (numericFundamentalTypes.includes(fundamentalType || '')) {
		const abbreviatedNumber = AbbreviateNumber(value, fundamentalType);

		// Handle currency and percent
		if (fundamentalType === FundamentalType.Currency) {
			return getString('CURRENCY_SYMBOL') + abbreviatedNumber;
		}

		if (fundamentalType === FundamentalType.Percent) {
			return `${abbreviatedNumber}%`;
		}

		return abbreviatedNumber;
	}

	const dataType = attributeMetadata.DataType?.toUpperCase() || null;

	switch (dataType) {
		// Format Numbers
		case DataType.Double:
		case DataType.Int:
		case DataType.Integer:
		case DataType.Short:
		case DataType.Long:
		case DataType.Float:
			return AbbreviateNumber(value, fundamentalType);
		// Format Date
		case DataType.Date:
			return FormatStringDate(value, false);
		// Format DateTime and Time
		case DataType.DateTime:
		case DataType.Time:
			return FormatStringDate(value, true);
		// Format Boolean and Bit
		case DataType.Boolean:
		case DataType.Bit:
			return FormatBooleanValueForDisplay(value);
		// Format EpochTime
		case DataType.Epoch:
			return FormatEpochDate(value.toString());
		default:
			// No formatting required for String or LongString
			return value;
	}
};
// #endregion FormatBucketValue

// #region FormatIntegerBucket
const FormatIntegerBucket = (
	upper: number | null,
	attributeMetadata: AttributeMetadata | Predictor
): string => {
	const upperValue = FormatBucketValue(upper, attributeMetadata);

	return getString('ANALYTIC_ATTRIBUTE_LESS_THAN_LABEL', [upperValue]);
};
// #endregion FormatIntegerBucket

// #region GetAttributeBucketName
const getBucketValuesName = (
	bucket: Bucket | Element,
	attributeMetadata: AttributeMetadata | Predictor
): string => {
	const [firstBucket] = bucket.Values;

	// This is the Null bucket
	if (firstBucket === null) {
		return getString('ANALYTIC_ATTRIBUTE_NULL_VALUE_LABEL');
	}

	if (firstBucket === 'Other') {
		return 'Other, Less Popular';
	}

	if (attributeMetadata !== null) {
		const fundamentalType =
			attributeMetadata.FundamentalType?.toUpperCase() || null;

		if (fundamentalType === FundamentalType.Boolean) {
			const value = firstBucket.toString().toUpperCase();

			return FormatBooleanBucketName(value);
		}
	}

	const discreteValueString =
		bucket.Values.reduce((valueString, value, currentIndex) => {
			const bucketValue = FormatBucketValue(value, attributeMetadata);
			const separator = bucket.Values.length - 1 === currentIndex ? '' : ', ';

			if (typeof bucketValue === 'string') {
				return `${valueString}${bucketValue}${separator}`;
			}

			return valueString;
		}, '') || '';

	if (discreteValueString.toUpperCase() === 'MISC.') {
		return MISC_BUCKET_NAME;
	}

	return discreteValueString;
};

const getBucketUpperValue = (
	bucket: Bucket,
	attributeMetadata: AttributeMetadata | Predictor
): string => {
	const upperValue = FormatBucketValue(
		bucket.UpperExclusive,
		attributeMetadata
	);

	if (attributeMetadata !== null) {
		const dataType = attributeMetadata.DataType?.toUpperCase() || null;

		if (dataType === DataType.Int || dataType === DataType.Integer) {
			return FormatIntegerBucket(bucket.UpperExclusive, attributeMetadata);
		}

		return getString('ANALYTIC_ATTRIBUTE_LESS_THAN_LABEL', [upperValue]);
	}

	return getString('ANALYTIC_ATTRIBUTE_LESS_THAN_LABEL', [upperValue]);
};

const GetAttributeBucketName = (
	bucket: Bucket | Element | null,
	attributeMetadata: AttributeMetadata | Predictor
): string => {
	if (bucket === null) {
		return '';
	}

	const newBucket = {...bucket};

	if (
		newBucket.LowerInclusive !== null &&
		newBucket.LowerInclusive > 0 &&
		newBucket.UpperExclusive === 0
	) {
		newBucket.UpperExclusive = null;
	}

	if (!isNil(newBucket.Values) && newBucket.Values.length > 0) {
		return String(getBucketValuesName(newBucket, attributeMetadata));
	}

	if (newBucket.LowerInclusive !== null && newBucket.UpperExclusive !== null) {
		return String(getBucketUpperValue(newBucket, attributeMetadata));
	}

	if (bucket.LowerInclusive === null && bucket.UpperExclusive === null) {
		return String(getString('ANALYTIC_ATTRIBUTE_ALL_VALUES_LABEL'));
	}

	if (bucket.LowerInclusive !== null) {
		const lowerValue = FormatBucketValue(
			bucket.LowerInclusive,
			attributeMetadata
		);

		return String(
			getString('ANALYTIC_ATTRIBUTE_GREATER_THAN_LABEL', [lowerValue])
		);
	}

	const upperValue = FormatBucketValue(
		bucket.UpperExclusive,
		attributeMetadata
	);

	return String(getString('ANALYTIC_ATTRIBUTE_LESS_THAN_LABEL', [upperValue]));
};
// #endregion GetAttributeBucketName

// #region IsPredictorBoolean
const IsPredictorBoolean = (
	attributeMetadata: AttributeMetadata | Predictor
): boolean => {
	if (isNil(attributeMetadata) || isNil(attributeMetadata.FundamentalType)) {
		return false;
	}

	const fundamentalType = attributeMetadata.FundamentalType.toUpperCase();

	return fundamentalType === FundamentalType.Boolean;
};
// #endregion IsPredictorBoolean

// #region IsAllowedForInsights
const IsApprovedForUsage = (
	usage: string | null,
	attributeMetadata: AttributeMetadata | Predictor | null
): boolean => {
	if (
		usage === null ||
		attributeMetadata === null ||
		attributeMetadata.ApprovedUsage === null
	) {
		return false;
	}

	return attributeMetadata.ApprovedUsage.some(
		(approvedUsage) => approvedUsage === usage
	);
};

// TODO: pierce Another hack because DataLoader is not properly populating the metadata
const IsAllowedForInsights = (
	attributeMetadata: AttributeMetadata | Predictor | null
): boolean => {
	if (attributeMetadata === null) {
		return false;
	}

	if (isEmpty(attributeMetadata.ApprovedUsage)) {
		return true;
	}

	return (
		IsApprovedForUsage(ApprovedUsage.ModelAndAllInsights, attributeMetadata) ||
		IsApprovedForUsage(ApprovedUsage.ModelAndModelInsights, attributeMetadata)
	);
};
// #endregion IsAllowedForInsights

export {
	GetAttributeBucketName,
	MISC_BUCKET_NAME,
	IsPredictorBoolean,
	IsAllowedForInsights,
};
