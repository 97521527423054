import React, {react2angular} from 'common/react-vendor';
import {Provider} from 'common/redux-vendor';
import {store} from 'store';
import {ISegmentEditHeaderX, SegmentEditHeaderX} from './SegmentEditHeaderX';

const SegmentEditHeaderXWithRedux = ({
	...props
}: ISegmentEditHeaderX): JSX.Element => {
	return (
		<Provider store={store}>
			<SegmentEditHeaderX {...props} />
		</Provider>
	);
};

angular
	.module('common.datacloud.tabs.segmenteditheaderx', [])
	.component(
		'segmentEditHeaderX',
		react2angular(SegmentEditHeaderXWithRedux, [], ['$state', '$stateParams'])
	);
