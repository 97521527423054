import {IActionColumnItem} from '../Component/Table/Component/ActionColumnItem';
import {INameColumnItem} from '../Component/Table/Component/NameColumnItem';
import {ITextColumnItem} from '../Component/Table/Component/TextColumnItem';
import {IToggle} from '../Component/Toggle';

enum ImportStatus {
	None = 'None',
	Importing = 'Importing',
	Partial = 'Partial',
	Failed = 'Failed',
	Success = 'Success',
}

type IImportColumnType =
	| IToggle
	| INameColumnItem
	| ITextColumnItem
	| ITextColumnItem[]
	| IActionColumnItem;

export {ImportStatus};
export type {IImportColumnType};
