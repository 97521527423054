import React from 'common/react-vendor';
import {axios, AxiosResponse} from 'common/network.vendor';
import jwtDecode from 'jwt-decode';

interface SuccessResponse {
	Success: true;
	UserName: string;
	FirstName: string;
	LastName: string;
	Uniqueness: string;
	Randomness: string;
}

interface ErrorResponse {
	Success: false;
	Errors: string[];
}

type Response = SuccessResponse | ErrorResponse;

export const Passwordless = ({
	redirectToLoginForm,
	handleLoginSuccess,
}: {
	redirectToLoginForm: () => void;
	handleLoginSuccess: (response: AxiosResponse) => void;
}): React.ReactElement => {
	/** Handle passwordless login flow */
	React.useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const passwordlessToken = queryParams.get('token'); // optional, if missing or invalid we will redirect to regular login
		const encodedRedirectPath = queryParams.get('redirect'); // optional, we can still login a user and redirect to /atlas

		// sessionStorage->referringUrl will be used by regular login flow if passwordless fails
		if (encodedRedirectPath) {
			const redirectUrl = new URL(encodedRedirectPath, window.location.origin);
			if (redirectUrl.origin === window.location.origin)
				sessionStorage.setItem('referringUrl', redirectUrl.toString());
		}

		// Missing, invalid, or expired tokens will redirect to manual login
		try {
			const {exp} = jwtDecode<{exp: number}>(passwordlessToken || '');
			const isExpired = new Date(exp * 1000).getTime() < Date.now();
			if (isExpired) return void redirectToLoginForm();
		} catch {
			return void redirectToLoginForm();
		}

		axios
			.get('/pls/login/jwt', {
				params: {token: passwordlessToken},
			})
			.then((response: AxiosResponse<Response>) => {
				// TODO: BE needs to not send 200 response on failures
				if (!response.data.Success)
					throw new Error('Redirect in catch block below');
				handleLoginSuccess(response);
				return null;
			})
			.catch((error) => {
				console.error('Passwordless login failed', error, error.stack);
				redirectToLoginForm();
			});
	}, [redirectToLoginForm, handleLoginSuccess]);

	/** We don't render anything yet but may add loading gif in the future */
	return <></>;
};
