import React from 'common/react-vendor';
import {DNBTypography} from 'common/dnb-uux-vendor';

interface IProps {
	count: number;
}

function AttributesCount({count}: IProps): React.ReactElement {
	return (
		<span>
			<DNBTypography
				variant='h5'
				component='span'
				sx={{color: (theme) => theme.colors.ColorPrimaryBlue}}>
				{count}
			</DNBTypography>
			<DNBTypography
				variant='compact-body'
				color={(theme) => theme.colors.ColorGrayPrimary}>
				/ 500 Attributes
			</DNBTypography>
		</span>
	);
}

export default AttributesCount;
