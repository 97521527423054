import type {StateService} from '@uirouter/angularjs';
import React from 'common/react-vendor';
import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {DNBTextField} from 'common/dnb-uux-vendor';
import {ISegment} from 'atlas/data/SegmentConst';
import {
	CommonBreadcrumbs,
	ICommonBreadcrumbs,
} from '../../../../../atlas/app/connectors/EIF/Component/CommonBreadcrumbs';

const MaximumIntValue = 50;

/**
 * ISegmentEditHeader
 * @param $state Angular state
 * @param vmSegment Segment data @ISegment
 * @param onChange On change callback.
 * @remarks Tightly coupled with angurlar functions, will be removed after
 * buyer journey FF GA with new style SegmentEditHeaderX.
 */
interface ISegmentEditHeader {
	$state: StateService;
	vmSegment: ISegment;
	onChange: (name: string) => void;
}

/**
 * SegmentEditHeader
 * @param prop @ISegmentEditHeader
 */
function SegmentEditHeader({
	$state,
	vmSegment,
	onChange,
}: ISegmentEditHeader): JSX.Element {
	const display_name = vmSegment?.display_name;
	const [name, setName] = React.useState(display_name);
	const canEdit = vmSegment
		? !vmSegment.viewOnly &&
		  RBAC.hasAccess(RBACInterface.SEGMENTS, RBACActions.EDIT)
		: true;
	const breadText = display_name ? 'Edit a Segment' : 'Create a Segment';
	const breadcrumbsList: ICommonBreadcrumbs[] = [
		{
			text: 'Segments',
			onClick: () => {
				$state.go('home.segments_new', {}, {reload: true});
			},
			isLink: true,
		},
		{
			text: breadText,
			isLink: false,
		},
	];

	const showInput = !!display_name;
	return (
		<>
			<div className='navgationBar'>
				<CommonBreadcrumbs
					breadcrumbsList={breadcrumbsList.filter(({hide}) => !hide)}
				/>
			</div>
			{showInput && (
				<div className='nameInput'>
					<DNBTextField
						size='default'
						disabled={!canEdit}
						inputProps={{maxLength: MaximumIntValue}}
						required
						helperText={`${Math.max(
							MaximumIntValue - (name || '')?.length,
							0
						)} characters remaining`}
						value={name}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							const {value} = e.target;
							onChange(value);
							setName(value);
						}}
					/>
				</div>
			)}
		</>
	);
}

export {SegmentEditHeader};
