import {store} from 'store';
import {
	getDataCloudProperty,
	setFeedbackModalPropertyValue,
} from 'common/stores/datacloud';
import {getCompanyProfilesBody} from 'common/stores/lookup';
import template from './attributefeedbackmodal.component.html';
import {postCustomerReports} from '../../datacloud.queries';

export default function () {
	return {
		restrict: 'EA',
		template,
		scope: {
			lookupResponse: '=?',
		},
		controller: function ($scope, $document, $timeout) {
			'ngInject';
			const vm = $scope,
				$modal = angular.element('attribute-feedback-modal');

			$scope.modal = getDataCloudProperty('feedbackModal');

			$scope.close = function () {
				store.dispatch(setFeedbackModalPropertyValue('show', false));
			};

			$scope.showLookupStore = $scope.modal.context.showLookupStore;

			if ($scope.showLookupStore) {
				$scope.lookupStore = getCompanyProfilesBody();
			}

			$scope.icon = $scope.modal.context.icon;
			$scope.label =
				$scope.modal.context.label ||
				$scope.modal.context.attribute.DisplayName;
			$scope.value = $scope.modal.context.value;
			$scope.categoryClass = $scope.modal.context.categoryClass;
			$scope.reported = false;

			$scope.report = function () {
				$scope.sendingReport = true;

				const form_fields = $scope.input || {};

				const report = {
					Attribute:
						$scope.modal.context.attributeKey ||
						$scope.modal.context.attribute.ColumnId ||
						'',
					MatchedValue: $scope.value,
					CorrectValue: form_fields.value || '',
					Comment: form_fields.comment || '',
					MatchedKeys: {
						LDC_Name: $scope.lookupResponse.attributes.LDC_Name,
						LDC_Domain: $scope.lookupResponse.attributes.LDC_Domain,
						LDC_City: $scope.lookupResponse.attributes.LDC_City,
						LDC_State: $scope.lookupResponse.attributes.LDC_State,
						LDC_ZipCode: $scope.lookupResponse.attributes.LDC_ZipCode,
						LDC_Country: $scope.lookupResponse.attributes.LDC_Country,
						LDC_DUNS: $scope.lookupResponse.attributes.LDC_DUNS,
					},
					MatchLog: $scope.lookupResponse.matchLogs,
				};

				$scope.sendingReport = false;
				$scope.reported = true;

				$timeout(function () {
					$scope.close();
				}, 1000 * 5);

				postCustomerReports(report, $scope.modal.context.type);
			};

			const setHeight = function () {
				const height = $(window).height() - 20;

				$modal.css({maxHeight: height});

				$modal.find('.attribute-feedback-container').css({maxHeight: height});
			};

			$scope.setHeight = function () {
				// gets called by ng-inits so that the data is there and heights make sense
				setHeight();
			};

			const _handleDocumentResize = _.debounce(handleDocumentResize, 300);

			$(window).on('resize', _handleDocumentResize);

			function handleDocumentResize(evt) {
				setHeight();
			}

			$document.on('click', handleDocumentClick);

			function handleDocumentClick(evt) {
				const target = angular.element(evt.target),
					clickedModal = target.parents('attribute-feedback-modal').length;

				if (!clickedModal) {
					$scope.close();
					$scope.$apply();
				}
			}

			$scope.$on('$destroy', function () {
				$document.off('click', handleDocumentClick);
				$(window).off('resize', _handleDocumentResize);
			});
		},
	};
}
