import {isEqual} from 'lodash';
import React, {Component, PropTypes} from 'common/react-vendor';
import {redux} from 'store';
import './le-range-slider.scss';
import NumericShortenedInput from './le-numeric-shortened-input';

export default class LeRangeSlider extends Component {
	constructor(props) {
		super(props);

		const state = {
			input_low: props.config.low || props.config.min,
			input_high: props.config.high || props.config.max,
		};

		this.redux = redux({
			path: props.namespace || '@filter.rangeslider',
			state: Object.assign(state, props.config),
			component: this,
			appCache: props.noAppCache ? null : ['low', 'high'], // save state & changes to local storage
		});
	}

	/**
	 * FIXME
	 * This is an initial fix for a deeper problem with redux and the item list component
	 * where the props and state are not in sync.
	 * A better fix should be done later when more time is allowed
	 */
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps?.config?.items?.length !== undefined &&
			!isEqual(nextProps?.config?.items, this.redux.get()?.items)
		) {
			this.redux.set({items: nextProps.config.items});
		}
	}

	onMouseDown = (event) => {
		const state = this.redux.get();
		event = Object.assign({}, event);

		if (this.dragging) {
			setTimeout(() => {
				this.onMouseUp(event);
			}, 1);
		} else {
			const track = this.refs.track.getBoundingClientRect(),
				x = this.calcX(event.clientX, track.left, track.width);
			this.lower = this.isLow(state, x);
			this.dragging = true;
			setTimeout(() => {
				this.onMouseMove(event, track, x);
			}, 1);
		}
	};

	onMouseUp = () => {
		const state = this.redux.get(),
			high = this.high || state.high || state.max,
			low =
				typeof this.low == 'number'
					? this.low
					: typeof state.low == 'number'
					? state.low
					: state.min,
			newstate = {
				lower: null,
				dragging: false,
				low,
				high,
				input_low: low,
				input_high: high,
			};

		if (this.dragging === true) {
			this.redux.set(newstate);
		}

		delete this.low;
		delete this.high;
		delete this.input_low;
		delete this.input_high;
		delete this.lower;
		delete this.dragging;

		this.setActiveHandle(null);
	};

	onMouseMove = (event, track, x) => {
		const state = this.redux.get();
		let lower = this.lower;
		track = track || this.refs.track.getBoundingClientRect();
		x = x || this.calcX(event.clientX, track.left, track.width);

		if (this.dragging) {
			this['move' + (lower ? 'Low' : 'High')](state, x);
		} else {
			lower = this.isLow(state, x);
		}

		this.setActiveHandle(lower);
	};

	calcX(cX, tX, tW) {
		const x = ((cX - tX) / tW) * 100;
		return x < 0 ? 0 : x > 100 ? 100 : x;
	}

	isLow(state, x) {
		const low = typeof state.low == 'number' ? state.low : state.min,
			high = state.high || state.max,
			left = (low - state.min) / (state.max - state.min),
			right = (high - state.min) / (state.max - state.min),
			center = (left + (right - left) / 2) * 100;
		return x < center;
	}

	moveLow(state, left) {
		const high = state.high || state.max;
		const width = ((high - state.min) / (state.max - state.min)) * 100;
		left = left > width ? width : left;
		const low = parseInt(state.min + (state.max - state.min) * (left * 0.01));
		this.low = low;
		this.input_low = low;
		this.left = left;
		this.width = width - left;
		this.setState({low, input_low: low});
	}

	moveHigh(state, right) {
		const low = typeof state.low == 'number' ? state.low : state.min;
		const left = ((low - state.min) / (state.max - state.min)) * 100;
		right = right < left ? left : right;
		const high = parseInt(state.min + (state.max - state.min) * (right * 0.01));
		this.high = high;
		this.input_high = high;
		this.left = left;
		this.width = right - left;
		this.setState({high, input_high: high});
	}

	setActiveHandle(lower) {
		if (this.refs.low && this.refs.high) {
			this.refs.low.className =
				'handle-low' + (lower === true ? ' active' : '');
			this.refs.high.className =
				'handle-high' + (lower === false ? ' active' : '');
		}
	}

	setHandles(left, width) {
		left = this.left;
		width = this.width;
		const state = this.redux.get();
		let needResize = false;
		if (!this.input_low && !state.input_low) {
			needResize = true;
		}
		if (!this.input_high && !state.input_high) {
			needResize = true;
		}
		if (!left || !width || needResize) {
			const low =
					typeof this.low == 'number'
						? this.low
						: typeof state.low == 'number'
						? state.low
						: state.min,
				high = this.high || state.high || state.max,
				right = ((high - state.min) / (state.max - state.min)) * 100;
			left = ((low - state.min) / (state.max - state.min)) * 100;
			width = right - left;
		}

		if (this.refs.handle) {
			this.refs.handle.style.left = left + '%';
			this.refs.handle.style.width = width + '%';
		}
	}

	inputChange(e, save) {
		let val = e.target.value;
		if (val === '') {
			val = 0;
		}
		if (!Number.isInteger(parseInt(val))) {
			return false;
		}

		const state = this.redux.get(),
			type = e.target.name.split('-').slice(-1)[0],
			value = parseInt(val),
			min = state.min,
			max = state.max,
			change = {};

		if (!save) {
			change[`input_${type}`] = value;
			this.redux.set(change);
		} else {
			const low = typeof state.low == 'number' ? state.low : state.min,
				high = state.high || state.max;

			if (type == 'high' && value >= low && value <= max) {
				this.redux.set({high: value});
				this.high = value;
				this.setHandles();
				delete this.high;
			} else if (type == 'low' && value <= high && value >= min) {
				this.redux.set({low: value});
				this.low = value;
				this.setHandles();
				delete this.low;
			}
		}
	}

	render() {
		const state = this.redux.get();

		this.setHandles();

		return (
			<div
				className='filter-range-slider noselect-children'
				onMouseMove={(event) => {
					this.onMouseMove(event);
				}}
				onMouseUp={(event) => {
					this.onMouseUp(event);
				}}
				onMouseLeave={(event) => {
					this.onMouseUp(event);
				}}>
				<span className='range-slider-label'>{state.label}</span>
				<ul className='range-slider-container'>
					<li className='range-slider-input value-low'>
						<NumericShortenedInput
							name='range-slider-low'
							value={
								typeof this.input_low == 'number'
									? this.input_low
									: typeof state.input_low == 'number' 
										? state.input_low
										: state.min
							}
							className={`${this.props.dial ? 'dial' : 'no-dial'}`}
							onChange={(event) => {
								this.inputChange(event);
							}}
							onBlur={(event) => {
								this.inputChange(event, true);
							}}
						/>
					</li>
					<li
						className='range-slider-slider'
						onMouseDown={(event) => {
							this.onMouseDown(event);
						}}>
						<div className='range-slider-track' ref='track'>
							<div className='range-slider-handle' ref='handle'>
								<div className='handle-low' ref='low' />
								<div className='handle-high' ref='high' />
							</div>
						</div>
					</li>
					<li className='range-slider-input value-high'>
						<NumericShortenedInput
							name='range-slider-high'
							value={
								typeof this.input_high == 'number'
									? this.input_high
									: typeof state.input_high == 'number' 
										? state.input_high
										: state.max
							}
							className={`${this.props.dial ? 'dial' : 'no-dial'}`}
							onChange={(event) => {
								this.inputChange(event);
							}}
							onBlur={(event) => {
								this.inputChange(event, true);
							}}
						/>
					</li>
				</ul>
			</div>
		);
	}
}

LeRangeSlider.propTypes = {
	config: PropTypes.object.isRequired,
};
