import qs from 'qs';
import {handlePromiseRejected} from 'atlas/import/import.helpers';
import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {RatingEngine} from 'atlas/ratingsengine/ratingsengine.types';
import {LaunchesResponse} from './content/launchhistorytable/launchHistoryTable.types';
import {
	Play,
	RatingEnginesCoverageSegments,
} from './content/metadataLiveramplaunch/types';
import {
	GetPlayLaunchCountParams,
	PlayLaunchesPlaySetParams,
	PlayLaunchesPlaySetData,
	GetPlayLaunchCountPlaySetParams,
	GetTypesResponse,
	GetRatingSegmentCountsOpts,
	GetTargetDataQuery,
	GetTargetDataResponse,
	GetRatingsOpts,
	GetAccountsDataQuery,
	GetAccountsDataResponse,
	GetTargetCountQuery,
	IGetTenantOverage,
} from './playbook.types';

export const getPlay = async (play_name: string): Promise<Play | string> => {
	const playName = play_name ? `/${play_name}` : '';

	return axiosInstance
		.get<Play>(`/pls/play${playName}`)
		.then(({data}) => data, handlePromiseRejected);
};

export const getPlayLaunchCount = async (
	params: GetPlayLaunchCountParams
): Promise<number | string> =>
	axiosInstance
		.get<number>(`/pls/play/launches/dashboard/count`, {params})
		.then(({data}) => data, handlePromiseRejected);

export const playLaunchesPlaySet = async (
	params: PlayLaunchesPlaySetParams,
	data: PlayLaunchesPlaySetData = {}
): Promise<LaunchesResponse | string> =>
	axiosInstance
		.put<LaunchesResponse>(`/pls/play/launches/dashboardPlaySet/`, data, {
			params,
		})
		.then(({data}) => data, handlePromiseRejected);

export const getPlayLaunchCountPlaySet = async (
	params: GetPlayLaunchCountPlaySetParams,
	data: PlayLaunchesPlaySetData = {}
): Promise<number | string> =>
	axiosInstance
		.put<number>(`/pls/play/launches/dashboardPlaySet/count`, data, {params})
		.then(({data}) => data, handlePromiseRejected);

export const getTypes = async (): Promise<GetTypesResponse | string> =>
	axiosInstance
		.get<GetTypesResponse>('/pls/playtypes')
		.then(({data}) => data, handlePromiseRejected);

export const getRatingSegmentCounts = async (
	segmentName: string,
	ratings: string[],
	opts: GetRatingSegmentCountsOpts = {}
): Promise<RatingEnginesCoverageSegments | string> =>
	axiosInstance
		.post<RatingEnginesCoverageSegments>(
			`/pls/ratingengines/coverage/segment/${segmentName}`,
			{
				ratingEngineIds: ratings,
				loadContactsCountByBucket: opts.loadContactsCountByBucket,
				loadContactsCount: opts.loadContactsCount,
				lookupId: opts.lookupId,
				restrictNullLookupId: opts.restrictNullLookupId,
				applyEmailFilter: opts.applyEmailFilter || false,
			}
		)
		.then(({data}) => data, handlePromiseRejected);

export const getRatingSegmentCountsByJourney = async (
	segmentName: string,
	ratingEngineIds: string[],
	journeyStageId?: number,
	stageIdForSubStages?: number
): Promise<RatingEnginesCoverageSegments | string> =>
	axiosInstance
		.post<RatingEnginesCoverageSegments>(
			'/pls/buyerJourney/stage/coverageInfo',
			{
				segmentName,
				ratingEngineIds,
				journeyStageId,
				stageIdForSubStages,
			}
		)
		.then(({data}) => data, handlePromiseRejected);

export const getTargetData = async (
	engineId: string,
	query: GetTargetDataQuery
): Promise<GetTargetDataResponse | string> =>
	axiosInstance
		.get<GetTargetDataResponse>(
			`/pls/ratingengines/${engineId}/entitypreview`,
			{
				params: {
					...query,
				},
				paramsSerializer: {indexes: null},
			}
		)
		.then(({data}) => data, handlePromiseRejected);

export const getRatings = async (
	opts: GetRatingsOpts
): Promise<RatingEngine[] | string> =>
	axiosInstance
		.get<RatingEngine[]>('/pls/ratingengines', {
			params: {
				type: opts.type || null,
				status: opts.active ? 'ACTIVE' : '',
				publishedratingsonly: 'true',
			},
		})
		.then(({data}) => data, handlePromiseRejected);

export const savePlay = async (opts: Play): Promise<Play | string> =>
	axiosInstance
		.post<Play>('/pls/play', opts)
		.then(({data}) => data, handlePromiseRejected);

export const getAccountsData = (
	query: GetAccountsDataQuery
): Promise<GetAccountsDataResponse> =>
	axiosInstance
		.post<GetAccountsDataResponse>('/pls/accounts/data', query)
		.then(({data}) => data);

export const getAccountsCount = (
	query: Partial<GetAccountsDataQuery>
): Promise<number> =>
	axiosInstance.post('/pls/accounts/count', query).then(({data}) => data);

export const getTargetCount = (
	engineId: string,
	query: GetTargetCountQuery
): Promise<number | string> =>
	axiosInstance
		.get<number>(`/pls/ratingengines/${engineId}/entitypreview/count`, {
			params: query,
			paramsSerializer: (params) =>
				qs.stringify(params, {arrayFormat: 'repeat'}),
		})
		.then(({data}) => data, handlePromiseRejected);
export const getTenantOverage = (): Promise<IGetTenantOverage> =>
	axiosInstance
		.get('/pls/aba-activation-svc/tenant/overage')
		.then(({data}) => data);
