import React, {Component, PropTypes} from 'common/react-vendor';
import {redux} from 'store';
import './le-sort.scss';

export default class LeSort extends Component {
	static propTypes = {
		config: PropTypes.object.isRequired,
		hide: PropTypes.bool,
	};

	static defaultProps = {
		hide: false,
	};

	constructor(props) {
		super(props);

		const state = {
			label: '',
			visible: false,
			items: [],
		};

		this.redux = redux({
			path: props.namespace || '@filters.sort',
			state: Object.assign(state, props.config),
			component: this,
			appCache: props.noAppCache ? null : ['order', 'property', 'label'], // save state & changes to local storage
		});
	}

	getButtonClasses(config, additional) {
		let classes = 'button white-button icon-button select-';
		classes += additional ? additional : '';
		classes += config.visible ? ' open' : '';
		return classes;
	}

	getIconClasses(item) {
		const state = this.redux.get();
		let classes = 'fa fa-sort-' + (item.icon || 'amount');
		classes += '-' + (state.order == '' ? 'asc' : 'desc');
		return classes;
	}

	setProperty = (item) => {
		const state = this.redux.get();
		state.label = item.label;
		state.property = item.property;
		state.icon = item.icon;
		this.redux.set(state);
	};

	setToggle = () => {
		const state = this.redux.get();
		state.visible = !state.visible;
		this.redux.set(state);
	};

	setOrder = () => {
		const state = this.redux.get();
		state.order = state.order == '-' ? '' : '-';
		this.redux.set(state);
	};

	render() {
		const state = this.redux.get();

		return (
			<div
				className='select-menu white-select-menu sort-select'
				style={this.props.hide ? {display: 'none'} : {}}>
				<button
					type='button'
					title='Sort Property'
					className={this.getButtonClasses(state, 'label')}
					onClick={this.setToggle}>
					{state.label}
				</button>
				<button
					type='button'
					title='Sort Direction'
					className={this.getButtonClasses(state, 'more')}
					onClick={this.setOrder}>
					<span className={this.getIconClasses(state)} />
				</button>
				{state.visible && state.items.length > 0 ? (
					<ul className='model-menu' onClick={this.clickToggle}>
						{this.renderItems(state.items)}
					</ul>
				) : (
					''
				)}
			</div>
		);
	}

	renderItems(items) {
		return items.map((item) => {
			if (item.if === undefined || item.if) {
				return (
					<li
						key={'LeSortItem' + item.label}
						className={item.class}
						onClick={() => {
							this.setToggle();
							this.setProperty(item);
						}}>
						<span>{item.label}</span>
						{this.renderItemIcon(item)}
					</li>
				);
			}
		});
	}

	renderItemIcon(item) {
		if (item.icon) {
			return <i className={this.getIconClasses(item)} />;
		}
	}
}
