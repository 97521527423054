import template from './orphan-export.component.html';
import {getOrphanExportDownloadLink} from '../../../../../atlas/app/segments/services/SegmentService';

angular.module('lp.jobs.orphan.export', []).component('orphanExport', {
	template,
	bindings: {},
	controller($stateParams) {
		const vm = this;
		vm.$onInit = function () {
			vm.exportID = $stateParams.exportID;
			vm.exportDownloadLink = getOrphanExportDownloadLink(
				$stateParams.exportID
			);
		};
	},
});
