import {Banner} from 'common/app/services/BannerService';
import {queryBuilderExit} from '../query/queryBuilder.utilities';
import {getEntityFieldBuckets} from '../query/advanced/tree/tree.queries';
import subHeaderTemplate from '../tabs/subheader/subheader.component.html';
import pickerTemplate from './picker.component.html';
import StateHistory from '../../../../atlas/app/StateHistory';

angular
	.module('common.datacloud.valuepicker')
	.config(function ConfigurePicker($stateProvider) {
		$stateProvider.state('home.segment.explorer.enumpicker', {
			url: '/picker/:entity/:fieldname',
			onExit: ($state, $q) => {
				Banner.reset();
				const queryBuilderConfig = {
					$state,
				};
				const deferred = $q.defer();
				queryBuilderExit(queryBuilderConfig).then((exit) => {
					if (exit) {
						deferred.resolve(true);
					} else {
						deferred.reject('user cancelled action');
						StateHistory.fromPop();
						HideSpinner();
					}
				});
				return deferred.promise;
			},
			resolve: {
				async PickerBuckets($stateParams) {
					'ngInject';

					const {entity, fieldname} = $stateParams;

					return getEntityFieldBuckets(entity, fieldname);
				},
			},
			views: {
				'subsummary@': {
					controller: 'SubHeaderTabsController',
					controllerAs: 'vm',
					template: subHeaderTemplate,
				},
				'main@': {
					controller: 'ValuePickerController',
					controllerAs: 'vm',
					template: pickerTemplate,
				},
			},
		});
	});
