import React from 'common/react-vendor';
import {
	DNBCard,
	DNBSelect,
	DNBTooltip,
	DNBTypography,
	DNBSelectOption,
} from 'common/dnb-uux-vendor';
import {Spinner} from 'widgets/spinner';
import CommonHeader from './CommonHeader';
import styles from './PersonalizationComponent.module.scss';
import {
	CommonHeaderMessage,
	CommonHeaderSize,
	HeaderType,
	IOptions,
} from '../configs/PersonalizationTypes';
import {usePersonalization} from '../hook/usePersonalization';
import {
	CommonHeaderMessageConfig,
	defaultAttributeGroupOption,
} from '../configs/PersonalizationConst';

const PersonalizationAttributeGroup = (): React.ReactElement => {
	const {
		context: personalizationContext,
		setContext: personalizationUpdateContext,
	} = usePersonalization();
	const {groupList, attributesList, groupOption, isAttributeListLoading} =
		personalizationContext;
	const {setGroupOption} = personalizationUpdateContext;

	const onChangeGroup = (value: string): void => {
		setGroupOption(value);
	};

	const selectOptions: Array<IOptions> = groupList.map(
		({name, displayName}) => {
			return {
				label: displayName,
				value: name,
			};
		}
	);

	return (
		<>
			<CommonHeader
				headerType={HeaderType.Steps}
				size={CommonHeaderSize.CompactBody}
				title={
					CommonHeaderMessageConfig[CommonHeaderMessage.AttributeGroup].title
				}
				description={
					CommonHeaderMessageConfig[CommonHeaderMessage.AttributeGroup]
						.description
				}
			/>
			<div className={styles.personalizationAttributeGroup}>
				<div className={styles.attributeGroup}>
					<DNBTypography
						variant='compact-bold'
						sx={{
							color: (theme) => theme.colors.ColorGrayPrimary,
						}}>
						Attribute Group (optional)
					</DNBTypography>
					<div className={styles.selectGroup}>
						<div className={styles.selectGroupLeft}>
							<DNBSelect<string>
								size='default'
								minWidth='360px'
								value={groupOption}
								onChange={(_, value) => onChangeGroup(value!)}
								placeholder='Select an attribute group'>
								{defaultAttributeGroupOption
									.concat(selectOptions)
									.map(({label, value}) => {
										return (
											<DNBSelectOption
												className={styles.selectGroupOption}
												key={value}
												value={value}>
												{label}
											</DNBSelectOption>
										);
									})}
							</DNBSelect>
						</div>
						{groupOption !== '' && (
							<div className={styles.attributesContent}>
								<DNBTypography
									variant='h5'
									sx={{
										ml: 4,
										mr: 1,
										color: (theme) => theme.colors.ColorPrimaryBlueInteractive,
									}}>
									{attributesList.length}
								</DNBTypography>
								<DNBTypography variant='compact-body'>Attributes</DNBTypography>
							</div>
						)}
					</div>
				</div>
				{isAttributeListLoading ? (
					<Spinner />
				) : (
					<div className={styles.attributeGroupList}>
						{attributesList.map(
							({Attribute, DisplayName, Entity, Category}) => {
								return (
									<DNBCard
										hasBorder
										key={Attribute + Entity}
										cardColor='ColorGrayPrimary'
										className={styles.attributeCard}>
										<DNBTooltip
											arrow
											placement='top'
											content={`${Category}.${DisplayName}`}>
											<div className={styles.attributeCardContent}>
												<DNBTypography noWrap variant='compact-body'>
													{`${Category}.${DisplayName}`}
												</DNBTypography>
											</div>
										</DNBTooltip>
									</DNBCard>
								);
							}
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default PersonalizationAttributeGroup;
