import {ObjectType} from 'atlas/connectors/EIF/Data/ConnectionType';

interface AdsAccount {
	display: string;
	type: string;
	value: string;
}

const getAdsAccountListUrl = ({
	systemType,
	trayAuthenticationId,
}: ObjectType): string =>
	`/pls/tray/accounts/${systemType}/options?trayAuthenticationId=${trayAuthenticationId}`;

export {getAdsAccountListUrl};

export type {AdsAccount};
