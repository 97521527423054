import {styled, IconButton} from 'common/dnb-uux-vendor';
import {
	FreeStyleDialog,
	FreeStyleDialogContent,
	FreeStyleStickyFooter,
} from 'common/composite-uux-components/dialog-drawer/DialogDrawer';

export const StyledFreeStyleDialog = styled(FreeStyleDialog)(() => ({
	'.MuiDialog-paper': {
		width: '576px',
		height: '449px',
	},
}));

export const StyledHeaderDiv = styled('div')(({theme}) => ({
	display: 'flex',
	flexDirection: 'column',
	padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(
		4
	)} ${theme.spacing(6)}`,
	gap: theme.spacing(4),
}));

export const StyledTitleDiv = styled('div')`
	position: relative;
`;

export const StyledIconButton = styled(IconButton)`
	position: absolute;
	bottom: 16px;
	right: -18px;
`;

export const StyledFreeStyleDialogContent = styled(FreeStyleDialogContent)(
	({theme}) => ({
		padding: `0 ${theme.spacing(5)}`,
	})
);

export const StyledFreeStyleStickyFooter = styled(FreeStyleStickyFooter)(
	({theme}) => ({
		alignItems: 'center',
		justifyContent: 'flex-end',
		gap: theme.spacing(3),
		padding: theme.spacing(3),
		boxShadow: `0px -1px 0px 0px ${theme.colors.ColorGrayIncidental}`,
	})
);

export const StyledFormContainer = styled('form')(({theme}) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spacing(4),
}));

export const StyledSelectContainer = styled('div')(({theme}) => ({
	display: 'flex',
	alignItems: 'center',
	gap: theme.spacing(1),
}));
