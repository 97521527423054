import React from 'common/react-vendor';
import {DNBTypography} from 'common/dnb-uux-vendor';
import {format} from 'date-fns';
import classNames from 'classnames';
import {ItemType} from '../../Tile/TileBody';
import styles from './TextColumnItem.module.scss';

interface ITextColumnItem {
	value: string;
	valueType: ItemType;
	isPrimary?: boolean;
	stylePrefix?: string;
}

const HiddenValue = 'None';

const TextColumnItem = ({
	value,
	valueType,
	isPrimary,
	stylePrefix,
}: ITextColumnItem): React.ReactElement => {
	const hideColumnItem = valueType === ItemType.Enum && value === HiddenValue;
	const valueText =
		valueType === ItemType.Date || valueType === ItemType.DateTime
			? format(
					parseInt(value, 10),
					`MM/dd/yyyy${valueType === ItemType.DateTime ? ' hh:mma' : ''}`
			  )
			: value;
	return !hideColumnItem ? (
		<span
			className={classNames({
				[styles.textEllipsis!]: true,
				[styles.ItemValueContainer!]: valueType === ItemType.Enum,
				[styles[stylePrefix ? `${stylePrefix}_${value}` : `${value}`]!]:
					valueType === ItemType.Enum,
			})}>
			<DNBTypography
				variant='caption'
				className={classNames({
					[styles.ItemValue!]: true,
					[styles.Bold!]: isPrimary,
				})}
				title={valueText}>
				{valueText}
			</DNBTypography>
		</span>
	) : (
		<></>
	);
};

export {TextColumnItem};
export type {ITextColumnItem};
