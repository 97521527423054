import {RatingsEngineActionType} from './enums';
import type {
	RatingsEngineAction,
	RatingsEngineState,
	ModelTrainingOptions,
	TrainingCounts,
	Current,
	RatingsEngineValidation,
} from './types';

export const clearRatingsEngineStore = (): RatingsEngineAction => {
	return {
		type: RatingsEngineActionType.CLEAR_RATINGS_ENGINE_STORE,
	};
};

export const setRatingsEnginePropertyValue = <RatingsEngineValue>(
	key: keyof RatingsEngineState,
	value: RatingsEngineValue
): RatingsEngineAction => {
	return {
		type: RatingsEngineActionType.SET_RATINGS_ENGINE_PROPERTY_VALUE,
		payload: {
			key,
			value,
		},
	};
};

export const setModelTrainingOption = (
	key: keyof ModelTrainingOptions,
	value: unknown
): RatingsEngineAction => {
	return {
		type: RatingsEngineActionType.SET_MODEL_TRAINING_OPTION,
		payload: {
			key,
			value,
		},
	};
};

export const setTrainingCountsPropertyValue = (
	key: keyof TrainingCounts,
	value: unknown
): RatingsEngineAction => {
	return {
		type: RatingsEngineActionType.SET_TRAINING_COUNTS_PROPERTY_VALUE,
		payload: {
			key,
			value,
		},
	};
};

export const setCurrentPropertyValue = (
	key: keyof Current,
	value: unknown
): RatingsEngineAction => {
	return {
		type: RatingsEngineActionType.SET_CURRENT_PROPERTY_VALUE,
		payload: {
			key,
			value,
		},
	};
};

export const setValidationPropertyValue = (
	key: keyof RatingsEngineValidation,
	value: boolean
): RatingsEngineAction => {
	return {
		type: RatingsEngineActionType.SET_VALIDATION_PROPERTY_VALUE,
		payload: {
			key,
			value,
		},
	};
};
