import React from 'common/react-vendor';
import type {BucketRestriction} from '../../../../query.types';
import {TreeType, BucketCmp, TransactionKey} from '../../../../query.enums';
import {getBucketCmp, resetBktValues} from '../../tree.helpers';
import {isNumericalRangeValid} from '../../edit/NumericalRange/numericalRange.validations';
import {getChoicesList} from '../transactionStore.helpers';
import {qtyConfig} from '../transactionStore.constants';
import {unitFromCmps, unitToCmps} from './transactionEditItem.constants';
import {SubTypeSelection} from './SubTypeSelection';
import {
	updatePurchaseHistoryCmp,
	removeBucketKey,
	getSubTypeConfig,
	getNumericalRangeValues,
} from './transactionEditItem.helpers';
import {SubType} from '../../tree.types';
import {NumericalRangeStatusesKeys} from './TransactionEditItem';

const {useState} = React;

interface TransactionUnitsPurchasedProps {
	type?: TreeType;
	bucketRestriction?: BucketRestriction;
	purchased: string;
	onBucketRestrictionChange(newBucketRestriction?: BucketRestriction): void;
	onNumericalRangeValidation(
		key: NumericalRangeStatusesKeys,
		isValid: boolean
	): void;
}

const TransactionUnitsPurchased = ({
	type,
	bucketRestriction,
	purchased,
	onBucketRestrictionChange,
	onNumericalRangeValidation,
}: TransactionUnitsPurchasedProps): React.ReactElement => {
	const [qtyRangeConfig, setQtyRangeConfig] = useState(
		getSubTypeConfig({
			bucketRestriction,
			type,
			subType: TransactionKey.Qty,
			config: qtyConfig,
		})
	);

	const qtyCmp = getBucketCmp({
		bucketRestriction,
		type,
		subType: TransactionKey.Qty,
	});

	const unitPurchasedCmp = qtyCmp === '' ? BucketCmp.ANY_CAPITALIZE : qtyCmp;

	const showFromUnit = unitFromCmps.includes(unitPurchasedCmp);
	const showToUnit = unitToCmps.includes(unitPurchasedCmp);

	const getNewBucketRestriction = (
		value: BucketCmp
	): BucketRestriction | undefined => {
		const bucketParserParams = {
			bucketRestriction,
			type,
			value,
			subType: TransactionKey.Qty as SubType,
		};

		return removeBucketKey({
			...bucketParserParams,
			bucketRestriction: resetBktValues({
				...bucketParserParams,
				bucketRestriction: updatePurchaseHistoryCmp(bucketParserParams),
			}),
		});
	};

	const handleUnitPurchasedCmpChange = (value: BucketCmp): void => {
		onBucketRestrictionChange(getNewBucketRestriction(value));

		onNumericalRangeValidation(
			'isUnitsPurchasedValid',
			value === BucketCmp.ANY_CAPITALIZE
		);

		setQtyRangeConfig((previousState) => ({
			...previousState,
			from: {
				...previousState.from,
				value: undefined,
			},
			to: {
				...previousState.to,
				value: undefined,
			},
		}));
	};

	const handleRangeChange = (): void => {
		const newNumericalConfig = getSubTypeConfig({
			bucketRestriction,
			type,
			subType: TransactionKey.Qty,
			config: qtyRangeConfig,
		});

		onNumericalRangeValidation(
			'isUnitsPurchasedValid',
			isNumericalRangeValid({
				vals: getNumericalRangeValues({
					bucketRestriction,
					type,
					subType: TransactionKey.Qty,
				}),
				rangeConfig: newNumericalConfig,
				showFrom: showFromUnit,
				showTo: showToUnit,
			})
		);

		setQtyRangeConfig(newNumericalConfig);
	};

	return (
		<SubTypeSelection
			type={type}
			bucketRestriction={bucketRestriction}
			purchased={purchased}
			title='units purchased'
			cmpList={getChoicesList()}
			initialCmp={unitPurchasedCmp}
			onCmpChange={handleUnitPurchasedCmpChange}
			onRangeChange={handleRangeChange}
			showFrom={showFromUnit}
			showTo={showToUnit}
			rangeConfig={qtyRangeConfig}
		/>
	);
};

export {TransactionUnitsPurchased};
