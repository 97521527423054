import React from 'common/react-vendor';
import styles from '../Component/Placeholder/Placeholder.module.scss';

let draggingElement: (EventTarget & HTMLLIElement) | null = null;
let dragToElement: (EventTarget & HTMLLIElement) | null = null;

type onReorder = (orderList: string[]) => void;

interface IPlaceholder {
	text?: string;
}

interface IUseReorderProp {
	orderList: string[];
	onReorder: onReorder;
	placeholder?: IPlaceholder;
}

interface IUseReorder {
	onDragStart?: (e: React.DragEvent<HTMLLIElement>) => void;
	onDragOver?: (e: React.DragEvent<HTMLOListElement>) => void;
	onDragEnd?: (e: React.DragEvent<HTMLLIElement>) => void;
}

const DefaultPlaceholderText = 'Drop Here';

/**
 * use reorder hook.
 * @param orderList Order list
 * @param onReorder onReorder callback
 * @placeholder Placeholder configuration
 * Usage:
 * Apply onDragStart onDragOver onDragEnd to element
 * which has data-id attribute indicates key of the data item.
 */
const useReorder = ({
	orderList,
	onReorder,
	placeholder: {text = DefaultPlaceholderText} = {
		text: DefaultPlaceholderText,
	},
}: IUseReorderProp): IUseReorder => {
	const placeholder = document.createElement('li');
	placeholder.className = styles.Placeholder!;

	const onDragStart = (e: React.DragEvent<HTMLLIElement>): void => {
		e.dataTransfer.effectAllowed = 'move';
		draggingElement = e.currentTarget;
	};

	const onDragEnd = (): void => {
		if (!draggingElement || !dragToElement) return;

		const {parentNode} = draggingElement;
		if (!parentNode) return;

		const placeholders = document.querySelectorAll(`.${styles.Placeholder}`);
		if (placeholders.length > 0) {
			placeholders.forEach((el) => {
				parentNode.removeChild(el);
			});
		}

		draggingElement.style.display = '';

		const from = orderList.filter(
			(id) => id === draggingElement?.dataset.id
		)?.[0];
		const to = orderList.filter((id) => id === dragToElement?.dataset.id)?.[0];
		if (!from || !to || from === to) return;
		const toIndex = orderList.findIndex((id) => id === to);
		const reorderColumnList = orderList.filter((item) => item !== from);
		reorderColumnList.splice(toIndex, 0, from!);
		onReorder?.(reorderColumnList);
	};

	const onDragOver = (e: React.DragEvent<HTMLOListElement>): void => {
		e.preventDefault();
		if (!draggingElement) return;
		const target = e.target as HTMLLIElement;
		if (target.tagName !== 'LI') return;
		if (target.className === styles.Placeholder) return;

		const parentNode = draggingElement.parentNode!;
		const placeholders = document.querySelectorAll(`.${styles.Placeholder}`);
		if (placeholders.length > 0) {
			placeholders.forEach((el) => {
				parentNode.removeChild(el);
			});
		}
		dragToElement = target;
		draggingElement.style.display = 'none';
		const fromIndex = orderList.findIndex(
			(id) => id === draggingElement?.dataset.id
		);
		const toIndex = orderList.findIndex((id) => id === target?.dataset.id);
		if (fromIndex < toIndex) {
			if (parentNode.lastChild === target) {
				parentNode.appendChild(placeholder);
			} else {
				parentNode.insertBefore(placeholder, target.nextSibling);
			}
		} else {
			parentNode.insertBefore(placeholder, target);
		}
		placeholder.innerHTML = text;
	};

	React.useEffect(() => {
		draggingElement = null;
		dragToElement = null;
	}, []);
	return {onDragStart, onDragOver, onDragEnd};
};

export {useReorder};
