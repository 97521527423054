const enUsStrings = require('../../assets/i18n/en-US.json');

const strings = {
	'en-US': enUsStrings,
};
const replaceTokens = function (key, replacements) {
	const maxNumberOfReplacements = 10;
	for (let i = 0; i < replacements.length; i++) {
		let replacementsCount = 0;
		while (key.indexOf(`{{${i}}}`) !== -1) {
			key = key.replace(`{{${i}}}`, replacements[i]);
			replacementsCount++;
			if (replacementsCount > maxNumberOfReplacements) {
				console.warn(`Max number of replacements reached for: ${key}`);
				break;
			}
		}
	}
	return key;
};
// get a config resource string value given its key
export const getString = function (key, replacements) {
	let toReturn = strings['en-US'][key] || key;
	if (toReturn.indexOf('i18n-') > -1) {
		console.warn(`i18n conflict found for: ${key} using: "${toReturn}"`);
	}
	if (replacements) {
		toReturn = replaceTokens(toReturn, replacements);
	}
	return toReturn;
};
