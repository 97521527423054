import axios from 'axios';
import type {StateService} from '@uirouter/angularjs';
import NgState from 'atlas/ng-state';
import React from 'common/react-vendor';
import {ISegment} from 'atlas/data/SegmentConst';
import {handleSegmentClick} from 'atlas/segmentation/Helper/SegmentHelper';
import {
	CommonBreadcrumbs,
	ICommonBreadcrumbs,
} from '../../../../../atlas/app/connectors/EIF/Component/CommonBreadcrumbs';
import {BaseSegmentHelper} from '../tabs/SegmentTabsHelper';

/**
 * IAC360SegmentHeader
 * @param $state Angular state
 * @param $stateParams state params
 * @param vmSegment Segment data @ISegment
 * @remarks Tightly coupled with angurlar functions, will be removed after
 * the whole things are converted using React.
 */
interface IAC360SegmentHeader {
	$state: StateService;
	$stateParams: Record<string, string>;
	vmSegment: ISegment;
}

/**
 * AC360SegmentHeader
 * @param prop @IAC360SegmentHeader
 * @returns
 */
function AC360SegmentHeader({
	$state,
	$stateParams,
	vmSegment,
}: IAC360SegmentHeader): JSX.Element {
	const [accountName, setAccountName] = React.useState<string>();

	const baseSegmentHelper = React.useMemo(
		() => new BaseSegmentHelper($state, !!$stateParams.aiModel, vmSegment),
		[$state, $stateParams.aiModel, vmSegment]
	);

	const breadcrumbsList: ICommonBreadcrumbs[] = React.useMemo(() => {
		NgState.setAngularState($state);
		return [
			{
				text: 'Segments',
				onClick: () => $state.go('home.segments_new', {}, {reload: true}),
				isLink: true,
			},
			{
				text: vmSegment?.display_name,
				isLink: true,
				onClick: () => handleSegmentClick(vmSegment),
				hide: !vmSegment,
			},
			{
				text: 'Accounts',
				onClick: () => baseSegmentHelper.clickTab('accounts'),
				isLink: true,
			},
			{
				text: accountName || '',
				isLink: false,
			},
		];
	}, [vmSegment, $state, accountName, baseSegmentHelper]);

	React.useEffect(() => {
		if ($stateParams.a360_accountId) {
			axios
				.get(`/pls/ac360/account?accountId=${$stateParams.a360_accountId}`)
				.then(({data, status}) => {
					if (status === 200) {
						setAccountName(data.companyName);
					}
				})
				.catch(null);
		}
	}, [$stateParams.a360_accountId]);

	return (
		<CommonBreadcrumbs
			sx={{mt: 6, ml: 8, width: '1000px'}}
			breadcrumbsList={breadcrumbsList.filter(({hide}) => !hide)}
		/>
	);
}

export type {IAC360SegmentHeader};
export {AC360SegmentHeader};
