import isEmpty from 'lodash/isEmpty';
import React from 'common/react-vendor';
import type {BucketRestriction} from '../../../../query.types';
import {BucketCmp, BucketType, TreeType} from '../../../../query.enums';
import {getBucketCmp, getValues} from '../../tree.helpers';
import {dateAttributeMap, getPeriod} from '../dateAttribute.helpers';
import '../date-attribute-edit.scss';

interface DateAttributeProps {
	type?: BucketType | TreeType;
	bucketRestriction?: BucketRestriction;
}

const DateAttribute = ({
	type,
	bucketRestriction,
}: DateAttributeProps): React.ReactElement => {
	const getCmp = (): string => {
		if (!bucketRestriction || !type) {
			return '';
		}

		const bucketCmp = getBucketCmp({
			bucketRestriction,
			type,
			subType: BucketType.Date,
		});

		if (bucketCmp === '') {
			return BucketCmp.EVER;
		}

		if (dateAttributeMap[bucketCmp]) {
			return dateAttributeMap[bucketCmp] || '';
		}

		return '';
	};

	const getBucketValues = (): string => {
		if (!bucketRestriction || !type) {
			return '';
		}

		const bucketValues = getValues({
			bucketRestriction,
			type,
			subType: BucketType.Date,
		});

		if (isEmpty(bucketValues)) {
			return '';
		}

		const [firstValue, secondValue] = bucketValues || [''];
		if (bucketValues?.length === 2) {
			return `${firstValue} - ${secondValue}`;
		}

		return firstValue?.toString() || '';
	};

	const getBucketPeriod = (): string => {
		if (!bucketRestriction || !type) {
			return '';
		}

		const period = getPeriod(bucketRestriction.bkt);

		switch (bucketRestriction.bkt?.Fltr?.Cmp) {
			case BucketCmp.EVER:
			case BucketCmp.IS_EMPTY:
			case BucketCmp.IS_EMPTY_CAPITALIZE:
			case BucketCmp.BETWEEN_DATE:
			case BucketCmp.BEFORE:
			case BucketCmp.AFTER:
			case BucketCmp.LATEST_DAY:
				return '';

			default:
				return `${period}(s)`;
		}
	};

	return (
		<span className='query-section-item-value'>
			<strong>
				{getCmp()}&nbsp;{getBucketValues()}&nbsp;{getBucketPeriod()}
			</strong>
		</span>
	);
};

/**
 * TODO: Angular directive components can't use camelCase attributes...
 * Since we are migrating everything to React I feel that ideally
 * we should keep using camelCases for props, that is why I am creating this temporary
 * wrapper to pass the angular attributes to the React component...
 *
 * DONT use this component!
 * Use DateAttribute if you are refactoring an angular component to React.
 * This component purpose is only to be use in the temporary
 * date-attribute.component react2angular
 *
 * TODO: DELETE ME Once all instances of <date-attribute />
 * are removed...
 */

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
const DateAttributeWithAngularProps = ({
	bucketrestriction,
	type,
}: Record<string, any>): React.ReactElement => (
	<DateAttribute bucketRestriction={bucketrestriction} type={type} />
);

export {DateAttribute, DateAttributeWithAngularProps};
