import {DialogContent, styled} from '@mui/material';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import {getDNBDialogSizes} from '@dnb-uux-design-system/react';

const FreeStyleStickyFooter = styled(DialogContent)(({theme}) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	gap: theme.spacing(3),
	padding: theme.spacing(3),
	overflowY: 'unset',
	boxShadow: `0px -2px 4px ${theme.colors.ColorGrayPrimaryAlpha2}`,
}));

type IFreeStyleStickyFooter = {
	justifyContent: string;
};

const getFreeStyleStickyFooter = ({
	justifyContent,
}: IFreeStyleStickyFooter): typeof FreeStyleStickyFooter =>
	styled(FreeStyleStickyFooter)(() => ({
		justifyContent,
	}));

const FreeStyleBetweenFooter = getFreeStyleStickyFooter({
	justifyContent: 'space-between',
});

const FreeStyleDialogContent = styled(DialogContent)(({theme}) => ({
	display: 'flex',
	padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
	flexDirection: 'column',
	height: 'inherit',
	overflowY: 'auto',
	borderBottom: 'none',
	borderTop: 'none',
}));

const FreeStyleDialog = styled(Dialog)<DialogProps>(({theme}) => ({
	'.MuiDialog-paper': {
		...getDNBDialogSizes('full', true),
		boxShadow: theme.boxShadows.ShadowPlus2,
		position: 'static',
	},
}));

type IFreeStyleDialog = {
	width?: string;
	minWidth?: string;
	maxWidth?: string;
	height?: string;
	minHeight?: string;
	maxHeight?: string;
};

const getFreeStyleDialog = (config: IFreeStyleDialog): typeof FreeStyleDialog =>
	styled(FreeStyleDialog)(() => ({
		'.MuiDialog-paper': {
			...config,
		},
	}));

enum DialogButton {
	Done = 'Done',
	Duplicate = 'Duplicate',
	Save = 'Save',
	Leave = 'Yes, leave now',
	Delete = 'Yes, delete it',
	Cancel = 'Cancel',
	Confirm = 'Confirm',
	Back = 'Back',
	Continue = 'Continue',
	Add = 'Add',
}

export {
	FreeStyleDialog,
	FreeStyleStickyFooter,
	FreeStyleBetweenFooter,
	FreeStyleDialogContent,
	DialogButton,
	getFreeStyleDialog,
	getFreeStyleStickyFooter,
};
