import React from 'common/react-vendor';
import {
	Box,
	DNBTypography,
	DNBButton,
	AccountTreeOutlined,
} from 'common/dnb-uux-vendor';
import {ISegment} from 'atlas/data/SegmentConst';
import {SegmentCreate} from 'common/components/datacloud/segment/SegmentQueryHelper';
import {getQueryProperty} from 'common/stores/query';
import {SEGMENTS_EXPLORER} from 'atlas/navigation/header/components/page-navigation.utils';
import {JourneyStageNgState} from 'atlas/helpers/NavigateHelper';
import {IParentContainer, IParentStageContainer} from './parent.container.ng';

const ParentContainer = ({
	titleText,
	buttonText,
	onClick,
}: IParentContainer): React.ReactElement => {
	return (
		<Box display='flex' flexDirection='column' gap={4} margin={6}>
			<DNBTypography variant='h6'>{titleText}</DNBTypography>
			<DNBButton
				variant='secondary'
				startIcon={<AccountTreeOutlined />}
				onClick={() => onClick?.()}>
				{buttonText}
			</DNBButton>
		</Box>
	);
};

const ParentSegmentContainer = ({
	$state,
}: IParentContainer): React.ReactElement => {
	const segmentObj = getQueryProperty<ISegment>('segment') || {
		name: SegmentCreate,
	};
	const parentSegment = React.useMemo(() => {
		const segmentList = getQueryProperty<ISegment[]>('segments') || [];
		const parentSegment = segmentList.find(
			(segment) => segment.name === segmentObj.customParentSegmentName
		);
		return `Parent Segment: ${parentSegment?.display_name}`;
	}, [segmentObj.customParentSegmentName]);
	return (
		<ParentContainer
			titleText='Create a custom subsegment by narrowing down the parent segment below:'
			buttonText={parentSegment}
			onClick={() =>
				$state?.go(SEGMENTS_EXPLORER, {
					segment: segmentObj.customParentSegmentName,
				})
			}
		/>
	);
};

const ParentStageContainer = ({
	$state,
	stage,
}: IParentStageContainer): React.ReactElement => {
	return (
		<ParentContainer
			titleText='Create a substage by narrowing down the journey stage below:'
			buttonText={`Journey Stage: ${stage.name}`}
			onClick={() =>
				$state?.go(JourneyStageNgState.Query, {
					id: stage.id,
				})
			}
		/>
	);
};

export {ParentContainer, ParentSegmentContainer, ParentStageContainer};
