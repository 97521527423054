import RBAC, {
	AccessGroups,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {setEnrichments} from 'common/stores/datacloud';
import dataCloudTabsTemplate from '../tabs/datacloud/datacloud.component.html';
import dataCloudExplorerTemplate from '../explorer/explorer.component.html';
import lookupTemplate from './lookup.component.html';
import lookupTabsTemplate from './tabs.component.html';
import lookupMatchingTemplate from './matching.component.html';

angular.module('common.datacloud.lookup').config(function ($stateProvider) {
	$stateProvider
		.state('home.datacloud.lookup', {
			url: '/lookup',
			redirectTo: 'home.datacloud.lookup.form',
		})
		.state('home.datacloud.lookup.form', {
			url: '/form',
			params: {
				pageIcon: 'ico-enrichment',
				pageTitle: 'Data Cloud Explorer',
			},
			views: {
				'summary@': {
					controller: 'DataCloudTabsController',
					controllerAs: 'vm',
					template: dataCloudTabsTemplate,
				},
				'main@': {
					controller: 'LookupController',
					controllerAs: 'vm',
					template: lookupTemplate,
				},
			},
		})
		.state('home.datacloud.lookup.tabs', {
			url: '/tabs',
			params: {
				pageIcon: 'ico-enrichment',
				pageTitle: 'Data Cloud Explorer',
			},
			onExit: [
				'Enrichments',
				function (Enrichments) {
					setEnrichments(Enrichments, false);
				},
			],
			views: {
				'summary@': {
					controller: 'DataCloudTabsController',
					controllerAs: 'vm',
					template: dataCloudTabsTemplate,
				},
				'subsummary@': {
					controller: function (LookupResponse) {
						this.ldc_name = LookupResponse.companyInfo
							? LookupResponse.companyInfo.LDC_Name
							: '';

						this.isInternalUser = false;
						if (RBAC.userHasAccessLevel(AccessGroups.INTERNAL)) {
							this.isInternalUser = true;
						}
					},
					controllerAs: 'vm',
					template: lookupTabsTemplate,
				},
			},
			redirectTo: 'home.datacloud.lookup.tabs.attr',
		})
		.state('home.datacloud.lookup.tabs.matching', {
			url: '/matching',
			views: {
				'main@': {
					controller: function (LookupResponse) {
						const vm = this;

						angular.extend(vm, {
							response: LookupResponse,
						});
					},
					controllerAs: 'vm',
					template: lookupMatchingTemplate,
				},
			},
		})
		.state('home.datacloud.lookup.tabs.attr', {
			url: '/attr/:category/:subcategory',
			params: {
				section: 'lookup',
				LoadingText: 'Looking up Company Profile data',
				category: {dynamic: true, value: ''},
				subcategory: {dynamic: true, value: ''},
			},
			views: {
				'main@': {
					controller: 'DataCloudController',
					controllerAs: 'vm',
					template: dataCloudExplorerTemplate,
				},
			},
		});
});
