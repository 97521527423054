import {DataCloudActionType} from './enums';
import type {DataCloudAction, DataCloudMetadata, DataCloudState} from './types';

const clearDataCloudStore = (): DataCloudAction => {
	return {
		type: DataCloudActionType.CLEAR_DATA_CLOUD_STORE,
	};
};

const setDataCloudPropertyValue = <DataCloudValue>(
	key: keyof DataCloudState,
	value: DataCloudValue
): DataCloudAction => {
	return {
		type: DataCloudActionType.SET_DATA_CLOUD_PROPERTY_VALUE,
		payload: {
			key,
			value,
		},
	};
};

const setMetadataPropertyValue = <MetadataValue>(
	key: keyof DataCloudMetadata,
	value: MetadataValue
): DataCloudAction => {
	return {
		type: DataCloudActionType.SET_METADATA_PROPERTY_VALUE,
		payload: {
			key,
			value,
		},
	};
};

const setFeedbackModalPropertyValue = <FeedbackModalValue>(
	key: keyof DataCloudState['feedbackModal'],
	value: FeedbackModalValue
): DataCloudAction => {
	return {
		type: DataCloudActionType.SET_FEEDBACK_MODAL_PROPERTY_VALUE,
		payload: {
			key,
			value,
		},
	};
};

export {
	clearDataCloudStore,
	setDataCloudPropertyValue,
	setMetadataPropertyValue,
	setFeedbackModalPropertyValue,
};
