import NgState from 'atlas/ng-state';
import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';

export type CampaignFilter = {
	campaignName: string;
	campaignId: string;
	deleted?: boolean;
};

type CampaignRecord = {
	[name: string]: string[];
};
export interface IFilterOptions {
	campaigns: CampaignRecord;
}

export interface CommonAccountMetric {
	'Currency': string;
	'CPC': number;
	'CPM': number;
	'CTR': number;
	'Clicks': number;
	'ClosedLostOpportunities': number;
	'ClosedWonOpportunities': number;
	'ClosedWonRevenues': number;
	'Cost': number;
	'Email CTR': number;
	'Email Clicks': number;
	'Email Delivery': number;
	'Email Opens': number;
	'ExpectedRevenues': number;
	'FormFills': number;
	'Impressions': number;
	'OpenOpportunities': number;
	'TotalOpportunities': number;
	'WebVisits': number;
	'AccountId': string;
	'AccountName': string;
}

export interface AccountDetail {
	date: string;
	CampaignsName: string;
	campaigns: (CommonAccountMetric & {
		CampaignId: string;
		CampaignName: string;
	})[];
}

export const totalMetricName = ['Impressions', 'Clicks', 'Cost'];
export const calSumMetrics = ['Impressions', 'Clicks'];
export const unableSortableMetrics = ['Cost', 'CTR', 'CPM', 'CPC', 'Currency'];

const handleCampaignClick = (playId: string): void => {
	const drillable = RBAC.hasAccess(RBACInterface.CAMPAIGNS, RBACActions.DRILL);
	if (drillable) {
		NgState.getAngularState().go(
			'home.playbook.overview',
			{
				play_name: playId,
			},
			{reload: true}
		);
	}
};

const getPlayIdsByDisplayNames = (
	names: string[],
	campaignMap: CampaignRecord
): string[] =>
	names.reduce((ids: string[], name) => {
		const playIdsByName = campaignMap[name];
		if (playIdsByName) return [...ids, ...playIdsByName];
		return ids;
	}, []);

const filterValidPlayIDs = (ids: string[], allPlaysStr: string[]): string[] =>
	ids.reduce((realIds: string[], id) => {
		if (allPlaysStr.includes(id)) realIds.push(id);
		return realIds;
	}, []);

export {handleCampaignClick, getPlayIdsByDisplayNames, filterValidPlayIDs};
