import {TenantConfigActionType} from './enums';
import {TenantConfigState, TenantConfigAction} from './types';

const initialState: TenantConfigState = {
	featureFlags: {},
	modules: [],
	products: [],
	isFetching: true,
};

const tenantConfigReducer = (
	state = initialState,
	action: TenantConfigAction
): TenantConfigState => {
	switch (action.type) {
		case TenantConfigActionType.SET_FEATURE_FLAGS:
			return {
				...state,
				featureFlags: action.payload,
			};
		case TenantConfigActionType.SET_MODULES:
			return {
				...state,
				modules: action.payload,
			};
		case TenantConfigActionType.SET_PRODUCTS:
			return {
				...state,
				products: action.payload,
			};
		case TenantConfigActionType.SET_IS_FETCHING:
			return {
				...state,
				isFetching: action.payload,
			};
		case TenantConfigActionType.SET_TENANT_CONFIG:
			return action.payload;
		default:
			return state;
	}
};

export {tenantConfigReducer};
