import React from 'common/react-vendor';
import {ObjectType} from '../../Data/ConnectionType';

interface IEditable extends ObjectType {
	isDirty: boolean;
}

interface IFetcherContext {
	data: ObjectType;
	setData: React.Dispatch<React.SetStateAction<ObjectType>>;
}

const DefaultContext = {} as IFetcherContext;
const FetcherContext = React.createContext<IFetcherContext>(DefaultContext);

interface IFetcherContextProvider {
	data?: ObjectType;
	children: React.ReactNode;
}

function FetcherContextProvider({
	data: defaultData = {},
	children,
}: IFetcherContextProvider): React.ReactElement {
	const [data, setData] = React.useState(defaultData);
	return (
		<FetcherContext.Provider
			value={{
				data,
				setData,
			}}>
			{children}
		</FetcherContext.Provider>
	);
}

export {FetcherContext, FetcherContextProvider};
export type {IEditable, IFetcherContext, IFetcherContextProvider};
