import React from 'common/react-vendor';

const {useMemo} = React;

export type UseTransformation<State, In, Out> = (
	state: State,
	items: In
) => Out;

// A transformation is a monoid
// pure is defined as a function because typescript's generics aren't as powerful as in some other languages
const pure =
	<S, I>(): UseTransformation<S, I, I> =>
	// @ts-ignore: State isn't used by definition of pure
	(state: S, items: I): I =>
		items;

function append<State, In, Xf, Next>(
	useCurItems: UseTransformation<State, In, Xf>,
	useNextItems: UseTransformation<State, Xf, Next>
): UseTransformation<State, In, Next> {
	return (state, prev) => {
		const cur = useCurItems(state, prev);
		return useNextItems(state, cur);
	};
}

// pipeline is sugar for append
type Pipeline<State, In, Out> = UseTransformation<State, In, Out> & {
	append<Selected, Next>(
		selector: (s: State) => Selected,
		useNextItems: UseTransformation<Selected, Out, Next>
	): Pipeline<State, In, Next>;
};

function createPipeline<State, In, Out>(
	useItems: UseTransformation<State, In, Out>
): Pipeline<State, In, Out> {
	type P = Pipeline<State, In, Out>;
	// the useItems param is always defined in calls to append or pure, so
	// mutating it will never leak out of this file
	// eslint-disable-next-line no-param-reassign
	(useItems as P).append = (selector, useNextItems) =>
		createPipeline(
			append(useItems, (state, items) => useNextItems(selector(state), items))
		);
	return useItems as P;
}

export function pipeline<State, In>(): Pipeline<State, In, In> {
	return createPipeline(pure());
}

export function filter<State, Item>(
	makePred: (s: State) => (i: Item) => boolean
): UseTransformation<State, Item[], Item[]> {
	return (state, items) => {
		return useMemo(() => items.filter(makePred(state)), [state, items]);
	};
}

export function sort<State, Item>(
	makeCmp: (s: State) => (a: Item, b: Item) => number
): UseTransformation<State, Item[], Item[]> {
	return (state, items) => {
		return useMemo(() => [...items].sort(makeCmp(state)), [items, state]);
	};
}

export function map<State, In, Out>(
	makeProject: (s: State) => (i: In) => Out
): UseTransformation<State, In[], Out[]> {
	return (state, items) => {
		return useMemo(() => items.map(makeProject(state)), [state, items]);
	};
}

export function reduce<State, In, Out>(
	makeReducer: (s: State) => (acc: Out, cur: In) => Out,
	init: Out
): UseTransformation<State, In[], Out> {
	return (state, items) => {
		return useMemo(
			() => items.reduce(makeReducer(state), init),
			[state, items]
		);
	};
}
