import LocalStorageUtility from 'common/widgets/utilities/local-storage.utility';

const getIdentifier = (): string | undefined => {
	return LocalStorageUtility.getClientSession()?.Identifier;
};

const getTenantId = (): string | undefined => {
	return LocalStorageUtility.getClientSession()?.Tenant?.Identifier;
};

const getLongFormTenantId = (): string | undefined => {
	return `${getIdentifier()}.${getTenantId()}`;
};

const getTenantName = (): string | undefined => {
	return LocalStorageUtility.getClientSession()?.Tenant?.DisplayName;
};
const getUserEmail = (): string | undefined => {
	return LocalStorageUtility.getClientSession()?.EmailAddress;
};
const getRegisteredTime = (): number | undefined => {
	return LocalStorageUtility.getClientSession()?.Tenant?.RegisteredTime;
};

export default {
	getIdentifier,
	getTenantId,
	getLongFormTenantId,
	getTenantName,
	getUserEmail,
	getRegisteredTime,
};

export {getUserEmail};
