import React from 'common/react-vendor';
import {DialogButton} from 'common/composite-uux-components/dialog-drawer/DialogDrawer';
import {DNBDialog, DNBTypography} from 'common/dnb-uux-vendor';
import {ICell} from '../../JourneyStageConfiguration/Component/EditableCell';
import {IAction, IDialog} from './DialogType';

type IDuplicateDialog = ICell<boolean> & IAction & IDialog;

enum DuplicateDialogTitle {
	Segment = 'Duplicate a Segment',
	Stage = 'Duplicate a Stage',
	JourneyStageMap = 'Duplicate a Buyer Journey Map',
}

const DuplicateJourneyMapMessage = (name: string): string =>
	`Are you sure you want to duplicate the buyer journey map "${name}"?`;
const DuplicateJourneyStageMessage = (name: string): string =>
	`Are you sure you want to duplicate the journey stage "${name}"?`;
const DuplicateSegmentMessage = (name: string): string =>
	`Are you sure you want to duplicate the segment "${name}"?`;

const DuplicateDialog = ({
	data: open,
	setData: setOpen,
	title,
	description,
	isProcessing,
	onOkay,
	onCancel,
}: IDuplicateDialog): React.ReactElement => {
	return (
		<DNBDialog
			variant='default'
			title={title}
			open={open}
			onClose={() => {
				setOpen(false);
				onCancel?.();
			}}
			primaryCTALabel={DialogButton.Duplicate}
			primaryCTA={() => {
				setOpen(false);
				onOkay?.();
			}}
			disabledPrimaryCTA={isProcessing}
			disabledSecondaryCTA={isProcessing}
			secondaryCTALabel={DialogButton.Cancel}
			secondaryCTA={() => setOpen(false)}>
			<DNBTypography variant='body'>{description}</DNBTypography>
		</DNBDialog>
	);
};

export {
	DuplicateDialog,
	DuplicateDialogTitle,
	DuplicateJourneyMapMessage,
	DuplicateJourneyStageMessage,
	DuplicateSegmentMessage,
};
