// Initial load of the application
const mainApp = angular
	.module('loginApp', [
		'templates-main',
		'ui.router',
		'ui.bootstrap',
		'common.banner',
		'common.modal',
		'common.notice',
		'common.exceptions',
		'login',
		'login.form',
		'login.forgot',
		'login.passwordless',
		'login.idaasResetPasswordMessage',
		'login.idaasForgotPasswordMessage',
		'login.idaasLoginWithDnbCredentialsMessage',
		'login.tenants',
		'login.saml',
		'login.saml.logout',
		'login.saml.error',
	])
	.config(function ($httpProvider) {
		if (!$httpProvider.defaults.headers.get) {
			$httpProvider.defaults.headers.get = {};
		}

		$httpProvider.defaults.headers.get['If-Modified-Since'] =
			'Mon, 26 Jul 1997 05:00:00 GMT';
		$httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
		$httpProvider.defaults.headers.get.Pragma = 'no-cache';
	})
	.config(function ($httpProvider) {
		$httpProvider.interceptors.push('authInterceptor');
	})
	.factory('authInterceptor', function ($rootScope, $q) {
		return {
			response(response) {
				if (response.status === 401) {
					// handle the case where the user is not authenticated
				}

				return response || $q.when(response);
			},
		};
	});
