import template from './percent-edit.component.html';
import {
	changeCompare,
	changeValue,
	getDirection,
	getErrorMsg,
	getNumericalPeriodConfig,
	getChangeValue,
	getCompareList,
	isPeriodRangeValid,
	resetChangeValues,
	getDirectionList,
	setDirection,
} from 'common/components/datacloud/query/advanced/tree/percent/percentStore.helpers';

angular
	.module('common.datacloud.query.builder.tree.edit.percent.edit', [])
	.component('percentEditAttribute', {
		template,

		bindings: {
			type: '<',
			bucketrestriction: '=',
			form: '=',
			vm: '=',
		},

		controller: function ($timeout) {
			const self = this;

			function changePeriodView() {
				switch (self.periodModel) {
					case 'AS_MUCH_AS': {
						self.showFromPeriod = true;
						self.showToPeriod = false;
						break;
					}
					case 'AT_LEAST': {
						self.showFromPeriod = true;
						self.showToPeriod = false;
						break;
					}
					case 'BETWEEN': {
						self.showFromPeriod = true;
						self.showToPeriod = true;
						break;
					}
					default: {
						self.showFromPeriod = false;
						self.showToPeriod = false;
					}
				}
			}

			function initValues() {
				const fromTmp = getChangeValue({
					compareType: self.periodModel,
					bucket: self.bucketrestriction.bkt,
					index: 0,
				});
				const toTmp = getChangeValue({
					compareType: self.periodModel,
					bucket: self.bucketrestriction.bkt,
					index: 1,
				});
				self.numericalConfig.from.value =
					fromTmp != null ? Number(fromTmp) : undefined;
				self.numericalConfig.to.value =
					toTmp != null ? Number(toTmp) : undefined;
			}

			function resetValues() {
				self.showFromPeriod = false;
				self.showToPeriod = false;
				self.bucketrestriction.bkt = resetChangeValues(
					self.bucketrestriction.bkt
				);
				initValues();
			}

			this.$onInit = function () {
				self.periodChanging = false;
				self.showFromPeriod = false;
				self.showToPeriod = false;
				self.directionList = getDirectionList();
				self.periodList = getCompareList();
				self.numericalConfig = getNumericalPeriodConfig();
				self.periodModel = self.bucketrestriction.bkt.Chg.Cmp;
				self.directionModel = getDirection(self.bucketrestriction.bkt);
				// self.bucketrestriction.bkt.Chg.Direction = direction;
				changePeriodView();
				initValues();
			};

			this.getPeriodNumericalConfString = function () {
				if (!self.numericalConfig) {
					self.numericalConfig = getNumericalPeriodConfig();
				}
				return self.numericalConfig;
			};

			this.callbackChangedValue = function ({index, value}) {
				self.bucketrestriction.bkt.Chg.Vals = changeValue({
					compareType: self.periodModel,
					index,
					values: self.bucketrestriction.bkt.Chg.Vals,
					value: value,
				});
			};

			this.changeDirection = function () {
				setDirection(self.bucketrestriction.bkt, self.directionModel);
			};

			this.changePeriod = function () {
				self.periodChanging = true;
				changePeriodView();
				self.bucketrestriction.bkt = changeCompare(
					self.bucketrestriction.bkt,
					self.periodModel
				);
				resetValues();
				$timeout(function () {
					changePeriodView();
					self.periodChanging = false;
				}, 0);
			};

			this.isPeriodRangeValid = function () {
				return isPeriodRangeValid(self.form);
			};

			this.getErrorMsg = function () {
				return getErrorMsg(self.showFromPeriod, self.showToPeriod);
			};
		},
	});
