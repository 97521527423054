import {
	DNBTextField,
	InputAdornment,
	SearchOutlinedIcon,
} from 'common/dnb-uux-vendor';
import React from 'common/react-vendor';

interface IConnectionSearch {
	search: string;
	isHasIcon?: boolean;
	placeHolder?: string;
	onSearchChanged: (val: string) => void;
}

const ConnectionSearch = ({
	search,
	isHasIcon,
	placeHolder,
	onSearchChanged,
}: IConnectionSearch): React.ReactElement => {
	return (
		<DNBTextField
			InputProps={{
				startAdornment: isHasIcon ? (
					<InputAdornment position='start'>
						<SearchOutlinedIcon />
					</InputAdornment>
				) : (
					<></>
				),
			}}
			sx={{
				width: '240px',
			}}
			value={search}
			size='compact'
			placeholder={placeHolder}
			onChange={(e) => onSearchChanged(e.target.value)}
		/>
	);
};

export {ConnectionSearch};
export type {IConnectionSearch};
