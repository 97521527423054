import {
	ROUTE_PAGE,
	AdminRouteMap,
	NoAdminRouteMap,
	setMultipleStates,
} from 'common/app/utilities/AdminPageUtility';
import template from './activate.component.html';
import {loadCategoryMetadata} from '../../datacloud.service.vanilla';
import {Banner} from '../../../../app/services/BannerService';
import AttrConfigService from '../AttrConfigService';
import AttrConfigStore from '../AttrConfigStore';

angular
	.module('common.attributes.activate', ['mainApp.core.redux'])
	.config(function ($stateProvider) {
		$stateProvider.states = setMultipleStates;

		const activeAttributesNames = [
			AdminRouteMap.get(ROUTE_PAGE.ACTIVATE_ATTRIBUTES),
			NoAdminRouteMap.get(ROUTE_PAGE.ACTIVATE_ATTRIBUTES),
		];

		$stateProvider.states(activeAttributesNames, {
			url: '/activate/:category/:subcategory',
			params: {
				category: {
					dynamic: false,
					value: 'My Attributes',
				},
				subcategory: {
					dynamic: true,
					value: '',
				},
			},
			onExit: function () {
				AttrConfigStore.reset();
				Banner.clear('info');
			},
			resolve: {
				categoryMetadata: loadCategoryMetadata,
				showPremiumBadge: () => true,
				overview: async () => {
					const {data} = await AttrConfigService.getOverview('activation');

					const Selections = data.Selections.sort(
						(a, b) =>
							(a.metadata?.uIOrderAttrAdminPremiumAttribute ?? Infinity) -
							(b.metadata?.uIOrderAttrAdminPremiumAttribute ?? Infinity)
					);

					return {Selections};
				},
				config: [
					'$state',
					'$stateParams',
					($state, $stateParams) => {
						const {category} = $stateParams;

						AttrConfigStore.set('category', category);

						return AttrConfigService.getConfig('activation', category)
							.then((res) => res.data || [])
							.then((data) => {
								AttrConfigStore.setData('config', data);
								return data;
							});
					},
				],
				reportstatus: () =>
					AttrConfigService.getProfileReportStatus().then(
						({data}) => data || []
					),
			},
			views: {
				'subsummary@': 'attrSubheader',
				'main@': 'attrActivate',
				'adminSidebar@': {component: 'adminSidebar'},
			},
		});
	})
	.component('attrActivate', {
		template,
		bindings: {
			overview: '<',
			categoryMetadata: '<',
			config: '<',
			reportstatus: '<',
		},
		controller($q, $state, $stateParams, $scope) {
			const vm = this;

			vm.store = AttrConfigStore;
			vm.filters = vm.store.get('filters');
			vm.params = $stateParams;
			vm.filtersChange = (f) => {
				AttrConfigStore.set('filters', f);
				vm.filters = f;
				$scope.$apply();
			};
			vm.showModal = false;

			vm.save = function () {
				vm.store.saveConfig($state.current.name, $stateParams).then(() => {
					$state.reload();
				});
			};

			vm.uiCanExit = () => {
				if (!vm.store.isChanged()) {
					return true;
				}

				const deferred = $q.defer();

				vm.onConfirm = () => {
					deferred.resolve(true);
					vm.showModal = false;
				};
				vm.closeModal = () => {
					deferred.reject('user cancelled action');
					vm.showModal = false;
					HideSpinner();
				};

				vm.showModal = true;

				return deferred.promise;
			};

			const {metadata} = AttrConfigStore.getActiveTabData(
				$state.current.name,
				$stateParams
			);

			if (metadata?.isPremium)
				Banner.info({
					message:
						'Please note these attributes have limits.<br>Premium attributes count against your licensed limit and cannot be freely activated or deactivated. If you make a mistake and need to make changes, please contact support and we will be happy to assist you.<br>Non-premium attributes have system limits on the total number that can be activated. Non active attributes will not count against your limit.',
				});

			this.$onDestroy = () => Banner.clear('info');
		},
	});
