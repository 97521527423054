import React from 'react';
import Stack from '@mui/material/Stack';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import {DNBTypography, DNBButton} from '@dnb-uux-design-system/react';

export type AlertBoxProps = {
	variant?: 'success' | 'info' | 'warning' | 'error';
	isWrapped?: boolean;
	message: React.ReactNode;
	hasCloseIcon?: boolean;
	handleClose?: () => void;
	labelCTA?: React.ReactNode;
	loadingCTA?: boolean;
	disabledCTA?: boolean;
	onClickCTA?: () => void;
	labelCTA2?: React.ReactNode;
	loadingCTA2?: boolean;
	disabledCTA2?: boolean;
	onClickCTA2?: () => void;
};

const StyledButton = styled(DNBButton)(({theme}) => ({
	'outline': 'none !important',
	'fontSize': '14px',
	'textDecoration': 'underline',
	'&.Mui-disabled': {
		color: theme.colors.ColorGrayPrimary,
	},
	'&.MuiButton-text': {
		color: theme.colors.ColorGrayPrimary,
	},
}));

const CTAButtons: React.FC<
	React.PropsWithChildren<{
		labelCTA?: React.ReactNode;
		loadingCTA: boolean;
		disabledCTA: boolean;
		onClickCTA?: () => void;
		labelCTA2?: React.ReactNode;
		loadingCTA2: boolean;
		disabledCTA2: boolean;
		onClickCTA2?: () => void;
	}>
> = ({
	labelCTA,
	loadingCTA,
	disabledCTA,
	onClickCTA,
	labelCTA2,
	loadingCTA2,
	disabledCTA2,
	onClickCTA2,
}) => {
	if (labelCTA || labelCTA2) {
		return (
			<>
				{labelCTA && (
					<StyledButton
						variant='text'
						color='inherit'
						size='small'
						onClick={!loadingCTA ? onClickCTA : () => null}
						disabled={disabledCTA}>
						{labelCTA && loadingCTA ? <CircularProgress size={16} /> : labelCTA}
					</StyledButton>
				)}
				{labelCTA2 && (
					<StyledButton
						variant='text'
						color='inherit'
						size='small'
						onClick={!loadingCTA2 ? onClickCTA2 : () => null}
						disabled={disabledCTA2}>
						{loadingCTA2 ? <CircularProgress size={16} /> : labelCTA2}
					</StyledButton>
				)}
			</>
		);
	}
	return null;
};

const CTAWrapper = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isWrapped' && prop !== 'hasCloseIcon',
})<{isWrapped: boolean; hasCloseIcon: boolean}>(
	({theme, isWrapped, hasCloseIcon}) => ({
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		marginRight: isWrapped && hasCloseIcon ? '-24px' : undefined,
		marginTop: isWrapped ? theme.spacing(4) : undefined,
	})
);

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref
) {
	return <MuiAlert elevation={6} ref={ref} variant='outlined' {...props} />;
});

const StyledAlert = styled(Alert, {
	shouldForwardProp: (prop) => prop !== 'isWrapped',
})<{isWrapped: boolean}>(({isWrapped}) => ({
	'alignItems': isWrapped ? undefined : 'center',
	'minHeight': isWrapped ? '104px' : undefined,
	'boxShadow': 'none',
	'& .MuiAlert-message': {
		width: '100%',
		padding: '0',
		display: isWrapped ? '' : 'flex',
		justifyContent: isWrapped ? '' : 'space-between',
	},
	'& .MuiButton-root': {
		color: 'inherit',
		textDecoration: 'underline',
	},
}));

export const AlertBox: React.FC<React.PropsWithChildren<AlertBoxProps>> = (
	props
) => {
	const {
		variant = 'success',
		isWrapped = false,
		message,
		handleClose,
		hasCloseIcon = true,
		labelCTA,
		loadingCTA = false,
		disabledCTA = false,
		onClickCTA,
		labelCTA2,
		loadingCTA2 = false,
		disabledCTA2 = false,
		onClickCTA2,
	} = props;

	return (
		<Stack spacing={2} sx={{width: '100%'}}>
			<Box
				sx={(theme) => ({
					'bgcolor': 'background.paper',
					'borderRadius': 2,
					'& .MuiAlert-action': {
						paddingTop: '1px',
						paddingLeft: '0',
						marginBottom: isWrapped ? 14 : undefined,
					},
					'& .MuiAlert-outlinedSuccess': {
						backgroundColor: theme.colors.ColorNotificationSuccessAlpha1,
						border: `1px solid ${theme.colors.ColorNotificationSuccess}`,
						borderRadius: 2,
					},
					'& .MuiAlert-outlinedError': {
						backgroundColor: theme.colors.ColorNotificationErrorAlpha1,
						border: `1px solid ${theme.colors.ColorNotificationError}`,
						borderRadius: 2,
					},
					'& .MuiAlert-outlinedWarning': {
						backgroundColor: theme.colors.ColorNotificationWarningAlpha1,
						border: `1px solid ${theme.colors.ColorNotificationWarning}`,
						borderRadius: 2,
					},
					'& .MuiAlert-outlinedInfo': {
						backgroundColor: theme.colors.ColorPrimaryBlueAlpha1,
						border: `1px solid ${theme.colors.ColorNotificationInfo}`,
						borderRadius: 2,
					},
				})}>
				<StyledAlert
					isWrapped={isWrapped}
					onClose={(event) => {
						event.stopPropagation();
						if (hasCloseIcon && handleClose) {
							handleClose();
						}
					}}
					severity={variant}
					iconMapping={{
						success: <CheckCircleOutlineIcon fontSize='inherit' />,
					}}>
					<DNBTypography alignSelf='center' variant='compact-medium'>
						{message}
					</DNBTypography>
					<CTAWrapper isWrapped={isWrapped} hasCloseIcon={hasCloseIcon}>
						<CTAButtons
							labelCTA={labelCTA}
							loadingCTA={loadingCTA}
							disabledCTA={disabledCTA}
							onClickCTA={onClickCTA}
							labelCTA2={labelCTA2}
							loadingCTA2={loadingCTA2}
							disabledCTA2={disabledCTA2}
							onClickCTA2={onClickCTA2}
						/>
					</CTAWrapper>
				</StyledAlert>
			</Box>
		</Stack>
	);
};
