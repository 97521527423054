import httpService from 'app/http/http-service';
import Observer from 'app/http/observer';
import { dispatchQueryPropertyValue } from 'common/stores/query';
import {store} from 'store';
import {v4 as uuid} from 'uuid';
import {getSegmentDisplayDataType, segmentFormat} from './SegmentationUtility';

const namespace = `segments-${uuid()}`;

const CONST = {
	INITIALIZE: 'INITIALIZE',
	SET_CONTEXT: 'SET_CONTEXT',
	GET_ENRICHMENTS: 'GET_ENRICHMENTS',
	GET_SEGMENTS: 'GET_SEGMENTS',
	GET_SEGMENT: 'GET_SEGMENT',
	SET_SEGMENT: 'SET_SEGMENT',
	GET_SUBSEGMENTS: 'GET_SUBSEGMENTS',
	GET_SEGMENT_CHART_DATA: 'GET_SEGMENT_CHART_DATA',
	CLEAR_SEGMENTS: 'CLEAR_SEGMENTS',
	GET_CUBE: 'GET_CUBE',
	CLEAR_CUBE: 'CLEAR_CUBE',
	TOGGLE_PANEL: 'TOGGLE_PANEL',
	GET_COLLECTION_STATUS: 'GET_COLLECTION_STATUS',
};

const initialState = {
	context: null,
	segments: null,
	enrichments: [],
	cube: {},
	filter_panel: false,
	collection_status: {},
};

const prepSegments = (segments) => {
	let map = {};

	const recursive = (branch) => {
		if (!branch) {
			return;
		}

		Object.keys(branch).forEach((property) => {
			const value = branch[property];

			if (typeof value == 'object') {
				if (!Array.isArray(value)) {
					recursive(value);
				} else {
					value.forEach((element, index) => {
						if (typeof element != 'string') {
							recursive(element);
						}
					});
				}
			}

			if (property == 'attr') {
				const split = (value || '').split('.');
				const type = split[0];
				const attribute = split.length > 1 ? split[1] : false;

				if (attribute) {
					switch (type) {
						case 'Rating':
						case 'PurchaseHistory':
							break;
						default:
							map[attribute.replace(/_/g, ' ')] = true;
					}
				}
			}
		});
	};

	const getSegmentDisplayName = (name, arr) => {
		const find = arr.filter((item) => item.name === name);
		if (find && find.length === 1) {
			return find[0].display_name;
		}
		return name;
	};

	segments.forEach((segment, index, arr) => {
		map = {};

		segment.accounts = segment.accounts || 0;
		segment.contacts = segment.contacts || 0;
		segment.dataType = getSegmentDisplayDataType(segment.dataType);

		recursive(segment.account_raw_restriction || {});
		recursive(segment.contact_raw_restriction || {});

		segment.attributes = Object.keys(map).join(',');

		if (segment.subSegments && segment.subSegments.length > 0) {
			const subSegmentsDisplayName = segment.subSegments.map((sub) =>
				getSegmentDisplayName(sub, arr)
			);
			segment.subSegmentsDisplayName = subSegmentsDisplayName;
		}
		if (segment.parentSegments && segment.parentSegments.length > 0) {
			const parentSegmentsDisplayName = segment.parentSegments.map((sub) =>
				getSegmentDisplayName(sub, arr)
			);
			segment.parentSegmentsDisplayName = parentSegmentsDisplayName;
		}
	});

	return segments;
};

export const actions = {
	initialize: (payload) => {
		return store.dispatch({
			type: CONST.INITIALIZE,
			namespace,
		});
	},
	setContext: (payload) => {
		return store.dispatch({
			type: CONST.SET_CONTEXT,
			payload,
			namespace,
		});
	},
	getEnrichments: (enrichments) => {
		if (enrichments.length > 0) {
			return store.dispatch({
				type: CONST.GET_ENRICHMENTS,
				payload: enrichments,
				namespace,
			});
		}

		httpService.get(
			'/pls/datacollection/attributes',
			new Observer((response) => {
				store.dispatch({
					type: CONST.GET_ENRICHMENTS,
					payload: response.data,
					namespace,
				});
			})
		);
	},
	getSegments: () => {
		httpService.get(
			'/pls/datacollection/segments',
			new Observer((response) => {
				const _segments = prepSegments(response.data);
				const segments = [];

				/**
				 * surfacing team for filter
				 */
				_segments.forEach((segment) => {
					const {systemType} = segment?.listSegment || '';
					if (systemType !== 'SSVI') {
						segments.push(segmentFormat(segment));
					}
				});

				store.dispatch({
					type: CONST.GET_SEGMENTS,
					payload: segments || [],
					namespace,
				});
				dispatchQueryPropertyValue('segments', segments);
			})
		);
	},
	switchSegment: (segment, obs) => {
		httpService.post('/pls/datacollection/segments/switch', segment, obs);
	},
	editSegment: (segment, callBack) => {
		httpService.post(
			'/pls/datacollection/segments',
			segment,
			new Observer((response) => {
				store.dispatch({
					type: CONST.SET_SEGMENT,
					payload: response.data || {},
					namespace,
				});
				if (callBack) callBack();
			})
		);
	},
	getSubSegments: (name) => {
		httpService.get(
			`/pls/datacollection/segments/${name}/subsegments`,
			new Observer((response) => {
				store.dispatch({
					type: CONST.GET_SUBSEGMENTS,
					payload: response.data || {},
					namespace,
				});
			})
		);
	},
	getSegment: (name) => {
		httpService.get(
			`/pls/datacollection/segments/${name}`,
			new Observer((response) => {
				store.dispatch({
					type: CONST.GET_SEGMENT,
					payload: response.data || {},
					namespace,
				});
			})
		);
	},
	getSegmentChartData: (name) => {
		httpService.get(
			`/pls/datacollection/segments/stats/${name}`,
			new Observer((response) => {
				store.dispatch({
					type: CONST.GET_SEGMENT_CHART_DATA,
					payload: response.data?.StatsCubes || {},
					namespace,
				});
			})
		);
	},
	getCollectionStatus: () => {
		httpService.get(
			'/pls/datacollection/status',
			new Observer((response) => {
				store.dispatch({
					type: CONST.GET_COLLECTION_STATUS,
					payload: response.data,
					namespace,
				});
			}),
			{
				ErrorDisplayMethod: 'Banner',
				ErrorDisplayOptions: '{"title": "Warning"}',
			}
		);
	},
	getCube: (cube) => {
		if (cube && cube.data) {
			return store.dispatch({
				type: CONST.GET_CUBE,
				payload: cube.data,
				namespace,
			});
		}

		httpService.get(
			'/pls/datacollection/statistics/cubes',
			new Observer((response) => {
				store.dispatch({
					type: CONST.GET_CUBE,
					payload: response.data,
					namespace,
				});
			})
		);
	},
	clearSegments: () => {
		return store.dispatch({
			type: CONST.CLEAR_SEGMENTS,
			namespace,
		});
	},
	clearCube: () => {
		return store.dispatch({
			type: CONST.CLEAR_CUBE,
			namespace,
		});
	},
	togglePanel: () => {
		return store.dispatch({
			type: CONST.TOGGLE_PANEL,
			namespace,
		});
	},
};

export const reducer = (state = initialState, action) => {
	if (action.namespace !== namespace) {
		return state;
	}

	switch (action.type) {
		case CONST.INITIALIZE:
			return initialState;
		case CONST.SET_CONTEXT:
			return {
				...state,
				context: action.payload,
			};
		case CONST.GET_ENRICHMENTS:
			return {
				...state,
				enrichments: action.payload,
			};
		case CONST.GET_SEGMENTS:
			return {
				...state,
				segments: action.payload,
			};
		case CONST.GET_SEGMENT:
			return {
				...state,
				segment: action.payload,
			};
		case CONST.SET_SEGMENT:
			return {
				...state,
				segment: action.payload,
			};
		case CONST.GET_SUBSEGMENTS:
			return {
				...state,
				subSegments: action.payload,
			};
		case CONST.GET_SEGMENT_CHART_DATA:
			return {
				...state,
				segmentChartData: action.payload,
			};
		case CONST.CLEAR_SEGMENTS:
			return {
				...state,
				segments: [],
			};
		case CONST.GET_CUBE:
			return {
				...state,
				cube: action.payload,
			};
		case CONST.CLEAR_CUBE:
			return {
				...state,
				cube: {},
			};
		case CONST.TOGGLE_PANEL:
			return {
				...state,
				filter_panel: !state.filter_panel,
			};
		case CONST.GET_COLLECTION_STATUS:
			return {
				...state,
				collection_status: action.payload,
			};
		default:
			return state;
	}
};
