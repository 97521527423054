import {Play} from 'atlas/playbook/content/metadataLiveramplaunch/types';
import {store} from 'store';
import {clearCgTalkingPointStore} from '../cgTalkingPoint';
import {
	clearPlaybookWizardStore,
	setPlaybookWizardPropertyValue,
	setPlaybookWizardValidationValue,
} from './actions';
import type {PlaybookWizardState, PlaybookWizardValidation} from './types';

// #region get from store
export const getPlaybookWizardStore = (): PlaybookWizardState | undefined =>
	store.getState().playbookWizard;

export const getPlaybookWizardProperty = <PlaybookWizardProperty>(
	key: keyof PlaybookWizardState
): PlaybookWizardProperty =>
	getPlaybookWizardStore()?.[key] as PlaybookWizardProperty;
// #endregion get from store

// #region setters
export const dispatchClearPlaybookWizardStore = (): void => {
	store.dispatch(clearPlaybookWizardStore());

	store.dispatch(clearCgTalkingPointStore());
};

export const dispatchPlaybookWizardPropertyValue = <PlaybookWizardValue>(
	key: keyof PlaybookWizardState,
	value: PlaybookWizardValue
): void => {
	store.dispatch(setPlaybookWizardPropertyValue(key, value));
};

export const setPlaybookWizardSettings = (
	play: Partial<Play> = {},
	unset?: boolean
): void => {
	const newSettings = {
		...getPlaybookWizardProperty<Partial<Play>>('settings'),
	};

	// eslint-disable-next-line guard-for-in
	for (const key in play) {
		const settingsProperty = key as keyof Play;

		const value = play[settingsProperty];

		if (unset) {
			delete newSettings[settingsProperty];
		} else {
			newSettings[settingsProperty]! = value as never;
		}
	}

	dispatchPlaybookWizardPropertyValue<Partial<Play>>('settings', newSettings);
};

export const dispatchPlaybookWizardValidationValue = (
	key: keyof PlaybookWizardValidation,
	value: boolean
): void => {
	store.dispatch(setPlaybookWizardValidationValue(key, value));
};
// #endregion setters
