// New version of https://github.com/dnb-main/lat-leui/blob/develop/projects/atlas/app/modelsOld/modals/CreateSegmentModal.js
import isEmpty from 'lodash/isEmpty';
import {useSelector} from 'react-redux';
import {QueryState} from 'common/stores/query/types';
import React, {NgStateService} from 'common/react-vendor';
import {
	CloseIcon,
	DNBTextField,
	DNBButton,
	DNBInputLabel,
	DNBSelect,
	DNBSelectOption,
	DNBTypography,
} from 'common/dnb-uux-vendor';
import {useGetTeams} from 'common/app/hooks/useApi/useTeams';
import {Team} from 'atlas/playbook/content/metadataLiveramplaunch/types';
import {SegmentNgState} from 'atlas/helpers/NavigateHelper';
import {useCreateOrUpdateSegment} from 'common/app/hooks/useApi/useSegments';
import {CreateOrUpdateSegmentResponse} from 'common/components/datacloud/segment/segment.queries';
import NoticeService from 'common/components/notice/NoticeService';
import {setKeyCacheData} from 'common/app/utilities/cacheUtility/cacheUtility';
import {isSegmentationV2Enabled} from 'common/stores/tenantConfig';
import {getQueryProperty, setPublicProperty} from 'common/stores/query';
import {ISegment} from 'atlas/data/SegmentConst';
import {
	StyledFreeStyleDialog,
	StyledHeaderDiv,
	StyledTitleDiv,
	StyledIconButton,
	StyledFreeStyleDialogContent,
	StyledFormContainer,
	StyledFreeStyleStickyFooter,
	StyledSelectContainer,
} from './SaveSegmentDialog.styled';
import {
	descriptionMaxLength,
	segmentNameMaxLength,
	successMessage,
} from './saveSegmentDialog.constants';
import {
	getDefaultSegmentName,
	getSubmitLabel,
	getTitle,
	mapSegment,
} from './SaveSegmentDialog.functions';

const {useState, useEffect, useMemo} = React;

export type FormData = {
	segmentName: string;
	team: Team | null;
	description: string;
};

interface SaveSegmentDialogProps {
	ngState: NgStateService;
	open: boolean;
	handleClose(): void;
}

export const SaveSegmentDialog = ({
	ngState,
	open,
	handleClose,
}: SaveSegmentDialogProps): React.ReactElement => {
	const defaultSegmentName = useMemo<string>(() => getDefaultSegmentName(), []);

	const queryStore = useSelector((state: {query?: QueryState}) => state?.query);

	const [formData, setFormData] = useState<FormData>({
		segmentName: defaultSegmentName,
		team: null,
		description: '',
	});

	const {isFetching: isGettingTeams, data: teams} = useGetTeams();

	const [isCreateSegmentEnabled, setIsCreateSegmentEnabled] =
		useState<boolean>(false);

	const {customParentSegmentName} = getQueryProperty<ISegment>('segment') ?? {};

	const handlePostSegmentSuccess = (
		response: CreateOrUpdateSegmentResponse
	): void => {
		if (response.success) {
			// TODO: Delete this once we fully migrate to React, and we don't have that mix bag of angular/react :/
			// Dark magic to be able to persist the segment in the react component AccountFitModelingWizard
			window.postMessage({segment: response.data});

			/**
			 * TODO: Hacky solution for unstable navbar in app...
			 * PLS-29220
			 * https://github.com/dnb-main/lat-leui/blob/develop/projects/atlas/app/navigation/sidebar/sidebar.component.js#L188
			 */
			setKeyCacheData('getSegments', 'sessionStorage', null);

			if (isSegmentationV2Enabled()) {
				NoticeService.success({
					title: successMessage,
				});
			}

			setPublicProperty('enableSaveSegmentButton', false);

			const state = customParentSegmentName
				? SegmentNgState.Subsegments
				: 'home.segments_new';

			ngState.go(
				state,
				{segment: customParentSegmentName},
				{notify: true, reload: true}
			);
		}

		setPublicProperty('enableSaveSegmentButton', false);
		handleClose();
	};

	const {isFetching: isPostingSegment} = useCreateOrUpdateSegment({
		segment: mapSegment({
			defaultSegmentName,
			formData,
			queryStore,
			customParentSegmentName,
		}),
		enabled: isCreateSegmentEnabled,
		onSuccess: handlePostSegmentSuccess,
		onFinally: () => setIsCreateSegmentEnabled(false),
	});

	useEffect(() => {
		if (!isGettingTeams && teams && isEmpty(formData.team)) {
			setFormData((prevState) => ({
				...prevState,
				team: teams.find(({TeamId}) => TeamId === 'Global_Team')!,
			}));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isGettingTeams, teams]);

	const segmentNameRemainingChars =
		segmentNameMaxLength - formData.segmentName.length;
	const descriptionRemainingChars =
		descriptionMaxLength - formData.description.length;

	const {
		current: {name: routeName},
	} = ngState ?? {};

	return (
		<StyledFreeStyleDialog open={open} onClose={handleClose}>
			<StyledHeaderDiv>
				<StyledTitleDiv>
					<DNBTypography variant='h6'>{getTitle(routeName)}</DNBTypography>

					<StyledIconButton onClick={handleClose}>
						<CloseIcon fontSize='small' />
					</StyledIconButton>
				</StyledTitleDiv>
			</StyledHeaderDiv>

			<StyledFreeStyleDialogContent>
				<StyledFormContainer>
					<DNBTextField
						label='Name your Segment'
						size='compact'
						helperText={`${segmentNameRemainingChars} characters remaining`}
						fullWidth
						inputProps={{
							maxLength: segmentNameMaxLength,
						}}
						value={formData.segmentName}
						onChange={({target: {value}}) =>
							setFormData((prevState) => ({
								...prevState,
								segmentName: value,
							}))
						}
					/>

					<div>
						<DNBInputLabel htmlFor='team' size='compact'>
							Team
						</DNBInputLabel>

						<StyledSelectContainer>
							<DNBSelect<string>
								id='team'
								size='compact'
								minWidth='100%'
								disabled={isGettingTeams}
								value={formData.team?.TeamId}>
								{teams?.map((team) => (
									<DNBSelectOption
										key={team.TeamId}
										style={{
											width: '518px',
											zIndex: 2,
										}}
										value={team.TeamId}
										onClick={() =>
											setFormData((prevState) => ({
												...prevState,
												team,
											}))
										}>
										{team.TeamName}
									</DNBSelectOption>
								))}
							</DNBSelect>

							{isGettingTeams && <i className='fa fa-spinner fa-spin' />}
						</StyledSelectContainer>
					</div>

					<DNBTextField
						label='Description'
						InputLabelProps={{
							sx: {
								zIndex: 'auto',
							},
						}}
						helperText={`${descriptionRemainingChars} characters remaining`}
						size='compact'
						multiline
						fullWidth
						InputProps={{
							sx: {
								height: '100px !important',
								textarea: {
									minHeight: '82px',
								},
							},
						}}
						// eslint-disable-next-line react/jsx-no-duplicate-props
						inputProps={{
							maxLength: descriptionMaxLength,
						}}
						value={formData.description}
						onChange={({target: {value}}) =>
							setFormData((prevState) => ({
								...prevState,
								description: value,
							}))
						}
					/>
				</StyledFormContainer>
			</StyledFreeStyleDialogContent>

			<StyledFreeStyleStickyFooter>
				<DNBButton
					variant='secondary'
					size='compact'
					onClick={handleClose}
					disabled={isPostingSegment}>
					Cancel
				</DNBButton>

				<DNBButton
					variant='primary'
					size='compact'
					onClick={() => {
						setIsCreateSegmentEnabled(true);
					}}
					isLoading={isPostingSegment}
					disabled={formData.segmentName.trim().length === 0}>
					{getSubmitLabel(routeName)}
				</DNBButton>
			</StyledFreeStyleStickyFooter>
		</StyledFreeStyleDialog>
	);
};
