import {format} from 'date-fns';
import {PossibleCol} from 'atlas/playbook/content/campaigngenericcolumn';
import {NumericRangeState} from 'common/widgets/dataviews/numeric-range';
import {
	QuickType,
	getTimeFromQuickType,
} from 'common/widgets/dataviews/numeric-range/quickTimeRange.helpers';
import {ICampaignStatus} from './playlistchannels/status';
import {DisplayMode} from './campaignstable/DisplayModeType';

const rowsPerPageOptions = [9, 10, 25, 50];
const rowHeight = 72;
const defaultPageSize = 8;
const pageSizes = [8, 10, 25, 50];

enum DNBSortDirection {
	Ascend = 'asc',
	Descend = 'desc',
}
const CAMPAIGNGROUP_TIME_RANGE_KEY = 'campaignGroup-localTimeRange';
const CAMPAIGN_TIME_RANGE_KEY = 'campaign-localTimeRange';
const CAMPAIGN_CALENDAR_TIME_RANGE_KEY = 'campaign-calendar-localTimeRange';
const CHANNELS_TIME_RANGE_KEY = 'channels-localTimeRange';
const PLACEMENT_TIME_RANGE_KEY = 'placement-localTimeRange';
const ACCOUNT_TIME_RANGE_KEY = 'account-localTimeRange';
const CAMPAIGN_DISPLAYMODE_KEY = 'campaign-DisplayMode';

const handleDateFormat = (time: number): string =>
	new Intl.DateTimeFormat('en-US', {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric',
	}).format(time);

const getAdsDefaultTimeRange = (
	timeRangeKey: string,
	quickOption?: QuickType
): NumericRangeState => {
	const quickOptionTimeRange =
		quickOption &&
		quickOption !== QuickType.Custom &&
		getTimeFromQuickType(quickOption).timeRange;
	if (quickOptionTimeRange) return quickOptionTimeRange;

	const localTimeRange = JSON.parse(
		`${sessionStorage.getItem(timeRangeKey)}`
	) as NumericRangeState;
	const monthAgo = new Date(
		new Date().getTime() - 1000 * 60 * 60 * 24 * 7
	).getTime(); // last 7 days

	return {
		low: localTimeRange?.low || monthAgo,
		high: localTimeRange?.high || new Date().getTime(),
	};
};

const getDateRangeStr = (startTime?: number, endTime?: number): string => {
	if (!startTime || !endTime) return '';
	const startDate = handleDateFormat(startTime).replace(/\//g, '-');
	const endDate = handleDateFormat(endTime).replace(/\//g, '-');
	return startDate === endDate ? startDate : `${startDate} - ${endDate}`;
};

const formatDateStrToUTC = (time: number, isEnd?: boolean): string =>
	time
		? `${format(new Date(time), 'yyyy-MM-dd')}T${
				isEnd ? '23:59:59' : '00:00:00'
		  }Z`
		: '';

interface ColumnCategories<T> {
	categoryName: string;
	columns: PossibleCol<T>[];
}

export const columnsSettings = {
	CPM: {
		display_setting: {
			fundamental_type: 'NUMERIC',
			prefix: '$',
			fixed: 2,
		},
	},
	CPC: {
		display_setting: {
			fundamental_type: 'NUMERIC',
			prefix: '$',
			fixed: 2,
		},
	},
	CTR: {
		display_setting: {
			fundamental_type: 'NUMERIC',
			suffix: '%',
			fixed: 2,
		},
	},
	Cost: {
		display_setting: {
			fundamental_type: 'NUMERIC',
			prefix: '$',
			fixed: 2,
		},
	},
};

// CampaignGroup and view by launch page only has USD keep showing prefix
function mapTableColumns<T>(
	categories: ColumnCategories<T>[],
	columnsSettings: Record<string, Partial<PossibleCol<T>>>,
	doesOnlyUSDCurrency?: boolean
): Record<string, PossibleCol<T>> {
	const columns = categories.reduce(
		(tabColumns, {columns: categoryColumns}) => [
			...tabColumns,
			...categoryColumns,
		],
		[] as PossibleCol<T>[]
	);

	return columns.reduce((dynamicColumns, column) => {
		const {metric_name} = column;

		if (
			columnsSettings.hasOwnProperty(metric_name) &&
			metric_name in columnsSettings
		) {
			const display_setting = {
				...columnsSettings[metric_name as string]?.display_setting,
			};

			if (!doesOnlyUSDCurrency) delete display_setting?.prefix;

			const mergedColumn = {
				...column,
				display_setting: {
					...column.display_setting,
					...display_setting,
				},
			};

			return {
				...dynamicColumns,
				[column.measure_name]: mergedColumn,
			};
		}

		return {...dynamicColumns, [column.measure_name]: column};
	}, {});
}

const handleSumRecords = (
	metricNames: string[],
	metricList: Record<string, number>[]
): Record<string, number> =>
	metricNames.reduce((preRecord, key) => {
		const value = metricList.reduce(
			(sum, metrics) => sum + (metrics[key] || 0),
			0
		);
		return {
			...preRecord,
			[key]: value,
		};
	}, {} as Record<string, number>);

interface LaunchHistory {
	launchTime: number;
	playDisplayName: string;
	playName: string;
	playStatus: ICampaignStatus;
	destinationOrgId: string;
	createdBy: string;
	stats: {
		selectedTargets: number;
		accountsSuppressed: number;
		accountErrors: number;
		contactErrors: number;
		selectedContacts: number;
		contactsSuppressed: number;
		recommendationsLaunched: number;
		contactsWithinRecommendations: number;
	};
	uiLaunchState: string;
	audienceName: string;
	launchType: string;
	systemType: string;
	integrationStatusMonitor: {
		s3Bucket: string;
		errorFile: string;
		deleteFile: string;
		sourceFile: string;
		errorMessage: string;
	};
	segmentName: string;
	destinationSysName: string;
}

const getCampaignsTimeRangeKey = (campaignGroupsFilter: string[]): string => {
	const mode =
		sessionStorage.getItem(CAMPAIGN_DISPLAYMODE_KEY) || DisplayMode.Table;
	if (mode === DisplayMode.Calendar) {
		return CAMPAIGN_CALENDAR_TIME_RANGE_KEY;
	}
	return campaignGroupsFilter && campaignGroupsFilter.length
		? CAMPAIGNGROUP_TIME_RANGE_KEY
		: CAMPAIGN_TIME_RANGE_KEY;
};

export {
	rowsPerPageOptions,
	rowHeight,
	defaultPageSize,
	pageSizes,
	DNBSortDirection,
	CAMPAIGNGROUP_TIME_RANGE_KEY,
	CAMPAIGN_TIME_RANGE_KEY,
	CAMPAIGN_CALENDAR_TIME_RANGE_KEY,
	CHANNELS_TIME_RANGE_KEY,
	PLACEMENT_TIME_RANGE_KEY,
	ACCOUNT_TIME_RANGE_KEY,
	CAMPAIGN_DISPLAYMODE_KEY,
	getAdsDefaultTimeRange,
	handleDateFormat,
	getDateRangeStr,
	mapTableColumns,
	getCampaignsTimeRangeKey,
	handleSumRecords,
	formatDateStrToUTC,
};
export type {LaunchHistory};
