import React, {ReactModal, createRoot} from 'common/react-vendor';
import styles from './SourceModal.module.scss';

export default function SourceModal({
	isOpen = false,
	closable = false,
	onClose,
	style,
	type = '',
	title,
	children,
	footer,
}: {
	isOpen?: boolean;
	closable?: boolean;
	onClose?: () => void;
	style?: React.CSSProperties;
	type?: string;
	title?: React.ReactNode;
	children?: React.ReactNode;
	footer?: React.ReactNode;
}): React.ReactElement {
	return (
		<ReactModal
			id='sourceModal'
			appElement={document.getElementById('outer-wrap')!}
			isOpen={isOpen}
			overlayClassName={styles.mask}
			className={`${styles.modal} ${type && styles[type]}`}
			style={style && {content: style}}>
			<div className={styles.header}>
				{title}
				{closable && (
					<i className={styles.closeButton} onClick={onClose}>
						&times;
					</i>
				)}
			</div>
			<div className={styles.body}>{children}</div>
			{footer && <div className={styles.footer}>{footer}</div>}
		</ReactModal>
	);
}

SourceModal.show = ({
	title,
	type,
	children,
}: {
	title: string;
	type?: string;
	children: React.ReactNode;
}) => {
	const mountPoint = document.body;
	const containerElement = document.createElement('div');
	mountPoint.appendChild(containerElement);

	const root = createRoot(containerElement);

	function closeModal(): void {
		root.unmount();
		mountPoint.removeChild(containerElement);
	}

	root.render(
		<SourceModal
			isOpen
			type={type}
			title={title}
			onClose={() => closeModal()}
			footer={
				<button type='button' onClick={closeModal}>
					Close
				</button>
			}>
			{children}
		</SourceModal>
	);
};
