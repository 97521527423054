import React from 'common/react-vendor';
import {
	DNBButton,
	Box,
	DNBThemeProvider,
	DNBTypography,
} from 'common/dnb-uux-vendor';
import {getAdminRoute, ROUTE_PAGE} from 'common/app/utilities/AdminPageUtility';
import RBAC, {
	AccessGroups,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {
	hasModule,
	isEIFInboundEnabled,
	isEntityMatchEnabled,
	Modules,
} from 'common/stores/tenantConfig';
import NgState from 'common/app/utilities/ng-state';
import {createHooversUrl} from 'atlas/segmentation/SegmentationUtility';
import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';

export const NoData = (): React.ReactElement => {
	const exploreEnabled = hasModule(Modules.EXPLORE);
	const showImportButton = RBAC.userHasAccessLevel(
		AccessGroups.INTERNAL_OR_ADMIN
	);
	const isInboundEIFEnabled = isEIFInboundEnabled();
	const hasInboundAccess = RBAC.hasAccess(
		RBACInterface.EIF_INBOUND_CONNECTIONS,
		RBACActions.VIEW
	);
	const disableImportDataButton = isInboundEIFEnabled && !hasInboundAccess;
	let importState = 'home.importtemplates';
	if (isEntityMatchEnabled()) {
		if (isInboundEIFEnabled && hasInboundAccess) {
			importState = getAdminRoute(ROUTE_PAGE.CONNECTORS);
		} else {
			importState = getAdminRoute(ROUTE_PAGE.IMPORT_DATA);
		}
	}
	// Explore module enabled tenants only
	// Check if templates already exist in the tenant.
	// If templates exist, do not redirect to add a system on click.
	// If templates do not exist, redirect to add a system on click.
	const disableUploadMyOwnDataButton = isInboundEIFEnabled && !hasInboundAccess;
	const [hasTemplates, setHasTemplates] = React.useState<boolean>(false);

	const handleMyOwnDataClick = (): void => {
		if (isInboundEIFEnabled && hasInboundAccess) {
			NgState.getAngularState().go(getAdminRoute(ROUTE_PAGE.CONNECTORS), {});
		} else {
			NgState.getAngularState().go(getAdminRoute(ROUTE_PAGE.IMPORT_DATA), {
				redirectToAddSystem: !hasTemplates,
			});
		}
	};
	const handleGetDnbDataClick = (): void => {
		window.open(createHooversUrl('/datavision/'), '_blank');
	};

	React.useEffect(() => {
		if (exploreEnabled && RBAC.userHasAccessLevel(AccessGroups.ADMINS))
			// eslint-disable-next-line promise/catch-or-return
			axiosInstance
				.get('/pls/cdl/s3import/template?sortBy=SystemDisplay')
				.then(({data}) => {
					setHasTemplates(data.length > 0);
				});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DNBThemeProvider>
			<Box sx={{textAlign: 'center', p: 16}}>
				<DNBTypography variant='h5'>
					{exploreEnabled
						? 'How would you like to import data?'
						: 'There is no data in the system yet.'}
				</DNBTypography>
				{showImportButton && (
					<Box display='flex' justifyContent='center' sx={{p: 8}} gap={4}>
						{exploreEnabled ? (
							<>
								<DNBButton
									variant='primary'
									onClick={handleMyOwnDataClick}
									disabled={disableUploadMyOwnDataButton}>
									Upload my own data
								</DNBButton>
								<DNBButton
									variant='secondary'
									onClick={handleGetDnbDataClick}
									disabled={disableUploadMyOwnDataButton}>
									Get data from D&B
								</DNBButton>
							</>
						) : (
							<DNBButton
								variant='secondary'
								onClick={() => {
									NgState.getAngularState().go(importState);
								}}
								disabled={disableImportDataButton}>
								Import Data
							</DNBButton>
						)}
					</Box>
				)}
			</Box>
		</DNBThemeProvider>
	);
};
