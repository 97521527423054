import {getSegmentExportDownloadLink} from '../../../../../atlas/app/segments/services/SegmentService';

angular
	.module('common.datacloud.explorer.export', [])
	.controller(
		'SegmentExportController',
		function (
			$scope,
			$q,
			$state,
			$stateParams,
			$http,
			SegmentExport
		) {
			const vm = this;
			angular.extend(vm, {
				stateParams: $stateParams,
				segment: $stateParams.segment,
				exportId: $stateParams.exportID,
				segmentExport: SegmentExport,
				showDownloadMessage: false,
				disableDownload: false,
				showErrorMessage: false,
			});

			vm.init = function () {
				vm.exportDownloadLink = getSegmentExportDownloadLink(
					$stateParams.exportID
				);
				if (vm.isExpired()) {
					vm.disableDownload = true;
					vm.showErrorMessage = true;
				}
			};

			vm.isExpired = function () {
				const currentTime = Date.now();
				return currentTime > vm.segmentExport.cleanup_by;
			};

			vm.hideDownloadMessage = function () {
				vm.showDownloadMessage = false;
			};

			vm.init();
		}
	);
