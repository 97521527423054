import React from 'common/react-vendor';
import {
	BucketCmp,
	BucketType,
} from 'common/components/datacloud/query/query.enums';
import {
	ActivityRestriction,
	InputRangeConfig,
} from 'common/components/datacloud/query/query.types';
import {getActivityNumber} from '../../tree.helpers';
import {ScopeChange} from './TreeActivityEdit';
import {collectionCmp} from './treeActivityEdit.constants';

// #region updateNumericalRange
type ReactBooleanDispatch = React.Dispatch<React.SetStateAction<boolean>>;

type UpdateNumericalRangeParams = {
	operation: BucketCmp;
	setShowFromNumerical: ReactBooleanDispatch;
	setShowToNumerical: ReactBooleanDispatch;
	onScopeChange: ScopeChange;
};

export const updateNumericalRange = ({
	operation,
	setShowFromNumerical,
	setShowToNumerical,
	onScopeChange,
}: UpdateNumericalRangeParams): void => {
	switch (operation) {
		case BucketCmp.IN_COLLECTION:
		case BucketCmp.NOT_IN_COLLECTION:
		case BucketCmp.GREATER_OR_EQUAL:
		case BucketCmp.GREATER_THAN:
			setShowFromNumerical(true);
			setShowToNumerical(false);
			break;
		case BucketCmp.LESS_THAN:
		case BucketCmp.LESS_OR_EQUAL:
			setShowFromNumerical(false);
			setShowToNumerical(true);
			break;
		case BucketCmp.GT_AND_LT:
		case BucketCmp.GT_AND_LTE:
		case BucketCmp.GTE_AND_LTE:
		case BucketCmp.GTE_AND_LT:
			setShowFromNumerical(true);
			setShowToNumerical(true);
			break;
		default:
			setShowFromNumerical(false);
			setShowToNumerical(false);
	}

	onScopeChange('vals', []);
};
// #endregion updateNumericalRange

// #region setNumericalRangeInitialState
type SetNumericalRangeInitialStateParams = {
	operation: BucketCmp;
	activityRestriction: ActivityRestriction;
	onScopeChange: ScopeChange;
	setRangeConfig: React.Dispatch<
		React.SetStateAction<InputRangeConfig | undefined>
	>;
	initialRangeConfig?: InputRangeConfig | undefined;
} & UpdateNumericalRangeParams;

export const setNumericalRangeInitialState = ({
	operation,
	activityRestriction,
	onScopeChange,
	setShowFromNumerical,
	setShowToNumerical,
	setRangeConfig,
	initialRangeConfig,
}: SetNumericalRangeInitialStateParams): void => {
	const isCollectionCmp = collectionCmp.includes(operation);

	const fromNumerical = isCollectionCmp
		? activityRestriction.cnt?.[0]
		: getActivityNumber(activityRestriction, 0);

	const toNumerical = getActivityNumber(activityRestriction, 1);

	const rangeConfig: InputRangeConfig = {
		from: {
			name: 'from-numerical',
			value: fromNumerical !== null ? Number(fromNumerical) : undefined,
			index: 0,
			type: BucketType.Numerical,
			step: 1,
			min: initialRangeConfig?.from.min || '0',
		},
		to: {
			name: 'to-numerical',
			value: toNumerical !== null ? Number(toNumerical) : undefined,
			index: 1,
			type: BucketType.Numerical,
			step: 1,
			...(initialRangeConfig?.to?.min
				? {min: initialRangeConfig?.to?.min}
				: {}),
		},
	};

	setRangeConfig(rangeConfig);

	onScopeChange('rangeConfig', rangeConfig);

	updateNumericalRange({
		operation,
		setShowFromNumerical,
		setShowToNumerical,
		onScopeChange,
	});
};
// #endregion setNumericalRangeInitialState
