import React from 'common/react-vendor';
import type {BucketRestriction} from '../../../../query.types';
import {TreeType, TransactionKey} from '../../../../query.enums';
import {removeKey} from '../../tree.helpers';
import {TransactionTimeFrame} from './TransactionTimeFrame';
import {TransactionUnitsPurchased} from './TransactionUnitsPurchased';
import {TransactionAmountSpent} from './TransactionAmountSpent';

const {useEffect, useState} = React;

interface NumericalRangeStatuses {
	isTimeFrameValid: boolean;
	isUnitsPurchasedValid: boolean;
	isAmountSpentValid: boolean;
}

type NumericalRangeStatusesKeys = keyof NumericalRangeStatuses;

interface TransactionEditItemProps {
	type?: TreeType;
	bucketRestriction?: BucketRestriction;
	purchased: string;
	booleanChanged: boolean;
	onBucketRestrictionChange(newBucketRestriction?: BucketRestriction): void;
	onNumericalRangeValidation(isValid: boolean): void;
	setBooleanChanged(newValue: boolean): void;
}

const TransactionEditItem = ({
	type,
	bucketRestriction,
	purchased,
	booleanChanged,
	onBucketRestrictionChange,
	onNumericalRangeValidation,
	setBooleanChanged,
}: TransactionEditItemProps): React.ReactElement => {
	const [numericalRangeStatuses, setNumericalRangeStatuses] =
		useState<NumericalRangeStatuses>({
			isTimeFrameValid: true,
			isUnitsPurchasedValid: true,
			isAmountSpentValid: true,
		});

	useEffect(() => {
		if (booleanChanged) {
			onBucketRestrictionChange(
				removeKey({
					subType: TransactionKey.Amt,
					type,
					bucketRestriction: removeKey({
						bucketRestriction,
						type,
						subType: TransactionKey.Qty,
					}),
				})
			);

			setBooleanChanged(false);
		}

		// This useEffect needs to only run when booleanChanged is set.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [booleanChanged]);

	const handleNumericalRangeValidation = (
		key: NumericalRangeStatusesKeys,
		isValid: boolean
	): void => {
		const newNumericalRangeStatuses = {
			...numericalRangeStatuses,
			[key]: isValid,
		};

		const isTransactionValid = Object.values(newNumericalRangeStatuses).every(
			(status) => status === true
		);

		onNumericalRangeValidation(isTransactionValid);

		setNumericalRangeStatuses(newNumericalRangeStatuses);
	};

	return (
		<div className='transaction-container'>
			<TransactionTimeFrame
				type={type}
				bucketRestriction={bucketRestriction}
				onBucketRestrictionChange={onBucketRestrictionChange}
				onNumericalRangeValidation={handleNumericalRangeValidation}
			/>

			<TransactionUnitsPurchased
				type={type}
				bucketRestriction={bucketRestriction}
				purchased={purchased}
				onBucketRestrictionChange={onBucketRestrictionChange}
				onNumericalRangeValidation={handleNumericalRangeValidation}
			/>

			<TransactionAmountSpent
				type={type}
				bucketRestriction={bucketRestriction}
				purchased={purchased}
				onBucketRestrictionChange={onBucketRestrictionChange}
				onNumericalRangeValidation={handleNumericalRangeValidation}
			/>
		</div>
	);
};

/**
 * TODO: Angular directive components can't use camelCase attributes...
 * Since we are migrating everything to React I feel that ideally
 * we should keep using camelCases for props, that is why I am creating this temporary
 * wrapper to pass the angular attributes to the React component...
 *
 * DONT use this component!
 * Use TransactionEditItem if you are refactoring an angular component to React.
 * This component purpose is only to be use in the temporary
 * transaction-item-edit.component react2angular
 *
 * TODO: DELETE ME Once all instances of <transaction-edit-directive />
 * are removed...
 *
 * TODO: It seems that the only component that uses <transaction-edit-directive />
 * is queryItemEditDirective ('common.datacloud.query.builder.tree.edit').
 * Remove this angular version once the <query-item-edit-directive />
 * gets refactor to React.
 */

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
const TransactionEditItemWithAngularProps = ({
	bucketrestriction,
	type,
	purchased,
	booleanchanged,
	onbucketrestrictionchange,
	onnumericalrangevalidation,
	setbooleanchanged,
}: Record<string, any>): React.ReactElement => (
	<TransactionEditItem
		bucketRestriction={bucketrestriction}
		type={type}
		purchased={purchased}
		booleanChanged={booleanchanged}
		onBucketRestrictionChange={onbucketrestrictionchange}
		onNumericalRangeValidation={onnumericalrangevalidation}
		setBooleanChanged={setbooleanchanged}
	/>
);

export {TransactionEditItem, TransactionEditItemWithAngularProps};
export type {NumericalRangeStatusesKeys};
