import {isEmpty} from 'lodash';
import template from './tree-item.component.html';
import {
	getOperationLabel,
	getOperationValue,
	showTo,
	showType,
} from '../tree.helpers';
import {segmentRelationOptions, segmentEntityOptions} from '../tree.constants';

angular
	.module('common.datacloud.query.builder.tree.info', [])
	.directive('queryItemDirective', function () {
		return {
			restrict: 'E',
			scope: {
				vm: '=',
			},
			template,
			controllerAs: 'vm',
			controller: function ($scope) {
				const vm = $scope.vm;
				vm.chipsOperations = [
					'EQUAL',
					'IN_COLLECTION',
					'NOT_EQUAL',
					'NOT_IN_COLLECTION',
					'CONTAINS',
					'NOT_CONTAINS',
				];

				vm.getOperationLabel = function () {
					return getOperationLabel({
						type: vm.type,
						bucketRestriction: vm.tree.bucketRestriction,
					});
				};

				vm.getOperationValue = function (type, pos) {
					const val = getOperationValue({
						bucketRestriction: vm.tree.bucketRestriction,
						type,
						index: pos,
					});
					return type == 'String' && typeof val == 'string' ? [val] : val;
				};

				vm.isNumericalChips = function () {
					const ret =
						vm.chipsOperations.indexOf(vm.tree.bucketRestriction.bkt.Cmp) > -1;
					return ret;
				};

				vm.getSuffixLabel = function (index) {
					const suffix = showTo(vm.tree.bucketRestriction) ? ' -' : ',';

					return index != vm.getOperationValue('Enum').length - 1 ? suffix : '';
				};

				vm.showTo = function () {
					return showTo(vm.tree.bucketRestriction);
				};

				vm.showItem = function (typeToShow) {
					if (vm.showSegmentationV2 && vm.tree.segmentMemberRestriction) {
						return vm.type === typeToShow;
					}

					const {
						tree: {bucketRestriction},
						type,
					} = vm;

					return showType({
						bucketRestriction,
						type,
						typeToShow,
					});
				};

				vm.getRelationLable = function () {
					const relation = vm.tree.segmentMemberRestriction.relation;
					const relation_options = segmentRelationOptions;
					return relation_options[relation];
				};

				vm.getSegmentEntityLable = function () {
					const entity = vm.tree.segmentMemberRestriction.entity;
					const segmentEntity = segmentEntityOptions;
					return segmentEntity[entity];
				};

				vm.isEmpty = function (values) {
					return isEmpty(values) || isEmpty(values.toLocaleString());
				}

				if (vm.showSegmentationV2) {
					vm.showSegment = vm.tree.segmentMemberRestriction;
				} else {
					vm.showSegment = false;
				}
			},
		};
	});
