import {isEqual} from 'lodash';
import React, {Component, PropTypes} from 'common/react-vendor';
import {redux} from 'store';
import './le-list.scss';

export default class LeFilterList extends Component {
	constructor(props) {
		super(props);

		this.redux = redux({
			path: props.namespace,
			state: props.config,
			component: this,
			appCache: props.noAppCache ? null : ['values'], // makes properties sticky across session
		});
	}

	/**
	 * FIXME
	 * This is an initial fix for a deeper problem with redux and the item list component
	 * where the props and state are not in sync.
	 * A better fix should be done later when more time is allowed
	 */
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps?.config?.items?.length !== undefined &&
			!isEqual(nextProps?.config?.items, this.redux.get()?.items)
		) {
			this.redux.set({items: nextProps.config.items});
		}
	}

	componentDidMount() {
		// Use initPayload prop to set redux value on mount
		if (this.props.initPayload) {
			this.redux.set(this.props.initPayload);
		}
	}

	getId(index) {
		return `filter-list-option-${this.props.namespace}-${index}`;
	}

	isChecked(item) {
		const state = this.redux.get();
		let ret = false;

		(state.values || []).forEach((value) => {
			if (item.label === value.label) {
				ret = true;
			}
		});

		return ret;
	}

	clickAll = () => {
		const {items} = this.redux.get();

		this.redux.set({
			values: items.map((item) => {
				return {
					label: item.label,
					action: item.action,
				};
			}),
		});
	};

	clickNone = () => {
		this.redux.set({values: []});
	};

	clickItem = (item) => {
		const {label, action} = item;
		const state = this.redux.get();
		let index = -1;

		state.values.forEach((value, i) => {
			if (value.label == label) {
				index = i;
			}
		});

		if (index > -1) {
			state.values.splice(index, 1);
		} else {
			state.values.push({label, action});
		}

		this.redux.set(state);
	};

	render() {
		if (!this.props || !this.props.config) {
			return '';
		}

		const {toggles, items, label, hideHeader} = this.redux.get();

		return (
			<div className={`filter-list ${this.props.className}`}>
				{!hideHeader ? this.renderHeader(label) : ''}
				{toggles ? this.renderToggles() : ''}
				{items && items.length > 0 ? (
					<ul className='filter-list-container'>{this.renderItems(items)}</ul>
				) : (
					<span>No items found.</span>
				)}
			</div>
		);
	}

	renderHeader(label) {
		return (
			<span className='filter-list-label'>
				{Array.isArray(label)
					? label.map((v, i) => <span key={v + i}>{v}</span>)
					: label}
			</span>
		);
	}

	renderToggles() {
		const {values, items} = this.redux.get();

		if (values.length == 0) {
			return (
				<div className='filter-list-toggles'>
					<span onClick={this.clickAll}>Select All</span>
				</div>
			);
		}

		if (values.length == items.length) {
			return (
				<div className='filter-list-toggles'>
					<span onClick={this.clickNone}>Select None</span>
				</div>
			);
		}

		return (
			<div className='filter-list-toggles'>
				<span onClick={this.clickAll}>All</span> |{' '}
				<span onClick={this.clickNone}>None</span>
			</div>
		);
	}

	renderItems(items) {
		return items.map((item, index) => {
			if (item.if == undefined || item.if) {
				const replace = this.props.replace || {},
					label = replace[item.label] || item.label;

				return (
					<li
						key={label + index}
						className={`${item.class ? item.class : ''} ${
							item.label ? item.label.replace(/[^a-z]/gi, '').toLowerCase() : ''
						}`}>
						<label htmlFor={this.getId(index)}>
							<input
								className='list-item-input'
								type='checkbox'
								id={this.getId(index)}
								onChange={() => this.clickItem(item)}
								checked={this.isChecked(item)}
							/>
							{item.label_icon ? (
								<span className='list-item-icon'>{item.label_icon}</span>
							) : (
								''
							)}
							<span className='list-item-label' title={item.title}>
								<strong className='list-item-wrapper'>{label}</strong>
								{item.label_alt ? <i>{item.label_alt}</i> : ''}
							</span>
							{(item._additional || []).map((v, i) => (
								<span className={'list-item-tabular tabular-' + i} key={v + i}>
									{v}
								</span>
							))}
							<span className='list-item-total'>{item.total}</span>
						</label>
					</li>
				);
			}
		});
	}
}

LeFilterList.propTypes = {
	config: PropTypes.object.isRequired,
	namespace: PropTypes.string,
	initPayload: PropTypes.object,
};

LeFilterList.defaultProps = {
	namespace: '@filter.list',
	initPayload: null,
};
