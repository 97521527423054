import React, {createRoot} from 'common/react-vendor';
import styles from './Snackbar.module.scss';

enum SnackbarTypes {
	SUCCESS = 'success',
	INFO = 'info',
	WARN = 'warn',
	ERROR = 'error',
}

interface SnackbarProps {
	message?: React.ReactNode;
	type?: SnackbarTypes;
}

export function SnackbarComponent({
	message = '',
	type = SnackbarTypes.SUCCESS,
}: SnackbarProps): React.ReactElement {
	return <div className={`${styles.snackbar} ${styles[type]}`}>{message}</div>;
}

type SnackbarFunction = (
	message: React.ReactNode,
	duration?: number
) => HTMLDivElement | void;

const defaultFunction: SnackbarFunction = () => {
	return;
};

type SnackbarTypesFunctions = Record<SnackbarTypes, SnackbarFunction>;

export class Snackbar implements SnackbarTypesFunctions {
	[SnackbarTypes.SUCCESS] = defaultFunction;

	[SnackbarTypes.INFO] = defaultFunction;

	[SnackbarTypes.WARN] = defaultFunction;

	[SnackbarTypes.ERROR] = defaultFunction;

	root: ReturnType<typeof createRoot> | undefined;

	constructor(getMountPoint?: () => HTMLElement) {
		if (getMountPoint) {
			this.getMountPoint = getMountPoint;
		}

		Object.values(SnackbarTypes).forEach((type) =>
			this.generateMessageMethod(type)
		);
	}

	getMountPoint(): HTMLElement {
		return document.body;
	}

	getContainer(): HTMLDivElement {
		const containerElement = document.createElement('div');

		this.getMountPoint().appendChild(containerElement);

		return containerElement;
	}

	removeContainer(container: HTMLDivElement): void {
		if (this.root) {
			this.root.unmount();
			this.getMountPoint().removeChild(container);
		}
	}

	generateMessageMethod(type: SnackbarTypes): void {
		this[type] = (
			message: React.ReactNode,
			duration = 3000
		): HTMLDivElement => {
			const snackbarContainer = this.getContainer();

			this.root = createRoot(snackbarContainer);
			this.root.render(<SnackbarComponent message={message} type={type} />);

			setTimeout(() => this.removeContainer(snackbarContainer), duration);

			return snackbarContainer;
		};
	}
}

export default new Snackbar();
