interface BannerOptions {
	badge: number;
	name: string;
	show: boolean;
	type: string;
	title: string;
	message: string | string[];
	timestamp: number[];
}

type BannerType = 'error' | 'info' | 'success' | 'warning';

interface IncompleteBannerOptions {
	badge?: number;
	name?: string;
	show?: boolean;
	type?: BannerType;
	title?: string;
	message?: string | string[];
	timestamp?: number[];
}

const DEFAULT_BANNER_OPTIONS = {
	badge: 1,
	name: '',
	show: true,
	type: 'info',
	title: '',
	message: '',
	timestamp: [],
};

export const Banner = (() => {
	const getBanners = (): BannerOptions[] => {
		return JSON.parse(localStorage.getItem('banners') || '[]');
	};

	const set = (opts: IncompleteBannerOptions): void => {
		const banner: BannerOptions = {
			...DEFAULT_BANNER_OPTIONS,
			...opts,
		};

		const banners = getBanners();
		const filteredBanners = banners.filter(
			(item) =>
				item.name === opts.name &&
				item.type === banner.type &&
				item.title === banner.title &&
				item.message === banner.message &&
				item.show
		);

		if (filteredBanners.length > 0) {
			filteredBanners.forEach(function (item) {
				item.timestamp.push(new Date().getTime());
				// eslint-disable-next-line no-param-reassign
				item.badge++;
			});
		} else {
			banner.timestamp = [new Date().getTime()];
			banners.push(banner);
		}

		if (Array.isArray(banner.message)) {
			banner.message = banner.message.map((m) => `<li>${m}</li>`);
		}

		localStorage.setItem('banners', JSON.stringify(banners));
	};

	return {
		get(name: string) {
			const banners = getBanners();
			return name ? banners.filter((banner) => banner.name === name) : banners;
		},
		reset(lifetime = 0) {
			const now = Date.now();
			const banners = getBanners();
			banners.forEach((b, i) => {
				const ts = b.timestamp[b.timestamp.length - 1]!;
				const age = now - ts;
				if (age >= lifetime) {
					banners.splice(i, 1);
				}
			});
			localStorage.setItem('banners', JSON.stringify(banners));
		},
		close(name: string) {
			const banners = getBanners();
			banners.forEach((b) => {
				// eslint-disable-next-line no-param-reassign
				if (b.name === name) b.show = false;
			});
			localStorage.setItem('banners', JSON.stringify(banners));
		},
		clear(type: string) {
			const banners = getBanners();
			banners.forEach((b) => {
				// eslint-disable-next-line no-param-reassign
				if (b.type === type) b.show = false;
			});
			localStorage.setItem('banners', JSON.stringify(banners));
		},
		error(opts: IncompleteBannerOptions) {
			set({...opts, type: 'error'});
		},
		warning(opts: IncompleteBannerOptions) {
			set({...opts, type: 'warning'});
		},
		success(opts: IncompleteBannerOptions) {
			set({...opts, type: 'success'});
		},
		info(opts: IncompleteBannerOptions) {
			set({...opts, type: 'info'});
		},
	};
})();
